/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import bus from '@/components/common/bus'
import QS from 'qs'
import { Message } from 'element-ui'
import { Toast } from 'vant'
import store from '../store/index'
import router from '../router'
import i18n from '../common/lang/i18n'
// 路径代理
axios.defaults.baseURL = '/qeda/'
// 环境的切换
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = ''
}

// 请求超时时间
axios.defaults.timeout = 60000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Origin-language'] = 'en';

axios.defaults.headers['Access-Control-Allow-Credentials'] = true

// 请求携带Cookie
axios.defaults.withCredentials = true
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.state.token
    token && (config.headers.token = token)
    config.metadata = { startTime: new Date() }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    bus.$emit('loadFinish', response.duration)
    return response
  },
  function (error) {
    // error.config.metadata.endTime = new Date();
    // error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error)
  }
)
// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (parseInt(response.data.code / 1000) === 400) {
        if (response.data.code === 400024 || response.data.code === 400003) {
          bus.$emit('resetImportDialog', false)
        }
        if (i18n.t('messages.code_' + response.data.code)) {
          // Message.error(`${i18n.t('messages.code_' + response.data.code)}`)
          if (response.data.code === 400911 || response.data.code === 400912) {
            bus.$emit('errDialog', response.data.message)
          } else {
            bus.$emit('errDialog', `${i18n.t('messages.code_' + response.data.code)}`)
          }
        } else {
          Message.error('error')
        }
        bus.$emit('ending', true)
        bus.$emit('screen_lock', false)
        return Promise.reject(response)
      } else if (parseInt(response.data.code / 1000) === 401 || response.data.code === 500003) {
        delCookie()
        bus.$emit('loginFailed', false)
        router.replace('/login')
        return Promise.reject(response)
      } else if (parseInt(response.data.code / 1000) === 405) {
        if (response.data.code === 405336) {
        } else if (parseInt(response.data.code) === 405334) {
          // DO NOT throw error code from get_pdk_file
        } else {
          // Message.error(`${i18n.t('messages.code_' + response.data.code)}`)
          bus.$emit('errDialog', `${i18n.t('messages.code_' + response.data.code)}`)
        }
        // Message.error(`${i18n.t('messages.code_' + response.data.code)}`)
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: '/login',
          })
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          Toast({
            message: '登录过期，请重新登录',
            duration: 1000,
            forbidClick: true,
          })
          // 清除token
          localStorage.removeItem('token')
          store.commit('loginSuccess', null)
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath,
              },
            })
          }, 1000)
          break
        // 404请求不存在
        case 404:
          Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true,
          })
          break
        // 其他错误，直接抛出错误提示
        default:
          let errMsg = error.response.data.message
          // Toast({
          //   message: errMsg,
          //   duration: 1500,
          //   forbidClick: true,
          // })
          Message.error(errMsg)
      }
      return Promise.reject(error.response)
    }
  }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, otherConfig = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...otherConfig,
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function postMulti(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { 'Content-Type': 'multipart/form-data;' })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function patch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function httpDelete(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data: params })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function download(url, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      responseType: 'arraybuffer',
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function getBlob(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: params,
      headers: headers,
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function clearCookie() {
  // let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  let keys = ['originqsid', 'domain']
  if (keys) {
    for (let i = keys.length; i--;) {
      document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() //清除当前域名下的,例如：m.kevis.com
      document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() //清除当前域名下的，例如 .m.kevis.com
      document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString() //清除一级域名下的或指定的，例如 .kevis.com
    }
  }
}

export function delCookie() {
  let cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    let eqPos = cookie.indexOf('=')
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
  }
  if (cookies.length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i]
      let eqPos = cookie.indexOf('=')
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      let domain = location.host.substr(location.host.indexOf('.'))
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + domain
    }
  }
}
