var GATETYPE = {
  GAP_TYPE_ONE_TOP: 0b00000001,
  GAP_TYPE_ONE_BOTTOM: 0b00000010,
  GAP_TYPE_TWO_TOP: 0b00000100,
  GAP_TYPE_TWO_BOTTOM: 0b00001000,
}
// superconductor layers
const default_layer_keypoint = 0
const default_layer_flip_l1 = 201
const default_layer_base_l1 = 101
const default_layer_flip_l3 = 203
// const default_layer_metal3_flip = 4
const default_layer_flip_l4 = 204
// const default_layer_metal4_flip = 6
const default_layer_base_l5 = 105
const default_layer_flip_l5 = 205
const default_layer_base_l2 = 102
const default_layer_flip_l2 = 202
const default_layer_jj = 206
// const default_layer_junction_3 = 13
const default_layer_bandage = 207
// const default_layer_junction_2 = 15
// const default_layer_junction_1 = 16
const default_layer_label = 2002

// auxiliary layers
const aux_layer_drc_wbp = 1002
const aux_layer_drc_cpw = 1003
const aux_layer_drc_cab = 1004
const aux_layer_drc_cab_wbp = 1005
const aux_layer_drc_res = 1006

// semiconductor layers
const default_layer_screen = 201
const default_layer_co_zone = 202
const default_layer_co = 203
const default_layer_barrier = 204
const default_layer_plunger = 205
const default_layer_ion = 206
const default_layer_tiau = 207
const default_layer_mesa = 208
const default_layer_qubit = 209
const default_layer_lead = 210
const default_layer_channel = 211
const default_layer_source = 212
const default_layer_fin = 213
const default_layer_pad = 214
const default_layer_resistor = 215
const default_layer_resonator = 216

var keypoint_property = {
  create_label: true,
}

var kp_pro_str = JSON.stringify(keypoint_property)
// var QGdstk = Module;
;(function () {
  function PDKDevice() {
    this.assert = function (params) {
      return true
    }
    
    this.convex_hull_points = function (polygon) {
      var tmp_cell = new QGdstk.Cell("tmp");
      tmp_cell.add(polygon);
      return tmp_cell.convex_hull();
    }

    this.paramsInfo = {
      CPW_corner: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 4, min: 3, max: 10 },
        A1: { value: 2, min: 2, max: 5 },
        A2: { value: 25, min: 20, max: 50 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 },
      },
      CPW: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 3200, min: 1000, max: 4000 },
        A1: { value: 50, min: 20, max: 50 },
        A2: { value: 1500, min: 1000, max: 2000 },
        A3: { value: 20, min: 6, max: 20 },
        A4: { value: 10, min: 4, max: 10 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 }
      },
      Inlinepad_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 30, min: 25, max: 60 },
        A1: { value: 21, min: 18, max: 40 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 }
      },
      Inlinepad_2: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 30, min: 25, max: 60 },
        A1: { value: 21, min: 18, max: 40 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 }
      },
      Inlinepad_3: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 30, min: 25, max: 60 },
        A1: { value: 21, min: 18, max: 40 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 }
      },
      Resonator_FPDesign_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 },
        A: { value: 300, min: 200, max: 600 },
        A1: { value: 97.5, min: 60, max: 200 },
        A2: { value: 285, min: 150, max: 550 },
        A3: { value: 5, min: 1, max: 6 },
        A4: { value: 10, min: 3, max: 12 },
        A5: { value: 25, min: 20, max: 50 },
        B: { value: 120, min: 40, max: 300 },
        B1: { value: 132.25, min: 60, max: 300 },
      },
      Resonator_FPDesign_2: {
        layer: default_layer_flip_l1,
        datatype: 0,
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 },
        A: { value: 330, min: 200, max: 600 },
        A1: { value: 125, min: 60, max: 200 },
        A2: { value: 220, min: 150, max: 550 },
        A3: { value: 25, min: 20, max: 50 },
        B: { value: 767.5, min: 600, max: 900 },
        B1: { value: 10, min: 3, max: 12 },
        B2: { value: 5, min: 1, max: 6 },
        C: { value: 189.62, min: 120, max: 500 },
      },
      Cap1_Qubit_Qubit_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        a: { value: 231, min: 180, max: 300 },
        a1: { value: 60, min: 40, max: 80 },
        b: { value: 154, min: 120, max: 160 },
        b1: { value: 78, min: 60, max: 100 },
        c: { value: 65.5, min: 50, max: 120 },
        c1: { value: 20, min: 10, max: 30 },
        c2: { value: 5, min: 3, max: 6 },
      },
      Cap1_Qubit_Qubit_2: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 55.4, min: 50, max: 60 },
        A1: { value: 34.4, min: 30, max: 40 },
        A2: { value: 16, min: 10, max: 20 },
        A3: { value: 3, min: 2, max: 6 },
        B: { value: 82, min: 75, max: 90 },
        B1: { value: 72, min: 65, max: 80 },
        B2: { value: 92.7, min: 85, max: 100 },
        B3: { value: 14, min: 10, max: 20 },
        B4: { value: 5, min: 3, max: 10 },
        B5: { value: 114.77, min: 100, max: 120 },
        C: { value: 51.3, min: 40, max: 60 },
        C1: { value: 12.18, min: 8, max: 20 },
        C2: { value: 33.18, min: 25, max: 40 },
      },
      Cap1_Qubit_Resonator_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 158, min: 120, max: 180 },
        A1: { value: 10, min: 3, max: 20 },
        A2: { value: 85.5, min: 50, max: 120 },
        A3: { value: 20, min: 10, max: 40 },
        B: { value: 40, min: 20, max: 80 },
        B1: { value: 5, min: 2, max: 8 },
      },
      Cap1_Qubit_Resonator_2: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 4, min: 3, max: 20 },
        B: { value: 102.14, min: 80, max: 120 },
        B1: { value: 3, min: 2, max: 8 },
      },
      Cap1_Qubit_Resonator_3: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 4, min: 3, max: 20 },
        B: { value: 145.6, min: 80, max: 200 },
        B3: { value: 3, min: 2, max: 8 },
      },
      Cap1_Qubit_Resonator_4: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 4, min: 3, max: 20 },
        B: { value: 194.93, min: 150, max: 230 },
        B3: { value: 3, min: 2, max: 8 },
      },
      WBPad: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 456, min: 300, max: 600 },
        A1: { value: 250, min: 100, max: 500 },
        B: { value: 323, min: 200, max: 500 },
        B1: { value: 250, min: 150, max: 400 },
        B2: { value: 100, min: 50, max: 200 },
        C: { value: 8, min: 8, max: 20 },
        C1: { value: 4, min: 4, max: 10 },
      },
      JJTest: {
        layer: default_layer_flip_l1,
        datatype: 0,
        layer_squid: default_layer_jj,
        datatype_squid: 0,
        A: { value: 400, min: 300, max: 500 },
        A1: { value: 300, min: 200, max: 400 },
        B: { value: 64, min: 30, max: 80 },
        B1: { value: 15, min: 10, max: 30 },
        B2: { value: 15, min: 10, max: 30 },
        B3: { value: 24, min: 20, max: 40 },
      },
      X_line: {
        layer: default_layer_flip_l1,
        datatype: 0,
        a: { value: 54, min: 20, max: 80 },
        c: { value: 2, min: 1, max: 5 },
        d: { value: 4, min: 2, max: 10 },
      },
      Z_line_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        b: { value: 40, min: 20, max: 80 },
        c: { value: 4, min: 2, max: 8 },
        d: { value: 4, min: 2, max: 10 },
        e: { value: 2, min: 1, max: 5 },
        f: { value: 3, min: 2, max: 8 },
        g: { value: 7, min: 3, max: 10 },
      },
      ChipFrame_1: {
        layer: default_layer_flip_l1,
        datatype: 0,
        A: { value: 8000, min: 4000, max: 40000 },
        A1: { value: 8000, min: 4000, max: 40000 },
      },
      AB_1: {
        layer_pier: default_layer_flip_l3,
        datatype_pier: 0,
        layer_deck: default_layer_flip_l4,
        datatype_deck: 0,
        A: { value: 48, min: 40, max: 100 },
        A1: { value: 17, min: 10, max: 30 },
        A2: { value: 16, min: 10, max: 50 },
        A3: { value: 14, min: 10, max: 30 },
        A4: { value: 21, min: 15, max: 40 },
      },
      Indium_1: {
        layer_indium: default_layer_base_l5,
        datatype_indium: 0,
        layer_indium_flip: default_layer_flip_l5,
        datatype_indium_flip: 0,
        layer_tin: default_layer_base_l2,
        datatype_tin: 0,
        layer_tin_flip: default_layer_flip_l2,
        datatype_tin_flip: 0,
        A: { value: 26, min: 20, max: 80 },
        A1: { value: 16, min: 10, max: 60 },
        tolerance: { value: 1e-2, min: 1e-10, max: 1e10 }
      },
    }
    
    PDKDevice.prototype.getParams = function (name) {
      return this.paramsInfo[name];
    },

    PDKDevice.prototype.convex_hull_points = function (polygon) {
      var tmp_cell = new QGdstk.Cell("tmp");
      tmp_cell.add(polygon);
      return tmp_cell.convex_hull();
    }
  
    PDKDevice.prototype.stepper_marker = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const a = 1600;
      const b = 200;
      const c = 600;
      const d = 200;
      const e = 20;
      const g = 348.5;
      const l = 40;
      const w = 160;
      const y = 20;
      const r = Math.PI / 2;
      const d1 = 18;
      const d2 = 2;
      const d3 = 52;
      const d4 = 58;
      const d7 = 128.5;
      const d8 = 560;
  
      const f = 303;
      const m = 4;
      const n = 3;
      const o = 3;
      const p = 16;
      const s = 22;
      const d6 = 50;
  
      const h = 4;
      const i = 4;
      const j = 16;
      const k = 596;
      const d5 = 124;
  
      // points
      var p0 = [0, 0];
      var p1 = [0, y];
      var p2 = [c + d, y];
      var p3 = [c + d, (w - l) / 2 + y];
      var p4 = [c, (w - l) / 2 + y];
      var p5 = [c, (w + l) / 2 + y];
      var p6 = [c + d, (w + l) / 2 + y];
      var p7 = [c + d, w + y];
      var p8 = [0, w + y];
      var p9 = [0, w + 2 * y];
      var p10 = [a, w + 2 * y];
      var p11 = [a, w + y];
      var p12 = [a - d, w + y];
      var p13 = [a - d, (w + l) / 2 + y];
      var p14 = [a, (w + l) / 2 + y];
      var p15 = [a, (w - l) / 2 + y];
      var p16 = [a - d, (w - l) / 2 + y];
      var p17 = [a - d, y];
      var p18 = [a, y];
      var p19 = [a, 0];
      var p20 = [c + d + e, y];
      var p21 = [c + d + e + w / 2, w / 2 + y];
      var p22 = [c + d + e, w + y];
      var p23 = [c + d + e + d8, w + y];
      var p24 = [c + d + e + d8 - w / 2, w / 2 + y];
      var p25 = [c + d + e + d8, y];
  
      // cell
      var outter = new QGdstk.Polygon([p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p25, p24, p23, p22, p21, p20, p25,
        p17, p18, p19], layer, datatype);
  
      var left_dot = new QGdstk.Polygon([[0, 0], [h, 0], [h, h], [0, h]], layer, datatype);
      var right_dot = new QGdstk.Polygon([[0, 0], [n, 0], [n, m], [0, m]], layer, datatype);
  
      var left_dot_cell = new QGdstk.Cell("stepper_marker_left_dot");
      left_dot_cell.add(left_dot);
      var right_dot_cell = new QGdstk.Cell("stepper_marker_right_dot");
      right_dot_cell.add(right_dot);
  
      var left_array = new QGdstk.Reference(left_dot_cell, [d2, y + d1], 0, 1,
        false, (k - h) / (h + i) + 1, (d5 - h) / (h + j) + 1, [i + h, j + h]);
      var right_array_below_2_line = new QGdstk.Reference(right_dot_cell, [c + d + e + d7, y + d4], 0, 1,
        false, (f - n) / (n + o) + 1, 2, [n + o, m + p]);
      var right_array_upper_line = new QGdstk.Reference(right_dot_cell, [c + d + e + d7, y + d4 + d6 - m], 0, 1,
        false, (f - n) / (n + o) + 1, 1, [n + o, 0]);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("stepper_marker");
      // FIXME: this effective
      // cell.add([outter, left_array, right_array_upper_line, right_array_below_2_line]);
      cell.add(outter);
      cell.add(left_array.get_polygons());
      cell.add(right_array_upper_line.get_polygons());
      cell.add(right_array_below_2_line.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.XZ_transform = function (layer = default_layer_flip_l1, datatype = 0) {
      // vars, unit is um
      const a = 20;
      const b = 6;
      const c = 10;
      const d = 4;
      const e = 8;
  
      // points
      var p1 = [0, -c / 2];
      var p2 = [b, -d / 2];
      var p3 = [b, -e / 2];
      var p4 = [0, -a / 2];
      var p5 = [0, c / 2];
      var p6 = [b, d / 2];
      var p7 = [b, e / 2];
      var p8 = [0, a / 2];
  
      // cell
      var poly_below = new QGdstk.Polygon([p1, p2, p3, p4], layer, datatype);
      var poly_upper = new QGdstk.Polygon([p5, p6, p7, p8], layer, datatype);
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [b, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
  
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("XZ_transform");
      cell.add([poly_below, poly_upper, O1, O2]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.X_line = function (layer = default_layer_flip_l1, datatype = 0, a = 54, c = 2, d = 4) {
      // check
      this.assert(a >= 20 && a <= 80);
      this.assert(c >= 1 && c <= 5);
      this.assert(d >= 2 && d <= 10);
  
      // points
      var p1 = [-d / 2, 0];
      var p2 = [-(d / 2 + c), 0];
      var p3 = [-(d / 2 + c), -a];
      var p4 = [d / 2 + c, -a];
      var p5 = [d / 2 + c, 0];
      var p6 = [d / 2, 0];
      var p7 = [d / 2, -a + c];
      var p8 = [-d / 2, -a + c];
  
      // cell
      var poly = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8], layer, datatype);
      var cell = new QGdstk.Cell("X_line");
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      cell.add([poly, O1]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Z_line_1 = function (layer = default_layer_flip_l1, datatype = 0, b = 40, c = 4, d = 4, e = 2, f = 3, g = 7) {
      // check
      this.assert(b >= 20 && b <= 80);
      this.assert(c >= 2 && c <= 8);
      this.assert(d >= 2 && d <= 10);
      this.assert(e >= 1 && e <= 5);
      this.assert(f >= 2 && f <= 8);
      this.assert(g >= 3 && g <= 10);
  
      // vars
      var a = 2 * g + 2 * e + d;
  
      // points
      var p1 = [-d / 2, 0];
      var p2 = [-(d / 2 + e), 0];
      var p3 = [-(d / 2 + e), b - 2 * e - c];
      var p4 = [-(d / 2 + f), b - 2 * e - c];
      var p5 = [-(d / 2 + f), b - e - c];
      var p6 = [-d / 2, b - e - c];
      var p7 = [d / 2, 0];
      var p8 = [d / 2 + e, 0];
      var p9 = [d / 2 + e, b - e];
      var p10 = [a / 2, b - e];
      var p11 = [a / 2, b];
      var p12 = [-a / 2, b];
      var p13 = [-a / 2, b - e];
      var p14 = [d / 2, b - e];
  
      // cell
      var poly_left = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6], layer, datatype);
      var poly_right = new QGdstk.Polygon([p7, p8, p9, p10, p11, p12, p13, p14], layer, datatype);
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Z_line_1");
      cell.add([poly_left, poly_right, O1]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Z_line_2 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 0.01) {
      // param
      const A = 8;
      const A1 = 4;
      const A2 = 9.06;
      const A3 = 7.27;
      const A4 = 12.76;
      const A5 = 1.95;
      const A6 = 10;
      const A7 = 1.98;
      const A8 = 2;
      const A9 = 12.58;
      const A10 = 2;
      const A11 = 20;
      const A12 = 30.3;
      const A13 = 30.3;
      const A14 = 25.59;
  
      const B = 12.58;
      const B1 = 2;
      const B2 = 12.96;
      const B3 = 0.8;
      const B4 = 5;
      const B5 = (154 / 180) * Math.PI;
  
      // ansible var
      var p1 = [-A / 2, 0];
      var l1 = 18.55;
      var r1 = A6 + A / 2;
      var p2 = [A / 2 + A3 + 8.25, -A4];
      var l2 = (A - A1) / 2;
      var p3 = [p2[0] - l2, p2[1]];
      var l3 = 1.25;
      var l4 = 16.20;
      var r2 = r1 - l2;
      var l5 = A2 + A5 + (A - A1) / 2 - A7;
      var p4 = [-A1 / 2, 0];
  
      var p5 = [l2, 0];
      var l6 = 24.51;
      var l7 = 15.01;
      var r3 = A6 - A1 / 2;
      var p6 = [A / 2 + A3 + l2, -A4];
      var p7 = [A / 2 + A3, -A4];
      var p8 = [A / 2 + A9, -A4 - B2];
      var arc_B1 = (56 / 180) * Math.PI;
      var l8 = A9 - A10 - Math.sin(arc_B1) * B1;
      var p9 = [A / 2, 0];
      // cell
      var outter_path = new QGdstk.Curve(p1, tolerance);
      outter_path.commands(['l', 0, -l1,
        'a', A5, -Math.PI / 2,
        'l', -A2, 0,
        'a', r1, Math.PI / 2,
        'l', 0, -A12,
        'a', r1, Math.PI / 2,
        'l', A13, 0,
        'a', r1, Math.PI / 2,
        'l', 0, A14,
        'a', A11, Math.PI - B5,
        'L', p2[0] + B4 - (B4 * Math.cos(Math.PI - B5)), p2[1] - B3 - B4 * Math.sin(Math.PI - B5),
        'A', B4, -B5, -Math.PI,
        'L', p2[0], p2[1],
        'L', p7[0], p7[1],
        'l', 0, -l3,
        'a', B4, Math.PI - B5,
        'L', p8[0], p8[1],
        'A', B1, arc_B1 - Math.PI / 2, -Math.PI / 2,
        'l', -l8, 0,
        'a', A10, -Math.PI / 2,
        'L', p9[0], p9[1]
      ]);
  
      var outter = new QGdstk.Polygon(outter_path.points());
  
      var inner_path = new QGdstk.Curve([p4[0], p4[1] - 20.55], tolerance);
      inner_path.commands(['l', 0, 20.55,
        'l', A1, 0,
        'l', 0, -l6,
        'a', A8, -Math.PI / 2,
        'l', -l7, 0,
        'a', r3, Math.PI / 2,
        'l', 0, -A12,
        'a', r3, Math.PI / 2,
        'l', A13, 0,
        'a', r3, Math.PI / 2,
        'l', 0, A14,
        'a', A11, Math.PI - B5,
        'L', p6[0] + B4 - (B4 * Math.cos(Math.PI - B5)), p6[1] - B3 - B4 * Math.sin(Math.PI - B5),
        'A', B4, -B5, -Math.PI,
        'L', p6[0], p6[1],
        'L', p3[0], p3[1],
        'l', 0, -l3,
        'a', B4, Math.PI - B5,
        // add magic number for sligtly offset
        'L', A1 / 2 - A8 - l7 + A13 + r2 - (A11 - A11 * Math.cos(Math.PI - B5)) - 0.092, -l6 - A8 - r3 - A12 + A14 + A11 * Math.sin(Math.PI - B5) - 0.018,
        'a', A11, B5 - Math.PI,
        'l', 0, -A14,
        'a', r2, -Math.PI / 2,
        'l', -A13, 0,
        'a', r2, -Math.PI / 2,
        'l', 0, A12,
        'a', r2, -Math.PI / 2,
        'l', l5, 0,
        'a', A7, Math.PI / 2
      ]);
      var inner = new QGdstk.Polygon(inner_path.points());
  
      var polys = QGdstk.boolean(outter, inner, 'not', 1e-3, layer, datatype);
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Z_line_2");
      cell.add(polys);
      cell.add(O1)
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_1 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // param
      const a = 72;
      const b = 140;
      const c = 86;
      const c1 = 18;
      const d = 364;
  
      const e = 24;
      const f = 140;
      const g = 32;
      const h = 24;
      const k = 92;
      const m = 344;
  
      const n = 5;
      const o = 10;
      const p = 1.94;
      const r = 18;
      const s = 22;
      const t = 12;
      const x = 0.12;
      const y = 6;
      const z = 6;
      const a1 = 18;
  
      // outter point
      var p1 = [-a / 2, 0];
      var p2 = [a / 2, 0];
      var p3 = [a / 2, c];
      var p4 = [d / 2, c];
      var p5 = [d / 2, c + a];
      var p6 = [a / 2, c + a];
      var p7 = [a / 2, c + a + b];
      var p8 = [-a / 2, c + a + b];
      var p9 = [-a / 2, c + a];
      var p10 = [-d / 2, c + a];
      var p11 = [-d / 2, k - ((g - e) / 2) + c1];
      var p12 = [-m / 2, k - ((g - e) / 2) + c1];
      var p13 = [-m / 2, k + g - ((g - e) / 2) + c1];
      var p14 = [-(m / 2 - h), k + g - ((g - e) / 2) + c1];
      var p15 = [-(m / 2 - h), k + e + c1];
      var p16 = [-e / 2, k + e + c1];
      var p17 = [-e / 2, k + e + f + c1];
      var p18 = [e / 2, k + e + f + c1];
      var p19 = [e / 2, k + e + c1];
      var p20 = [(m / 2 - h), k + e + c1];
      var p21 = [(m / 2 - h), k + g - ((g - e) / 2) + c1];
      var p22 = [m / 2, k + g - ((g - e) / 2) + c1];
      var p23 = [m / 2, k - ((g - e) / 2) + c1];
      var p24 = [(m / 2 - h), k - ((g - e) / 2) + c1];
      var p25 = [(m / 2 - h), k + c1];
      var p26 = [e / 2, k + c1];
      var p27 = [e / 2, c1];
      var p28 = [-e / 2, c1];
      var p29 = [-e / 2, k + c1];
      var p30 = [-(m / 2 - h), k + c1];
      var p31 = [-(m / 2 - h), k - ((g - e) / 2) + c1];
      var p32 = [-d / 2, c];
      var p33 = [-a / 2, c];
  
      // outter polygon
      var cavity = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11,
        p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27, p28, p29, p30, p31, p12,
        p11, p32, p33], layer, datatype);
  
      var p1 = [-(t / 2 + y), 0];
      var p2 = [-t / 2, 0];
      var p3 = [t / 2, 0];
      var p4 = [(t / 2 + y), 0];
      var p5 = [-(r / 2 + x / 2), n];
      var p6 = [-(r / 2 - x / 2), n];
      var p7 = [(r / 2 - x / 2), n];
      var p8 = [(r / 2 + x / 2), n];
      var p9 = [-(t / 2 + y), z];
      var p10 = [-t / 2, z];
      var p11 = [t / 2, z];
      var p12 = [(t / 2 + y), z];
      var p13 = [-y / 2, a1 - z];
      var p14 = [y / 2, a1 - z];
      var p15 = [-s / 2, n + o - p - x];
      var p16 = [s / 2, n + o - p - x];
      var p17 = [-s / 2, n + o - p];
      var p18 = [s / 2, n + o - p];
      var p19 = [-(r / 2 + x / 2), n + o];
      var p20 = [-(r / 2 - x / 2), n + o];
      var p21 = [(r / 2 - x / 2), n + o];
      var p22 = [(r / 2 + x / 2), n + o];
      var p23 = [-y / 2, a1];
      var p24 = [y / 2, a1];
  
      var box_left = new QGdstk.Polygon([p1, p2, p10, p9], layer_squid, datatype_squid);
      var box_middle = new QGdstk.Polygon([p13, p14, p24, p23], layer_squid, datatype_squid);
      var box_right = new QGdstk.Polygon([p3, p4, p12, p11], layer_squid, datatype_squid);
  
      var sc_left = new QGdstk.Polygon([p5, p6, p20, p19], layer_squid, datatype_squid);
      var sc_middle = new QGdstk.Polygon([p15, p16, p18, p17], layer_squid, datatype_squid);
      var sc_right = new QGdstk.Polygon([p7, p8, p22, p21], layer_squid, datatype_squid);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_1");
      cell.add([cavity, box_left, box_middle, box_right, sc_left, sc_middle, sc_right]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_2 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var
      const A = 21;
      const A1 = 20;
      const A2 = 60;
      const B = 1246.8;
      const B1 = 578.3;
      const B2 = 570;
  
      const C = 6;
      const C1 = 7.96;
      const C2 = 6.86;
      const C3 = 7.1;
      const D = 18;
      const D1 = 6;
      const D2 = 0.2;
      const D3 = 6;
  
      // points
      var p1 = [-A2 / 2, 0];
      var p2 = [A2 / 2, 0];
      var p3 = [A2 / 2, (B - A2) / 2];
      var p4 = [B / 2, (B - A2) / 2];
      var p5 = [B / 2, (B + A2) / 2];
      var p6 = [A2 / 2, (B + A2) / 2];
      var p7 = [A2 / 2, B];
      var p8 = [-A2 / 2, B];
      var p9 = [-A2 / 2, (B + A2) / 2];
      var p10 = [-B / 2, (B + A2) / 2];
      var p11 = [-B / 2, (B - A2) / 2];
      var p12 = [-A2 / 2, (B - A2) / 2];
      var p13 = [-A1 / 2, A];
      var p14 = [A1 / 2, A];
      var p15 = [A1 / 2, A + B2];
      var p16 = [B / 2 - A - B2, (B - A1) / 2];
      var p17 = [B / 2 - A, (B - A1) / 2];
      var p18 = [B / 2 - A, (B + A1) / 2];
      var p19 = [B / 2 - A - B1, (B + A1) / 2];
      var p20 = [-A1 / 2, A + B1];
  
      // cell
      var outter = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12]);
      var hollow_below = new QGdstk.Polygon([p13, p14, p15, p16, p17, p18, p19, p20]);
      var hollow_upper = hollow_below.copy();
      hollow_upper.mirror(p6, p12);
      var cap = QGdstk.boolean(outter, [hollow_upper, hollow_below], 'not', 1e-3, layer, datatype);
  
      // squid points
      var trans_to = [(p19[0] + p20[0]) / 2, (p19[1] + p20[1]) / 2];
      var p1 = [-(D - D3) / 2, 0];
      var p2 = [-(D + D3) / 2, 0];
      var p3 = [-(D + D3) / 2, D1];
      var p4 = [-(D + D2) / 2, D1];
      var p5 = [-(D + D2) / 2, D1 + C3];
      var p6 = [-C / 2, D1 + C3];
      var p7 = [-C / 2, D1 + C3 + C2];
      var p8 = [C / 2, D1 + C3 + C2];
      var p9 = [C / 2, D1 + C3];
      var p10 = [(D + D2) / 2, D1 + C3];
      var p11 = [(D + D2) / 2, D1];
      var p12 = [(D + D3) / 2, D1];
      var p13 = [(D + D3) / 2, 0];
      var p14 = [(D - D3) / 2, 0];
      var p15 = [(D - D3) / 2, D1];
      var p16 = [(D - D2) / 2, D1];
      var p17 = [(D - D2) / 2, D1 + C3 - D2];
      var p18 = [C / 2, D1 + C3 - D2];
      var p19 = [C / 2, D1 + C3 + C2 - C1];
      var p20 = [-C / 2, D1 + C3 + C2 - C1];
      var p21 = [-C / 2, D1 + C3 - D2];
      var p22 = [-(D - D2) / 2, D1 + C3 - D2];
      var p23 = [-(D - D2) / 2, D1];
      var p24 = [-(D - D3) / 2, D1];
  
      // squid
      var squid = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24],
        layer_squid, datatype_squid);
      squid.transform(1, false, Math.PI / 4, trans_to, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_2");
      cell.add(cap)
      cell.add(squid);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_3 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var
      const A = 60;
      const A1 = 20;
      const B = 1246.8;
      const B1 = 570;
      const B2 = 578.3;
      const C = 21;
      const C1 = 567.78;
      const C2 = 572.24;
  
      const D = 2.05;
      const D1 = 3.03;
      const D2 = 30;
      const D3 = 38;
  
      const E = 6;
      const E1 = 7.96;
      const E2 = 6.68;
      const E3 = 7.1;
      const F = 18;
      const F1 = 6;
      const F2 = 0.2;
      const F3 = 6;
  
      // points
      var p1 = [-A / 2, 0];
      var p2 = [A / 2, 0];
      var p3 = [A / 2, (B - A) / 2];
      var p4 = [B / 2, (B - A) / 2];
      var p5 = [B / 2, (B + A) / 2];
      var p6 = [A / 2, (B + A) / 2];
      var p7 = [A / 2, B];
      var p8 = [-A / 2, B];
      var p9 = [-A / 2, (B + A) / 2];
      var p10 = [-B / 2, (B + A) / 2];
      var p11 = [-B / 2, (B - A) / 2];
      var p12 = [-A / 2, (B - A) / 2];
      var p13 = [-A1 / 2, C];
      var p14 = [A1 / 2, C];
      var p15 = [A1 / 2, C + B1];
      var p16 = [B / 2 - C - B1, (B - A1) / 2];
      var p17 = [B / 2 - C, (B - A1) / 2];
      var p18 = [B / 2 - C, (B + A1) / 2];
      var p19 = [B / 2 - C - B2, (B + A1) / 2];
      var p20 = [-A1 / 2, C + B2];
  
      // cap
      var outter = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12]);
      var hollow_below = new QGdstk.Polygon([p13, p14, p15, p16, p17, p18, p19, p20]);
      var hollow_upper = hollow_below.copy();
      hollow_upper.mirror(p6, p12);
  
      var hollow_below_hollow = new QGdstk.Polygon([[-D3 / 2, 0], [D3 / 2, 0], [D3 / 2, -A], [-D3 / 2, -A]]);
      var anchor = [(p19[0] + p20[0]) / 2, (p19[1] + p20[1]) / 2];
      var hollow_below_hollow_trans_to = [anchor[0] + D1 / Math.sqrt(2), anchor[1] - D1 / Math.sqrt(2)];
      hollow_below_hollow.transform(1, false, Math.PI / 4, hollow_below_hollow_trans_to, null)
      var hollow_below = QGdstk.boolean(hollow_below, hollow_below_hollow, 'not')[0];
  
      var outter_hollow = new QGdstk.Polygon([[-D2 / 2, 0], [D2 / 2, 0], [D2 / 2, -A], [-D2 / 2, -A]]);
      var outter_hollow_trans_to = [anchor[0] + (D1 + D) / Math.sqrt(2), anchor[1] - (D1 + D) / Math.sqrt(2)];
      outter_hollow.transform(1, false, Math.PI / 4, outter_hollow_trans_to, null)
  
      var cap = QGdstk.boolean(outter, [hollow_upper, hollow_below, outter_hollow], 'not', 1e-3, layer, datatype);
  
      // squid
      // squid points
      var p1 = [-(F - F3) / 2, 0];
      var p2 = [-(F + F3) / 2, 0];
      var p3 = [-(F + F3) / 2, F1];
      var p4 = [-(F + F2) / 2, F1];
      var p5 = [-(F + F2) / 2, F1 + E3];
      var p6 = [-E / 2, F1 + E3];
      var p7 = [-E / 2, F1 + E3 + E2];
      var p8 = [E / 2, F1 + E3 + E2];
      var p9 = [E / 2, F1 + E3];
      var p10 = [(F + F2) / 2, F1 + E3];
      var p11 = [(F + F2) / 2, F1];
      var p12 = [(F + F3) / 2, F1];
      var p13 = [(F + F3) / 2, 0];
      var p14 = [(F - F3) / 2, 0];
      var p15 = [(F - F3) / 2, F1];
      var p16 = [(F - F2) / 2, F1];
      var p17 = [(F - F2) / 2, F1 + E3 - F2];
      var p18 = [E / 2, F1 + E3 - F2];
      var p19 = [E / 2, F1 + E3 + E2 - E1];
      var p20 = [-E / 2, F1 + E3 + E2 - E1];
      var p21 = [-E / 2, F1 + E3 - F2];
      var p22 = [-(F - F2) / 2, F1 + E3 - F2];
      var p23 = [-(F - F2) / 2, F1];
      var p24 = [-(F - F3) / 2, F1];
  
      // squid
      var squid = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24],
        layer_squid, datatype_squid);
      squid.transform(1, false, Math.PI / 4, anchor, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_3");
      cell.add(cap);
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_4 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var
      const A = 36;
      const A1 = 6;
      const A2 = 20;
      const A3 = 24;
      const A4 = 56;
      const B = 394;
      const C = 448;
      const C1 = 384;
      const C2 = 64;
      const C3 = 89;
      const C4 = 77;
      const C5 = 20;
      const D = 88;
      const D1 = 48;
      const D2 = 8;
  
      const E = 4.5;
      const E1 = 2;
      const E2 = 1;
      const E3 = 0.12;
      const E4 = 1.191;
      const F = 12;
      const F1 = 6;
      const G = 16.8;
      const G1 = 0.2;
      const G2 = 8.75;
      const G3 = 20;
  
      // points
      var p1 = [-C2 / 2, 0];
      var p2 = [C2 / 2, 0];
      var p3 = [-A3 / 2, (C2 - A3) / 2];
      var p4 = [A3 / 2, (C2 - A3) / 2];
      var p5 = [-C / 2, C4];
      var p6 = [-C2 / 2, C4];
      var p7 = [C2 / 2, C4];
      var p8 = [C / 2, C4];
      var p9 = [-C1 / 2, C4 + (D - D1) / 2];
      var p10 = [-(C1 / 2 - D2), C4 + (D - D1) / 2];
      var p11 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p12 = [-A3 / 2, C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p13 = [A3 / 2, C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p14 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p15 = [(C1 / 2 - D2), C4 + (D - D1) / 2];
      var p16 = [C1 / 2, C4 + (D - D1) / 2];
      var p17 = [-C1 / 2, C4 + (D - D1) / 2 + D1];
      var p18 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + D1];
      var p19 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p20 = [-A3 / 2, C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p21 = [A3 / 2, C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p22 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p23 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + D1];
      var p24 = [C1 / 2, C4 + (D - D1) / 2 + D1];
      var p25 = [-C / 2, C4 + D];
      var p26 = [-C2 / 2, C4 + D];
      var p27 = [C2 / 2, C4 + D];
      var p28 = [C / 2, C4 + D];
      var p29 = [-A / 2, B - A1 - A2];
      var p30 = [-A3 / 2, B - A1 - A2];
      var p31 = [A3 / 2, B - A1 - A2];
      var p32 = [A / 2, B - A1 - A2];
      var p33 = [-A / 2, B - A1];
      var p34 = [A / 2, B - A1];
      var p35 = [-C2 / 2, B];
      var p36 = [C2 / 2, B];
  
      // cap
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]],
        p4, p3, p12, p11, p10, p9, p17, p18, p19, p20, p30, p29, p33, p34, p32, p31, p21, p22, p23, p24, p16, p15, p14, p13, p4,
        [p2[0], p4[1]], p7, p8, p28, p27, p36, p35, p26, p25, p5, p6], layer, datatype);
  
      // squid 
      var p1 = [0, F1 / 2];
      var p2 = [0, -F1 / 2];
      var p3 = [E, -F1 / 2];
      var p4 = [E, -E2 / 2];
      var p5 = [E + E1, -E2 / 2];
      var p6 = [E + E1, E2 / 2];
      var p7 = [E, E2 / 2];
      var p8 = [E, F1 / 2];
  
      var box_middle = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      box_middle.mirror([0, 1]);
      box_middle.translate([G3, 0]);
  
      var box_below = new QGdstk.Polygon([p1, p2, p3, p4, p5, [E + E1, -G1 / 2], [E + E1 + G2, -G1 / 2], [E + E1 + G2, G1 / 2], [E + E1, G1 / 2], p6, p7, p8]);
      var box_upper = box_below.copy();
      box_below.translate([0, -F / 2]);
      box_upper.translate([0, F / 2]);
  
      var sc_vertical = new QGdstk.Polygon([[-E3, G / 2], [-E3, -G / 2], [0, -G / 2], [0, G / 2]]);
      sc_vertical.translate([G3 - E - E4, 0]);
  
      var reuslt = QGdstk.boolean(sc_vertical, [box_below, box_middle, box_upper], 'or', 1e-3, layer_squid, datatype_squid);
      var squid = reuslt[0];
      squid.translate([-C2 / 2, C4 + D + A4]);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_4");
      cell.add(cap);
      cell.add(squid);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_5 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var
      const A = 60;
      const A1 = 26;
      const A2 = 66;
      const A3 = 3;
      const A4 = 36.25;
      const A5 = 20;
      const B = 228.5;
      const B1 = 30;
      const C = 416.2;
      const C1 = 380.2;
      const C2 = 60;
      const C3 = 27.5;
      const C4 = 22.5;
  
      const D = 2;
      const D1 = 1;
      const D2 = 0.2;
      const E = 16.8;
      const E1 = 1.191;
      const F = 20;
      const F1 = 0.12;
      const F2 = 8.75;
      const G = 12;
      const G1 = 4.5;
      const G2 = 6;
  
      // cap 
      var p1 = [-C2 / 2, 0];
      var p2 = [C2 / 2, 0];
      var p3 = [-(A1 / 2 - A3), C4];
      var p4 = [A1 / 2 - A3, C4];
      var p5 = [-C / 2, B1];
      var p6 = [-C2 / 2, B1];
      var p7 = [C2 / 2, B1];
      var p8 = [C / 2, B1];
      var p9 = [-C1 / 2, B1 + (A - A5) / 2];
      var p10 = [-(A1 / 2 - A3), B1 + (A - A5) / 2];
      var p11 = [A1 / 2 - A3, B1 + (A - A5) / 2];
      var p12 = [C1 / 2, B1 + (A - A5) / 2];
      var p13 = [-C1 / 2, B1 + (A + A5) / 2];
      var p14 = [-(A1 / 2 - A3), B1 + (A + A5) / 2];
      var p15 = [A1 / 2 - A3, B1 + (A + A5) / 2];
      var p16 = [C1 / 2, B1 + (A + A5) / 2];
      var p17 = [-C / 2, B1 + A];
      var p18 = [-C2 / 2, B1 + A];
      var p19 = [C2 / 2, B1 + A];
      var p20 = [C / 2, B1 + A];
      var p21 = [-C2 / 2, B - A2];
      var p22 = [-A1 / 2, B - A2];
      var p23 = [A1 / 2, B - A2];
      var p24 = [C2 / 2, B - A2];
      var p25 = [-(A1 / 2 - A3), B - A3];
      var p26 = [A1 / 2 - A3, B - A3];
      var p27 = [-A1 / 2, B];
      var p28 = [A1 / 2, B];
  
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]], p4, p3, p10, p9, p13, p14, p25, p26, p15, p16, p12, p11, p4, [p2[0], p4[1]],
        p7, p8, p20, p19, p24, p23, p28, p27, p22, p21, p18, p17, p5, p6], layer, datatype);
  
      // squid
      var p1 = [0, G2 / 2];
      var p2 = [0, -G2 / 2];
      var p3 = [-G1, -G2 / 2];
      var p4 = [-G1, -D1 / 2];
      var p5 = [-(G1 + D), -D1 / 2];
      var p6 = [-(G1 + D), D1 / 2];
      var p7 = [-G1, D1 / 2];
      var p8 = [-G1, G2 / 2];
  
      var box_middle = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      box_middle.mirror([0, 1]);
      box_middle.translate([-F, 0]);
  
      var box_below = new QGdstk.Polygon([p1, p2, p3, p4, p5, [-(G1 + D), -D2 / 2], [-(G1 + D + F2), -D2 / 2], [-(G1 + D + F2), D2 / 2], [-(G1 + D), D2 / 2], p6, p7, p8]);
      var box_upper = box_below.copy();
      box_below.translate([0, -G / 2]);
      box_upper.translate([0, G / 2]);
  
      var sc_vertical = new QGdstk.Polygon([[F1, E / 2], [F1, -E / 2], [0, -E / 2], [0, E / 2]]);
      sc_vertical.translate([-(F - G1 - E1), 0]);
  
      var reuslt = QGdstk.boolean(sc_vertical, [box_below, box_middle, box_upper], 'or', 1e-3, layer_squid, datatype_squid);
      var squid = reuslt[0];
      squid.translate([C2 / 2, B1 + A + A4]);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_5");
      cell.add(cap);
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_6 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var
      const A1 = 89;
      const A2 = 48;
      const A3 = 88;
      const A4 = 8;
      const B1 = 64;
      const B2 = 466;
      const B3 = 530;
      const C1 = 295;
      const C2 = 77;
      const D1 = 36;
      const D2 = 24;
      const D3 = 4;
      const D4 = 20;
      const D5 = 56;
  
      const E = 12;
      const E1 = 6;
      const F = 4.5;
      const F1 = 2;
      const F2 = 1;
      const G = 16.8;
      const G1 = 0.2;
      const G2 = 0.12;
      const G3 = 20;
      const G4 = 8.75;
      const G5 = 1.191;
  
      // cap
      var p1 = [-B1 / 2, 0];
      var p2 = [B1 / 2, 0];
      var p3 = [-D2 / 2, (B1 - D2) / 2];
      var p4 = [D2 / 2, (B1 - D2) / 2];
      var p5 = [-B3 / 2, C2];
      var p6 = [-B1 / 2, C2];
      var p7 = [B1 / 2, C2];
      var p8 = [B3 / 2, C2];
      var p9 = [-B2 / 2, C2 + (A3 - A2) / 2];
      var p10 = [-(B2 / 2 - A4), C2 + (A3 - A2) / 2];
      var p11 = [-(B2 / 2 - A4), C2 + (A3 - D2) / 2];
      var p12 = [-D2 / 2, C2 + (A3 - D2) / 2];
      var p13 = [D2 / 2, C2 + (A3 - D2) / 2];
      var p14 = [B2 / 2 - A4, C2 + (A3 - D2) / 2];
      var p15 = [B2 / 2 - A4, C2 + (A3 - A2) / 2];
      var p16 = [B2 / 2, C2 + (A3 - A2) / 2];
      var p17 = [-B2 / 2, C2 + (A3 + A2) / 2];
      var p18 = [-(B2 / 2 - A4), C2 + (A3 + A2) / 2];
      var p19 = [-(B2 / 2 - A4), C2 + (A3 + D2) / 2];
      var p20 = [-D2 / 2, C2 + (A3 + D2) / 2];
      var p21 = [D2 / 2, C2 + (A3 + D2) / 2];
      var p22 = [B2 / 2 - A4, C2 + (A3 + D2) / 2];
      var p23 = [B2 / 2 - A4, C2 + (A3 + A2) / 2];
      var p24 = [B2 / 2, C2 + (A3 + A2) / 2];
      var p25 = [-B3 / 2, C2 + A3];
      var p26 = [-B1 / 2, C2 + A3];
      var p27 = [B1 / 2, C2 + A3];
      var p28 = [B3 / 2, C2 + A3];
      var p29 = [-D1 / 2, C1 - D3 - D4];
      var p30 = [-D2 / 2, C1 - D3 - D4];
      var p31 = [D2 / 2, C1 - D3 - D4];
      var p32 = [D1 / 2, C1 - D3 - D4];
      var p33 = [-D1 / 2, C1 - D3];
      var p34 = [D1 / 2, C1 - D3];
      var p35 = [-B1 / 2, C1];
      var p36 = [B1 / 2, C1];
  
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]], p4, p3, p12, p11, p10, p9, p17, p18, p19, p20, p30, p29,
        p33, p34, p32, p31, p21, p22, p23, p24, p16, p15, p14, p13, p4,
        [p2[0], p4[1]], p7, p8, p28, p27, p36, p35, p26, p25, p5, p6], layer, datatype);
  
      // squid
      var p1 = [0, E1 / 2];
      var p2 = [0, -E1 / 2];
      var p3 = [F, -E1 / 2];
      var p4 = [F, -F2 / 2];
      var p5 = [F + F1, -F2 / 2];
      var p6 = [F + F1, F2 / 2];
      var p7 = [F, F2 / 2];
      var p8 = [F, E1 / 2];
  
      var box_middle = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      box_middle.mirror([0, 1]);
      box_middle.translate([G3, 0]);
  
      var box_below = new QGdstk.Polygon([p1, p2, p3, p4, p5, [F + F1, -G1 / 2], [F + F1 + G4, -G1 / 2], [F + F1 + G4, G1 / 2], [F + F1, G1 / 2], p6, p7, p8]);
      var box_upper = box_below.copy();
      box_below.translate([0, -E / 2]);
      box_upper.translate([0, E / 2]);
  
      var sc_vertical = new QGdstk.Polygon([[-G2, G / 2], [-G2, -G / 2], [0, -G / 2], [0, G / 2]]);
      sc_vertical.translate([G3 - F - G5, 0]);
  
      var reuslt = QGdstk.boolean(sc_vertical, [box_below, box_middle, box_upper], 'or', 1e-3, layer_squid, datatype_squid);
      var squid = reuslt[0];
      squid.translate([-B1 / 2, C2 + A3 + D5]);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_6");
      cell.add(cap);
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_7 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      // var 
      const A = 416.2;
      const A1 = 380.2;
      const A2 = 60;
      const A3 = 22.5;
      const A4 = 27.5;
      const A5 = 30;
      const B = 239.3;
      const B1 = 20;
      const B2 = 66.9;
      const C = 60;
      const C1 = 34;
      const C2 = 3;
      const C3 = 15.5;
  
      const D = 16.8;
      const D1 = 0.12;
      const D2 = 8.75;
      const D3 = 1.19;
      const D4 = 20;
      const E = 0.2;
      const E1 = 1;
      const E2 = 2;
      const E3 = 4.5;
      const E4 = 6;
      const E5 = 12;
  
      // cap
      var p1 = [-A2 / 2, 0];
      var p2 = [-A2 / 2, A5];
      var p3 = [-A / 2, A5];
      var p4 = [-A / 2, A5 + C];
      var p5 = [-A2 / 2, A5 + C];
      var p6 = [-A2 / 2, B - C3];
      var p7 = [-C1 / 2, B - C3];
      var p8 = [-C1 / 2, B];
      var p9 = [C1 / 2, B];
      var p10 = [C1 / 2, B - C3];
      var p11 = [C / 2, B - C3];
      var p12 = [C / 2, A5 + C];
      var p13 = [A / 2, A5 + C];
      var p14 = [A / 2, A5];
      var p15 = [A2 / 2, A5];
      var p16 = [A2 / 2, 0];
      var p17 = [-B1 / 2, A3];
      var p18 = [-B1 / 2, A3 + A4];
      var p19 = [-A1 / 2, A3 + A4];
      var p20 = [-A1 / 2, A5 + (C + B1) / 2];
      var p21 = [-B1 / 2, A5 + (C + B1) / 2];
      var p22 = [-B1 / 2, B - C2];
      var p23 = [B1 / 2, B - C2];
      var p24 = [B1 / 2, A5 + (C + B1) / 2];
      var p25 = [A1 / 2, A5 + (C + B1) / 2];
      var p26 = [A1 / 2, A3 + A4];
      var p27 = [B1 / 2, A3 + A4];
      var p28 = [B1 / 2, A3];
  
  
      var cap = new QGdstk.Polygon([p1, p16, [p16[0], p28[1]],
        p28, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27, p28, [p16[0], p28[1]],
        p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4, p3, p2], layer, datatype);
      // squid
      var p1 = [0, E4 / 2];
      var p2 = [0, -E4 / 2];
      var p3 = [-E3, -E4 / 2];
      var p4 = [-E3, -E1 / 2];
      var p5 = [-(E3 + E2), -E1 / 2];
      var p6 = [-(E3 + E2), E1 / 2];
      var p7 = [-E3, E1 / 2];
      var p8 = [-E3, E4 / 2];
  
      var box_middle = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      box_middle.mirror([0, 1]);
      box_middle.translate([-D4, 0]);
  
      var box_below = new QGdstk.Polygon([p1, p2, p3, p4, p5, [-(E3 + E2), -E / 2], [-(E3 + E2 + D2), -E / 2], [-(E3 + E2 + D2), E / 2], [-(E3 + E2), E / 2], p6, p7, p8]);
      var box_upper = box_below.copy();
      box_below.translate([0, -E5 / 2]);
      box_upper.translate([0, E5 / 2]);
  
      var sc_vertical = new QGdstk.Polygon([[0, D / 2], [0, -D / 2], [D1, -D / 2], [D1, D / 2]]);
      sc_vertical.translate([-(D4 - E3 - D3), 0]);
  
      var reuslt = QGdstk.boolean(sc_vertical, [box_below, box_middle, box_upper], 'or', 1e-3, layer_squid, datatype_squid);
      var squid = reuslt[0];
      squid.translate([A2 / 2, A5 + C + B2]);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_7");
      cell.add(cap);
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_8 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var 
      // param
      const a = 72;
      const b = 140;
      const c = 86;
      const c1 = 18;
      const d = 364;
  
      const e = 24;
      const f = 140;
      const g = 32;
      const h = 24;
      const k = 92;
      const m = 344;
  
      const n = 5;
      const o = 10;
      const p = 1.94;
      const r = 18;
      const s = 22;
      const t = 12;
      const x = 0.12;
      const y = 6;
      const z = 6;
      const a1 = 18;
  
      // outter point
      var p1 = [-a / 2, 0];
      var p2 = [a / 2, 0];
      var p3 = [a / 2, c];
      var p4 = [d / 2, c];
      var p5 = [d / 2, c + a];
      var p6 = [a / 2, c + a];
      var p7 = [a / 2, c + a + b];
      var p8 = [-a / 2, c + a + b];
      var p9 = [-a / 2, c + a];
      var p10 = [-d / 2, c + a];
      var p11 = [-d / 2, k - ((g - e) / 2) + c1];
      var p12 = [-m / 2, k - ((g - e) / 2) + c1];
      var p13 = [-m / 2, k + g - ((g - e) / 2) + c1];
      var p14 = [-(m / 2 - h), k + g - ((g - e) / 2) + c1];
      var p15 = [-(m / 2 - h), k + e + c1];
      var p16 = [-e / 2, k + e + c1];
      var p17 = [-e / 2, k + e + f + c1];
      var p18 = [e / 2, k + e + f + c1];
      var p19 = [e / 2, k + e + c1];
      var p20 = [(m / 2 - h), k + e + c1];
      var p21 = [(m / 2 - h), k + g - ((g - e) / 2) + c1];
      var p22 = [m / 2, k + g - ((g - e) / 2) + c1];
      var p23 = [m / 2, k - ((g - e) / 2) + c1];
      var p24 = [(m / 2 - h), k - ((g - e) / 2) + c1];
      var p25 = [(m / 2 - h), k + c1];
      var p26 = [e / 2, k + c1];
      var p27 = [e / 2, c1];
      var p28 = [-e / 2, c1];
      var p29 = [-e / 2, k + c1];
      var p30 = [-(m / 2 - h), k + c1];
      var p31 = [-(m / 2 - h), k - ((g - e) / 2) + c1];
      var p32 = [-d / 2, c];
      var p33 = [-a / 2, c];
  
      // outter polygon
      var cavity = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11,
        p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27, p28, p29, p30, p31, p12,
        p11, p32, p33], layer, datatype);
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var v_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [0, c1 / 2 - v_height], 0, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_8");
      cell.add(cavity);
      cell.add(squid_ref.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_9 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const A = 36;
      const A1 = 6;
      const A2 = 20;
      const A3 = 24;
      const A4 = 56;
      const B = 394;
      const C = 448;
      const C1 = 384;
      const C2 = 64;
      const C3 = 89;
      const C4 = 77;
      const C5 = 20;
      const D = 88;
      const D1 = 48;
      const D2 = 8;
  
      const E = 4.5;
      const E1 = 2;
      const E2 = 1;
      const E3 = 0.12;
      const E4 = 1.191;
      const F = 12;
      const F1 = 6;
      const G = 16.8;
      const G1 = 0.2;
      const G2 = 8.75;
      const G3 = 20;
  
      // points
      var p1 = [-C2 / 2, 0];
      var p2 = [C2 / 2, 0];
      var p3 = [-A3 / 2, (C2 - A3) / 2];
      var p4 = [A3 / 2, (C2 - A3) / 2];
      var p5 = [-C / 2, C4];
      var p6 = [-C2 / 2, C4];
      var p7 = [C2 / 2, C4];
      var p8 = [C / 2, C4];
      var p9 = [-C1 / 2, C4 + (D - D1) / 2];
      var p10 = [-(C1 / 2 - D2), C4 + (D - D1) / 2];
      var p11 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p12 = [-A3 / 2, C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p13 = [A3 / 2, C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p14 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + (D1 - A3) / 2];
      var p15 = [(C1 / 2 - D2), C4 + (D - D1) / 2];
      var p16 = [C1 / 2, C4 + (D - D1) / 2];
      var p17 = [-C1 / 2, C4 + (D - D1) / 2 + D1];
      var p18 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + D1];
      var p19 = [-(C1 / 2 - D2), C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p20 = [-A3 / 2, C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p21 = [A3 / 2, C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p22 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + D1 - (D1 - A3) / 2];
      var p23 = [(C1 / 2 - D2), C4 + (D - D1) / 2 + D1];
      var p24 = [C1 / 2, C4 + (D - D1) / 2 + D1];
      var p25 = [-C / 2, C4 + D];
      var p26 = [-C2 / 2, C4 + D];
      var p27 = [C2 / 2, C4 + D];
      var p28 = [C / 2, C4 + D];
      var p29 = [-A / 2, B - A1 - A2];
      var p30 = [-A3 / 2, B - A1 - A2];
      var p31 = [A3 / 2, B - A1 - A2];
      var p32 = [A / 2, B - A1 - A2];
      var p33 = [-A / 2, B - A1];
      var p34 = [A / 2, B - A1];
      var p35 = [-C2 / 2, B];
      var p36 = [C2 / 2, B];
  
      // cap
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]],
        p4, p3, p12, p11, p10, p9, p17, p18, p19, p20, p30, p29, p33, p34, p32, p31, p21, p22, p23, p24, p16, p15, p14, p13, p4,
        [p2[0], p4[1]], p7, p8, p28, p27, p36, p35, p26, p25, p5, p6], layer, datatype);
  
      // squid 
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [-A3 / 2 - (C2 - A3) / 4 + h_height, C4 + D + A4], Math.PI / 2, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_9");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_10 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const A1 = 89;
      const A2 = 48;
      const A3 = 88;
      const A4 = 8;
      const B1 = 64;
      const B2 = 466;
      const B3 = 530;
      const C1 = 295;
      const C2 = 77;
      const D1 = 36;
      const D2 = 24;
      const D3 = 4;
      const D4 = 20;
      const D5 = 56;
  
      const E = 12;
      const E1 = 6;
      const F = 4.5;
      const F1 = 2;
      const F2 = 1;
      const G = 16.8;
      const G1 = 0.2;
      const G2 = 0.12;
      const G3 = 20;
      const G4 = 8.75;
      const G5 = 1.191;
  
      // cap
      var p1 = [-B1 / 2, 0];
      var p2 = [B1 / 2, 0];
      var p3 = [-D2 / 2, (B1 - D2) / 2];
      var p4 = [D2 / 2, (B1 - D2) / 2];
      var p5 = [-B3 / 2, C2];
      var p6 = [-B1 / 2, C2];
      var p7 = [B1 / 2, C2];
      var p8 = [B3 / 2, C2];
      var p9 = [-B2 / 2, C2 + (A3 - A2) / 2];
      var p10 = [-(B2 / 2 - A4), C2 + (A3 - A2) / 2];
      var p11 = [-(B2 / 2 - A4), C2 + (A3 - D2) / 2];
      var p12 = [-D2 / 2, C2 + (A3 - D2) / 2];
      var p13 = [D2 / 2, C2 + (A3 - D2) / 2];
      var p14 = [B2 / 2 - A4, C2 + (A3 - D2) / 2];
      var p15 = [B2 / 2 - A4, C2 + (A3 - A2) / 2];
      var p16 = [B2 / 2, C2 + (A3 - A2) / 2];
      var p17 = [-B2 / 2, C2 + (A3 + A2) / 2];
      var p18 = [-(B2 / 2 - A4), C2 + (A3 + A2) / 2];
      var p19 = [-(B2 / 2 - A4), C2 + (A3 + D2) / 2];
      var p20 = [-D2 / 2, C2 + (A3 + D2) / 2];
      var p21 = [D2 / 2, C2 + (A3 + D2) / 2];
      var p22 = [B2 / 2 - A4, C2 + (A3 + D2) / 2];
      var p23 = [B2 / 2 - A4, C2 + (A3 + A2) / 2];
      var p24 = [B2 / 2, C2 + (A3 + A2) / 2];
      var p25 = [-B3 / 2, C2 + A3];
      var p26 = [-B1 / 2, C2 + A3];
      var p27 = [B1 / 2, C2 + A3];
      var p28 = [B3 / 2, C2 + A3];
      var p29 = [-D1 / 2, C1 - D3 - D4];
      var p30 = [-D2 / 2, C1 - D3 - D4];
      var p31 = [D2 / 2, C1 - D3 - D4];
      var p32 = [D1 / 2, C1 - D3 - D4];
      var p33 = [-D1 / 2, C1 - D3];
      var p34 = [D1 / 2, C1 - D3];
      var p35 = [-B1 / 2, C1];
      var p36 = [B1 / 2, C1];
  
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]], p4, p3, p12, p11, p10, p9, p17, p18, p19, p20, p30, p29,
        p33, p34, p32, p31, p21, p22, p23, p24, p16, p15, p14, p13, p4,
        [p2[0], p4[1]], p7, p8, p28, p27, p36, p35, p26, p25, p5, p6], layer, datatype);
  
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [-B1 / 2 + (B1 - D2) / 4 + h_height, C2 + A3 + D5], Math.PI / 2, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_10");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_11 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const A = 60;
      const A1 = 26;
      const A2 = 66;
      const A3 = 3;
      const A4 = 36.25;
      const A5 = 20;
      const B = 228.5;
      const B1 = 30;
      const C = 416.2;
      const C1 = 380.2;
      const C2 = 60;
      const C3 = 27.5;
      const C4 = 22.5;
  
      const D = 2;
      const D1 = 1;
      const D2 = 0.2;
      const E = 16.8;
      const E1 = 1.191;
      const F = 20;
      const F1 = 0.12;
      const F2 = 8.75;
      const G = 12;
      const G1 = 4.5;
      const G2 = 6;
  
      // cap 
      var p1 = [-C2 / 2, 0];
      var p2 = [C2 / 2, 0];
      var p3 = [-(A1 / 2 - A3), C4];
      var p4 = [A1 / 2 - A3, C4];
      var p5 = [-C / 2, B1];
      var p6 = [-C2 / 2, B1];
      var p7 = [C2 / 2, B1];
      var p8 = [C / 2, B1];
      var p9 = [-C1 / 2, B1 + (A - A5) / 2];
      var p10 = [-(A1 / 2 - A3), B1 + (A - A5) / 2];
      var p11 = [A1 / 2 - A3, B1 + (A - A5) / 2];
      var p12 = [C1 / 2, B1 + (A - A5) / 2];
      var p13 = [-C1 / 2, B1 + (A + A5) / 2];
      var p14 = [-(A1 / 2 - A3), B1 + (A + A5) / 2];
      var p15 = [A1 / 2 - A3, B1 + (A + A5) / 2];
      var p16 = [C1 / 2, B1 + (A + A5) / 2];
      var p17 = [-C / 2, B1 + A];
      var p18 = [-C2 / 2, B1 + A];
      var p19 = [C2 / 2, B1 + A];
      var p20 = [C / 2, B1 + A];
      var p21 = [-C2 / 2, B - A2];
      var p22 = [-A1 / 2, B - A2];
      var p23 = [A1 / 2, B - A2];
      var p24 = [C2 / 2, B - A2];
      var p25 = [-(A1 / 2 - A3), B - A3];
      var p26 = [A1 / 2 - A3, B - A3];
      var p27 = [-A1 / 2, B];
      var p28 = [A1 / 2, B];
  
      var cap = new QGdstk.Polygon([p1, p2, [p2[0], p4[1]], p4, p3, p10, p9, p13, p14, p25, p26, p15, p16, p12, p11, p4, [p2[0], p4[1]],
        p7, p8, p20, p19, p24, p23, p28, p27, p22, p21, p18, p17, p5, p6], layer, datatype);
  
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [C2 / 2 - (C2 - A5) / 4 + h_height, B1 + A + A4], Math.PI / 2, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_11");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_12 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var 
      const A = 416.2;
      const A1 = 380.2;
      const A2 = 60;
      const A3 = 22.5;
      const A4 = 27.5;
      const A5 = 30;
      const B = 239.3;
      const B1 = 20;
      const B2 = 66.9;
      const C = 60;
      const C1 = 34;
      const C2 = 3;
      const C3 = 15.5;
  
      const D = 16.8;
      const D1 = 0.12;
      const D2 = 8.75;
      const D3 = 1.19;
      const D4 = 20;
      const E = 0.2;
      const E1 = 1;
      const E2 = 2;
      const E3 = 4.5;
      const E4 = 6;
      const E5 = 12;
  
      // cap
      var p1 = [-A2 / 2, 0];
      var p2 = [-A2 / 2, A5];
      var p3 = [-A / 2, A5];
      var p4 = [-A / 2, A5 + C];
      var p5 = [-A2 / 2, A5 + C];
      var p6 = [-A2 / 2, B - C3];
      var p7 = [-C1 / 2, B - C3];
      var p8 = [-C1 / 2, B];
      var p9 = [C1 / 2, B];
      var p10 = [C1 / 2, B - C3];
      var p11 = [C / 2, B - C3];
      var p12 = [C / 2, A5 + C];
      var p13 = [A / 2, A5 + C];
      var p14 = [A / 2, A5];
      var p15 = [A2 / 2, A5];
      var p16 = [A2 / 2, 0];
      var p17 = [-B1 / 2, A3];
      var p18 = [-B1 / 2, A3 + A4];
      var p19 = [-A1 / 2, A3 + A4];
      var p20 = [-A1 / 2, A5 + (C + B1) / 2];
      var p21 = [-B1 / 2, A5 + (C + B1) / 2];
      var p22 = [-B1 / 2, B - C2];
      var p23 = [B1 / 2, B - C2];
      var p24 = [B1 / 2, A5 + (C + B1) / 2];
      var p25 = [A1 / 2, A5 + (C + B1) / 2];
      var p26 = [A1 / 2, A3 + A4];
      var p27 = [B1 / 2, A3 + A4];
      var p28 = [B1 / 2, A3];
  
  
      var cap = new QGdstk.Polygon([p1, p16, [p16[0], p28[1]],
        p28, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27, p28, [p16[0], p28[1]],
        p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4, p3, p2], layer, datatype);
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [A2 / 2 - (A2 - B1) / 4 + h_height, A5 + C + B2], Math.PI / 2, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_12");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_13 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const A = 21;
      const A1 = 20;
      const A2 = 60;
      const B = 1246.8;
      const B1 = 578.3;
      const B2 = 570;
  
      const C = 6;
      const C1 = 7.96;
      const C2 = 6.86;
      const C3 = 7.1;
      const D = 18;
      const D1 = 6;
      const D2 = 0.2;
      const D3 = 6;
  
      // points
      var p1 = [-A2 / 2, 0];
      var p2 = [A2 / 2, 0];
      var p3 = [A2 / 2, (B - A2) / 2];
      var p4 = [B / 2, (B - A2) / 2];
      var p5 = [B / 2, (B + A2) / 2];
      var p6 = [A2 / 2, (B + A2) / 2];
      var p7 = [A2 / 2, B];
      var p8 = [-A2 / 2, B];
      var p9 = [-A2 / 2, (B + A2) / 2];
      var p10 = [-B / 2, (B + A2) / 2];
      var p11 = [-B / 2, (B - A2) / 2];
      var p12 = [-A2 / 2, (B - A2) / 2];
      var p13 = [-A1 / 2, A];
      var p14 = [A1 / 2, A];
      var p15 = [A1 / 2, A + B2];
      var p16 = [B / 2 - A - B2, (B - A1) / 2];
      var p17 = [B / 2 - A, (B - A1) / 2];
      var p18 = [B / 2 - A, (B + A1) / 2];
      var p19 = [B / 2 - A - B1, (B + A1) / 2];
      var p20 = [-A1 / 2, A + B1];
  
      // cell
      var outter = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12]);
      var hollow_below = new QGdstk.Polygon([p13, p14, p15, p16, p17, p18, p19, p20]);
      var hollow_upper = hollow_below.copy();
      hollow_upper.mirror(p6, p12);
      var cap = QGdstk.boolean(outter, [hollow_upper, hollow_below], 'not', 1e-3, layer, datatype);
  
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var trans_to = [Math.sin(Math.PI / 4) * h_height, B / 2 - Math.sin(Math.PI / 4) * h_height];
      var squid_ref = new QGdstk.Reference(squid, trans_to, Math.PI / 4, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_13");
      cell.add(cap)
      cell.add(squid_ref.get_polygons());
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_14 = function (layer = default_layer_flip_l1, datatype = 0) {
      // var
      const A = 60;
      const A1 = 20;
      const B = 1246.8;
      const B1 = 570;
      const B2 = 578.3;
      const C = 21;
      const C1 = 567.78;
      const C2 = 572.24;
  
      const D = 2.05;
      const D1 = 3.03;
      const D2 = 30;
      const D3 = 38;
  
      const E = 6;
      const E1 = 7.96;
      const E2 = 6.68;
      const E3 = 7.1;
      const F = 18;
      const F1 = 6;
      const F2 = 0.2;
      const F3 = 6;
  
      // points
      var p1 = [-A / 2, 0];
      var p2 = [A / 2, 0];
      var p3 = [A / 2, (B - A) / 2];
      var p4 = [B / 2, (B - A) / 2];
      var p5 = [B / 2, (B + A) / 2];
      var p6 = [A / 2, (B + A) / 2];
      var p7 = [A / 2, B];
      var p8 = [-A / 2, B];
      var p9 = [-A / 2, (B + A) / 2];
      var p10 = [-B / 2, (B + A) / 2];
      var p11 = [-B / 2, (B - A) / 2];
      var p12 = [-A / 2, (B - A) / 2];
      var p13 = [-A1 / 2, C];
      var p14 = [A1 / 2, C];
      var p15 = [A1 / 2, C + B1];
      var p16 = [B / 2 - C - B1, (B - A1) / 2];
      var p17 = [B / 2 - C, (B - A1) / 2];
      var p18 = [B / 2 - C, (B + A1) / 2];
      var p19 = [B / 2 - C - B2, (B + A1) / 2];
      var p20 = [-A1 / 2, C + B2];
  
      // cap
      var outter = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12]);
      var hollow_below = new QGdstk.Polygon([p13, p14, p15, p16, p17, p18, p19, p20]);
      var hollow_upper = hollow_below.copy();
      hollow_upper.mirror(p6, p12);
  
      var hollow_below_hollow = new QGdstk.Polygon([[-D3 / 2, 0], [D3 / 2, 0], [D3 / 2, -A], [-D3 / 2, -A]]);
      var anchor = [(p19[0] + p20[0]) / 2, (p19[1] + p20[1]) / 2];
      var hollow_below_hollow_trans_to = [anchor[0] + D1 / Math.sqrt(2), anchor[1] - D1 / Math.sqrt(2)];
      hollow_below_hollow.transform(1, false, Math.PI / 4, hollow_below_hollow_trans_to, null)
      var hollow_below = QGdstk.boolean(hollow_below, hollow_below_hollow, 'not')[0];
  
      var outter_hollow = new QGdstk.Polygon([[-D2 / 2, 0], [D2 / 2, 0], [D2 / 2, -A], [-D2 / 2, -A]]);
      var outter_hollow_trans_to = [anchor[0] + (D1 + D) / Math.sqrt(2), anchor[1] - (D1 + D) / Math.sqrt(2)];
      outter_hollow.transform(1, false, Math.PI / 4, outter_hollow_trans_to, null)
  
      var cap = QGdstk.boolean(outter, [hollow_upper, hollow_below, outter_hollow], 'not', 1e-3, layer, datatype);
  
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var h_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var trans_to = [Math.sin(Math.PI / 4) * h_height, B / 2 - Math.sin(Math.PI / 4) * h_height];
      var squid_ref = new QGdstk.Reference(squid, trans_to, Math.PI / 4, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Cap1_Qubit_14");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_1 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2,
      A = 300,
      A1 = 97.5,
      A2 = 285,
      A3 = 5,
      A4 = 10,
      A5 = 25,
      B = 120,
      B1 = 132.25
    ) {
      // check
      this.assert(A >= 200 && A <= 600);
      this.assert(A1 >= 60 && A1 <= 200);
      this.assert(A2 >= 150 && A2 <= 550);
      this.assert(A3 >= 1 && A3 <= 6);
      this.assert(A4 >= 3 && A4 <= 12);
      this.assert(A5 >= 20 && A5 <= 50);
      this.assert(B >= 40 && B <= 300);
      this.assert(B1 >= 60 && B1 <= 300);
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A5, null,
          tolerance, false, true, layer, datatype);
        path.segment([-A, 0], null, null, true);
        path.turn(A5, Math.PI / 2);
        path.segment([0, -A1], null, null, true);
        path.turn(A5, Math.PI / 2);
        for (let i = 0; i < 5; i++) {
          path.segment([A2, 0], null, null, true);
          path.turn(A5, -Math.PI);
          path.segment([-A2, 0], null, null, true);
          path.turn(A5, Math.PI);
        }
        path.segment([B1, 0], null, null, true);
        path.turn(A5, -Math.PI / 2);
        path.segment([0, -B], null, null, true);
        return path;
      }
  
      // path
      var outter_path = make_path(2 * A3 + A4);
      var inner_path = make_path(A4);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
      // label
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [-(A - B1 - A5), -(2 * A5 + A1 + 20 * A5 + A5 + B)],
        's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_1");
      cell.add(resonator);
      cell.add(convex_polygon);
      cell.add([O1, O2]);
      cell.add(O);
      let length = cell.area() / (2 * A3);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_2 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2,
      A = 330,
      A1 = 125,
      A2 = 220,
      A3 = 25,
      B = 767.5,
      B1 = 10,
      B2 = 5,
      C = 189.62
    ) {
      // check
      this.assert(A >= 200 && A <= 600);
      this.assert(A1 >= 60 && A1 <= 200);
      this.assert(A2 >= 150 && A2 <= 550);
      this.assert(A3 >= 20 && A3 <= 50);
      this.assert(B >= 600 && B <= 900);
      this.assert(B1 >= 3 && B1 <= 12);
      this.assert(B2 >= 1 && B2 <= 6);
      this.assert(C >= 120 && C <= 500);
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A3, null,
          tolerance, false, true, layer, datatype);
        path.arc(A3, -Math.PI / 2, -Math.PI - Math.PI / 2);
        path.segment([C, 0], null, null, true);
        for (let i = 0; i < 4; i++) {
          path.turn(A3, Math.PI);
          path.segment([-A2, 0], null, null, true);
          path.turn(A3, -Math.PI);
          path.segment([A2, 0], null, null, true);
        }
        path.turn(A3, Math.PI);
        path.segment([-A2, 0], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([0, A1], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([A, 0], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([0, -B], null, null, true);
        return path;
      }
  
      var outter_path = make_path(B1 + 2 * B2);
      var inner_path = make_path(B1);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
      // label
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [C - A2 + A + A3, 21 * A3 + A1 - B]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_2");
      cell.add(resonator);
      cell.add(convex_polygon);
      cell.add([O1, O2]);
      cell.add(O)
      let length = cell.area() / (2 * B2);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_3 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2,
      A = 300,
      A1 = 97.5,
      A2 = 285,
      A3 = 5,
      A4 = 10,
      A5 = 25,
      B = 120,
      B1 = 132.25
    ) {
      // check
      this.assert(A >= 200 && A <= 600);
      this.assert(A1 >= 60 && A1 <= 200);
      this.assert(A2 >= 150 && A2 <= 550);
      this.assert(A3 >= 1 && A3 <= 6);
      this.assert(A4 >= 3 && A4 <= 12);
      this.assert(A5 >= 20 && A5 <= 50);
      this.assert(B >= 40 && B <= 300);
      this.assert(B1 >= 60 && B1 <= 300);
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A5, null,
          tolerance, false, true, layer, datatype);
        path.segment([-A, 0], null, null, true);
        path.turn(A5, Math.PI / 2);
        path.segment([0, -A1], null, null, true);
        path.turn(A5, Math.PI / 2);
        for (let i = 0; i < 5; i++) {
          path.segment([A2, 0], null, null, true);
          path.turn(A5, -Math.PI);
          path.segment([-A2, 0], null, null, true);
          path.turn(A5, Math.PI);
        }
        path.segment([B1, 0], null, null, true);
        path.turn(A5, -Math.PI / 2);
        path.segment([0, -B], null, null, true);
  
        return path;
      }
  
      // path
      var outter_path = make_path(2 * A3 + A4);
      var inner_path = make_path(A4);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
      // Cap1_Qubit_Resonator_1
      var trans_to = [-(A - B1 - A5), -(2 * A5 + A1 + 20 * A5 + A5 + B)];
      A = 158; A1 = 10; A2 = 85.5; A3 = 20; B = 40; B1 = 5;
      var p1 = [-A / 2, -A2];
      var p2 = [-A / 2, 0];
      var p3 = [-A1 / 2, 0];
      var p4 = [-A1 / 2, -B1];
      var p5 = [-(A / 2 - B1), -B1];
      var p6 = [-(A / 2 - B1), -(A2 - B1)];
      var p7 = [-(A / 2 - B + B1), -(A2 - B1)];
      var p8 = [-(A / 2 - B + B1), -(A3 - B1)];
      var p9 = [(A / 2 - B + B1), -(A3 - B1)];
      var p10 = [(A / 2 - B + B1), -(A2 - B1)];
      var p11 = [(A / 2 - B1), -(A2 - B1)];
      var p12 = [(A / 2 - B1), -B1];
      var p13 = [A1 / 2, -B1];
      var p14 = [A1 / 2, 0];
      var p15 = [A / 2, 0];
      var p16 = [A / 2, -A2];
      var p17 = [(A / 2 - B), -A2];
      var p18 = [(A / 2 - B), -A3];
      var p19 = [-(A / 2 - B), -A3];
      var p20 = [-(A / 2 - B), -A2];
  
      var poly = new QGdstk.Polygon([p3, p2, p1, p20, p19, p18, p17, p16, p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4], layer, datatype);
      poly.translate(trans_to);
  
      // label
      // var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, 0, 0);
      // var O2 = new QGdstk.Label("O2", [-(A - B1 - A5), -(2 * A5 + A1 + 20 * A5 + A5 + B)],
      //   's', 0, 1, false, 0, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      // O1.set_gds_property(1, kp_pro_str);
      // O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_3");
      var res = QGdstk.boolean(resonator, poly, "or", 1e-3, layer, datatype);
      cell.add(res);
      cell.add(convex_polygon)
      // cell.add([O1, O2]);
      cell.add(O);
      let length = cell.area() / (2 * A3);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_4 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2) {
      // var
      var A = 330;
      var A1 = 8;
      var A2 = 4;
      var A3 = 102;
      const A4 = 122.04;
      const A5 = 20;
      const A6 = 84.07;
      var B = 22.04;
      var B1 = 24.72;
      const B2 = Math.PI * 3 / 4;
      const B3 = 153.14;
      const B4 = 63.22;
      const B5 = 87;
      const B6 = 66;
      const B7 = 104;
      const B8 = 32;
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A5, null,
          tolerance, false, true, layer, datatype);
        path.commands([
          "l", A, 0,
          "a", A5, -Math.PI / 2,
          "l", 0, -A3,
          "a", A5, -Math.PI / 2,
          "l", -A4, 0
        ])
        for (let index = 0; index < 10; index++) {
          path.commands(
            [
              "a", A5, Math.PI,
              "l", A6, 0,
              "a", A5, -Math.PI,
              "l", -A6, 0
            ]
          )
        }
        path.commands([
          "a", A5, Math.PI,
          "l", B, 0,
          "a", A5, -Math.PI / 2,
          "l", 0, -B1,
          "a", A5, Math.PI / 4,
          "l", B3 / Math.sqrt(2), -B3 / Math.sqrt(2),
          "a", A5, -Math.PI / 4,
          "l", 0, -B4,
        ])
        return path;
      }
  
      var outter_path = make_path(A1);
      var inner_path = make_path(A2);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
  
      // Cap1_Qubit_Resonator_1
      var points = inner_path.points;
      var last_points = points[points.length - 1];
      A = B7; A1 = A2; A2 = B5; A3 = B5 - B6; B = (B7 - B8) / 2; B1 = 3;
      var p1 = [-A / 2, -A2];
      var p2 = [-A / 2, 0];
      var p3 = [-A1 / 2, 0];
      var p4 = [-A1 / 2, -B1];
      var p5 = [-(A / 2 - B1), -B1];
      var p6 = [-(A / 2 - B1), -(A2 - B1)];
      var p7 = [-(A / 2 - B + B1), -(A2 - B1)];
      var p8 = [-(A / 2 - B + B1), -(A3 - B1)];
      var p9 = [(A / 2 - B + B1), -(A3 - B1)];
      var p10 = [(A / 2 - B + B1), -(A2 - B1)];
      var p11 = [(A / 2 - B1), -(A2 - B1)];
      var p12 = [(A / 2 - B1), -B1];
      var p13 = [A1 / 2, -B1];
      var p14 = [A1 / 2, 0];
      var p15 = [A / 2, 0];
      var p16 = [A / 2, -A2];
      var p17 = [(A / 2 - B), -A2];
      var p18 = [(A / 2 - B), -A3];
      var p19 = [-(A / 2 - B), -A3];
      var p20 = [-(A / 2 - B), -A2];
  
      var poly = new QGdstk.Polygon([p3, p2, p1, p20, p19, p18, p17, p16, p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4], layer, datatype);
      poly.translate(last_points);
  
      var res = QGdstk.boolean(poly, resonator, "or", 1e-3, layer, datatype);
  
      // label
      // var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, 0, 0);
      // var O2 = new QGdstk.Label("O2", [C - A2 + A + A3, 21 * A3 + A1 - B]
      //   , 's', 0, 1, false, 0, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      // O1.set_gds_property(1, kp_pro_str);
      // O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_4");
      cell.add(res);
      cell.add(convex_polygon)
      // cell.add([O1, O2]);
      cell.add(O)
      let length = cell.area() / (2 * B2);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_5 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2) {
      // var
      var A = 330;
      var A1 = 8;
      var A2 = 4;
      var A3 = 102;
      const A4 = 29;
      const A5 = 20;
      const A6 = 98;
      var B = 29;
      var B1 = 24.72;
      const B2 = Math.PI * 3 / 4;
      const B3 = 153.14;
      const B4 = 52.42;
      const B5 = 36.5;
      const B6 = 18.5;
      const B7 = 112;
      const B8 = 40;
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A5, null,
          tolerance, false, true, layer, datatype);
        path.commands([
          "l", A, 0,
          "a", A5, -Math.PI / 2,
          "l", 0, -A3,
          "a", A5, Math.PI / 2,
          "l", A4, 0,
        ])
        for (let index = 0; index < 10; index++) {
          path.commands(
            [
              "a", A5, -Math.PI,
              "l", -A6, 0,
              "a", A5, Math.PI,
              "l", A6, 0
            ]
          )
        }
        path.commands([
          "a", A5, -Math.PI,
          "l", -B, 0,
          "a", A5, Math.PI / 2,
          "l", 0, -B1,
          "a", A5, Math.PI / 4,
          "l", B3 / Math.sqrt(2), -B3 / Math.sqrt(2),
          "a", A5, -Math.PI / 4,
          "l", 0, -B4,
        ])
        return path;
      }
  
      var outter_path = make_path(A1);
      var inner_path = make_path(A2);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
  
      // Cap1_Qubit_Resonator_1
      var points = inner_path.points;
      var last_points = points[points.length - 1];
      A = B7; A1 = A2; A2 = B5; A3 = B5 - B6 + 3; B = (B7 - B8) / 2; B1 = 3;
      var p1 = [-A / 2, -A2];
      var p2 = [-A / 2, 0];
      var p3 = [-A1 / 2, 0];
      var p4 = [-A1 / 2, -B1];
      var p5 = [-(A / 2 - B1), -B1];
      var p6 = [-(A / 2 - B1), -(A2 - B1)];
      var p7 = [-(A / 2 - B + B1), -(A2 - B1)];
      var p8 = [-(A / 2 - B + B1), -(A3 - B1)];
      var p9 = [(A / 2 - B + B1), -(A3 - B1)];
      var p10 = [(A / 2 - B + B1), -(A2 - B1)];
      var p11 = [(A / 2 - B1), -(A2 - B1)];
      var p12 = [(A / 2 - B1), -B1];
      var p13 = [A1 / 2, -B1];
      var p14 = [A1 / 2, 0];
      var p15 = [A / 2, 0];
      var p16 = [A / 2, -A2];
      var p17 = [(A / 2 - B), -A2];
      var p18 = [(A / 2 - B), -A3];
      var p19 = [-(A / 2 - B), -A3];
      var p20 = [-(A / 2 - B), -A2];
  
      var poly = new QGdstk.Polygon([p3, p2, p1, p20, p19, p18, p17, p16, p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4], layer, datatype);
      poly.translate(last_points);
  
      var res = QGdstk.boolean(poly, resonator, "or", 1e-3, layer, datatype);
  
      // label
      // var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, 0, 0);
      // var O2 = new QGdstk.Label("O2", [C - A2 + A + A3, 21 * A3 + A1 - B]
      //   , 's', 0, 1, false, 0, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      // O1.set_gds_property(1, kp_pro_str);
      // O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_5");
      cell.add(res);
      cell.add(convex_polygon);
      // cell.add([O1, O2]);
      cell.add(O)
      let length = cell.area() / (2 * B2);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
    PDKDevice.prototype.Resonator_FPDesign_6 = function (layer = default_layer_flip_l1, datatype = 0, tolerance = 1e-2,
      A = 330,
      A1 = 125,
      A2 = 220,
      A3 = 25,
      B = 767.5,
      B1 = 10,
      B2 = 5,
      C = 189.62
    ) {
      // check
      this.assert(A >= 200 && A <= 600);
      this.assert(A1 >= 60 && A1 <= 200);
      this.assert(A2 >= 150 && A2 <= 550);
      this.assert(A3 >= 20 && A3 <= 50);
      this.assert(B >= 600 && B <= 900);
      this.assert(B1 >= 3 && B1 <= 12);
      this.assert(B2 >= 1 && B2 <= 6);
      this.assert(C >= 120 && C <= 500);
  
      // path
      function make_path(width) {
        var path = new QGdstk.FlexPath([[0, 0]], width, 0, "round", 'flush', A3, null,
          tolerance, false, true, layer, datatype);
        path.arc(A3, -Math.PI / 2, -Math.PI - Math.PI / 2);
        path.segment([C, 0], null, null, true);
        for (let i = 0; i < 4; i++) {
          path.turn(A3, Math.PI);
          path.segment([-A2, 0], null, null, true);
          path.turn(A3, -Math.PI);
          path.segment([A2, 0], null, null, true);
        }
        path.turn(A3, Math.PI);
        path.segment([-A2, 0], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([0, A1], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([A, 0], null, null, true);
        path.turn(A3, -Math.PI / 2);
        path.segment([0, -B], null, null, true);
        return path;
      }
  
      var outter_path = make_path(B1 + 2 * B2);
      var inner_path = make_path(B1);
  
      var resonator = new QGdstk.boolean(outter_path, inner_path, 'not', 1e-3, layer, datatype);
  
      // Cap1_Qubit_Resonator_1
      var trans_to = [C - A2 + A + A3, 21 * A3 + A1 - B];
      A = 158; A1 = 10; A2 = 85.5; A3 = 20; B = 40; B1 = 5;
      var p1 = [-A / 2, -A2];
      var p2 = [-A / 2, 0];
      var p3 = [-A1 / 2, 0];
      var p4 = [-A1 / 2, -B1];
      var p5 = [-(A / 2 - B1), -B1];
      var p6 = [-(A / 2 - B1), -(A2 - B1)];
      var p7 = [-(A / 2 - B + B1), -(A2 - B1)];
      var p8 = [-(A / 2 - B + B1), -(A3 - B1)];
      var p9 = [(A / 2 - B + B1), -(A3 - B1)];
      var p10 = [(A / 2 - B + B1), -(A2 - B1)];
      var p11 = [(A / 2 - B1), -(A2 - B1)];
      var p12 = [(A / 2 - B1), -B1];
      var p13 = [A1 / 2, -B1];
      var p14 = [A1 / 2, 0];
      var p15 = [A / 2, 0];
      var p16 = [A / 2, -A2];
      var p17 = [(A / 2 - B), -A2];
      var p18 = [(A / 2 - B), -A3];
      var p19 = [-(A / 2 - B), -A3];
      var p20 = [-(A / 2 - B), -A2];
  
      var poly = new QGdstk.Polygon([p3, p2, p1, p20, p19, p18, p17, p16, p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4], layer, datatype);
      poly.translate(trans_to);
  
      var res = QGdstk.boolean(poly, resonator, "or", 1e-3, layer, datatype);
  
      // label
      // var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, 0, 0);
      // var O2 = new QGdstk.Label("O2", [C - A2 + A + A3, 21 * A3 + A1 - B]
      //   , 's', 0, 1, false, 0, 0);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      // O1.set_gds_property(1, kp_pro_str);
      // O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // aux layer
      var convex_polygon = outter_path.to_polygons()
      convex_polygon.forEach(p => {
        p.layer = aux_layer_drc_res
      });
  
      // cell
      var cell = new QGdstk.Cell("Resonator_FPDesign_6");
      cell.add(res);
      cell.add(convex_polygon)
      // cell.add([O1, O2]);
      cell.add(O)
      let length = cell.area() / (2 * B2);
      O.set_gds_property(4, length.toFixed(3))
      return cell;
    }
  
  
    PDKDevice.prototype.ChipFrame_1 = function (layer = default_layer_flip_l1, datatype = 0, A = 8000, A1 = 8000) {
      this.assert(A >= 4000 && A <= 40000);
      this.assert(A1 >= 4000 && A1 <= 40000);
      //var
      const A2 = 200;
      const B = 60;
      const B1 = 190.92;
      const B2 = 60;
      const C = 80;
      const C1 = 225;
      const C2 = 3;
      const C3 = 3;
      const C4 = 20;
      const D = 600;
      const D1 = 500;
      const D2 = 40;
  
      // points
      var p1 = [-A / 2, -A1 / 2];
      var p2 = [A / 2, -A1 / 2];
      var p3 = [A / 2, A1 / 2];
      var p4 = [-A / 2, A1 / 2];
      var p5 = [-A / 2 + A2, A1 / 2 - D];
      var p6 = [-A / 2 + D, A1 / 2 - D];
      var p7 = [-A / 2 + D, A1 / 2 - A2];
      var p8 = [A / 2 - D, A1 / 2 - A2];
      var p9 = [A / 2 - D, A1 / 2 - D];
      var p10 = [A / 2 - A2, A1 / 2 - D];
      var p11 = [A / 2 - A2, -A1 / 2 + D];
      var p12 = [A / 2 - D, -A1 / 2 + D];
      var p13 = [A / 2 - D, -A1 / 2 + A2];
      var p14 = [-A / 2 + D, -A1 / 2 + A2];
      var p15 = [-A / 2 + D, -A1 / 2 + D];
      var p16 = [-A / 2 + A2, -A1 / 2 + D];
  
      // outer_frame
      var frame_outter = new QGdstk.Polygon([p1, p2, p3, p4]);
      var frame_inner = new QGdstk.Polygon([p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16]);
      var outter_frame = QGdstk.boolean(frame_outter, frame_inner, 'not');
  
      // marker
      var p1 = [C2 / 2, C2 / 2]
      var p2 = [D1 / 2 - C1, C2 / 2]
      var p3 = [D1 / 2 - C1, D2 / 2]
      var p4 = [D1 / 2, D2 / 2]
      var p5 = [D1 / 2, D1 / 2]
      var p6 = [D2 / 2, D1 / 2]
      var p7 = [D2 / 2, D1 / 2 - C1]
      var p8 = [C2 / 2, D1 / 2 - C1]
  
      var hollow_right_upper = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      var cross = new QGdstk.cross([C / 2, C / 2], C4, C3);
      var hollow_right_upper = QGdstk.boolean(hollow_right_upper, cross, 'not')[0];
  
      var hollow_left_upper = hollow_right_upper.copy();
      hollow_left_upper.mirror([0, 0], [0, 1]);
  
      var hollow_right_lower = hollow_right_upper.copy();
      hollow_right_lower.mirror([0, 0], [1, 0]);
  
      var hollow_left_lower = hollow_left_upper.copy();
      hollow_left_lower.mirror([0, 0], [1, 0]);
  
      var arrow_curve = new QGdstk.Curve([0, 0]);
      arrow_curve.segment([B1 / Math.sqrt(2), -B1 / Math.sqrt(2)], true);
      arrow_curve.segment([B2, 0], true);
      arrow_curve.segment([0, B2], true);
      arrow_curve.segment([-B1 / Math.sqrt(2), B1 / Math.sqrt(2)], true);
      var arrow = new QGdstk.Polygon(arrow_curve.points());
      arrow.translate([-D1 / 2, D1 / 2 - B]);
  
      var hollow_left_upper = QGdstk.boolean(hollow_left_upper, arrow, 'not')[0];
  
      var marker_cell_1 = new QGdstk.Cell('ChipFrame_1_marker_lu');
      marker_cell_1.add([hollow_left_upper, hollow_right_upper, hollow_left_lower, hollow_right_lower]);
  
      var hollow_left_upper_mirror = hollow_left_upper.copy();
      hollow_left_upper_mirror.mirror([0, 0], [0, 1]);
      var hollow_right_upper_mirror = hollow_right_upper.copy();
      hollow_right_upper_mirror.mirror([0, 0], [0, 1]);
  
      var marker_cell_2 = new QGdstk.Cell('ChipFrame_1_marker_ru');
      marker_cell_2.add([hollow_left_upper_mirror, hollow_right_upper_mirror, hollow_left_lower, hollow_right_lower]);
  
      var ref_1 = new QGdstk.Reference(marker_cell_1, [-(A - D) / 2, (A1 - D) / 2], 0, 1, false,
        1, 1, null);
      var ref_2 = new QGdstk.Reference(marker_cell_1, [-(A - D) / 2, -(A1 - D) / 2], 0, 1, true,
        1, 1, null);
      var ref_3 = new QGdstk.Reference(marker_cell_2, [(A - D) / 2, (A1 - D) / 2], 0, 1, false,
        1, 1, null);
      var ref_4 = new QGdstk.Reference(marker_cell_2, [(A - D) / 2, -(A1 - D) / 2], 0, 1, true,
        1, 1, null);
      var marker_cell = new QGdstk.Cell("ChipFrame_1_Eline");
      marker_cell.add([ref_1, ref_2, ref_3, ref_4]);
  
      outter_frame = QGdstk.boolean(outter_frame, marker_cell.get_polygons(), 'not', 1e-3, layer, datatype);
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("ChipFrame_1");
      cell.add(outter_frame);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.ChipFrame_2 = function (layer = default_layer_flip_l1, datatype = 0) {
      const A = 16000;
      const A1 = 16000;
      var cell = this.ChipFrame_1(layer, datatype, A, A1);
      cell.name = "ChipFrame_2";
      return cell;
    }
  
    PDKDevice.prototype.ChipFrame_3 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0) {
      const A = 8000;
      const A1 = 200;
      const A2 = 6600;
      const A3 = 6600;
      const A4 = 490;
      const A5 = 778;
  
      const B = 480;
      const B1 = 211;
      const C = 151;
      const C1 = 260;
      const C2 = 18;
      const D = 350;
      const D1 = 80;
      const D2 = 9;
      const D3 = 20;
      const D4 = 80;
  
      const E = 200;
      const E1 = 80;
      const F = 90;
      const F1 = 20;
      const F2 = 9;
      const G = 20;
      const G1 = 5;
      const G2 = 2;
      const H = 10;
      const H1 = 40;
      const H2 = 45 / 180 * Math.PI;
      const H3 = 30;
      const H4 = 25;
      const H5 = 25;
      const H6 = 47.21;
  
      const J = 620;
      const J1 = 200;
      const J2 = 200;
      const J3 = 154;
      const J4 = 10;
      const J5 = 9;
      const J6 = 33;
      const J7 = 40;
      const J8 = 10;
      const J9 = 100;
      const J10 = 2;
      const J11 = 100;
      const J13 = 25;
      const J14 = 200;
      const J15 = 10;
      const J16 = 165;
      const J12 = 2 * J15 + 2 * J14 + J13;
  
      const K = 12;
      const K1 = 6;
      const K2 = 6;
      const K3 = 5;
      const K4 = 10;
      const K5 = 18;
      const K6 = 4.94;
      const K7 = 0.12;
      const K8 = 22;
  
      function make_frame() {
        function make_arrow() {
          // origin in arrow
          const dis_to_border = 1.25;
          var p1 = [-H6 + dis_to_border + (H1 - (H3 - H) / 2) / Math.sqrt(2), H6 - dis_to_border - (H + H1 + (H3 - H) / 2) / Math.sqrt(2)];
          var arrow_curve = new QGdstk.Curve([0, 0]);
          arrow_curve.segment(p1);
          arrow_curve.segment([((H3 - H) / 2) / Math.sqrt(2), ((H3 - H) / 2) / Math.sqrt(2)], true);
          arrow_curve.segment([-H1 / Math.sqrt(2), H1 / Math.sqrt(2)], true);
          arrow_curve.segment([H / Math.sqrt(2), H / Math.sqrt(2)], true);
          arrow_curve.segment([H1 / Math.sqrt(2), -H1 / Math.sqrt(2)], true);
          arrow_curve.segment([((H3 - H) / 2) / Math.sqrt(2), ((H3 - H) / 2) / Math.sqrt(2)], true);
          var arrow = new QGdstk.Polygon(arrow_curve.points());
          return arrow;
        }
        function make_marker() {
          // origin in cross center
          var cross = new QGdstk.cross([0, 0], G, G2);
          var rep = new QGdstk.Repetition(2, 2, [(G - G1), (G - G1)], null, null, null, null, null);
          var box = new QGdstk.Polygon([[-G / 2, -G / 2], [-G / 2 + G1, -G / 2], [-G / 2 + G1, -G / 2 + G1], [-G / 2, -G / 2 + G1]]);
          box.repetition = rep;
  
          var cell_cross = new QGdstk.Cell("ChipFrame_3_marker_cross");
          cell_cross.add(cross);
          // cell_cross.add(box.apply_repetition());
          cell_cross.add(box);
  
          var ref = new QGdstk.Reference(cell_cross, [-E1 / 2, -E1 / 2], 0, 1, false,
            2, 2, [E1, E1])
  
          var p2 = [-E / 2, -F1 / 2];
          var p3 = [-E / 2, F1 / 2];
          var p4 = [-F1 / 2, F1 / 2];
          var p5 = [-F1 / 2, (F1 / 2 - F2)];
          var p6 = [-(F1 / 2 - F2), (F1 / 2 - F2)];
          var p7 = [-(F1 / 2 - F2), F1 / 2];
          var p8 = [-F1 / 2, E / 2];
          var p9 = [F1 / 2, E / 2];
          var p10 = [F1 / 2, F1 / 2];
          var p11 = [(F1 / 2 - F2), F1 / 2];
          var p12 = [(F1 / 2 - F2), (F1 / 2 - F2)];
          var p13 = [F1 / 2, (F1 / 2 - F2)];
          var p14 = [E / 2, F1 / 2];
          var p15 = [E / 2, -F1 / 2];
          var p16 = [F1 / 2, -F1 / 2];
          var p17 = [F1 / 2, -(F1 / 2 - F2)];
          var p18 = [(F1 / 2 - F2), -(F1 / 2 - F2)];
          var p19 = [(F1 / 2 - F2), -F1 / 2];
          var p20 = [F1 / 2, -E / 2];
          var p21 = [-F1 / 2, -E / 2];
          var p22 = [-F1 / 2, -F1 / 2];
          var p23 = [-(F1 / 2 - F2), -F1 / 2];
          var p24 = [-(F1 / 2 - F2), -(F1 / 2 - F2)];
          var p25 = [-F1 / 2, -(F1 / 2 - F2)];
  
          var polygon = new QGdstk.Polygon([p2, p3, p4, p5, p6, p7, p4, p8, p9, p10, p11, p12, p13, p10, p14, p15, p16, p17, p18, p19, p16, p20, p21, p22, p23, p24, p25, p22]);
          var cell = new QGdstk.Cell("ChipFrame_3_marker_big_cross");
          cell.add(ref.get_polygons());
          cell.add(polygon);
          return cell;
  
        }
  
        var p1 = [-A / 2, -A / 2];
        var p2 = [-A / 2, A / 2];
        var p3 = [A / 2, A / 2];
        var p4 = [A / 2, -A / 2];
        var p5 = [A / 2 - A1, -A3 / 2];
        var p6 = [A / 2 - A1, A3 / 2];
        var p7 = [A2 / 2, A / 2 - A1];
        var p8 = [-A2 / 2, A / 2 - A1];
        var p9 = [-(A / 2 - A1), A3 / 2];
        var p10 = [-(A / 2 - A1), -A3 / 2];
        var p11 = [-A2 / 2, -(A / 2 - A1)];
        var p12 = [A2 / 2, -(A / 2 - A1)];
  
        var frame_outter = new QGdstk.Polygon([p1, p2, p3, [p4[0], p5[1]], p5, p6, p7, p8, p9, p10, p11, p12, p5, [p4[0], p5[1]], p4]);
  
        var marker_cell = make_marker();
        var ref = new QGdstk.Reference(marker_cell, [-(A - E) / 2, -(A - E) / 2], 0, 1, false,
          2, 2, [A - E, A - E]);
  
        var arrow_lu = make_arrow();//.translate([-(F + F1 / 2 - H6), F + F1 / 2 - H6]);
        arrow_lu.translate([-(A / 2 - H6), (A / 2 - H6)])
        var arrow_ll = arrow_lu.copy();
        arrow_ll.mirror([0, 0], [1, 0]);
        var arrow_ru = arrow_lu.copy();
        arrow_ru.mirror([0, 0], [0, 1]);
        var arrow_rl = arrow_ru.copy();
        arrow_rl.mirror([0, 0], [1, 0]);
  
        var marker = new QGdstk.Cell("ChipFrame_3_markers");
        marker.add(ref.get_polygons());
        marker.add([arrow_lu, arrow_ll, arrow_ru, arrow_rl]);
  
        return QGdstk.boolean(frame_outter, marker.get_polygons(), 'not', 1e-3, layer, datatype);
      }
  
      function make_JJTest() {
        function make_box_array() {
          var box = new QGdstk.Polygon([[0, 0], [D2, 0], [D2, D2], [0, D2]], layer, datatype);
          var cell_box = new QGdstk.Cell("ChipFrame_3_JJTest_box");
          cell_box.add(box);
          var box_ref = new QGdstk.Reference(cell_box, [-D3 / 2, -D3 / 2], 0, 1, false, 2,
            2, [D3 - D2, D3 - D2]);
          var cell_box_array = new QGdstk.Cell("ChipFrame_3_JJTest_box_array");
          cell_box_array.add(box_ref.get_polygons());
          var ref_box_array = new QGdstk.Reference(cell_box_array, [-D1 / 2, -(D4 + C2) / 2], 0, 1, false,
            2, 2, [D1, D4]);
          var cell_box_arrays = new QGdstk.Cell("ChipFrame_3_JJTest_box_arrays");
          cell_box_arrays.add(ref_box_array.get_polygons());
          return cell_box_arrays;
        }
        function make_squid() {
          var box_upper = new QGdstk.Polygon([[0, 0], [K1, 0], [K1, K2], [0, K2]]);
          var box_ll = box_upper.copy();
          var box_rl = box_upper.copy();
          box_upper.translate([-K1 / 2, -K2]);
          box_ll.translate([-K / 2 - K1, -K5]);
          box_rl.translate([K / 2, -K5]);
  
          var sc_l = new QGdstk.Polygon([[-K7 / 2, 0], [K7 / 2, 0], [K7 / 2, K4], [-K7 / 2, K4]]);
          var sc_r = sc_l.copy();
          var sc_upper = new QGdstk.Polygon([[-K8 / 2, -K7], [K8 / 2, -K7], [K8 / 2, 0], [-K8 / 2, 0]]);
          sc_l.translate([-(K + K1) / 2, -(K5 - K3)]);
          sc_r.translate([(K + K1) / 2, -(K5 - K3)]);
          sc_upper.translate([0, -K6]);
  
          var squid = QGdstk.boolean([box_upper, box_ll, box_rl], [sc_l, sc_r, sc_upper], 'or', 1e-3, layer_squid, datatype_squid);
  
          var cell = new QGdstk.Cell("ChipFrame_3_JJTest_squid");
          cell.add(squid);
          return cell;
        }
  
        var p1 = [-D / 2, (B1 / 2 + C / 2 + C2)];
        var p2 = [-D / 2, -(B1 / 2 + C / 2 + C2)];
        var p3 = [D / 2, -(B1 / 2 + C / 2 + C2)];
        var p4 = [D / 2, (B1 / 2 + C / 2 + C2)];
        var p5 = [-C1 / 2, (B1 + C) / 2];
        var p6 = [-C1 / 2, (B1 - C) / 2];
        var p7 = [C1 / 2, (B1 - C) / 2];
        var p8 = [C1 / 2, (B1 + C) / 2];
        var p9 = [-C1 / 2, -(B1 - C) / 2];
        var p10 = [-C1 / 2, -(B1 + C) / 2];
        var p11 = [C1 / 2, -(B1 + C) / 2];
        var p12 = [C1 / 2, -(B1 - C) / 2];
  
        var frame_outter = new QGdstk.Polygon([p1, [p1[0], p5[1]], p5, p8, p7, p6, p5, [p1[0], p5[1]], p2, p3, [p3[0], p11[1]], p11, p10, p9, p12, p11, [p3[0], p11[1]], p4],
          layer, datatype);
  
        var box_array = make_box_array();
        var ref_box = new QGdstk.Reference(box_array, [0, -(B1 / 2 + C / 2)], 0, 1, false,
          1, 2, [0, B1 + C + C2]);
  
        var squid = make_squid();
        var ref_squid = new QGdstk.Reference(squid, [0, -(B1 / 2 + C / 2)], 0, 1, false,
          1, 2, [0, B1 + C + C2]);
  
        var cell = new QGdstk.Cell("ChipFrame_3_JJTest");
        cell.add([frame_outter]);
        cell.add(ref_box.get_polygons());
        cell.add(ref_squid.get_polygons());
        return cell;
      }
  
      function make_Test_2() {
        function make_cross() {
          var cross = QGdstk.cross([0, 0], J8, J10);
          var cell_cross = new QGdstk.Cell("ChipFrame_3_test2_cross");
          cell_cross.add(cross);
          var ref_cross = new QGdstk.Reference(cell_cross, [-(J + J11 - J8) / 2, (J12 - J15 - J3 - J4 - J5 - J9 + J8 / 2)], 0, 1, false,
            2, 2, [J11 - J8, J9 - J8]);
          var cell_crosses = new QGdstk.Cell("ChipFrame_3_test2_crosses");
          cell_crosses.add(ref_cross.get_polygons());
          return cell_crosses;
        }
        var l1 = J14 + J15;
        var l2 = J1;
        var l3 = J14 - J16 - J4;
        var l4 = (J2 - J7) / 2 - J4;
        var l5 = J6;
        var l6 = J7 + 2 * J4;
        var l7 = (J2 - J7) / 2;
        var l8 = J16 + J15;
        var l9 = J1 + J2 + J15;
        var l10 = J14 - J3 - J4;
  
        var path_outter = new QGdstk.Curve([0, 0]);
        path_outter.commands(['l', -J15, 0,
          'l', 0, l1,
          'l', -l2, 0,
          'l', 0, -l3,
          'l', -l4, 0,
          'l', 0, l5,
          'l', -l6, 0,
          'l', 0, -l5,
          'l', -l4, 0,
          'l', 0, l3,
          'l', -J1, 0,
          'l', 0, -J14,
          'l', J1, 0,
          'l', 0, J16,
          'l', l7, 0,
          'l', 0, l5,
          'l', J7, 0,
          'l', 0, -l5,
          'l', l7, 0,
          'l', 0, -l8,
          'l', -l9, 0,
          'l', 0, J12,
          'l', J, 0]);
  
        var path_inner = new QGdstk.Curve([-J + J15, J12 - J15]);
        path_inner.commands(['l', 0, -J14,
          'l', J1, 0,
          'l', 0, l10,
          'l', l4, 0,
          'l', 0, -l5,
          'l', l6, 0,
          'l', 0, l5,
          'l', l4, 0,
          'l', 0, -l10,
          'l', J1, 0,
          'l', 0, J14,
          'l', -J1, 0,
          'l', 0, -J3,
          'l', -l7, 0,
          'l', 0, -l5,
          'l', -J7, 0,
          'l', 0, l5,
          'l', -l7, 0,
          'l', 0, J3]);
  
        var frame_outter = new QGdstk.Polygon(path_outter.points());
        var hollow = new QGdstk.Polygon(path_inner.points());
        var frame = QGdstk.boolean(frame_outter, hollow, 'not');
  
        var cross = make_cross();
        var frame = QGdstk.boolean(frame, cross.get_polygons(), 'not', 1e-3, layer, datatype);
  
        var cell = new QGdstk.Cell("ChipFrame_3_JJTest_Test2");
        cell.add(frame);
        return cell;
  
      }
  
      var jj_test = make_JJTest();
      var ref_jj_test_lu = new QGdstk.Reference(jj_test, [-A2 / 2, A3 / 2], 0, 1, false, 1, 1, null);
      var ref_jj_test_ru = new QGdstk.Reference(jj_test, [A2 / 2, A3 / 2], 0, 1, false, 1, 1, null);
      var ref_jj_test_ll = new QGdstk.Reference(jj_test, [-A2 / 2, -A3 / 2], 0, 1, false, 1, 1, null);
  
      var test_2 = make_Test_2();
      var ref_test_2 = new QGdstk.Reference(test_2, [A / 2 - A4, -(A / 2 - A5)], 0, 1, false, 1, 1, null);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("ChipFrame_3");
      cell.add(make_frame());
      cell.add(ref_jj_test_lu.get_polygons())
      cell.add(ref_jj_test_ru.get_polygons())
      cell.add(ref_jj_test_ll.get_polygons());
      cell.add(ref_test_2.get_polygons());
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Indium_1 = function (layer_indium = default_layer_base_l5, datatype_indium = 0, layer_indium_flip = default_layer_flip_l5, datatype_indium_flip = 0, layer_tin = default_layer_base_l2, datatype_tin = 0, layer_tin_flip = default_layer_flip_l2, datatype_tin_flip = 0,
      A = 26, A1 = 16, tolerance = 0.01) {
      this.assert(A >= 20 && A <= 80);
      this.assert(A1 >= 10 && A1 <= 60);
      var TiN = QGdstk.ellipse([0, 0], A / 2, null, 0, 0, tolerance, layer_tin, datatype_tin);
      var TiN_Flip = QGdstk.ellipse([0, 0], A / 2, null, 0, 0, tolerance, layer_tin_flip, datatype_tin_flip);
      var Indium = QGdstk.ellipse([0, 0], A1 / 2, null, 0, 0, tolerance, layer_indium, datatype_indium);
      var Indium_Flip = QGdstk.ellipse([0, 0], A1 / 2, null, 0, 0, tolerance, layer_indium_flip, datatype_indium_flip);
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Indium_1");
      cell.add([Indium, Indium_Flip, TiN, TiN_Flip]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Indium_2 = function (layer_indium = default_layer_base_l5, datatype_indium = 0, layer_indium_flip = default_layer_flip_l5, datatype_indium_flip = 0, layer_tin = default_layer_base_l2, datatype_tin = 0, layer_tin_flip = default_layer_flip_l2, datatype_tin_flip = 0, tolerance = 0.01) {
      const A = 26;
      const A1 = 20;
      var cell = this.Indium_1(layer_indium, datatype_indium, layer_indium_flip, datatype_indium_flip, layer_tin, datatype_tin, layer_tin_flip, datatype_tin_flip, A, A1, tolerance);
      cell.name = "Indium_2";
      return cell;
    }
  
    PDKDevice.prototype.Indium_3 = function (layer_indium = default_layer_base_l5, datatype_indium = 0, layer_indium_flip = default_layer_flip_l5, datatype_indium_flip = 0, layer_tin = default_layer_base_l2, datatype_tin = 0, layer_tin_flip = default_layer_flip_l2, datatype_tin_flip = 0, tolerance = 0.01) {
      const A = 50;
      const A1 = 36;
      var cell = this.Indium_1(layer_indium, datatype_indium, layer_indium_flip, datatype_indium_flip, layer_tin, datatype_tin, layer_tin_flip, datatype_tin_flip, A, A1, tolerance);
      cell.name = "Indium_3";
      return cell;
    }
  
    PDKDevice.prototype.Inlinepad_1 = function (layer = default_layer_flip_l1, datatype = 0, A = 30, A1 = 21, tolerance = 0.01) {
      this.assert(A >= 25 && A <= 60);
      this.assert(A1 >= 18 && A1 <= 40);
  
      const A2 = 10;
      const A3 = 5;
      const A4 = 4;
      const A5 = 2;
      const A6 = 1.22;
      // arc
      let a1 = Math.asin((A4 / 2 + A5 + A3) / (A + A3))
      let a2 = Math.asin((A4 / 2 + A3) / (A1 + A3))
      // curve
      let curve = new QGdstk.Curve([0, A4 / 2], tolerance)
      curve.vertical(A5, true)
      curve.horizontal(A6, true)
      curve.arc(A3, -Math.PI / 2, -a1)
      curve.arc(A, Math.PI - a1, -(Math.PI - a1))
      curve.arc(A3, a1, Math.PI / 2)
      curve.horizontal(-A6, true)
      curve.vertical(A5, true)
      curve.horizontal(A2, true)
      curve.arc(A3, Math.PI / 2, a2)
      curve.arc(A1, -(Math.PI - a2), Math.PI - a2)
      curve.arc(A3, -a2, -Math.PI / 2)
      // cell
      let spout = new QGdstk.Polygon(curve.points(), layer, datatype)
  
      var O = new QGdstk.Label("O", [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      let O1 = new QGdstk.Label('O1', [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      O1.set_gds_property(1, kp_pro_str);
  
      let cell = new QGdstk.Cell('Inlinepad_1')
      cell.add([spout, O1]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Inlinepad_2 = function (layer = default_layer_flip_l1, datatype = 0, A = 30, A1 = 21, tolerance = 0.01) {
      this.assert(A >= 25 && A <= 60);
      this.assert(A1 >= 18 && A1 <= 40);
  
      const A2 = 100;
      const A3 = 10;
      const A4 = 5;
      const A5 = 4;
      const A6 = 2;
      const A7 = 1.22;
  
      var a1 = Math.asin((A5 / 2 + A6 + A4) / (A + A4));
      var a2 = Math.asin((A5 / 2 + A4) / (A1 + A4));
  
      // curve
      var curve = new QGdstk.Curve([0, A5 / 2], tolerance)
      curve.vertical(A6, true)
      curve.horizontal(A7, true)
      curve.arc(A4, -Math.PI / 2, -a1)
      curve.arc(A, Math.PI - a1, Math.PI / 2)
      curve.horizontal(A2, true);
      curve.arc(A, Math.PI / 2, -Math.PI / 2);
      curve.horizontal(-A2, true);
      curve.arc(A, -Math.PI / 2, -Math.PI + a1)
      curve.arc(A4, a1, Math.PI / 2)
      curve.horizontal(-A7, true)
      curve.vertical(A6, true)
      curve.horizontal(A3, true)
      curve.arc(A4, Math.PI / 2, a2)
      curve.arc(A1, -(Math.PI - a2), -Math.PI / 2);
      curve.horizontal(A2, true);
      curve.arc(A1, -Math.PI / 2, Math.PI / 2);
      curve.horizontal(-A2, true);
      curve.arc(A1, Math.PI / 2, Math.PI - a2);
      curve.arc(A4, -a2, -Math.PI / 2)
      // curve.horizontal(-A3, true)
  
      // cell
      var poly = new QGdstk.Polygon(curve.points(), layer, datatype);
      let O1 = new QGdstk.Label('O1', [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Inlinepad_2");
      cell.add([poly, O1]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Inlinepad_3 = function (layer = default_layer_flip_l1, datatype = 0, A = 30, A1 = 21, tolerance = 0.01) {
      this.assert(A >= 25 && A <= 60);
      this.assert(A1 >= 18 && A1 <= 40);
  
      const A2 = 100;
      const A3 = 10;
      const A4 = 5;
      const A5 = 4;
      const A6 = 2;
      const A7 = 1;
  
      // curve
      var curve = new QGdstk.Curve([A5 / 2, 0], tolerance);
      curve.horizontal(A6, true);
      curve.vertical(A7, true);
      curve.arc(A4, Math.PI, Math.PI / 2);
      curve.horizontal((A2 - 2 * A4 - A5 - 2 * A6) / 2, true);
      curve.arc(A, -Math.PI / 2, Math.PI / 2);
      curve.horizontal(-A2, true);
      curve.arc(A, -3 * Math.PI / 2, -Math.PI / 2);
      curve.horizontal((A2 - 2 * A4 - A5 - 2 * A6) / 2, true);
      curve.arc(A4, Math.PI / 2, 0);
      curve.vertical(-A7, true);
      curve.horizontal(A6, true);
      curve.vertical(A3, true);
      curve.arc(A4, 0, Math.PI / 2);
      curve.horizontal(-(A2 - 2 * A4 - A5) / 2, true);
      curve.arc(A1, 3 * Math.PI / 2, Math.PI / 2);
      curve.horizontal(A2, true);
      curve.arc(A1, Math.PI / 2, -Math.PI / 2);
      curve.horizontal(-(A2 - 2 * A4 - A5) / 2, true);
      curve.arc(A4, Math.PI / 2, Math.PI);
  
      var poly = new QGdstk.Polygon(curve.points(), layer, datatype);
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell("Inlinepad_3");
      cell.add([poly, O1]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Interdigital_cap_1 = function (layer = default_layer_flip_l1, datatype = 0) {
      const A = 14;
      const A1 = 4;
      const A2 = 2;
      const B = 52;
      const B1 = 8;
      const B2 = 8;
      const B3 = 6;
      const B4 = 6;
  
      // curve
      var path = new QGdstk.Curve([-A1 / 2, 0]);
      path.commands(['l', -A2, 0,
        'l', 0, -B3,
        'l', -(A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -(B + B1 + B2),
        'l', (A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -B3,
        'l', A2, 0,
        'l', 0, B3,
        'l', -(A - A1 - 2 * A2) / 2, B4,
        'l', 0, B2,
        'l', 2 * A / 7, 0,
        'l', 0, B - A / 7,
        'l', A / 7, 0,
        'l', 0, -(B - A / 7),
        'l', 2 * A / 7, 0,
        'l', 0, -B2,
        'l', -(A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -B3,
        'l', A2, 0,
        'l', 0, B3,
        'l', (A - A1 - 2 * A2) / 2, B4,
        'l', 0, B2 + B + B1,
        'l', -(A - A1 - 2 * A2) / 2, B4,
        'l', 0, B3,
        'l', -A2, 0,
        'l', 0, -B3,
        'l', (A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -(B1 + B - A / 7),
        'l', -A / 7, 0,
        'l', 0, B - A / 7,
        'l', -3 * A / 7, 0,
        'l', 0, -(B - A / 7),
        'l', -A / 7, 0,
        'l', 0, B1 + B - A / 7,
        'l', (A - A1 - 2 * A2) / 2, B4]);
  
      var poly = new QGdstk.Polygon(path.points(), layer, datatype);
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [0, -(2 * B3 + 2 * B4 + B2 + B + B1)]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell("Interdigital_cap_1");
      cell.add([poly, O1, O2]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Interdigital_cap_2 = function (layer = default_layer_flip_l1, datatype = 0) {
      const A = 18;
      const A1 = 4;
      const A2 = 2;
      const B = 52;
      const B1 = 8;
      const B2 = 8;
      const B3 = 6;
      const B4 = 6;
  
      // curve
      var path = new QGdstk.Curve([-A1 / 2, 0]);
      path.commands(['l', -A2, 0,
        'l', 0, -B3,
        'l', -(A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -(B + B1 + B2),
        'l', (A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -B3,
        'l', A2, 0,
        'l', 0, B3,
        'l', -(A - A1 - 2 * A2) / 2, B4,
        'l', 0, B2,
        'l', 2 * A / 9, 0,
        'l', 0, B - A / 9,
        'l', A / 9, 0,
        'l', 0, -(B - A / 9),
        'l', 3 * A / 9, 0,
        'l', 0, B - A / 9,
        'l', A / 9, 0,
        'l', 0, -(B + B2 - A / 9),
        'l', -(A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -B3,
        'l', A2, 0,
        'l', 0, B3,
        'l', (A - A1 - 2 * A2) / 2, B4,
        'l', 0, B2 + B + B1,
        'l', -(A - A1 - 2 * A2) / 2, B4,
        'l', 0, B3,
        'l', -A2, 0,
        'l', 0, -B3,
        'l', (A - A1 - 2 * A2) / 2, -B4,
        'l', 0, -B1,
        'l', -2 * A / 9, 0,
        'l', 0, -(B - A / 9),
        'l', -A / 9, 0,
        'l', 0, (B - A / 9),
        'l', -3 * A / 9, 0,
        'l', 0, -(B - A / 9),
        'l', -A / 9, 0,
        'l', 0, B + B1 - A / 9,
        'l', (A - A1 - 2 * A2) / 2, B4]);
  
      var poly = new QGdstk.Polygon(path.points(), layer, datatype);
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [0, -(2 * B3 + 2 * B4 + B2 + B + B1)]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Interdigital_cap_2");
      cell.add([poly, O1, O2]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.AB_1 = function (layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0, A = 48, A1 = 17, A2 = 16, A3 = 14, A4 = 21) {
      this.assert(A >= 40 && A <= 100);
      this.assert(A1 >= 10 && A1 <= 30);
      this.assert(A2 >= 10 && A2 <= 50);
      this.assert(A3 >= 10 && A3 <= 30);
      this.assert(A4 >= 15 && A4 <= 40);
  
      // points
      var p1 = [-A4 / 2, 0];
      var p2 = [-A4 / 2, A];
      var p3 = [A4 / 2, A];
      var p4 = [A4 / 2, 0];
      var p5 = [-A1 / 2, (A - A3 * 2 - A2) / 2];
      var p6 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3];
      var p7 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3];
      var p8 = [A1 / 2, (A - A3 * 2 - A2) / 2];
      var p9 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2];
      var p10 = [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2];
      var p11 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2];
      var p12 = [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2];
  
      // polygon
      var deck = new QGdstk.Polygon([p1, p2, p3, p4], layer_deck, datatype_deck);
      var pier_upper = new QGdstk.Polygon([p9, p10, p11, p12], layer_pier, datatype_pier);
      var pier_lower = new QGdstk.Polygon([p5, p6, p7, p8], layer_pier, datatype_pier);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell("AB_1");
      cell.add([deck, pier_upper, pier_lower]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.AB_2 = function (layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0) {
      const A = 58;
      const A1 = 15;
      const A2 = 24;
      const A3 = 15;
      const A4 = 19;
  
      var cell = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, A, A1, A2, A3, A4);
      cell.name = "AB_2";
      return cell;
    }
  
    PDKDevice.prototype.AB_3 = function (layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0) {
      const A = 91;
      const A1 = 25;
      const A2 = 39;
      const A3 = 23.5;
      const A4 = 29;
  
      var cell = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, A, A1, A2, A3, A4);
      cell.name = "AB_3";
      return cell;
    }
  
    PDKDevice.prototype.Cross_AB_1 = function (layer = default_layer_flip_l1, datatype = 0, layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0) {
      const A = 64;
      const A1 = 22;
      const A2 = 42;
      const A3 = 18;
      const A4 = 18;
  
      const B = 91;
      const B1 = 29;
      const B2 = 62;
      const B3 = 25;
      const B4 = 23.5;
  
      const D = 8;
      const D1 = 4;
      const D2 = 13.5;
      const D3 = 38;
      const D4 = 30;
      const D5 = 50;
      const D6 = 4;
  
      const E = 82;
      const E1 = 26;
  
      function make_pad_4(layer = 0, datatype = 0) {
        // curve
        var path = new QGdstk.Curve([-D1 / 2, 0]);
        path.commands(['l', -(D - D1) / 2, 0,
          'l', -(D5 - D) / 2, -D2,
          'l', 0, -D3,
          'l', D5, 0,
          'l', 0, D3,
          'l', -(D5 - D) / 2, D2,
          'l', -(D - D1) / 2, 0,
          'l', (D4 - D1) / 2, -D2,
          'l', 0, -(D3 - D6),
          'l', -D4, 0,
          'l', 0, (D3 - D6)]);
        var poly = new QGdstk.Polygon(path.points(), layer, datatype);
        return poly;
      }
  
  
      var comp_1 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, A, A3, A2 - A4, A4, A1);
      comp_1.name = "Cross_AB_1_Comp_1";
      comp_1.remove(comp_1.labels);
      var comp_2 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, B, B3, B2 - B4, B4, B1);
      comp_2.name = "Cross_AB_1_Comp_2";
      comp_2.remove(comp_2.labels);
      var comp_1_ref_l = new QGdstk.Reference(comp_1, [-E / 2, -(D2 + D3 + E1 / 2 + A / 2)], 0, 1, false,
        1, 1, null);
      var comp_1_ref_r = new QGdstk.Reference(comp_1, [E / 2, -(D2 + D3 + E1 / 2 + A / 2)], 0, 1, false,
        1, 1, null);
      var comp_2_ref = new QGdstk.Reference(comp_2, [0, -(2 * D2 + 2 * D3 + E1 + B) / 2], 0, 1, false,
        1, 1, null);
      var comp_4_upper = make_pad_4(layer, datatype);
      var comp_4_lower = comp_4_upper.copy();
      comp_4_lower.mirror([0, -(D2 + D3 + E1 / 2)], [1, -(D2 + D3 + E1 / 2)]);
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [0, -(2 * D2 + 2 * D3 + E1)]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // drc aux layer
      var convex_upper_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_4_upper), aux_layer_drc_cab_wbp, 0);
      var convex_lower_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_4_lower), aux_layer_drc_cab_wbp, 0);
  
      var cell = new QGdstk.Cell("Cross_AB_1");
      cell.add(comp_1_ref_l.get_polygons())
      cell.add(comp_1_ref_r.get_polygons())
      cell.add(comp_2_ref.get_polygons())
      cell.add([comp_4_upper, comp_4_lower, O1, O2]);
  
      // drc aux layer
      var cab_convex = new QGdstk.Polygon(cell.convex_hull(), aux_layer_drc_cab, 0);
  
      // add aux polygon
      cell.add([convex_upper_polygon, convex_lower_polygon, cab_convex]);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Cross_AB_2 = function (layer = default_layer_flip_l1, datatype = 0, layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0) {
      const A = 64;
      const A1 = 22;
      const A2 = 42;
      const A3 = 18;
      const A4 = 18;
  
      const B = 91;
      const B1 = 29;
      const B2 = 62;
      const B3 = 25;
      const B4 = 23.5;
  
      const C = 20;
      const C1 = 10;
      const C2 = 13.5;
      const C3 = 38;
      const C4 = 30;
      const C5 = 50;
      const C6 = 4;
  
      const D = 8;
      const D1 = 4;
      const D2 = 13.5;
      const D3 = 38;
      const D4 = 30;
      const D5 = 50;
      const D6 = 4;
  
      const F = 82;
      const F1 = 26;
  
      function make_pad_4(layer = 0, datatype = 0) {
        // curve
        var path = new QGdstk.Curve([-D1 / 2, 0]);
        path.commands(['l', -(D - D1) / 2, 0,
          'l', -(D5 - D) / 2, -D2,
          'l', 0, -D3,
          'l', D5, 0,
          'l', 0, D3,
          'l', -(D5 - D) / 2, D2,
          'l', -(D - D1) / 2, 0,
          'l', (D4 - D1) / 2, -D2,
          'l', 0, -(D3 - D6),
          'l', -D4, 0,
          'l', 0, (D3 - D6)]);
        var poly = new QGdstk.Polygon(path.points(), layer, datatype);
        return poly;
      }
  
      function make_pad_3(layer = 0, datatype = 0) {
        // curve
        var path = new QGdstk.Curve([-C1 / 2, 0]);
        path.commands(['l', -(C - C1) / 2, 0,
          'l', -(C5 - C) / 2, -C2,
          'l', 0, -C3,
          'l', C5, 0,
          'l', 0, C3,
          'l', -(C5 - C) / 2, C2,
          'l', -(C - C1) / 2, 0,
          'l', (C4 - C1) / 2, -C2,
          'l', 0, -(C3 - C6),
          'l', -C4, 0,
          'l', 0, (C3 - C6)]);
        var poly = new QGdstk.Polygon(path.points(), layer, datatype);
        return poly;
      }
  
  
      var comp_1 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, A, A3, A2 - A4, A4, A1);
      comp_1.name = "Cross_AB_2_Comp_1";
      comp_1.remove(comp_1.labels);
      var comp_2 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, B, B3, B2 - B4, B4, B1);
      comp_2.name = "Cross_AB_2_Comp_2";
      comp_2.remove(comp_2.labels);
      var comp_1_ref_l = new QGdstk.Reference(comp_1, [-F / 2, -(C2 + C3 + D2 + D3 + F1) / 2 - A / 2], 0, 1, false,
        1, 1, null);
      var comp_1_ref_r = new QGdstk.Reference(comp_1, [F / 2, -(C2 + C3 + D2 + D3 + F1) / 2 - A / 2], 0, 1, false,
        1, 1, null);
      var comp_2_ref = new QGdstk.Reference(comp_2, [0, -(C2 + C3 + D2 + D3 + F1 + B) / 2], 0, 1, false,
        1, 1, null);
      var comp_3_upper = make_pad_3(layer, datatype);
      var comp_4_lower = make_pad_4(layer, datatype);
      comp_4_lower.mirror([0, 0], [1, 0]);
      comp_4_lower.translate([0, -(C2 + C3 + D2 + D3 + F1)]);
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [0, -(C2 + C3 + D2 + D3 + F1)]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // drc aux layer
      var convex_upper_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_3_upper), aux_layer_drc_cab_wbp, 0);
      var convex_lower_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_4_lower), aux_layer_drc_cab_wbp, 0);
  
      var cell = new QGdstk.Cell("Cross_AB_2");
      cell.add(comp_1_ref_l.get_polygons())
      cell.add(comp_1_ref_r.get_polygons())
      cell.add(comp_2_ref.get_polygons())
      cell.add([comp_3_upper, comp_4_lower, O1, O2]);
      // drc aux layer
      var cab_convex = new QGdstk.Polygon(cell.convex_hull(), aux_layer_drc_cab, 0);
  
      // add aux polygon
      cell.add([convex_upper_polygon, convex_lower_polygon, cab_convex]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cross_AB_3 = function (layer = default_layer_flip_l1, datatype = 0, layer_pier = default_layer_flip_l3, datatype_pier = 0, layer_deck = default_layer_flip_l4, datatype_deck = 0) {
      const A = 64;
      const A1 = 22;
      const A2 = 42;
      const A3 = 18;
      const A4 = 18;
  
      const B = 91;
      const B1 = 29;
      const B2 = 62;
      const B3 = 25;
      const B4 = 23.5;
  
      const C = 20;
      const C1 = 10;
      const C2 = 13.5;
      const C3 = 38;
      const C4 = 30;
      const C5 = 50;
      const C6 = 4;
  
      const G = 82;
      const G1 = 26;
  
      function make_pad_3(layer = 0, datatype = 0) {
        // curve
        var path = new QGdstk.Curve([-C1 / 2, 0]);
        path.commands(['l', -(C - C1) / 2, 0,
          'l', -(C5 - C) / 2, -C2,
          'l', 0, -C3,
          'l', C5, 0,
          'l', 0, C3,
          'l', -(C5 - C) / 2, C2,
          'l', -(C - C1) / 2, 0,
          'l', (C4 - C1) / 2, -C2,
          'l', 0, -(C3 - C6),
          'l', -C4, 0,
          'l', 0, (C3 - C6)]);
        var poly = new QGdstk.Polygon(path.points(), layer, datatype);
        return poly;
      }
  
  
      var comp_1 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, A, A3, A2 - A4, A4, A1);
      comp_1.name = "Cross_AB_3_Comp_1";
      comp_1.remove(comp_1.labels);
      var comp_2 = this.AB_1(layer_pier, datatype_pier, layer_deck, datatype_deck, B, B3, B2 - B4, B4, B1);
      comp_2.name = "Cross_AB_3_Comp_2";
      comp_2.remove(comp_2.labels);
      var comp_1_ref_l = new QGdstk.Reference(comp_1, [-G / 2, -(C2 + C3 + C2 + C3 + G1) / 2 - A / 2], 0, 1, false,
        1, 1, null);
      var comp_1_ref_r = new QGdstk.Reference(comp_1, [G / 2, -(C2 + C3 + C2 + C3 + G1) / 2 - A / 2], 0, 1, false,
        1, 1, null);
      var comp_2_ref = new QGdstk.Reference(comp_2, [0, -(C2 + C3 + C2 + C3 + G1 + B) / 2], 0, 1, false,
        1, 1, null);
      var comp_3_upper = make_pad_3(layer, datatype);
      var comp_3_lower = comp_3_upper.copy();
      comp_3_lower.mirror([0, -(C2 + C3 + C2 + C3 + G1) / 2], [1, -(C2 + C3 + C2 + C3 + G1) / 2]);
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [0, -(C2 + C3 + C2 + C3 + G1)]
        , 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      // drc aux layer
      var convex_upper_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_3_upper), aux_layer_drc_cab_wbp, 0);
      var convex_lower_polygon = new QGdstk.Polygon(this.convex_hull_points(comp_3_lower), aux_layer_drc_cab_wbp, 0);
  
      var cell = new QGdstk.Cell("Cross_AB_3");
      cell.add(comp_1_ref_l.get_polygons())
      cell.add(comp_1_ref_r.get_polygons())
      cell.add(comp_2_ref.get_polygons())
      cell.add([comp_3_upper, comp_3_lower, O1, O2]);
  
      // drc aux layer
      var cab_convex = new QGdstk.Polygon(cell.convex_hull(), aux_layer_drc_cab, 0);
  
      // add aux polygon
      cell.add([convex_upper_polygon, convex_lower_polygon, cab_convex]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Squid_1 = function (layer_squid = default_layer_jj, datatype_squid = 0) {
      // vars
      const n = 5;
      const o = 10;
      const p = 1.94;
      const r = 18;
      const s = 22;
      const t = 12;
      const x = 0.12;
      const y = 6;
      const z = 6;
      const a1 = 18;
  
      var p1 = [-(t / 2 + y), 0];
      var p2 = [-t / 2, 0];
      var p3 = [t / 2, 0];
      var p4 = [(t / 2 + y), 0];
      var p5 = [-(r / 2 + x / 2), n];
      var p6 = [-(r / 2 - x / 2), n];
      var p7 = [(r / 2 - x / 2), n];
      var p8 = [(r / 2 + x / 2), n];
      var p9 = [-(t / 2 + y), z];
      var p10 = [-t / 2, z];
      var p11 = [t / 2, z];
      var p12 = [(t / 2 + y), z];
      var p13 = [-y / 2, a1 - z];
      var p14 = [y / 2, a1 - z];
      var p15 = [-s / 2, n + o - p - x];
      var p16 = [s / 2, n + o - p - x];
      var p17 = [-s / 2, n + o - p];
      var p18 = [s / 2, n + o - p];
      var p19 = [-(r / 2 + x / 2), n + o];
      var p20 = [-(r / 2 - x / 2), n + o];
      var p21 = [(r / 2 - x / 2), n + o];
      var p22 = [(r / 2 + x / 2), n + o];
      var p23 = [-y / 2, a1];
      var p24 = [y / 2, a1];
  
      var box_left = new QGdstk.Polygon([p1, p2, p10, p9], layer_squid, datatype_squid);
      var box_middle = new QGdstk.Polygon([p13, p14, p24, p23], layer_squid, datatype_squid);
      var box_right = new QGdstk.Polygon([p3, p4, p12, p11], layer_squid, datatype_squid);
  
      var sc_left = new QGdstk.Polygon([p5, p6, p20, p19], layer_squid, datatype_squid);
      var sc_middle = new QGdstk.Polygon([p15, p16, p18, p17], layer_squid, datatype_squid);
      var sc_right = new QGdstk.Polygon([p7, p8, p22, p21], layer_squid, datatype_squid);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Squid_1");
      cell.add([box_left, box_middle, box_right, sc_left, sc_middle, sc_right]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Squid_2 = function (layer_squid = default_layer_jj, datatype_squid = 0) {
      // vars
      const C = 6;
      const C1 = 7.96;
      const C2 = 6.86;
      const C3 = 7.1;
      const D = 18;
      const D1 = 6;
      const D2 = 0.2;
      const D3 = 6;
  
      var p1 = [-(D - D3) / 2, 0];
      var p2 = [-(D + D3) / 2, 0];
      var p3 = [-(D + D3) / 2, D1];
      var p4 = [-(D + D2) / 2, D1];
      var p5 = [-(D + D2) / 2, D1 + C3];
      var p6 = [-C / 2, D1 + C3];
      var p7 = [-C / 2, D1 + C3 + C2];
      var p8 = [C / 2, D1 + C3 + C2];
      var p9 = [C / 2, D1 + C3];
      var p10 = [(D + D2) / 2, D1 + C3];
      var p11 = [(D + D2) / 2, D1];
      var p12 = [(D + D3) / 2, D1];
      var p13 = [(D + D3) / 2, 0];
      var p14 = [(D - D3) / 2, 0];
      var p15 = [(D - D3) / 2, D1];
      var p16 = [(D - D2) / 2, D1];
      var p17 = [(D - D2) / 2, D1 + C3 - D2];
      var p18 = [C / 2, D1 + C3 - D2];
      var p19 = [C / 2, D1 + C3 + C2 - C1];
      var p20 = [-C / 2, D1 + C3 + C2 - C1];
      var p21 = [-C / 2, D1 + C3 - D2];
      var p22 = [-(D - D2) / 2, D1 + C3 - D2];
      var p23 = [-(D - D2) / 2, D1];
      var p24 = [-(D - D3) / 2, D1];
  
      // squid
      var squid = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24],
        layer_squid, datatype_squid);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Squid_2");
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Squid_3 = function (layer_squid = default_layer_jj, datatype_squid = 0) {
      // vars
      const E = 4.5;
      const E1 = 2;
      const E2 = 1;
      const E3 = 0.12;
      const E4 = 1.191;
      const F = 12;
      const F1 = 6;
      const G = 16.8;
      const G1 = 0.2;
      const G2 = 8.75;
      const G3 = 20;
  
      var p1 = [F1 / 2, 0];
      var p2 = [-F1 / 2, 0];
      var p3 = [-F1 / 2, E];
      var p4 = [-E2 / 2, E];
      var p5 = [-E2 / 2, E + E1];
      var p6 = [E2 / 2, E + E1];
      var p7 = [E2 / 2, E];
      var p8 = [F1 / 2, E];
  
      var box_middle = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6, p7, p8]);
      box_middle.mirror([1, 0]);
      box_middle.translate([0, G3]);
  
      var box_below = new QGdstk.Polygon([p1, p2, p3, p4, p5, [-G1 / 2, E + E1], [-G1 / 2, E + E1 + G2], [G1 / 2, E + E1 + G2], [G1 / 2, E + E1], p6, p7, p8]);
      var box_upper = box_below.copy();
      box_below.translate([-F / 2, 0]);
      box_upper.translate([F / 2, 0]);
  
      var sc_vertical = new QGdstk.Polygon([[G / 2, -E3], [-G / 2, -E3], [-G / 2, 0], [G / 2, 0]]);
      sc_vertical.translate([0, G3 - E - E4]);
  
      var squid = QGdstk.boolean(sc_vertical, [box_below, box_middle, box_upper], 'or', 1e-3, layer_squid, datatype_squid);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Squid_3");
      cell.add(squid);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Squid_4 = function (layer_bandage = default_layer_bandage,
      layer_junction_1 = default_layer_jj,
      layer_junction_2 = default_layer_jj,
      layer_junction_3 = default_layer_jj,
      datatype_squid = 0) {
      // vars
      const n = 3.48;
      const n1 = 4;
      const n2 = 2;
      const n3 = 6;
      const n4 = 2.5;
      const n5 = 9;
      const n6 = 2;
      const n7 = 1;
      const p = 11.5;
      const q = 0.17;
      const q1 = 0.17;
      const q2 = 11.11;
      const q3 = 7.11;
      const q4 = 15;
      const q5 = 0.11;
      const q6 = 0.83;
      const q7 = 7.83;
      const q8 = 0.88;
  
      var pad = new QGdstk.Cell("Box");
      var box_1 = QGdstk.rectangle([-n5 / 2, 0], [n5 / 2, n4], layer_bandage, datatype_squid);
      var box_2 = QGdstk.rectangle([-n1 / 2, n4], [n1 / 2, n4 + n2], layer_bandage, datatype_squid);
      var box_3 = QGdstk.rectangle([-n3 / 2, n4], [n3 / 2, n4 + n], layer_junction_3, datatype_squid);
      var box_4 = QGdstk.rectangle([-n7 / 2, n4 + n], [n7 / 2, n4 + n + n6], layer_junction_3, datatype_squid);
      pad.add([box_1, box_2, box_3, box_4]);
  
      var pad_1 = pad.copy("box_1", [p / 2, 0], 0, 1, false, true);
      var pad_2 = pad.copy("box_2", [-p / 2, 0], 0, 1, false, true);
      var pad_3 = pad.copy("box_3", [0, 2 * (n4 + n + n6) + q7 - q6], 0, 1, true, true);
  
      var line_v_l = QGdstk.rectangle([0, 0], [q, q2], layer_junction_1, datatype_squid);
      line_v_l.translate([-p / 2 - n7 / 2, n4 + n + q8]);
      var line_h_r = QGdstk.rectangle([0, 0], [q3, q1], layer_junction_2, datatype_squid);
      line_h_r.translate([n7 / 2 - q5, n4 + n + n6 + q7]);
      var line_v_r = line_v_l.copy();
      line_v_r.mirror([0, 0], [0, 1]);
      var line_h_l = line_h_r.copy();
      line_h_l.mirror([0, 0], [0, 1]);
  
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell("Squid_4");
      cell.add(pad_1.get_polygons());
      cell.add(pad_2.get_polygons());
      cell.add(pad_3.get_polygons());
      cell.add([line_h_l, line_h_r, line_v_l, line_v_r]);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_Qubit_1 = function (layer = default_layer_flip_l1, datatype = 0, a = 231, a1 = 60, b = 154, b1 = 78, c = 65.5, c1 = 20, c2 = 5) {
      this.assert(a >= 180 && a <= 300);
      this.assert(a1 >= 40 && a1 <= 80);
      this.assert(b >= 120 && b <= 160);
      this.assert(b1 >= 60 && b1 <= 100);
      this.assert(c >= 50 && c <= 120);
      this.assert(c1 >= 10 && c1 <= 30);
      this.assert(c2 >= 3 && c2 <= 6);
  
      var p1 = [-a / 2, 0];
      var p2 = [-a1 / 2, 0];
      var p3 = [a1 / 2, 0];
      var p4 = [a / 2, 0];
      var p5 = [-(a / 2 - c2), c2];
      var p6 = [-(a1 / 2 + c2), c2];
      var p7 = [(a1 / 2 + c2), c2];
      var p8 = [(a / 2 - c2), c2];
      var p9 = [-(a / 2 - c2), (b - b1) / 2 - c2];
      var p10 = [-(a / 2 - c2 - c), (b - b1) / 2 - c2];
      var p11 = [(a / 2 - c2 - c), (b - b1) / 2 - c2];
      var p12 = [(a / 2 - c2), (b - b1) / 2 - c2];
      var p13 = [-a / 2, (b - b1) / 2];
      var p14 = [-(a / 2 - c), (b - b1) / 2];
      var p15 = [(a / 2 - c), (b - b1) / 2];
      var p16 = [a / 2, (b - b1) / 2];
      var p17 = [-a1 / 2, (b - c1) / 2];
      var p18 = [a1 / 2, (b - c1) / 2];
      var p19 = [-(a1 / 2 + c2), (b - c1) / 2 + c2];
      var p20 = [(a1 / 2 + c2), (b - c1) / 2 + c2];
      var p21 = [-(a1 / 2 + c2), (b - c1) / 2 + c1 - c2];
      var p22 = [(a1 / 2 + c2), (b - c1) / 2 + c1 - c2];
      var p23 = [-a1 / 2, (b - c1) / 2 + c1];
      var p24 = [a1 / 2, (b - c1) / 2 + c1];
      var p25 = [-a / 2, (b + b1) / 2];
      var p26 = [-(a / 2 - c), (b + b1) / 2];
      var p27 = [(a / 2 - c), (b + b1) / 2];
      var p28 = [a / 2, (b + b1) / 2];
      var p29 = [-(a / 2 - c2), (b + b1) / 2 + c2];
      var p30 = [-(a / 2 - c2 - c), (b + b1) / 2 + c2];
      var p31 = [(a / 2 - c2 - c), (b + b1) / 2 + c2];
      var p32 = [(a / 2 - c2), (b + b1) / 2 + c2];
      var p33 = [-(a / 2 - c2), b - c2];
      var p34 = [-(a1 / 2 + c2), b - c2];
      var p35 = [(a1 / 2 + c2), b - c2];
      var p36 = [(a / 2 - c2), b - c2];
      var p37 = [-a / 2, b];
      var p38 = [-a1 / 2, b];
      var p39 = [a1 / 2, b];
      var p40 = [a / 2, b];
  
      var poly_outter = new QGdstk.Polygon([p1, p2, p17, p18, p3, p4, p16, p15, p27, p28, p40, p39, p24, p23, p38, p37, p25, p26, p14, p13],
        layer, datatype);
      var poly_inner = new QGdstk.Polygon([p5, p6, p19, p20, p7, p8, p12, p11, p31, p32, p36, p35, p22, p21, p34, p33, p29, p30, p10, p9],
        layer, datatype);
      var cap = QGdstk.boolean(poly_outter, poly_inner, 'not', 1e-3, layer, datatype);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Qubit_1");
      cell.add(cap);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_Qubit_2 = function (layer = default_layer_flip_l1, datatype = 0, A = 55.4, A1 = 34.4, A2 = 16, A3 = 3, B = 82, B1 = 72, B2 = 92.7, B3 = 14, B4 = 5, B5 = 114.77, C = 51.3, C1 = 12.18, C2 = 33.18) {
      this.assert(A >= 50 && A <= 60);
      this.assert(A1 >= 30 && A1 <= 40);
      this.assert(A2 >= 10 && A2 <= 20);
      this.assert(A3 >= 2 && A3 <= 6);
      this.assert(B >= 75 && B <= 90);
      this.assert(B1 >= 65 && B1 <= 80);
      this.assert(B2 >= 85 && B2 <= 100);
      this.assert(B3 >= 10 && B3 <= 20);
      this.assert(B4 >= 3 && B4 <= 10);
      this.assert(B5 >= 100 && B5 <= 120);
      this.assert(C >= 40 && C <= 60);
      this.assert(C1 >= 8 && C1 <= 20);
      this.assert(C2 >= 25 && C2 <= 40);
  
      if (B5 != 114.77) {
        A3 = B5 - (B2 - (B3 - B4) / 2 + B3 + C - (A - C1))
      }
  
      // curve
      var path_outter_left = new QGdstk.Curve([0, 0]);
      path_outter_left.commands(['l', A, 0,
        'l', 0, B2 - (B3 - B4) / 2 + A3,
        'l', B1 / 2, 0,
        'l', 0, B3,
        'l', -B1 / 2, 0,
        'l', 0, C,
        'l', -C1, 0,
        'l', -(A - C1), -(A - C1)]);
  
      var path_inner_left = new QGdstk.Curve([A2, A3]);
      path_inner_left.commands(['l', A1, 0,
        'l', 0, B2,
        'l', B / 2, 0,
        'l', 0, B4,
        'l', -B / 2, 0,
        'l', 0, C2,
        'l', -A1, -A1]);
  
      var central_pos = [A + B1 / 2, A3 + B2 + B4 / 2];
      var poly_outter_left = new QGdstk.Polygon(path_outter_left.points());
      var poly_outter_right = poly_outter_left.copy();
      poly_outter_right.mirror(central_pos, [central_pos[0], central_pos[1] + 1]);
      poly_outter_right.mirror(central_pos, [central_pos[0] + 1, central_pos[1]]);
      var poly_outter = QGdstk.boolean(poly_outter_left, poly_outter_right, 'or', 1e-3, layer, datatype);
  
      var poly_inner_left = new QGdstk.Polygon(path_inner_left.points(), 1, 1);
      var poly_inner_right = poly_inner_left.copy();
      poly_inner_right.mirror(central_pos, [central_pos[0], central_pos[1] + 1]);
      poly_inner_right.mirror(central_pos, [central_pos[0] + 1, central_pos[1]]);
      var poly_inner = QGdstk.boolean(poly_inner_left, poly_inner_right, 'or', 1e-3, layer, datatype);
  
      var poly = QGdstk.boolean(poly_outter, poly_inner, 'not', 1e-3, layer, datatype);
      for (let i in poly) {
        poly[i].translate([-central_pos[0], -central_pos[1]]);
      }
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Qubit_2");
      cell.add(poly);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_Resonator_1 = function (layer = default_layer_flip_l1, datatype = 0, A = 158, A1 = 10, A2 = 85.5, A3 = 20, B = 40, B1 = 5) {
      this.assert(A >= 120 && A <= 180);
      this.assert(A1 >= 3 && A1 <= 20);
      this.assert(A2 >= 50 && A2 <= 120);
      this.assert(A3 >= 10 && A3 <= 40);
      this.assert(B >= 20 && B <= 80);
      this.assert(B1 >= 2 && B1 <= 8);
  
      // points
      var p1 = [-A / 2, -A2];
      var p2 = [-A / 2, 0];
      var p3 = [-A1 / 2, 0];
      var p4 = [-A1 / 2, -B1];
      var p5 = [-(A / 2 - B1), -B1];
      var p6 = [-(A / 2 - B1), -(A2 - B1)];
      var p7 = [-(A / 2 - B + B1), -(A2 - B1)];
      var p8 = [-(A / 2 - B + B1), -(A3 - B1)];
      var p9 = [(A / 2 - B + B1), -(A3 - B1)];
      var p10 = [(A / 2 - B + B1), -(A2 - B1)];
      var p11 = [(A / 2 - B1), -(A2 - B1)];
      var p12 = [(A / 2 - B1), -B1];
      var p13 = [A1 / 2, -B1];
      var p14 = [A1 / 2, 0];
      var p15 = [A / 2, 0];
      var p16 = [A / 2, -A2];
      var p17 = [(A / 2 - B), -A2];
      var p18 = [(A / 2 - B), -A3];
      var p19 = [-(A / 2 - B), -A3];
      var p20 = [-(A / 2 - B), -A2];
  
      var poly = new QGdstk.Polygon([p3, p2, p1, p20, p19, p18, p17, p16, p15, p14, p13, p12, p11, p10, p9, p8, p7, p6, p5, p4], layer, datatype);
      var O1 = new QGdstk.Label('O1', [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Resonator_1");
      cell.add([poly, O1]);
      cell.add(O);
      return cell;
    }
  
  
    PDKDevice.prototype.Cap1_Qubit_Resonator_2 = function (layer = default_layer_flip_l1, datatype = 0, A = 4, B = 102.14, B1 = 3) {
      this.assert(A >= 3 && A <= 20);
      this.assert(B >= 80 && B <= 120);
      this.assert(B1 >= 2 && B1 <= 8);
  
      // points
      var path = new QGdstk.FlexPath([-(A / 2) / Math.sqrt(2), -(A / 2) / Math.sqrt(2)], B1, B1 / 2, 'miter', 'flush',
        0, null, 1e-2, false, true, layer, datatype);
      path.commands(['L', -B / 2, -B / 2,
        'l', B, 0,
        'l', 0, B,
        'L', (A / 2) / Math.sqrt(2), (A / 2) / Math.sqrt(2)]);
      var polys = path.to_polygons();
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Resonator_2");
      cell.add(polys);
      cell.add(O1);
      cell.add(O);
      return cell;
    }
  
    PDKDevice.prototype.Cap1_Qubit_Resonator_3 = function (layer = default_layer_flip_l1, datatype = 0, A = 4, B = 145.6, B3 = 3) {
      this.assert(A >= 3 && A <= 20);
      this.assert(B >= 80 && B <= 200);
      this.assert(B3 >= 2 && B3 <= 8);
  
      const B1 = 25;
      const B2 = 77.14;
  
      // points
      var path = new QGdstk.FlexPath([-(A / 2) / Math.sqrt(2), -(A / 2) / Math.sqrt(2)], B3, B3 / 2, 'miter', 'flush',
        0, null, 1e-2, false, true, layer, datatype);
      path.commands(['L', -(B2 - B1) / 2, -(B2 - B1) / 2,
        'l', -(B - B1 - B2), 0,
        'l', -B1, -B1,
        'l', B, 0,
        'l', 0, B,
        'l', -B1, -B1,
        'l', 0, -(B - B1 - B2),
        'L', (A / 2) / Math.sqrt(2), (A / 2) / Math.sqrt(2)]);
  
      var polys = path.to_polygons();
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Resonator_3");
      cell.add(polys)
      cell.add(O1);
      cell.add(O);
      return cell;
    }
  
  
    PDKDevice.prototype.Cap1_Qubit_Resonator_4 = function (layer = default_layer_flip_l1, datatype = 0, A = 4, B = 194.93, B3 = 3) {
      this.assert(A >= 3 && A <= 20);
      this.assert(B >= 150 && B <= 230);
      this.assert(B3 >= 2 && B3 <= 8);
  
      const B2 = 58.98;
  
      // points
      var path = new QGdstk.FlexPath([-(A / 2) / Math.sqrt(2), -(A / 2) / Math.sqrt(2)], B3, B3 / 2, 'miter', 'flush',
        0, null, 1e-2, false, true, layer, datatype);
      path.commands(['L', -B / 2, -B / 2,
        'l', (B - B2), 0,
        'l', B2, B2,
        'l', 0, (B - B2),
        'L', (A / 2) / Math.sqrt(2), (A / 2) / Math.sqrt(2)]);
  
      var polys = path.to_polygons();
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Cap1_Qubit_Resonator_4");
      cell.add(polys)
      cell.add(O1);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.WBPad = function (layer = default_layer_flip_l1, datatype = 0, A = 456, A1 = 250, B = 323, B1 = 250, B2 = 100, C = 8, C1 = 4) {
      this.assert(A >= 300 && A <= 600);
      this.assert(A1 >= 100 && A1 <= 500);
      this.assert(B >= 200 && B <= 500);
      this.assert(B1 >= 150 && B1 <= 400);
      this.assert(B2 >= 50 && B2 <= 200);
      this.assert(C >= 8 && C <= 20);
      this.assert(C1 >= 4 && C1 <= 10);
      
      // path
      var path = new QGdstk.Curve([-C1 / 2, 0]);
      path.commands(['l', -(C - C1) / 2, 0,
        'l', -(A - C) / 2, B2,
        'l', 0, B,
        'l', A, 0,
        'l', 0, -B,
        'l', -(A - C) / 2, -B2,
        'l', -(C - C1) / 2, 0,
        'l', (A1 - C1) / 2, B2,
        'l', 0, B1,
        'l', -A1, 0,
        'l', 0, -B1]);
      
      var pad = new QGdstk.Polygon(path.points(), layer, datatype);
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
      
      // aux
      var aux_pad = new QGdstk.Polygon(pad.get_points(), aux_layer_drc_wbp, 0)
      // cell
      var cell = new QGdstk.Cell("WBPad");
      cell.add([pad, O1, aux_pad]);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.JJTest = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0, A = 400, A1 = 300, B = 64, B1 = 15, B2 = 15, B3 = 24) {
      this.assert(A >= 300 && A <= 500);
      this.assert(A1 >= 200 && A1 <= 400);
      this.assert(B >= 30 && B <= 80);
      this.assert(B1 >= 10 && B1 <= 30);
      this.assert(B2 >= 10 && B2 <= 30);
      this.assert(B3 >= 20 && B3 <= 40);
  
      const C = 90;
      const C1 = 90;
      const C2 = 2;
      const C3 = 10;
  
      const D = 20;
      const D1 = 7.96;
      const D2 = 6.86;
      const D3 = 6;
      const E = 18;
      const E1 = 6;
      const E2 = 6;
      const E3 = 0.2;
  
      // cap
      var p1 = [-A / 2, 0];
      var p2 = [-B / 2, 0];
      var p3 = [B / 2, 0];
      var p4 = [A / 2, 0];
      var p5 = [-B / 2, B1];
      var p6 = [0, B1];
      var p7 = [B / 2, B1];
      var p8 = [-B3 / 2, (A - A1) / 2 - B2];
      var p9 = [B3 / 2, (A - A1) / 2 - B2];
      var p10 = [-A1 / 2, (A - A1) / 2];
      var p11 = [-B3 / 2, (A - A1) / 2];
      var p12 = [B3 / 2, (A - A1) / 2];
      var p13 = [A1 / 2, (A - A1) / 2];
      var p14 = [-A1 / 2, A - ((A - A1) / 2)];
      var p15 = [A1 / 2, A - ((A - A1) / 2)];
      var p16 = [-A / 2, A];
      var p17 = [A / 2, A];
      var p18 = [-C1 / 2, -(C - (A - A1) / 2) / 2];
      var p19 = [C1 / 2, -(C - (A - A1) / 2) / 2];
      var p20 = [-C1 / 2, C - (C - (A - A1) / 2) / 2];
      var p21 = [C1 / 2, C - (C - (A - A1) / 2) / 2];
  
      var cap = new QGdstk.Polygon([p5, p2, p1, [p1[0], p14[1]], p14, p10, p11, p8, p9, p12, p13, p15, p14, [p1[0], p14[1]], p16, p17, p4, p3, p7], layer, datatype);
  
      // squid
      var box_upper = new QGdstk.Polygon([[0, 0], [D3, 0], [D3, D1], [0, D1]]);
      box_upper.translate([-D3 / 2, D - D1 + B1]);
      var box_ll = new QGdstk.Polygon([[0, 0], [E2, 0], [E2, E1], [0, E1]]);
      var box_rl = box_ll.copy();
      box_ll.translate([-(E + E2) / 2, B1]);
      box_rl.translate([(E - E2) / 2, B1]);
      var path = new QGdstk.FlexPath([[-E / 2, E1 + B1], [-E / 2, D - D2 - E3 / 2 + B1], [E / 2, D - D2 - E3 / 2 + B1], [E / 2, E1 + B1]],
        E3, 0, 'miter', 'flush', 0, null, 1e-2,
        false, true, layer, datatype);
      var squid = QGdstk.boolean([box_upper, box_ll, box_rl], path, 'or', 1e-3, layer_squid, datatype_squid);
  
      // cross
      var cross = QGdstk.cross([-C1 / 2, -(C - (A - A1) / 2) / 2], C3, C2, layer, datatype);
      var rep = new QGdstk.Repetition(2, 2, [C1, C], null, null, null, null, null);
      cross.repetition = rep;
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell("JJTest");
      cell.add(cap);
      cell.add(squid);
      cell.add(cross);
      cell.add(cross.apply_repetition());
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.JJTest_1 = function (layer = default_layer_flip_l1, datatype = 0, layer_squid = default_layer_jj, datatype_squid = 0, A = 400, A1 = 300, B = 64, B1 = 15, B2 = 15, B3 = 24) {
      this.assert(A >= 300 && A <= 500);
      this.assert(A1 >= 200 && A1 <= 400);
      this.assert(B >= 30 && B <= 80);
      this.assert(B1 >= 10 && B1 <= 30);
      this.assert(B2 >= 10 && B2 <= 30);
      this.assert(B3 >= 20 && B3 <= 40);
  
      const C = 90;
      const C1 = 90;
      const C2 = 2;
      const C3 = 10;
  
      const D = 20;
      const D1 = 7.96;
      const D2 = 6.86;
      const D3 = 6;
      const E = 18;
      const E1 = 6;
      const E2 = 6;
      const E3 = 0.2;
  
      // cap
      var p1 = [-A / 2, 0];
      var p2 = [-B / 2, 0];
      var p3 = [B / 2, 0];
      var p4 = [A / 2, 0];
      var p5 = [-B / 2, B1];
      var p6 = [0, B1];
      var p7 = [B / 2, B1];
      var p8 = [-B3 / 2, (A - A1) / 2 - B2];
      var p9 = [B3 / 2, (A - A1) / 2 - B2];
      var p10 = [-A1 / 2, (A - A1) / 2];
      var p11 = [-B3 / 2, (A - A1) / 2];
      var p12 = [B3 / 2, (A - A1) / 2];
      var p13 = [A1 / 2, (A - A1) / 2];
      var p14 = [-A1 / 2, A - ((A - A1) / 2)];
      var p15 = [A1 / 2, A - ((A - A1) / 2)];
      var p16 = [-A / 2, A];
      var p17 = [A / 2, A];
      var p18 = [-C1 / 2, -(C - (A - A1) / 2) / 2];
      var p19 = [C1 / 2, -(C - (A - A1) / 2) / 2];
      var p20 = [-C1 / 2, C - (C - (A - A1) / 2) / 2];
      var p21 = [C1 / 2, C - (C - (A - A1) / 2) / 2];
  
      var cap = new QGdstk.Polygon([p5, p2, p1, [p1[0], p14[1]], p14, p10, p11, p8, p9, p12, p13, p15, p14, [p1[0], p14[1]], p16, p17, p4, p3, p7], layer, datatype);
  
      // squid
      var squid = this.Squid_4();
      var bounding_box = squid.bounding_box();
      var v_height = (bounding_box[1][1] - bounding_box[0][1]) / 2;
      var squid_ref = new QGdstk.Reference(squid, [0, (A - A1) / 4 - v_height], 0, 1, false, 1, 1, null);
  
      // cross
      var cross = QGdstk.cross([-C1 / 2, -(C - (A - A1) / 2) / 2], C3, C2, layer, datatype);
      var rep = new QGdstk.Repetition(2, 2, [C1, C], null, null, null, null, null);
      cross.repetition = rep;
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell("JJTest_1");
      cell.add(cap);
      cell.add(squid_ref.get_polygons());
      cell.add(cross);
      cell.add(cross.apply_repetition());
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.Marker = function (layer_deep = default_layer_flip_l1, datatype_deep = 0, layer_shallow = default_layer_base_l1, datatype_shallow = 0) {
      // vars
      const A = 80;
      const A1 = 40;
      const A2 = 4;
      const A3 = 24;
      const A4 = 4;
      const B = 280;
      const B1 = 152;
      const C = 315;
      const D = 100;
      const D1 = 25;
      const D2 = 50;
      const D3 = 25;
      const D4 = 50;
      const D5 = 100;
      const D6 = 40;
      const D7 = 60;
      const D8 = 20;
      const D9 = 40;
  
      function make_left() {
        // polygon
        var polygons = []
        // shallow
        var shallow_short_rect = new QGdstk.Polygon([[0, 0], [A3 / 2, 0], [A3 / 2, A4], [0, A4]], layer_shallow, datatype_shallow);
        var shallow_long_rect = new QGdstk.Polygon([[0, 0], [A1 / 2, 0], [A1 / 2, A4], [0, A4]], layer_shallow, datatype_shallow);
        var shallow_corner = new QGdstk.Polygon([[0, 0], [0, -A4], [A3 / 2 - A4, -A4], [A3 / 2 - A4, -A3 / 2], [A3 / 2, -A3 / 2], [A3 / 2, 0]], layer_shallow, datatype_shallow);
        var shallow_triangle = new QGdstk.Polygon([[B1 / 2 + A4, 0], [B / 2, A / 2], [B / 2, -A / 2]], layer_shallow, datatype_shallow);
  
        shallow_short_rect.translate([0, A3 / 2 + A2]);
        shallow_long_rect.translate([0, A3 / 2 + 2 * A2 + A4]);
        shallow_corner.translate([0, A3 / 2]);
  
        var rep = new QGdstk.Repetition(1, 4, [0, 2 * (A2 + A4)], null, null, null, null, null);
        shallow_short_rect.repetition = rep;
        shallow_long_rect.repetition = rep;
        var shallow_polygons = []
        shallow_polygons.push(shallow_short_rect, shallow_long_rect);
        shallow_polygons = shallow_polygons.concat(shallow_short_rect.apply_repetition());
        shallow_polygons = shallow_polygons.concat(shallow_long_rect.apply_repetition());
  
        var temp = [];
        for (let index in shallow_polygons) {
          var poly = shallow_polygons[index].copy();
          poly.mirror([0, 0], [1, 1]);
          temp.push(poly);
        }
        shallow_polygons = shallow_polygons.concat(temp);
        shallow_polygons.push(shallow_corner);
  
        // deep
        var deep_polygons = [];
        for (let index in shallow_polygons) {
          var poly = shallow_polygons[index].copy();
          poly.mirror([0, 0], [0, 1]);
          poly.layer = layer_deep;
          poly.datatype = datatype_deep;
          deep_polygons.push(poly);
        }
  
        // shallow_mirror
        shallow_polygons.push(shallow_triangle);
        var shallow_mirror = [];
        for (let index in shallow_polygons) {
          var poly = shallow_polygons[index].copy();
          poly.mirror([0, 0], [-1, 1]);
          shallow_mirror.push(poly);
        }
        shallow_polygons = shallow_polygons.concat(shallow_mirror);
  
        // deep_mirror
        var deep_mirror = [];
        for (let index in deep_polygons) {
          var poly = deep_polygons[index].copy();
          poly.mirror([0, 0], [1, 1]);
          deep_mirror.push(poly);
        }
        deep_polygons = deep_polygons.concat(deep_mirror);
        var deep_triangle = shallow_triangle.copy();
        deep_triangle.layer = layer_deep;
        deep_triangle.datatype = datatype_deep;
        deep_triangle.mirror([0, 0], [0, 1]);
        var deep_triangle_copy = deep_triangle.copy();
        deep_triangle_copy.mirror([0, 0], [-1, 1]);
        deep_polygons.push(deep_triangle, deep_triangle_copy);
  
        polygons = polygons.concat(shallow_polygons);
        polygons = polygons.concat(deep_polygons);
  
        return polygons;
      }
  
      function make_right() {
        var polygons = [];
        var cross = QGdstk.cross([0, D2 / 2 + D1], D, D4, layer_deep, datatype_deep);
  
        var D9_h = D9 * (D6 / (D5 - D7));
        var trapezoid = new QGdstk.Polygon([[-D5 / 2, D + D3], [-D7 / 2, D + D3 + D6], [D7 / 2, D + D3 + D6], [D5 / 2, D + D3]], layer_deep, datatype_deep);
        var triangle = new QGdstk.Polygon([[-D9 / 2, D + D3 + D6 + D8], [0, D + D3 + D6 + D8 + D9_h], [D9 / 2, D + D3 + D6 + D8]], layer_deep, datatype_deep);
  
        polygons.push(cross, trapezoid, triangle);
        for (let i in polygons) {
          polygons[i].translate([C, -B / 2]);
        }
        return polygons;
      }
  
  
      var left = make_left();
      var right = make_right()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("Marker");
      cell.add(left);
      cell.add(right);
      cell.add(O)
      return cell;
    }
  
    PDKDevice.prototype.CPW = function (layer = default_layer_flip_l1, datatype = 0, A = 3200, A1 = 50, A2 = 1500, A3 = 20, A4 = 10, tolerance = 1e-2) {
      this.assert(A >= 1000 && A <= 4000);
      this.assert(A1 >= 20 && A1 <= 50);
      this.assert(A2 >= 1000 && A2 <= 2000);
      this.assert(A3 >= 6 && A3 <= 20);
      this.assert(A4 >= 4 && A4 <= 10);
  
      var line_width = (A3 - A4) / 2;
      // two lines
      var path = new QGdstk.FlexPath([[0, 0], [0, -(A2 + A1)], [A + 2 * A1, -(A2 + A1)], [A + 2 * A1, 0]], [line_width, line_width],
        [-(A4 + line_width) / 2, (A4 + line_width) / 2], 'round', 'flush', A1,
        null, tolerance, false, true, layer, datatype);
      var polys = path.to_polygons();
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [A + 2 * A1, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("CPW");
      cell.add(polys);
      cell.add([O1, O2]);
      cell.add(O)
      // aux polygon
      var out_path = new QGdstk.FlexPath([[0, 0], [0, -(A2 + A1)], [A + 2 * A1, -(A2 + A1)], [A + 2 * A1, 0]], A3,
        0, 'round', 'flush', A1,
        null, tolerance, false, true, aux_layer_drc_cpw, datatype);
      var aux_polygon = out_path.to_polygons();
  
      cell.add(aux_polygon)
  
      return cell;
    }
  
    PDKDevice.prototype.CPW_corner = function (layer = default_layer_flip_l1, datatype = 0, A = 4, A1 = 2, A2 = 25, tolerance = 1e-2) {
      this.assert(A >= 3 && A <= 10);
      this.assert(A1 >= 2 && A1 <= 5);
      this.assert(A2 >= 20 && A2 <= 50);
  
      var path = new QGdstk.FlexPath([[0, 0], [0, -A2], [2 * A2, -A2], [2 * A2, -2 * A2]],
        [A1, A1],
        [-(A + A1) / 2, (A + A1) / 2], 'round', 'flush', A2,
        null, tolerance,
        false, true, layer, datatype);
      var polys = path.to_polygons();
  
      var O1 = new QGdstk.Label("O1", [0, 0], 's', 0, 1, false, default_layer_keypoint, 0);
      var O2 = new QGdstk.Label("O2", [2 * A2, -2 * A2], 's', 0, 1, false, default_layer_keypoint, 0);
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O1.set_gds_property(1, kp_pro_str);
      O2.set_gds_property(1, kp_pro_str);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell("CPW_corner");
      cell.add(polys);
      cell.add([O1, O2]);
      cell.add(O)
  
      // aux polygon
      var out_path = new QGdstk.FlexPath([[0, 0], [0, -A2], [2 * A2, -A2], [2 * A2, -2 * A2]], 2 * A,
        0, 'round', 'flush', A2,
        null, tolerance, false, true, aux_layer_drc_cpw, datatype);
      var aux_polygon = out_path.to_polygons();
  
      cell.add(aux_polygon)
  
      return cell;
    }
  
    // semiconductor param device
    PDKDevice.prototype.SiMos_Single_SET = function (
      layer_screen = default_layer_screen,
      datatype_screen = 0,
      layer_co_zone = default_layer_co_zone,
      datatype_co_zone = 0,
      layer_co = default_layer_co,
      datatype_co = 0,
      layer_barrier = default_layer_barrier,
      datatype_barrier = 0,
      layer_plunger = default_layer_plunger,
      datatype_plunger = 0,
      layer_ion = default_layer_ion,
      datatype_ion = 0,
      n = 2,
      l1 = 0.04,
      d1 = 0.024,
      l2 = 0.05,
      w1 = 0.137,
      w2 = 0.072,
      l3 = 0.239,
      d2 = 0.025,
      h1 = 0.5,
      w3 = 0.05,
      d3 = 0.05,
      h2 = 0.5,
      w4 = 0.208,
      SETy = 0.044,
      Cox = 0.049,
      Coy = -0.508,
      Col1 = 0.13,
      Col2 = 0.445,
      Col3 = 1.38,
      Coh1 = 0.98,
      Coh2 = 0.98,
      Coh3 = 0.36
    ) {
      // vars
      const AA1 = 20
      const AA2 = 5
      const AA3 = 80
      const AA4 = 180
      const AA5 = 60
      const AA6 = 90
      const AA7 = 230
      const AA8 = 120
      const AA9 = 700
  
      const a = 2.8
      const ax = a / 2
  
      const d4 = 0.006
      const d5 = 0.01
      const d6 = 0.001
      const d7 = 0.056
      const d8 = 0.014
      const d9 = 0.06
      const d10 = 0.207
      const h3 = 0.198
      const h4 = 0.3
      const l4 = 0.046
      const w5 = 0.06
      const w6 = 0.184
      const w7 = 0.068
  
      const qubit_central = [d4, l1 + d1 / 2]
  
      // ion_injector is mirror to qubit_central_y_aix
      function make_ion_injector() {
        var polygons = []
        var ion_temp_path = new QGdstk.Curve([0, 0])
        ion_temp_path.commands(['l', -AA2, 0, 'l', -(AA4 - AA6 - AA1 - AA2), AA7 - AA3, 'l', -AA6, 0, 'l', 0, AA8, 'l', AA4 - AA5, 0, 'l', 0, -AA6])
  
        var ion_lu = new QGdstk.Polygon(ion_temp_path.points(), layer_ion, datatype_ion)
        ion_lu.translate([-AA1, AA3 + qubit_central[1]])
        var ion_ru = ion_lu.copy()
        var ion_ll = ion_lu.copy()
  
        ion_ru.mirror([0, 0], [0, 1])
        ion_ll.mirror([0, qubit_central[1]], [1, qubit_central[1]])
  
        var ion_rl = ion_ll.copy()
        ion_rl.mirror([0, 0], [0, 1])
  
        polygons.push(ion_lu, ion_ru, ion_ll, ion_rl)
        return polygons
      }
  
      function make_qubit() {
        // plunger
        var plungers = []
        var drain = new QGdstk.Polygon(
          [
            [d5, -d1],
            [d5 + w2, -d1],
            [d5 + w2, -(d1 + l3)],
            [d5, -(d1 + l3)],
          ],
          layer_plunger,
          datatype_plunger
        )
        for (let i = 0; i < n; i++) {
          var p1 = [d4, l1 + i * (l1 + d1)]
          var p2 = [d4 + w1, l1 + i * (l1 + d1)]
          var p3 = [d4 + w1, i * (l1 + d1)]
          var p4 = [d4, i * (l1 + d1)]
          var plunger = new QGdstk.Polygon([p1, p2, p3, p4], layer_plunger, datatype_plunger)
          plungers.push(plunger)
        }
        var source = new QGdstk.Polygon(
          [
            [d5, n * (l1 + d1) + l3],
            [d5 + w2, n * (l1 + d1) + l3],
            [d5 + w2, n * (l1 + d1)],
            [d5, n * (l1 + d1)],
          ],
          layer_plunger,
          datatype_plunger
        )
        plungers.push(drain, source)
  
        var new_central_y = (n / 2) * l1 + (n / 2 + 0.5 - 1) * d1
        var offset = qubit_central[1] - new_central_y
        for (let i in plungers) {
          plungers[i].translate([0, offset])
        }
  
        // barrier
        var barriers = []
        for (let i = 0; i < n + 1; i++) {
          var p1 = [d4, -d1 / 2 + l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p2 = [d4 + w1, -d1 / 2 + l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p3 = [d4 + w1, -d1 / 2 - l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p4 = [d4, -d1 / 2 - l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var barrier = new QGdstk.Polygon([p1, p2, p3, p4], layer_barrier, datatype_barrier)
          barriers.push(barrier)
        }
        for (let i in barriers) {
          barriers[i].translate([0, offset])
        }
  
        // screen
        var screens = []
        var p1 = [-w3 / 2, -h1 / 2]
        var p2 = [w3 / 2, -h1 / 2]
        var p3 = [w3 / 2, h1 / 2]
        var p4 = [-w3 / 2, h1 / 2]
        var p5 = [w3 / 2 + d3, -h2 / 2]
        var p6 = [w3 / 2 + d3 + w4, -h2 / 2]
        var p7 = [w3 / 2 + d3 + w4, h2 / 2]
        var p8 = [w3 / 2 + d3, h2 / 2]
        screens.push(new QGdstk.Polygon([p1, p2, p3, p4], layer_screen, datatype_screen), new QGdstk.Polygon([p5, p6, p7, p8], layer_screen, datatype_screen))
        for (let i in screens) {
          screens[i].translate([0, qubit_central[1]])
        }
  
        // single SET
        var SET = []
        const SETx = -(d2 - d4)
        const overlap_y = (l4 - 2 * d8) / 2
        // set barriers
        SET.push(
          new QGdstk.Polygon(
            [
              [SETx - w6, SETy + d8],
              [SETx, SETy + d8],
              [SETx, SETy + d8 + d9 - d8],
              [SETx - w6, SETy + d8 + d9 - d8],
            ],
            layer_barrier,
            datatype_barrier
          ),
          new QGdstk.Polygon(
            [
              [SETx - w6, SETy - d9],
              [SETx, SETy - d9],
              [SETx, SETy - d8],
              [SETx - w6, SETy - d8],
            ],
            layer_barrier,
            datatype_barrier
          )
        )
        // set plunger
        SET.push(
          new QGdstk.Polygon(
            [
              [SETx - d6 - w7, SETy + d8 + d9 - d8 - overlap_y],
              [SETx - d6, SETy + d8 + d9 - d8 - overlap_y],
              [SETx - d6, SETy + d10],
              [SETx - d6 - w7, SETy + d10],
            ],
            layer_plunger,
            datatype_plunger
          ),
          new QGdstk.Polygon(
            [
              [SETx - d6 - w7, SETy - d9 + overlap_y],
              [SETx - d6, SETy - d9 + overlap_y],
              [SETx - d6, SETy - d10],
              [SETx - d6 - w7, SETy - d10],
            ],
            layer_plunger,
            datatype_plunger
          ),
          new QGdstk.Polygon(
            [
              [SETx - w6, SETy - l4 / 2],
              [SETx, SETy - l4 / 2],
              [SETx, SETy + l4 / 2],
              [SETx - w6, SETy + l4 / 2],
            ],
            layer_plunger,
            datatype_plunger
          )
        )
  
        // set screen
        SET.push(
          new QGdstk.Polygon(
            [
              [SETx - d7 - w5, SETy - h4 / 2],
              [SETx - d7, SETy - h4 / 2],
              [SETx - d7, SETy + h4 / 2],
              [SETx - d7 - w5, SETy + h4 / 2],
            ],
            layer_screen,
            datatype_screen
          )
        )
  
        var polygons = []
        polygons = polygons.concat(plungers).concat(barriers).concat(screens).concat(SET)
        return polygons
      }
  
      var ion_injector = make_ion_injector()
      var co_zone = new QGdstk.Polygon(
        [
          [-ax, -a / 2],
          [ax, -a / 2],
          [ax, a / 2],
          [-ax, a / 2],
        ],
        layer_co_zone,
        datatype_co_zone
      )
      co_zone.translate([0, qubit_central[1]])
      var co = new QGdstk.Polygon(
        [
          [Col2, 0],
          [Col2, Coh3],
          [Col3, Coh3],
          [Col3, Coh3 + Coh2],
          [Col1, Coh3 + Coh2],
          [Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2],
          [-Col3, Coh3 + Coh2],
          [-Col3, Coh3],
          [-Col2, Coh3],
          [-Col2, 0],
        ],
        layer_co,
        datatype_co
      )
      co.translate([Cox, Coy])
      var qubit = make_qubit()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell('SiMos_Single_SET')
      cell.add(ion_injector)
      cell.add([co_zone, co])
      cell.add(qubit)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.SiMos_Double_SET = function (
      layer_screen = default_layer_screen,
      datatype_screen = 0,
      layer_co_zone = default_layer_co_zone,
      datatype_co_zone = 0,
      layer_co = default_layer_co,
      datatype_co = 0,
      layer_barrier = default_layer_barrier,
      datatype_barrier = 0,
      layer_plunger = default_layer_plunger,
      datatype_plunger = 0,
      layer_ion = default_layer_ion,
      datatype_ion = 0,
      n = 2,
      l1 = 0.04,
      d1 = 0.024,
      l2 = 0.05,
      w1 = 0.137,
      w2 = 0.072,
      l3 = 0.239,
      d2 = 0.025,
      h1 = 0.5,
      w3 = 0.05,
      d3 = 0.05,
      h2 = 0.5,
      w4 = 0.208,
      SETy = 0.044,
      Cox = 0.049,
      Coy = -0.508,
      Col1 = 0.13,
      Col2 = 0.445,
      Col3 = 1.38,
      Coh1 = 0.98,
      Coh2 = 0.98,
      Coh3 = 0.36
    ) {
      // vars
      const AA1 = 20
      const AA2 = 5
      const AA3 = 80
      const AA4 = 180
      const AA5 = 60
      const AA6 = 90
      const AA7 = 230
      const AA8 = 120
      const AA9 = 700
  
      const AA1_prime = 130
      const AA2_prime = SETy + 10
      const AA3_prime = 20
  
      const a = 2.8
      const ax = a / 2
  
      const d4 = 0.006
      const d5 = 0.01
      const d6 = 0.001
      const d7 = 0.056
      const d8 = 0.014
      const d9 = 0.06
      const d10 = 0.207
      const h3 = 0.198
      const h4 = 0.3
      const l4 = 0.046
      const w5 = 0.06
      const w6 = 0.184
      const w7 = 0.068
  
      const l1_prime = 0.194
      const w1_prime = 0.05
      const d1_prime = 0.014
      const d2_prime = 0.082
      const h1_prime = 0.239
      const d3_prime = 0.046
      const d4_prime = 0.028
      const d5_prime = 0.028
      const d6_prime = 0.075
  
      const qubit_central = [d4, l1 + d1 / 2]
  
      function make_ion_injector() {
        var polygons = []
        var ion_temp_path = new QGdstk.Curve([0, 0])
        ion_temp_path.commands(['l', -AA2, 0, 'l', -(AA4 - AA6 - AA1 - AA2), AA7 - AA3, 'l', -AA6, 0, 'l', 0, AA8, 'l', AA4 - AA5, 0, 'l', 0, -AA6])
  
        var ion_template = new QGdstk.Polygon(ion_temp_path.points(), layer_ion, datatype_ion)
        var ion_lu = ion_template.copy()
        ion_lu.translate([-AA1, AA3 + qubit_central[1]])
        var ion_ru = ion_lu.copy()
        var ion_ll = ion_lu.copy()
  
        ion_ru.mirror([0, 0], [0, 1])
        ion_ll.mirror([0, qubit_central[1]], [1, qubit_central[1]])
  
        var ion_rl = ion_ll.copy()
        ion_rl.mirror([0, 0], [0, 1])
  
        var ion_left_upper = ion_template.copy()
        ion_left_upper.mirror([0, 0], [-1, 1])
  
        ion_left_upper.translate([-AA1_prime, AA3_prime / 2 + qubit_central[1]])
        var ion_left_lower = ion_left_upper.copy()
        ion_left_lower.mirror([0, qubit_central[1]], [1, qubit_central[1]])
  
        polygons.push(ion_lu, ion_ru, ion_ll, ion_rl, ion_left_upper, ion_left_lower)
        return polygons
      }
  
      function make_qubit() {
        // plunger
        // make plunger and barrier from (0,0) then move new qubit_central align to old qubit_central
        var plungers = []
        var drain = new QGdstk.Polygon(
          [
            [d5, -d1],
            [d5 + w2, -d1],
            [d5 + w2, -(d1 + l3)],
            [d5, -(d1 + l3)],
          ],
          layer_plunger,
          datatype_plunger
        )
        for (let i = 0; i < n; i++) {
          var p1 = [d4, l1 + i * (l1 + d1)]
          var p2 = [d4 + w1, l1 + i * (l1 + d1)]
          var p3 = [d4 + w1, i * (l1 + d1)]
          var p4 = [d4, i * (l1 + d1)]
          var plunger = new QGdstk.Polygon([p1, p2, p3, p4], layer_plunger, datatype_plunger)
          plungers.push(plunger)
        }
        var source = new QGdstk.Polygon(
          [
            [d5, n * (l1 + d1) + l3],
            [d5 + w2, n * (l1 + d1) + l3],
            [d5 + w2, n * (l1 + d1)],
            [d5, n * (l1 + d1)],
          ],
          layer_plunger,
          datatype_plunger
        )
        plungers.push(drain, source)
  
        var new_central_y = (n / 2) * l1 + (n / 2 + 0.5 - 1) * d1
        var offset = qubit_central[1] - new_central_y
        for (let i in plungers) {
          plungers[i].translate([0, offset])
        }
  
        // barrier
        var barriers = []
        for (let i = 0; i < n + 1; i++) {
          var p1 = [d4, -d1 / 2 + l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p2 = [d4 + w1, -d1 / 2 + l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p3 = [d4 + w1, -d1 / 2 - l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var p4 = [d4, -d1 / 2 - l2 / 2 + i * (l1 - l2 + d1 + l2)]
          var barrier = new QGdstk.Polygon([p1, p2, p3, p4], layer_barrier, datatype_barrier)
          barriers.push(barrier)
        }
        for (let i in barriers) {
          barriers[i].translate([0, offset])
        }
  
        // screen
        var screens = []
        var p1 = [-w3 / 2, -h1 / 2]
        var p2 = [w3 / 2, -h1 / 2]
        var p3 = [w3 / 2, h1 / 2]
        var p4 = [-w3 / 2, h1 / 2]
        var p5 = [w3 / 2 + d3, -h2 / 2]
        var p6 = [w3 / 2 + d3 + w4, -h2 / 2]
        var p7 = [w3 / 2 + d3 + w4, h2 / 2]
        var p8 = [w3 / 2 + d3, h2 / 2]
        screens.push(new QGdstk.Polygon([p1, p2, p3, p4], layer_screen, datatype_screen), new QGdstk.Polygon([p5, p6, p7, p8], layer_screen, datatype_screen))
        for (let i in screens) {
          screens[i].translate([0, qubit_central[1]])
        }
  
        // double SET
        var SET = []
        const SETx = -(d2 - d4)
        const overlap_y = (l4 - 2 * d8) / 2
        // set barriers
        var b1 = new QGdstk.Polygon(
          [
            [SETx - w6, SETy + d2_prime - overlap_y],
            [SETx, SETy + d2_prime - overlap_y],
            [SETx, SETy + d2_prime - overlap_y + d3_prime],
            [SETx - w6, SETy + d2_prime - overlap_y + d3_prime],
          ],
          layer_barrier,
          datatype_barrier
        )
        var b2 = b1.copy()
        b2.translate([0, d3_prime + d5_prime])
        var b3 = b1.copy()
        b3.mirror([0, SETy], [1, SETy])
        var b4 = b2.copy()
        b4.mirror([0, SETy], [1, SETy])
        SET.push(b1, b2, b3, b4)
        // set plunger
        var height = d10 - (d8 + d9 - d8 - overlap_y)
        var s = new QGdstk.Polygon(
          [
            [SETx - d6 - w7, SETy + d2_prime - 2 * overlap_y + 2 * d3_prime + d5_prime],
            [SETx - d6, SETy + d2_prime - 2 * overlap_y + 2 * d3_prime + d5_prime],
            [SETx - d6, SETy + d2_prime - 2 * overlap_y + 2 * d3_prime + d5_prime + height],
            [SETx - d6 - w7, SETy + d2_prime - 2 * overlap_y + 2 * d3_prime + d5_prime + height],
          ],
          layer_plunger,
          datatype_plunger
        )
  
        var p1 = new QGdstk.Polygon(
          [
            [SETx - w6, SETy + d2_prime + d4_prime],
            [SETx, SETy + d2_prime + d4_prime],
            [SETx, SETy + d2_prime + d4_prime + d3_prime],
            [SETx - w6, SETy + d2_prime + d4_prime + d3_prime],
          ],
          layer_plunger,
          datatype_plunger
        )
  
        // p2's width = s.height
        var p2 = new QGdstk.Polygon(
          [
            [SETx - d6 - height, SETy + d1_prime],
            [SETx - d6, SETy + d1_prime],
            [SETx - d6, SETy + d1_prime + w7],
            [SETx - d6 - height, SETy + d1_prime + w7],
          ],
          layer_plunger,
          datatype_plunger
        )
  
        var p3 = p1.copy()
        p3.mirror([0, SETy], [1, SETy])
        var p4 = p2.copy()
        p4.mirror([0, SETy], [1, SETy])
        var d = s.copy()
        d.mirror([0, SETy], [1, SETy])
  
        SET.push(s, p1, p2, p3, p4, d)
  
        // set screen
        SET.push(
          new QGdstk.Polygon(
            [
              [SETx - d7 - w5, SETy + d6_prime],
              [SETx - d7, SETy + d6_prime],
              [SETx - d7, SETy + d6_prime + h1_prime],
              [SETx - d7 - w5, SETy + d6_prime + h1_prime],
            ],
            layer_screen,
            datatype_screen
          ),
          new QGdstk.Polygon(
            [
              [SETx - d7 - w5, SETy - d6_prime],
              [SETx - d7, SETy - d6_prime],
              [SETx - d7, SETy - d6_prime - h1_prime],
              [SETx - d7 - w5, SETy - d6_prime - h1_prime],
            ],
            layer_screen,
            datatype_screen
          ),
          new QGdstk.Polygon(
            [
              [-w3 / 2 - l1_prime, SETy - w1_prime / 2],
              [w3 / 2, SETy - w1_prime / 2],
              [w3 / 2, SETy + w1_prime / 2],
              [-w3 / 2 - l1_prime, SETy + w1_prime / 2],
            ],
            layer_screen,
            datatype_screen
          )
        )
  
        var polygons = []
        polygons = polygons.concat(plungers).concat(barriers).concat(screens).concat(SET)
        return polygons
      }
  
      var ion_injector = make_ion_injector()
      var co_zone = new QGdstk.Polygon(
        [
          [-ax, -a / 2],
          [ax, -a / 2],
          [ax, a / 2],
          [-ax, a / 2],
        ],
        layer_co_zone,
        datatype_co_zone
      )
      co_zone.translate([0, qubit_central[1]])
  
      var co = new QGdstk.Polygon(
        [
          [Col2, 0],
          [Col2, Coh3],
          [Col3, Coh3],
          [Col3, Coh3 + Coh2],
          [Col1, Coh3 + Coh2],
          [Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2],
          [-Col3, Coh3 + Coh2],
          [-Col3, Coh3],
          [-Col2, Coh3],
          [-Col2, 0],
        ],
        layer_co,
        datatype_co
      )
      co.translate([Cox, Coy])
      var qubit = make_qubit()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell('SiMos_Double_SET')
      cell.add(ion_injector)
      cell.add([co_zone, co])
      cell.add(qubit)
      cell.add(O);
      return cell
    }
  
    PDKDevice.prototype.SiGe = function (
      layer_screen = default_layer_screen,
      datatype_screen = 0,
      layer_co_zone = default_layer_co_zone,
      datatype_co_zone = 0,
      layer_co = default_layer_co,
      datatype_co = 0,
      layer_barrier = default_layer_barrier,
      datatype_barrier = 0,
      layer_plunger = default_layer_plunger,
      datatype_plunger = 0,
      layer_ion = default_layer_ion,
      datatype_ion = 0,
      layer_TiAu = default_layer_tiau,
      datatype_TiAu = 0,
      layer_mesa = default_layer_mesa,
      datatype_mesa = 0,
      Cox = 0.07,
      Coy = -0.578,
      Col1 = 0.315,
      Col2 = 0.445,
      Col3 = 1.38,
      Coh1 = 0.98,
      Coh2 = 0.98,
      Coh3 = 0.36,
      n = 2,
      l1 = 0.07,
      d1 = 0.04,
      l2 = 0.08,
      w1 = 0.128,
      l3 = 0.088,
      w2 = 0.12,
      d2 = 0.034,
      d3 = 0.07,
      h1 = 0.38,
      h2 = 0.84,
      d4 = 0.345,
      h3 = 0.9,
      w3 = 0.07,
      SETy = 0.035
    ) {
      const M1 = 200
      const M2 = 200
      const M3 = 170
      const M4 = 160
      const M5 = 300
      const M6 = 105
      const M7 = 110
      const M8 = 122.5
      const M9 = 90
      const M10 = 5
      const M11 = 70
      const M12 = 10
      const M13 = 6
      const M14 = 22
      const M15 = 12
      const A1 = 10
      const A2 = 20
      const A3 = 65
      const A4 = A1 / 2
      const A5 = 295
      const A6 = 175
      const A7 = 150
      const C1 = 2 * M8 + M10 + l1 / 2 + (n - 1) * (l1 / 2 + d1 / 2)
      const C2 = M8 + M10 / 2
  
      const L1 = 15
      const L2 = L1 / 2 + l1 / 2 + (n - 1) * (l1 / 2 + d1 / 2)
      const L3 = L1 / 2
  
      const l4 = 0.08
      const l5 = 0.084
      const l6 = 0.088
      const w4 = 0.128
      const w5 = 0.12
      const d5 = 0.04
      const d6 = 0.345
      const h5 = 0.27
      const h6 = 0.75
  
      // d3_prime is the horizen distance from SET screen top to origin point;
      const d3_prime = 0.07
  
      const qubit_central = [d2 / 2, l1 + d1 / 2]
  
      // qubit
      function make_qubit() {
        var polygons = []
        // plungers
        var plungers = []
        for (let i = 0; i < n; i++) {
          var p1 = [d2 / 2, l1 + i * (l1 + d1)]
          var p2 = [d2 / 2 + w1, l1 + i * (l1 + d1)]
          var p3 = [d2 / 2 + w1, i * (l1 + d1)]
          var p4 = [d2 / 2, i * (l1 + d1)]
          var plunger = new QGdstk.Polygon([p1, p2, p3, p4], layer_plunger, datatype_plunger)
          plungers.push(plunger)
        }
        var lead_upper = new QGdstk.Polygon(
          [
            [d2 / 2, n * (l1 + d1)],
            [d2 / 2 + w2, n * (l1 + d1)],
            [d2 / 2 + w2, n * (l1 + d1) + l3],
            [d2 / 2, n * (l1 + d1) + l3],
          ],
          layer_plunger,
          datatype_plunger
        )
        var lead_lower = new QGdstk.Polygon(
          [
            [d2 / 2, -d1],
            [d2 / 2 + w2, -d1],
            [d2 / 2 + w2, -d1 - l3],
            [d2 / 2, -d1 - l3],
          ],
          layer_plunger,
          datatype_plunger
        )
  
        plungers.push(lead_upper, lead_lower)
        var new_central_y = (n / 2) * l1 + (n / 2 + 0.5 - 1) * d1
        var offset = qubit_central[1] - new_central_y
        for (let i in plungers) {
          plungers[i].translate([0, offset])
        }
  
        // barriers
        const overlap_y = (l2 - d1) / 2
        const barrier_offset = l1 - 2 * overlap_y;
        var barriers = []
        for (let i = 0; i < n + 1; i++) {
          var p1 = [d2 / 2, -(l2 - overlap_y) + i * (l2 + barrier_offset)]
          var p2 = [d2 / 2 + w1, -(l2 - overlap_y) + i * (l2 + barrier_offset)]
          var p3 = [d2 / 2 + w1, overlap_y + i * (l2 + barrier_offset)]
          var p4 = [d2 / 2, overlap_y + i * (l2 + barrier_offset)]
          var barrier = new QGdstk.Polygon([p1, p2, p3, p4], layer_barrier, datatype_barrier)
          barriers.push(barrier)
        }
        for (let i in barriers) {
          barriers[i].translate([0, offset])
        }
  
        // screen
        var screens = []
        screens.push(
          new QGdstk.Polygon(
            [
              [-w3 / 2, -h3 / 2],
              [w3 / 2, -h3 / 2],
              [w3 / 2, h3 / 2],
              [-w3 / 2, h3 / 2],
            ],
            layer_screen,
            datatype_screen
          ),
          new QGdstk.Polygon(
            [
              [w3 / 2 + d3, -h1 / 2],
              [w3 / 2 + d3 + d4, -h2 / 2],
              [w3 / 2 + d3 + d4, h2 / 2],
              [w3 / 2 + d3, h1 / 2],
            ],
            layer_screen,
            datatype_screen
          )
        )
        for (let i in screens) {
          screens[i].translate([0, qubit_central[1]])
        }
  
        polygons = polygons.concat(plungers).concat(barriers).concat(screens)
        return polygons
      }
  
      // SET
      function make_SET() {
        const SETx = -d2 / 2
        // const overlap_y =
        var polygons = []
        // plungers
        polygons.push(
          new QGdstk.Polygon(
            [
              [-w4, -l4 / 2],
              [0, -l4 / 2],
              [0, l4 / 2],
              [-w4, l4 / 2],
            ],
            layer_plunger,
            datatype_plunger
          )
        )
        // leads
        polygons.push(
          new QGdstk.Polygon(
            [
              [-w5, -l4 / 2 - d5 - l6],
              [0, -l4 / 2 - d5 - l6],
              [0, -l4 / 2 - d5],
              [-w5, -l4 / 2 - d5],
            ],
            layer_plunger,
            datatype_plunger
          ),
          new QGdstk.Polygon(
            [
              [-w5, l4 / 2 + d5],
              [0, l4 / 2 + d5],
              [0, l4 / 2 + d5 + l6],
              [-w5, l4 / 2 + d5 + l6],
            ],
            layer_plunger,
            datatype_plunger
          )
        )
  
        // barriers
        const overlap_y = (l5 - d5) / 2
        polygons.push(
          new QGdstk.Polygon(
            [
              [-w4, -(l4 / 2 - overlap_y + l5)],
              [0, -(l4 / 2 - overlap_y + l5)],
              [0, -(l4 / 2 - overlap_y)],
              [-w4, -(l4 / 2 - overlap_y)],
            ],
            layer_barrier,
            datatype_barrier
          ),
          new QGdstk.Polygon(
            [
              [-w4, l4 / 2 - overlap_y + l5],
              [0, l4 / 2 - overlap_y + l5],
              [0, l4 / 2 - overlap_y],
              [-w4, l4 / 2 - overlap_y],
            ],
            layer_barrier,
            datatype_barrier
          )
        )
        // screens
        polygons.push(
          new QGdstk.Polygon(
            [
              [-d3_prime, -h5 / 2],
              [-d3_prime, h5 / 2],
              [-d3_prime - d6, h6 / 2],
              [-d3_prime - d6, -h6 / 2],
            ],
            layer_screen,
            datatype_screen
          )
        )
  
        // move to SET_x_y
        for (let i in polygons) {
          polygons[i].translate([SETx, SETy])
        }
        return polygons
      }
  
      // ion injector
      function make_ion() {
        var polygons = []
        var box_lu = new QGdstk.Polygon(
          [
            [0, 0],
            [A1, 0],
            [A1, A1],
            [0, A1],
          ],
          layer_ion,
          datatype_ion
        )
        box_lu.translate([-(C2 - A3), qubit_central[1] + C2 - A2 - A1])
        var box_ru = box_lu.copy()
        box_ru.mirror([0, 0], [0, 1])
        var box_ll = box_lu.copy()
        box_ll.mirror([0, qubit_central[1]], [1, qubit_central[1]])
        var box_rl = box_ll.copy()
        box_rl.mirror([0, 0], [0, 1])
  
        var ion_lu_path = new QGdstk.Curve([0, 0])
        ion_lu_path.commands(['l', A1, A4, 'l', -A6, A5, 'l', -A7, 0, 'l', 0, -A7, 'l', A7, 0])
        var ion_lu = new QGdstk.Polygon(ion_lu_path.points(), layer_ion, datatype_ion)
        ion_lu.translate([-(C2 - A3), qubit_central[1] + C2 - A2 - A4])
        var ion_ru = ion_lu.copy()
        ion_ru.mirror([0, 0], [0, 1])
        var ion_ll = ion_lu.copy()
        ion_ll.mirror([0, qubit_central[1]], [1, qubit_central[1]])
        var ion_rl = ion_ll.copy()
        ion_rl.mirror([0, 0], [0, 1])
  
        polygons.push(box_ll, box_lu, box_rl, box_ru, ion_lu, ion_ll, ion_rl, ion_ru)
        return polygons
      }
  
      function make_mesa() {
        // central
        var polygons = []
        var central = new QGdstk.Polygon([
          [-C2, -C2],
          [C2, -C2],
          [C2, C2],
          [-C2, C2],
        ])
        var hollow_bar_1 = new QGdstk.Polygon([
          [-M10 / 2, C2 - M9],
          [M10 / 2, C2 - M9],
          [M10 / 2, C2],
          [-M10 / 2, C2],
        ])
        var hollow_bar_2 = new QGdstk.Polygon([
          [-M12 / 2, -C2],
          [M12 / 2, -C2],
          [M12 / 2, -(C2 - M11)],
          [-M12 / 2, -(C2 - M11)],
        ])
        var hollow_box_lu = new QGdstk.Polygon([
          [0, 0],
          [M13, 0],
          [M13, -M13],
          [0, -M13],
        ])
        hollow_box_lu.translate([-(C2 - M14), C2 - M14])
        var hollow_box_ru = hollow_box_lu.copy()
        hollow_box_ru.mirror([0, 0], [0, 1])
        var hollow_box_ll = hollow_box_lu.copy()
        hollow_box_ll.mirror([0, 0], [1, 0])
        var hollow_box_rl = hollow_box_ll.copy()
        hollow_box_rl.mirror([0, 0], [0, 1])
        var hollow_box = hollow_box_ll.copy()
        hollow_box.translate([-(M14 - M15), -(M14 - M15)])
  
        var central_box = QGdstk.boolean(central, [hollow_bar_1, hollow_bar_2, hollow_box_ll, hollow_box_lu, hollow_box_rl, hollow_box_ru, hollow_box], 'not', 1e-3, layer_mesa, datatype_mesa)
        for (let i in central_box) {
          central_box[i].translate([0, qubit_central[1]])
        }
  
        // others
        var path = new QGdstk.Curve([0, 0])
        path.commands(['l', 0, -(M7 + M1 - M5), 'l', -M6, M7, 'l', -M3, 0, 'l', 0, M1, 'l', M2, 0, 'l', M4, -M5])
        var poly_lu = new QGdstk.Polygon(path.points(), layer_mesa, datatype_mesa)
        poly_lu.translate([-C2, C2 + qubit_central[1]])
        var poly_ru = poly_lu.copy()
        poly_ru.mirror([0, 0], [0, 1])
        var poly_ll = poly_lu.copy()
        poly_ll.mirror([0, qubit_central[1]], [1, qubit_central[1]])
        var poly_rl = poly_ll.copy()
        poly_rl.mirror([0, 0], [0, 1])
  
        polygons = polygons.concat(central_box)
        polygons.push(poly_lu, poly_ll, poly_rl, poly_ru)
        return polygons
      }
  
      function make_TiAu() {
        var polygons = []
        var tiau_ll = new QGdstk.Polygon(
          [
            [0, 0],
            [A7, 0],
            [A7, A7],
            [0, A7],
          ],
          layer_TiAu,
          datatype_TiAu
        )
        tiau_ll.translate([-(M8 + M10 / 2 - A3 - A1 + A6 + A7), qubit_central[1] - M8 - M10 / 2 + A2 - A5])
        var tiau_lu = tiau_ll.copy()
        tiau_lu.mirror([0, qubit_central[1]], [1, qubit_central[1]])
        var tiau_rl = tiau_ll.copy()
        tiau_rl.mirror([0, 0], [0, 1])
        var tiau_ru = tiau_lu.copy()
        tiau_ru.mirror([0, 0], [0, 1])
  
        polygons.push(tiau_ll, tiau_lu, tiau_rl, tiau_ru)
        return polygons
      }
  
      function make_AlO_co() {
        var co_zones = []
        var co_zone = new QGdstk.Polygon(
          [
            [-L3, -L1 / 2],
            [L3, -L1 / 2],
            [L3, L1 / 2],
            [-L3, L1 / 2],
          ],
          layer_co_zone,
          datatype_co_zone
        )
        co_zone.translate([0, qubit_central[1]])
  
        var co_ll = new QGdstk.Polygon(
          [
            [0, 0],
            [A7, 0],
            [A7, A7],
            [0, A7],
          ],
          layer_co_zone,
          datatype_co_zone
        )
        co_ll.translate([-(M8 + M10 / 2 - A3 - A1 + A6 + A7), qubit_central[1] - M8 - M10 / 2 + A2 - A5])
        var co_lu = co_ll.copy()
        co_lu.mirror([0, qubit_central[1]], [1, qubit_central[1]])
        var co_rl = co_ll.copy()
        co_rl.mirror([0, 0], [0, 1])
        var co_ru = co_lu.copy()
        co_ru.mirror([0, 0], [0, 1])
  
        co_zones.push(co_zone, co_ll, co_lu, co_rl, co_ru)
        return co_zones
      }
  
      var co = new QGdstk.Polygon(
        [
          [Col2, 0],
          [Col2, Coh3],
          [Col3, Coh3],
          [Col3, Coh3 + Coh2],
          [Col1, Coh3 + Coh2],
          [Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2 - Coh1],
          [-Col1, Coh3 + Coh2],
          [-Col3, Coh3 + Coh2],
          [-Col3, Coh3],
          [-Col2, Coh3],
          [-Col2, 0],
        ],
        layer_co,
        datatype_co
      )
      co.translate([Cox, Coy])
  
      var co_zone = make_AlO_co()
      var ion = make_ion()
      var qubit = make_qubit()
      var SET = make_SET()
      var mesa = make_mesa()
      var TiAu = make_TiAu()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      // cell
      var cell = new QGdstk.Cell('SiGe')
      cell.add(co_zone)
      cell.add(co)
      cell.add(qubit)
      cell.add(SET)
      cell.add(TiAu)
      cell.add(ion)
      cell.add(mesa)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.GaAs = function (layer_ion = default_layer_ion, datatype_ion = 0, layer_TiAu = default_layer_tiau, datatype_TiAu = 0, layer_co_zone = default_layer_co_zone, datatype_co_zone = 0, layer_qubit = default_layer_qubit, datatype_qubit = 0, Q1 = 0.028, Q2 = 0.4, Q3 = 0.04, Q4 = 0.035, Q5 = 0.4, Q6 = 0.05, Q7 = 0.04, Q8 = 0.88) {
      const A1 = 150
      const A2 = 90
      const A3 = 400
      const A4 = 70
      const A5 = 144
      const A6 = 286
      const A7 = 8
      const A8 = 30
  
      const A9 = 20
      const A10 = 30
      const A11 = 10
      const A12 = 25
      const A13 = 16
      const A14 = 20
      const A15 = 1.292
      const A16 = 10.03
      const B1 = 60
      const B2 = 55
      const B3 = 2.5
      const B4 = 4
  
      const Q9 = 0.05
      const Q10 = 0.05
      const Q11 = 0.1
      const Q12 = 0.05
      const Q13 = 0.05
      const Q14 = 0.12
      const Q15 = 0.04
      const Q16 = 0.055
      const Q17 = 0.075
      const Q18 = 0.03
      const Q19 = 0.05
      const Q20 = 0.1
      const Q21 = 0.035
      const Q22 = 0.05
      const Q23 = 0.12
      const Q24 = 0.025
      const Q25 = 0.055
      const Q26 = 0.075
      const Q27 = 0.042
      const Q28 = 0.12
      const Q29 = 0.11
      const Q30 = 0.09
      const Q31 = 0.08
      const Q32 = 0.11
      const Q33 = 1.515
  
      function make_qubit() {
        var polygons = []
        var poly_l_1_path = new QGdstk.Curve([0, 0])
        poly_l_1_path.commands(['l', Q10, 0, 'l', 0, Q11, 'l', Q12, Q13, 'l', 0, Q14, 'l', -Q15, Q16, 'l', 0, Q17, 'l', -(Q10 + Q12 - Q15) / 2, Q18, 'l', -(Q10 + Q12 - Q15) / 2, -Q18])
        var poly_l_1 = new QGdstk.Polygon(poly_l_1_path.points(), layer_qubit, datatype_qubit)
        poly_l_1.translate([-(Q10 + Q28 + Q3 + Q30 + Q6 + Q31 + Q3 / 2), Q29])
  
        var poly_l_2_path = new QGdstk.Curve([0, 0])
        poly_l_2_path.commands(['l', Q3, 0, 'l', 0, Q2, 'l', -Q3 / 2, Q1, 'l', -Q3 / 2, -Q1])
        var poly_l_2 = new QGdstk.Polygon(poly_l_2_path.points(), layer_qubit, datatype_qubit)
        var poly_c = poly_l_2.copy()
        poly_l_2.translate([-(Q3 / 2 + Q31 + Q6 + Q30 + Q3), 0])
        poly_c.translate([-Q3 / 2, 0])
  
        var poly_l_3_path = new QGdstk.Curve([0, 0])
        poly_l_3_path.commands(['l', Q6, 0, 'l', 0, Q5, 'l', -Q6 / 2, Q4, 'l', -Q6 / 2, -Q4])
        var poly_l_3 = new QGdstk.Polygon(poly_l_3_path.points(), layer_qubit, datatype_qubit)
        poly_l_3.translate([-(Q3 / 2 + Q31 + Q6), 0])
  
        var poly_r_path = new QGdstk.Curve([0, 0])
        poly_r_path.commands(['l', -Q19, 0, 'l', 0, Q20, 'l', -Q21, Q22, 'l', 0, Q23, 'l', Q24, Q25, 'l', 0, Q26, 'l', (Q19 + Q21 - Q24) / 2, Q27, 'l', (Q19 + Q21 - Q24) / 2, -Q27])
        var poly_r = new QGdstk.Polygon(poly_r_path.points(), layer_qubit, datatype_qubit)
        poly_r.translate([Q3 / 2 + Q32 + Q19, Q29])
  
        var poly_u_path = new QGdstk.Curve([0, 0])
        poly_u_path.commands(['l', -Q9, 0, 'l', 0, -Q8, 'l', Q9 / 2, -Q7, 'l', Q9 / 2, Q7])
        var poly_u = new QGdstk.Polygon(poly_u_path.points(), layer_qubit, datatype_qubit)
        poly_u.translate([-(Q31 + Q3 / 2), Q33])
  
        polygons.push(poly_l_1, poly_l_2, poly_l_3, poly_c, poly_r, poly_u)
        return polygons
      }
  
      function make_ion() {
        var polygons = []
  
        var trapezoid_path = new QGdstk.Curve([0, 0])
        trapezoid_path.commands(['l', -A12, 0, 'l', -A11, A10, 'l', 0, A9, 'l', A11 + A12 + A13, 0, 'l', 0, -(A9 + A10 - A14)])
        var trapezoid = new QGdstk.Polygon(trapezoid_path.points(), layer_ion, datatype_ion)
        trapezoid.translate([A16, -A15])
  
        var left_l_box = new QGdstk.Polygon(
          [
            [0, 0],
            [A7, 0],
            [A7, A8],
            [0, A8],
          ],
          layer_ion,
          datatype_ion
        )
        left_l_box.translate([-(A12 - A16 + A11), -A15 + A10 + A9])
        var right_l_box = left_l_box.copy()
        right_l_box.translate([-A4 + A1 + A2 - A5, 0])
  
        var left_trapezoid_path = new QGdstk.Curve([0, 0])
        left_trapezoid_path.commands(['l', -A4, A3, 'l', A1, 0, 'L', A7, 0])
        var left_trapezoid = new QGdstk.Polygon(left_trapezoid_path.points(), layer_ion, datatype_ion)
        left_trapezoid.translate([-(A12 - A16 + A11), -A15 + A10 + A9 + A8])
  
        var right_trapezoid_path = new QGdstk.Curve([0, 0])
        right_trapezoid_path.commands(['l', A7, 0, 'l', A6, A3, 'l', -A1, 0])
        var right_trapezoid = new QGdstk.Polygon(right_trapezoid_path.points(), layer_ion, datatype_ion)
        right_trapezoid.translate([-(A12 - A16 + A11 + A4 - A1 - A2 + A5), -A15 + A10 + A9 + A8])
  
        var left_u_box = new QGdstk.Polygon(
          [
            [0, 0],
            [A1, 0],
            [A1, -A1],
            [0, -A1],
          ],
          layer_ion,
          datatype_ion
        )
        left_u_box.translate([-(A12 - A16 + A11 + A4), -A15 + A10 + A9 + A8 + A3 + A1])
        var right_u_box = left_u_box.copy()
        right_u_box.translate([A1 + A2, 0])
  
        polygons.push(trapezoid, left_trapezoid, right_trapezoid, left_l_box, left_u_box, right_l_box, right_u_box)
        return polygons
      }
  
      function make_TiAu() {
        var left = new QGdstk.Polygon(
          [
            [0, 0],
            [A1, 0],
            [A1, -A1],
            [0, -A1],
          ],
          layer_TiAu,
          datatype_TiAu
        )
        left.translate([-(A12 - A16 + A11 + A4), -A15 + A10 + A9 + A8 + A3 + A1])
        var right = left.copy()
        right.translate([A1 + A2, 0])
  
        return [left, right]
      }
  
      var qubit = make_qubit()
      var ion = make_ion()
      var TiAu = make_TiAu()
  
      var co_zone = new QGdstk.Polygon(
        [
          [0, 0],
          [-B1, 0],
          [-B1, -B2],
          [0, -B2],
        ],
        layer_co_zone,
        datatype_co_zone
      )
      co_zone.translate([A16 + A13 + B4, -A15 + A10 + A9 + B3])
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
      // cell
      var cell = new QGdstk.Cell('GaAs')
      cell.add(qubit)
      cell.add(ion)
      cell.add(TiAu)
      cell.add(co_zone)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.Single_Channle_FDSOI = function (layer_lead = default_layer_lead, datatype_lead = 0, layer_channel = default_layer_channel, datatype_channel = 0, n = 1, a = 0.12, b = 0.1, W = 0.015, a1 = 0.03, b1 = 0.05, c1 = 0.11, d1 = 0.1, b2 = 0.1, c2 = 0.2, c3 = 0.2, w1 = 0.03) {
      function make_odd_lead() {
        var polygons = []
        var central_path = new QGdstk.Curve([0, 0])
        central_path.commands(['l', a1, 0, 'l', 0, b1 + b2 + Math.floor((n - 1) / 4) * (c2 + c3), 'l', c1, c1, 'l', 0, d1, 'l', -2 * c1 - a1, 0, 'l', 0, -d1, 'l', c1, -c1])
        var central = new QGdstk.Polygon(central_path.points(), layer_lead, datatype_lead)
        central.translate([-a1 / 2, -b1])
  
        var left_lead = []
        for (let i = 0; i < (n - 1) / 2; i++) {
          var path = new QGdstk.Curve([0, 0])
          path.commands(['l', -a1, 0, 'l', 0, b1 + b2 + Math.floor((n - 2 - 2 * i) / 4) * (c2 + c3), 'l', -c2, c2, 'l', 0, c2, 'l', a1, 0, 'l', c2, -c2])
          var lead = new QGdstk.Polygon(path.points(), layer_lead, datatype_lead)
          lead.translate([-(a1 / 2 + w1 + i * (w1 + a1)), -b1])
          if (i % 2 === 0) {
            lead.mirror([0, 0], [1, 0])
          }
          left_lead.push(lead)
        }
  
        for (let i in left_lead) {
          var right_lead = left_lead[i].copy()
          right_lead.mirror([0, 0], [0, 1])
          // right_lead.mirror([0, 0], [1, 0]);
          polygons.push(right_lead)
        }
  
        polygons = polygons.concat(left_lead)
        polygons.push(central)
        return polygons
      }
  
      function make_even_lead() {
        var polygons = []
        var left_lead = []
        for (let i = 0; i < n / 2; i++) {
          var path = new QGdstk.Curve([0, 0])
          path.commands(['l', -a1, 0, 'l', 0, b1 + b2 + Math.floor((n - 2 - 2 * i) / 4) * (c2 + c3), 'l', -c2, c2, 'l', 0, c2, 'l', a1, 0, 'l', c2, -c2])
          var lead = new QGdstk.Polygon(path.points(), layer_lead, datatype_lead)
          lead.translate([-(w1 / 2 + i * (w1 + a1)), -b1])
          if (i % 2 === 0) {
            lead.mirror([0, 0], [1, 0])
          }
          left_lead.push(lead)
        }
  
        for (let i in left_lead) {
          var right_lead = left_lead[i].copy()
          right_lead.mirror([0, 0], [0, 1])
          right_lead.mirror([0, 0], [1, 0])
          polygons.push(right_lead)
        }
  
        polygons = polygons.concat(left_lead)
        return polygons
      }
  
      function make_channle() {
        const L = n * a1 + (n - 1) * w1 + 2 * (w1 + 0.005)
        var left_channle_path = new QGdstk.Curve([0, 0])
        left_channle_path.commands(['l', 0, W, 'l', -L / 2, 0, 'l', -a, a, 'l', -b, 0, 'l', 0, -(2 * a + W), 'l', b, 0, 'l', a, a])
        var left_channle = new QGdstk.Polygon(left_channle_path.points(), layer_channel, datatype_channel)
        left_channle.translate([0, -W / 2])
        var right_channle = left_channle.copy()
        right_channle.mirror([0, 0], [0, 1])
        return [left_channle, right_channle]
      }
  
      // cell
      var leads = []
      if (n % 2 === 0) {
        leads = make_even_lead()
      } else {
        leads = make_odd_lead()
      }
  
      var channle = make_channle()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell('Single_Channle_FDSOI')
      cell.add(leads)
      cell.add(channle)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.Double_Channle_FDSOI = function (layer_lead = default_layer_lead, datatype_lead = 0, layer_channel = default_layer_channel, datatype_channel = 0, n = 6, a = 0.11, b = 0.1, W = 0.04, a1 = 0.03, c1 = 0.11, d1 = 0.05, b2 = 0.1, c2 = 0.2, w1 = 0.03, w2 = 0.02, c3 = 0.2) {
      function make_even_lead() {
        var polygons = []
        var left_lead = []
        for (let i = 1; i <= n / 4; i++) {
          var path = new QGdstk.Curve([0, 0])
          path.commands(['l', -a1, 0, 'l', 0, b2 + (n / 4 - i) * (c2 + c3), 'l', -c2, c2, 'l', 0, c2, 'l', a1, 0, 'l', c2, -c2])
          var lead = new QGdstk.Polygon(path.points(), layer_lead, datatype_lead)
          lead.translate([-(w1 / 2 + (i - 1) * (w1 + a1)), w2 / 2])
          left_lead.push(lead)
        }
  
        for (let i in left_lead) {
          var right_lead = left_lead[i].copy()
          right_lead.mirror([0, 0], [0, 1])
          polygons.push(right_lead)
        }
  
        polygons = polygons.concat(left_lead)
  
        var lower_leads = []
        for (let i in polygons) {
          var lower_lead = polygons[i].copy()
          lower_lead.mirror([0, 0], [1, 0])
          lower_leads.push(lower_lead)
        }
  
        polygons = polygons.concat(lower_leads)
        return polygons
      }
  
      function make_odd_lead() {
        var polygons = []
        var central_path = new QGdstk.Curve([0, 0])
        central_path.commands(['l', a1, 0, 'l', 0, b2 + ((n - 2) / 4) * (c2 + c3), 'l', c1, c1, 'l', 0, d1, 'l', -2 * c1 - a1, 0, 'l', 0, -d1, 'l', c1, -c1])
        var central = new QGdstk.Polygon(central_path.points(), layer_lead, datatype_lead)
        central.translate([-a1 / 2, w2 / 2])
        polygons.push(central)
  
        var left_lead = []
        for (let i = 1; i <= (n - 2) / 4; i++) {
          var path = new QGdstk.Curve([0, 0])
          path.commands(['l', -a1, 0, 'l', 0, b2 + ((n - 2) / 4 - i) * (c2 + c3), 'l', -c2, c2, 'l', 0, c2, 'l', a1, 0, 'l', c2, -c2])
          var lead = new QGdstk.Polygon(path.points(), layer_lead, datatype_lead)
          lead.translate([-(a1 / 2 + w1 + (i - 1) * (w1 + a1)), w2 / 2])
          left_lead.push(lead)
        }
  
        for (let i in left_lead) {
          var right_lead = left_lead[i].copy()
          right_lead.mirror([0, 0], [0, 1])
          // right_lead.mirror([0, 0], [1, 0]);
          polygons.push(right_lead)
        }
        polygons = polygons.concat(left_lead)
  
        var lower_leads = []
        for (let i in polygons) {
          var lower_lead = polygons[i].copy()
          lower_lead.mirror([0, 0], [1, 0])
          // right_lead.mirror([0, 0], [1, 0]);
          lower_leads.push(lower_lead)
        }
  
        polygons = polygons.concat(lower_leads)
        return polygons
      }
  
      function make_channle() {
        const L = (n / 2) * a1 + (n / 2 - 1) * w1 + 2 * (w1 + 0.005)
        var left_channle_path = new QGdstk.Curve([0, 0])
        left_channle_path.commands(['l', 0, W, 'l', -L / 2, 0, 'l', -a, a, 'l', -b, 0, 'l', 0, -(2 * a + W), 'l', b, 0, 'l', a, a])
        var left_channle = new QGdstk.Polygon(left_channle_path.points(), layer_channel, datatype_channel)
        left_channle.translate([0, -W / 2])
        var right_channle = left_channle.copy()
        right_channle.mirror([0, 0], [0, 1])
        return [left_channle, right_channle]
      }
  
      // cell
      var leads = []
      if ((n / 2) % 2 === 0) {
        leads = make_even_lead()
      } else {
        leads = make_odd_lead()
      }
  
      var channle = make_channle()
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell('Double_Channle_FDSOI')
      cell.add(leads)
      cell.add(channle)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.FinFET = function (
      layer_barrier = default_layer_barrier,
      datatype_barrier = 0,
      layer_plunger = default_layer_plunger,
      datatype_plunger = 0,
      layer_source = default_layer_source,
      datatype_source = 0,
      layer_fin = default_layer_fin,
      datatype_fin = 0,
      n = 3,
      w = 0.04,
      d = 0.01,
  
      // h = 0.02,
      h0 = 0.06,
      h1 = 0.67,
      h2 = 0.5,
      b = 0.3,
      a1 = 0.6,
      a2 = 1,
      D1 = 0.4,
      H = 0.47,
      L = 4,
      g = 0.04,
      L0 = 20,
      L1 = 1,
      H0 = 0.2
    ) {
      const d1 = w + 2 * d
  
      function make_plunger() {
        var path = new QGdstk.Curve([0, 0])
        path.commands(['l', 0, -h0, 'l', D1, 0, 'l', 0, -h1, 'l', -b, -b])
  
        path.commands(['L', D1 + ((n + 1) * w + n * d1) / 2 - (a2 / 2 + n * a1), -(h0 + h1 + b)])
  
        var count = 0
  
        for (let i = 0; i < n; i++) {
          path.commands(['l', 0, -h2, 'l', a1, 0])
        }
        path.commands(['l', 0, -h2, 'l', a2, 0, 'l', 0, h2])
        for (let i = 0; i < n; i++) {
          path.commands(['l', a1, 0, 'l', 0, h2])
        }
  
        path.commands(['L', D1 + ((n + 1) * w + n * d1) + b, -(h0 + h1 + b)])
        path.commands(['l', -b, b, 'l', 0, h1, 'l', D1, 0, 'l', 0, h0])
        var polygon = new QGdstk.Polygon(path.points(), layer_plunger, datatype_plunger)
        polygon.translate([-(D1 + ((n + 1) * w + n * d1) / 2), h0 / 2])
        return polygon
      }
  
      function make_barrier() {
        var polygons = []
  
        var left_leads = []
        var leftmost_path = new QGdstk.Curve([0, 0])
        leftmost_path.commands(['l', 0, -h0, 'l', D1, 0, 'l', 0, -h1, 'l', -b, -b])
  
        leftmost_path.commands(['L', D1 + ((n + 1) * w + n * d1) / 2 - (a2 / 2 + n * a1), -(h0 + h1 + b), 'l', 0, -h2, 'L', D1 + w - b, -(h0 + h1 + b + h2), 'l', b, b, 'L', D1 + w, 0])
        var leftmost = new QGdstk.Polygon(leftmost_path.points(), layer_barrier, datatype_barrier)
        leftmost.translate([-(D1 + ((n + 1) * w + n * d1) / 2), h0 / 2])
        left_leads.push(leftmost)
  
        var count = 0
        // even
        if (n % 2 === 0) {
          count = (n - 1) / 2 - 1
  
          var central_path = new QGdstk.Curve([0, 0])
          central_path.commands(['l', 0, -(h0 + h1 + n * h2), 'l', -b, -b, 'l', -((a2 - w) / 2 - b), 0, 'l', 0, -h2, 'l', a2, 0, 'l', 0, h2, 'l', -((a2 - w) / 2 - b), 0, 'l', -b, b, 'L', w, 0])
          var central = new QGdstk.Polygon(central_path.points(), layer_barrier, datatype_barrier)
          central.translate([-w / 2, h0 / 2])
          polygons.push(central)
        } else {
          // odd
          count = (n - 1) / 2
        }
  
        for (let i = 0; i < count; i++) {
          var lead_path = new QGdstk.Curve([0, 0])
          lead_path.commands(['l', 0, -(h0 + h1 + (i + 1) * 2 * h2), 'l', -b, -b, 'L', ((n - 1) / 2 - i) * w + ((n - 1) / 2 - i - 1) * d1 - (a2 - d1) / 2 - ((n - 1) / 2 - i - 1) * 2 * a1 - a1, -(h0 + h1 + (i + 1) * 2 * h2 + b), 'l', 0, -h2, 'L', w - b, -(h0 + h1 + (i + 1) * 2 * h2 + b + h2), 'l', b, b, 'L', w, 0])
          var lead = new QGdstk.Polygon(lead_path.points(), layer_barrier, datatype_barrier)
          lead.translate([-(((n - 1) / 2 - i) * w + ((n - 1) / 2 - i - 1) * d1 + d1 / 2), h0 / 2])
          left_leads.push(lead)
        }
  
        //right part
        for (let i in left_leads) {
          var lead = left_leads[i].copy()
          lead.mirror([0, 0], [0, 1])
          polygons.push(lead)
        }
        polygons = polygons.concat(left_leads)
        return polygons
      }
  
      function make_source_drain() {
        var polygons = []
        var left_bar = new QGdstk.Polygon(
          [
            [0, 0],
            [L1 + L, 0],
            [L1 + L, -h0],
            [0, -h0],
          ],
          layer_source,
          datatype_source
        )
        var left_box = new QGdstk.Polygon(
          [
            [0, -h0],
            [L1, -h0],
            [L1, -(h0 + H)],
            [0, -(h0 + H)],
          ],
          layer_source,
          datatype_source
        )
  
        left_bar.translate([-(L1 + L + g + D1 + (n * d1 + (n + 1) * w) / 2), h0 / 2])
        left_box.translate([-(L1 + L + g + D1 + (n * d1 + (n + 1) * w) / 2), h0 / 2])
        var right_bar = left_bar.copy()
        var right_box = left_box.copy()
        right_bar.mirror([0, 0], [0, 1])
        right_box.mirror([0, 0], [0, 1])
  
        polygons.push(left_bar, left_box, right_bar, right_box)
        return polygons
      }
  
      // cell
      var plunger = make_plunger()
      var barrier = make_barrier()
      var source_drain = make_source_drain()
      var fin = new QGdstk.Polygon(
        [
          [-L0 / 2, -H0 / 2],
          [L0 / 2, -H0 / 2],
          [L0 / 2, H0 / 2],
          [-L0 / 2, H0 / 2],
        ],
        layer_fin,
        datatype_fin
      )
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      var cell = new QGdstk.Cell('FinFET')
      cell.add(plunger)
      cell.add(barrier)
      cell.add(source_drain)
      cell.add(fin)
      cell.add(O)
      return cell
    }
  
    PDKDevice.prototype.ReflectionResonator = function (
      layer_pad = default_layer_pad,
      datatype_pad = 0,
      layer_resistor = default_layer_resistor,
      datatype_resistor = 0,
      layer_resonator = default_layer_resonator,
      datatype_resonator = 0,
      GapType = GATETYPE.GAP_TYPE_ONE_TOP,
      L11 = 277.25,
      w = 20,
      s = 0.35
    ) {
      const L1 = 200;
      const L2 = 3;
      const L3 = 109;
      const L4 = 15;
      const L5 = 12;
      const L6 = 34.5;
      const L7 = 0.5;
      const L8 = 22.25;
      const L9 = 60;
      const L10 = 0.5;
  
      const L12 = 297.5;
      const L13 = 410;
      const L14 = 220;
      const L15 = 240;
      const L16 = 11;
      const D2 = 2;
  
      function line_intersection(line1, line2) {
        var xdiff = [line1[0][0] - line1[1][0], line2[0][0] - line2[1][0]]
        var ydiff = [line1[0][1] - line1[1][1], line2[0][1] - line2[1][1]]
  
        function det(a, b) {
          return a[0] * b[1] - a[1] * b[0]
        }
  
        var div = det(xdiff, ydiff)
        if (div == 0) throw 'lines do not intersect'
  
        var d = [det(line1[0], line1[1]), det(line2[0], line2[1])]
        var x = det(d, xdiff) / div
        var y = det(d, ydiff) / div
        return [x, y]
      }
  
      const D1 = 2 * w + s - 2 * L2
      var cell = new QGdstk.Cell('ReflectionResonator')
  
      var pad_outter = new QGdstk.Polygon([
        [0, 0],
        [L12, 0],
        [L12 + L1, L13 / 2.0 - D1 / 2.0 - L2],
        [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
        [L12, L13],
        [0, L13],
      ])
      var pad_inner = new QGdstk.Polygon([
        [L12 - L14, L13 / 2.0 - L15 / 2.0],
        [L12, L13 / 2.0 - L15 / 2.0],
        [L12 + L1, L13 / 2.0 - D1 / 2.0],
        [L12 + L1, L13 / 2.0 + D1 / 2.0],
        [L12, L13 / 2.0 + L15 / 2.0],
        [L12 - L14, L13 / 2.0 + L15 / 2.0],
      ])
      var pad = QGdstk.boolean(pad_outter, pad_inner, 'not', 1e-3, layer_pad, datatype_pad)
      cell.add(pad)
  
      var rect = new QGdstk.Polygon(
        [
          [L12 - L14, L13 / 2.0 - L15 / 2.0],
          [L12, L13 / 2.0 - L15 / 2.0],
          [L12, L13 / 2.0 + L15 / 2.0],
          [L12 - L14, L13 / 2.0 + L15 / 2.0],
        ],
        layer_resistor,
        datatype_resistor
      )
      cell.add(rect)
  
      var vertical_line = [
        [L12 + L1 - L16, 0],
        [L12 + L1 - L16, 100],
      ]
      var cross_line1 = [
        [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
        [L12, L13],
      ]
      var cross_line2 = [
        [L12 + L1, L13 / 2.0 + D1 / 2.0],
        [L12, L13 / 2.0 + L15 / 2.0],
      ]
      var cross_point1 = line_intersection(vertical_line, cross_line1)
      var cross_point2 = line_intersection(vertical_line, cross_line2)
  
      var bg_rect_left = new QGdstk.Polygon(
        [
          [L12 + L1 - L16, cross_point1[1]],
          [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
          [L12 + L1 + L3, L13 / 2.0 + D1 / 2.0 + L2],
          [L12 + L1 + L3, L13 / 2.0 + D1 / 2.0],
          [L12 + L1, L13 / 2.0 + D1 / 2.0],
          [L12 + L1 - L16, cross_point2[1]],
        ],
        layer_resonator,
        datatype_resonator
      )
      var bg_rect_mirror = bg_rect_left.copy()
      bg_rect_mirror.mirror([0, L13 / 2.0], [100, L13 / 2.0])
      bg_rect_left = new QGdstk.boolean(bg_rect_left, bg_rect_mirror, 'or', 1e-3, layer_resonator, datatype_resonator)
      cell.add(bg_rect_left)
  
      const PADDING_LEFT = L12 + L1 + L3
      const PADDING_BOTTOM = L13 / 2.0 - D1 / 2.0 - L2
      const TOP = L13 / 2.0 + D1 / 2.0 + L2
      const V_CENTER = L13 / 2.0
      const CENTER_EMPTY_V_CENTER = TOP - w - s / 2.0
      var bg_rect = QGdstk.rectangle([PADDING_LEFT, PADDING_BOTTOM], [PADDING_LEFT + D2 + L7 + L8 + L10 + L11, PADDING_BOTTOM + D1 + L2 * 2], layer_resonator, datatype_resonator)
  
      var empty_1 = QGdstk.rectangle([PADDING_LEFT + D2, V_CENTER - L6 / 2.0], [PADDING_LEFT + D2 + L7, V_CENTER + L6 / 2.0])
      var bg_rect = QGdstk.boolean(bg_rect, empty_1, 'not', 1e-3, layer_resonator, datatype_resonator)
  
      var empty_2 = QGdstk.rectangle([PADDING_LEFT + D2 + L7, CENTER_EMPTY_V_CENTER - s / 2.0], [PADDING_LEFT + D2 + L7 + L8 + L10 + L11, CENTER_EMPTY_V_CENTER + s / 2.0])
      bg_rect = QGdstk.boolean(bg_rect, empty_2, 'not', 1e-3, layer_resonator, datatype_resonator)
  
      var O1;
  
      var gap_center_x = PADDING_LEFT + D2 + L7 + L8 + L10 / 2.0
      if ((GATETYPE.GAP_TYPE_ONE_TOP & GapType) === GATETYPE.GAP_TYPE_ONE_TOP) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, TOP], [gap_center_x + L4 / 2.0, TOP + L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER + L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER + L9 / 2.0 - 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_ONE_BOTTOM & GapType) === GATETYPE.GAP_TYPE_ONE_BOTTOM) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, PADDING_BOTTOM], [gap_center_x + L4 / 2.0, PADDING_BOTTOM - L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER - L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER - L9 / 2.0 + 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_TWO_TOP & GapType) === GATETYPE.GAP_TYPE_TWO_TOP || (GATETYPE.GAP_TYPE_TWO_BOTTOM & GapType) === GATETYPE.GAP_TYPE_TWO_BOTTOM) {
        gap_center_x = PADDING_LEFT + L11 + L10 / 2.0
      }
      if ((GATETYPE.GAP_TYPE_TWO_TOP & GapType) === GATETYPE.GAP_TYPE_TWO_TOP) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, TOP], [gap_center_x + L4 / 2.0, TOP + L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER + L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER + L9 / 2.0 - 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_TWO_BOTTOM & GapType) === GATETYPE.GAP_TYPE_TWO_BOTTOM) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, PADDING_BOTTOM], [gap_center_x + L4 / 2.0, PADDING_BOTTOM - L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER - L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER - L9 / 2.0 + 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
  
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      cell.add(bg_rect)
      cell.add(O)
      cell.add(O1)
      return cell
    }
  
    PDKDevice.prototype.TransmissionResonator = function (
      layer_pad = default_layer_pad,
      datatype_pad = 0,
      layer_resistor = default_layer_resistor,
      datatype_resistor = 0,
      layer_resonator = default_layer_resonator,
      datatype_resonator = 0,
      GapType = GATETYPE.GAP_TYPE_ONE_TOP | GATETYPE.GAP_TYPE_TWO_BOTTOM,
      L11 = 654.5,
      w = 20,
      s = 0.35
    ) {
      const L1 = 200;
      const L2 = 3;
      const L3 = 109;
      const L4 = 15;
      const L5 = 12;
      const L6 = 34.5;
      const L7 = 0.5;
      const L8 = 22.25;
      const L9 = 60;
      const L10 = 0.5;
  
      const L12 = 297.5;
      const L13 = 410;
      const L14 = 220;
      const L15 = 240;
      const L16 = 11;
      const D2 = 2;
  
      function line_intersection(line1, line2) {
        var xdiff = [line1[0][0] - line1[1][0], line2[0][0] - line2[1][0]]
        var ydiff = [line1[0][1] - line1[1][1], line2[0][1] - line2[1][1]]
  
        function det(a, b) {
          return a[0] * b[1] - a[1] * b[0]
        }
  
        var div = det(xdiff, ydiff)
        if (div == 0) throw 'lines do not intersect'
  
        var d = [det(line1[0], line1[1]), det(line2[0], line2[1])]
        var x = det(d, xdiff) / div
        var y = det(d, ydiff) / div
        return [x, y]
      }
  
      const D1 = 2 * w + s - 2 * L2
      var cell = new QGdstk.Cell('TransmissionResonator')
  
      var pad_outter = new QGdstk.Polygon([
        [0, 0],
        [L12, 0],
        [L12 + L1, L13 / 2.0 - D1 / 2.0 - L2],
        [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
        [L12, L13],
        [0, L13],
      ])
      var pad_inner = new QGdstk.Polygon([
        [L12 - L14, L13 / 2.0 - L15 / 2.0],
        [L12, L13 / 2.0 - L15 / 2.0],
        [L12 + L1, L13 / 2.0 - D1 / 2.0],
        [L12 + L1, L13 / 2.0 + D1 / 2.0],
        [L12, L13 / 2.0 + L15 / 2.0],
        [L12 - L14, L13 / 2.0 + L15 / 2.0],
      ])
      var pad = QGdstk.boolean(pad_outter, pad_inner, 'not', 1e-3, layer_pad, datatype_pad)
      cell.add(pad)
  
      var rect = new QGdstk.Polygon(
        [
          [L12 - L14, L13 / 2.0 - L15 / 2.0],
          [L12, L13 / 2.0 - L15 / 2.0],
          [L12, L13 / 2.0 + L15 / 2.0],
          [L12 - L14, L13 / 2.0 + L15 / 2.0],
        ],
        layer_resistor,
        datatype_resistor
      )
      cell.add(rect)
  
      var vertical_line = [
        [L12 + L1 - L16, 0],
        [L12 + L1 - L16, 100],
      ]
      var cross_line1 = [
        [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
        [L12, L13],
      ]
      var cross_line2 = [
        [L12 + L1, L13 / 2.0 + D1 / 2.0],
        [L12, L13 / 2.0 + L15 / 2.0],
      ]
      var cross_point1 = line_intersection(vertical_line, cross_line1)
      var cross_point2 = line_intersection(vertical_line, cross_line2)
  
      var bg_rect_left = new QGdstk.Polygon(
        [
          [L12 + L1 - L16, cross_point1[1]],
          [L12 + L1, L13 / 2.0 + D1 / 2.0 + L2],
          [L12 + L1 + L3, L13 / 2.0 + D1 / 2.0 + L2],
          [L12 + L1 + L3, L13 / 2.0 + D1 / 2.0],
          [L12 + L1, L13 / 2.0 + D1 / 2.0],
          [L12 + L1 - L16, cross_point2[1]],
        ],
        layer_resonator,
        datatype_resonator
      )
      var bg_rect_mirror = bg_rect_left.copy()
      bg_rect_mirror.mirror([0, L13 / 2.0], [100, L13 / 2.0])
      bg_rect_left = new QGdstk.boolean(bg_rect_left, bg_rect_mirror, 'or', 1e-3, layer_resonator, datatype_resonator)
      cell.add(bg_rect_left)
  
      const PADDING_LEFT = L12 + L1 + L3
      const PADDING_BOTTOM = L13 / 2.0 - D1 / 2.0 - L2
      const TOP = L13 / 2.0 + D1 / 2.0 + L2
      const V_CENTER = L13 / 2.0
      const CENTER_EMPTY_V_CENTER = TOP - w - s / 2.0
      var bg_rect = QGdstk.rectangle([PADDING_LEFT, PADDING_BOTTOM], [PADDING_LEFT + 2 * (D2 + L7 + L8 + L10) + L11, PADDING_BOTTOM + D1 + L2 * 2], layer_resonator, datatype_resonator)
  
      var empty_1 = QGdstk.rectangle([PADDING_LEFT + D2, V_CENTER - L6 / 2.0], [PADDING_LEFT + D2 + L7, V_CENTER + L6 / 2.0])
      var bg_rect = QGdstk.boolean(bg_rect, empty_1, 'not', 1e-3, layer_resonator, datatype_resonator)
  
      var empty_2 = QGdstk.rectangle([PADDING_LEFT + D2 + L7, CENTER_EMPTY_V_CENTER - s / 2.0], [PADDING_LEFT + 2 * (D2 + L7 + L8 + L10) + L11 - D2 - L7, CENTER_EMPTY_V_CENTER + s / 2.0])
      bg_rect = QGdstk.boolean(bg_rect, empty_2, 'not', 1e-3, layer_resonator, datatype_resonator)
  
      var empty_3 = QGdstk.rectangle([PADDING_LEFT + 2 * (D2 + L7 + L8 + L10) + L11 - D2 - L7, V_CENTER - L6 / 2.0], [PADDING_LEFT + 2 * (D2 + L7 + L8 + L10) + L11 - D2, V_CENTER + L6 / 2.0])
      bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
  
      var O1, O2;
  
      var gap_center_x = PADDING_LEFT + D2 + L7 + L8 + L10 / 2.0
      if ((GATETYPE.GAP_TYPE_ONE_TOP & GapType) === GATETYPE.GAP_TYPE_ONE_TOP) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, TOP], [gap_center_x + L4 / 2.0, TOP + L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER + L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER + L9 / 2.0 - 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_ONE_BOTTOM & GapType) === GATETYPE.GAP_TYPE_ONE_BOTTOM) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, PADDING_BOTTOM], [gap_center_x + L4 / 2.0, PADDING_BOTTOM - L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER - L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O1 = new QGdstk.Label("O1", [gap_center_x, V_CENTER - L9 / 2.0 + 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_TWO_TOP & GapType) === GATETYPE.GAP_TYPE_TWO_TOP || (GATETYPE.GAP_TYPE_TWO_BOTTOM & GapType) === GATETYPE.GAP_TYPE_TWO_BOTTOM) {
        gap_center_x = PADDING_LEFT + D2 + L7 + L8 + L10 + L11 + L10 / 2.0
      }
      if ((GATETYPE.GAP_TYPE_TWO_TOP & GapType) === GATETYPE.GAP_TYPE_TWO_TOP) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, TOP], [gap_center_x + L4 / 2.0, TOP + L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER + L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O2 = new QGdstk.Label("O2", [gap_center_x, V_CENTER + L9 / 2.0 - 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      if ((GATETYPE.GAP_TYPE_TWO_BOTTOM & GapType) === GATETYPE.GAP_TYPE_TWO_BOTTOM) {
        var small_rect = QGdstk.rectangle([gap_center_x - L4 / 2.0, PADDING_BOTTOM], [gap_center_x + L4 / 2.0, PADDING_BOTTOM - L5])
        bg_rect = QGdstk.boolean(bg_rect, small_rect, 'or', 1e-3, layer_resonator, datatype_resonator)
  
        var empty_3 = QGdstk.rectangle([gap_center_x - L10 / 2.0, CENTER_EMPTY_V_CENTER], [gap_center_x + L10 / 2.0, V_CENTER - L9 / 2.0])
        bg_rect = QGdstk.boolean(bg_rect, empty_3, 'not', 1e-3, layer_resonator, datatype_resonator)
        O2 = new QGdstk.Label("O2", [gap_center_x, V_CENTER - L9 / 2.0 + 11], 's', 0, 1, false, default_layer_keypoint, 0)
      }
      cell.add(bg_rect)
  
      const MIRROR_X = PADDING_LEFT + D2 + L7 + L8 + L10 + L11 / 2.0
      const MIRROR_LINE = [
        [MIRROR_X, 0],
        [MIRROR_X, 100],
      ]
  
      var pad_r = []
      for (let i in pad) {
        var right_pad = pad[i].copy()
        right_pad.mirror(MIRROR_LINE[0], MIRROR_LINE[1])
        pad_r.push(right_pad)
      }
      // var pad_r = pad[0].mirror(MIRROR_LINE[0], MIRROR_LINE[1])
      var pad_inner_r = pad_inner.copy()
      pad_inner_r.mirror(MIRROR_LINE[0], MIRROR_LINE[1])
      cell.add(QGdstk.boolean(pad_r, pad_inner_r, 'not', 1e-3, layer_pad, datatype_pad))
  
      var rect2 = rect.copy()
      rect2.mirror(MIRROR_LINE[0], MIRROR_LINE[1])
      cell.add(rect2)
  
      var bg_rect_right = []
      for (let i in bg_rect_left) {
        var rect_right = bg_rect_left[i].copy()
        rect_right.mirror(MIRROR_LINE[0], MIRROR_LINE[1])
        bg_rect_right.push(rect_right)
      }
  
      var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, default_layer_keypoint, 0);
      O.set_gds_property(1, kp_pro_str);
  
      cell.add(bg_rect_right)
      cell.add(O)
      cell.add([O1, O2])
      return cell
    }
    // run = function () {
    //   var funcs = [
    //     stepper_marker,
    //     XZ_transform,
    //     X_line,
    //     Z_line_1,
    //     Z_line_2,
    //     Cap1_Qubit_1,
    //     Cap1_Qubit_2,
    //     Cap1_Qubit_3,
    //     Cap1_Qubit_4,
    //     Cap1_Qubit_5,
    //     Cap1_Qubit_6,
    //     Cap1_Qubit_7,
    //     Resonator_FPDesign_1,
    //     Resonator_FPDesign_2,
    //     ChipFrame_1,
    //     ChipFrame_2,
    //     ChipFrame_3,
    //     Indium_1,
    //     Indium_2,
    //     Indium_3,
    //     Inlinepad_1,
    //     Inlinepad_2,
    //     Inlinepad_3,
    //     Interdigital_cap_1,
    //     Interdigital_cap_2,
    //     AB_1,
    //     AB_2,
    //     AB_3,
    //     Cross_AB_1,
    //     Cross_AB_2,
    //     Cross_AB_3,
    //     Squid_1,
    //     Squid_2,
    //     Squid_3,
    //     Cap1_Qubit_Qubit_1,
    //     Cap1_Qubit_Qubit_2,
    //     Cap1_Qubit_Resonator_1,
    //     Cap1_Qubit_Resonator_2,
    //     Cap1_Qubit_Resonator_3,
    //     Cap1_Qubit_Resonator_4,
    //     WBPad,
    //     JJTest,
    //     Marker,
    //     CPW,
    //     CPW_corner,
    //   ]
    //   funcs.forEach(f => {
    //     var lib = new QGdstk.Library();
    //     var cell = f();
    //     lib.add(cell);
    //     var cell_name = cell.name;
    //     var fn_name = cell_name + ".gds"
    //     lib.write_gds(fn_name)
    //     download_file(fn_name)
    //   });
  
    //   // download_file(SiMos_Single_SET())
    //   // download_file(SiMos_Double_SET())
    //   // download_file(SiGe())
    //   // download_file(GaAs())
    //   // download_file(Single_Channle_FDSOI())
    //   // download_file(Double_Channle_FDSOI())
    //   // download_file(FinFET())
    //   // download_file(ReflectionResonator())
    //   // download_file(TransmissionResonator())
    // }
  }
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = PDKDevice
  } else {
    window.PDKDevice = PDKDevice
  }
})()
