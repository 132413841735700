export const messages = {
  // 首页
  qlayoutWelcome: 'Welcome to QLayout',
  quickStart: 'Quick Start',
  instructions: 'Instructions',
  tutorial: 'Tutorial',
  new: 'New',
  oldVersion: 'Old Version',
  video_1: '24-bit Layout Drawing ',
  video_2: 'Teamwork',
  video_3: 'Team 1 bit schematic layout drawing',
  video_4: 'Intelligent tools',
  video_5: 'Load PDK/Generate PDK',
  video_6: 'Automatic wiring',
  video_7: 'Team collaboration and permission settings',
  video_8: '3D View',
  loginTips: 'The system supports Google Browser Core and Microsoft Edge Browser Core',
  transforming: 'Data migration in progress',
  transformSuccess: 'Migration successful, please reopen a new version of the file',
  transformError: 'Migration failed',
  //弹窗,文件
  yes: 'Yes',
  no: 'No',
  tips: 'Tips',
  sure: 'OK',
  cancel: 'Cancel',
  pleaseChoose: 'Please Choose',
  enterProjectName: 'Please enter the project name',
  enterFileName: 'Please enter the file name',
  enterCellName: 'Please enter cell name',
  loadProject: 'Please load project',
  creatSucces: 'Creat succes',
  fileNameError: 'Wrong！ please enter a file name that starts with a letter and consists of letters, numbers or underscores',
  fileFormatError: 'File format should be GDSII and cannot be empty',
  fileImportFailed: 'It only supports importing files less than 500kb，file import failed',
  projectNumberLimit: 'The number of project cannot exceed 3',
  fileNumberLimit: 'The number of file cannot exceed 3 in one project',
  selectFile: 'Please select file',
  noFiles: 'No Files has been created',
  newCell: 'New Cell',
  deleteFile: 'Delete File',
  deleteCell: 'Delete Cell',
  deleteProject: 'Delete Project',
  cancelInput: 'Cancel input',
  FilenameEmpty: 'File name cannot be empty',
  nameCannotEmpty: 'Name cannot be empty',
  empty: 'Empty',
  projectPermanentlyDeleted: 'This project will be permanently deleted，are you sure？',
  deleteSuccess: 'Delete success',
  deleteCancel: 'Delete cancel',
  filePermanentlyDeleted: 'This file will be permanently deleted，are you sure？',
  enterCellName: 'Please enter cell name',
  cellPermanentlyDeleted: 'This cell will be permanently deleted，are you sure？',
  saveChanges: 'Do you want to save your changes？',
  closeWithoutSaving: 'Close without saving',
  closeCancel: 'Close cancel',
  switchingCellFiles: "Switching cell files under different projects here will lead to switching failure. For cell files under different projects, please switch in 'file list'!",
  notShownThisTime: 'Not shown this time',
  alreadyKnown: 'Already known',
  cellTooLarge: 'The cell is too large to save',
  waitLoading: 'Loading，please wait',
  cellNumberError: 'The number of cell cannot exceed 1 in one file',
  loadCellFirst: 'please load cell first',
  incorrectFormat: 'incorrect format',
  itemBelong: 'Project Ownership',
  person: 'Person',
  team: 'Team',
  userAndTeamName: 'User/Team name',
  projectName: 'Project Name',
  fileName: 'File Name',
  // fileName: 'Layout File Name',
  fileType: 'File Type',
  LayoutFile: 'Layout File',
  SchematicFile: 'Schematic File',
  cellType: 'Cell Type',
  fileCell: 'Layout Cell',
  componentCell: 'Component Cell',
  cellName: 'Component Name',
  SchematicName: 'Schematic Name',
  Layout: 'Layout ',
  personName: 'User Name',
  teamName: 'Team Name',
  pleaseEnterProjectName: 'Please enter the name',
  pleaseEnterFileName: 'Please enter the name',
  pleaseEnterCellName: 'Please enter the name',
  pleaseEnterSchematicName: 'Please enter the name',
  pleaseEnterSignName: 'Please enter the name',
  pleaseEnterPinName: 'Please enter the name',
  pleaseEnterType: 'Please enter the type',
  pleaseEnterAngle: 'Please enter the angle',
  teamDetail: 'Team',
  noTeamInfo: "There's no team yet, let's create a team",
  sMember: "'s member",
  createTime: 'Create time',
  updateTime: 'Update time',
  editAndDelete: 'Edit&Delete',
  readOnly: 'Read only',
  invisible: 'Invisible',
  inherit: 'Inherit from superior',
  teamRole: 'Role of team',
  projectPermission: 'Project Permission',
  collaborator: 'Collaborator',
  manager: 'Administrator',
  owner: 'Owner',
  canUsed: 'Available',
  canEdit: 'Editable',
  batchTeamRoleManage: 'Batch Team Role Management',
  batchProjectManage: 'Batch Files Permissions Management',
  batchDeviceManage: 'Batch  Library Permissions Management',
  devicePower: 'Library Permissions',
  libraryPower: 'Library Permissions',
  batchDeletion: 'Batch Deletion',
  uploadStatus: 'Upload State',
  notUpload: 'Not Upload',
  alreadyUpload: 'Uploaded',
  uploadTime: 'Upload time',
  remark: 'Note',
  teamName: 'Team Name',
  teamUrl: 'Link',
  shareUrl: 'Link',
  cessionMember: 'Transferred to',
  personalList: 'Personal project directory',
  uploadFile: 'Upload File',
  upload: 'Upload',
  originPassword: 'Origin Password',
  rename: 'Rename',
  instance: 'Instance',
  wrong: 'Wrong',
  userProjectPermission: 'User does not have permission for this project',
  userFilePermission: 'User does not have permission for this file',
  userCellPermission: 'User does not have permission for this cell',
  fileCopySucceeded: 'The file copy to the project succeeded',
  cellCopySucceeded: 'Cell copy to file succeeded',
  layoutSplitSuccessfully: 'Layout split successfully',
  NoProjectsUnderUser: 'No projects under the personal user',
  NoProjectsUnderTeam: 'No projects under the team',
  cannotImport: 'No editing permission, can not  import operation',
  successfullyModified: 'Successfully modified',
  saveAsUnitSucceeded: 'Save as unit succeeded',
  importSucceeded: 'Import Succeeded',
  createSucceeded: 'Create new cell succeeded',
  selectTeamFirst: 'Please select a team first',
  unframedData: 'Unframed data',
  fileTip1: 'Character should be greater than one, and only supports Chinese, English, numbers, and symbols + - _ & () []. ~',
  fileTip2: 'Character should be greater than one, support only supports English, number, and symbols + - _ & () []. ~',
  fileTip3: 'The length of tag name cannot exceed 20!',
  fileTip4: 'Enter at least one label',
  fileTip5: 'Please select a team first',
  fileTip6: 'Please open at least one unit first',
  fileTip7: 'Warning, the layout is being split, please wait~',
  fileTip8: 'The user is not in the current project',
  fileTip9: 'Please close the drawing board opened in read-only mode first',
  fileTip10: 'Please enter the correct pin name',
  fileTip11: 'Someone else is using it',
  fileTip12: 'Unit being used by others',
  fileTip13: 'The maximum layer number is 4294967295',
  fileTip14: 'Please select a different layer',
  fileTip15: 'The unit data has not changed and does not need to be saved',
  fileTip16: 'Missing unit data',

  OpeninReadOnly: 'Open in read-only mode',
  PersonalSymbolLibrary: 'Personal Symbol Library',
  TeamSymbolLibrary: 'Team Symbol Library',
  commonSymbolLibrary: 'Common Symbol Library',
  saveToSymbolLibrary: 'Upload to common symbol library',
  duplication: 'The name already exists',
  shareNoFile: 'Directory error or no acceptable files',
  selectNone: 'The list that can be added is empty',
  importGDSSizeError: 'The size cannot exceed 25MB',
  importSymbolSizeError: 'The size cannot exceed 5MB',
  importDXFSizeError: 'The size cannot exceed 2MB',

  // 顶部导航栏
  file: 'File',
  toDraw: 'Draw',
  share: 'Share',
  setUp: 'Set Up',
  view: 'View',
  check: 'Check',
  help: 'Help',
  newProject: 'New Project',
  newFile: 'New File',
  openProject: 'Open Project',
  importPDK: 'Import PDK',
  importGDS: 'Import GDS',
  importDXF: 'Import DXF',
  exportGDS: 'Export GDS',
  exportDXF: 'Export DXF',
  import: 'Import',
  export: 'Export',
  saveCell: 'Save',
  recentBrowsing: 'Recent Browsing',

  edit: 'Edit',
  copy: 'Copy',
  cut: 'Cut',
  paste: 'Paste',
  undo: 'Undo',
  redo: 'Redo',
  browsingRecord: 'No Browsing Record',

  saveAs: 'Save As',
  checkSchematicDiagram: 'Check',
  openSymbol: 'Open Symbol',
  Symbol: 'Symbol',
  symbolNotExist: 'The current symbol does not exist',
  net: 'Net',
  encapsulation: 'Encapsulation',
  outputNetlist: 'Output Netlist',
  import: 'Import',
  export: 'Export',
  importSchFile: 'Import SchemaFile',
  exportSchFile: 'Export SchemaFile',

  download: 'Download',
  displayLabel: 'Display Label',
  loadPDK: 'Load PDK',
  generatePDK: 'Generate PDK',
  layerFile: 'Layer File',
  designFile: 'Design rule checking Document',
  libraryFile: 'Library File',
  fileOf3D: '3D process files',
  designTips: 'Please select the compiled file',
  layerFileTips: 'Please open the layout drawing board',
  g_personalLibrary: 'Personal Library',
  g_teamLibrary: 'Team Library',
  g_PDKLibrary: 'PDK Library',
  g_publicLibrary: 'Public Library',
  g_officialLibrary: 'Official Library',

  // 编辑
  select: 'Select',
  Move: 'Move',
  Rotate: 'Rotate',
  draw: 'draw',
  Flip: 'Flip',
  FullSelect: 'Full Select',
  PartialSelect: 'Partial Select',
  FlipHorizontal: 'Flip Horizontal',
  FlipVertical: 'Flip Vertical',
  QuickAlign: 'Quick Align',
  PointAlign: 'Point Align',
  LineAlign: 'Line Align',
  Align: 'Align',
  alignLeft: 'Align Left',
  alignRight: 'Align Right',
  alignTop: 'Align Top',
  alignBottom: 'Align Bottom',
  alignHorizontalCenter: 'Align Horizontal Center',
  alignVerticalCenter: 'Align Vertical Center',
  Center: 'Center',
  Up: 'Up',
  Down: 'Down',
  Left: 'Left',
  Right: 'Right',
  upperLeft: 'Upper Left',
  rightUpper: 'Right Upper',
  bottomLeft: 'Bottom Left',
  lowerRight: 'Lower Right',
  BasicTools: 'Basic Tools',
  Slice: 'Slice',
  HorizontalSlice: 'Horizontal Slice',
  VerticalSlice: 'Vertical Slice',
  Nibble: 'Nibble',
  Merge: 'Merge',
  AdvancedTools: 'Advanced Tools',
  Align: 'Align',
  Boolean: 'Boolean',
  Array: 'Array',
  ArrayCopy: 'ArrayCopy',
  Hierarchy: 'Hierarchy',
  DescendRead: 'Descend Read',
  DescendEdit: 'Descend Edit',
  Return: 'Return',
  ReturntoTop: 'Return to Top',
  MakeCell: 'Make Cell',
  Flatten: 'Flatten',
  TextContent: 'Text Content',
  layerLocation: 'Layer Location',
  SelectedLayer: 'Selected Layer',
  Defined: 'Defined',
  LabelSettings: 'Label Settings',
  fontSize: 'Size',
  createKeyPoint: 'Create Key Point',
  createLabel: 'Create Label',
  AlignmentDistance: 'Alignment distance',
  Alignment: 'Alignment',
  axisLocation: 'The Symmetry Axis is Located Relative to the Center Point',
  OriginalGraphics: ' Original Graphics',
  Retain: 'Retain',
  rowsCells: 'Rows/Columns',
  row: 'Rows',
  column: 'Columns',
  RowVector: 'Row Vector',
  ColumnVector: 'Column Vector',
  path: 'Path',
  type: 'Type',
  Flattten: 'Flattten',
  OneLayer: 'One Layer',
  all: 'All',
  ParameterizedCell: 'Parameterized Cell',
  SavetoLibrary: 'Save to Library',
  Group: 'Group',
  teamSelected: 'Select Team',
  coverWarning: 'Cover Warning',
  DrawWire: 'Draw Wire',
  RotatedClockwise: 'Rotated Clockwise 90°',
  RotatedCounterclockwise: 'Rotated Counterclockwise 90°',
  create: 'Create',
  CouplingLine: 'Coupling Line',
  AngleSetting: 'Angle Setting',
  CreatePin: 'Create Pin',
  label: 'label',
  InstanceName: 'Instance Name',
  LabelName: 'Label Name',
  KeyParameter: 'Key Parameter',
  PinName: 'Pin Name',
  PortName: 'Port Name',
  OtherLabel: 'Other Label',
  Highlight: 'Highlight',
  HighlightNetwork: 'Highlight Network',
  RemoveHighlights: 'Remove Highlights',
  DisplaySettings: 'Display Settings',
  CheckSettings: 'Check Settings',
  Pin: 'Pin',
  input: 'Input',
  inputAndOutput: 'Input&output',
  checkContent: 'Check Content',
  checkLevel: 'Check Level',
  ignore: 'Ignore',

  // 绘制
  Line: 'Line',
  Rectangle: 'Rectangle',
  Polygon: 'Polygon',
  Circle: 'Circle',
  Text: 'Text',
  AuxiliaryTool: 'Auxiliary Tool',
  Rule: 'Rule',
  Label: 'Label',
  KeyPoint: 'Key Point',
  BoldTypeface: 'Bold Typeface',
  SongTypeface: 'Song Typeface',

  //工具
  tool: 'Tool',
  bigLayoutLayerTool: 'Big Layout Layer Tool',
  SplittheLayoutbylayer: 'Split the Layout by layer',
  connectLine: 'Connect Line',
  Superconductivity: 'Superconductivity',
  Semiconductor: 'Semiconductor',
  CPWs: 'CPWs',
  AirBridge: 'Air Bridge',
  AutomaticLayoutoftheSQUIDandZ_line: 'Automatic Layout of the SQUID and Z_line',
  JJ: 'JJ Tool',
  Distance: 'Distance',
  Avoid: 'Avoid',
  SelectionAll: 'Selection All',
  InvertSelection: 'Invert Selection',
  Region: 'Region',
  Oneside: 'One side',
  TheOtherSide: 'The other side',
  CheckLine: 'Check Line',
  Drc: 'Design rule edit and check',
  Minimumdistanceofthetwolines: 'Minimum distance of the two lines',
  Properties: 'Properties',
  UserGuide: 'User Guide',
  VideoTutorial: 'Video Tutorial',
  About: 'About',
  QuantumChipDesignIndustrialSoftware: 'Quantum Chip Design Industrial Software',
  Version: 'Version',
  feedback: 'Feedback',
  feedback_title: 'Please send any issues you encounter to this email address',
  feedback_info: 'Modules that can describe the problem, operation steps, upload images and videos, so that we can better solve the problem',

  // 约瑟夫森结
  toolJJheader: 'Josephson junction optimization',
  toolJJtitle: 'Josephson junction tool',
  toolJJcrossBitCap: 'Cross bit capacitor (Josephson junction)',
  toolJJadjustableCCap: 'Adjustable coupling capacitor',
  toolJJadjustableCCap_0: 'Adjustable coupling capacitance-0° (Josephson junction)',
  toolJJadjustableCCap_90: 'Adjustable coupling capacitance-90° (Josephson junction)',
  toolJJtest: 'Test junction (Josephson junction)',
  toolJJoriginCoordinates: 'Process coordinate origin',
  toolJJdirection: 'Toward',
  toolJJpleaseSelect: 'Please select',
  toolJJlayerNumber: 'Identification layer number',
  toolJJget: 'Get the Josephson junction',
  toolJJdevice: 'Josephson junction device',
  toolJJtype: 'Josephson junction type',
  toolJJresitance: 'Theoretical resistance /KΩ',
  toolJJresitanceMin: 'Lower resistance limit /KΩ',
  toolJJresitanceMax: 'Upper resistance limit /KΩ',
  toolJJnumber: 'ID',
  toolJJposition: 'Position',
  toolJJadjust: 'Adjust',
  toolJJselectPos: 'Selection point',
  toolJJexport: 'Export',
  toolJJerrorCell: 'No chip cells were identified in the layout',
  toolJJTabName: 'Josephson junction configuration table',
  toolJJTabNumber: 'Number',
  toolJJTabChipSplit: 'Chip partition',
  toolJJTabDesignLineWidth: 'Design line width/nm',
  toolJJTabResitanceMax: 'Upper resistance limit /KΩ',
  toolJJTabResitanceMin: 'Lower resistance limit /KΩ',
  toolJJTabType: 'Type',
  toolJJTypeOptions: [
    { value: 'cross_bit', label: 'Cross bit capacitor (Josephson junction)' },
    { value: 'adjustable_coupling_0', label: 'Adjustable coupling capacitance-0° (Josephson junction)' },
    { value: 'adjustable_coupling_90', label: 'Adjustable coupling capacitance-90° (Josephson junction)' },
    { value: 'test_junction', label: 'Test junction (Josephson junction)' },
  ],

  //  晶圆布局
  toolWaferPlaceWarn: 'Wafer layout generation in progress~',
  toolWaferPlaceSuccess: 'Successful wafer layout',
  toolWaferPlaceFailed: 'Wafer layout failure',
  toolWaferLayout: 'Wafer layout',
  toolWaferFillArea: 'Fill area',
  toolWaferRadius: 'Wafer radius',
  toolWaferCenterPos: 'Wafer center position',
  toolWaferLayer: 'Wafer layer',
  toolWaferEdgeSpace: 'Distance from the edge of the wafer',
  toolWaferFillCell: 'Fill device',
  toolWaferCellSpace: 'Spacing between cells',
  toolWaferOptionFill: 'optional',
  toolWafererrorCell: 'No chip cells were identified in the layout',

  //自动工具
  autotools: 'Autotools',
  layout: 'Layout',
  results: 'Results',
  operation: 'Operation',
  connectToMQTT: 'Connect to MQTT',
  viewToolGuide: 'View Tool Guide',
  LoadtheGDS: 'Load the GDS',
  saveConfiguration: 'Save',
  OK: 'OK',
  rules: 'Rules',
  GDSPath: 'GDS Path',
  NameofCell: 'Name of Cell',
  parameters: 'Parameters',
  checkLine: 'Check Line',
  minmumDistanceBetweenTwoLines: 'Minmum distance between two lines',
  placetheWBPad: 'Place the WBPad',
  distanceD1: 'Distance from outer WBPad to base border  d1',
  distanceD2: 'Distance from inner WBPad to base border  d2',
  distanceCPWCenterline: 'The distance to bounding box of CPW centerline',
  size: 'Size',
  placetheInLinePad: 'Place the InLinePad',
  minDistanceBetweenEndpointsofTwoLines: 'Minimum distance between endpoints of two lines',
  layer1ofLine1: 'Layer1 of line 1',
  layer1ofLine2: 'Layer1 of line 2',
  turnRadiusofSpout: 'Turn radius of spout',
  theInnerWidthofSpout: 'The inner width of the spout',
  theOutsideWidthofSpout: 'The outside width of the spout',
  innerRadiusofSpout: 'Inner radius of spout',
  outsideRadiusofSpout: 'Outside radius of spout',
  xaxisoffsetofSurroundingPad: 'The x-axis offset of the surrounding pad',
  yaxisoffsetofSurroundingPad: 'The y-axis offset of the surrounding pad',
  radiusofInPillar: 'Radius of InPillar',
  layerofInPillar: 'Layer of InPillar',
  materialofInPillar: 'Material of InPillar',
  radiusofTiNPad: 'Radius of TiNPad',
  layerofTiNPad: 'Layer of TiNPad',
  materialofTiNPad: 'Material of TiNPad',
  placetheAirBridge: 'Place the AirBridge',
  selectLayer: 'Select layer',
  airBridgeNeedAvoidOther: 'Air Bridges need to avoid other layers',
  minDistanceAirBridgeToOtherEdge: 'Minimum spacing between the center of the air bridge and the edge of the bypass layer device',
  airBridgeStep: 'Step of two AirBridge/Air Bridge step',
  airBridgeFineTuningStep: 'Air bridge fine-tuning step',
  theOffsetFromEndtoEnd: 'Offset from head to tail/the offset from end to end',
  minDistanceBetweenCenterofTwoAirBridge: 'Minimum distance between center of air bridge and center of air bridge',
  minDistanceBetweenCenterofAirBridgeAndInflectionPointofLine: 'The minimum distance between the center of the air bridge and the inflection point of the line',
  minDistanceBetweenCenterofAirBridgeAndCenterofInLinePad: 'Minimum distance between the center of the air bridge and the center of the InLinePad',
  placetheTiNPad: 'Place the TiNPad',
  placementAreaofTiNPad: 'Placement area of TiNPad',
  miningAreaofTiNPad: 'Mining area of TiNPad',
  placementStepDistanceofTiNPad: 'Placement step distance of TiNPad',
  minDistanceFromTiNPadCentertoTiNPadCenterD1: 'Minimum distance from TiNPad center to TiNPad center (d1)',
  minDistanceFromTiNPadEdgetoOtherComponentEdgesD2: 'Minimum distance from TiNPad edge to other component edges (d2)',
  minDistanceFromTiNPadEdgetoCenterlineoftheCPWD3: 'Minimum distance fromTiNPad edge to centerline of the CPW  (d3)',
  add: 'Add',
  delete: 'Delete',
  shape: 'Shape',
  radius: 'Radius',
  max_points: 'Max points',
  material: 'Material',
  square: 'Square',
  generateCPWs: 'Generate CPWs ',
  outerWidthW1: 'Outer Width (w1)',
  innerWidthW2: 'Inner Width (w2)',
  turnRadiusR: 'Turn Radius (r)',
  GUI: 'GUI',
  gridDisplay: 'Grid Display',
  gridAccuracy: 'Grid Accuracy',
  gridSnap: 'Grid Snap',
  anyAngle: 'Any Angle',
  obliqueAdsorption: 'Angled Adsorption',
  angleLock: 'Angle Lock',
  anyStretch: 'Any Stretch',
  coordinate: 'Coordinate',
  coordinateZero: 'Coordinate Zero',
  zoom: 'Zoom',
  record: 'Record',
  time: 'Time',
  content: 'Content',
  connectSuccessful: 'successful',
  saveConfigurationSuccessful: 'successful',
  notFindFile: 'not find file/file does not exist',
  noData: 'No data',
  exportPath: 'export path',
  checkLineFinish: 'finish',
  completePlacementWBPad: 'Complete Placement~',
  completePlacementInLinePad: 'Complete Placement~',
  completePlacementAirBridge: 'Complete Placement~',
  completePlacementTiNPad: 'Complete Placement~',
  completePlacementCPWs: 'Complete Placement~',
  fileExceedsLimit: 'The file exceeds the limit, maximum: 1024KB~',
  successfulExport: 'Successful Export',
  pleaseSelect: 'Please select',
  InpillarNumber: 'Number of Inpillar',
  Inpillarshape: 'Shape of Inpillar',
  totalInpillarArea: 'Total Inpillar area',
  InpillarPlacementArea: 'Area of placement Inpillar',
  excavationArea: 'Excavation area',
  ROIArea: 'ROI area',
  totalInpillarAreaROIAreaProportion: 'Proportion (total Inpillar area / ROI area)',
  sureToSave: 'Save',
  layerNumberLimit1: 'The number of layers exceeds the limit!',
  layerNumberLimit2: 'There can only be 256 layers at most!',
  numberLimit1: 'Please enter a decimal within three digits or an integer within five digits',
  numberLimit2: 'Please enter an integer within five digits',

  //智能谐振腔
  quickResonators: 'Quick Resonators',
  resonators: 'Resonators',
  operation: 'Operation',
  creatPolygon: 'Creat Polygon',
  placeStartPoint: 'Place Start Point',
  placeEndPoint: 'Place End Point',
  parameterModification: 'Parameter',
  setConditions: 'Parameter',
  start: 'Start',
  end: 'End',
  length: 'Length',
  minimumRadius: 'Minimum Radius',
  maximumRadius: 'Maximum Radius',
  layerNumber: 'Layer Number',
  creat: 'Creat',
  save: 'Save',
  result: 'Result',
  turnNumber: 'Number of turn',
  startGeneratingResonators: 'Start Generating Resonators',
  startPointFarFromPolygon: 'Start point is too far from polygon',
  tailPointFarFromPolygon: 'The tail point is too far from the polygon',
  lengthShort: 'Length is too short',
  resonatorsNotGenerated: 'Resonators not generated',
  resonatorsSuccessfullyGenerated: 'Resonators was successfully generated',
  saveSuccess: 'Save success',

  //设置
  userCenter: 'User Center',
  quit: 'Logout',
  personalCenter: 'Personal',
  teamInfo: 'Team Space',
  userInfo: 'User Info',
  reallyQuit: 'Do you really wish to quit?',
  switchLang: 'Switch to Chinese',
  sureToSwitchLang: 'Are you sure you want to switch languages?',
  cancelLanguageSwitch: 'Cancel Language Switch',
  project: 'Project',
  ComponentLibrary: 'Component Library',
  character: 'Character',
  toShare: 'Share',
  member: 'Member',
  search: 'Search',
  receiveShare: 'Receive to Share',
  toTeam: 'Export to Team',
  ofTeam: 'Import to Team',
  inviteMember: 'Add Collaborators',
  batchPower: 'Batch Power Manage',
  batchtoPersonal: 'Batch to Personal',
  batchCopyTo: 'Batch Copy',
  batchShare: 'Batch Share',
  batchDelete: 'Batch Delete',
  batchExportGDS: 'Batch Export of the GDS',
  pleaseInputName: 'Please Enter Name',
  myTeam: 'My Team',
  createTeam: 'Create Team',
  joinTeam: 'Join Team',
  updateInfo: 'Edit Information',
  addPerson: 'Add Member',
  cessionTeam: 'Transfer Team',
  dismissTeam: 'Dissolution Team',
  quitTeam: 'Exit Team',
  BoardDesign: 'Board Design',
  CoordinateDisplay: 'Coordinate Display',
  Zoomout: 'Zoom out',
  Zoomin: 'Zoom in',
  ZoomFit: 'Zoom Fit',
  ObjectFitSelection: 'Object Fit Selection',
  ZoomFitSelection: 'Zoom Fit Selection',
  displayName: 'Nickname',
  password: 'Password',
  editPassword: 'Edit Password',
  userName: 'User Name',
  registTime: 'Created Time',
  update: 'Update',
  searchForKeyword: 'Keyword Search',
  placeholder_0: 'Please select',
  placeholder_1: 'Please enter the content',
  placeholder_2: 'Please enter the team name',
  placeholder_3: 'Please enter the project name',
  placeholder_4: 'Please enter the member name',
  placeholder_5: 'Please enter the pin information',
  edit_displayName: 'Edit',
  set: 'Set',
  attrs: 'Property',
  params: 'Parameter',
  power: 'Permission',
  Warning: 'Warning',
  copyTo: 'Copy',
  belong: 'Belong to',
  uploadTo: 'Upload to public library',
  uploadSymbolTo: 'Upload to public library',
  optional: '(Optional)',
  editMemberInfo: 'Member Information Editing',
  powerTips_1: 'Possess project and member management rights, and the device library can be deleted and used.',
  powerTips_2: 'Has owner/administrator assigned permissions',
  powerTips_3: 'Join the project, the permission is read-only',
  powerTips_4: 'Has owner/administrator assigned permissions',
  powerTips_5: 'Fill in the  code to join the team identity as the "collaborator"',
  powerTips_6: 'The link is valid for 24 hours',
  powerTips_7: 'After transferring a team, you will be demoted to a administrator.This operation cannot be undone, please operate with caution.',
  powerTips_8:
    'All project and  component must be removed before disbanding the team, otherwise the team cannot be disbanded. The operation of disbanding the team cannot be undone, please proceed with caution.',
  powerTips_9_1: 'Are you sure set permission',
  powerTips_9_2: 'to these member in project, continue',
  powerTips_10_1: 'These selected member will be get permission',
  powerTips_10_2: '',
  powerTips_11: 'The link is valid for 7 days',
  powerTips_12: 'Delete the project, and cannot be restored',
  powerTips_13: 'I know it',
  powerTips_14: 'Do you want to delete selected member? They will be removed permission of project, continue?',
  powerTips_15: 'This operation will quit this team, continue',
  powerTips_16: 'Remove the member from the project，are you sure',
  powerTips_17: 'Remove the component from the project，are you sure',
  powerTips_18: 'When the member becomes a collaborator, it will lose the permission to add and remove members. Do you want to continue?',
  powerTips_19:
    'When becomes an administrator,the member will get permission to add and remove members, administrative rights for all project and delete and usable rights for  libraries. Do you want to continue?',
  powerTips_20: 'Do not use nicknames that contain special symbols or that contain obvious marketing intent',
  powerTips_21: 'A 6-digit digital verification code',
  powerTips_22: 'Delete the following documents, all of which are deleted and cannot be restored',
  powerTips_23: 'Already exist the same component, continue cover?',
  powerTips_24: 'After selecting Delete, the contents of this layer under the layout file will be deleted and will not be restored',
  powerTips_25: 'Remove the symbol from the project，are you sure',
  powerTips_26: 'Already exist the same symbol, continue cover?',
  powerTips_11_2: 'The link is valid for 1 days',
  createNewLibrary: 'Create New Library',
  libraryName: 'Library Name',
  powerTips_27: 'The permissions of the selected role are',
  powerTips_28: " 's Personal project directory",
  powerTips_29: 'After the device library is deleted, all the devices it contains will also be deleted. Do you want to continue?',
  selectLibrary: 'Please select a library first',
  updataLibraryName: 'Update Name',
  batchAddComponent: 'Batch Add Component',
  batchAddSymbol: 'Batch Add Symbol',
  placeholder_seach_component: 'Please enter the component name',
  placeholder_seach_symbol: 'Please enter the symbol name',
  fixedDevices: 'Fixed devices',
  parameterizedDevice: 'Parameterized device',
  batchAddComponentTips: 'Do you want to overwrite devices with the same name?',
  exportLibraryFile: 'Export Library File',
  exportLibrary: 'Export Library',
  createNewComponentLibrary: 'Create library',
  createNewSymbolLibrary: 'Create library',
  deleteComponentLibrary: 'Delete library',
  deleteSymbolLibrary: 'Delete library',

  // 器件库
  paramsComponentLibrary: 'Parameterized Library',
  OfficialParametrizedLibrary: 'The Official Parametrized  Library',
  PDKParametrizedLibrary: 'The PDK Parametrized  Library',
  Component: 'Component',
  ComponentName: 'Component Name',
  ComponentType: 'Component Type',
  Details: 'Details',
  NoteFigure: 'Note Figure',
  img_2D: '2D',
  Creator: 'Creator',
  MyLibrary: 'Personal Library',
  MineLibrary: 'My Library',
  Library: 'Library',
  TeamLibrary: 'Team Library',
  PublicLibrary: 'Public Library',
  OfficialLibrary: 'Official Library',
  PDKLibrary: 'PDK Library',
  note: 'Note',
  ComponentDetails: 'Component Details',
  paramsComponentDetails: 'Component Details',
  totalLength: 'Length',

  symbol: 'Symbol',
  symbolLibrary: 'Symbol Library',
  MySymbol: 'My Symbol',
  SymbolLibrary: 'Symbol',
  TeamSymbol: 'Team Symbol',
  PublicSymbol: 'Piblic Symbol',
  OfficialSymbol: 'Official Symbol',
  PDKSymbol: 'PDK Symbol',
  cpLine: 'Coupling line',
  pin: 'Pin',
  symbolIns: 'Symbolic instance',
  insName: ' Instance Name',
  portCount: 'Port Count',
  port: 'Port',
  noParamsData: 'No parameters yet',
  insNameError: 'Duplicate instance names are not allowed',
  SymbolName: 'Symbol Name',
  elcParams: 'Electrostatic Parameters',
  controlParams: 'Control Parameters',

  // 检查
  checkRule: 'inspection',
  startRuleCheck: 'Start Design Rule Check',
  EndRuleCheck: 'End Design Rule Check',
  ErrorTitle: '[Error]',
  WarningTitle: '[Warning]',
  netError: 'Wrong number of ports for network connection：',
  pinError: 'Pins not connected：',
  pinWarning: 'Connected to a pin on a net, the net and pin names do not match：',
  symbolPinError: 'The port of the symbol is not connected：',
  symbolPortTypeError: 'symbolic port connection type error：',

  //左边栏
  select: 'Select',
  line: 'Straight Line',
  dogleg: 'Broken Line',
  circle: 'Circle',
  rectangle: 'Rectangle',
  polygon: 'Polygon',
  flip: 'Flip Horizontal',

  // 自定义图形属性
  customGraphicsProperty: 'Shape Properties',
  noneGraph: 'No shapes has been selected',
  name: 'Name',
  layerNumber: 'Layer Number',
  coordinateList: 'Points List',
  xCoordinate: 'x-coordinate',
  yCoordinate: 'y-cooordinate',
  lowerLeftCornerCoordinates: 'Lower Left Coordinate',
  width: 'Width',
  height: 'Height',
  perimeter: 'Perimeter',
  centerCoordinate: 'Center Coordinates',
  radiusInformation: 'Radius',
  confirmChanges: 'OK',

  // 器件属性
  componentProperty: 'Component Properties',
  noneComponent: 'No component has been selected',
  annotationMap: 'Annotation drawing',
  basicProperties: 'Attribute',
  OProperties: 'Key-points Coordinates',
  customProperties: 'Parameters',
  topLeft: 'top-left',
  bottomLeft: 'bottom-left',
  topRight: 'top-right',
  bottomRight: 'bottom-right',
  tipSelected: 'Tip: it corresponds to the selected value of gaptype attribute',
  RW1W2: 'R, W1, W2 must be integers, and R is greater than or equal to W1+W2!',
  nLimit: 'n must be an integer value of 2k + 1 (k>=2)!',
  GapTypeCannotEmpty: 'GapType cannot be empty',

  //器件列表
  component: 'Component',
  fileList: 'Files',
  componentList: 'Component library',
  projectList: 'Project List',
  superconductivity: 'Superconductivity',
  semiconductor: 'Semiconductor',
  universalComponentLibrary: 'General Library',
  customComponentLibrary: 'Custom library',

  // 属性列表
  propertyList: 'Attribute List',
  layerPropertyList: 'Layer Properties',
  commonPropertyList: 'General Properties',
  layer: 'Layer',
  color: 'Color',
  hide: 'Hide',
  number: 'Number',
  createNewLayer: 'Add Layer',
  backgroundColor: 'Background Color',
  gridColor: 'Grid Color',
  isGridVisible: 'Grid Visible',
  adsorption: 'Adsorb',
  adsorptionAccuracy: 'Mouse Snap Grid',
  gridAdsorptionAccuracy: 'Mouse Snap Grid',
  gridSize: 'Grid Size',
  unit: 'Unit',
  zoomFactor: 'Zoom Factor',
  mousePositionX: 'Pointer-x',
  mousePositionY: 'Pointer-y',
  output: 'Output',
  lock: 'Lock',
  NO: 'No',
  fillColor: 'Fill Color',
  borderColor: 'Frame Color',
  fillShape: 'Stipple',
  borderShape: 'Style',
  allLayers: 'Layout File Layer',
  cellLayers: 'Cell Layer',
  deleteLayer: 'Delete Layer',
  deleteLayerWarning: 'After deletion, the contents of the layer under the layout file are all deleted and will not be restored.',
  mergeLayer: 'Merge Layer',
  mergedLayer: 'The Merged Layer',
  selectMergedLayer: 'Please Select the Merged Layer',
  addLayer: 'Add Layer',
  listInformation: 'List  Information',
  tolerance: 'Radian accuracy',
  editObject: 'objects edit',
  listInformation: 'List Information',
  modifiedLayerInfo: 'Successfully modified layer information',
  enterCorrectLayerNumber: 'Please enter the correct layer number',
  LayerNameCannotEmpty: 'The layer name cannot be empty and greater than 10 characters',
  enterLayerNameFormat: 'Please enter the layer name in the correct format',
  sureToClose: 'Are you sure to close?',

  // 自定义图形属性
  customGraphicsProperty: 'Shape Properties',
  noneGraph: 'No shapes has been selected',
  name: 'Name',
  layerNumber: 'Layer Number',
  coordinateList: 'Points List',
  xCoordinate: 'x-coordinate',
  yCoordinate: 'y-cooordinate',
  lowerLeftCornerCoordinates: 'Lower Left Coordinate',
  width: 'Width',
  height: 'Height',
  perimeter: 'Perimeter',
  centerCoordinate: 'Center Coordinates',
  radiusInformation: 'Radius',
  confirmChanges: 'OK',

  // 器件属性
  componentProperty: 'Component Properties',
  noneComponent: 'No component has been selected',
  annotationMap: 'Annotation drawing',
  basicProperties: 'Attribute',
  OProperties: 'Key-points Coordinates',
  customProperties: 'Parameters',
  topLeft: 'top-left',
  bottomLeft: 'bottom-left',
  topRight: 'top-right',
  bottomRight: 'bottom-right',
  tipSelected: 'Tip: it corresponds to the selected value of gaptype attribute',
  RW1W2: 'R, W1, W2 must be integers, and R is greater than or equal to W1+W2!',
  nLimit: 'n must be an integer value of 2k + 1 (k>=2)!',
  GapTypeCannotEmpty: 'GapType cannot be empty',
  quantumDotsNumber: 'Number of quantum dots',

  //登录页面
  register: 'Register',
  phoneEmail: 'Phone/Email',
  enterPassword: 'Enter password',
  forgotPassword: 'Forgot password',
  login: 'Login',
  passwordIsIncorrect: 'Your account or password is incorrect',
  userNamelength: 'The length of user name is 3 ~ 20 characters',
  passwordlength: 'The length of password is 6 ~ 20 characters',
  userNameEmpty: 'User name cannot be empty',
  passwordEmpty: 'Password cannot be empty',
  loginSuccessful: 'Login successful',
  haveAccount: '',
  enterPhoneNumber: 'Enter phone number',
  enterVerificationCode: 'Enter verification code',
  getVerificationCode: 'Get Code',
  VerificationCode: 'Verification Code',
  enterPassword: 'Enter password',
  enterPassword2: 'Enter password again',
  enterValidPhoneNumber: 'Please enter valid phone number',
  verificationCodeSent: 'Verification code has been sent',
  phoneNumberRegistered: 'Phone number has been registered',
  enterTrueVerificationCode: 'Please enter true verification code',
  verificationCodeEmpty: 'Verification code cannot be empty',
  passwordEmpty2: 'Confirm password cannot be empty',
  twoPasswordsInconsistent: 'Two password inputs are inconsistent',
  registerSuccessful: 'Register successful',
  returnLogin: 'Return to login',
  reset: 'Reset',
  enterRequiredItem: 'Please enter a required item',
  accountDoesNotExist: 'Account does not exist',
  changeSuccessfulLoginAgain: 'Change successful and Login again',
  passwordsDoesNotMatch: 'Passwords does not match，please reenter',
  phoneNumberlength: 'The length of phone number is 11 characters',
  newPassword: 'New password',
  passwordAgain: 'Enter the new password again',
  passwordRule: 'The password meets the following requirements:',
  pass_tips_1: '6-16 Characters',
  pass_tips_2: 'It consists of letters or numbers and cannot contain spaces',
  clearLayer: 'Clear Layer',

  // 属性面板
  selectObjList: 'List of Objects',
  graph: 'Figure',
  Staff: 'Staff',
  centerPoint: 'Central Point Coordinates',
  Size: 'Size',
  aText: 'Text',
  lineWidth: 'Line Width',
  CenterLineWidth: 'Center Line Width',
  TurningRadius: 'Turning Radius',
  orderNumber: 'No.',
  pointLength: 'Distance',
  all: 'all',
  radiusX: 'X Axis Radius',
  radiusY: 'Y Axis Radius',
  wide: 'Wide',
  high: 'High',
  sameParams: 'Same Params',
  originPoint: 'Coordinate Origin',
  keypointName: 'Point Name',
  points: 'Point Coordinates',
  ReferencePoint: 'Reference Point',
  ReferenceLocation: 'Reference Location',
  networkLabel: 'Network Label',
  Font: 'Font',
  distance: 'Distance',
  apply: 'Apply',
  complete: 'Complete',
  maxPoints: 'Number of edges',
  pointsCount: 'Number of point',
  rowsAndColumns: 'Rows/Columns',
  rowVector: 'Row vector(x, y)',
  columnVector: 'Column vector(x, y)',
  radiusAndWHError: 'The value of this input box cannot be 0, the minimum value is 0.001',
  even_number: 'Even',

  //2.1原理图
  inputPinNameErr_1: 'The input pin name cannot be duplicate',
  symbolSubstitution: 'Symbol substitution',
  replaceClosedLoop: 'Replace with closed loop',
  callClosedLoop: 'Call has closed loop',
  successSaveToSymbolLib: 'Successfully saved to symbol library',
  CheckSet1: 'Whether the network is connected to both ports',
  CheckSet2: 'The pins connected to the network have inconsistent network and pin names',
  CheckSet3: 'Pin cannot be suspended',
  CheckSet4: 'The port for the symbol is not connected',
  CheckSet5: 'Symbol port connection type error',
  CheckSet6: 'Bus port not connected to Cp1',
  CheckSet7: 'Cp1 port not connected to bus and resonator',
  CheckSet8: 'The resonator port is not connected to Cp1 and Cp2',
  CheckSet9: 'Cp2 port not connected to resonator and bit',
  CheckSet10: 'Bit port (three port) not connected to Cp2, Cp3, and Cz1',
  CheckSet11: 'The bit port (four port) is not connected to Cp2, Cp3, Cz1, and Cb1',
  CheckSet12: 'Cp3 port not connected to bit and X-ray',
  CheckSet13: 'Two bits are not connected to the Cb1 port',
  CheckSet14: 'X-ray port not connected to Cp3',
  CheckSet15: 'Cz1 port not connected to bit and z-line',
  CheckSet16: 'The Z line port is not connected to the Cz1 line',
  encapsulateSuccess: 'Encapsulate success',

  //PDK
  PDKClassification: 'PDK classification',
  officialPDK: 'Official PDK',
  personalPDK: 'Personal PDK',
  selectFile: 'Select File',

  // code码
  noDevicePower_1: 'No permission to use the library',
  noDevicePower_2: '',
  Success: 'Success',
  copySuccess: 'Copy succeeded',
  deleteSuccess: 'Delete succeeded',
  shareCodeCopySuccess: 'Copied sharing link to clipboard',
  operationSuccess: 'The operation succeeded',
  operationFailed: 'The operation failed',
  getCode: 'Get Code',
  error_1: 'Please select the component first',
  error_2: 'Please select the member first',
  error_3: 'No member or project selected',
  error_4: 'Please select the project first',
  error_5: 'You are not a member of the project yet',
  error_6: 'At least one label exists',
  error_7: 'Please complete the label added last time',
  error_8: 'Up to 15 labels are allowed',
  error_9: 'Please select the file first',
  uploadSuccess: 'Upload succeeded',
  updateSuccess: 'Update succeeded',
  code_400001: 'Engineering id cannot be empty',
  code_400002: 'The request parameter is not a JSON',
  code_400003: 'A file with the same name already exists',
  code_400004: 'A file with the same name already exists under the project',
  code_400005: 'The components could not be found',
  code_400006: 'Components with the same name already exist',
  code_400010: 'The account is not activated',
  code_400011: 'Two password inputs are inconsistent',
  code_400012: 'The mailbox has a registered user',
  code_400013: 'This user name already exists, please replace it',
  code_400014: 'Wrong password',
  code_400015: 'The new password is the same as the old one',
  code_400016: 'The same project name already exists',
  code_400017: 'A Cell with the same name already exists under the file',
  code_400018: 'Incorrect user name or password',
  code_400019: 'Wrong gds file content',
  code_400020: 'The name already exists, please rename the component',
  code_400021: 'Error in file format',
  code_400022: 'The maximum number of project created by users exceeds the limit',
  code_400023: 'The file size is over 500kb',
  code_400024: 'The maximum number of project created by users exceeds the limit under the same project',
  code_400025: 'The maximum number of cell exceeds the limit under a file ',
  code_400026: 'Save Failed ，file maximum 2.5mb',
  code_400027: 'All of the Cell content in the file is empty',
  code_400028: 'Parameter check error',
  code_400029: "Create failed, check the parameter, file name, cell name for legal: only letters, numbers, . , \\ + - '",
  code_400030: "Document name is illegal, the file name cannot start with a space, only  letters, numbers + - _ & () []()~'",
  code_400031: "CELL name is illegal, CELL name cannot start with space, only  letters, numbers + - _ & () []()~'",
  code_400032: "The project name is illegal, the project name can not be space, only Chinese.  letters, numbers + - _ & () []()~'",
  code_400033: 'Error in es insert data',
  code_400034: 'gns parameter error',
  code_400035: 'category parameter error',
  code_400036: 'category_child parameter error',
  code_400037: 'You must search for files under the specified project path',
  code_400307: 'Deletion failed',
  code_400038: 'Please enter the team name',
  code_400099: 'Please enter a team name that cannot start with a space',
  code_400039: 'The team name already exists',
  code_400040: 'Invitation code error',
  code_400041: 'The invitation code expired',
  code_400042: 'Members are already in the team',
  code_400043: 'The number of team members exceeds the limit',
  code_400044: 'Projects with the same name under the team',
  code_400045: 'The cell name is repeated',
  code_400046: 'The user is not on the team',
  code_400047: 'Add a collaborator that cannot be empty',
  code_400048: 'Some user roles in the add-collaborator list are incorrect',
  code_400049: 'The administrator can only edit the collaborator rights, not over the level or level editing',
  code_400050: 'Users cannot use the invitation code created by themselves',
  code_400051: 'The team name is the same, please enter the name you want to change',
  code_400052: 'Team name cannot be more than 10 characters',
  code_400053: 'Network fluctuations, please select a small amount of data to retry',
  code_400055: 'Share code expired',
  code_400056: 'Team creation exceeds the maximum limit',
  code_400057: 'The number of projects created under the team exceeds the maximum limit',
  code_400058: 'The number of documents under the project exceeds the maximum limit',
  code_400059: 'Modifying the member role has failed',
  code_400060: 'The currently selected user section is not in the team',
  code_400061: 'The currently selected project section is not in the team',
  code_400062: 'The currently selected user section is not included in the project',
  code_400305: 'Share Error',
  code_400307: 'The name is illegal. The beginning of the name cannot be a space. It can only be Chinese alphanumeric + - _ & () []（）~',
  code_400309: 'The number of library creations exceeds the maximum limit',
  code_400310: 'There is a library with the same name',
  code_400315: 'Same name exists',
  code_400318: 'The file name is 3 to 30 characters in length',
  code_400319: 'The number of files has reached the maximum limit',
  code_400329: 'Unable to operate devices in the current library',
  code_400330: 'Adding device failed',
  code_400331: 'Cannot manipulate symbols in the current library',
  code_400332: 'Export library file failed',
  code_400333: 'Import PCell exception',
  code_400334: 'Import DRC exception',
  code_400335: 'The name is illegal. The beginning of the name cannot be a space. It can only be alphanumeric or + - _ & () []（）. ~',
  code_400336: 'DRC operation exception',
  code_400337: "Unable to operate the current team's devices",
  code_400338: 'Cannot manipulate symbols for the current team',
  code_400339: 'Failed to add symbol',
  code_400340: 'Same file name exists',

  code_400907: 'Cannot modify file',
  code_400908: 'Inconsistent upload file names',
  code_400909: 'Inconsistent number of uploaded files',
  code_400910: 'data in wrong format',
  code_400913: 'You do not have the current operation permissions',
  code_400914: 'Can only operate on data under the current file',
  code_400915: 'Failed to load pdk',

  code_401000: 'No login exists or the authentication information is not provided',
  code_401001: 'No logged in or a cookie error',
  code_401002: 'Login failure',
  code_404001: 'Item does not exist, please check if gns is entered correctly',
  code_404002: 'The user does not exist',
  code_404003: 'The Cell does not exist, Please check if the gns is entered correctly',
  code_404004: 'The file does not exist, please check that the gns is entered correctly',
  code_404005: 'The file does not exist, please check that the snow_id is entered correctly',
  code_404006: 'The team does not exist, check that snow_team_id is filled correctly',
  code_404006: 'Team does not exist, please check if the gns is filled in correctly',
  code_404007: 'The user is not on the team',
  code_404008: 'The project does not exist',
  code_404009: 'This project does not exist in the team',
  code_404010: 'You are not allowed to delete yourself',

  code_404903: 'Project does not exist',
  code_404904: 'file does not exist',
  code_404905: 'There is no unit data in this file',
  code_404906: 'No official PDK information found',

  code_405001: 'The current user does not have the team permission to view it',
  code_405002: 'You do not have added member privileges',
  code_405003: 'The role of the current user is not allowed to transfer the team',
  code_405004: 'You are not allowed to transfer it to yourself',
  code_405005: 'Owners are not allowed to exit',
  code_405006: 'Collaborators are not allowed to add or edit collaborators',
  code_405028: 'Projects do not allow automatic inheritance',
  code_405031: 'The currently selected files are not allowed to be deleted',
  code_405301: 'The current user does not have the team device library editing rights',
  code_405302: 'The current user does not have the team device library usage permission',
  code_405303: 'File size exceeds limit',
  code_405304: 'File download failed',
  code_405305: 'File deletion failed',
  code_405306: 'Only administrators or owners can perform this operation',
  code_405307: 'Failed to get file',
  code_405308: 'Failed to get the device content',
  code_405309: 'Layer number exceeds the maximum limit',
  code_405310: 'The number of layers exceeds the maximum limit',
  code_405311: 'There is a duplication in the layer numbers',
  code_405312: 'Layer information does not exist',
  code_405313: 'Component saving failed, and the Component size exceeded the limit',
  code_405314: 'Component saving fails and the number of Component exceeds the maximum limit',
  code_405315: 'Component saving fails and the number of Component exceeds the maximum limit',
  code_405316: 'Import failed, file size exceeded maximum limit',
  code_405317: 'Upload failed, and the number of uploads exceeded the maximum limit',
  code_405318: 'Upload failed, and the number of uploads exceeded the maximum limit',
  code_405319: 'Save failed, file size exceeds maximum limit',
  code_405320: 'Component name is not legal, Component name can not be a space, only letters, numbers + - _ & () []()~',
  code_405321: 'Component name must not exceed 50 characters',
  code_405322: 'Password error of operating official Component',
  code_405323: 'Delete Component error',
  code_405324: 'The GDS file read failed',
  code_405325: 'The GDS parsing of the cell has failed',
  code_405336: 'Symbol with the same name already exist',
  code_405007: 'collaborators are not allowed to copy team projects to an individual',
  code_405008: "Individuals are not allowed to share other user's projects",
  code_405009: 'collaborators and administrator are not allowed to modify team names',
  code_405010: 'Collaborators are not allowed to delete items from the team',
  code_405011: 'The number of project permissions obtained cannot exceed 20',
  code_405012: 'The current user does not have delete privileges',
  code_405013: 'Only the owners can manage it in bulk',
  code_405014: 'There are projects under the team, which is not allowed to dissolve the team',
  code_405015: 'There are component under the team, which is not allowed to dissolve the team',
  code_405016: "The administrator's library privileges can only be editable",
  code_405017: 'The collaborator does not have permission to operate a project',
  code_405018: 'The current user does not have the source project to delete, edit permission',
  code_405019: 'The current user does not have target project to delete, edit permission',
  code_405020: 'The user does not have permission to copy this project',
  code_405021: 'Collaborators are not allowed to add members',
  code_405022: 'Files are not allowed to copy under the same project',
  code_405023: 'Administrators are not allowed to modify administrators',
  code_405024: 'Collaborators are not allowed to operate',
  code_405025: 'Other users are using it',
  code_405026: 'Rename is not allowed',
  code_405027: 'Do not have permission',
  code_405032: 'The current user does not have permission to manipulate layers',
  code_405328: 'Only administrators or owners can perform this operation',
  code_500001: 'Server unknown exception',
  code_500002: 'Import GDS exception',
  code_500003: 'Failed to obtain the third-party user information interface',
  code_500004: 'redis wrong',
  code_500005: 'ES deletion error',
  code_500006: 'Upload object storage failed',
  code_500007: 'Creating the default layer failed',

  code_500912: 'Failed to obtain third-party user information interface',
  code_500915: 'Failed to update message es status',
  code_500918: 'Permission verification exception',
  //画板提示
  layoutSplitSuccess: 'Layout split successfully',
  splitLayoutFailed: 'Split layout failed',
  cwGeneratedSuccess: 'Coplanar waveguide was successfully generated',
  cwdGeneratedFaile: 'Coplanar waveguide generation failed',
  wbPadPlacedSuccess: 'WBPad placed successfully',
  wbPadPlacedFaile: 'WBPad placement failed',
  inLinePadPlacedSuccess: 'InLinePad placed successfully',
  inLinePadPlacedFaile: 'InLinePad placement failed',
  airBridgePlacedSuccess: 'Air bridge placed successfully',
  airBridgePlacedFaile: 'Air bridge placement failed',
  tpPlacedSuccess: 'TiNPad&InPillar placed successfully',
  tpPlacedFaile: 'TiNPad&InPillar placement failed',
  testLineSuccess: 'Check line success',
  theConnectionLineSuccess: 'The connection line is successful',
  theConnectionLineFailed: 'The connection line is failed',
  plsSelectSquidEdge: 'Please select the edge where the SQUID is placed',
  plsSelect1P2K: 'Please select a polygon and two keypoints',
  deviceCircularReference: 'Device circular reference',
  // 工具弹框
  toolBigLayout: 'Big Layout Layer Tool',
  toolSizeImage: 'Mark figure',
  tool2dImage: '2D figure',
  toolNumber: 'Number',
  toolCount: 'Count',
  toolLayer: 'Layer',
  toolDistance: 'Distance',
  toolAvoid: 'Avoid',
  toolAll: 'All',
  toolSelectAll: 'Selection All',
  toolSelected: 'Invert Selection',
  toolSize: 'Size',
  toolRules: 'Rule',
  toolText: 'Line Mark',
  toolTextSize: 'text size',
  toolNoRules: 'No Rule',
  toolCheck: 'Check',
  toolShape: 'Shape',
  toolSquare: 'Square',
  toolCircle: 'Circel',
  toolPrecision: 'Precision',
  toolCoord: 'Coordinate',
  toolStartPos: 'Start',
  toolEndPos: 'End',
  toolCenterPos: 'Center',
  toolLength: 'Length',
  toolRadius: 'Radius',
  toolAdd: 'Add',
  toolOperate: 'Operate',
  toolConnect: 'Connect',
  toolArea: 'Area',
  toolKeyWord: 'Keyword',
  toolPleaseSelect: 'Please Select',
  toolAirbridgeTitle: 'Air Bridge',
  toolAirbridge2LineMinDistance: 'Minimum distance between the center point of the air bridge and the end point of the skeleton line',
  toolAirbridge2AirbridgeMinDistance: 'The minimum distance between the central points of an air bridge',
  toolAirbridgeStep: 'Air bridge placement step',
  toolAirbridgeLineLayer: 'The air bridge is placed on the layer where the skeleton line is',
  toolAirbridgeAvoidInflect: 'Avoid the skeleton line inflection point',
  toolAirbridgeInflectCircle: 'Frame line inflection point arc placement (pay attention to coplanar waveguide)',
  toolAirbridgeInflectCircleRadius: 'Skeleton line inflection point arc placement radius',
  toolAirbridge2InflectMinDistance: 'Minimum distance between skeleton line inflection point and air bridge center',
  toolAirbridgePlaceWarn: 'Warning, air bridge is being placed, please wait until the placement is complete',
  toolAirbridgeLayerPier: 'Layer Pier',
  toolAirbridgeLayerDeck: 'Layer Deck',
  toolWBPadBounds: 'Layout border',
  toolWBPad2Bounds: 'The spacing between the layout border and the Pad edge',
  toolWBPadMinDistance: 'The minimum distance between the skeleton line border and the skeleton line endpoint. A WBPad is placed on all skeleton line endpoints that meet this distance',
  toolWBPadTextX: 'Distance from the lower left corner of the text to the horizontal coordinate of point O',
  toolWBPadTextY: 'Distance from the bottom left corner of the text to the vertical coordinate of point O',
  toolWBPadText: 'Text',
  toolWBPadTextSize: 'Text Size',
  toolWBPadTextLayer: 'Layer',
  toolWBPadTextNumber: 'WBPad Number',
  toolInLinePadDistance: 'The maximum distance between the ends of the skeleton lines on two different layers. Place InLinePad here',
  toolInLinePadLayer1: 'Connect layer 1',
  toolInLinePadLayer2: 'Connect layer 2',
  toolInLinePadIndiumLayer: 'Indium Layer',
  toolInLinePadIndiumFlipLayer: 'Indium Layer(flip)',
  toolInLinePadTinLayer: 'TiN Layer',
  toolInLinePadTinFlipLayer: 'TiN Layer(flip)',
  toolSquidLine: 'Automatic placement of SQUID and Z line',
  toolSquidLineLen: 'Line length',
  toolSquid2StartDistance: 'To start pos distance',
  toolSquidDirect: 'SQUID direction',
  toolSquidDirectLeft: 'Left',
  toolSquidDirectRight: 'Right',
  toolSquidType: 'SQUID Type',
  toolSquid2ZlineDistance: 'Distance between the SQUID and the Z-line',
  toolSplitGdsTitle: 'Split the layout by layer',
  toolSplitGdsAdd: 'Add layout',
  toolSplitGdsLayout: 'The Splited Layout',
  toolConnectLine: 'Connect Line',
  toolConnectLineMinDistance: 'Minimum distance between two lines',
  toolCheckLine: 'Check Line',
  toolCheckLineCheck: 'Check',
  toolCheckLineMinDistance: 'Minimum distance between two lines',
  toolCavity: 'Resonators',
  toolCavityChangeStartEnd: 'Start End Exchange',
  toolCavityAutoGen: 'Automatically generate',
  toolCavityMinRadius: 'Minimum Radius',
  toolCavityMaxRadius: 'Maximum Radius',
  toolCavityOuterWidth: 'Outer Width',
  toolCavityInnerWidth: 'Inner Width',
  toolCavityResult: 'Result',
  toolCavityInflectNumber: 'Corner Number',
  toolCavityThreePoint: 'Polygons need at least 3 points',
  toolCavityStartPosTooFar: 'The starting point is too far from the polygon, minimum range: 10um',
  toolCavityEndPosTooFar: 'Tail point is too far from the polygon, minimum range: 10um',
  toolCavityEndPosErr: 'Tail point detection error',
  toolCavityLengthTooShort: 'The length is too short',
  toolCavityMaxRadiusError: 'The maximum radius is incorrect',
  toolCavityMinRadiusError: 'The minimum radius is incorrect',
  toolCavityGenerateSuccess: 'The resonator was successfully generated',
  toolCavityLengthRange: 'The length of the range',
  toolCavityPleaseCheckArgs: 'Check whether the input parameters meet the rules',
  toolTinpadInpillarPlaceArea: 'The TiNPad Placement Area',
  toolTinpadInpillarDigArea: 'TiNPad Dig Area',
  toolTinpadInpillarStep: 'InPillar placement step length',
  toolTinpadInpillarCenterMinDistance: 'Minimum distance between TiNPad center points',
  toolTinpadInpillarLayer: 'Layer',
  toolTinpadInpillarFlipLayer: 'Layer(flip)',
  toolCheckLineMinDistance: 'Minimum distance between two lines',
  toolWaveGuide: 'CPWs',
  toolWaveGuideSelect: 'Select',
  toolWaveGuideGen: 'Generate',
  toolWaveGuidePleaseSelect: 'Please Select',
  toolWaveGuideLineLayer: 'Skeleton Line Layer',
  toolWaveGuideNewLayer: 'Generate CPWs Layer',
  toolLogWBPadCount: 'WBPad Number',
  toolLogAirBridgeCount: 'AirBridge Number',
  toolLogInlinepadCount: 'InLinePad Number',
  toolLogInpillarCount: 'InPillar Number',
  toolLogInpillarRadius: 'InPillar Radius',
  toolLogInpillarShape: 'InPillar Shape',
  toolLogInpillarAreaTotal: 'InPillar Total Area',
  toolLogROIAreaTotal: 'ROI Total Area',
  toolLogInpillarProp: 'Proportion (Inpillar total area / ROI total area)',
  toolTipSplitSuccess: 'Layout split successfully',
  toolTipSplitFailed: 'Split layout failed',
  toolTipWaveGuideSuccess: 'Coplanar waveguide was successfully generated',
  toolTipWaveGuideFailed: 'Coplanar waveguide generation failed',
  toolTipWBPadSuccess: 'WBPad placed successfully',
  toolTipWBPadFailed: 'WBPad placement failed',
  toolTipInLinePadSuccess: 'InLinePad placed successfully',
  toolTipInLinePadFailed: 'InLinePad placement failed',
  toolTipAirBridgeSuccess: 'Air bridge placed successfully',
  toolTipAirBridgeFailed: 'Air bridge placement failed',
  toolTipTinpadSuccess: 'TiNPad&InPillar placed successfully',
  toolTipTinpadFailed: 'TiNPad&InPillar placement failed',
  toolTipCheckLineSuccess: 'Check line success',
  toolTipCheckLineFailed: 'Check line failed',
  toolTipConnectLineSuccess: 'The connection line is successful',
  toolTipConnectLineSuccess: 'The connection line is failed',
  toolTipSquidSelectEdge: 'Please select the edge where the SQUID is placed',
  toolTipSquidSelectPolyPoint: 'Please select a polygon and two keypoints',
  toolTipLayoutSpliting: 'Layout splitting, Please wait',
  toolTipCheckLineWait: 'The check line is running. Please wait',
  toolTipConnectLineWait: 'The connect line is running. Please wait',
  toolTipInlinepadPlaceWait: 'InLinePad is being placed, Please wait',
  toolTipTinpadPlaceWait: 'TiNPad&InPillar is being placed, Please wait',
  toolTipWBPadPlaceWait: 'WBPad is being placed, Please wait',
  toolTipWaveGuidePlaceWait: 'Coplanar waveguide generation, Please wait',

  // big tool
  toolBigTitle: 'Big Layout Layer Tool',
  toolBigPleaseOpenUse: 'Please use this tool after opening a layout ~',
  toolBigCPWs: 'CPWs',
  toolBigAirBridge: 'Air Bridge',
  toolBigWBPad: 'WBPad',
  toolBigInLinePad: 'InLinePad',
  toolBigTiNPadInpillar: 'TiNPad&Inpillar',
  toolBigSplitLayout: 'Split the Layout by layer to export Gds',
  toolBigExportGds: 'Export Gds',
  toolBigLogs: 'Logs',
  toolBigTip: 'Tip',
  toolBigTipWBPad: 'Tip',
  toolBigWait: 'Running, please wait~',
  toolBigOK: 'Success~',
  toolBigFailed: 'Failure~',

  // DRC
  drcDesignRuleCheck: 'Design Rule Check',
  drcLibraryPath: 'Library path',
  drcSelect: 'Select',
  drcOk: 'Ok',
  drcCancel: 'Cancel',
  drcDone: 'Done',
  drcCorrect: 'Correct',
  drcError: 'Error',
  drcExport: 'Export',
  drcRuleSpecification: 'Rule specification',
  drcErrorCode: 'Error code',
  drcErrorCoordinate: 'Error coordinate',
  drcUploadError: 'Network error, please try again later',
  drcSite: 'Current layout position',

  // 自动布线
  toolAutoLine: 'Auto Line',
  autoLineImportGds: 'Import gds',
  autoLineExportGds: 'Export gds',
  autoLinePadName: 'Pad Name',
  autoLineSelectCell: 'Please Select Cell',
  autoLineSelectPad: 'Please Select Pad',
  autoLineIdentificationPad: 'Identification Pad',
  autoLineSelectBitPoly: 'Select the bit array polygon',
  autoLineResetBitOrigin: 'Reset the bit array origin',
  autoLineBitSetOriginNumber: 'Number of the starting point of the bit array setting',
  autoLineConnectSet: 'Wired setup',
  autoLineAllWiring: 'All wiring',
  autoLineClearWiring: 'Clear wiring',
  autoLineWiringRules: 'Wiring rules',
  autoLineViewLogs: 'View logs',
  autoLineLogs: 'logs',
  autoLineConnectLineLayer: 'Connectint line layer',
  autoLineMinDistance: 'Minimum distance between cables',
  autoLineGridSpace: 'Connection line grid point spacing',
  autoLineSelect: 'Select',
  autoLineLayer: 'Layer',
  autoLineColor: 'Color',
  autoLineSpace: 'Space',
  autoLineAvoid: 'Avoid',
  autoLineAll: 'All',
  autoLineSelectAll: 'Select all',
  autoLineReverseSelect: 'Reverse selection',
  autoLineOk: 'Ok',
  autoLineCancel: 'Cancel',

  //pcell
  pcellGenerator: 'Pcell Generator',
  pcellList: 'Pcell List',
  saveAll: 'Save All',
  notSave: 'Not Save',
  run: 'Run',
  ParameterSettings: 'Parameter settings',
  preview: 'Preview',
  errorMessage: 'Error message',
  code: 'Code',
  logs: 'Compilation and error reporting information',
  addParams: 'Add Parameters',
  deletePcellTips: 'Are you sure to delete this file?',
  noParams: 'The device has no parameters set. Do you want to generate a parameterized device',
  noParamsError: 'The device has no parameters set',
  labelExists: 'Label already exists.',
  pcellFileImport: 'File import',
  uploadPcellFile: 'Please upload the JSON format file exported from Pcell, And no more than 5.',
  uploadPreview: 'Please upload the .png, .jpg, .JPG, .JPEG, .jpeg, .PNG .GIF, .gif format file, And no more than 5.',
  selectPcellFile: 'Select file',
  pcellExistsTips: 'The following file with the same name already exists. Do you want to replace it?',
  pcellExistsNameTips: 'The following file with the same name already exists. Do you want to replace it?',
  paramsIncomplate: 'Please fill in the required parameters',
  pcellNameError: 'The file name must start with Pcell-',

  //pdk生成器
  drcNameError: 'The file name must start with drc',
  uploadDrcFile: 'Please upload the .py format file exported from DRC, And no more than 5.',
  stop: 'Stop',
  stop_progress: 'The inspection has not been completed yet. Closing now will lose progress. Do you want to continue closing?',
  fileNotSaved: 'The file has been modified. Do you want to save it?',
  drcRunSuccess: 'Compile Success',
  startCompile: 'Start Compiling......',
  exportDRCError: 'Unsuccessful or uncompiled DRC files are not allowed to be exported',
  project_no_permission: 'The currently selected project does not have permission',

  //下拉
  directionOption: [
    { label: 'Center', value: 'o' },
    { label: 'Up', value: 'n' },
    { label: 'Down', value: 's' },
    { label: 'Left', value: 'w' },
    { label: 'Right', value: 'e' },
    { label: 'upperLeft', value: 'nw' },
    { label: 'rightUpper', value: 'ne' },
    { label: 'bottomLeft', value: 'sw' },
    { label: 'lowerRight', value: 'se' },
  ],

  //输入输出
  ioOption: [
    { label: 'IN', value: 'in' },
    { label: 'OUT', value: 'out' },
    { label: 'IN&OUT', value: 'io' },
  ],

  qubit_port: {
    X: 'x',
    Z: 'z',
    Res: 'Res',
    Q1: 'Q',
    Q2: 'Q',
    Q3: 'Q',
    Q4: 'Q',
    Q5: 'Q',
    Q6: 'Q',
    Q7: 'Q',
  },

  cpw_port: {
    Res1: 'Res',
    Res2: 'Res',
    Res3: 'Res',
    Res4: 'Res',
    Res5: 'Res',
    Res6: 'Res',
    Res7: 'Res',
    Res8: 'Res',
    Res9: 'Res',
    Res10: 'Res',
  },

  all_port: {
    Q: 'Q',
    B: 'Bus',
    Z: 'z ',
    Res: 'Res',
    X: 'x',
    Q1: 'Q',
    Q2: 'Q',
    Q3: 'Q',
    Q4: 'Q',
    Q5: 'Q',
    Q6: 'Q',
  },

  symbolConf_en: {
    互容值_cp1: 'Capacitance between bus and resonator',
    外部耗散值_cp1: 'External quality factor',
    外部耗强度_cp1: 'External dissipation',
    腔频_resonator_fpdesign: 'Resonator frequency',
    互容值_cp2: 'Capacitance between resonator and qubit',
    腔和比特耦合强度_cp2: 'Coupling strength of the cavity and qubit',
    电容值_qubit_single: 'Capacitance',
    简并点频率_qubit_single: 'Qubit frequency',
    非谐_qubit_single: 'Anharmonicity',
    SQUID非对称度_qubit_single: 'Asymmetry of SQUID',
    'Ej/Ec_qubit_single': 'Ej/Ec',
    色散位移_qubit_single: 'Dispersive shift',
    腔和比特失谐_qubit_single: 'Detuning of qubit to the resonator',
    自电容1_qubit_double: 'Capacitance of qubit 1',
    自电容2_qubit_double: 'Capacitance of qubit 2',
    互容_qubit_double: 'Capacitance between qubit pad1 and qubit pad2',
    简并点频率_qubit_double: 'Qubit frequency',
    非谐_qubit_double: 'Anharmonicity',
    SQUID非对称度_qubit_double: 'Asymmetry of SQUID',
    'Ej/Ec_qubit_double': 'Ej/Ec',
    色散位移_qubit_double: 'Dispersive shift',
    腔和比特失谐_qubit_double: 'Detuning of qubit to the resonator',
    阻抗_cpw: 'Impedance',
    阻抗_x1: 'Impedance',
    阻抗_z1: 'Impedance',
    互容值_cp3: 'Capacitance between X line and qubit',
    电容值_cb1_single: 'Capacitance',
    电容值_cb1_t_network: 'Capacitance',
    互容值C1m_cb1_t_network: 'Capacitance between coupler and qubit1',
    互容值C2m_cb1_t_network: 'Capacitance between coupler and qubit2',
    对地电容_cb1_t_network: 'Capacitance',
    互感值_cz1: 'Inductance between Z line and qubit',

    // cp1: {
    //   el: [{ name: 'Capacitance between bus and resonator', value: 5, unit: 'fF', min: 0, max: 100 }],
    //   ck: [
    //     { name: 'External quality factor', value: 6100, unit: '', min: 0, max: 100000 },
    //     { name: 'External dissipation', value: 1.2, unit: 'MHz', min: 0, max: 50 },
    //   ],
    // },
    // resonator_fpdesign: { el: [{ name: 'Resonator frequency', value: 6.5, unit: 'GHz', min: 4, max: 9 }], ck: [{ name: 'Resonator frequency', value: 6.5, unit: 'GHz', min: 4, max: 9 }] },
    // cp2: { el: [{ name: 'Capacitance between resonator and qubit', value: 5, unit: 'fF', min: 0, max: 50 }], ck: [{ name: 'Coupling strength of the cavity and qubit', value: 100, unit: 'MHz', min: 0, max: 1000 }] },
    // qubit_single: {
    //   el: [{ name: 'Capacitance', value: 85, unit: 'fF', min: 0, max: 300 }],
    //   ck: [
    //     { name: 'Qubit frequency', value: 5, unit: 'GHz', min: 2, max: 12 },
    //     { name: 'Anharmonicity', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
    //     { name: 'Asymmetry of SQUID', value: 0, unit: '', min: 0, max: 1 },
    //   ],
    // },
    // qubit_double: {
    //   el: [
    //     { name: 'Capacitance of qubit 1', value: 165, unit: 'fF', min: 0, max: 500 },
    //     { name: 'Capacitance of qubit 2', value: 165, unit: 'fF', min: 0, max: 500 },
    //     { name: 'Capacitance between qubit pad1 and qubit pad2', value: 3, unit: 'fF', min: 0, max: 50 },
    //   ],
    //   ck: [
    //     { name: 'Qubit frequency', value: 5, unit: 'GHz', min: 2, max: 12 },
    //     { name: 'Anharmonicity', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
    //     { name: 'Asymmetry of SQUID', value: 0, unit: '', min: 0, max: 1 },
    //   ],
    // },
    // cpw: { el: [{ name: 'Impedance', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    // x1: { el: [{ name: 'Impedance', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    // z1: { el: [{ name: 'Impedance', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    // cp3: { el: [{ name: 'Capacitance between X line and qubit', value: 75, unit: 'aF', min: 0, max: 500 }], ck: [] },
    // cb1_single: { el: [{ name: 'Capacitance', value: 0.5, unit: 'fF', min: 0, max: 50 }], ck: [] },
    // cb1_t_network: {
    //   el: [
    //     { name: 'Capacitance', value: 1.1, unit: 'fF', min: 0, max: 50 },
    //     { name: 'Qubit frequencyC1m', value: 70, unit: 'fF', min: 0, max: 100 },
    //     { name: 'Qubit frequencyC2m', value: 70, unit: 'fF', min: 0, max: 100 },
    //     { name: 'Capacitance', value: 70, unit: 'fF', min: 0, max: 500 },
    //   ],
    //   ck: [],
    // },
    // cb1_t_network_1: { el: [], ck: [] },
    // cz1: { el: [{ name: 'Inductance between Z line and qubit', value: 2, unit: 'pH', min: 0, max: 10 }], ck: [] },
  },
  symbolConf: {
    cp1: {
      el: [{ name: '互容值', value: 5, unit: 'fF', min: 0, max: 100 }],
      ck: [
        { name: '外部耗散值', value: 6100, unit: '', min: 0, max: 100000 },
        { name: '外部耗强度', value: 1.2, unit: 'MHz', min: 0, max: 50 },
      ],
    },
    resonator_fpdesign: { el: [{ name: '腔频', value: 6.5, unit: 'GHz', min: 4, max: 9 }], ck: [{ name: '腔频', value: 6.5, unit: 'GHz', min: 2, max: 12 }] },
    cp2: { el: [{ name: '互容值', value: 5, unit: 'fF', min: 0, max: 50 }], ck: [{ name: '腔和比特耦合强度', value: 100, unit: 'MHz', min: 0, max: 1000 }] },
    qubit_single: {
      el: [{ name: '电容值', value: 85, unit: 'fF', min: 0, max: 300 }],
      ck: [
        { name: '简并点频率', value: 5, unit: 'GHz', min: 2, max: 12 },
        { name: '非谐', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
        { name: 'SQUID非对称度', value: 0, unit: '', min: 0, max: 1 },
        { name: 'Ej/Ec', value: 53.2, unit: '', min: 0, max: 1000 },
        { name: '色散位移', value: 0.28, unit: 'MHz', min: -100, max: 100 },
        { name: '腔和比特失谐', value: -2700, unit: 'MHz', min: -5000, max: 0 },
      ],
    },
    qubit_double: {
      el: [
        { name: '自电容1', value: 165, unit: 'fF', min: 0, max: 500 },
        { name: '自电容2', value: 165, unit: 'fF', min: 0, max: 500 },
        { name: '互容', value: 3, unit: 'fF', min: 0, max: 50 },
      ],
      ck: [
        { name: '简并点频率', value: 5, unit: 'GHz', min: 2, max: 12 },
        { name: '非谐', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
        { name: 'SQUID非对称度', value: 0, unit: '', min: 0, max: 1 },
        { name: 'Ej/Ec', value: 53.2, unit: '', min: 0, max: 1000 },
        { name: '色散位移', value: 0.28, unit: 'MHz', min: -100, max: 100 },
        { name: '腔和比特失谐', value: -2700, unit: 'MHz', min: -5000, max: 0 },
      ],
    },
    cpw: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    x1: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    z1: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    cp3: { el: [{ name: '互容值', value: 75, unit: 'aF', min: 0, max: 500 }], ck: [] },
    cb1_single: { el: [{ name: '电容值', value: 0.5, unit: 'fF', min: 0, max: 50 }], ck: [] },
    cb1_t_network: {
      el: [
        { name: '电容值', value: 1.1, unit: 'fF', min: 0, max: 50 },
        { name: '互容值C1m', value: 70, unit: 'fF', min: 0, max: 100 },
        { name: '互容值C2m', value: 70, unit: 'fF', min: 0, max: 100 },
        { name: '对地电容', value: 70, unit: 'fF', min: 0, max: 500 },
      ],
      ck: [],
    },
    cb1_t_network_1: { el: [], ck: [] },
    cz1: { el: [{ name: '互感值', value: 2, unit: 'pH', min: 0, max: 10 }], ck: [] },
  },
  fontOptions: [
    {
      value: 'heiti',
      label: 'Black body',
    },
    {
      value: 'songti',
      label: 'Song typeface',
    },
  ],
  RoleOptions: [
    {
      label: 'Administrator',
      value: '管理者',
    },
    {
      label: 'Collaborator',
      value: '协作者',
    },
  ],
  view3D: {
    name: '3D View',
    ui: {
      draw_3d_section_area: '3D Section Area',
      draw_3d_view_area: '3D View Area',
      load_file: 'Load Process File',
      reset_model: 'Reset Model',
      process_steps: 'Process Steps',
      deposition: 'Deposition',
      etching: 'Etching',
      layer: 'layer',
      layer_number: 'Layer Number',
      material: 'Material',
      structure_name: 'Structure Name',
      angle: 'Angle',
      depth: 'Depth',
      color: 'Color',
      structure_list: 'Structure List',
      structure: 'Structure',
      hide: 'Hide',
      number: 'Num',
      list: 'List',
      light: 'Light',
      ambient_light_intensity: 'Ambient',
      direct_light_intensity: 'Direct  ',
      direct_light_angle: 'Angle  ',
      model_thickness: 'Model Thickness',
      thickness: 'Thickness',
      profile: 'Section',
      profile_area: 'Section Area',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      generate_profile: 'Generate Section',
      choose: 'Choose',
      ok: 'Ok',
      cancel: 'Cancel',
      process_file_type_error: 'Process file type is incorrect!',
      process_file_type_not_json_error: 'Upload process file only in JSON format!',
    },
  },
  componentOptions: [
    {
      label: 'All',
      value: [1, 2],
    },
    {
      label: 'Fixed devices',
      value: [1],
    },
    {
      label: 'Parameterized device',
      value: [2],
    },
  ],
  toolComponentOptions: [
    {
      label: 'Fixed devices',
      value: [1],
    },
    {
      label: 'Parameterized device',
      value: [2],
    },
  ],
  copyToTypeOptions: [
    {
      label: 'Personal Device Library',
      value: 0,
    },
    {
      label: 'Team Device Library',
      value: 1,
    },
  ],
  copyToSymbolTypeOptions: [
    {
      label: 'Personal Symbol Library',
      value: 0,
    },
    {
      label: 'Team Symbol Library',
      value: 1,
    },
  ],
  batchAddComponentTypeOptions: [
    {
      label: 'Personal Device Library',
      value: 0,
    },
    {
      label: 'Team Device Library',
      value: 1,
    },
    {
      label: 'Public Device Library',
      value: 2,
    },
  ],
  batchAddSymbolTypeOptions: [
    {
      label: 'Personal Symbol Library',
      value: 0,
    },
    {
      label: 'Team Symbol Library',
      value: 1,
    },
    {
      label: 'Public Symbol Library',
      value: 2,
    },
  ],
  searchLibraryTypeOptions: [
    {
      label: 'Personal Device Library',
      value: 0,
    },
    {
      label: 'Team Device Library',
      value: 1,
    },
    {
      label: 'Public Device Library',
      value: 2,
    },
    // {
    //   label: 'Official Device Library',
    //   value: 3
    // },
    {
      label: 'PDK Device Library',
      value: 4,
    },
  ],
  searchSymbolTypeOptions: [
    {
      label: 'Personal Symbol Library',
      value: 0,
    },
    {
      label: 'Team Symbol Library',
      value: 1,
    },
    {
      label: 'Public Symbol Library',
      value: 2,
    },
    // {
    //   label: 'Official Symbol Library',
    //   value: 3
    // },
    {
      label: 'PDK Symbol Library',
      value: 4,
    },
  ],
  exportLibraryTypeOptions: [
    {
      label: 'Personal Library',
      value: 1,
    },
    {
      label: 'Team Library',
      value: 2,
    },
    {
      label: 'Public Library',
      value: 3,
    },
    // {
    //   label: 'Official Library',
    //   value: 4,
    // },
    {
      label: 'PDK Library',
      value: 5,
    },
  ],
  varOptions: [
    { value: 0, label: 'dependent variable' },
    { value: 1, label: 'argument' },
  ],
  argOptions: [
    { value: 0, label: 'Device parameters' },
    { value: 1, label: 'Non device parameters' },
  ],
  cellBoardMsg: {
    message: {
      cellNeedUpdate: 'Cell data has been updated, please reopen the board',
    },
    error: {
      partCheckBanFunc: 'This feature is not available in part select mode',
      mergeLayersError: 'Different layers cannot be merged',
    },
  },
  messageBoard: {
    editHistory: 'Edit History',
    show: 'show',
    hide: 'hide',
    team: ' team',
    member: ' member',
    editCurrentCell: ' edited the current cell',
    memberDone: ' member performed ',
    operation: ' operation',
    DRAW_LINE: 'DRAW LINE',
    DRAW_CIRCLE: 'DRAW CIRCLE',
    DRAW_RECT: 'DRAW RECT',
    DRAW_POLYGON: 'DRAW POLYGON',
    DRAW_LABEL: 'DRAW LABEL',
    DRAW_KP: 'DRAW KP',
    DRAW_RULER: 'DRAW RULER',
    DRAW_3D_CUT_AREA: 'DRAW 3D CUT AREA',
    DRAW_3D_RENDER_AREA: 'DRAW 3D RENDER AREA',
    MOVE: 'MOVE',
    STRETCH: 'STRETCH',
    ROTATE: 'ROTATE',
    MIRROR: 'MIRROR',
    MIRRORX: 'MIRRORX',
    MIRRORY: 'MIRRORY',
    ALIGN_PICK_POINT: 'POINT ALIGNMENT',
    ALIGN_PICK_LINE: 'LINE ALIGNMENT',
    ALIGN: 'OVERALL ALIGNMENT',
    DELETE: 'DELETE',
    ADD_DEVICE: 'ADD DEVICE',
    PASTE: 'PASTE',
    CUT: 'CUT',
    MERGE: 'MERGE',
    CUT_AREA: 'CUT AREA',
    SLICE: 'SLICE',
    ARRAY: 'ARRAY',
    BREAK: 'BREAK CELL',
    BOOLEAN: 'BOOLEAN',
    PROPERTY: 'PROPERTY',
    TOOL_AIRBRIDGE: 'TOOL-AIRBRIDGE',
    CONNECT_LINE: 'TOOL-CONNECT LINE',
    TOOL_SQUID_ZLINE_RUN: 'TOOL-SQUID ZLINE',
    TOOL_WAVEGUIDE_RUN: 'TOOL-WAVEGUIDE RUN',
    TOOL_CAVITY_RUN: 'TOOL-CAVITY',
    undo: ' (undo)',
    redo: ' (redo)',
  },

  //图层切换
  options_layerLibrarySelector: [
    {
      value: 0,
      label: "Layout File Layer"
    },
    {
      value: 1,
      label: "Cell Layer"
    },
  ]
}
