const Device = require('../utils/qeda-component.js')
var deviceCollection = new Device()

class BidirectionalMap {
  fwdMap = {}
  revMap = {}

  constructor(map) {
    this.fwdMap = { ...map }
    this.revMap = Object.keys(map).reduce(
      (acc, cur) => ({
        ...acc,
        [map[cur]]: cur,
      }),
      {}
    )
  }
}

const NameMap = new BidirectionalMap({ ...Object.getOwnPropertyNames(deviceCollection.__proto__) })

const anchorTransForm = function (anchor) {
  if (Kernel.GdsAnchor.O == anchor) {
    return 'o'
  }
  if (Kernel.GdsAnchor.N == anchor) {
    return 'n'
  }
  if (Kernel.GdsAnchor.S == anchor) {
    return 's'
  }
  if (Kernel.GdsAnchor.W == anchor) {
    return 'w'
  }
  if (Kernel.GdsAnchor.E == anchor) {
    return 'e'
  }
  if (Kernel.GdsAnchor.NW == anchor) {
    return 'nw'
  }
  if (Kernel.GdsAnchor.NE == anchor) {
    return 'ne'
  }
  if (Kernel.GdsAnchor.SW == anchor) {
    return 'sw'
  }
  if (Kernel.GdsAnchor.SE == anchor) {
    return 'se'
  }
}

export function compressPropStr(prop_json) {
  let s = ''
  s += NameMap.revMap[prop_json.function_name]
  s += ','

  if (prop_json.is_params_device) {
    let keys = Object.keys(deviceCollection.paramsInfo[prop_json.function_name])
    keys.forEach(e => {
      if (e.startsWith('layer') || e.startsWith('datatype')) {
        s += prop_json.params[e]
        s += ','
      } else {
        s += prop_json.params[e].value
        s += ','
      }
    })
  }

  if (prop_json.is_params_device) {
    s += '1'
  } else {
    s += '0'
  }

  return s
}

export function decompressPropStr(prop_str) {
  // strip \" at start and end
  let str_array = prop_str.replace(/[\"\']/g, '')
  str_array = str_array.split(',')

  if (str_array[0].startsWith('{function_name:')) {
    return JSON.parse(prop_str)
  }

  let fn_name = NameMap.fwdMap[str_array[0]]

  let params_device = null
  if (str_array[str_array.length - 1] === '1') {
    params_device = true
  } else {
    params_device = false
  }

  let params_obj = null
  if (params_device) {
    let params_array = str_array.slice(1, str_array.length - 1)
    params_obj = deviceCollection.paramsInfo[fn_name]
    let keys = Object.keys(params_obj)
    let index = 0
    keys.forEach(e => {
      if (e.startsWith('layer') || e.startsWith('datatype')) {
        params_obj[e] = parseInt(params_array[index])
      } else {
        params_obj[e].value = parseFloat(params_array[index])
      }
      index += 1
    })
  }

  let result = {
    function_name: fn_name,
    params: params_obj,
    is_params_device: params_device,
  }

  return result
}

export function getCellFunc(name, selectParamsDeviceParams, updateCell) {
  let target_cell_obj = null
  let is_params_device = true
  switch (name) {
    case 'stepper_marker':
      target_cell_obj = deviceCollection.stepper_marker()
      is_params_device = false
      break
    case 'XZ_transform':
      target_cell_obj = deviceCollection.XZ_transform()
      is_params_device = false
      break
    case 'X_line':
      target_cell_obj = deviceCollection.X_line(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.a.value, selectParamsDeviceParams.c.value, selectParamsDeviceParams.d.value) //
      break
    case 'Z_line_1':
      target_cell_obj = deviceCollection.Z_line_1(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.b.value,
        selectParamsDeviceParams.c.value,
        selectParamsDeviceParams.d.value,
        selectParamsDeviceParams.e.value,
        selectParamsDeviceParams.f.value,
        selectParamsDeviceParams.g.value
      ) //
      break
    case 'Z_line_2':
      target_cell_obj = deviceCollection.Z_line_2()
      is_params_device = false
      break
    case 'Cap1_Qubit_1':
      target_cell_obj = deviceCollection.Cap1_Qubit_1()
      is_params_device = false
      break
    case 'Cap1_Qubit_2':
      target_cell_obj = deviceCollection.Cap1_Qubit_2()
      is_params_device = false
      break
    case 'Cap1_Qubit_3':
      target_cell_obj = deviceCollection.Cap1_Qubit_3()
      is_params_device = false
      break
    case 'Cap1_Qubit_4':
      target_cell_obj = deviceCollection.Cap1_Qubit_4()
      is_params_device = false
      break
    case 'Cap1_Qubit_5':
      target_cell_obj = deviceCollection.Cap1_Qubit_5()
      is_params_device = false
      break
    case 'Cap1_Qubit_6':
      target_cell_obj = deviceCollection.Cap1_Qubit_6()
      is_params_device = false
      break
    case 'Cap1_Qubit_7':
      target_cell_obj = deviceCollection.Cap1_Qubit_7()
      is_params_device = false
      break
    case 'Resonator_FPDesign_1':
      target_cell_obj = deviceCollection.Resonator_FPDesign_1(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.tolerance.value,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.A4.value,
        selectParamsDeviceParams.A5.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value
      ) //
      break
    case 'Resonator_FPDesign_2':
      target_cell_obj = deviceCollection.Resonator_FPDesign_2(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.tolerance.value,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value,
        selectParamsDeviceParams.B2.value,
        selectParamsDeviceParams.C.value
      ) //
      break
    case 'ChipFrame_1':
      target_cell_obj = deviceCollection.ChipFrame_1(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.A1.value) //
      break
    case 'ChipFrame_2':
      target_cell_obj = deviceCollection.ChipFrame_2()
      is_params_device = false
      break
    case 'ChipFrame_3':
      target_cell_obj = deviceCollection.ChipFrame_3()
      is_params_device = false
      break
    case 'Indium_1':
      target_cell_obj = deviceCollection.Indium_1(
        selectParamsDeviceParams.layer_indium,
        selectParamsDeviceParams.datatype_indium,
        selectParamsDeviceParams.layer_indium_flip,
        selectParamsDeviceParams.datatype_indium_flip,
        selectParamsDeviceParams.layer_tin,
        selectParamsDeviceParams.datatype_tin,
        selectParamsDeviceParams.layer_tin_flip,
        selectParamsDeviceParams.datatype_tin_flip,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.tolerance.value
      ) //
      break
    case 'Indium_2':
      target_cell_obj = deviceCollection.Indium_2()
      is_params_device = false
      break
    case 'Indium_3':
      target_cell_obj = deviceCollection.Indium_3()
      is_params_device = false
      break
    case 'Inlinepad_1':
      target_cell_obj = deviceCollection.Inlinepad_1(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.A1.value, selectParamsDeviceParams.tolerance.value) //
      break
    case 'Inlinepad_2':
      target_cell_obj = deviceCollection.Inlinepad_2(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.A1.value, selectParamsDeviceParams.tolerance.value) //
      break
    case 'Inlinepad_3':
      target_cell_obj = deviceCollection.Inlinepad_3(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.A1.value, selectParamsDeviceParams.tolerance.value) //
      break
    case 'Interdigital_cap_1':
      target_cell_obj = deviceCollection.Interdigital_cap_1()
      is_params_device = false
      break
    case 'Interdigital_cap_2':
      target_cell_obj = deviceCollection.Interdigital_cap_2()
      is_params_device = false
      break
    case 'AB_1':
      target_cell_obj = deviceCollection.AB_1(
        selectParamsDeviceParams.layer_pier,
        selectParamsDeviceParams.datatype_pier,
        selectParamsDeviceParams.layer_deck,
        selectParamsDeviceParams.datatype_deck,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.A4.value
      ) //
      break
    case 'AB_2':
      target_cell_obj = deviceCollection.AB_2()
      is_params_device = false
      break
    case 'AB_3':
      target_cell_obj = deviceCollection.AB_3()
      is_params_device = false
      break
    case 'Cross_AB_1':
      target_cell_obj = deviceCollection.Cross_AB_1()
      is_params_device = false
      break
    case 'Cross_AB_2':
      target_cell_obj = deviceCollection.Cross_AB_2()
      is_params_device = false
      break
    case 'Cross_AB_3':
      target_cell_obj = deviceCollection.Cross_AB_3()
      is_params_device = false
      break
    case 'Squid_1':
      target_cell_obj = deviceCollection.Squid_1()
      is_params_device = false
      break
    case 'Squid_2':
      target_cell_obj = deviceCollection.Squid_2()
      is_params_device = false
      break
    case 'Squid_3':
      target_cell_obj = deviceCollection.Squid_3()
      is_params_device = false
      break
    case 'Cap1_Qubit_Qubit_1':
      target_cell_obj = deviceCollection.Cap1_Qubit_Qubit_1(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.a.value,
        selectParamsDeviceParams.a1.value,
        selectParamsDeviceParams.b.value,
        selectParamsDeviceParams.b1.value,
        selectParamsDeviceParams.c.value,
        selectParamsDeviceParams.c1.value,
        selectParamsDeviceParams.c2.value
      ) //
      break
    case 'Cap1_Qubit_Qubit_2':
      target_cell_obj = deviceCollection.Cap1_Qubit_Qubit_2(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value,
        selectParamsDeviceParams.B2.value,
        selectParamsDeviceParams.B3.value,
        selectParamsDeviceParams.B4.value,
        selectParamsDeviceParams.B5.value,
        selectParamsDeviceParams.C.value,
        selectParamsDeviceParams.C1.value,
        selectParamsDeviceParams.C2.value
      ) //
      break
    case 'Cap1_Qubit_Resonator_1':
      target_cell_obj = deviceCollection.Cap1_Qubit_Resonator_1(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value
      ) //
      break
    case 'Cap1_Qubit_Resonator_2':
      target_cell_obj = deviceCollection.Cap1_Qubit_Resonator_2(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.B.value, selectParamsDeviceParams.B1.value) //
      break
    case 'Cap1_Qubit_Resonator_3':
      target_cell_obj = deviceCollection.Cap1_Qubit_Resonator_3(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.B.value, selectParamsDeviceParams.B3.value) //
      break
    case 'Cap1_Qubit_Resonator_4':
      target_cell_obj = deviceCollection.Cap1_Qubit_Resonator_4(selectParamsDeviceParams.layer, selectParamsDeviceParams.datatype, selectParamsDeviceParams.A.value, selectParamsDeviceParams.B.value, selectParamsDeviceParams.B3.value) //
      break
    case 'WBPad':
      target_cell_obj = deviceCollection.WBPad(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value,
        selectParamsDeviceParams.B2.value,
        selectParamsDeviceParams.C.value,
        selectParamsDeviceParams.C1.value
      ) //
      break
    case 'JJTest':
      target_cell_obj = deviceCollection.JJTest(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.layer_squid,
        selectParamsDeviceParams.datatype_squid,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.B.value,
        selectParamsDeviceParams.B1.value,
        selectParamsDeviceParams.B2.value,
        selectParamsDeviceParams.B3.value
      ) //
      break
    case 'Marker':
      target_cell_obj = deviceCollection.Marker()
      is_params_device = false
      break
    case 'CPW':
      target_cell_obj = deviceCollection.CPW(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.A3.value,
        selectParamsDeviceParams.A4.value,
        selectParamsDeviceParams.tolerance.value
      ) //
      break
    case 'CPW_corner':
      target_cell_obj = deviceCollection.CPW_corner(
        selectParamsDeviceParams.layer,
        selectParamsDeviceParams.datatype,
        selectParamsDeviceParams.A.value,
        selectParamsDeviceParams.A1.value,
        selectParamsDeviceParams.A2.value,
        selectParamsDeviceParams.tolerance.value
      ) //
      break
    case 'TransmissionResonator':
      target_cell_obj = deviceCollection.TransmissionResonator(
        selectParamsDeviceParams.layer_pad,
        selectParamsDeviceParams.datatype_pad,
        selectParamsDeviceParams.layer_resistor,
        selectParamsDeviceParams.datatype_resistor,
        selectParamsDeviceParams.layer_resonator,
        selectParamsDeviceParams.datatype_resonator,
        selectParamsDeviceParams.GapType.value,
        // selectParamsDeviceParams.L1.value,
        // selectParamsDeviceParams.L2.value,
        // selectParamsDeviceParams.L3.value,
        // selectParamsDeviceParams.L4.value,
        // selectParamsDeviceParams.L5.value,
        // selectParamsDeviceParams.L6.value,
        // selectParamsDeviceParams.L7.value,
        // selectParamsDeviceParams.L8.value,
        // selectParamsDeviceParams.L9.value,
        // selectParamsDeviceParams.L10.value,
        selectParamsDeviceParams.L11.value,
        // selectParamsDeviceParams.L12.value,
        // selectParamsDeviceParams.L13.value,
        // selectParamsDeviceParams.L14.value,
        // selectParamsDeviceParams.L15.value,
        // selectParamsDeviceParams.L16.value,
        // selectParamsDeviceParams.D2.value,
        selectParamsDeviceParams.w.value,
        selectParamsDeviceParams.s.value
      ) //
      break
    case 'ReflectionResonator':
      target_cell_obj = deviceCollection.ReflectionResonator(
        selectParamsDeviceParams.layer_pad,
        selectParamsDeviceParams.datatype_pad,
        selectParamsDeviceParams.layer_resistor,
        selectParamsDeviceParams.datatype_resistor,
        selectParamsDeviceParams.layer_resonator,
        selectParamsDeviceParams.datatype_resonator,
        selectParamsDeviceParams.GapType.value,
        // selectParamsDeviceParams.L1.value,
        // selectParamsDeviceParams.L2.value,
        // selectParamsDeviceParams.L3.value,
        // selectParamsDeviceParams.L4.value,
        // selectParamsDeviceParams.L5.value,
        // selectParamsDeviceParams.L6.value,
        // selectParamsDeviceParams.L7.value,
        // selectParamsDeviceParams.L8.value,
        // selectParamsDeviceParams.L9.value,
        // selectParamsDeviceParams.L10.value,
        selectParamsDeviceParams.L11.value,
        // selectParamsDeviceParams.L12.value,
        // selectParamsDeviceParams.L13.value,
        // selectParamsDeviceParams.L14.value,
        // selectParamsDeviceParams.L15.value,
        // selectParamsDeviceParams.L16.value,
        // selectParamsDeviceParams.D2.value,
        selectParamsDeviceParams.w.value,
        selectParamsDeviceParams.s.value
      ) //
      break
    case 'SiMos_Single_SET':
      target_cell_obj = deviceCollection.SiMos_Single_SET(
        selectParamsDeviceParams.layer_screen,
        selectParamsDeviceParams.datatype_screen,
        selectParamsDeviceParams.layer_co_zone,
        selectParamsDeviceParams.datatype_co_zone,
        selectParamsDeviceParams.layer_co,
        selectParamsDeviceParams.datatype_co,
        selectParamsDeviceParams.layer_barrier,
        selectParamsDeviceParams.datatype_barrier,
        selectParamsDeviceParams.layer_plunger,
        selectParamsDeviceParams.datatype_plunger,
        selectParamsDeviceParams.layer_ion,
        selectParamsDeviceParams.datatype_ion,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.l1.value,
        selectParamsDeviceParams.d1.value,
        selectParamsDeviceParams.l2.value,
        selectParamsDeviceParams.w1.value,
        selectParamsDeviceParams.w2.value,
        selectParamsDeviceParams.l3.value,
        selectParamsDeviceParams.d2.value,
        selectParamsDeviceParams.h1.value,
        selectParamsDeviceParams.w3.value,
        selectParamsDeviceParams.d3.value,
        selectParamsDeviceParams.h2.value,
        selectParamsDeviceParams.w4.value,
        selectParamsDeviceParams.SETy.value,
        selectParamsDeviceParams.Cox.value,
        selectParamsDeviceParams.Coy.value,
        selectParamsDeviceParams.Col1.value,
        selectParamsDeviceParams.Col2.value,
        selectParamsDeviceParams.Col3.value,
        selectParamsDeviceParams.Coh1.value,
        selectParamsDeviceParams.Coh2.value,
        selectParamsDeviceParams.Coh3.value
      ) //
      break
    case 'SiMos_Double_SET':
      target_cell_obj = deviceCollection.SiMos_Double_SET(
        selectParamsDeviceParams.layer_screen,
        selectParamsDeviceParams.datatype_screen,
        selectParamsDeviceParams.layer_co_zone,
        selectParamsDeviceParams.datatype_co_zone,
        selectParamsDeviceParams.layer_co,
        selectParamsDeviceParams.datatype_co,
        selectParamsDeviceParams.layer_barrier,
        selectParamsDeviceParams.datatype_barrier,
        selectParamsDeviceParams.layer_plunger,
        selectParamsDeviceParams.datatype_plunger,
        selectParamsDeviceParams.layer_ion,
        selectParamsDeviceParams.datatype_ion,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.l1.value,
        selectParamsDeviceParams.d1.value,
        selectParamsDeviceParams.l2.value,
        selectParamsDeviceParams.w1.value,
        selectParamsDeviceParams.w2.value,
        selectParamsDeviceParams.l3.value,
        selectParamsDeviceParams.d2.value,
        selectParamsDeviceParams.h1.value,
        selectParamsDeviceParams.w3.value,
        selectParamsDeviceParams.d3.value,
        selectParamsDeviceParams.h2.value,
        selectParamsDeviceParams.w4.value,
        selectParamsDeviceParams.SETy.value,
        selectParamsDeviceParams.Cox.value,
        selectParamsDeviceParams.Coy.value,
        selectParamsDeviceParams.Col1.value,
        selectParamsDeviceParams.Col2.value,
        selectParamsDeviceParams.Col3.value,
        selectParamsDeviceParams.Coh1.value,
        selectParamsDeviceParams.Coh2.value,
        selectParamsDeviceParams.Coh3.value
      ) //
      break
    case 'SiGe':
      target_cell_obj = deviceCollection.SiGe(
        selectParamsDeviceParams.layer_screen,
        selectParamsDeviceParams.datatype_screen,
        selectParamsDeviceParams.layer_co_zone,
        selectParamsDeviceParams.datatype_co_zone,
        selectParamsDeviceParams.layer_co,
        selectParamsDeviceParams.datatype_co,
        selectParamsDeviceParams.layer_barrier,
        selectParamsDeviceParams.datatype_barrier,
        selectParamsDeviceParams.layer_plunger,
        selectParamsDeviceParams.datatype_plunger,
        selectParamsDeviceParams.layer_ion,
        selectParamsDeviceParams.datatype_ion,
        selectParamsDeviceParams.layer_TiAu,
        selectParamsDeviceParams.datatype_TiAu,
        selectParamsDeviceParams.layer_mesa,
        selectParamsDeviceParams.datatype_mesa,
        selectParamsDeviceParams.Cox.value,
        selectParamsDeviceParams.Coy.value,
        selectParamsDeviceParams.Col1.value,
        selectParamsDeviceParams.Col2.value,
        selectParamsDeviceParams.Col3.value,
        selectParamsDeviceParams.Coh1.value,
        selectParamsDeviceParams.Coh2.value,
        selectParamsDeviceParams.Coh3.value,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.l1.value,
        selectParamsDeviceParams.d1.value,
        selectParamsDeviceParams.l2.value,
        selectParamsDeviceParams.w1.value,
        selectParamsDeviceParams.l3.value,
        selectParamsDeviceParams.w2.value,
        selectParamsDeviceParams.d2.value,
        selectParamsDeviceParams.d3.value,
        selectParamsDeviceParams.h1.value,
        selectParamsDeviceParams.h2.value,
        selectParamsDeviceParams.d4.value,
        selectParamsDeviceParams.h3.value,
        selectParamsDeviceParams.w3.value,
        selectParamsDeviceParams.SETy.value
      ) //
      break
    case 'GaAs':
      target_cell_obj = deviceCollection.GaAs(
        selectParamsDeviceParams.layer_ion,
        selectParamsDeviceParams.datatype_ion,
        selectParamsDeviceParams.layer_TiAu,
        selectParamsDeviceParams.datatype_TiAu,
        selectParamsDeviceParams.layer_co_zone,
        selectParamsDeviceParams.datatype_co_zone,
        selectParamsDeviceParams.layer_qubit,
        selectParamsDeviceParams.datatype_qubit,
        selectParamsDeviceParams.Q1.value,
        selectParamsDeviceParams.Q2.value,
        selectParamsDeviceParams.Q3.value,
        selectParamsDeviceParams.Q4.value,
        selectParamsDeviceParams.Q5.value,
        selectParamsDeviceParams.Q6.value,
        selectParamsDeviceParams.Q7.value,
        selectParamsDeviceParams.Q8.value
      ) //
      break
    case 'Single_Channle_FDSOI':
      target_cell_obj = deviceCollection.Single_Channle_FDSOI(
        selectParamsDeviceParams.layer_lead,
        selectParamsDeviceParams.datatype_lead,
        selectParamsDeviceParams.layer_channel,
        selectParamsDeviceParams.datatype_channel,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.a.value,
        selectParamsDeviceParams.b.value,
        selectParamsDeviceParams.W.value,
        selectParamsDeviceParams.a1.value,
        selectParamsDeviceParams.b1.value,
        selectParamsDeviceParams.c1.value,
        selectParamsDeviceParams.d1.value,
        selectParamsDeviceParams.b2.value,
        selectParamsDeviceParams.c2.value,
        selectParamsDeviceParams.c3.value,
        selectParamsDeviceParams.w1.value
      ) //
      break
    case 'Double_Channle_FDSOI':
      target_cell_obj = deviceCollection.Double_Channle_FDSOI(
        selectParamsDeviceParams.layer_lead,
        selectParamsDeviceParams.datatype_lead,
        selectParamsDeviceParams.layer_channel,
        selectParamsDeviceParams.datatype_channel,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.a.value,
        selectParamsDeviceParams.b.value,
        selectParamsDeviceParams.W.value,
        selectParamsDeviceParams.a1.value,
        selectParamsDeviceParams.c1.value,
        selectParamsDeviceParams.d1.value,
        selectParamsDeviceParams.b2.value,
        selectParamsDeviceParams.c2.value,
        selectParamsDeviceParams.w1.value,
        selectParamsDeviceParams.w2.value,
        selectParamsDeviceParams.c3.value
      ) //
      break
    case 'FinFET':
      target_cell_obj = deviceCollection.FinFET(
        selectParamsDeviceParams.layer_barrier,
        selectParamsDeviceParams.datatype_barrier,
        selectParamsDeviceParams.layer_plunger,
        selectParamsDeviceParams.datatype_plunger,
        selectParamsDeviceParams.layer_source,
        selectParamsDeviceParams.datatype_source,
        selectParamsDeviceParams.layer_fin,
        selectParamsDeviceParams.datatype_fin,
        selectParamsDeviceParams.n.value,
        selectParamsDeviceParams.w.value,
        selectParamsDeviceParams.d.value,
        // selectParamsDeviceParams.h.value,
        selectParamsDeviceParams.h0.value,
        selectParamsDeviceParams.h1.value,
        selectParamsDeviceParams.h2.value,
        selectParamsDeviceParams.b.value,
        selectParamsDeviceParams.a1.value,
        selectParamsDeviceParams.a2.value,
        selectParamsDeviceParams.D1.value,
        selectParamsDeviceParams.H.value,
        selectParamsDeviceParams.L.value,
        selectParamsDeviceParams.g.value,
        selectParamsDeviceParams.L0.value,
        selectParamsDeviceParams.L1.value,
        selectParamsDeviceParams.H0.value
      ) //
      break
    default:
      break
  }
  if (target_cell_obj) {
    if (updateCell) {
      let target_obj_cell = updateCell
      let labels = target_obj_cell.labels
      let real_labels = updateCell.labels
      let real_origin_labels = target_cell_obj.labels
      // target_cell_obj.remove(target_cell_obj.labels)
      let origin_labels_text_list = real_origin_labels.map(item => {
        return item.text
      })
      if (labels.length > 0) {
        let wait_labels = []
        for (let i in labels) {
          if (labels[i].get_gds_property(2)) {
            let label = new QGdstk.Label('', [0, 0], 'o', 0, 1, false, 1, 0)
            let prop = {
              function_name: target_cell_obj.name,
              params: selectParamsDeviceParams,
              is_params_device: is_params_device,
            }
            let compress_prop = compressPropStr(prop)
            let decom = decompressPropStr(compress_prop)
            label.set_gds_property(2, JSON.stringify(compress_prop)) //参数化器件标记
            target_cell_obj.add(label)
          } else if (labels[i].get_gds_property(1)) {
            // 判断是否为用户添加的关键点
            let origin_text = ['O', 'O1', 'O2', 'O3']

            if (origin_labels_text_list.indexOf(labels[i].text) == -1 && !origin_text.includes(labels[i].text)) {
              wait_labels.push(real_labels[i])
            }
          }
        }
        // 将用户添加的关键点放入新生成的器件中
        for (let i in wait_labels) {
          let kp_pro_str = wait_labels[i].text
          let font_size = wait_labels[i].font_size

          let O = new QGdstk.Label(wait_labels[i].text, wait_labels[i].origin, anchorTransForm(wait_labels[i].anchor), 0, 1, false, wait_labels[i].layer, 0)
          O.set_gds_property(1, kp_pro_str)
          O.set_gds_property(0, String(font_size))
          target_cell_obj.add(O)
        }
      }
      let target_obj = new QGdstk.Library(target_cell_obj.name, 1e-6, 1e-9)
      target_obj.add(target_cell_obj)
      target_obj.add(target_cell_obj.dependencies(true))
      return target_obj
      // let prop = {
      //   function_name: target_cell_obj.name,
      //   params: selectParamsDeviceParams,
      //   is_params_device: is_params_device,
      // };
      // label.set_gds_property(2, JSON.stringify(prop)); //参数化器件标记
      // target_cell_obj.add(label);

      // // target_cell_obj.position = deviceConf.points;
      // let target_obj = new QGdstk.Library(target_cell_obj.name, 1e-6, 1e-9);
      // target_obj.add(target_cell_obj);
      // target_obj.add(target_cell_obj.dependencies(true));

      // return target_obj;
    } else {
      let label = new QGdstk.Label('', [0, 0], 'o', 0, 1, false, 1, 0)
      let prop = {
        function_name: target_cell_obj.name,
        params: selectParamsDeviceParams,
        is_params_device: is_params_device,
      }
      let compress_prop = compressPropStr(prop)
      let decom = decompressPropStr(compress_prop)
      label.set_gds_property(2, JSON.stringify(compress_prop)) //参数化器件标记
      target_cell_obj.add(label)

      // target_cell_obj.position = deviceConf.points;
      let target_obj = new QGdstk.Library(target_cell_obj.name, 1e-6, 1e-9)
      target_obj.add(target_cell_obj)
      target_obj.add(target_cell_obj.dependencies(true))

      return target_obj
    }
  }
  return null
}
