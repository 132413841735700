<template>
  <div id="app">
    <transition name="el-zoom-in-center">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  created () {
    if (!localStorage.getItem('userId')) {
      this.$router.push("/login");
    } else {
      if(this.$router.path !== "/home/layout"){
        this.$router.push("/home")
      }
    }
  }
}
</script>

<style>
@import "./assets/css/main.css";
</style>