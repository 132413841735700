export const messages = {
  // 首页
  qlayoutWelcome: '欢迎使用QLayout',
  quickStart: '快速开始',
  instructions: '使用文档',
  tutorial: '使用教程',
  new: '新建',
  oldVersion: '旧版本',
  video_1: '24比特版图绘制示例',
  video_2: '团队协作',
  video_3: '团队1bit原理图版图绘制',
  video_4: '智能工具',
  video_5: '加载PDK/生成PDK',
  video_6: '自动布线',
  video_7: '团队协作及权限设置',
  video_8: '3D视图',
  loginTips: '本系统支持谷歌浏览器内核和Microsoft Edge浏览器内核',
  transforming: '正在进行数据迁移',
  transformSuccess: '迁移成功，请重新打开新版本文件',
  transformError: '迁移失败',
  //弹窗,文件
  yes: '是',
  no: '否',
  tips: '提示',
  sure: '确定',
  cancel: '取消',
  pleaseChoose: '请选择',
  enterProjectName: '请输入项目名',
  enterFileName: '请输入文件名',
  enterCellName: '请输入cell文件名',
  loadProject: '请先加载项目',
  creatSucces: '创建成功',
  fileNameError: '文件名输入格式有误,请输入以字母开头,以字母数字下划线组成的文件名',
  fileFormatError: '文件类型必须是GDS，且不能为空文件',
  fileImportFailed: '支持导入500kb以下的文件，文件过大导入失败',
  projectNumberLimit: '项目数量不能超过3个',
  fileNumberLimit: '项目下文件数量不能超过3个',
  selectFile: '请先选择文件',
  noFiles: '你还没有创建任何文件',
  newCell: '新建cell文件',
  deleteFile: '删除文件',
  deleteCell: '删除cell文件',
  deleteProject: '删除项目',
  cancelInput: '取消输入',
  FilenameEmpty: '文件名不能为空',
  nameCannotEmpty: '项目名不能为空',
  empty: '清空',
  projectPermanentlyDeleted: '此操作将永久删除该项目, 是否继续?',
  deleteSuccess: '删除成功',
  deleteCancel: '已取消删除',
  filePermanentlyDeleted: '此操作将永久删除该文件, 是否继续?',
  enterCellName: '请输入cell名',
  cellPermanentlyDeleted: '此操作将永久删除该cell, 是否继续?',
  saveChanges: '是否保存对此文件的更改?',
  closeWithoutSaving: '关闭且不保存',
  closeCancel: '已取消关闭',
  switchingCellFiles: '在此处切换不同项目下的cell文件会导致切换失败，对于不同项目下的cell文件请在“文件列表”中进行切换！',
  notShownThisTime: '本次不再显示',
  alreadyKnown: '已知悉',
  cellTooLarge: '保存cell文件过大无法保存',
  waitLoading: '加载中，请耐心等待',
  cellNumberError: '文件下只允许创建一个cell',
  loadCellFirst: '请先加载Cell',
  incorrectFormat: '格式不正确',
  itemBelong: '项目归属',
  person: '个人',
  team: '团队',
  userAndTeamName: '用户/团队名',
  projectName: '项目名称',
  fileName: '文件名称',
  fileType: '文件类型',
  LayoutFile: '版图文件',
  SchematicFile: '原理图文件',
  // fileName: '版图文件名称',
  cellType: '单元类型',
  fileCell: '版图单元',
  componentCell: '器件单元',
  cellName: '单元名称',
  SchematicName: '原理图名称',
  Layout: '版图',
  personName: '个人用户名',
  teamName: '团队名',
  pleaseEnterProjectName: '请输入项目名',
  pleaseEnterFileName: '请输入文件名',
  pleaseEnterCellName: '请输入单元名',
  pleaseEnterSchematicName: '请输入原理图名',
  pleaseEnterSignName: '请输入符号名',
  pleaseEnterPinName: '请输入引脚名',
  pleaseEnterType: '请输入类型',
  pleaseEnterAngle: '请输入角度',
  teamDetail: '团队信息',
  noTeamInfo: '还没有团队，快去创建团队吧',
  uploadFile: '指定上传文件',
  upload: '上传',
  rename: '重命名',
  instance: '调用',
  wrong: '错误',
  userProjectPermission: '用户没有该项目的权限',
  userFilePermission: '用户没有该文件的权限',
  userCellPermission: '用户没有该单元的权限',
  fileCopySucceeded: '文件复制到项目成功',
  cellCopySucceeded: '单元复制到文件成功',
  layoutSplitSuccessfully: '版图拆分成功',
  NoProjectsUnderUser: '当前个人下无项目',
  NoProjectsUnderTeam: '当前团队下无项目',
  cannotImport: '无可编辑权限，不能导入操作',
  successfullyModified: '修改成功',
  saveAsUnitSucceeded: '另存为单元成功',
  unframedData: '未框选数据',
  importSucceeded: '导入成功',
  createSucceeded: '新建成功',
  selectTeamFirst: '请先选择团队',
  fileTip1: '字符需大于1，仅支持中文、英文、数字、和符号+ - _  &  () [] . ~',
  fileTip2: '字符需大于1，仅支持英文、数字、和符号+ - _  &  () [] . ~',
  fileTip3: '标签名长度不能超过20！',
  fileTip4: '至少输入一个标签',
  fileTip5: '请先选择团队',
  fileTip6: '请先打开至少一个单元',
  fileTip7: '警告，版图拆分中，请等待~',
  fileTip8: '用户不在当前项目中',
  fileTip9: '请先关闭以只读模式打开的画板',
  fileTip10: '请输入正确的引脚名',
  fileTip11: '其他人正在使用',
  fileTip12: '有其他人正在使用的单元',
  fileTip13: '最大图层编号为4294967295',
  fileTip14: '请选择不同的图层',
  fileTip15: '单元数据没有变化，无需保存',
  fileTip16: '单元数据缺失',

  OpeninReadOnly: '以只读模式打开',
  PersonalSymbolLibrary: '个人符号库',
  TeamSymbolLibrary: '团队符号库',
  commonSymbolLibrary: '公共符号库',
  saveToSymbolLibrary: '存到符号库',
  duplication: '该名称已存在',
  shareNoFile: '目录错误或没有可接收的文件',
  selectNone: '可添加的列表为空',
  importGDSSizeError: '大小不能超过25MB',
  importSymbolSizeError: '大小不能超过5MB',
  importDXFSizeError: '大小不能超过2MB',

  // 顶部导航栏
  file: '文件',
  toDraw: '绘制',
  share: '共享',
  setUp: '设置',
  view: '视图',
  check: '检测',
  help: '帮助',
  newProject: '新建项目',
  newFile: '新建文件',
  openProject: '打开项目',
  importPDK: '导入PDK',
  importGDS: '导入GDS',
  importDXF: '导入DXF',
  exportGDS: '导出GDS',
  exportDXF: '导出DXF',
  import: '导入',
  export: '导出',
  saveCell: '保存cell',
  recentBrowsing: '近期浏览',
  edit: '编辑',
  copy: '复制',
  cut: '剪切',
  paste: '粘贴',
  undo: '撤销',
  redo: '恢复',
  browsingRecord: '暂无浏览记录',

  saveAs: '另存为',
  checkSchematicDiagram: '检查',
  openSymbol: '打开符号',
  Symbol: '符号',
  symbolNotExist: '当前符号不存在',
  net: '网络',
  encapsulation: '封装',
  outputNetlist: '输出网表',
  import: '导入',
  export: '导出',
  importSchFile: '导入原理图文件',
  exportSchFile: '导出原理图文件',

  download: '下载',
  displayLabel: '显示标签',
  loadPDK: '加载PDK',
  generatePDK: '生成PDK',
  layerFile: '图层文件',
  designFile: '设计规则检查文件',
  libraryFile: '库文件',
  fileOf3D: '3D工艺文件',
  designTips: '请选择编译通过的文件',
  layerFileTips: '请打开版图画板',
  g_personalLibrary: '个人库',
  g_teamLibrary: '团队库',
  g_PDKLibrary: 'PDK库',
  g_publicLibrary: '公用库',
  g_officialLibrary: '官方库',

  // 编辑
  select: '选择',
  Move: '移动',
  Rotate: '旋转',
  draw: '拉伸',
  Flip: '翻转',
  FullSelect: '整体选择',
  PartialSelect: '部分选择',
  FlipHorizontal: '水平翻转',
  FlipVertical: '垂直翻转',
  QuickAlign: '快速对齐',
  PointAlign: '点对齐',
  LineAlign: '线对齐',
  Align: '对齐',
  BasicTools: '基础工具',
  Slice: '切割',
  HorizontalSlice: '水平切割',
  VerticalSlice: '垂直切割',
  Nibble: '区域切除',
  Merge: '合并',
  AdvancedTools: '高级工具',
  Align: '对齐',
  alignLeft: '左对齐',
  alignRight: '右对齐',
  alignTop: '上对齐',
  alignBottom: '下对齐',
  alignHorizontalCenter: '横向中线对齐',
  alignVerticalCenter: '竖向中线对齐',
  Center: '中心',
  Up: '上',
  Down: '下',
  Left: '左',
  Right: '右',
  upperLeft: '左上',
  rightUpper: '右上',
  bottomLeft: '左下',
  lowerRight: '右下',
  Boolean: '布尔运算',
  Array: '阵列',
  ArrayCopy: '阵列复制',
  Hierarchy: '层级',
  DescendRead: '进入下层',
  DescendEdit: '编辑下层',
  Return: '返回上层',
  ReturntoTop: '返回最上层',
  MakeCell: '另存为单元',
  Flatten: '打散',
  TextContent: '文本内容',
  layerLocation: '图层位置',
  SelectedLayer: '选中的图层',
  Defined: '自定义',
  LabelSettings: '标签的设置',
  fontSize: '字体大小',
  createKeyPoint: '创建关键点',
  createLabel: '创建标签',
  AlignmentDistance: '对齐距离',
  Alignment: '对齐方式',
  axisLocation: '对称轴位置相对中心点位置',
  OriginalGraphics: '原图形',
  Retain: '保留',
  rowsCells: '行/列',
  row: '行',
  column: '列',
  RowVector: '行向量',
  ColumnVector: '列向量',
  path: '路径',
  type: '类型',
  Flattten: '打散层级',
  OneLayer: '一层',
  all: '全部',
  ParameterizedCell: '参数化单元',
  SavetoLibrary: '存到器件库',
  Group: '组别',
  teamSelected: '团队选择',
  coverWarning: '覆盖提醒',
  DrawWire: '拉伸导线',
  RotatedClockwise: '顺时针旋转90°',
  RotatedCounterclockwise: '逆时针旋转90°',
  create: '创建',
  CouplingLine: '耦合线',
  AngleSetting: '角度设置',
  CreatePin: '创建引脚',
  label: '标签',
  InstanceName: '实例名称',
  LabelName: '符号名称',
  KeyParameter: '关键参数',
  PinName: '引脚名称',
  PortName: '端口名称',
  OtherLabel: '其他标签',
  Highlight: '高亮',
  HighlightNetwork: '高亮网络',
  RemoveHighlights: '移除高亮',
  DisplaySettings: '显示设置',
  CheckSettings: '检查设置',
  Pin: '引脚',
  input: '输入',
  inputAndOutput: '输入输出',
  checkContent: '检查内容',
  checkLevel: '检查等级',
  ignore: '忽略',

  //pcell
  PCellGen: 'Pcell生成器',

  // 绘制
  Line: '线',
  Rectangle: '矩形',
  Polygon: '多边形',
  Circle: '圆',
  Text: '文本图形',
  AuxiliaryTool: '辅助工具',
  Rule: '刻度尺',
  Label: '标签',
  KeyPoint: '关键点',
  BoldTypeface: '黑体',
  SongTypeface: '宋体',

  //工具
  tool: '工具',
  bigLayoutLayerTool: '大版图工具',
  SplittheLayoutbylayer: '按图层拆分版图',
  connectLine: '连接线',
  Superconductivity: '超导',
  Semiconductor: '半导',
  CPWs: '共面波导',
  AirBridge: '空气桥',
  AutomaticLayoutoftheSQUIDandZ_line: 'SQUID和Z线的自动布局',
  JJ: 'JJ工具',
  Distance: '间隔',
  Avoid: '避开',
  SelectionAll: '全选',
  InvertSelection: '反选',
  Region: '区域',
  Oneside: '一边',
  TheOtherSide: '另一边',
  CheckLine: '检测线',
  Drc: '设计规则编辑及检查',
  Minimumdistanceofthetwolines: '两条线最小距离',
  Properties: '属性面板',
  UserGuide: '用户指南',
  VideoTutorial: '演示视频',
  About: '关于',
  QuantumChipDesignIndustrialSoftware: '量子芯片设计工业软件',
  Version: '版本',
  feedback: '反馈',
  feedback_title: '请您将遇到的问题发送到该邮箱',
  feedback_info: '可以描述问题的模块，操作步骤，上传图片、视频，以便我们更好的解决问题',

  //自动工具
  autotools: '自动工具',
  layout: '版图',
  results: '报告',
  operation: '操作',
  connectToMQTT: '连接MQTT',
  viewToolGuide: '查看工具使用说明',
  LoadtheGDS: '加载GDS',
  saveConfiguration: '保存配置',
  OK: '确定',
  rules: '规则',
  GDSPath: 'GDS本地路径',
  NameofCell: '需要加载的cell名称',
  parameters: '配置参数',
  // checkLine: '检测线',
  minmumDistanceBetweenTwoLines: '两条线之间的最小距离',
  placetheWBPad: '放WBPad',
  distanceD1: 'WBPad到基础边框的距离d1',
  distanceD2: 'WBPad到基础边框的距离d2',
  distanceCPWCenterline: '检测到所有骨架线整体边框距离',
  size: '尺寸',
  placetheInLinePad: '放InLinePad',
  minDistanceBetweenEndpointsofTwoLines: '两条线连接点的最小距离',
  layer1ofLine1: '连接线-层',
  layer1ofLine2: '连接线-层',
  turnRadiusofSpout: '壶嘴拐角半径',
  theInnerWidthofSpout: '壶嘴内宽',
  theOutsideWidthofSpout: '壶嘴外宽',
  innerRadiusofSpout: '壶内径',
  outsideRadiusofSpout: '壶外径',
  xaxisoffsetofSurroundingPad: '四周pad的X轴偏移量',
  yaxisoffsetofSurroundingPad: '四周pad的y轴偏移量',
  radiusofInPillar: 'InPillar半径',
  layerofInPillar: 'InPillar层',
  materialofInPillar: 'InPillar材料',
  radiusofTiNPad: 'TiNPad半径',
  layerofTiNPad: 'TiNPad层',
  materialofTiNPad: 'TiNPad材料',
  placetheAirBridge: '放AirBridge',
  selectLayer: '选择需要放置的层',
  airBridgeNeedAvoidOther: '空气桥需要避开的其他层',
  minDistanceAirBridgeToOtherEdge: '空气桥中心与避开层器件边缘的最小间距',
  airBridgeStep: '空气桥步距',
  airBridgeFineTuningStep: '空气桥微调步距',
  theOffsetFromEndtoEnd: '距离首尾偏移量',
  minDistanceBetweenCenterofTwoAirBridge: '空气桥与空气桥中心之间最小距离',
  minDistanceBetweenCenterofAirBridgeAndInflectionPointofLine: '空气桥中心与线拐点最小距离',
  minDistanceBetweenCenterofAirBridgeAndCenterofInLinePad: '空气桥中心与InLinePad中心最小距离',
  placetheTiNPad: '放TiNPad',
  placementAreaofTiNPad: 'TiNPad放置区域',
  miningAreaofTiNPad: 'TiNPad挖取区域',
  placementStepDistanceofTiNPad: 'TiNPad放置步距',
  minDistanceFromTiNPadCentertoTiNPadCenterD1: 'TiNPad中心到TiNPad中心最小距离d1',
  minDistanceFromTiNPadEdgetoOtherComponentEdgesD2: 'TiNPad边缘到其他元器件边缘最小距离d2',
  minDistanceFromTiNPadEdgetoCenterlineoftheCPWD3: 'TiNPad边缘到骨架线中心最小距离d3',
  add: '添加',
  delete: '删除',
  shape: '形状',
  radius: '半径',
  max_points: '最大点数',
  material: '材料',
  square: '方形',
  generateCPWs: '生成共面波导 ',
  outerWidthW1: '外宽',
  innerWidthW2: '内宽',
  turnRadiusR: '拐角半径',
  GUI: '画板',
  gridDisplay: '网格显示',
  gridAccuracy: '网格精度',
  gridSnap: '网格吸附',
  anyAngle: '任意角度',
  obliqueAdsorption: '斜角吸附',
  angleLock: '角度锁定',
  anyStretch: '任意拉伸',
  coordinate: '坐标',
  coordinateZero: '坐标原点',
  zoom: '缩放',
  record: '日志',
  time: '时间',
  content: '内容',
  connectSuccessful: '连接成功',
  saveConfigurationSuccessful: '保存配置成功',
  notFindFile: '文件不存在',
  noData: '暂无数据',
  exportPath: 'GDS导出路径',
  checkLineFinish: '检测线完成~',
  completePlacementWBPad: '放置WBPad完成~',
  completePlacementInLinePad: '放置InLinePad完成~',
  completePlacementAirBridge: '放置AirBridge完成~',
  completePlacementTiNPad: '放置TiNPad完成~',
  completePlacementCPWs: '生成共面波导完成~',
  fileExceedsLimit: '文件超出限制，最大:1024KB~',
  successfulExport: '导出GDS成功~',
  pleaseSelect: '请选择',
  InpillarNumber: 'Inpillar数量',
  Inpillarshape: 'Inpillar形状',
  totalInpillarArea: 'Inpillar总面积',
  InpillarPlacementArea: '放置区域面积',
  excavationArea: '挖取区域面积',
  ROIArea: 'ROI区域面积',
  totalInpillarAreaROIAreaProportion: '占比(Inpillar总面积 / ROI区域面积)',
  sureToSave: '确认保存',

  //智能谐振腔
  quickResonators: '智能谐振腔',
  resonators: '谐振腔',
  operation: '操作',
  creatPolygon: '绘制多边形',
  placeStartPoint: '放置起点',
  placeEndPoint: '放置终点',
  parameterModification: '参数修改',
  setConditions: '设置条件',
  start: '起点',
  end: '终点',
  length: '长度',
  minimumRadius: '最小半径',
  maximumRadius: '最大半径',
  layerNumber: '层',
  creat: '自动生成',
  save: '保存',
  result: '结果',
  turnNumber: '拐角数',
  startGeneratingResonators: '开始生成谐振腔',
  startPointFarFromPolygon: '起点离多边形过远',
  tailPointFarFromPolygon: '尾点离多边形过远',
  lengthShort: '长度过短',
  resonatorsNotGenerated: '谐振腔未生成~',
  resonatorsSuccessfullyGenerated: '生成谐振腔成功',
  saveSuccess: '保存成功',

  //设置
  userCenter: '个人中心',
  quit: '退出登录',
  personalCenter: '个人空间',
  teamInfo: '团队空间',
  userInfo: '用户信息',
  reallyQuit: '确定要退出登录吗?',
  switchLang: '切换到英文',
  sureToSwitchLang: '确定切换语言吗?',
  cancelLanguageSwitch: 'd取消语言切换',
  project: '项目',
  ComponentLibrary: '器件库',
  member: '成员',
  search: '搜索',
  toShare: '分享',
  receiveShare: '接收分享',
  toTeam: '导出到团队',
  ofTeam: '导入到团队',
  inviteMember: '添加协作者',
  batchPower: '批量权限管理',
  batchtoPersonal: '批量导出到个人',
  batchCopyTo: '批量复制到',
  batchShare: '批量分享',
  batchDelete: '批量删除',
  batchExportGDS: '批量导出GDS',
  pleaseInputName: '请输入项目名称',
  myTeam: '我的团队',
  createTeam: '创建团队',
  joinTeam: '加入团队',
  updateInfo: '修改信息',
  addPerson: '添加成员',
  cessionTeam: '转让团队',
  dismissTeam: '解散团队',
  quitTeam: '退出团队',
  BoardDesign: '画板设计',
  CoordinateDisplay: '坐标显示',
  Zoomout: '画板放大',
  Zoomin: '画板缩小',
  ZoomFit: '画板适应',
  ObjectFitSelection: '对象适应',
  ZoomFitSelection: '区域放大',
  displayName: '显示昵称',
  password: '密码',
  originPassword: '原密码',
  editPassword: '修改密码',
  userName: '用户名',
  registTime: '注册时间',
  update: '修改',
  searchForKeyword: '关键字搜索',
  placeholder_0: '请选择内容',
  placeholder_1: '请输入内容',
  placeholder_2: '请输入团队名称',
  placeholder_3: '请输入项目名称',
  placeholder_4: '请输入成员名称',
  placeholder_5: '请输入引脚信息',
  edit_displayName: '编辑显示昵称',
  sMember: '的成员',
  createTime: '创建时间',
  updateTime: '更新时间',
  editAndDelete: '编辑',
  readOnly: '只读',
  invisible: '不可见',
  inherit: '继承上级',
  teamRole: '团队角色',
  projectPermission: '项目权限',
  collaborator: '协作者',
  manager: '管理者',
  owner: '所有者',
  canUsed: '可使用',
  canEdit: '可编辑',
  batchTeamRoleManage: '批量团队角色管理',
  batchProjectManage: '批量文件权限管理',
  batchDeviceManage: '批量库权限管理',
  devicePower: '器件库权限',
  libraryPower: '库权限',
  batchDeletion: '批量移除',
  uploadStatus: '上传情况',
  notUpload: '未上传',
  alreadyUpload: '已上传',
  uploadTime: '上传时间',
  remark: '备注',
  teamName: '团队名称',
  teamUrl: '邀请码',
  shareUrl: '分享码',
  cessionMember: '转让成员',
  personalList: '个人项目目录',
  power: '权限',
  Warning: '警告',
  editMemberInfo: '成员信息编辑',
  copyTo: '复制到',
  belong: '归属',
  uploadTo: '上传到公用器件库',
  uploadSymbolTo: '上传到公用符号库',
  optional: '（选填）',
  powerTips_1: '拥有项目、成员管理权限， 器件库可删除、可使用权限',
  powerTips_2: '拥有所有者/管理员分配后的权限',
  powerTips_3: '添加进项目后，权限为“只读”',
  powerTips_4: '拥有所有者/管理员分配后的权限',
  powerTips_5: '填入邀请码进入团队身份为“协作者”',
  powerTips_6: '邀请码有效期为24小时',
  powerTips_7: '转移团队后，您将被降级为团队管理者。该操作无法撤销，请谨慎操作。',
  powerTips_8: '解散团队前必须移除所有项目和器件，否则无法解散团队。该操作无法撤销，请谨慎操作。',
  powerTips_9_1: '确认在该项目把成员批量设置为',
  powerTips_9_2: '是否继续',
  powerTips_10_1: '所选成员将获得',
  powerTips_10_2: '权限',
  powerTips_11: '分享码有效期为7天',
  powerTips_12: '删除选中项目，该项目的内容全都删除，并且不能恢复',
  powerTips_13: '我已知晓',
  powerTips_14: '确认是否批量移除该成员，移除后的成员将失去该项目权限，是否继续',
  powerTips_15: '该操作将退出此团队,是否继续',
  powerTips_16: '确认从该项目移除选中成员，是否继续',
  powerTips_17: '确认从该项目移除选中器件，是否继续',
  powerTips_18: '该成员成为协作者后，将失去添加、移除成员的权限，是否继续？',
  powerTips_19: '该成员成为管理员后，将获得添加、移除成员的权限，还会获得所有项目的管理权限和器件库的可删除、可使用权限，是否继续？',
  powerTips_20: '请勿使用包含特殊符号或含有明显营销推广意图的显示昵称',
  powerTips_21: '6位数字验证码',
  powerTips_22: '删除以下文档，该文档的内容全都删除，并且不能恢复',
  powerTips_23: '已经存在相同名字的元器件,是否覆盖?',
  powerTips_24: '选择删除后, 版图文件下该图层的内容将全部删除, 并且不再恢复',
  powerTips_25: '确认从该项目移除选中符号，是否继续',
  powerTips_26: '已经存在相同名字的符号,是否覆盖?',
  powerTips_11_2: '邀请码有效期为1天',
  createNewLibrary: '新建库',
  libraryName: '名称',
  powerTips_27: '所选角色的权限已经为',
  powerTips_28: '的个人项目目录',
  powerTips_29: '器件库被删除后其包含的器件也将全部删除，是否继续？',
  selectLibrary: '请先选择库',
  updataLibraryName: '修改名称',
  batchAddComponent: '批量添加器件',
  batchAddSymbol: '批量添加符号',
  placeholder_seach_component: '请输入器件名称',
  placeholder_seach_symbol: '请输入符号名称',
  fixedDevices: '固定器件',
  parameterizedDevice: '参数化器件',
  batchAddComponentTips: '是否覆盖同名器件？',
  exportLibraryFile: '导出库文件',
  exportLibrary: '导出库',
  createNewComponentLibrary: '新建器件库',
  createNewSymbolLibrary: '新建符号库',
  deleteComponentLibrary: '删除器件库',
  deleteSymbolLibrary: '删除符号库',

  // 器件库
  paramsComponentLibrary: '参数化器件库',
  OfficialParametrizedLibrary: '官方参数化器件库',
  PDKParametrizedLibrary: 'PDK参数化器件库',
  Component: '器件',
  ComponentName: '器件名称',
  ComponentType: '器件类型',
  Details: '详情',
  NoteFigure: '标注图',
  img_2D: '2D图',
  Creator: '创建者',
  MyLibrary: '个人器件库',
  MineLibrary: '我的器件库',
  Library: '器件库',
  TeamLibrary: '团队器件库',
  PublicLibrary: '公用器件库',
  OfficialLibrary: '官方器件库',
  PDKLibrary: 'PDK器件库',
  note: '注释',
  ComponentDetails: '固定器件详情',
  paramsComponentDetails: '参数化器件详情',
  set: '放置',
  attrs: '属性',
  params: '参数',
  totalLength: '总长',

  symbol: '符号',
  symbolLibrary: '符号库',
  MySymbol: '我的符号库',
  SymbolLibrary: '符号库',
  TeamSymbol: '团队符号库',
  PublicSymbol: '公用符号库',
  OfficialSymbol: '官方符号库',
  PDKSymbol: 'PDK符号库',
  cpLine: '耦合线',
  pin: '引脚',
  symbolIns: '符号实例',
  insName: '实例名称',
  portCount: '端口数量',
  port: '端口',
  noParamsData: '暂无参数数据',
  insNameError: '实例名不允许重复',
  SymbolName: '符号名称',
  elcParams: '静电参数',
  controlParams: '测控参数',

  // 检查
  checkRule: '检查',
  startRuleCheck: '开始设计规则检查',
  EndRuleCheck: '结束设计规则检查',
  ErrorTitle: '[错误]',
  WarningTitle: '[警告]',
  netError: '网络未连接任何端口：',
  pinError: '引脚未连接：',
  pinWarning: '连接到网络上的引脚，网络和引脚名 称不一致：',
  symbolPinError: '符号的端口未连接：',
  symbolPortTypeError: '符号端口连接类型错误：',

  //左边栏
  select: '选择',
  line: '直线',
  dogleg: '折线',
  circle: '圆',
  rectangle: '矩形',
  polygon: '多边形',
  flip: '翻转',

  //器件列表
  component: '器件',
  fileList: '文件列表',
  componentList: '器件列表',
  projectList: '项目列表',
  superconductivity: '超导',
  semiconductor: '半导',
  universalComponentLibrary: '通用器件库',
  customComponentLibrary: '自定义器件库',

  // 属性列表
  propertyList: '属性列表',
  layerPropertyList: '图层属性',
  commonPropertyList: '通用属性',
  layer: '图层',
  color: '颜色',
  hide: '隐藏',
  number: '编号',
  createNewLayer: '新建图层',
  backgroundColor: '背景颜色',
  gridColor: '格点颜色',
  isGridVisible: '网格可见',
  adsorption: '吸附',
  adsorptionAccuracy: '吸附精度',
  gridAdsorptionAccuracy: '格点吸附精度',
  gridSize: '网格大小',
  unit: '单位',
  zoomFactor: '缩放系数',
  mousePositionX: '鼠标位置-x',
  mousePositionY: '鼠标位置-y',
  output: '输出',
  lock: '锁定',
  NO: '编号',
  fillColor: '填充颜色',
  borderColor: '边框颜色',
  fillShape: '填充形状',
  borderShape: '边框形状',
  allLayers: '版图文件图层',
  cellLayers: '单元图层',
  deleteLayer: '删除图层',
  deleteLayerWarning: '选择删除后，版图文件下该图层的内容全部删除，并且不再恢复',
  mergeLayer: '合并图层',
  mergedLayer: '合并后的图层',
  selectMergedLayer: '请选择合并后的图层',
  addLayer: '添加图层',
  listInformation: '列表信息',
  tolerance: '弧度精度',
  editObject: '对象编辑',
  modifiedLayerInfo: '修改图层信息成功',
  enterCorrectLayerNumber: '请输入正确的图层编号',
  LayerNameCannotEmpty: '图层名称不能为空，且不能大于10个字符',
  enterLayerNameFormat: '请输入正确格式的图层名',
  sureToClose: '确认关闭?',
  layerNumberLimit1: '图层数量超过限制!',
  layerNumberLimit2: '图层最多只能有256个!',
  numberLimit1: '请输入三位以内小数或者五位以内整数',
  numberLimit2: '请输入五位以内整数',

  // 自定义图形属性
  customGraphicsProperty: '自定义图形属性',
  noneGraph: '还未选择任何自定义图形',
  name: '名称',
  layerNumber: '图层编号',
  coordinateList: '坐标列表',
  xCoordinate: 'x坐标',
  yCoordinate: 'y坐标',
  lowerLeftCornerCoordinates: '左下角坐标',
  width: '宽',
  height: '高',
  perimeter: '周长信息',
  centerCoordinate: '圆心坐标',
  radiusInformation: '半径信息',
  confirmChanges: '确认修改',

  // 器件属性
  componentProperty: '器件属性',
  noneComponent: '还未选择任何器件',
  annotationMap: '标注图',
  basicProperties: '基本属性',
  OProperties: 'O点属性',
  customProperties: '自定义属性',
  topLeft: '左上',
  bottomLeft: '左下',
  topRight: '右上',
  bottomRight: '右下',
  tipSelected: '提示:与GapType属性选中值一一对应',
  RW1W2: 'R、W1、W2 必须是整数,并且R大于或等于W1+W2!',
  nLimit: 'n 必须是 2k + 1 (k>=2)的整数值!',
  GapTypeCannotEmpty: 'GapType 不能为空!',
  quantumDotsNumber: '量子点数目',

  //登录页面
  register: '立即注册',
  phoneEmail: '手机号/邮箱地址',
  enterPassword: '请输入密码',
  forgotPassword: '忘记密码',
  login: '登录',
  passwordIsIncorrect: '用户名或密码错误',
  userNamelength: '用户名3-20位',
  passwordlength: '密码6-20位',
  userNameEmpty: '用户名不能为空',
  passwordEmpty: '密码不能为空',
  loginSuccessful: '登录成功',
  haveAccount: '',
  enterPhoneNumber: '请输入手机号',
  enterVerificationCode: '请输入验证码',
  getVerificationCode: '获取验证码',
  VerificationCode: '验证码',
  enterPassword: '请输入密码',
  enterPassword2: '请再次输入密码',
  enterValidPhoneNumber: '请输入正确的手机号',
  verificationCodeSent: '验证码发送成功',
  phoneNumberRegistered: '该手机号已注册',
  enterTrueVerificationCode: '请输入正确的验证码',
  verificationCodeEmpty: '验证码不能为空',
  passwordEmpty2: '确认密码不能为空',
  twoPasswordsInconsistent: '两次输入的密码不一致',
  registerSuccessful: '注册成功',
  returnLogin: '返回登录',
  reset: '重置',
  enterRequiredItem: '请输入必填项',
  accountDoesNotExist: '账号不存在',
  changeSuccessfulLoginAgain: '修改成功请重新登录',
  passwordsDoesNotMatch: '密码不一致请重新输入',
  phoneNumberlength: '请输入11位手机号码',
  newPassword: '新密码',
  passwordAgain: '再次输入新密码',
  passwordRule: '密码满足以下要求',
  pass_tips_1: '6-16字符',
  pass_tips_2: '由字母或数字组成，不能包含空格',
  deleteLayer: '删除图层',
  clearLayer: '清空图层',

  // 属性面板
  selectObjList: '选中对象列表',
  graph: '图形',
  Staff: '标尺',
  centerPoint: '中心点坐标',
  Size: '大小',
  aText: '文本',
  lineWidth: '线宽',
  CenterLineWidth: '中心线宽',
  TurningRadius: '转弯半径',
  orderNumber: '序号',
  pointLength: '两点距离',
  all: '全部',
  radiusX: 'X轴半径',
  radiusY: 'Y轴半径',
  wide: '宽',
  high: '高',
  sameParams: '共有参数',
  originPoint: '原点坐标',
  keypointName: '关键点名称',
  points: '点坐标',
  ReferencePoint: '参考点坐标',
  ReferenceLocation: '参考点位置',
  networkLabel: '网络标签',
  Font: '字体',
  distance: '距离',
  apply: '应用',
  complete: '完成',
  maxPoints: '边的个数',
  pointsCount: '点的个数',
  rowsAndColumns: '行/列',
  rowVector: '行向量(x, y)',
  columnVector: '列向量(x, y)',
  radiusAndWHError: '该输入框的值不能为0，最小值为0.001',
  even_number: '偶数',

  //2.1原理图
  inputPinNameErr_1: '输入的引脚名称不能重复',
  symbolSubstitution: '符号替换',
  replaceClosedLoop: '替换存在闭环',
  callClosedLoop: '调用存在闭环',
  successSaveToSymbolLib: '存到符号库成功',
  CheckSet1: '网络是否连接到两个端口',
  CheckSet2: '连接到网络上的引脚，网络和引脚名称不一致',
  CheckSet3: '引脚不能悬空',
  CheckSet4: '符号的端口未连接',
  CheckSet5: '符号端口连接类型错误',
  CheckSet6: '总线端口未连接Cp1',
  CheckSet7: 'Cp1端口未连接总线和谐振腔',
  CheckSet8: '谐振腔端口未连接Cp1和Cp2',
  CheckSet9: 'Cp2端口未连接谐振腔和比特',
  CheckSet10: '比特端口（三端口）未连接Cp2、Cp3和Cz1',
  CheckSet11: '比特端口（四端口）未连接Cp2、Cp3、Cz1和Cb1',
  CheckSet12: 'Cp3端口未连接比特和X线',
  CheckSet13: 'Cb1端口未连接两个比特',
  CheckSet14: 'X线端口未连接Cp3',
  CheckSet15: 'Cz1端口未连接比特和z线',
  CheckSet16: 'Z线端口未连接Cz1线',
  encapsulateSuccess: '封装成功',

  //PDK
  PDKClassification: 'PDK分类',
  officialPDK: '官方PDK',
  personalPDK: '个人PDK',
  selectFile: '选择文件',

  // code码
  noDevicePower_1: '没有',
  noDevicePower_2: '器件库的使用权限',
  Success: '成功',
  copySuccess: '复制成功',
  deleteSuccess: '删除成功',
  shareCodeCopySuccess: '已复制分享码至剪切板',
  uploadSuccess: '上传成功',
  updateSuccess: '更新成功',
  operationSuccess: '操作成功',
  operationFailed: '操作失败',
  getCode: '获取验证码',
  error_1: '请先选择器件',
  error_2: '请先选择成员',
  error_3: '没有选择成员或没有选择项目',
  error_4: '请先选择项目',
  error_5: '你还不是该项目的成员',
  error_6: '至少存在一个标签',
  error_7: '请完成上一次添加的标签填写',
  error_8: '标签最多允许存在15个',
  error_9: '请先选择文件',
  code_400001: '工程id不能为空',
  code_400002: '请求参数不是JSON',
  code_400003: '已经存在相同名字的文件',
  code_400004: '该项目下已经存在相同名字的文件',
  code_400005: '找不到元器件',
  code_400006: '已经存在相同名字的元器件',
  code_400010: '账号未激活',
  code_400011: '两次密码输入不一致',
  code_400012: '该邮箱已经注册过用户',
  code_400013: '该用户名已经存在，请更换',
  code_400014: '密码错误',
  code_400015: '新密码与旧密码相同',
  code_400016: '已经存在相同的项目名字',
  code_400017: '该文件下已经存在相同名字的Cell',
  code_400018: '用户名或密码错误',
  code_400019: 'gds文件内容错误',
  code_400020: '名字已经存在,请重新命名该元器件',
  code_400021: '文件格式错误',
  code_400022: '用户最多创建项目个数超标',
  code_400023: '文件大小超过500kb',
  code_400024: '用户在同一个项目下最多创建文件个数超标',
  code_400025: '一个文件下最多创建cell个数超标',
  code_400026: '保存文件失败，保存文件最大2.5mb',
  code_400027: '文件中所有的Cell内容为空',
  code_400028: '参数校验错误',
  code_400029: "创建失败,请检查参数，文件名，cell名是否合法:只能是字母、数字 . \\ + - '",
  code_400030: "文件名不合法，文件名开头不能为空格，只能是字母 数字 + - _ & () []（）~'",
  code_400031: "CELL名不合法，CELL名开头不能为空格，只能是字母 数字 + - _ & () []（）~'",
  code_400032: "项目名不合法，项目名开头不能为空格，只能是中文 字母 数字 + - _ & () []（）~'",
  code_400033: 'es插入数据错误',
  code_400034: 'gns参数错误',
  code_400035: 'category参数错误',
  code_400036: 'category_child参数错误',
  code_400037: '必须在指定项目路径下搜索文件',
  code_400038: '请输入团队名',
  code_400307: '删除失败',
  code_400099: '请输入团队名，开头不能为空格',
  code_400039: '团队名已经存在',
  code_400040: '邀请码错误',
  code_400041: '邀请码过期',
  code_400042: '成员已经在团队中',
  code_400043: '团队成员到达上限',
  code_400044: '团队下已有相同名字的项目',
  code_400045: 'cell名字重复',
  code_400046: '用户不在团队中',
  code_400047: '添加协作者不能为空',
  code_400048: '添加协作者列表中部分用户角色不正确',
  code_400049: '管理员只能编辑协助者权限，不能越级或者平级编辑',
  code_400050: '用户不能使用自己创建的邀请码',
  code_400051: '团队名前后一样，请输入想要更改的名字',
  code_400052: '团队名字不能超过10个字符',
  code_400053: '网络波动，请选择少量数据重试',
  code_400055: '分享码过期',
  code_400056: '团队创建超过最大限额',
  code_400057: '团队下创建项目超过最大限额',
  code_400058: '项目下文件数量超过最大限额',
  code_400059: '修改成员角色失败',
  code_400060: '当前选中的用户部分不在该团队中',
  code_400061: '当前选中的项目部分不在该团队中',
  code_400062: '当前选中的用户部分不在该项目中',
  code_400305: '分享失败',
  code_400307: '名称不合法，名称开头不能为空格，只能是中文 字母 数字 + - _ & () []（）~',
  code_400309: '库的创建个数超过最大限制',
  code_400310: '存在相同名称的库',
  code_400315: '存在相同的名称',
  code_400318: '文件名称长度为3-30个字符',
  code_400319: '文件数量已达到上限',
  code_400329: '不能操作当前库的器件',
  code_400330: '添加器件失败',
  code_400331: '不能操作当前库的符号',
  code_400332: '导出库文件失败',
  code_400333: '导入pcell异常',
  code_400334: '导入drc异常',
  code_400335: '名称不合法，名称开头不能为空格，只能是字母 数字 + - _ & () []（）. ~',
  code_400336: 'drc操作异常',
  code_400337: '不能操作当前团队的器件',
  code_400338: '不能操作当前团队的符号',
  code_400339: '添加符号失败',
  code_400340: '文件名存在相同名称',

  code_400907: '不能修改文件',
  code_400908: '上传文件名称不一致',
  code_400909: '上传文件数量不一致',
  code_400910: '数据格式错误',
  code_400913: '你没有当前的操作权限',
  code_400914: '只能操作当前文件下的数据',
  code_400915: '加载pdk失败',
  
  code_401000: '未登录或未提供身份认证信息',
  code_401001: '未登录或者cookie错误',
  code_401002: '登录失效',
  code_404001: '项目不存在,请检查gns是否输入正确',
  code_404002: '用户不存在',
  code_404003: 'Cell不存在,请检查gns是否输入正确',
  code_404004: '文件不存在,请检查gns是否输入正确',
  code_404005: '文件不存在,请检查snow_id是否输入正确',
  code_404006: '团队不存在，请检查snow_team_id是否填入正确',
  code_404006: '团队不存在，请检查gns是否填入正确',
  code_404007: '用户不在团队中',
  code_404008: '项目不存在',
  code_404009: '团队中不存在这个项目',
  code_404010: '自己不允许删除自己',

  code_404903: '项目不存在',
  code_404904: '文件不存在',
  code_404905: '该文件下没有单元数据',
  code_404906: '没有找到官方PDK信息',

  code_405001: '当前用户没有该团队查看权限',
  code_405002: '您没有添加成员权限',
  code_405003: '当前用户的角色不被允许转让团队',
  code_405004: '自己不允许转让给自己',
  code_405005: '所有者不允许退出',
  code_405006: '协作者不允许添加或者编辑协作者',
  code_405028: '项目不允许自动继承',
  code_405031: '当前选中部分文件不允许被删除',
  code_405301: '当前用户没有该团队器件库编辑权限',
  code_405302: '当前用户没有该团队器件库使用权限',
  code_405303: '文件大小超出限制',
  code_405304: '文件下载失败',
  code_405305: '文件删除失败',
  code_405306: '只有管理员或所有者才能进行此操作',
  code_405307: '获取文件失败',
  code_405308: '获取器件内容失败',
  code_405309: '图层编号超过最大限制',
  code_405310: '图层数量超过最大限制',
  code_405311: '图层编号存在重复',
  code_405312: '图层信息不存在',
  code_405313: '保存器件失败，器件大小超过限制',
  code_405314: '保存器件失败，器件个数超过最大限制',
  code_405315: '保存器件失败，器件个数超过最大限制',
  code_405316: '导入失败，文件大小超出最大限制',
  code_405317: '上传失败，已上传个数超出最大限制',
  code_405318: '上传失败，已上传个数超出最大限制',
  code_405319: '保存失败，文件大小超出最大限制',
  code_405320: '器件名不合法，器件名开头不能为空格，只能是字母 数字 + - _ & () []（）~',
  code_405321: '器件名不能超过50个字符',
  code_405322: '操作官方器件口令错误',
  code_405323: '删除器件错误',
  code_405324: 'GDS文件读取失败',
  code_405325: 'GDS解析cell失败',
  code_405336: '已经存在相同名字的符号',
  code_405007: '协作者不允许复制团队项目到个人',
  code_405008: '个人不允许分享别人的项目',
  code_405009: '协作者和管理者不允许修改团队名字',
  code_405010: '协作者不允许删除团队中的项目',
  code_405011: '获取项目权限数量不能超过20个',
  code_405012: '当前用户没有删除权限',
  code_405013: '只有所有者可以批量管理',
  code_405014: '团队下有项目，不允许解散团队',
  code_405015: '团队下有器件，不允许解散团队',
  code_405016: '管理员的器件库权限只能是可编辑',
  code_405017: '协作者没有操作某个项目的权限',
  code_405018: '当前用户没有源项目的可删除，编辑权限',
  code_405019: '当前用户没有目标项目的可删除，编辑权限',
  code_405020: '当前用户复制的选择的项目没有权限',
  code_405021: '协作者不允许添加成员',
  code_405022: '文件不允许复制到同一个项目下',
  code_405023: '管理员不允许修改管理员',
  code_405024: '协作者不允许操作',
  code_405025: '其他用户正在使用',
  code_405026: '不允许重命名',
  code_405027: '没有权限',
  code_405032: '当前用户没有操作图层的权限',
  code_405328: '只有管理员或所有者才能进行此操作',
  code_500001: '服务器未知异常',
  code_500002: '导入GDS异常',
  code_500003: '三方用户信息接口获取失败',
  code_500004: 'redis错误',
  code_500005: 'ES删除错误',
  code_500006: '上传对象存储失败',
  code_500007: '创建默认图层失败',

  code_500912: '三方用户信息接口获取失败',
  code_500915: '更新消息es状态失败',
  code_500918: '权限校验异常',
  //画板提示
  layoutSplitSuccess: '版图拆分成功',
  splitLayoutFailed: '拆分版图失败',
  cwGeneratedSuccess: '共面波导生成成功',
  cwdGeneratedFaile: '共面波导生成失败',
  wbPadPlacedSuccess: 'WBPad放置成功',
  wbPadPlacedFaile: 'WBPad放置失败',
  inLinePadPlacedSuccess: 'InLinePad放置成功',
  inLinePadPlacedFaile: 'InLinePad放置失败',
  airBridgePlacedSuccess: '空气桥放置成功',
  airBridgePlacedFaile: '空气桥放置失败',
  tpPlacedSuccess: 'TiNPad&InPillar放置成功',
  tpPlacedFaile: 'TiNPad&InPillar放置失败',
  squidZPlacedSuccess: 'SQUID-Z线放置成功',
  squidZPlacedFailed: 'SQUID-Z线放置失败',
  testLineSuccess: '检测线成功',
  theConnectionLineSuccess: '连接线成功',
  theConnectionLineFailed: '连接线失败',
  plsSelectSquidEdge: '请选择SQUID放置的边',
  plsSelect1P2K: '请选择一个多边形和两个关键点',
  deviceCircularReference: '器件循环引用',
  // 工具弹框
  toolBigLayout: '大版图工具',
  toolSizeImage: '标注图',
  tool2dImage: '2D图',
  toolNumber: '编号',
  toolCount: '数量',
  toolLayer: '图层',
  toolDistance: '间隔',
  toolAvoid: '避开',
  toolAll: '全部',
  toolSelectAll: '全选',
  toolSelected: '反选',
  toolSize: '尺寸',
  toolRules: '规则',
  toolText: '线标',
  toolTextSize: '字体大小',
  toolNoRules: '无规则',
  toolCheck: '检测',
  toolShape: '形状',
  toolSquare: '方形',
  toolCircle: '圆形',
  toolPrecision: '精度',
  toolCoord: '点坐标',
  toolStartPos: '起点',
  toolEndPos: '终点',
  toolCenterPos: '中心',
  toolLength: '长度',
  toolRadius: '半径',
  toolAdd: '添加',
  toolOperate: '操作',
  toolConnect: '连接',
  toolArea: '区域',
  toolKeyWord: '关键字',
  toolPleaseSelect: '请选择',
  toolAirbridgeTitle: '空气桥',
  toolAirbridge2LineMinDistance: '空气桥中心点与骨架线端点的最小距离',
  toolAirbridge2AirbridgeMinDistance: '空气桥中心点之间的最小距离',
  toolAirbridgeStep: '空气桥放置步长',
  toolAirbridgeLineLayer: '空气桥放置骨架线所在图层',
  toolAirbridgeAvoidInflect: '避开骨架线拐点',
  toolAirbridgeInflectCircle: '骨架线拐点圆弧放置(注意配合共面波导)',
  toolAirbridgeInflectCircleRadius: '骨架线拐点圆弧放置半径',
  toolAirbridge2InflectMinDistance: '骨架线拐点与空气桥中心最小距离',
  toolAirbridgePlaceWarn: '警告，空气桥放置中，请等待放置完成',
  toolAirbridgeLayerPier: '图层-桥墩',
  toolAirbridgeLayerDeck: '图层-桥面',
  toolWBPadBounds: '版图边框',
  toolWBPad2Bounds: '版图边框到Pad边缘的间距',
  toolWBPadMinDistance: '骨架线边框到骨架线端点的最小距离，满足该距离的所有骨架线端点会放置一个WBPad',
  toolWBPadTextX: '文本左下角到O点横坐标距离',
  toolWBPadTextY: '文本左下角到O点纵坐标距离',
  toolWBPadText: '文本',
  toolWBPadTextSize: '字体大小',
  toolWBPadTextLayer: '图层',
  toolWBPadTextNumber: 'WBPad个数',
  toolInLinePadDistance: '两条不同图层骨架线端点之间的最大距离，满足该条件，在此处放置InLinePad',
  toolInLinePadLayer1: '连接图层1',
  toolInLinePadLayer2: '连接图层2',
  toolInLinePadIndiumLayer: 'Indium图层',
  toolInLinePadIndiumFlipLayer: 'Indium图层(flip)',
  toolInLinePadTinLayer: 'TiN图层',
  toolInLinePadTinFlipLayer: 'TiN图层(flip)',
  toolSquidLine: 'SQUID和Z线的自动布局',
  toolSquidLineLen: '基线长度',
  toolSquid2StartDistance: '到起点的距离',
  toolSquidDirect: 'SQUID朝向',
  toolSquidDirectLeft: '左侧',
  toolSquidDirectRight: '右侧',
  toolSquidType: 'SQUID种类',
  toolSquid2ZlineDistance: 'SQUID和Z线的距离',
  toolSplitGdsTitle: '按图层拆分版图',
  toolSplitGdsAdd: '添加版图',
  toolSplitGdsLayout: '拆分的版图',
  toolConnectLine: '连接线',
  toolConnectLineMinDistance: '两条线最小距离',
  toolCheckLine: '检测线',
  toolCheckLineCheck: '检测',
  toolCheckLineMinDistance: '两条线最小距离',
  toolCavity: '谐振腔',
  toolCavityChangeStartEnd: '起点终点交换',
  toolCavityAutoGen: '自动生成',
  toolCavityMinRadius: '最小半径',
  toolCavityMaxRadius: '最大半径',
  toolCavityOuterWidth: '外宽',
  toolCavityInnerWidth: '内宽',
  toolCavityResult: '生成结果',
  toolCavityInflectNumber: '拐角数',
  toolCavityThreePoint: '多边形最少需要3个点',
  toolCavityStartPosTooFar: '起点离多边形过远, 最小范围: 10um',
  toolCavityEndPosTooFar: '尾点离多边形过远, 最小范围: 10um',
  toolCavityEndPosErr: '尾点检测错误',
  toolCavityLengthTooShort: '长度太短',
  toolCavityMaxRadiusError: '最大半径输入错误',
  toolCavityMinRadiusError: '最小半径输入错误',
  toolCavityGenerateSuccess: '谐振腔生成成功',
  toolCavityLengthRange: '长度范围',
  toolCavityPleaseCheckArgs: '请检测输入参数是否满足规则',
  toolTinpadInpillarPlaceArea: 'TiNPad放置区域',
  toolTinpadInpillarDigArea: 'TiNPad挖取区域',
  toolTinpadInpillarStep: 'InPillar放置步长',
  toolTinpadInpillarCenterMinDistance: 'TiNPad中心点之间的最小距离',
  toolTinpadInpillarLayer: '图层',
  toolTinpadInpillarFlipLayer: '图层(flip)',
  toolCheckLineMinDistance: '两条线最小距离',
  toolWaveGuide: '共面波导',
  toolWaveGuideSelect: '选择',
  toolWaveGuideGen: '生成',
  toolWaveGuidePleaseSelect: '请选择',
  toolWaveGuideLineLayer: '骨架线的图层',
  toolWaveGuideNewLayer: '生成共面波导的图层',
  toolLogWBPadCount: 'WBPad数量',
  toolLogAirBridgeCount: 'AirBridge数量',
  toolLogInlinepadCount: 'InLinePad数量',
  toolLogInpillarCount: 'InPillar数量',
  toolLogInpillarRadius: 'InPillar半径',
  toolLogInpillarShape: 'InPillar形状',
  toolLogInpillarAreaTotal: 'InPillar总面积',
  toolLogROIAreaTotal: 'ROI总面积',
  toolLogInpillarProp: '占比(Inpillar总面积/ ROI总面积)',
  toolTipSplitSuccess: '版图拆分成功',
  toolTipSplitFailed: '拆分版图失败',
  toolTipWaveGuideSuccess: '共面波导生成成功',
  toolTipWaveGuideFailed: '共面波导生成失败',
  toolTipWBPadSuccess: 'WBPad放置成功',
  toolTipWBPadFailed: 'WBPad放置失败',
  toolTipInLinePadSuccess: 'InLinePad放置成功',
  toolTipInLinePadFailed: 'InLinePad放置失败',
  toolTipAirBridgeSuccess: '空气桥放置成功',
  toolTipAirBridgeFailed: '空气桥放置失败',
  toolTipTinpadSuccess: 'TiNPad&InPillar放置成功',
  toolTipTinpadFailed: 'TiNPad&InPillar放置失败',
  toolTipCheckLineSuccess: '检测线成功',
  toolTipCheckLineFailed: '检测线失败',
  toolTipConnectLineSuccess: '连接线成功',
  toolTipConnectLineSuccess: '连接线失败',
  toolTipSquidSelectEdge: '请选择SQUID放置的边',
  toolTipSquidSelectPolyPoint: '请选择一个多边形和两个关键点',
  toolTipLayoutSpliting: '版图拆分中，请等待',
  toolTipCheckLineWait: '检测线运行中，请等待',
  toolTipConnectLineWait: '连接线运行中，请等待',
  toolTipInlinepadPlaceWait: 'InLinePad放置中，请等待',
  toolTipTinpadPlaceWait: 'TiNPad&InPillar放置中，请等待',
  toolTipWBPadPlaceWait: 'WBPad放置中，请等待',
  toolTipWaveGuidePlaceWait: '共面波导生成中，请等待',

  // DRC
  drcDesignRuleCheck: '设计规则检查',
  drcLibraryPath: '版图路径',
  drcSelect: '选择',
  drcOk: '确定',
  drcCancel: '取消',
  drcDone: '完成',
  drcCorrect: '正确',
  drcError: '错误',
  drcExport: '导出',
  drcRuleSpecification: '规则说明',
  drcErrorCode: '错误编码',
  drcErrorCoordinate: '错误坐标',
  drcUploadError: '网络错误, 请稍后再试',
  drcSite: '当前版图位置',

  // 约瑟夫森结工具
  toolJJheader: '约瑟夫森结优化',
  toolJJtitle: '约瑟夫森结工具',
  toolJJcrossBitCap: '十字比特电容(约瑟夫森结)',
  toolJJadjustableCCap: '可调耦合电容',
  toolJJadjustableCCap_0: '可调耦合电容-0°(约瑟夫森结)',
  toolJJadjustableCCap_90: '可调耦合电容-90°(约瑟夫森结)',
  toolJJtest: '测试结(约瑟夫森结)',
  toolJJoriginCoordinates: '工艺坐标原点',
  toolJJdirection: '朝向',
  toolJJpleaseSelect: '请选择',
  toolJJlayerNumber: '识别层编号',
  toolJJget: '获取约瑟夫森结位置',
  toolJJdevice: '约瑟夫森结器件',
  toolJJtype: '约瑟夫森结类型',
  toolJJresitance: '电阻理论值/KΩ',
  toolJJresitanceMin: '电阻下限/KΩ',
  toolJJresitanceMax: '电阻上限/KΩ',
  toolJJnumber: '编号',
  toolJJposition: '位置',
  toolJJadjust: '调整',
  toolJJselectPos: '选取点',
  toolJJexport: '导出',
  toolJJerrorCell: '未识别到版图中存在芯片cell',
  toolJJTabName: '约瑟夫森结配置表',
  toolJJTabNumber: '序号',
  toolJJTabChipSplit: 'Chip分区',
  toolJJTabDesignLineWidth: '设计线宽/nm',
  toolJJTabResitanceMax: '电阻上限/KΩ',
  toolJJTabResitanceMin: '电阻下限/KΩ',
  toolJJTabType: '类型',

  toolJJTypeOptions: [
    { value: 'cross_bit', label: '十字比特电容(约瑟夫森结)' },
    { value: 'adjustable_coupling_0', label: '可调耦合电容-0°(约瑟夫森结)' },
    { value: 'adjustable_coupling_90', label: '可调耦合电容-90°(约瑟夫森结)' },
    { value: 'test_junction', label: '测试结(约瑟夫森结)' },
  ],

  //  晶圆布局
  toolWaferPlaceWarn: '晶圆布局生成中~',
  toolWaferPlaceSuccess: '晶圆布局成功~',
  toolWaferPlaceFailed: '晶圆布局失败',
  toolWaferLayout: '晶圆布局',
  toolWaferFillArea: '填充面积',
  toolWaferRadius: '晶圆半径',
  toolWaferCenterPos: '晶圆圆心位置',
  toolWaferLayer: '晶圆图层',
  toolWaferEdgeSpace: '距离晶圆边缘距离',
  toolWaferFillCell: '填充器件',
  toolWaferCellSpace: 'cell之间的间距',
  toolWaferOptionFill: '选填',
  toolWafererrorCell: '未识别到版图中存在芯片cell',

  // 自动布线
  toolAutoLine: '自动布线',
  autoLineImportGds: '导入gds',
  autoLineExportGds: '导出gds',
  autoLinePadName: 'Pad名称',
  autoLineSelectCell: '请选择Cell',
  autoLineSelectPad: '请选择Pad',
  autoLineIdentificationPad: '识别Pad',
  autoLineSelectBitPoly: '选择比特阵列多边形',
  autoLineResetBitOrigin: '重置比特阵列起点',
  autoLineBitSetOriginNumber: '比特阵列设置起点的编号',
  autoLineConnectSet: '连线设置',
  autoLineAllWiring: '全部布线',
  autoLineClearWiring: '清除布线',
  autoLineWiringRules: '布线规则',
  autoLineViewLogs: '查看日志',
  autoLineLogs: '日志',
  autoLineConnectLineLayer: '连接线图层',
  autoLineMinDistance: '连接线最小间距',
  autoLineGridSpace: '连接线格点间距',
  autoLineSelect: '选择',
  autoLineLayer: '图层',
  autoLineColor: '颜色',
  autoLineSpace: '间距',
  autoLineAvoid: '避开',
  autoLineAll: '全部',
  autoLineSelectAll: '全选',
  autoLineReverseSelect: '反选',
  autoLineOk: '确定',
  autoLineCancel: '取消',

  // pecell
  pcellGenerator: 'Pcell生成器',
  pcellList: 'Pcell列表',
  saveAll: '保存所有',
  notSave: '不保存',
  run: '运行',
  ParameterSettings: '参数设置',
  preview: '预览图',
  errorMessage: '报错信息',
  code: '代码',
  logs: '编译及报错信息',
  addParams: '添加参数',
  deletePcellTips: '是否确定删除该文件?',
  noParams: '该器件未设置参数，是否要生成参数化器件',
  noParamsError: '该器件未设置参数',
  labelExists: '标签已存在',
  pcellFileImport: '文件导入',
  uploadPcellFile: '请上传Pcell导出的json格式文件，且最多不超过5个。',
  uploadPreview: '请上传.png, .jpg, .JPG, .JPEG, .jpeg, .PNG .GIF, .gif格式文件，且最多不超过5个。',
  selectPcellFile: '选择文件',
  pcellExistsTips: '已存在以下同名文件，是否进行替换？',
  pcellExistsNameTips: '已存在同名器件，是否进行替换？',
  paramsIncomplate: '请填写必填参数',
  pcellNameError: '文件名必须以Pcell-开始',

  //pdk生成器
  drcNameError: '文件名必须以drc开始',
  uploadDrcFile: '请上传DRC生成器导出的或本地的py格式文件，且最多不超过5个。',
  stop: '终止',
  stop_progress: '检查还未完成，现在关闭会丢失进度，是否继续关闭？',
  fileNotSaved: '文件已经修改是否进行保存？',
  drcRunSuccess: '编译成功',
  startCompile: '开始编译......',
  exportDRCError: '未编译成功或未编译的DRC文件不允许导出',
  project_no_permission: '当前选择项目无权限',

  //xiala
  directionOption: [
    { label: '中心', value: 'o' },
    { label: '上', value: 'n' },
    { label: '下', value: 's' },
    { label: '左', value: 'w' },
    { label: '右', value: 'e' },
    { label: '左上', value: 'nw' },
    { label: '右上', value: 'ne' },
    { label: '左下', value: 'sw' },
    { label: '右下', value: 'se' },
  ],

  //输入输出
  ioOption: [
    { label: '输入', value: 'in' },
    { label: '输出', value: 'out' },
    { label: '输入输出', value: 'io' },
  ],

  qubit_port: {
    X: 'x',
    Z: 'z',
    Res: 'Res',
    Q1: 'Q',
    Q2: 'Q',
    Q3: 'Q',
    Q4: 'Q',
    Q5: 'Q',
    Q6: 'Q',
    Q7: 'Q',
  },

  cpw_port: {
    Res1: 'Res',
    Res2: 'Res',
    Res3: 'Res',
    Res4: 'Res',
    Res5: 'Res',
    Res6: 'Res',
    Res7: 'Res',
    Res8: 'Res',
    Res9: 'Res',
    Res10: 'Res',
  },

  all_port: {
    Q: 'Q',
    B: 'Bus',
    Z: 'z',
    Res: 'Res',
    X: 'x',
  },

  symbolConf: {
    cp1: {
      el: [{ name: '互容值', value: 5, unit: 'fF', min: 0, max: 100 }],
      ck: [
        { name: '外部耗散值', value: 6100, unit: '', min: 0, max: 100000 },
        { name: '外部耗强度', value: 1.2, unit: 'MHz', min: 0, max: 50 },
      ],
    },
    resonator_fpdesign: { el: [{ name: '腔频', value: 6.5, unit: 'GHz', min: 4, max: 9 }], ck: [{ name: '腔频', value: 6.5, unit: 'GHz', min: 2, max: 12 }] },
    cp2: { el: [{ name: '互容值', value: 5, unit: 'fF', min: 0, max: 50 }], ck: [{ name: '腔和比特耦合强度', value: 100, unit: 'MHz', min: 0, max: 1000 }] },
    qubit_single: {
      el: [{ name: '电容值', value: 85, unit: 'fF', min: 0, max: 300 }],
      ck: [
        { name: '简并点频率', value: 5, unit: 'GHz', min: 2, max: 12 },
        { name: '非谐', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
        { name: 'SQUID非对称度', value: 0, unit: '', min: 0, max: 1 },
        { name: 'Ej/Ec', value: 53.2, unit: '', min: 0, max: 1000 },
        { name: '色散位移', value: 0.28, unit: 'MHz', min: -100, max: 100 },
        { name: '腔和比特失谐', value: -2700, unit: 'MHz', min: -5000, max: 0 },
      ],
    },
    qubit_double: {
      el: [
        { name: '自电容1', value: 165, unit: 'fF', min: 0, max: 500 },
        { name: '自电容2', value: 165, unit: 'fF', min: 0, max: 500 },
        { name: '互容', value: 3, unit: 'fF', min: 0, max: 50 },
      ],
      ck: [
        { name: '简并点频率', value: 5, unit: 'GHz', min: 2, max: 12 },
        { name: '非谐', value: -222.9, unit: 'MHz', min: -1000, max: 0 },
        { name: 'SQUID非对称度', value: 0, unit: '', min: 0, max: 1 },
        { name: 'Ej/Ec', value: 53.2, unit: '', min: 0, max: 1000 },
        { name: '色散位移', value: 0.28, unit: 'MHz', min: -100, max: 100 },
        { name: '腔和比特失谐', value: -2700, unit: 'MHz', min: -5000, max: 0 },
      ],
    },
    cpw: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    x1: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    z1: { el: [{ name: '阻抗', value: 50, unit: 'Ω', min: 30, max: 100 }], ck: [] },
    cp3: { el: [{ name: '互容值', value: 75, unit: 'aF', min: 0, max: 500 }], ck: [] },
    cb1_single: { el: [{ name: '电容值', value: 0.5, unit: 'fF', min: 0, max: 50 }], ck: [] },
    cb1_t_network: {
      el: [
        { name: '电容值', value: 1.1, unit: 'fF', min: 0, max: 50 },
        { name: '互容值C1m', value: 70, unit: 'fF', min: 0, max: 100 },
        { name: '互容值C2m', value: 70, unit: 'fF', min: 0, max: 100 },
        { name: '对地电容', value: 70, unit: 'fF', min: 0, max: 500 },
      ],
      ck: [],
    },
    cb1_t_network_1: { el: [], ck: [] },
    cz1: { el: [{ name: '互感值', value: 2, unit: 'pH', min: 0, max: 10 }], ck: [] },
  },
  fontOptions: [
    {
      value: 'heiti',
      label: '黑体',
    },
    {
      value: 'songti',
      label: '宋体',
    },
  ],
  RoleOptions: [
    {
      label: '管理者',
      value: '管理者',
    },
    {
      label: '协作者',
      value: '协作者',
    },
  ],
  view3D: {
    name: '3D视图',
    ui: {
      draw_3d_section_area: '3D剖面区域',
      draw_3d_view_area: '3D视图区域',
      load_file: '加载工艺文件',
      reset_model: '重置3D模型',
      process_steps: '工艺步骤',
      deposition: '沉积',
      etching: '刻蚀',
      layer: '图层',
      layer_number: '图层编号',
      material: '材料',
      structure_name: '结构层名称',
      angle: '角度',
      depth: '深度',
      color: '颜色',
      structure_list: '结构层列表',
      structure: '结构层',
      hide: '隐藏',
      number: '编号',
      list: '列表',
      light: '光照',
      ambient_light_intensity: '环境光强度',
      direct_light_intensity: '直射光强度',
      direct_light_angle: '直射光角度',
      model_thickness: '模型厚度',
      thickness: '厚度',
      profile: '剖面',
      profile_area: '剖面区域',
      height: '长',
      width: '宽',
      depth: '高',
      generate_profile: '生成剖面',
      choose: '选择',
      ok: '确认',
      cancel: '取消',
      process_file_type_error: '工艺文件格式错误!',
      process_file_type_not_json_error: '上传工艺文件只能是 JSON 格式!',
    },
  },
  componentOptions: [
    {
      label: '全部',
      value: 0,
    },
    {
      label: '固定器件',
      value: 1,
    },
    {
      label: '参数化器件',
      value: 2,
    },
  ],
  toolComponentOptions: [
    {
      label: '固定器件',
      value: 1,
    },
    {
      label: '参数化器件',
      value: 2,
    },
  ],
  copyToTypeOptions: [
    {
      label: '个人器件库',
      value: 0,
    },
    {
      label: '团队器件库',
      value: 1,
    },
  ],
  copyToSymbolTypeOptions: [
    {
      label: '个人符号库',
      value: 0,
    },
    {
      label: '团队符号库',
      value: 1,
    },
  ],
  batchAddComponentTypeOptions: [
    {
      label: '个人器件库',
      value: 0,
    },
    {
      label: '团队器件库',
      value: 1,
    },
    {
      label: '公共器件库',
      value: 2,
    },
  ],
  batchAddSymbolTypeOptions: [
    {
      label: '个人符号库',
      value: 0,
    },
    {
      label: '团队符号库',
      value: 1,
    },
    {
      label: '公共符号库',
      value: 2,
    },
  ],
  searchLibraryTypeOptions: [
    {
      label: '个人器件库',
      value: 0,
    },
    {
      label: '团队器件库',
      value: 1,
    },
    {
      label: '公共器件库',
      value: 2,
    },
    // {
    //   label: '官方器件库',
    //   value: 3,
    // },
    {
      label: 'PDK器件库',
      value: 4,
    },
  ],
  searchSymbolTypeOptions: [
    {
      label: '个人符号库',
      value: 0,
    },
    {
      label: '团队符号库',
      value: 1,
    },
    {
      label: '公共符号库',
      value: 2,
    },
    // {
    //   label: '官方符号库',
    //   value: 3,
    // },
    {
      label: 'PDK符号库',
      value: 4,
    },
  ],
  exportLibraryTypeOptions: [
    {
      label: '个人库',
      value: 1,
    },
    {
      label: '团队库',
      value: 2,
    },
    {
      label: '公共库',
      value: 3,
    },
    // {
    //   label: '官方库',
    //   value: 4,
    // },
    {
      label: 'PDK库',
      value: 5,
    },
  ],
  varOptions: [
    { value: 0, label: '因变量' },
    { value: 1, label: '自变量' },
  ],
  argOptions: [
    { value: 0, label: '器件参数' },
    { value: 1, label: '非器件参数' },
  ],

  //版图画板编辑
  cellBoardMsg: {
    message: {
      cellNeedUpdate: '版图数据已更新，请重新打开画板 ',
    },
    error: {
      partCheckBanFunc: '部分选择模式无法使用此功能',
      mergeLayersError: '不同图层不能进行合并',
    },
  },
  messageBoard: {
    editHistory: '编辑历史',
    show: '展开',
    hide: '隐藏',
    team: '团队',
    member: '成员',
    editCurrentCell: '编辑了当前单元',
    memberDone: '成员进行了',
    operation: '操作',
    DRAW_LINE: '绘制线',
    DRAW_CIRCLE: '绘制圆',
    DRAW_RECT: '绘制矩形',
    DRAW_POLYGON: '绘制多边形',
    DRAW_LABEL: '绘制标签',
    DRAW_KP: '绘制关键点',
    DRAW_RULER: '绘制尺子',
    DRAW_3D_CUT_AREA: '绘制3d剖面区域',
    DRAW_3D_RENDER_AREA: '绘制3d渲染区域',
    MOVE: '移动',
    STRETCH: '拉伸',
    ROTATE: '旋转',
    MIRROR: '镜像',
    MIRRORX: '水平翻转',
    MIRRORY: '垂直翻转',
    ALIGN_PICK_POINT: '点对齐',
    ALIGN_PICK_LINE: '线对齐',
    ALIGN: '整体对齐',
    DELETE: '删除',
    ADD_DEVICE: '放置器件',
    PASTE: '复制粘贴',
    CUT: '剪切粘贴',
    MERGE: '合并',
    CUT_AREA: '区域切除',
    SLICE: '切割',
    ARRAY: '阵列',
    BREAK: '打散',
    BOOLEAN: '布尔运算',
    PROPERTY: '属性修改',
    TOOL_AIRBRIDGE: '工具-空气桥',
    CONNECT_LINE: '工具-连接线',
    TOOL_SQUID_ZLINE_RUN: '工具-SQUID和Z线的自动布局',
    TOOL_WAVEGUIDE_RUN: '工具-共面波导',
    TOOL_CAVITY_RUN: '工具-谐振腔',
    undo: '(撤销)',
    redo: '(恢复)',
  },

  //图层切换
  options_layerLibrarySelector: [
    {
      value: 0,
      label: "版图文件图层"
    },
    {
      value: 1,
      label: "单元图层列表"
    },
  ]
}


