import Md5 from 'js-md5'
export function pointDist(p1, p2) {
  let a = p2.x - p1.x,
    b = p2.y - p1.y
  return Math.sqrt(a * a + b * b)
}

export function pointDist_L(p1, p2) {
  let a = p2[0] - p1[0],
    b = p2[1] - p1[1]
  return Math.sqrt(a * a + b * b)
}

export function pointsTranslateGDS(points) {
  let newPoints = []
  for (let index in points) {
    if (index % 2 === 0) {
      let pointArray = []
      pointArray.push(points[index])
      pointArray.push(points[Number(index) + 1])
      newPoints.push(pointArray)
    }
  }
  return newPoints
}

export function pointsTranslateKonva(points) {
  let newPoints = []
  for (let i in points) {
    for (let j in points[i]) {
      newPoints.push(points[i][j])
    }
  }
  return newPoints
}

//获取最合适的宽高，取整数以便调整中心点坐标系
export function getSuitSize() {
  const realWidth = document.body.clientWidth,
    realHeight = document.body.clientHeight - 30
  return {
    width: realWidth % 2 == 0 ? realWidth : realWidth - 1,
    height: realHeight % 2 == 0 ? realHeight : realHeight - 1,
  }
}

export function getCrossPoint(currentPoint) {
  return {
    x: Number(currentPoint.x.toFixed(3)) - window.guideX,
    y: -(Number(currentPoint.y.toFixed(3)) - window.guideY),
  }
}

export function getNewPos(zoom, p1, p2) {
  let a = p2.x - p1.x,
    b = p2.y - p1.y
  const pointDist = Math.sqrt(a * a + b * b)
  const offsetDist = pointDist * zoom - pointDist
  const moveX = (p1.x / pointDist) * offsetDist,
    moveY = (p1.y / pointDist) * offsetDist
  return {
    x: moveX,
    y: moveY,
  }
}

export function toDecimal(x) {
  var f = parseFloat(x)
  if (isNaN(f)) {
    return false
  }
  var f = Math.round(x * 100) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 3) {
    s += '0'
  }
  return s
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export function deepClone(obj) {
  if (obj == null) {
    return obj
  }
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        objClone[key] = deepClone(obj[key])
      } else {
        objClone[key] = obj[key]
      }
    }
  }
  return objClone
}

export function deepCopy(data) {
  //string,number,bool,null,undefined,symbol
  //object,array,date
  if (data && typeof data === 'object') {
    //针对函数的拷贝
    if (typeof data === 'function') {
      let tempFunc = data.bind(null)
      tempFunc.prototype = deepCopy(data.prototype)
      return tempFunc
    }

    switch (Object.prototype.toString.call(data)) {
      case '[object String]':
        return data.toString()
      case '[object Number]':
        return Number(data.toString())
      case '[object Boolean]':
        return new Boolean(data.toString())
      case '[object Date]':
        return new Date(data.getTime())
      case '[object Array]':
        var arr = []
        for (let i = 0; i < data.length; i++) {
          arr[i] = deepCopy(data[i])
        }
        return arr

      //js自带对象或用户自定义类实例
      case '[object Object]':
        var obj = {}
        for (let key in data) {
          //会遍历原型链上的属性方法，可以用hasOwnProperty来控制 （obj.hasOwnProperty(prop)
          obj[key] = deepCopy(data[key])
        }
        return obj
    }
  } else {
    //string,number,bool,null,undefined,symbol
    return data
  }
}

export function colorTransform(color) {
  let reColor = ''
  if (color.indexOf('#') > -1) {
    reColor = color.replace('#', '0x')
  }
  if (color.indexOf('0x') > -1) {
    reColor = color.replace('0x', '#')
  }
  return reColor
}

export function unique(arr) {
  return Array.from(new Set(arr))
}

export function uniqueObject(obj) {
  var uniques = []
  var stringify = {}
  for (var i = 0; i < obj.length; i++) {
    var keys = Object.keys(obj[i])
    keys.sort(function (a, b) {
      return Number(a) - Number(b)
    })
    var str = ''
    for (var j = 0; j < keys.length; j++) {
      str += JSON.stringify(keys[j])
      str += JSON.stringify(obj[i][keys[j]])
    }
    if (!stringify.hasOwnProperty(str)) {
      uniques.push(obj[i])
      stringify[str] = true
    }
  }
  uniques = uniques
  return uniques
}

export function setCookie(cname, cvalue) {
  document.cookie = cname + '=' + cvalue
}

export function fontHide(str) {
  let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  let hideStr = ''
  if (reg.test(str)) {
    if (String(str).indexOf('@') > 0) {
      let email_str = str.split('@'),
        _s = ''
      if (email_str[0].length > 3) {
        for (let i = 0; i < email_str[0].length - 3; i++) {
          _s += '*'
        }
      }
      hideStr = email_str[0].substr(0, 3) + _s + '@' + email_str[1]
    }
  } else {
    hideStr = str.substring(0, 3) + '****' + str.substr(str.length - 4)
  }
  return hideStr
}

export function toFirst(fieldData, index) {
  if (index != 0) {
    // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
    fieldData.unshift(fieldData.splice(index, 1)[0])
  }
}

export function compareStr(str1, str2) {
  if (str1 === str2) {
    return true
  }
  let arr1 = [...str1]
  let arr2 = [...str2]
  if (arr2.length > arr1.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (i > arr1.length - arr2.length - 1) {
      break
    }
    if (arr1[i] === arr2[0]) {
      let m = i
      let flag = true
      for (let k = 0; k < arr2.length; k++) {
        if (arr2[k] === arr1[m]) {
          m++
        } else {
          flag = false
        }
      }
      if (flag) {
        return true
      }
    }
  }
  return false
}

export function jsonClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function getPointDistTotal(points) {
  let sum = 0
  for (let i = 0; i < points.length - 1; i++) {
    sum += pointDist_L(points[i], points[i + 1])
  }
  return sum.toFixed(3)
}

export function same(aa, bb) {
  let b_arr = [],
    a_arr = []
  if (aa.length > bb.length) {
    a_arr = aa
    b_arr = bb
  } else {
    a_arr = bb
    b_arr = aa
  }
  try {
    let cc = [...a_arr]
    for (let item in b_arr) {
      for (let it in cc) {
        if (b_arr[item].toString() === cc[it].toString()) {
          cc.splice(it, 1)
        }
      }
    }
    return cc.length < 1 ? true : false
  } catch (error) {
    return false
  }
}

//通过文件下载url拿到对应的blob对象
export const getBlob = function (url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }

    xhr.send()
  })
}
//下载文件 　　js模拟点击a标签进行下载
export const downloadBlob = function (blob, filename) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

export const saveJSON = function (data, filename) {
  if (!data) {
    alert('保存的数据为空')
    return
  }
  if (!filename) filename = 'json.json'
  if (typeof data === 'object') {
    data = JSON.stringify(data, undefined, 4)
  }
  var blob = new Blob([data], { type: 'text/json' })
  return blob
}

export const unicode2str = function (str) {
  var strnew = JSON.stringify(str)
  const unicode_str = `${strnew.replace(/\"/g, '\\"')}`

  return unicode_str
}

/**
 * 下载压缩包文件
 * @param {blob} fileArrayBuffer 文件流
 * @param {String} filename 文件名称
 * @param {String} fileType 文件格式
 */
export const downloadZip = params => {
  let time = parseInt(new Date().getTime() / 1000)
  let apiKey = localStorage.getItem('apiKey')
  let bucket_name = localStorage.getItem('bucket_name')
  let minio_name = localStorage.getItem('minio_name')
  var xhr = new XMLHttpRequest()
  xhr.open('POST', '/file/api/v1/get_object_data_zip/')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.setRequestHeader('username', minio_name)
  xhr.setRequestHeader('sign', Md5(`${apiKey}_${time}`))
  xhr.responseType = 'blob'
  xhr.send(JSON.stringify(params))
  xhr.onreadystatechange = function () {
    //判断请求状态和响应
    if (xhr.readyState === 4 && xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: 'application/zip' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `export_library_file.zip`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }
}
