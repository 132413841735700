import Md5 from 'js-md5'
import { getDiagramFile_api, saveDiagramFile_api, batchPutObject_api, batchPutObject_fileSize_api, batchRemoveObject_api } from '@/api/objectStorage/objectStorage.js'
import bus from '@/components/common/bus'
import store from '@/store/index.js'
import { deepCopy } from '@/utils/utils'
import { getUserProjectList, getTeamList, getTeamProjectList, getFileList, getCellList, getRefDataUrlFn } from './fileListApiFn.js'

//获取单个对象存储的数据
export const getObjectStorageData = async function (nodeId) {
    let urlData = await getDiagramFile_api({ file_snow_id: [nodeId] })

    if (urlData.code === 200000) {
        if (urlData.data.length === 0) {
            return { data: {}, data_u8: [] }
        } else {
            let data_u8 = await getObjectDataFn(urlData.data[0].file_url)
            // downloadFileData(data_u8, 'c1', 'txt')
            let arr = Kernel.parse_file(data_u8, false)

            let data = arr.filter(item => item.snow_id === nodeId)[0]

            return { data, data_u8 }
        }
    }
}

//单个获取对象存储数据转u8
export const getObjectDataFn = async function (fileUrl) {
    let time = parseInt(new Date().getTime() / 1000)
    const bucket_name = localStorage.getItem('bucket_name')
    const minio_name = localStorage.getItem('minio_name')
    const apiKey = localStorage.getItem('apiKey')
    let file_content = await fetch('/file/api/v1/get_object_data/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            username: minio_name,
            sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
            time,
            bucket_name,
            object_path: fileUrl,
        }),
    })

    let data_buffer = await file_content.arrayBuffer()
    let data_u8 = new Uint8Array(data_buffer)
    // downloadFileData(data_u8, "cell", "txt")
    return data_u8
}

//批量获取对象存储数据,返回U8
export const batchGetObjectDataFn = async function (fileUrlArr) {
    const bucket_name = localStorage.getItem('bucket_name')
    const minio_name = localStorage.getItem('minio_name')
    const apiKey = localStorage.getItem('apiKey')
    let time = parseInt(new Date().getTime() / 1000)
    let batch_path_data = []

    fileUrlArr.data.forEach(item => {
        let path_data = {
            snow_id: item.snow_id,
            bucket_name,
            object_path: item.file_url,
            version_id: '',
        }
        batch_path_data.push(path_data)
    })

    let file_content = await fetch('/file/api/v1/get_batch_object_data/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            username: minio_name,
            sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
            time,
            batch_path_data,
        }),
    })

    let data_buffer = await file_content.arrayBuffer()
    let data_u8 = new Uint8Array(data_buffer)
    return data_u8
}

//批量保存
export const batchSaveDataFn = async function (fileU8Array, batch_data) {
    let formData = new FormData() //新建表单对象
    for (let i = 0; i < fileU8Array.length; i++) {
        formData.append('file', new Blob([fileU8Array[i].data_u8]), fileU8Array[i].snow_id)
    }

    await batchPutObject(formData, batch_data)
}

//批量保存文件上传文件流
export const batchPutObject = async function (formData, batch_data) {
    const bucket_name = localStorage.getItem('bucket_name')
    const minio_name = localStorage.getItem('minio_name')
    const apiKey = localStorage.getItem('apiKey')

    let time = parseInt(new Date().getTime() / 1000)
    formData.append('time', time)
    formData.append('bucket_name', bucket_name)
    formData.append('batch_data', JSON.stringify(batch_data))
    formData.append('object_name', '')
    let res = await batchPutObject_api(formData, {
        sign: Md5(`${apiKey}_${time}`),
        username: minio_name,
    })

    if (res.code === 200000) {
        let paramsArr = []
        for (const key in res.data) {
            paramsArr.push({ file_url: res.data[key].object_path, snow_id: key, etag: res.data[key].etag, version_id: res.data[key].version_id })
        }

        await saveDiagramFile_api({ file_data: paramsArr })
    }

    //保存当前返回的url
}

//批量上传-文件大小限制
export const batchSaveDataFn_fileSize = async function (fileU8Array, project_snow_id, file_snow_id, file_type, cellId_list) {
    let formData = new FormData() //新建表单对象
    for (let i = 0; i < fileU8Array.length; i++) {
        formData.append('file', new Blob([fileU8Array[i].data_u8]), fileU8Array[i].snow_id)
    }
    const bucket_name = localStorage.getItem('bucket_name')
    formData.append('bucket_name', bucket_name)
    formData.append('project_snow_id', project_snow_id)
    formData.append('file_snow_id', file_snow_id)
    formData.append('file_type', file_type)
    formData.append('cell_list', JSON.stringify(cellId_list))//如果没有保存单元文件,就传空字符串
    let res = await batchPutObject_fileSize_api(formData)
    return res
}

//PDK批量上传-文件大小限制
export const batchSavePDKFn_fileSize = async function (fileU8Array, project_snow_id, file_type, is_pdk_file, is_official_pdk) {
    let formData = new FormData() //新建表单对象
    if (fileU8Array.length) {
        for (let i = 0; i < fileU8Array.length; i++) {
            if (fileU8Array[i].type == "zip") {
                formData.append('zip_file', fileU8Array[i].zip, fileU8Array[i].name)
            } else {
                formData.append('file', new Blob([fileU8Array[i].data_u8]), fileU8Array[i].name)
            }
        }
    } else {
        formData.append('file', new Blob(), "")//官方PDK
    }

    const bucket_name = localStorage.getItem('bucket_name')
    formData.append('bucket_name', bucket_name)
    formData.append('project_snow_id', project_snow_id)
    formData.append('file_snow_id', "")
    formData.append('file_type', file_type)
    formData.append('cell_list', JSON.stringify(""))
    formData.append('is_pdk_file', is_pdk_file)
    formData.append('is_official_pdk', is_official_pdk)
    let res = await batchPutObject_fileSize_api(formData)
    return res
}

//获取指定单元的文件名
export const getFolderName = function (topId, gns, isTeam) {
    if (isTeam) {
        //团队项目
        return `team_${topId}/${gns}`
    } else {
        //个人项目
        return `user_${localStorage.getItem('userId')}/${gns}`
    }
}
//获取当前画板的文件名
export const getCurrentBoardFolderName = function (gns) {
    if (store.state.proInfo.hasOwnProperty('teamRule')) {
        //团队项目
        return `team_${store.state.topInfo.teamId}/${gns}`
    } else {
        //个人项目
        return `user_${localStorage.getItem('userId')}/${gns}`
    }
}

//导出下载文件
export const downloadFileData = function (data_u8, name, suffix) {
    let content = data_u8
    let mime = 'application/octet-stream'
    var download_link = document.getElementById('download_gds')
    if (download_link == null) {
        download_link = document.createElement('a')
        download_link.id = 'download_gds'
        download_link.style.display = 'none'
        document.body.appendChild(download_link)
    }
    download_link.download = `${name}.${suffix}` //名字
    download_link.href = URL.createObjectURL(
        new Blob([content], {
            type: mime,
        })
    )
    download_link.click()
}

//通过snowId获取单个对象存储中的数据
export const getObjectData = async function (snow_id) {
    let dataInfo = await getObjectStorageData(snow_id)
    let data = dataInfo.data
    if (dataInfo.data_u8.length) {
        return data
    } else {
        return false
    }
}

//获取和当前节点有关的数据信息
// function getRefInfo(EDData, notAll) {
//     let refInfo = []//需要拿来组装的数据id和类型信息
//     let refData = EDData.depends(notAll)

//     for (const key in refData) {
//         if (key !== EDData.snow_id) {
//             refInfo.push({
//                 snow_id: key,
//                 type: refData[key].value
//             })
//         }
//     }
//     return refInfo
// }

//通过snowId数组批量获取数据
export const batchGetOSData = async function (snowIdArr, isAssemble = false) {
    if (snowIdArr.length > 0) {
        let urlRes = await getRefDataUrlFn(snowIdArr)
        if (urlRes.code === 200000) {
            if (urlRes.data.length) {
                let dataArray = []
                let data_u8 = await batchGetObjectDataFn(urlRes)
                if (isAssemble) {
                    dataArray = Kernel.parse_file(data_u8)
                } else {
                    dataArray = Kernel.parse_file(data_u8, false)
                }
                // downloadFileData(data_u8, "c2", "txt")
                return dataArray
            } else {
                return []
            }
        }
    } else {
        return []
    }
}
