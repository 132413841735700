import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import i18n from './common/lang/i18n.js'
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store/index.js'
import jsCookie from 'js-cookie'
import axios from 'axios'
import bus from '@/components/common/bus'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { delCookie } from './api/axiosReq'
import { JSEncrypt } from 'jsencrypt'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
// import{} from 'three/examples/fonts/helvetiker_regular.typeface.json'
import '@/assets/iconfont/iconfont.css'
var AutoToolLibs = require('./utils/tools/libs')
window.AUTO_TOOL_LIBS = new AutoToolLibs()
window.THREE = require('three')
var JSZip = require('jszip') //NOSONAR
var JSZipUtils = require('jszip-utils') //NOSONAR
import { message } from '@/utils/resetMessage'
import wsConnect from '@/utils/websocket/websocket'

// 导出excel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.prototype.$ws = wsConnect

Vue.prototype.$msg = message

window.newJSEncrypt = function () {
  return new JSEncrypt()
}

window.i18n = i18n

import VueWorker from 'vue-worker'
Vue.use(VueWorker)

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999, // 这里设置层级高度
  },
})

Viewer.setDefaults({
  Options: {
    inline: true, // 启用 inline 模式
    button: true, // 显示右上角关闭按钮
    navbar: true, // 显示缩略图导航
    title: true, // 显示当前图片的标题
    toolbar: true, // 显示工具栏
    tooltip: true, // 显示缩放百分比
    movable: true, // 图片是否可移动
    zoomable: true, // 图片是否可缩放
    rotatable: true, // 图片是否可旋转
    scalable: true, // 图片是否可翻转
    transition: true, // 使用 CSS3 过度
    fullscreen: true, // 播放时是否全屏
    keyboard: true, // 是否支持键盘
    url: 'data-source', // 设置大图片的 url
  },
})

window.QGdstk = null
window.Kernel = null
window.CanvasKit = null
window.QGeos = null
window.tool_tinpad_inpillar = null
window.FONT = {}
window.FONT_FACES = {}
//加载字体
let heiti = getRomoteZipFile('/ttf/heiti.zip')
heiti.then(data => {
  window.FONT.heiti = data
})
let songti = getRomoteZipFile('/ttf/songti.zip')
songti.then(data => {
  window.FONT.songti = data
})
//获取压缩文件
function getRomoteZipFile(url) {
  return new Promise(function (resolve, reject) {
    //  step1 请求文件
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
    .then(function (files) {
      //  step2 解压
      return JSZip.loadAsync(files)
    })
    .then(files => {
      const fileName = Object.keys(files.files)[0] // 拿第一个文件
      //arraybuffer表示文件为二进制
      return files.file(fileName).async('arraybuffer')
    })
}

// 加载CanvasKitInit.wasm文件
import { CanvasKitInit } from './utils/canvaskit-wasm/bin/full/canvaskit.js'
async function loadCanvasKit() {
  await CanvasKitInit({
    locateFile: file => 'wasm/canvaskit/' + file,
  }).then(CanvasKit => {
    window.CanvasKit = CanvasKit
  })
}
loadCanvasKit()

// 加载gdstk.wasm文件
const QGdstk = require('./utils/gdstk/qgdstk')
// const QGdstk = require('../public/wasm/gdstk/qgdstk')
async function loadQGdstk() {
  await QGdstk({
    locateFile: file => 'wasm/gdstk/' + file,
  }).then(obj => {
    window.QGdstk = obj
  })
}
loadQGdstk()

// 加载kernel.wasm文件
const Kernel = require('./utils/kernel/qeda_kernel')
function loadKernel() {
  Kernel({
    locateFile: file => 'wasm/kernel/' + file,
  }).then(obj => {
    window.Kernel = obj
    window.SCHEMABOARD = {}
    window.CELLBOARD = {}
  })
}
loadKernel()

// 加载qgeos.wasm文件
const QGeos = require('./utils/geos/qgeos')
async function loadQGeos() {
  await QGeos({
    locateFile: file => 'wasm/geos/' + file,
  }).then(obj => {
    window.QGeos = obj
  })
}
loadQGeos()

const loader = new FontLoader()
loader.load('/fonts/helvetiker_regular.typeface.json', function (font) {
  window.ThreeFont = font
})
// 挂$bus 使用中介传参
Vue.prototype.$bus = new Vue()
window.$bus = new Vue()
window.lang = 'zh'
Vue.prototype.$axios = axios
axios.defaults.withCredentials = true

import Contextmenu from 'vue-contextmenujs'
import { setInterval } from 'core-js'
import VueI18n from 'vue-i18n'
Vue.use(Contextmenu)
// import * as kdtreefast from 'kdtree-fast'

// 全局修改dialog点击空白不触发关闭事件
ElementUI.Dialog.props.closeOnClickModal.default = false
// 全局修改dialog按下esc不触发关闭事件
ElementUI.Dialog.props.closeOnPressEscape.default = false
Vue.use(ElementUI)
Vue.prototype.$cookie = jsCookie

router.beforeEach((to, from, next) => {
  let path = to.path
  if (to.redirectedFrom == '/home' && location.hash == '#/3d-view') {
    next('3d-view')
    path = '/3d-view'
  } else if (to.redirectedFrom == '/home' && location.hash == '#/tools/autoline') {
    next('tools/autoline')
    path = '/tools/autoline'
  } else if (to.redirectedFrom == '/home' && location.hash == '#/newboard') {
    next('newboard')
    path = '/newboard'
  } else if (to.redirectedFrom == '/home' && location.hash == '#/pcell') {
    next('pcell')
    path = '/pcell'
  } else if (to.redirectedFrom == '/home' && location.hash == '#/test/three_test') {
    path = '/test/three_test'
    next('three_test')
  } else if (to.redirectedFrom == '/home' && location.hash == '#/test/pcell') {
    next('test/pcell')
    path = 'test/pcell'
  } else {
    next()
  }
  // if (to.path === '/login') {
  //   next('/home')
  //   // pingCookie().then(res => {
  //   //   try {
  //   //     if (res.code !== 401) {
  //   //       next('/home')
  //   //     } else {
  //   //       next()
  //   //     }
  //   //   } catch (e) {
  //   //     return;
  //   //   }
  //   // })
  // } else {
  //   next()
  // }
  store.commit('setRouteFor', path)
})

// const i18n = new VueI18n({
//   locale: 'zh',    // 语言标识
//   //this.$i18n.locale // 通过切换locale的值来实现语言切换
//   messages: {
//     'zh': require('./common/lang/zh'), // 中文语言包
//     'en': require('./common/lang/en')    // 英文语言包
//   }
// })

// onmessage = function (e) {

//   if (e.data.code == 'auto') {
//     const AutoTool = require('./components/tools/auto/index')

//     let gds = window.QGdstk.read_gds('triple18.gds')
//     var tool = new AutoTool(gds)
//   }

//   // postMessage(e.data)

// }
window.bus = new Vue()

// window.onunhandledrejection = function (event) {
//   if (event.reason.hasOwnProperty('code')) {
//     if (parseInt(event.reason.code / 1000) == 401) {
//       delCookie()
//       bus.$emit('loginFailed', false)
//       // let sys_url = window.location.href.split('/#/')[0];
//       // let replace_url = sys_url + '/#/login'
//       router.replace('/login')
//     }
//     bus.$emit('ending', true)

//     bus.$emit('errDialog', i18n.t('messages.code_' + event.reason.code))
//   }
// }
window.noRepeatArray = function (arr) {
  return Array.from(new Set(arr)) //利用Set结构不能接收重复数据的特点
}

window.noRepeatPtr = function (arr) {
  let ret = []
  arr.forEach((item, index, self) => {
    let compare = []
    ret.forEach((retitem, retindex, retself) => {
      compare.push(retitem.$$.ptr)
    })
    if (compare.indexOf(item.$$.ptr) === -1) {
      ret.push(item)
    }
  })
  return ret
}

window.removeArrayItem = function (arr, item) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === item) {
      arr.splice(i, 1)
      break
    }
  }
}

window.getUniqueCells = function (arr, key) {
  return [...new Map(arr.map(item => [item.$$.ptr, item])).values()]
}

window.DefLayerMat = {
  lineMat: new THREE.MeshBasicMaterial(),
  fillMat: new THREE.MeshBasicMaterial(),
  fontMat: new THREE.MeshBasicMaterial(),
  lineInsMat: new THREE.MeshBasicMaterial(),
  fillInsMat: new THREE.MeshBasicMaterial(),
  fontInsMat: new THREE.MeshBasicMaterial(),
  keyPointMat: new THREE.MeshBasicMaterial(),
  keyPointInsMat: new THREE.MeshBasicMaterial(),
}
// 这里上线后需要改成上线日期的时间戳，用于判断是否为2.4版本之前的文件
window.online_time = "2023-10-19 10:00:00";
function initVue() {
  new Vue({
    render: h => h(App),
    i18n,
    store,
    router,
  }).$mount('#app')
}
let timer = setInterval(() => {
  if (window.CanvasKit && window.QGdstk && window.QGeos && window.Kernel && window.ThreeFont) {
    initVue()
    clearInterval(timer)
  }
}, 100)
