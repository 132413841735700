import store from '@/store'

//websocket

function initWebpack(wsurl) {
  store.state.ws = new WebSocket(wsurl)
  store.state.ws.onopen = onopen
  store.state.ws.onmessage = onmessage
  store.state.ws.onclose = onclose
  store.state.ws.onerror = onerror
}
function onopen() {
  // var params = '{"reqtype":"Query","action":"allexts"}'
  // store.state.ws.send(params)
  start()
}
function reconnect() {
  //重新连接
  var that = store.state
  if (that.lockReconnect) {
    return
  }
  that.lockReconnect = true
  //没连接上会一直重连，设置延迟避免请求过多
  that.timeoutnum && clearTimeout(that.timeoutnum)
  that.timeoutnum = setTimeout(function () {
    //新连接
    initWebpack()
    that.lockReconnect = false
    that.isFirstGet = true
  }, 5000)
}
function reset() {
  //重置心跳
  var that = store.state
  //清除时间
  clearTimeout(that.timeoutObj)
  clearTimeout(that.serverTimeoutObj)
  //重启心跳
  start()
}
function start() {
  //开启心跳

  var self = store.state
  self.timeoutObj && clearTimeout(self.timeoutObj)
  self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj)
  self.timeoutObj = setTimeout(function () {
    //这里发送一个心跳，后端收到后，返回一个心跳消息，
    if (self.ws.readyState === 1) {
      //如果连接正常
      const heartbeat = '{"message": "ping", "to_user": 1}'
      self.ws.send(heartbeat) //心跳包格式需要自己确定
    } else {
      //否则重连
      reconnect()
    }
    self.serverTimeoutObj = setTimeout(function () {
      //超时关闭
      self.ws.close()
      reconnect()
    }, self.timeout)
  }, self.timeout)
}
function onmessage(e) {
  if (JSON.parse(e.data).message !== 'ping') {
    window.dispatchEvent(
      new CustomEvent('onmessageWS', {
        detail: {
          data: JSON.parse(e.data),
        },
      })
    )
  }
  //处理数据的地方
  reset()
}
function onclose(e) {
  var self = store.state
  self.ws.close()
}
function onerror(e) {
  //重连
  reconnect()
}

function send(params) {
  store.state.ws.send(JSON.stringify(params))
}

export default {
  initWebpack,
  onmessage,
  onclose,
  onopen,
  onerror,
  send,
  start,
}
