import { messages } from '../../common/lang/zh'
const SYMCONF = messages.symbolConf
//背景网格生成
const axisGridShader = `
  uniform float step; //小网格像素宽度
  uniform float2 transform_p; //位移变换
  uniform float2 loop_p; //循环坐标系 由CPU计算避免GPU在大于10^7浮点计算丢失精度
  uniform float4 bg_color; //背景颜色
  uniform float4 axis_color; //坐标轴颜色
  uniform float4 line_color; //grid颜色
  uniform float width; //屏幕宽
  uniform float height; //屏幕高
  uniform float show_grid; //显示grid
  float grid(float value, float space, float width, float grid_size) {
    return ceil(mod(value + width, space * grid_size)) - width;
  }

  half4 main(float2 p) {
      float2 pp = p; //拷贝像素坐标
      pp.x -= loop_p.x; //位移变换
      pp.y -= loop_p.y;
      float axis_w = 1.0; //坐标轴宽度
      float w = 1.0; //网格宽度
      float space = step; //点间距，方格宽度
      float2 pos;
      float4 col;

      pos.x = grid(pp.x, space, w, 5);
      pos.y = grid(pp.y, space, w, 5);
      col = bg_color;
      if(bool(show_grid)){
        if (!bool(pos.x) || !bool(pos.y)) { //计算大网格
            col = line_color;
        } else {
            pos.x = grid(pp.x, space, w, 1); //计算小网格
            pos.y = grid(pp.y, space, w, 1);
            if (!bool(pos.x) || !bool(pos.y)) {
              col = line_color * 0.5;
            }
        }
      }
      if(abs(p.x - transform_p.x) <= axis_w || abs(p.y - transform_p.y) <= axis_w){ //计算坐标轴
        col = axis_color;
      }
      return half4(col);
  }
`
//背景点阵
const axisPointShader = `
  uniform float step; //小网格像素宽度
  uniform float2 transform_p; //位移变换
  uniform float2 loop_p; //循环坐标系 由CPU计算避免GPU在大于10^7浮点计算丢失精度
  uniform float4 bg_color; //背景颜色
  uniform float4 axis_color; //坐标轴颜色
  uniform float4 point_color; //点颜色
  uniform float width; //屏幕宽
  uniform float height; //屏幕高
  uniform float show_grid; //显示grid
  uniform float show_xy; //显示xy轴
  float grid(float value, float space, float width, float grid_size) {
    return mod(value, space * grid_size) - width;
  }

  half4 main(float2 p) {
      float2 pp = p; //拷贝像素坐标
      pp.x -= loop_p.x; //位移变换
      pp.y -= loop_p.y;
      float axis_w = 1.0; //坐标轴宽度
      float w1 = 3; //点宽度
      float w2 = 1.0; //点宽度
      float space = step; //点间距，方格宽度
      float2 pos;
      float4 col;

      pos.x = grid(pp.x + w1/2, space, w1, 5);
      pos.y = grid(pp.y + w1/2, space, w1, 5);
      col = bg_color;
      float min_x = abs(p.x - transform_p.x);
      float min_y = abs(p.y - transform_p.y);
      float filter_step = step-10.0;
      if(bool(show_xy) && (min_x < filter_step || min_y < filter_step)){
          if(min_x <= axis_w || min_y <= axis_w){ //计算坐标轴
            col = axis_color;
          }

      }else if(bool(show_grid)){
        if (pos.x<0 && pos.y<0) { //计算大点
            col = axis_color;
            col.a = 1.0;
        }else{
          pos.x = grid(pp.x + w2/2, space, w2, 1);
          pos.y = grid(pp.y + w2/2, space, w2, 1);
          if (pos.x<0 && pos.y<0) { //计算小点
            col = axis_color;
          }
        }
      }
      return half4(col);
  }
`

//获取文字高
export function getGlyphHeight(bounds) {
  let minX = bounds[0]
  let minY = bounds[1]
  let maxX = bounds[2]
  let maxY = bounds[3]
  for (let i = 0; i < bounds.length; i += 4) {
    if (bounds[i] < minX) {
      minX = bounds[i]
    }
    if (bounds[i + 1] < minY) {
      minY = bounds[i + 1]
    }
    if (bounds[i + 2] > maxX) {
      maxX = bounds[i + 2]
    }
    if (bounds[i + 3] > maxY) {
      maxY = bounds[i + 3]
    }
  }




  return Math.abs(maxY - minY)
}
export function anchorTran(anchor, w, h) {
  // canvaskit字体在右上角，按照此位置转换到对应的8个方位
  // let dpi = 96 // dpi: 一般电脑为96
  // let px = (pt * dpi) / 72 // 转为像素大小
  let offset = 0
  let tranX = w
  let tranY = h
  if (anchor == Kernel.Anchor.Centra) {
    return { x: tranX / 2, y: tranY / 2 }
  }
  if (anchor == Kernel.Anchor.Right) {
    return { x: tranX, y: tranY / 2 }
  }
  if (anchor == Kernel.Anchor.Down) {
    return { x: tranX / 2, y: 0 - offset }
  }
  if (anchor == Kernel.Anchor.Left) {
    return { x: 0, y: tranY / 2 }
  }
  if (anchor == Kernel.Anchor.Up) {
    return { x: tranX / 2, y: tranY + offset }
  }
  if (anchor == Kernel.Anchor.RightUp) {
    return { x: tranX, y: tranY + offset }
  }
  if (anchor == Kernel.Anchor.RightDown) {
    return { x: tranX, y: 0 - offset }
  }
  if (anchor == Kernel.Anchor.LeftUp) {
    return { x: 0, y: tranY + offset }
  }
  if (anchor == Kernel.Anchor.LeftDown) {
    return { x: 0, y: 0 - offset }
  }
  return { x: 0, y: 0 }
}

export class SchemaDisplayConfig {
  constructor() {
    this.antiAlias = true
    this.showGrid = true
    this.showXY = false
    this.gridSize = 1
    this.pick_dist = 20
    this.line_pixel_width = 2
    this.line_width = 2
    this.checked_res_pixel_width = 4
    this.checked_res_width = 4
    this.line_dash = [2, 2, 2, 2]
    this.point_pixel_radius = 5
    this.point_radius = 5
    this.port_pixel_radius = 5
    this.port_radius = 5
    this.default_font_size = 7
    this.default_font = 'heiti'
    this.default_anchor = Kernel.Anchor.LeftDown //Kernel.Anchor.Centra
    this.showConfig = {
      instanceText: true,
      symbolText: true,
      parameterText: true,
      pinName: true,
      portName: true,
      netName: true,
      normalLabel: true,
    }
    this.colors = {
      preCheckedColor: '#00af9a', //悬停命中
      checkedColor: '#11b200', //选中
      backgroundColor: '#191919',
      gridColor: '#B2B2B2',
      mouse_cross_color: { hex: 0xff0000, alpha: 1.0 }, // 鼠标十字颜色
      label_font_color: '#c4dbf0', //标签颜色
      cp_color: '#9d1fff', //耦合线颜色
      pin_color: '#0b76d7', //pin图形颜色
      port_color: '#1d2560', //端口颜色
      port_name_color: '#7e7e7e', //端口名颜色
      symbol_color: '#0b76d7', //符号图形颜色
      symbol_name_color: '#7e7e7e', //符号名颜色
      symbol_instance_color: '#2761a3', //符号实例颜色
      symbol_parameter_color: '#0b76d7', //符号参数颜色
      pin_name_color: '#2761a3', //引脚实例颜色
      network_color: '#c4dbf0', //网络颜色
      edit_point_color: '#00ff00', //编辑线的点颜色
      mid_point_color: '#aeeddd', //线段中点颜色
      checked_res_color: '#ff0000', //检查结果强调颜色
    }
    this.mouse_cross_paint = new CanvasKit.Paint()
    this.label_font = FONT_FACES.heiti //标签文字
    this.mouse_box_paint = new CanvasKit.Paint() //鼠标盒子
    this.select_box_paint = new CanvasKit.Paint() //选择框
    this.test_paint = new CanvasKit.Paint() //测试
    this.edit_point_paint = new CanvasKit.Paint() //编辑线的端点
    this.mid_point_paint = new CanvasKit.Paint() //线中点
    this.fly_lines_paint = new CanvasKit.Paint() //飞线
    /*默认*/
    this.label_paint = new CanvasKit.Paint() //标签
    this.symbol_ins_name_paint = new CanvasKit.Paint() //实例名
    this.symbol_name_paint = new CanvasKit.Paint() //符号名
    this.port_name_paint = new CanvasKit.Paint() //端口名
    this.cp_paint = new CanvasKit.Paint() //耦合线
    this.cp_point_paint = new CanvasKit.Paint() //耦合线端点
    this.pin_paint = new CanvasKit.Paint() //针脚边框
    this.pin_name_paint = new CanvasKit.Paint() //针脚名
    this.port_paint = new CanvasKit.Paint() //符号颜色
    this.symbol_paint = new CanvasKit.Paint() //符号边框
    this.symbol_parameter_paint = new CanvasKit.Paint() //符号参数颜色
    /*预选中*/
    this.label_paint_pre_checked = new CanvasKit.Paint() //标签
    this.symbol_ins_name_paint_pre_checked = new CanvasKit.Paint() //实例名
    this.symbol_name_paint_pre_checked = new CanvasKit.Paint() //符号名
    this.port_name_paint_pre_checked = new CanvasKit.Paint() //端口名
    this.cp_paint_pre_checked = new CanvasKit.Paint() //耦合线
    this.cp_point_paint_pre_checked = new CanvasKit.Paint() //耦合线端点
    this.pin_paint_pre_checked = new CanvasKit.Paint() //针脚边框
    this.pin_name_paint_pre_checked = new CanvasKit.Paint() //针脚名
    this.port_paint_pre_checked = new CanvasKit.Paint() //符号颜色
    this.symbol_paint_pre_checked = new CanvasKit.Paint() //符号边框
    this.symbol_parameter_paint_pre_checked = new CanvasKit.Paint() //符号参数颜色
    /*选中*/
    this.label_paint_checked = new CanvasKit.Paint() //标签
    this.symbol_ins_name_paint_checked = new CanvasKit.Paint() //实例名
    this.symbol_name_paint_checked = new CanvasKit.Paint() //符号名
    this.port_name_paint_checked = new CanvasKit.Paint() //端口名
    this.cp_paint_checked = new CanvasKit.Paint() //耦合线
    this.cp_point_paint_checked = new CanvasKit.Paint() //耦合线端点
    this.pin_paint_checked = new CanvasKit.Paint() //针脚边框
    this.pin_name_paint_checked = new CanvasKit.Paint() //针脚名
    this.port_paint_checked = new CanvasKit.Paint() //符号颜色
    this.symbol_paint_checked = new CanvasKit.Paint() //符号边框
    this.symbol_parameter_paint_checked = new CanvasKit.Paint() //符号参数颜色

    this.checked_res_paint = new CanvasKit.Paint() //检查结果
    this.axisFact = CanvasKit.RuntimeEffect.Make(axisPointShader)
    this.axisPaint = new CanvasKit.Paint()
    this.updatePaints()
    this.updatePaintsDepLineWidth()
  }

  updatePaints() {
    setStrokePaint(this.mouse_cross_paint, 0.5, this.colors.mouse_cross_color.hex, this.colors.mouse_cross_color.alpha, this.antiAlias)
    let dep = CanvasKit.PathEffect.MakeDash([2, 2, 2, 2])
    this.mouse_cross_paint.setPathEffect(dep)
    dep.delete()
    setFillPaint(this.label_paint, this.colors.label_font_color, 1, this.antiAlias)
    setFillPaint(this.label_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.label_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.symbol_ins_name_paint, this.colors.symbol_instance_color, 1, this.antiAlias)
    setFillPaint(this.symbol_ins_name_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.symbol_ins_name_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.symbol_name_paint, this.colors.symbol_name_color, 1, this.antiAlias)
    setFillPaint(this.symbol_name_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.symbol_name_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.symbol_parameter_paint, this.colors.symbol_parameter_color, 1, this.antiAlias)
    setFillPaint(this.symbol_parameter_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.symbol_parameter_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.port_name_paint, this.colors.port_name_color, 1, this.antiAlias)
    setFillPaint(this.port_name_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.port_name_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.pin_name_paint, this.colors.pin_name_color, 1, this.antiAlias)
    setFillPaint(this.pin_name_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.pin_name_paint_checked, this.colors.checkedColor, 1, this.antiAlias)

    setFillPaint(this.port_paint, this.colors.port_color, 1, this.antiAlias)
    setFillPaint(this.port_paint_pre_checked, this.colors.preCheckedColor, 1, this.antiAlias)
    setFillPaint(this.port_paint_checked, this.colors.checkedColor, 1, this.antiAlias)
  }

  //更新和线宽有关的画笔
  updatePaintsDepLineWidth() {
    setStrokePaintRgb(this.select_box_paint, this.line_width, [220, 220, 0], 1.0, this.antiAlias)
    setStrokePaintRgb(this.fly_lines_paint, this.line_width * 1.0, [255, 0, 0], 0.5, this.antiAlias)

    setStrokePaintRgb(this.mouse_box_paint, this.line_width, [100, 100, 255], 1.0, this.antiAlias)
    setStrokePaintRgb(this.test_paint, this.line_width, [200, 200, 200], 1.0, this.antiAlias)
    setStrokePaintRgb(this.edit_point_paint, this.line_width * this.point_pixel_radius, hexToRgb(this.colors.edit_point_color), 1.0, this.antiAlias)
    setStrokePaintRgb(this.mid_point_paint, this.line_width * this.point_pixel_radius * 0.8, hexToRgb(this.colors.mid_point_color), 1.0, this.antiAlias)

    setStrokePaintRgb(this.cp_paint, this.line_width, hexToRgb(this.colors.cp_color), 1.0, this.antiAlias)
    setStrokePaintRgb(this.cp_point_paint, this.line_width * this.point_pixel_radius, [255, 0, 0], 1.0, this.antiAlias)
    setStrokePaintRgb(this.pin_paint, this.line_width * 2, hexToRgb(this.colors.pin_color), 1.0, this.antiAlias)
    setStrokePaintRgb(this.symbol_paint, this.line_width * 2, hexToRgb(this.colors.symbol_color), 1.0, this.antiAlias)

    setStrokePaintRgb(this.cp_paint_pre_checked, this.line_width, hexToRgb(this.colors.preCheckedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.cp_point_paint_pre_checked, this.line_width * this.point_pixel_radius, hexToRgb(this.colors.preCheckedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.pin_paint_pre_checked, this.line_width * 2, hexToRgb(this.colors.preCheckedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.symbol_paint_pre_checked, this.line_width * 2, hexToRgb(this.colors.preCheckedColor), 1.0, this.antiAlias)

    setStrokePaintRgb(this.cp_paint_checked, this.line_width, hexToRgb(this.colors.checkedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.cp_point_paint_checked, this.line_width * this.point_pixel_radius, hexToRgb(this.colors.checkedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.pin_paint_checked, this.line_width * 2, hexToRgb(this.colors.checkedColor), 1.0, this.antiAlias)
    setStrokePaintRgb(this.symbol_paint_checked, this.line_width * 2, hexToRgb(this.colors.checkedColor), 1.0, this.antiAlias)

    setStrokePaintRgb(this.checked_res_paint, this.checked_res_width * 2, hexToRgb(this.colors.checked_res_color), 1.0, this.antiAlias)

    // setStrokePaintRgb(this.cp_paint_pre_cut, this.line_width, hexToRgb(this.colors.checkedColor), this.antiAlias)
    // setStrokePaintRgb(this.cp_point_paint_pre_cut, this.line_width * this.point_pixel_radius, hexToRgb(this.colors.checkedColor), this.antiAlias)
    // setStrokePaintRgb(this.pin_paint_pre_cut, this.line_width, hexToRgb(this.colors.checkedColor), this.antiAlias)
    // setStrokePaintRgb(this.port_paint_pre_cut, this.line_width * this.port_pixel_radius, hexToRgb(this.colors.checkedColor), 0.5, this.antiAlias)
    // setStrokePaintRgb(this.symbol_paint_pre_cut, this.line_width, hexToRgb(this.colors.checkedColor), this.antiAlias)
    //
    let dep = CanvasKit.PathEffect.MakeDash(this.line_dash)
    this.cp_paint.setPathEffect(dep)
    this.cp_paint_pre_checked.setPathEffect(dep)
    this.cp_paint_checked.setPathEffect(dep)
    dep.delete()
  }

  onMouseWheel(scale) {
    this.line_width = this.line_pixel_width / scale
    this.point_radius = this.point_pixel_radius / scale
    this.port_radius = this.port_pixel_radius / scale
    this.pick_dist = 20 / scale
    this.checked_res_width = this.checked_res_pixel_width / scale
    this.updatePaintsDepLineWidth()
  }
  // switchFont(key) {
  //   if (!key) {
  //     this.label_font.setTypeface(null)
  //     return
  //   }
  //   const fontFace = CanvasKit.Typeface.MakeFreeTypeFaceFromData(FONT[key])
  //   this.label_font.setTypeface(fontFace)
  //   fontFace.delete()
  // }
  delete() {
    // this.mouse_cross_paint.delete()
    // this.mouse_box_paint.delete()
    // this.select_box_paint.delete()
    // this.test_paint.delete()
    // this.mid_point_paint.delete()
    // this.label_font.delete()
    // this.edit_point_paint.delete()
    // this.fly_lines_paint.delete()
    // this.label_paint.delete()
    // this.symbol_ins_name_paint.delete()
    // this.symbol_name_paint.delete()
    // this.symbol_parameter_paint.delete()
    // this.port_name_paint.delete()
    // this.cp_paint.delete()
    // this.cp_point_paint.delete()
    // this.pin_paint.delete()
    // this.pin_name_paint.delete()
    // this.port_paint.delete()
    // this.symbol_paint.delete()
    // this.label_paint_pre_checked.delete()
    // this.symbol_ins_name_paint_pre_checked.delete()
    // this.symbol_name_paint_pre_checked.delete()
    // this.symbol_parameter_paint_pre_checked.delete()
    // this.port_name_paint_pre_checked.delete()
    // this.cp_paint_pre_checked.delete()
    // this.cp_point_paint_pre_checked.delete()
    // this.pin_paint_pre_checked.delete()
    // this.pin_name_paint_pre_checked.delete()
    // this.port_paint_pre_checked.delete()
    // this.symbol_paint_pre_checked.delete()
    // this.label_paint_checked.delete()
    // this.symbol_ins_name_paint_checked.delete()
    // this.symbol_name_paint_checked.delete()
    // this.symbol_parameter_paint_checked.delete()
    // this.port_name_paint_checked.delete()
    // this.cp_paint_checked.delete()
    // this.cp_point_paint_checked.delete()
    // this.pin_paint_checked.delete()
    // this.pin_name_paint_checked.delete()
    // this.port_paint_checked.delete()
    // this.symbol_paint_checked.delete()
    // this.axisFact.delete()
    // this.axisPaint.delete()
  }
}

//设置画笔样式
function setStrokePaint(paint, strokeWidth, hex, alpha, antiAlias) {
  let rgb = hex2rgb(hex)
  paint.setColor(CanvasKit.Color4f(rgb[0], rgb[1], rgb[2], alpha))
  paint.setStyle(CanvasKit.PaintStyle.Stroke)
  paint.setAntiAlias(antiAlias)
  paint.setStrokeWidth(strokeWidth)
}

function setFillPaint(paint, hex, alpha, antiAlias) {
  let rgb = hexToRgb(hex)
  paint.setColor(CanvasKit.Color(rgb[0], rgb[1], rgb[2]))
  paint.setAlphaf(alpha)
  paint.setStyle(CanvasKit.PaintStyle.Fill)
  paint.setAntiAlias(antiAlias)
}

function setStrokePaintRgb(paint, strokeWidth, rgb, alpha = 1.0, antiAlias) {
  paint.setColor(CanvasKit.Color(rgb[0], rgb[1], rgb[2]))
  paint.setAlphaf(alpha)
  paint.setStyle(CanvasKit.PaintStyle.Stroke)
  paint.setAntiAlias(antiAlias)
  paint.setStrokeWidth(strokeWidth)
}

//0X... => [r,g,b]
export function hex2rgb(hex) {
  return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255]
}
//#... => [r,g,b]
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export function getBoardAABB(axis, stage) {
  const offset_x = (axis.dx - stage.width / 2) / axis.scale
  const offset_y = (axis.dy - stage.height / 2) / axis.scale
  return [
    [-(stage.width / 2) / axis.scale - offset_x, -(stage.height / 2) / axis.scale + offset_y],
    [stage.width / 2 / axis.scale - offset_x, stage.height / 2 / axis.scale + offset_y],
  ]
}

export function checkInsideStage(b, aabb) {
  return !(b[0][0] > aabb[1][0] || b[1][0] < aabb[0][0] || b[0][1] > aabb[1][1] || b[1][1] < aabb[0][1])
}

export function getParamsText(symbol) {
  let displayParams = []
  if (!symbol) return displayParams
  let func_name = symbol.generator
  if (func_name == '') return displayParams


  let conf = SYMCONF[func_name]
  let info = symbol.var_info
  let type = symbol.var_type
  let isEl = type == Kernel.VarType.ELECTROSTATIC
  let isMC = type == Kernel.VarType.MEASUREMENT_CONTROL
  info.keys().forEach(str => {
    let split = str.split(',#,')
    let name = split[0]
    let obj = { key: str, name, unit: '' }
    if (split[1] == 'el' && isEl) {
      obj.unit = conf.el.filter(obj => name == obj.name)[0].unit
      displayParams.push(obj)
    } else if (split[1] == 'ck' && isMC) {
      obj.unit = conf.ck.filter(obj => name == obj.name)[0].unit
      displayParams.push(obj)
    }
  })
  displayParams.forEach(obj => {
    obj.name = obj.name + ' ' + String(info.get(obj.key)) + obj.unit
  })
  return displayParams
}
