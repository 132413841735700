import { get, post, patch, httpDelete, download } from '../axiosReq'

export const getFileContent_api = fileId => post(`qeda/file/${fileId}/`)
// 新建项目
export const createProjectList = (params, headers) => post('/qeda/file/project/', params, headers)
//获取项目详情/项目文件列表
export const getProjectDetail = projectId => get(`/qeda/file/project/${projectId}/`)
// 修改项目详情
export const patchChangeProject = (projectId, name) => patch(`qeda/file/project/${projectId}/`, name)
// 删除项目
export const deleteProject = projectId => httpDelete(`qeda/file/project/${projectId}/`)
//创建文件
export const createFile = (params, headers) => post('/qeda/file/file/', params, headers)
//获取文件详情
export const getfileDetail = fileId => get(`/qeda/file/file/${fileId}/`)
// 修改保存文件
export const patchChangeFile = (fileId, content) => patch(`qeda/file/file/${fileId}/`, content)
// 删除文件
export const deleteFile = fileId => httpDelete(`qeda/file/file/${fileId}/`)
// 创建cell
export const createCellFile = (params, headers) => post('/qeda/file/cell/', params, headers)
//获取cell详情
export const getCellDetail = (cellId, params) => get(`/qeda/file/cell/${cellId}/`, params)
// 修改保存cell
export const patchChangeCell = (cellId, content) => patch(`qeda/file/cell/${cellId}/`, content)
//删除cell
export const deleteCell = cellId => httpDelete(`qeda/file/cell/${cellId}/`)

// 查询静态cell
export const getCustomCellList = params => get('/qeda/file/custom/', params)
export const addCustomJson = params => post('/qeda/file/import-custom-json/', params)
export const addCustomCell = params => post('/qeda/file/import-custom-cell/', params)

// 修改sn
export const editUserSn = (id, params) => patch(`/qeda/user/sn/${id}`, params)

//api-v2.4
export const createNewPFC_api = params => post('qeda/file/user_project_create/', params) //新建项目/文件/单元

export const getUserProjectList_api = params => get('/qeda/file/project_list/', params) //获取个人用户项目目录

export const getFileList_api = params => post('/qeda/file/list/', params) //获取文件列表

export const getCellList_api = params => post('/qeda/file/list/', params) //获取cell列表

export const deletePFC_api = params => httpDelete('/qeda/file/del_projects/', params) //删除项目/文件/单元

export const searchFile_api = params => post('/qeda/file/search_project_file/', params) //搜索项目/文件

export const updataName_api = params => patch('/qeda/file/update_name/', params) //重命名

export const addHistoryCell_api = params => post('/qeda/file/history_cell/', params) //添加近期浏览

export const getHistoryCell_api = params => get('/qeda/file/history_cell/', params) //展示近期浏览

export const parsingGns_api = params => post('/qeda/file/parsing_gns/', params) //添加近期浏览

export const getTeamList_api = params => post('/qeda/team/team_list/', params) //获取团队目录

export const getTeamPermissionList_api = params => post('/qeda/team/project_permission/', params) //获取团队目录权限

export const getTeamProjectList_api = params => post('/qeda/team/team_project/', params) //获取团队项目目录

export const getLayerList_api = params => post('/qeda/component/get_layer_info/', params) //获取图层信息

export const createNewLayer_api = params => post('/qeda/component/create_layer_info/', params) //创建图层信息

export const deleteLayer_api = params => post('/qeda/component/delete_layer_info/', params) //删除图层信息

export const editLayer_api = params => post('/qeda/component/update_layer_info/', params) //编辑图层信息

export const saveCellFile_api = params => post('/qeda/component/save_cell_file/', params) //保存cell文件内容

export const getCellFile_api = (params, config) => get('/qeda/component/save_cell_file/', params, config) //获取cell文件内容

export const copyFileToOtherProject_api = params => post('/qeda/team/copy_file_to_other_project/', params) //复制到文件内容

export const batchCreatePWD_api = params => post('/qeda/file/batch_create_cell/', params) //批量创建单元

export const createMyComponent_api = params => post('/qeda/component/create_my_component/', params) //保存到个人器件库

export const createTeamComponent_api = params => post('/qeda/component/create_team_component/', params) //保存到团队器件库

export const getBlobFile = url => get(url, null, { responseType: 'blob' }) //返回二进制

export const lockCell_api = params => post('/qeda/file/lock_cell/', params) //锁文件

export const selectLockCell_api = fileId => get(`/qeda/file/lock_cell/?file_snow_id=${fileId}`) //锁文件

export const getTree_api = params => post('/qeda/team/team_tree/', params) //文件树获取

export const getUserPermission_api = params => post('/qeda/team/user_gns_permission/', params) //新精确获取团队中权限

export const update_type_api = params => patch('/qeda/file/update_types/', params) //新精确获取团队中权限

export const create_file = params => post('/qeda/file/create/', params) //通用新建接口

export const getFileParams_api = fileId => get(`/qeda/file/file_params/?snow_id=${fileId}`) //获取索引关系数据

export const setFileParams_api = params => post('/qeda/file/file_params/', params) //修改索引关系数据

export const noPermissionFile_api = params => post('/qeda/file/no_permission_get_gns/', params) //无权限时获取项目下文件列表

export const getFileinfo_api = params => post('/qeda/file/gns_ids_message/', params) //无权限时获取项目下文件列表

export const getUserGnsPermission_api = params => post('/qeda/file/user_gns_permission/', params) //获取当前gns有权限的用户id

export const getFileChannels_api = params => post('/qeda/file/channels/', params) //获取当前gns有权限的用户id

export const getUserSymbolLibInfo_api = params => post('/qeda/symbols/get_library_list/', params) //获取当前用户的个人符号库信息

export const saveToUserSymbolLib_api = params => post('/qeda/symbols/save_my_symbol/', params) //存到个人符号库

export const saveToTeamSymbolLib_api = params => post('/qeda/symbols/save_team_symbol/', params) //存到团队符号库

export const unLockCell_api = params => post('/qeda/file/unlock/', params) //解锁文件
