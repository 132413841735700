import { deepClone } from '../utils'
import { isAdsorb } from '../pixi/qeda-pixi-data'
import { QedaGraphicType, getAngle, checkHit, checkHitBeforePart, insideEllipse, getSlope, getVerticalSlope, slopeAndIntercept, calculateIntersectionPoint } from './qeda-schema-graphics'
import bus from '@/components/common/bus'

import { anchorTran, SchemaDisplayConfig, hexToRgb, getBoardAABB, checkInsideStage, getParamsText } from './render-util'
import { AStar } from './layout/astar'
export class QedaSchemaStage {
  constructor(axis, containerId, schema, config) {
    this.containerId = containerId
    this.ELEM = containerId + '_canvas'
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    if (!this.surface) {
      console.error('Could not make surface')
      return
    }
    this.canvas = document.getElementById(this.ELEM) //获取canvas对象
    this.width = this.canvas.width //宽高
    this.height = this.canvas.height
    this.myaxis = axis //坐标系初始化
    this.canvasRulerX = document.getElementById(this.containerId + 'ruler-x')
    this.canvasRulerY = document.getElementById(this.containerId + 'ruler-y')
    this.aabb = null //屏幕包围盒
    this.area_scale = 0
    this.config = config //画笔配置
    this.active = true //画板激活状态
    this.mouse_aabb = [
      [0, 0],
      [0, 0],
    ] //鼠标包围盒
    this.schema = schema //原理图对象
    this.editCpLine = null //编辑中的原理图
    this.renderObjs = [] //渲染对象
    this.tools = [] //绘图工具
    this.re_render = false //重新渲染
    this.times = [] //计算fps
    this.flyLines = null //飞线
    this.preAddObjs = []
    this.checkedResBoxs = [] //检测结果
    this.clock = 0
    this.drawFrameLoop = this.drawFrame.bind(this) //绘制循环
    this.surface.requestAnimationFrame(this.drawFrameLoop) //绑定动画事件

    this.updateAABB()
    // this.aStartDebug = null
    // this.aStar = new AStar()
    // this.aStar.graph.setBox([
    //   [0, 0],
    //   [100, 100],
    // ])
    // this.aStar.graph.add_obstacle([[40, 40]])
    // let points = []
    // points = this.aStar.search_one([30, 30], [55, 55])
    // this.pathTest = new CanvasKit.Path()
    // if (points.length) {

    //   let len = points.length
    //   for (let i = 0; i < len; i++) {
    //     let p = points[i]
    //     if (!i) {
    //       this.pathTest.moveTo(p[0], p[1])
    //     } else {
    //       this.pathTest.lineTo(p[0], p[1])
    //     }
    //   }
    // }
  }

  updateCanvas() {
    this.ELEM = this.containerId + '_canvas'
    this.canvas = document.getElementById(this.ELEM) //获取canvas对象
    this.width = this.canvas.width //宽高
    this.height = this.canvas.height
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    this.surface.requestAnimationFrame(this.drawFrameLoop)
    this.render()
  }
  //更新画板屏幕包围盒
  updateAABB() {
    this.aabb = getBoardAABB(this.myaxis, this)
    this.area_scale = this.myaxis.scale * this.myaxis.scale
  }

  //四叉树剔除
  updateRenderObjs(tree) {
    this.renderObjs = tree.needRenderObjs(this.aabb)
  }
  //重置状态
  resetStatus() {}

  //添加工具
  addTool(tool) {
    if (Array.isArray(tool)) {
      this.tools.push(...tool)
    } else {
      this.tools.push(objs)
    }
  }

  //清除工具
  clearTools() {
    for (let i = 0; i < this.tools.length; i++) {
      this.tools[i].delete()
    }
    this.tools = []
  }

  //更新画布宽高
  updateSize() {
    this.surface.flush()
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    this.canvas = document.getElementById(this.ELEM) // 获取canvas对象
    this.width = this.canvas.width // 宽高
    this.height = this.canvas.height
  }

  //计算fps
  calFps() {
    const start = window.performance.now()
    while (this.times.length > 0 && this.times[0] <= start - 1000) {
      this.times.shift()
    }
    this.times.push(start)
  }

  //绘制标尺
  renderRulers() {
    this.drawRulerX()
    this.drawRulerY()
  }

  //绘制X标尺
  drawRulerX() {
    if (!this.canvasRulerX) {
      return
    }
    const ctx = this.canvasRulerX.getContext('2d')
    const rulerX = this.myaxis.rulerX
    ctx.clearRect(0, 0, rulerX.width, rulerX.height)

    ctx.save()
    ctx.strokeStyle = '#ff0000'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.moveTo(this.myaxis.pos_x, rulerX.height)
    ctx.lineTo(this.myaxis.pos_x, rulerX.height - 40)
    ctx.stroke()
    ctx.restore()

    ctx.fillStyle = '#77787b'
    ctx.strokeStyle = '#77787b'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.font = '12px Arial'
    const lines = rulerX.lines

    for (let i = 0; i < lines.paths.length; i++) {
      ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
      ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
    }
    for (let i = 0; i < lines.texts.length; i++) {
      ctx.fillText(lines.texts[i].label, lines.texts[i].x, lines.texts[i].y + 18)
    }
    ctx.stroke()
  }

  //绘制Y标尺
  drawRulerY() {
    if (!this.canvasRulerY) {
      return
    }
    const ctx = this.canvasRulerY.getContext('2d')
    const rulerY = this.myaxis.rulerY
    ctx.clearRect(0, 0, rulerY.width, rulerY.height)

    ctx.save()
    ctx.strokeStyle = '#ff0000'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.moveTo(rulerY.width - 40, this.myaxis.pos_y)
    ctx.lineTo(rulerY.width, this.myaxis.pos_y)
    ctx.stroke()
    ctx.restore()

    ctx.fillStyle = '#77787b'
    ctx.strokeStyle = '#77787b'
    ctx.font = '12px Arial'
    ctx.lineWidth = 1
    ctx.beginPath()
    const lines = rulerY.lines
    for (let i = 0; i < lines.paths.length; i++) {
      ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
      ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
    }
    ctx.stroke()

    for (let i = 0; i < lines.texts.length; i++) {
      ctx.save()
      ctx.translate(5, lines.texts[i].lineY + 2) //对应刻度尺y坐标
      ctx.rotate(lines.texts[i].rotate)
      ctx.fillText(lines.texts[i].label, 0, 0)
      ctx.restore()
    }
  }

  render() {
    if (!this.active) return
    this.config.pick_dist = 20 / this.myaxis.scale
    this.re_render = true
    this.surface.requestAnimationFrame(this.drawFrameLoop)
  }

  // 绘制frame
  drawFrame(canvas) {
    // if (this.re_render) {
    // console.time('drawContent')
    // this.calFps()
    canvas.drawColor(CanvasKit.BLACK)
    canvas.save()
    this.drawAxisByShader(canvas) //绘制背景点阵
    canvas.concat(this.myaxis.matrix) //平移缩放画板
    // this.drawAStart(canvas)
    this.drawContent(canvas) //绘制原理图内容
    this.drawPreAddObjs(canvas)
    this.drawChecekdRes(canvas)
    this.drawTools(canvas) //绘制辅助工具
    canvas.restore()
    this.drawMouse(canvas)
    // console.timeEnd('drawContent')
    // this.re_render = false
    // }

    // this.surface.requestAnimationFrame(this.drawFrameLoop)
  }
  drawPreAddObjs(canvas) {
    let symbol_text_off_y = this.config.showConfig.symbolText ? 6 : 0
    for (let i = 0; i < this.preAddObjs.length; i++) {
      const obj = this.preAddObjs[i]
      const type = obj.$$?.ptrType.name
      if (type == 'CpLine*') {
        this.drawCpLine(canvas, obj)
      } else if (type == 'SymbolIns*') {
        this.drawSymbolIns(canvas, obj, symbol_text_off_y)
      } else if (type == 'Pin*') {
        this.drawPin(canvas, obj)
      } else if (type == 'Label*') {
        this.drawLabel(canvas, obj)
      }
    }
  }

  //绘制检测框
  drawChecekdRes(canvas) {
    if (this.checkedResBoxs.length) {
      this.checkedResBoxs.forEach(aabb => {
        this.drawRect(canvas, aabb, this.config.checked_res_paint)
      })
    }
  }

  //绘制原理图内容
  drawContent(canvas) {
    let symbol_text_off_y = this.config.showConfig.symbolText ? 6 : 0
    //绘制耦合线
    let cp_lines_size = this.schema.cp_lines.length
    let cp_lines = this.schema.cp_lines
    for (let i = 0; i < cp_lines_size; i++) {
      const cp_line = cp_lines[i]
      this.drawCpLine(canvas, cp_line)
    }

    //绘制符号引用
    let symbols_size = this.schema.symbol_ins.length
    let symbols = this.schema.symbol_ins

    // console.time('loop symbols')
    for (let i = 0; i < symbols_size; i++) {
      let symbolIns = symbols[i]
      this.drawSymbolIns(canvas, symbolIns, symbol_text_off_y)
    }
    // console.timeEnd('loop symbols')
    //封装符号绘制
    let cap_symbols_size = this.schema.capsymbol_ins.length
    let cap_symbols = this.schema.capsymbol_ins
    for (let i = 0; i < cap_symbols_size; i++) {
      let symbolIns = cap_symbols[i]
      this.drawSymbolIns(canvas, symbolIns, symbol_text_off_y)
    }
    let pin_size = this.schema.pins.length
    let pins = this.schema.pins
    for (let i = 0; i < pin_size; i++) {
      let pinIns = pins[i]
      this.drawPin(canvas, pinIns)
    }

    //绘制标签
    let labels_size = this.schema.labels.length
    let labels = this.schema.labels
    for (let i = 0; i < labels_size; i++) {
      let label = labels[i]
      if (!checkInsideStage(label.bounding_box, this.aabb)) continue
      if (!this.config.showConfig.netName && label.belong_to?.$$?.ptrType.name === 'CpLine*') continue
      if (!this.config.showConfig.normalLabel && (label.belong_to == null || label.belong_to?.$$?.ptrType.name == 'Schema*')) continue
      this.drawLabel(canvas, label)
    }
    //绘制编辑中的耦合线
    if (this.editCpLine) {
      canvas.drawPoints(CanvasKit.PointMode.Points, this.editCpLine.line.points.flat(), this.config.edit_point_paint)
      canvas.drawPoints(CanvasKit.PointMode.Points, this.editCpLine.line.js_obj.midPoints.flat(), this.config.mid_point_paint)
    }

    //绘制飞线
    if (this.flyLines) {
      canvas.drawPath(this.flyLines, this.config.fly_lines_paint)
    }
  }

  drawCpLine(canvas, cp_line) {
    //线
    let color = cp_line.js_obj.rgb
    if (cp_line.js_obj.highlight) {
      color = cp_line.js_obj.highlight_rgb
    }
    let pt = this.getPaintByStatus(cp_line, 'cp_paint', color)
    canvas.drawPath(cp_line.js_obj.path, pt)
    //构成线的点
    // let points = []
    // let p_pt = this.getPaintByStatus(cp_line, 'cp_point_paint')

    // cp_line.lines.forEach(line => points.push(...line.points))
    // canvas.drawPoints(CanvasKit.PointMode.Points, points.flat(), p_pt)

    // canvas.drawPoints(CanvasKit.PointMode.Points, cp_line.line.points.flat(), p_pt)
  }

  drawSymbolIns(canvas, symbolIns, symbol_text_off_y) {
    if (!checkInsideStage(symbolIns.bounding_box, this.aabb)) return
    let params_text_off_y = 4
    let exist_params = false
    //符号参数
    if (this.config.showConfig.parameterText) {
      let params = getParamsText(symbolIns.symbol)
      params.forEach((obj, i) => {
        exist_params = true
        if (i) {
          params_text_off_y += 6
        }
        this.drawParamterText(canvas, symbolIns, obj.name, params_text_off_y)
      })
    }
    let exist_ins_text = false
    let ins_name_off_y = params_text_off_y
    //符号实例名
    if (symbolIns.ins_name !== '' && this.config.showConfig.instanceText) {
      if (exist_params) {
        ins_name_off_y = params_text_off_y + 6
      }
      exist_ins_text = true
      this.drawInsText(canvas, symbolIns, symbolIns.ins_name, ins_name_off_y)
    }

    //符号名
    let pt = this.getPaintByStatus(symbolIns, 'symbol_paint')
    if (this.config.showConfig.symbolText && symbolIns.symbol) {
      let off = 0
      if (exist_params || exist_ins_text) {
        off = symbol_text_off_y + ins_name_off_y
      }
      this.drawSymbolText(canvas, symbolIns, symbolIns.symbol.name, off)
    }
    canvas.drawPath(symbolIns.js_obj.path, pt)
    let ports_size = symbolIns.ports.length
    let ports = symbolIns.ports
    let port_pt = this.getPaintByStatus(symbolIns, 'port_paint')
    for (let j = 0; j < ports_size; j++) {
      let port = ports[j]
      //端口
      this.drawPort(canvas, port.pos, port_pt)
      //端口名
      if (this.config.showConfig.portName) {
        this.drawPortName(canvas, symbolIns, port)
      }
    }
    //包围盒debug
    // this.drawRect(canvas, symbolIns.bounding_box, this.config.select_box_paint)
  }

  drawPin(canvas, pinIns) {
    //绘制PIN name
    // this.drawText(canvas, pinIns, pinIns.name)
    if (this.config.showConfig.pinName) {
      // this.drawText(canvas, pinIns, pinIns.name.text)
      this.drawPinText(canvas, pinIns.name)
    }
    if (!checkInsideStage(pinIns.bounding_box, this.aabb)) return
    let pt = this.getPaintByStatus(pinIns, 'pin_paint')
    canvas.drawPath(pinIns.js_obj.path, pt)

    let port_pt = this.getPaintByStatus(pinIns, 'port_paint')
    for (let j = 0; j < pinIns.ports.length; j++) {
      let port = pinIns.ports[j]
      this.drawPort(canvas, port.pos, port_pt)
    }
    //debug
    // this.drawRect(canvas, pinIns.bounding_box, this.config.select_box_paint)
  }
  //渲染debug
  renderDebug() {
    //绘制吸附线 debug
    // this.adsorptionLinesPath.forEach(path => {
    //   canvas.drawPath(path, this.pre_aligment_line_checked_paint)
    // })
    //绘制吸附点
    //canvas.drawPoints(CanvasKit.PointMode.Points, this.adsorptionPoints, this.pre_aligment_point_checked_paint)
    //图形集合包围盒debug
    // this.drawRect(canvas, this.graphicsAABB)
    //选中图形集合包围盒debug
    //this.drawRect(canvas, this.checkedGraphicsAABB)
  }

  //绘制十字光标
  drawMouse(canvas) {
    let path = new CanvasKit.Path()
    path.moveTo(this.myaxis.mouse_pixel_x, 0)
    path.lineTo(this.myaxis.mouse_pixel_x, this.height)
    path.moveTo(0, this.myaxis.mouse_pixel_y)
    path.lineTo(this.width, this.myaxis.mouse_pixel_y)
    canvas.drawPath(path, this.config.mouse_cross_paint)
    path.delete()
  }

  axisLoopOffset(v) {
    let coefficient = 10
    if (this.myaxis.step * this.config.gridSize > 100) {
      coefficient = 100
    }
    let loopWidth = coefficient * this.myaxis.step //坐标系以10 * 刻度尺最小单位的屏幕像素宽度无限循环

    if (Math.abs(v) > loopWidth) {
      v = v % loopWidth
    }
    return v + loopWidth
  }

  //绘制背景网格
  drawAxisByShader(canvas) {
    let backgroundColor = hexToRgb(this.config.colors.backgroundColor).map(v => v / 255)
    let gridColor = hexToRgb(this.config.colors.gridColor).map(v => v / 255)
    backgroundColor.push(1)
    gridColor.push(1)
    if (this.myaxis.grid_show) {
      const shader = this.config.axisFact.makeShader([
        this.myaxis.step * this.config.gridSize,
        this.myaxis.dx,
        this.myaxis.dy,
        this.axisLoopOffset(this.myaxis.dx),
        this.axisLoopOffset(this.myaxis.dy),
        ...backgroundColor,
        ...gridColor,
        ...gridColor,
        this.width,
        this.height,
        this.config.showGrid,
        this.config.showXY,
      ])
      this.config.axisPaint.setShader(shader)
      const w = this.width
      canvas.drawRect(CanvasKit.LTRBRect(0, this.height, this.width, 0), this.config.axisPaint)
      shader.delete()
    }
    if (this.checkedResBoxs.length) {
      let checkedResColor = hexToRgb(this.config.colors.checked_res_color).map(v => v / 255)
      let alpha = Math.sin(this.clock * 0.5)
      this.clock += 0.1
      this.config.checked_res_paint.setColor(CanvasKit.Color4f(checkedResColor[0], checkedResColor[1], checkedResColor[2], alpha))
    }
  }

  //绘制辅助工具
  drawTools(canvas) {
    //鼠标包围盒
    // this.drawRect(canvas, this.mouse_aabb, this.config.mouse_box_paint)
    //遍历工具在画布绘制 鼠标选择框 自适应框
    for (let i = 0; i < this.tools.length; i++) {
      canvas.drawPath(this.tools[i].path, this.config.select_box_paint)
    }
  }

  //绘制点坐标 debug
  drawPoint(canvas, p, scale) {
    const paint = new CanvasKit.Paint() //设置画笔
    const pointRadius = 5 / scale //点半径
    paint.setAntiAlias(this.config.antiAlias)
    paint.setColor(CanvasKit.YELLOW)
    canvas.drawCircle(p.x, p.y, pointRadius, paint)
    paint.delete()
  }

  //绘制矩形
  drawRect(canvas, aabb, paint) {
    if (!aabb) return
    canvas.drawRect(CanvasKit.LTRBRect(aabb[0][0], aabb[0][1], aabb[1][0], aabb[1][1]), paint)
  }

  //绘制旋转角度
  drawRotAngle(canvas) {
    if (this.rotat_center) {
      let point = this.rotat_center
      let x = point[0]
      let y = point[1]
      // canvas.drawPoints(CanvasKit.PointMode.Points, [x, y], this.point_checked_paint)
      let pt = this.pre_aligment_line_checked_paint.copy()
      pt.setStyle(CanvasKit.PaintStyle.Fill)

      let s = 1 / this.myaxis.scale
      canvas.save()
      canvas.translate(-x, y)
      canvas.scale(1 * s, -1 * s)
      canvas.translate(x / s, -y)
      canvas.drawText(`${this.rotation.toFixed(2)}°`, x / s - 40, y + 10, pt, this.config.label_font)
      canvas.restore()
      pt.delete()
    }
  }

  //根据选中状态获取对应paint
  getPaintByStatus(obj, pt_name, color) {
    let pt = this.config[pt_name]
    if (obj.js_obj.checked) {
      pt = this.config[(pt_name += '_checked')]
    } else if (obj.js_obj.preChecked) {
      pt = this.config[(pt_name += '_pre_checked')]
    } else if (color) {
      pt.setColor(CanvasKit.Color4f(color[0], color[1], color[2], 1))
    }
    if (obj.js_obj.preCut) {
      pt = pt.copy()
      pt.setAlphaf(0.5)
      setTimeout(() => pt.delete())
    }
    return pt
  }

  //绘制端口名
  drawPortName(canvas, inst, port) {
    let obj = port
    let s = 1
    let x = obj.pos[0]
    let y = obj.pos[1]
    let angle = 0
    let text = obj.text
    let size = 4 //obj.size
    let font_size = size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = this.config.default_anchor //obj.anchor ? obj.anchor : this.config.default_anchor
    let tran = anchorTran(font_anchor, text.length * font_size * 0.6, font_size)
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(inst, 'port_name_paint')
    font.setSize(font_size)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  //绘制端口
  drawPort(canvas, pos, pt) {
    canvas.drawCircle(pos[0], pos[1], this.config.port_radius, pt) //绘制圆
    // canvas.drawPoints(CanvasKit.PointMode.Points, pos, this.config.port_paint)
  }
  //绘制标签，端口文本
  drawLabel(canvas, obj) {
    //包围盒debug
    // if (obj.bounding_box.length) {
    //   this.drawRect(canvas, obj.bounding_box, this.config.select_box_paint)
    // }
    // if (obj.js_obj.path) {
    //   canvas.drawPath(obj.js_obj.path, this.config.select_box_paint)
    // }
    let s = 1
    let x = obj.pos[0]
    let y = obj.pos[1]
    let angle = obj.angle
    let text = obj.text
    let size = obj.size
    let font_size = size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = obj.anchor ? obj.anchor : this.config.default_anchor
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'label_paint', obj.js_obj.rgb)
    font.setSize(font_size)
    let tran = anchorTran(font_anchor, obj.js_obj.w, obj.js_obj.h)

    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  //绘制实例名称
  drawText(canvas, obj, text) {
    let s = 1
    let x = obj.bounding_box[1][0] //obj.pos[0]
    let y = obj.bounding_box[1][1] //obj.pos[1]
    let angle = 0
    // let text = obj[key]
    let size = obj.size
    let font_size = size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = obj.anchor ? obj.anchor : this.config.default_anchor
    let tran = anchorTran(font_anchor, text.length * font_size * 0.6, font_size)
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'label_paint')
    font.setSize(font_size)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  //绘制符号名
  drawSymbolText(canvas, obj, text, symbol_text_off_y) {
    let s = 1
    let x = obj.bounding_box[1][0] //obj.pos[0]
    let y = obj.bounding_box[1][1] + symbol_text_off_y //obj.pos[1]
    let angle = 0
    // let text = obj[key]
    let size = obj.size
    let font_size = 6 //size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = this.config.default_anchor
    let tran = anchorTran(font_anchor, text.length * font_size * 0.6, font_size)
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'symbol_name_paint')
    font.setSize(font_size)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  //绘制实例名称
  drawInsText(canvas, obj, text, text_off_y) {
    let s = 1
    let x = obj.bounding_box[1][0] //obj.pos[0]
    let y = obj.bounding_box[1][1] + text_off_y //obj.pos[1]
    let angle = 0
    // let text = obj[key]
    let size = obj.size
    let font_size = 6 //size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = this.config.default_anchor
    let tran = anchorTran(font_anchor, text.length * font_size * 0.6, font_size)
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'symbol_ins_name_paint')
    font.setSize(font_size)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  //绘制参数
  drawParamterText(canvas, obj, text, text_off_y) {
    let s = 1
    let x = obj.bounding_box[1][0] //obj.pos[0]
    let y = obj.bounding_box[1][1] + text_off_y //obj.pos[1]
    let angle = 0
    // let text = obj[key]
    let size = obj.size
    let font_size = 6 //size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = this.config.default_anchor
    let tran = anchorTran(font_anchor, text.length * font_size * 0.6, font_size)
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'symbol_parameter_paint')
    font.setSize(font_size)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }
  //绘制引脚名称
  drawPinText(canvas, obj) {
    let s = 1
    let x = obj.pos[0]
    let y = obj.pos[1]
    let angle = obj.angle
    let text = obj.text
    let size = obj.size
    let font_size = size ? size : this.config.default_font_size
    let font_type = obj.font ? obj.font : this.config.default_font
    let font_anchor = obj.anchor ? obj.anchor : this.config.default_anchor
    let font = FONT_FACES[font_type]
    let pt = this.getPaintByStatus(obj, 'pin_name_paint', obj.js_obj.rgb)
    font.setSize(font_size)
    let tran = anchorTran(font_anchor, obj.js_obj.w, obj.js_obj.h)

    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    canvas.drawText(text, x / s - tran.x, y + tran.y, pt, font)
    canvas.restore()
  }

  drawAStart(canvas) {
    //测试自动避障
    // if (this.aStartDebug) {
    //   // let pathPaint = this.config.select_box_paint.copy()
    //   // pathPaint.setColor(CanvasKit.GREEN)
    //   // canvas.drawPath(this.aStartDebug.path, pathPaint)
    //   // pathPaint.delete()
    //   this.aStartDebug.nodeSet.forEach(nodes => {
    //     nodes.forEach(node => {
    //       let aabb = node.aabb
    //       if (node.isObstacle) {
    //         this.drawRect(canvas, aabb, this.config.label_paint_checked)
    //       } else if (node.cal) {
    //         this.drawRect(canvas, aabb, this.config.cp_point_paint)
    //       } else {
    //         this.drawRect(canvas, aabb, this.config.select_box_paint)
    //       }
    //     })
    //   })
    //   // canvas.drawPoints(CanvasKit.PointMode.Points, this.aStartDebug.start.concat(this.aStartDebug.end), this.config.cp_point_paint)
    // }
    // let map = this.aStar.graph.map
    // let step = this.aStar.graph.step
    // if (map) {
    //   map.forEach((row, i) =>
    //     row.forEach((grid, j) => {
    //       let aabb = [
    //         [j * step, i * step],
    //         [j * step + step, i * step + step],
    //       ]
    //       if (!grid) {
    //         this.drawRect(canvas, aabb, this.config.select_box_paint)
    //       } else {
    //         this.drawRect(canvas, aabb, this.config.label_paint_checked)
    //       }
    //     })
    //   )
    // }
    // let pathPaint = this.config.select_box_paint.copy()
    // pathPaint.setColor(CanvasKit.GREEN)
    // canvas.drawPath(this.pathTest, pathPaint)
    // pathPaint.delete()
  }
}
