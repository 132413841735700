import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    //文件列表相关数据
    currentNode: {}, //当前选中节点
    activeNode: {}, //当前画板对应的节点
    targetNodeInfo: {}, //通过cellId获取到的单元节点的相关信息

    positionX: '',
    positionY: '',
    scale: 1,
    openedTab: [], //打开的页面
    closeTab: [],
    drawIndex: 0, //画板工具索引
    activeTab: '',
    activeProject: 0, //激活的项目
    userMqttSN: '', //用户SN,
    username: '', //用户名
    routeFor: '', //路由
    fileInfo: '', //双击cell传递的file数据
    cellInfo: '', //双击cell传递的cell数据
    proInfo: '', //双击cell传递的项目数据
    topInfo: '', //双击cell传递的用户/团队数据
    // saveStateArr: [], //保存状态数组

    treeNameList: [], // 文件列表总节点列表
    teamNameList: [], //团队列表
    layerDataTable: [], //图层列表
    layerDatas: [], //暂时定义
    func: '', //当前工具
    adsorptionState: false, //吸附状态
    precision: null, //吸附精度
    boardPropertyState: null, //画板属性状态
    toolBigLibrary: null, //大版图工具
    toolBigFileLayers: null, //大版图图层
    toolBigCellLayers: null, //大版图单元图层

    schemaBoardSettingsArr: [], //原理图画板设置
    currentSchemaBoardSettings: {}, //当前项目对应的画板设置
    schemaCheckSettingsArr: [], //原理图检查设置
    currentSchemaCheckSettings: {}, //当前项目对应的检查设置

    //原理图数据映射表
    schemaFileTable: [],

    cellTable: [],

    fileLayerDataTable: [], //文件列表图层列表
    fileLayerListTable: [], //文件图层列表
    cellLayerList: [], //当前单元图层过滤出来的列表
    fileLayerList: [], //当前单元所在版图图层
    selectedLayer: {}, //当前选中的图层
    //ws
    permissions: false,
    url: '',
    //ws参数
    path: '',
    ws: null, //建立的连接
    lockReconnect: false, //是否真正建立连接
    timeout: 58 * 1000, //58秒一次心跳
    timeoutObj: null, //心跳心跳倒计时
    serverTimeoutObj: null, //心跳倒计时
    timeoutnum: null, //断开 重连倒计时
    activeCell: {}, //当前激活的单元

    //pdk
    DRFTable: [], //DRF表
    TFTable: [], //TF表

    nodeById: {}, //通过id得到的node节点
  },
  getters: {
    // 参数列表state指的是state数据

    getDrawIndex(state) {
      //工具索引
      return state.drawIndex
    },
    getAllObj(state) {
      return state.allObj
    },
    //用户SN
    getUserMqttSn(state) {
      return state.userMqttSN
    },
    getUserName(state) {
      return state.username
    },
    getRoute(state) {
      return state.routeFor
    },
    getCurrentNode(state) {
      return state.currentNode
    },

    getCellTable(state) {
      return state.cellTable
    },
  },
  mutations: {
    RCNodeInfo(state, data) {
      state.currentNode = data
    },

    //设置文件树总父节点名列表
    setTeamNameList(state, data) {
      state.teamNameList = data
    },

    //设置文件树总父节点名列表
    setTreeNameList(state, data) {
      state.treeNameList = data
    },

    setPositionX(state, x) {
      state.positionX = x
    },
    setPositionY(state, y) {
      state.positionY = y
    },
    setScale(state, scale) {
      state.scale = scale
    },
    setAllObj(state, allObj) {
      state.allObj = allObj
    },
    //添加
    addTab(state, params) {
      state.openedTab.push(params)
    },
    //激活状态
    activeTab(state, params) {
      state.activeTab = params
    },
    removeTab(state, routeArray) {
      state.closeTab = routeArray

      // let index = state.openedTab.indexOf(route)
      // state.openedTab.splice(index, 1)
    },

    //设置单元相关信息
    setCellInfo(state, params) {
      state.topInfo = params[0]
      state.proInfo = params[1]
      state.fileInfo = params[2]
      state.cellInfo = params[3]
    },
    setRouteFor(state, route) {
      state.routeFor = route
    },

    //清除单元相关信息
    clearCellInfo(state, params) {
      state.fileInfo = ''
      state.cellInfo = ''
      state.proInfo = ''
      state.topInfo = ''
    },

    // 更新数据
    updateOpenedTab(state, data) {
      state.openedTab.splice(0, state.openedTab.length)
      data.forEach(item => {
        state.openedTab.push(item)
      })
      // state.openedTab = JSON.parse(JSON.stringify(state.openedTab))
    },

    resetOpenedTab(state, data) {
      state.openedTab.splice(0, state.openedTab.length)
    },

    // 改变画图工具索引
    changeDrawIndex(state, drawData) {
      state.drawIndex = drawData
    },
    setUserName(state, name) {
      state.username = name
    },

    // //添加图层列表
    // addLayerDatas(state, data) {
    //   state.layerDataTable.push(JSON.parse(JSON.stringify(data)))
    // },

    // //修改图层列表数据
    // editLayerDatas(state, data) {
    //   state.layerDataTable[data.index].layerDatas = data.layerDatas
    // },

    // resetLayerDatas(state, data) {
    //   if (data) {
    //     state.layerDataTable.splice(0, state.layerDataTable.length)
    //   }
    // },

    //获取画图数据
    setAllDrawData(state, data) {
      state.allDrawData = data
    },

    //设置用户SN
    setUserMqttSn(state, data) {
      state.userMqttSN = data
    },
    //工具栏
    setFunc(state, func) {
      state.func = func
    },

    // //添加保存状态
    // setSaveState(state, fileId) {

    //   if (state.saveStateArr.some(item => item === fileId)) {
    //     return
    //   } else {
    //     state.saveStateArr.push(fileId)
    //   }
    // },

    // //删除保存状态
    // delSaveState(state, fileId) {
    //   let index = state.saveStateArr.findIndex(item => item === fileId)
    //   if (index !== -1) {
    //     state.saveStateArr.splice(index, 1)
    //   }
    // },

    //设置吸附状态
    setAdsorptionState(state, data) {
      state.adsorptionState = data
    },
    //设置吸附精度
    setPrecision(state, number) {
      state.precision = number
    },

    //工具栏画板属性状态
    setSidebarState(state, data) {
      state.boardPropertyState = data
    },
    // 工具大版图
    setToolBigLibrary(state, data) {
      state.toolBigLibrary = data
    },
    // 工具大版图cell图层
    setToolBigFileLayers(state, data) {
      state.toolBigFileLayers = data
    },
    // 工具大版图cell图层
    setToolBigCellLayers(state, data) {
      state.toolBigCellLayers = data
    },

    // 设置原理图画板
    setShemaBoardSettings(state, data) {
      let index = state.schemaBoardSettingsArr.findIndex(item => item.projectId === data.projectId)
      if (index === -1) {
        state.schemaBoardSettingsArr.push(data)
      } else {
        state.schemaBoardSettingsArr[index].schematicBoardSettings = data.schematicBoardSettings
      }
    },

    //获取原理图画板对应设置
    getShemaBoardSettings(state, id) {
      let data = state.schemaBoardSettingsArr.filter(item => item.projectId === id)[0]
      state.currentSchemaBoardSettings = data.schematicBoardSettings
    },

    //设置项目检查设置
    setSchemaCheckSettings(state, data) {
      let index = state.schemaCheckSettingsArr.findIndex(item => item.projectId === data.projectId)
      if (index === -1) {
        state.schemaCheckSettingsArr.push(data)
      } else {
        state.schemaCheckSettingsArr[index].schematicCheckSettings = data.schematicCheckSettings
      }
    },

    //获取项目检查对应设置
    getShemaCheckSettings(state, id) {
      let data = state.schemaCheckSettingsArr.filter(item => item.projectId === id)[0]
      state.currentSchemaCheckSettings = data.schematicCheckSettings
    },

    //向映射表中存入数据
    setDataToschemaFileTable(state, data) {
      state.schemaFileTable.push(data)
    },

    //删除映射表中数据
    delDataFromschemaFileTable(state, data) {
      let index = state.schemaFileTable.findIndex(item => item.fileId === data)
      state.schemaFileTable.splice(index, 1)
    },

    //修改映射表数据
    changeDataToSchemaFileTable(state, data) {
      let index = state.schemaFileTable.findIndex(item => item.fileId === data.fileId)

      if (index !== -1) {
        state.schemaFileTable[index].fileData = data.fileData
      }
    },

    //2.4
    //存入单元数据表
    setDataToCellTable(state, data) {
      let index = state.cellTable.findIndex(item => item.cellId === data.cellId)
      if (index === -1) {
        state.cellTable.push(data)
      } else {
        state.cellTable[index].cell = data.cell
      }
    },

    //从单元数据表删除
    removeFromCellTable(state, data) {
      let index = state.cellTable.findIndex(item => item.cellId === data)
      if (index !== -1) {
        state.cellTable.splice(index, 1)
      }
    },

    //从单元数据表删除文件包括的数据
    removeFileFromCellTable(state, fileId) {
      const fileCells = state.cellTable.filter(item => item.fileId == fileId)
      fileCells.forEach(cellData => {
        if (cellData.cell?.js_obj?.WS) {
          cellData.cell?.js_obj.WS.remove()
        }
      })
      state.cellTable = state.cellTable.filter(item => item.fileId !== fileId)
    },

    //初始化单元数据表
    resetCellTable(state, data) {
      if (data) {
        state.cellTable.splice(0, state.cellTable.length)
      }
    },

    //设置当前画板激活单元
    setActiveCell(state, data) {
      state.activeCell = data
    },

    //文件图层列表存储
    setFileLayerList(state, data) {
      let index = state.fileLayerListTable.findIndex(item => item.fileId === data.fileId)

      if (index === -1) {
        state.fileLayerListTable.push(data)
      } else {
        state.fileLayerListTable[index].fileLayerDatas = data.fileLayerDatas
        state.fileLayerListTable[index].layerState = data.layerState
      }
    },

    //文件图层列表删除
    removeFileLayerList(state, data) {
      let index = state.fileLayerListTable.findIndex(item => item.fileId === data)
      if (index !== -1) {
        state.fileLayerListTable.splice(index, 1)
      }
    },

    //初始化图层数据表
    resetFileLayerList(state, data) {
      if (data) {
        state.fileLayerListTable.splice(0, state.fileLayerListTable.length)
      }
    },

    //设置当前单元图层
    setCurrentCellLayer(state, data) {
      state.cellLayerList = data
    },

    //设置当前文件图层
    setCurrentFileLayer(state, data) {
      state.fileLayerList = data
    },

    //设置当前选中的图层
    setSelectedLayer(state, data) {
      state.selectedLayer = data
    },

    setActiveNode(state, node) {
      state.activeNode = node
    },

    setTargetNodeInfo(state, data) {
      state.targetNodeInfo = data
    },

    //设置pdk的drf文件
    setPDK_DRF(state, data) {
      console.log(data);
      let index = state.DRFTable.findIndex(item => item.projectId === data.projectId)
      if (index === -1) {
        state.DRFTable.push(data)
      } else {
        state.DRFTable[index].drf = data.drf
      }
    },

    //设置pdk的tf文件
    setPDK_TF(state, data) {
      let index = state.TFTable.findIndex(item => item.projectId === data.projectId)
      if (index === -1) {
        state.TFTable.push(data)
      } else {
        state.TFTable[index].tf = data.tf
      }
    },

    //设置通过id拿到的node
    setNodeById(state, data) {
      state.nodeById = data
    },
  },
  actions: {
    setProjectName({ commit, state }, name) {
      // 跟后台打交道
      // 调用mutaions里面的方法
      commit('setProjectName', name)
    },
    setFileTreeData({ commit, state }, ary) {
      commit('setFileTreeData', ary)
    },
    setFolderData({ commit, state }, ary) {
      commit('setFolderData', ary)
    },
    // 新建项目添加
    addProjectList({ commit, state }, addData) {
      commit('addProjectList', addData)
    },
    setallObjData({ commit, state }, n) {
      commit('setAllObj', allData)
    },
    changeDevice({ commit, state }, deData) {
      commit('changeDevice', deData)
    },
    changeFileType({ commit, state }, type) {
      commit('changeFileType', type)
    },
    changeDrawIndex({ commit, state }, type) {
      commit('changeDrawIndex', type)
    },
    // 删除项目
    removeProject({ commit, state }, reIndex) {
      commit('removeProject', reIndex)
    },
    // 删除项目
    removeFolderData({ commit, state }, reIndex) {
      commit('removeFolderData', reIndex)
    },
    // 设置用户名
    setUserName({ commit, state }, name) {
      commit('setUserName', name)
    },
    // 设置路由
    setRouteFor({ commit, state }, route) {
      commit('setRouteFor', route)
    },
  },
  modules: {},
})
