import { distanceToLineSegment } from '../graphic_f32/point-to-line-segment'
import { Transform } from '../graphic_f32/qeda-graphics'
import { deepClone } from '../utils'
import { FillShader } from './qeda-schema-stage'

export const QedaGraphicType = {
  PATH: 0,
  RECT: 1,
  POLYGON: 2,
  CIRCLE: 3,
  LABEL: 4,
  KEYPOINT: 5,
  TEXT: 6,
  RULER: 7,
}

let QEDA_SCH_ID = 0

export class RectGraphic {
  constructor() {
    this.shape = [] //图形顶点绘制数据
    this.path = new CanvasKit.Path()
  }

  updatePath() {
    this.path.reset()
    this.path.setIsVolatile(false)
    const length = this.shape.length
    for (let i = 0; i < length; i++) {
      let p = this.shape[i]
      if (!i) {
        this.path.moveTo(p[0], p[1])
      } else {
        this.path.lineTo(p[0], p[1])
      }
    }
    this.path.close()
  }

  //正在画板绘制图形
  drawPoint(point) {
    let start = STARTPOS
    const rect = new QGdstk.rectangle(start, [point.x, point.y], 0, 0)
    this.shape = rect.get_points()
    this.updatePath()
  }

  delete() {
    this.shape = null
    this.path.delete()
  }

  getAABB() {
    let aabb = null
    if (this.shape.length) {
      let data = new QGdstk.Polygon(this.shape)
      aabb = data.bounding_box()
    }
    return aabb
  }
}

export class QedaSchemaGraphic extends Transform {
  constructor() {
    super()
    QEDA_SCH_ID += 1
    this.id = QEDA_SCH_ID
    this.type //图形类型
    this.shape = [] //图形顶点绘制数据
    this.checked_points_index = [] //被选中顶点下标
    this.checked_paths = [] //选中边 CanvasKit.Path
    this.checked_points_mouse_offset = [] //缓存选择点与鼠标偏移量
    this.checked_lines = [] //选中线
    this.path = new CanvasKit.Path()
    this.mouse_offset = [0, 0] //和鼠标的偏移量
    this.slopes = [] //线斜率
    this.gds_data_local //gds本地数据
    this.gds_data_global //gds全局数据
  }

  //更新顶点信息
  updateShape() {
    this.updateGdsGlobalData()
    this.shape = this.gds_data_global.get_points()
    this.updatePath()
  }

  //更新全局坐标点
  updateGdsGlobalData() {
    this.gds_data_global = this.gds_data_local.copy()
    this.rotation = Math.ceil(this.rotation * 100) / 100
    let rot = (this.rotation * Math.PI) / 180 //角度->弧度
    this.gds_data_global.scale(this._scale[0], this._scale[1]) //缩放变换
    this.gds_data_global.rotate(rot) //旋转 弧度
    this.gds_data_global.translate(this.position) //平移
    let arr = this.gds_data_global.get_points()
    //坐标保留三位有效数字
    for (let index = 0; index < arr.length; index++) {
      arr[index] = fixPosNumber3(arr[index])
    }
    this.gds_data_global.points = arr
  }

  //更新轮廓
  updatePath() {
    this.path.rewind()
    const length = this.shape.length
    for (let i = 0; i < length; i++) {
      let p = this.shape[i]
      if (!i) {
        this.path.moveTo(p[0], p[1])
      } else {
        this.path.lineTo(p[0], p[1])
      }
    }
    this.path.close()
  }

  //更新选中边Path
  updateCheckedPaths() {
    this.checked_paths = []
    let length = this.checked_points_index.length
    let last = length - 1
    //更新选中线
    if (length < 2) return //小于一条边退出
    let sp = this.shape
    const first_checked = this.checked_points_index[0] === 0
    const first_p = sp[0]
    const last_checked = this.checked_points_index[length - 1] === sp.length - 1
    let index_old = -2
    let path = new CanvasKit.Path()
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      if (index != index_old + 1) {
        //断点重置
        //const next_index = this.checked_points_index[i+1]
        //if(index + 1 !== next_index) continue //少于两个点不需要生成path
        path = new CanvasKit.Path()
        this.checked_paths.push(path)
        path.moveTo(p[0], p[1])
      } else {
        path.lineTo(p[0], p[1])
      }
      index_old = index
    }
    if (this.type !== QedaGraphicType.PATH) {
      if (last_checked && first_checked) {
        //判断首位相连
        this.checked_paths[this.checked_paths.length - 1].lineTo(first_p[0], first_p[1])
      }
    }
  }

  //更新边斜率
  updateSlopes() {
    let sp = this.shape
    this.slopes = []
    const length = sp.length
    if (!length) return
    for (let i = 0; i < length - 1; i++) {
      this.slopes.push(getSlope(sp[i], sp[i + 1]))
    }
    if (this.type !== QedaGraphicType.PATH) {
      this.slopes.push(getSlope(sp[0], sp[sp.length - 1]))
    }
  }

  //拉伸图形后检查图形合法 自相交重置
  stretchConfirm() {
    const flag = this.rotation !== 0 || this._scale[0] !== 1 || this._scale[1] !== 1
    let new_points = this.shape
    if (flag) {
      //经过旋转缩放变换 重置本地坐标
      this.resetTransform()
    }
    this.setPoints(new_points)
    this.updateSlopes()
  }

  //计算图形和鼠标之间的偏移量
  updateMouseOffset(mouse_pos) {
    this.mouse_offset = [this.position[0] - mouse_pos.x, this.position[1] - mouse_pos.y]
  }

  //选择点和鼠标之间的偏移量
  updatePointsMouseOffset(mouse_position) {
    if (!mouse_position) {
      mouse_position = AXIS.mouse_point
    }
    let sp = this.shape
    this.checked_points_mouse_offset = []
    const length = this.checked_points_index.length
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      this.checked_points_mouse_offset.push({
        x: p[0] - mouse_position.x,
        y: p[1] - mouse_position.y,
      })
    }
  }

  //重置状态
  resetStatus() {
    this.checked_points_index = []
    this.checked_points_mouse_offset = []
    this.checked_paths = []
  }

  //根据坐标系统坐标初始化图形信息
  setPoints(posArry) {
    let points = posArry
    const aabb = new QGdstk.Polygon(posArry).bounding_box() //计算中心点
    const w = aabb[1][0] - aabb[0][0]
    const h = aabb[1][1] - aabb[0][1]
    this.position = [aabb[0][0] + w / 2, aabb[0][1] + h / 2]
    this.gds_data_local = new QGdstk.Polygon(points)
    this.gds_data_local.translate([-this.position[0], -this.position[1]])
    this.gds_data_local.rotate((-this.rotation * Math.PI) / 180)
    this.updateShape()
  }

  //正在画板绘制图形
  drawPoint(point, confirm = false, perfect = false) {
    let points
    switch (this.type) {
      case QedaGraphicType.PATH:
        points = this.gds_data_global.get_points()

        if (confirm && !this.intersect_self) {
          let valid = isValidPathPoint(points, [point.x, point.y])
          if (!valid) return
          points.push([point.x, point.y])
        } else {
          const index = points.length < 2 ? 1 : points.length - 1 //鼠标移动时保证最后一个点为鼠标坐标
          points[index] = [point.x, point.y]
        }
        this.setPoints(points)
        break
    }
  }

  //完成绘制
  completeDraw() {
    let points
    let invalidGraphics = []
    switch (this.type) {
      case QedaGraphicType.PATH:
        points = this.gds_data_global.get_points()
        let len = points.length
        if (len < 3) {
          invalidGraphics.push(this)
        } else {
          points.pop()
          this.setPoints(points)
        }
        break
    }
    this.updateSlopes()
    window.STARTPOS = null //清除绘制起始点
    return invalidGraphics
  }

  //根据鼠标位置移动
  movePosByMouse(mouse_pos) {
    STAGE.drag_objs = true
    STAGE.drag_obj_id = this.id
    // const mouse_pos = AXIS.mouse_point //鼠标相对坐标轴坐标
    // if (!mouse_pos) return
    this.translate(mouse_pos.x + this.mouse_offset[0], mouse_pos.y + this.mouse_offset[1])
    this.updateShape()
  }

  //根据鼠标位置移动选中顶点位置
  movePointsByByMouse(mouse_pos) {
    STAGE.drag_objs = true
    STAGE.drag_obj_id = this.id
    // const mouse_pos = AXIS.mouse_point //鼠标相对坐标轴坐标
    const length = this.checked_points_index.length

    let sp = this.shape
    const total_size = sp.length //点的总数
    const total_last_index = total_size - 1

    //移动所有选中点
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      const off_set = this.checked_points_mouse_offset[i]
      p[0] = mouse_pos.x + off_set.x
      p[1] = mouse_pos.y + off_set.y
    }

    if (length === total_size) {
      //全选
      if (this.type === QedaGraphicType.CIRCLE) {
        this.position[0] = sp[0][0] + (sp[2][0] - sp[0][0]) / 2
        this.position[1] = sp[0][1] + (sp[2][1] - sp[0][1]) / 2
        // this.updateTransform()
        this.updateGdsGlobalData()
      }
    } else {
      if (this.type === QedaGraphicType.CIRCLE) {
        const line_length = this.checked_lines.length
        for (let i = 0; i < line_length; i++) {
          movePolygonLines(this, total_last_index, this.checked_lines[i], total_size, sp)
        }
        this.updateCheckedPaths()
        return
      }
      if (STAGE.isStretch) {
        if (this.type === QedaGraphicType.PATH) {
          //线

          const line_length = this.checked_lines.length
          let off_set_index = 0
          for (let i = 0; i < line_length; i++) {
            moveLines(this, mouse_pos, total_last_index, this.checked_lines[i], off_set_index)
            off_set_index += this.checked_lines[i].length
          }
        } else {
          //多边形

          const line_length = this.checked_lines.length
          for (let i = 0; i < line_length; i++) {
            movePolygonLines(this, total_last_index, this.checked_lines[i], total_size, sp)
          }
        }
      }
    }
    this.updateAABB()
    this.updatePath()
    this.updateCheckedPaths()
    // this.setPoints()
  }
}

//更新选中线 拉伸操作相关数据
function updateLines(obj) {
  const length = obj.checked_points_index.length

  const lines = [] //计算需要控制的线段或顶点
  let line = []
  lines.push(line)

  let start = obj.checked_points_index[0]
  for (let i = 0; i < length; i++) {
    const index = obj.checked_points_index[i]
    if (start == index) {
      line.push(index)
    } else {
      start = index
      line = [index]
      lines.push(line)
    }
    start += 1
  }
  if (obj.type !== QedaGraphicType.PATH) {
    //线
    let sp = obj.shape
    if (lines.length > 1) {
      const lines_last_index = lines.length - 1
      let first_line = lines[0]
      let last_line = lines[lines_last_index]
      if (first_line[0] === 0 && last_line.includes(sp.length - 1)) {
        //首尾相连
        // first_line.push(...lines[lines_last_index])
        // lines.splice(lines_last_index,1)
        last_line.push(...first_line)
        lines.shift()
      }
    }
  }
  obj.checked_lines = lines
}

//移动线的的点和边
function moveLines(obj, mouse_pos, total_last_index, line_indexs, off_set_index) {
  const length = line_indexs.length
  if (length === 1) {
    //移动单个点
    const index = line_indexs[0] //下标

    const p = obj.shape[index] //坐标
    let off_set = obj.checked_points_mouse_offset[off_set_index] //偏移量
    if (!off_set) {
      off_set = { x: 0, y: 0 }
    }
    if (index === 0) {
      //选择首尾点
      const a = obj.shape[1]
      const s = obj.slopes[0]
      const line_ap = slopeAndIntercept(a, p, s)
      const line_mouse_p = slopeAndIntercept(p, p, null)
      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)
      if (!isValidPos(p1)) {
        return
      }
      obj.shape[index] = p1
    } else if (index === total_last_index) {
      const a = obj.shape[total_last_index - 1]
      const s = obj.slopes[total_last_index - 1]
      const line_ap = slopeAndIntercept(a, p, s)
      const line_mouse_p = slopeAndIntercept(p, p, null)
      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)
      if (!isValidPos(p1)) {
        return
      }
      obj.shape[index] = p1
    } else {
      //选择中间点 实际是移动选中点和上下一点直接线段
      let m_index = line_indexs[0]
      let line = []
      let count = 2
      if (m_index - 1 === 0) {
        //第二个点
        line = [m_index, m_index + 1]
      } else if (m_index + 1 === total_last_index) {
        //最后一个点前一个点
        const p_before = obj.shape[index - 1] //跟新前一个坐标
        p_before[0] = mouse_pos.x + off_set.x
        p_before[1] = mouse_pos.y + off_set.y
        line = [m_index - 1, m_index]
      } else {
        //中间点
        line = [m_index + 1, m_index, m_index - 1]
        count = 3
      }
      moveLines(obj, mouse_pos, total_last_index, line)
    }
  } else {
    const total = total_last_index
    // const end = length-1

    // const order_index = getOrderPoints(line_indexs, length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]

    //判断收尾顺序
    const head = obj.shape[head_index]
    const last = obj.shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = obj.shape[before_index[0]]
    const b = obj.shape[before_index[1]]
    const c = obj.shape[after_index[0]]
    const d = obj.shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, obj.slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, obj.slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, obj.slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, obj.slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, obj.slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    obj.shape[head_index] = p1
    obj.shape[last_index] = p2
  }
}

//移动多边形的点和边
function movePolygonLines(obj, total_last_index, line_indexs, total_size, shape) {
  const length = line_indexs.length
  if (length > 1) {
    //封闭图形
    const total = total_last_index
    // const end = length-1
    // const order_index = getOrderPoints(deepClone(line_indexs), length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]
    //判断收尾顺序
    const head = shape[head_index]
    const last = shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = shape[before_index[0]]
    const b = shape[before_index[1]]
    const c = shape[after_index[0]]
    const d = shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, obj.slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, obj.slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, obj.slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, obj.slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, obj.slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }

    // if (dist_points(p1, p2) == 0) return
    shape[head_index] = p1
    shape[last_index] = p2
  } else if (length === 1) {
    //移动单个点

    const index = line_indexs[0] //下标
    const p = shape[index] //坐标

    //计算点周围点坐标a, b, p, c, d
    const indexs = getAroundIndex(index, total_size - 1, 5, 2)

    const a = shape[indexs[0]]
    const b = shape[indexs[1]]
    const c = shape[indexs[2]]
    const d = shape[indexs[3]]

    const line_ab = slopeAndIntercept(a, b, obj.slopes[indexs[0]])
    const line_pb = slopeAndIntercept(p, b, obj.slopes[indexs[1]])

    const line_pc = slopeAndIntercept(p, c, obj.slopes[index])
    const line_dc = slopeAndIntercept(d, c, obj.slopes[indexs[2]])
    let p1 = calculateIntersectionPoint(line_ab, line_pb)
    let p2 = calculateIntersectionPoint(line_dc, line_pc)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    shape[indexs[1]] = p1
    shape[indexs[2]] = p2
  }
}

//获取下标index周围的下标
export function getAroundIndex(index, total, length, space) {
  const result = []
  for (let i = 0; i < length; i++) {
    let temp = index - space + i
    if (temp === index) continue
    if (temp < 0) {
      temp += total + 1
    } else if (temp > total) {
      temp -= total + 1
    }
    result.push(temp)
  }

  return result
}

//夹角
export function getAngle({ x: x1, y: y1 }, { x: x2, y: y2 }) {
  const dot = x1 * x2 + y1 * y2
  const det = x1 * y2 - y1 * x2
  const angle = (-Math.atan2(det, dot) / Math.PI) * 180
  return angle
}

//两条平行线偏移量
export function getLineOffset(l1, l2) {
  const start = l1[0]
  const slope1 = getVerticalSlope(getSlope(l1[0], l1[1]))
  const slope2 = getSlope(l2[0], l2[1])
  const line1 = slopeAndIntercept(start, [0, 0], slope1)
  const line2 = slopeAndIntercept(l2[0], l2[1], slope2)
  const cross = calculateIntersectionPoint(line1, line2)
  return [cross[0] - start[0], cross[1] - start[1]]
  // return [100,100]
}
//直线斜率 0 平行于x轴 null 垂直于y轴
export function getSlope(p1, p2) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  const delta_x = x2 - x1
  return delta_x !== 0 ? (y2 - y1) / delta_x : null
}

//获取斜率的垂直斜率
export function getVerticalSlope(s) {
  if (s === null) {
    return 0
  }
  if (s === 0) {
    return null
  }
  return -1 / s
}

//直线斜率和y交点 直线表达式 y = slope * x + intercept
export function slopeAndIntercept(p1, p2, s) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  let slope = 0 //斜率
  if (s !== undefined) {
    slope = s
  } else {
    const delta_x = x2 - x1
    if (delta_x !== 0) {
      slope = (y2 - y1) / delta_x
    }
  }

  let intercept = y1 - slope * x1 //y轴交点
  if (slope === null) {
    //垂直线
    intercept = x1
  }
  return { slope, intercept }
}

//已知斜率求交点
export function calculateIntersectionPoint(line_a, line_b) {
  const a = line_a.slope
  const b = line_b.slope
  const c = line_a.intercept
  const d = line_b.intercept
  let px
  let py
  if (a === null) {
    px = c
    py = b * px + d
  } else if (b === null) {
    px = d
    py = a * px + c
  } else {
    px = (d - c) / (a - b)
    py = a * px + c
  }

  // return { x: px, y: py };
  return [px, py]
}
//两直线交点
export function calculateIntersection(p1, p2, p3, p4) {
  var c2x = p3.x - p4.x // (x3 - x4)
  var c3x = p1.x - p2.x // (x1 - x2)
  var c2y = p3.y - p4.y // (y3 - y4)
  var c3y = p1.y - p2.y // (y1 - y2)

  // down part of intersection point formula
  var d = c3x * c2y - c3y * c2x

  if (d == 0) {
    return null
    throw new Error('Number of intersection points is zero or infinity.')
  }

  // upper part of intersection point formula
  var u1 = p1.x * p2.y - p1.y * p2.x // (x1 * y2 - y1 * x2)
  var u4 = p3.x * p4.y - p3.y * p4.x // (x3 * y4 - y3 * x4)

  // intersection point formula

  var px = (u1 * c2x - c3x * u4) / d
  var py = (u1 * c2y - c3y * u4) / d

  var p = { x: px, y: py }

  return p
}

//图形工厂类
export class QedaSchemaGraphicsFactory {
  constructor() {}

  //正在绘制的物体
  newQedaSchemaGraphic(type, start_point) {
    let newGraphic
    switch (type) {
      case 'line':
        newGraphic = this.drawPath([[start_point.x, start_point.y]], true)
        break
      case 'ruler':
        newGraphic = this.drawRuler([[start_point.x, start_point.y]], true)
        break
      case 'Label*':
        let center = [start_point.x, start_point.y]
        let w = 12 * 5 * 0.6
        let h = 12
        let p1 = [center[0] - w / 2, center[1] - h / 2]
        let p2 = [center[0] - w / 2, center[1] + h / 2]
        let p3 = [center[0] + w / 2, center[1] + h / 2]
        let p4 = [center[0] + w / 2, center[1] - h / 2]
        newGraphic = this.drawLabel([p1, p2, p3, p4], true)
        break
      case 'keyPoint':
        newGraphic = this.drawKeyPoint([[start_point.x, start_point.y]], true)
        break
      case 'dogleg':
        newGraphic = this.drawPath([[start_point.x, start_point.y]], true)
        break
      case 'circle':
        newGraphic = this.drawEllipse(start_point.x, start_point.y)
        break
      case 'rectangle':
        newGraphic = this.drawRect(start_point.x, start_point.y, 0, 0, true)
        break
      case 'polygon':
        newGraphic = this.drawPolygon([[start_point.x, start_point.y]], true)
        break
      case 'selectBox':
        newGraphic = this.drawRectTool(start_point.x, start_point.y)
        break
      case 'adaptRect':
        newGraphic = this.drawRectTool(start_point.x, start_point.y)
        break
      case 'cutBox':
        newGraphic = this.drawRectTool(start_point.x, start_point.y)
        break
    }
    return newGraphic
  }

  //复制图形
  copy(data, pos) {
    let newGraphic
    //复制reference
    if (data.isRefBox) {
      let targetRef = data.reference
      let new_ref = new QedaReference()
      new_ref.name = targetRef.cell.name
      new_ref.magnification = targetRef.magnification
      new_ref.origin = [targetRef.origin[0], targetRef.origin[1]]
      new_ref.repetition = targetRef.repetition
      new_ref.rotation = targetRef.rotation
      new_ref.x_reflection = targetRef.x_reflection
      new_ref.cell = targetRef.cell
      new_ref.QREF = new QGdstk.Reference(new_ref.cell.QCELL, [new_ref.origin[0], new_ref.origin[1]], (new_ref.rotation * Math.PI) / 180, new_ref.magnification, false, 1, 1, null)
      let points = targetRef.cell.aabb
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      let box = new QedaSchemaGraphic()
      box.type = QedaGraphicType.POLYGON
      box.hide = false
      box.lock = false
      new_ref.box = box
      box.isRefBox = true
      box.reference = new_ref
      box.setRefBoxPoints(rect.get_points())
      box.translate(pos[0], pos[1])
      box.updateShape()
      return new_ref.box
    }
    const points = data.gds_data_global.get_points()
    switch (data.type) {
      case QedaGraphicType.PATH:
        newGraphic = this.drawPath(points, false, data.width, data.radius)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.RULER:
        newGraphic = this.drawRuler(points)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.LABEL:
        newGraphic = this.drawLabel(getLabelBox(data.QDATA), false, data.QDATA.copy())
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        // newGraphic.QDATA = data.QDATA.copy()
        break
      case QedaGraphicType.CIRCLE:
        newGraphic = this.drawEllipse(pos[0], pos[1], data.r[0], data.r[1])
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.RECT:
        newGraphic = this.drawPolygon(points)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.POLYGON:
        newGraphic = this.drawPolygon(points, false)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
    }
    return newGraphic
  }
  //复制引用下的图形到全局
  copyRefPolygonsToGlobal(data, ref) {
    if (data.box) {
      //引用盒子拷贝
      let new_position = [data.box.position[0], data.box.position[1]]
      new_position[0] += ref.origin[0]
      new_position[1] += ref.origin[1]

      let refBox = data.box
      let targetRef = refBox.reference
      let new_ref = new QedaReference()
      new_ref.name = targetRef.cell.name
      new_ref.magnification = targetRef.magnification
      new_ref.origin = [ref.origin[0] + data.box.position[0], ref.origin[1] + data.box.position[1]]
      new_ref.repetition = targetRef.repetition
      new_ref.rotation = targetRef.rotation + ref.rotation
      new_ref.x_reflection = targetRef.x_reflection
      new_ref.cell = targetRef.cell
      new_ref.QREF = new QGdstk.Reference(new_ref.cell.QCELL, [new_ref.origin[0], new_ref.origin[1]], (new_ref.rotation * Math.PI) / 180, new_ref.magnification, false, 1, 1, null)
      let points = targetRef.cell.aabb
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      let box = new QedaSchemaGraphic()
      box.type = QedaGraphicType.POLYGON
      box.hide = false
      box.lock = false
      new_ref.box = box
      box.isRefBox = true
      box.reference = new_ref
      box.setRefBoxPoints(rect.get_points())
      // box.translate(new_position[0], new_position[1])
      // box.rotation = ref.rotation

      box.updateShape()
      return box
      // let temp = new QGdstk.Polygon([data.box.position])
      // temp.rotate((ref.rotation * Math.PI) / 180)
      // let scale = ref.magnification
      // temp.scale(scale, scale)
      // temp.translate([ref.origin[0], ref.origin[1]])
      // let new_posiiton = temp.get_points()[0]

      // let new_position = [data.box.position[0], data.box.position[1]]
      // new_position[0] += ref.origin[0]
      // new_position[1] += ref.origin[1]

      // let refBox = QedaGraphics.copy(data.box, new_position)
      // return refBox
    }
    let newGraphic
    //拷贝引用下的图形到全局
    const local_points = data.gds_data_global.get_points()
    let temp = new QGdstk.Polygon(local_points)
    let scale = ref.magnification
    temp.scale(scale, scale * ref.x_reflection)
    temp.rotate((ref.rotation * Math.PI) / 180)
    temp.translate([ref.origin[0], ref.origin[1]])
    let points = temp.get_points()
    let newPos = getRefTransformPos([data.position], ref)[0]
    if (data.isRefBox) {
      // let targetRef = data.reference
      // let points = targetRef.cell.aabb
      // let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      // data.setRefBoxPoints(rect.get_points())
      data.position[0] += ref.origin[0]
      data.position[1] += ref.origin[1]
      data.rotation += ref.rotation
      data.updateShape()
      return data
    }
    switch (data.type) {
      case QedaGraphicType.PATH:
        newGraphic = this.drawPath(points, data.width, data.radius)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.CIRCLE:
        newGraphic = this.drawEllipse(newPos[0], newPos[1], data.r[0], data.r[1])
        newGraphic.layer = data.layer
        break
      case QedaGraphicType.RECT:
        newGraphic = this.drawPolygon(points)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.POLYGON:
        newGraphic = this.drawPolygon(points, false)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.LABEL:
        let new_QDATA = data.QDATA.copy()
        new_QDATA.origin[0] = newPos[0]
        new_QDATA.origin[1] = newPos[1]
        newGraphic = this.drawLabel(points, false, new_QDATA)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
    }
    return newGraphic
  }
  //路径
  drawPath(arr, drawing = false, width = 0, radius = 0) {
    const path = new QedaSchemaGraphic()
    path.type = QedaGraphicType.PATH
    path.width = width
    path.radius = radius
    path.setPoints(arr)
    path.updateSlopes()
    return path
  }

  //绘制尺子
  drawRuler(arr, drawing = false) {
    const path = new QedaSchemaGraphic()
    path.type = QedaGraphicType.RULER
    path.lock = false
    path.hide = false
    path.setPoints(arr)
    path.updateSlopes()
    return path
  }

  //绘制标签
  drawLabel(arr, drawing = false, Qdata) {
    const label = new QedaSchemaGraphic()
    label.type = QedaGraphicType.LABEL
    label.QDATA = Qdata
    label.layer = Qdata.layer
    label.setPoints(arr)
    return label
  }

  //绘制关键点
  drawKeyPoint(arr, drawing = false) {
    const point = new QedaSchemaGraphic()
    point.type = QedaGraphicType.KEYPOINT
    point.setPoints(arr)
    return point
  }
  //矩形
  drawRect(x, y, w = 0, h = 0, drawing = false) {
    const rect = new QedaSchemaGraphic()
    rect.type = QedaGraphicType.RECT
    let arr = [
      [x, y],
      [x, y + h],
      [x + w, y + h],
      [x + w, y],
    ]
    window.STARTPOS = [x, y]
    rect.setPoints(arr)
    rect.updateSlopes()
    return rect
  }

  //多边形
  drawPolygon(arr, drawing = false) {
    const polygon = new QedaSchemaGraphic()
    polygon.type = QedaGraphicType.POLYGON
    polygon.setPoints(arr)
    polygon.updateSlopes()
    return polygon
  }

  //圆形 椭圆
  drawEllipse(x, y, rx = 0, ry = rx) {
    const ellipse = new QedaSchemaGraphic()
    ellipse.type = QedaGraphicType.CIRCLE
    ellipse.position = [x, y]
    window.STARTPOS = [x, y]
    ellipse.r = [rx, ry]
    ellipse.setPoints()
    ellipse.updateSlopes()
    return ellipse
  }

  //矩形辅助工具
  drawRectTool(x, y) {
    const rect = new RectGraphic()
    window.STARTPOS = [x, y]
    return rect
  }
}
const QedaSchemaGraphics = new QedaSchemaGraphicsFactory()
//判断命中图形
export function checkHit(mousePoint, obj, ctrl = false) {
  let adsorption = STAGE.myaxis.adsorpPoint //吸附
  if (STAGE.checkMode === 0) {
    let flag = false
    //整体选择
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    switch (obj.type) {
      case QedaGraphicType.PATH:
        return insidePath(mousePoint, obj.shape)
      case QedaGraphicType.RULER:
        return insidePath(mousePoint, obj.shape)
      case QedaGraphicType.LABEL:
        return obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
      case QedaGraphicType.POLYGON:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        // if (!flag && adsorption) {
        //   flag = checkHitPointAndLine(mousePoint, obj)
        // }
        return flag
      // return insidePolygon(mousePoint, obj.shape)
      case QedaGraphicType.RECT:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        // if (!flag && adsorption) {
        //   flag = checkHitPointAndLine(mousePoint, obj)
        // }
        return flag
      // return insidePolygon(mousePoint, obj.shape)
      case QedaGraphicType.CIRCLE:
        let r = [obj.r[0], obj.r[1]]
        if (adsorption) {
          r[0] += STAGE.pick_dist
          r[1] += STAGE.pick_dist
        }
        if (r[0] !== r[1]) {
          return insideEllipse(mousePoint, obj.position, r)
        }
        return insideCircle(mousePoint, obj.position, r, obj._scale)
      default:
        return false
    }
  } else if (STAGE.checkMode === 1) {
    //部分选择
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    let removeCheckedPoints = false
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          //判断是否重复选择点
          let repeatChoose = hit_points.every(hit => obj.checked_points_index.includes(hit))
          if (repeatChoose) return
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.POLYGON:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          //移除点

          // if(hit_points.length == 2){
          //     if(hit_points[1] === obj.shape.length - 1){
          //         hit_points = [hit_points[1]]
          //     }else{
          //         hit_points = [hit_points[0]]
          //     }

          // }
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.RECT:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.CIRCLE:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        //圆外包矩形不能选择一个点
        if (obj.checked_points_index.length == 1) {
          obj.checked_points_index = []
        }
        obj.updateCheckedPaths()
        obj.updateLines()
        return obj.checked_points_index.length
      default:
        return false
    }
  } else if (STAGE.checkMode === 8) {
    //点对齐
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.LABEL:
        //判断关键点
        if (obj.QDATA.get_gds_property(1)) {
          hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, false, false, true)
        }
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      default:
        return false
    }
  } else if (STAGE.checkMode === 9) {
    //线对齐
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      default:
        return false
    }
  } else if (STAGE.checkMode === 10) {
    //选择线
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      default:
        return false
    }
  }
}

export function checkHitPointAndLine(mousePoint, obj, adsorption, scale = 1) {
  let pick_dist = STAGE.pick_dist / scale
  if (!insideAABB_expand(mousePoint, obj.aabb, pick_dist)) return false //扩大包围盒过滤
  let hit_points //命中的点
  switch (obj.type) {
    case QedaGraphicType.PATH:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, false, adsorption)
      if (hit_points?.point) return hit_points
      if (adsorption) {
        if (obj.pathBody?.length) {
          for (let index = 0; index < obj.pathBody.length; index++) {
            const body = obj.pathBody[index]
            hit_points = hited_points_alignment(mousePoint, { shape: body, position: obj.position }, pick_dist, false, false, false, adsorption)
            if (hit_points?.point) return hit_points
          }
        }
      }

      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist)
        if (hit_points?.lines?.length) return hit_points
        if (adsorption) {
          if (obj.pathBody?.length) {
            for (let index = 0; index < obj.pathBody.length; index++) {
              const body = obj.pathBody[index]
              hit_points = hited_lines_alignment(mousePoint, { shape: body, position: obj.position }, pick_dist, true)
              if (hit_points?.lines?.length) return hit_points
            }
          }
        }
      }
      return hit_points
    case QedaGraphicType.RULER:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, false, false)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist)
      }
      return hit_points
    case QedaGraphicType.LABEL:
      //判断关键点
      if (obj.QDATA.get_gds_property(1)) {
        hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, true, adsorption)
      }
      return hit_points
    case QedaGraphicType.POLYGON:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    case QedaGraphicType.RECT:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    case QedaGraphicType.CIRCLE:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, true, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true, true)
      }
      return hit_points
    default:
      return false
  }
}

//判断命中图形之前选择部分
export function checkHitBeforePart(mousePoint, obj) {
  if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
  let result
  switch (obj.type) {
    case QedaGraphicType.PATH:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.POLYGON:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.RECT:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.CIRCLE:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集
      return result
    default:
      return false
  }
}

function isIntersection(arr1, arr2) {
  const length_1 = arr1.length
  const length_2 = arr2.length
  for (let i = 0; i < length_1; i++) {
    for (let j = 0; j < length_2; j++) {
      if (arr1[i] === arr2[j]) {
        return true
      }
    }
  }
  return false
}
//判断在包围盒内
export function insideAABB(point, aabb) {
  return aabb[0][0] < point.x && aabb[0][1] < point.y && aabb[1][0] > point.x && aabb[1][1] > point.y
}
//判断在扩大包围盒内
export function insideAABB_expand(point, aabb, expand) {
  return aabb[0][0] - expand < point.x && aabb[0][1] - expand < point.y && aabb[1][0] + expand > point.x && aabb[1][1] + expand > point.y
}

//判断多边形中心点
function centroid(arr) {
  // arr = JSON.parse(JSON.stringify(arr));
  const x = arr.map(function (a) {
    return a[0]
  })
  const y = arr.map(function (a) {
    return a[1]
  })
  const minX = Math.min.apply(null, x)
  const maxX = Math.max.apply(null, x)
  const minY = Math.min.apply(null, y)
  const maxY = Math.max.apply(null, y)
  return [(minX + maxX) / 2, (minY + maxY) / 2]
}

//判断点是否落在圆形内
function insideCircle(point, position, r, _scale) {
  const dist_points = (point.x - position[0]) * (point.x - position[0]) + (point.y - position[1]) * (point.y - position[1])
  return Math.sqrt(dist_points) < r[0] * _scale[0]
}

//判断点是否落在椭圆内
export function insideEllipse(point, position, r) {
  const p = Math.pow(point.x - position[0], 2) / Math.pow(r[0], 2) + Math.pow(point.y - position[1], 2) / Math.pow(r[1], 2)

  return p <= 1
}

//判断点是否落在矩形内
// function insideRect(point, rect) {
//     let x = point.x, y = point.y;

//     let inside = false;
//     return x <= x && x <= x + this.width &&
//         y <= y && y <= this.y + this.height;
// }

//判断点是否落在多边形内
function insidePolygon(point, polygon) {
  let x = point.x,
    y = point.y

  let inside = false
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].x,
      yi = polygon[i].y
    let xj = polygon[j].x,
      yj = polygon[j].y

    let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

//判断点是否落在路径上 循环和每一条线段比较
function insidePath(mouse_point, arr, dist = 10) {
  dist = STAGE.pick_dist
  let near = false
  for (let i = 0; i < arr.length - 1; i++) {
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      near = true
      break
    }
  }
  return near
}
//部分选择
function hited_points(mouse_point, arr, dist = 10, close = false, circle = false) {
  dist = STAGE.pick_dist
  let result = []
  const length = arr.length
  if (!circle) {
    for (let i = 0; i < length; i++) {
      //判断命中点
      let point = arr[i]
      const x = point[0] - mouse_point.x
      const y = point[1] - mouse_point.y
      if (Math.sqrt(x * x + y * y) <= dist) {
        result.push(i)
        return result
      }
    }
  }

  for (let i = 0; i < length - 1; i++) {
    //点未命中判断边
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      result.push(i, i + 1)
      return result
    }
  }
  if (close) {
    //封闭多边形
    const first = arr[0]
    const last = arr[length - 1]
    if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
      result.push(0, length - 1)
    }
  }

  return result
}
//对齐选择
function hited_points_alignment(mouse_point, obj, dist = 10, close = false, circle = false, label = false, can_pick_extra = true) {
  // dist = STAGE.pick_dist
  let result = { index: null, point: null, line_indexes: [], lines: [] }
  let arr = obj.shape
  if (label) {
    arr = [obj.position]
  }
  if (STAGE.checkMode_bk === 1 && STAGE.alignmentPoints.length === 0) {
    //部分选择对齐第一个点只能在旋转点里选择
    const checkIndexs = obj.checked_points_index
    arr = obj.shape.filter((sp, i) => checkIndexs.indexOf(i) !== -1)
  }
  const length = arr.length
  const last = length - 1
  const p_m = [mouse_point.x, mouse_point.y]
  // let can_pick_extra = true //选取额外的点 中点 中心点
  // if(STAGE.checkMode_bk === 1){
  //     can_pick_extra = STAGE.alignmentPoints.length !== 0 //对齐操作第二点无法拾取中心点，原点
  // }
  // if (!close) {
  //无宽度的线
  for (let i = 0; i < length; i++) {
    //点未命中判断边
    let start = arr[i]
    if (pointsInsideDist(start, p_m, dist)) {
      result.index = i
      result.point = start
      return result
    }
    if (can_pick_extra) {
      if (close) {
        let end = arr[i + 1]
        if (i === last) {
          end = arr[0]
        }
        const mid_point = midpoint(start, end)
        if (pointsInsideDist(mid_point, p_m, dist)) {
          result.index = 'midPoint'
          result.point = mid_point
          return result
        }
      } else {
        if (i !== last) {
          let end = arr[i + 1]
          const mid_point = midpoint(start, end)
          if (pointsInsideDist(mid_point, p_m, dist)) {
            result.index = 'midPoint'
            result.point = mid_point
            return result
          }
        }
      }
    }
  }
  // return result
  // }
  if (can_pick_extra) {
    if (pointsInsideDist(obj.position, p_m, dist)) {
      //判断中心点
      result.index = 'center'
      result.point = obj.position
      return result
    }
  }

  // if(circle && !can_pick_extra){ //圆对齐只能选择边
  //     for (let i = 0; i < length - 1; i++) { //点未命中判断边
  //         let start = arr[i]
  //         let end = arr[i + 1]
  //         if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
  //             result.line_indexes.push(i, i + 1)
  //             result.lines.push([start, end])
  //             return result
  //         }
  //     }
  //     const first = arr[0]
  //     const last = arr[length - 1]
  //     if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
  //         result.line_indexes.push(0, length - 1)
  //         result.lines.push([first, last])
  //         return result
  //     }
  //     return result
  // }else{ //多边形
  //     for (let i = 0; i < length; i++) { //判断命中点
  //         if (pointsInsideDist(arr[i], p_m, dist)) {
  //             result.index = i
  //             result.point = arr[i]
  //             return result
  //         }
  //     }
  // }
  if (!circle) {
    //多边形
    for (let i = 0; i < length; i++) {
      //判断命中点
      if (pointsInsideDist(arr[i], p_m, dist)) {
        result.index = i
        result.point = arr[i]
        return result
      }
    }
  }
  return result
}

function hited_lines_alignment(mouse_point, obj, dist = 10, close = false, circle = false) {
  // dist = STAGE.pick_dist
  let result = { point_indexes: [], lines: [] }
  let arr = obj.shape
  let insideLines = false
  if (STAGE.checkMode_bk === 1) {
    //部分选择
    if (STAGE.alignmentLines.length === 0) {
      //第一个点
      if (obj.checked_paths.length) {
        insideLines = true
      } else {
        return
      }
    }
  }
  let compareSlope = false
  if (STAGE.alignmentLines.length !== 0) {
    compareSlope = true
  }
  const length = arr.length
  for (let i = 0; i < length - 1; i++) {
    //判断边
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      if (compareSlope) {
        //判断平行线
        if (isSameSlope(obj.slopes[i], STAGE.alignmentLineSlope)) {
          // result.point_indexes.push(i, i + 1)
          result.lines.push([start, end])
          return result
        }
        const slope_v = getVerticalSlope(obj.slopes[i]) //线的垂直斜率
        if (isSameSlope(slope_v, STAGE.alignmentLineSlope)) {
          result.lines.push(getMidVertialLine(start, end, slope_v))
          return result
        }
      } else {
        if (insideLines) {
          const indexs = obj.checked_points_index

          if (indexs.indexOf(i) !== -1 && indexs.indexOf(i + 1) !== -1) {
            //判断已选择
            // result.point_indexes.push(i, i + 1)
            result.lines.push([start, end])
            return result
          }
        } else {
          // result.point_indexes.push(i, i + 1)
          result.lines.push([start, end])
          return result
        }
      }
    }
  }
  if (close) {
    //判断封闭边
    const first = arr[0]
    const last = arr[length - 1]
    if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
      if (compareSlope) {
        if (isSameSlope(obj.slopes[length - 1], STAGE.alignmentLineSlope)) {
          //平行
          // result.point_indexes.push(0, length - 1)
          result.lines.push([first, last])
          return result
        }
        const slope_v = getVerticalSlope(obj.slopes[length - 1]) //线的垂直斜率
        if (isSameSlope(slope_v, STAGE.alignmentLineSlope)) {
          result.lines.push(getMidVertialLine(first, last, slope_v))
          return result
        }
      } else {
        // result.point_indexes.push(0, length - 1)
        if (insideLines) {
          const indexs = obj.checked_points_index
          if (indexs.indexOf(0) !== -1 && indexs.indexOf(length - 1) !== -1) {
            //判断已选择
            result.point_indexes.push(0, length - 1)
            result.lines.push([first, last])
            return result
          }
        } else {
          result.lines.push([first, last])
          return result
        }
      }
    }
  }
  return result
}

function isSameSlope(s1, s2) {
  if (!s1 || !s2) {
    return s1 === s2
  } else {
    return s1.toFixed(2) === s2.toFixed(2)
  }
}

function getMidVertialLine(p1, p2, slope) {
  const mid_p = midpoint(p1, p2)
  const line = slopeAndIntercept(mid_p, [0, 0], slope)
  const p_start = [0, 0]
  const p_end = [0, 0]
  const d = 10 / AXIS.scale
  // 线的角度
  var theta = Math.atan(slope)
  if (slope === null) {
    theta = Math.PI / 2
  }

  //计算附近两点
  p_start[0] = mid_p[0] + d * Math.cos(theta)
  p_start[1] = mid_p[1] + d * Math.sin(theta)

  p_end[0] = mid_p[0] - d * Math.cos(theta)
  p_end[1] = mid_p[1] - d * Math.sin(theta)

  return [p_start, p_end]
}

function midpoint(p1, p2) {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2]
}

function pointsInsideDist(p1, p2, dist) {
  const x = p1[0] - p2[0]
  const y = p1[1] - p2[1]
  return Math.sqrt(x * x + y * y) <= dist
}

//判断多边形自相交
function isSelfIntersection(ring, close = true) {
  if (ring.length < 3) {
    return false
  }
  let rings = JSON.parse(JSON.stringify(ring))
  // rings.pop();
  if (close) {
    rings.push(rings[0])
  }
  const arr = []
  const data = rings

  for (let i = 0; i < data.length - 3; i++) {
    for (let j = i + 2; j <= data.length - 2; j++) {
      let a = data[i]
      let b = data[i + 1]
      let c = data[j]
      let d = data[j + 1]
      if (a === d) continue
      if (lineSegmentsIntersect(a[0], a[1], b[0], b[1], c[0], c[1], d[0], d[1])) {
        return true
      }
    }
  }
  return false
}

//判断两条线相交
function lineSegmentsIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  var a_dx = x2 - x1
  var a_dy = y2 - y1
  var b_dx = x4 - x3
  var b_dy = y4 - y3
  var s = (-a_dy * (x1 - x3) + a_dx * (y1 - y3)) / (-b_dx * a_dy + a_dx * b_dy)
  var t = (+b_dx * (y1 - y3) - b_dy * (x1 - x3)) / (-b_dx * a_dy + a_dx * b_dy)
  return s >= 0 && s <= 1 && t >= 0 && t <= 1
}

//判断两条线相交
// function segmentsIntr(a, b, c, d) {
//     /** 1 解线性方程组, 求线段交点. **/
//     // 如果分母为0 则平行或共线, 不相交
//     let denominator = (b.y - a.y) * (d.x - c.x) - (a.x - b.x) * (c.y - d.y);
//     if (denominator == 0) {
//         return false;
//     }
//     // 线段所在直线的交点坐标 (x , y)
//     let x =
//         ((b.x - a.x) * (d.x - c.x) * (c.y - a.y) +
//             (b.y - a.y) * (d.x - c.x) * a.x -
//             (d.y - c.y) * (b.x - a.x) * c.x) /
//         denominator;
//     let y =
//         -(
//             (b.y - a.y) * (d.y - c.y) * (c.x - a.x) +
//             (b.x - a.x) * (d.y - c.y) * a.y -
//             (d.x - c.x) * (b.y - a.y) * c.y
//         ) / denominator;
//     /** 2 判断交点是否在两条线段上 **/
//     if (
//         // 交点在线段1上
//         (x - a.x) * (x - b.x) <= 0 &&
//         (y - a.y) * (y - b.y) <= 0 &&
//         // 且交点也在线段2上
//         (x - c.x) * (x - d.x) <= 0 &&
//         (y - c.y) * (y - d.y) <= 0
//     ) {
//         // 返回交点p
//         return { x: x, y: y };
//     }
//     //否则不相交
//     return false;
// }

function segmentsIntr(a, b, c, d) {
  // 三角形abc 面积的2倍
  var area_abc = (a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])

  // 三角形abd 面积的2倍
  var area_abd = (a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0])

  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd >= 0) {
    return false
  }

  // 三角形cda 面积的2倍
  var area_cda = (c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0])
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  var area_cdb = area_cda + area_abc - area_abd
  if (area_cda * area_cdb >= 0) {
    return false
  }

  //计算交点坐标
  var t = area_cda / (area_abd - area_abc)
  var dx = t * (b[0] - a[0]),
    dy = t * (b[1] - a[1])
  return [a[0] + dx, a[1] + dy]
}

function getRectFromAABB(aabb) {
  const minX = aabb[0][0]
  const minY = aabb[0][1]
  const maxX = aabb[1][0]
  const maxY = aabb[1][1]

  return [
    { x: minX, y: minY },
    { x: minX, y: maxY },
    { x: maxX, y: maxY },
    { x: maxX, y: minY },
  ]
}

function cloneArray(arry) {
  const result = []
  const length = arry.length
  for (let i = 0; i < length; i++) {
    result.push([arry[i][0], arry[i][1]])
  }
  return result
}

export function sliceLines(lines, val, axis) {
  const aabb = STAGE.checkedGraphicsAABB
  const min_x = STAGE.checkedGraphicsAABB[0][0]
  const min_y = STAGE.checkedGraphicsAABB[0][1]
  const max_x = STAGE.checkedGraphicsAABB[1][0]
  const max_y = STAGE.checkedGraphicsAABB[1][1]
  let line_slice = []
  if (axis === 'x') {
    line_slice = [
      [val, min_y],
      [val, max_y],
    ]
  } else {
    line_slice = [
      [min_x, val],
      [max_x, val],
    ]
  }

  const result = []
  const len = lines.length
  for (let i = 0; i < len; i++) {
    result.push({ result: sliceLine(lines[i], line_slice), layer: lines[i].layer, width: lines[i].width, radius: lines[i].radius })
  }
  return result
}

function sliceLine(path, line_slice) {
  const result = []
  const points = path.gds_data_global.get_points()
  const len = points.length
  let path_new = []
  for (let i = 0; i < len - 1; i++) {
    const p1 = points[i]
    const p2 = points[i + 1]

    const p_intersection = segmentsIntr(p1, p2, line_slice[0], line_slice[1])

    path_new.push(p1)
    if (p_intersection) {
      //存在交点
      path_new.push(p_intersection)
      result.push(deepClone(path_new))
      path_new = []
      path_new.push(p_intersection)
    }
  }
  path_new.push(points[len - 1])
  result.push(path_new)
  // path.deleted = true
  return result
}

export function getFillAndBorderImgs() {
  const fillImgs = []
  const borderImgs = []
  const rect_fill = CanvasKit.LTRBRect(0, 0, 250, -250)
  const fill_pt = new CanvasKit.Paint()
  const fillFact = CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理

  // let canvas = document.createElement('canvas')
  // canvas.id = 'img_temp'
  for (let key in QedaGraphicFillTypeImg) {
    fill_pt.setShader(fillFact.makeShader([...CanvasKit.BLACK, ...QedaGraphicFillType[key], 0, 0, 1]))
    const surface = CanvasKit.MakeCanvasSurface('fill_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    // const outline_pt = this.stage.layer_paints_img[index]
    canvas.save()
    canvas.scale(3, 3)
    canvas.drawRect(rect_fill, fill_pt)
    canvas.restore()

    surface.flush()
    let data = surface.makeImageSnapshot().encodeToBytes()
    let blob = new Blob([data], { type: 'image/jpeg' })
    data = null // 要置null，否则存在内存泄漏风险
    let url = URL.createObjectURL(blob)
    fillImgs.push(url)
  }

  const rect_border = CanvasKit.LTRBRect(0, 0, 11.5, -6)
  const outline_pt = new CanvasKit.Paint()
  for (let key in QedaGraphicBorderTypeImg) {
    const dep = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[key])
    outline_pt.setPathEffect(dep)
    outline_pt.setColor(CanvasKit.BLACK)
    outline_pt.setStyle(CanvasKit.PaintStyle.Stroke)
    outline_pt.setStrokeWidth(1.5)
    const surface = CanvasKit.MakeCanvasSurface('border_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    canvas.save()
    canvas.scale(30, 30)
    canvas.drawRect(rect_border, outline_pt)
    canvas.restore()
    surface.flush()
    let border_data = surface.makeImageSnapshot().encodeToBytes()
    let border_blob = new Blob([border_data], { type: 'image/jpeg' })
    border_data = null // 要置null，否则存在内存泄漏风险
    let border_url = URL.createObjectURL(border_blob)
    borderImgs.push(border_url)
  }
  return { fillImgs, borderImgs }
}

export function stretchPoint(target, start, to_point) {
  target.updatePointsMouseOffset({ x: start[0], y: start[1] })
  //移动所有选中点

  const sp = target.shape
  const checked_length = target.checked_points_index.length
  for (let i = 0; i < checked_length; i++) {
    const index = target.checked_points_index[i]
    const p = sp[index]
    const off_set = target.checked_points_mouse_offset[i]
    p[0] = to_point[0] + off_set.x
    p[1] = to_point[1] + off_set.y
  }
  const p_end = { x: to_point[0], y: to_point[1] }

  const total_size = sp.length //点的总数
  const check_all = total_size === checked_length
  if (STAGE.isStretch && !check_all) {
    const total_last_index = total_size - 1
    target.checked_paths = []
    target.updateLines()
    if (target.type === QedaGraphicType.PATH) {
      //线
      const line_length = target.checked_lines.length
      let off_set_index = 0
      for (let i = 0; i < line_length; i++) {
        moveLines(target, p_end, total_last_index, target.checked_lines[i], off_set_index)
        off_set_index += target.checked_lines[i].length
      }
    } else {
      //多边形
      const line_length = target.checked_lines.length
      for (let i = 0; i < line_length; i++) {
        movePolygonLines(target, total_last_index, target.checked_lines[i], total_size, sp)
      }
    }
  }
  target.checked_points_mouse_offset = []
  target.stretchConfirm()
  target.updateCheckedPaths()
}

//获取label包围盒
export function getLabelBox(label) {
  //   label.anchor = 'e' //debug
  let anchor = label.anchor
  if (!label.get_gds_property(0)) {
    label.set_gds_property(0, JSON.stringify(30)) //字体大小默认30
  }
  let font_size = JSON.parse(label.get_gds_property(0))
  let w = label.text.length * font_size * 0.6
  let isKeyPoint = label.get_gds_property(1)
  if (isKeyPoint) {
    let params = JSON.parse(isKeyPoint)
    if (params === true) {
      anchor = 'o'
    } else {
      if (!params?.create_label) {
        anchor = 'o'
      }
    }
  }
  //没有文字的关键点或者没有文字默认一个文字单位
  if ((isKeyPoint && w == 0) || w == 0) {
    w = font_size * 0.6
  }
  let h = 1 * font_size
  let center = [label.origin[0], label.origin[1]]
  let p1 = [center[0] - w / 2, center[1] - h / 2]
  let p2 = [center[0] - w / 2, center[1] + h / 2]
  let p3 = [center[0] + w / 2, center[1] + h / 2]
  let p4 = [center[0] + w / 2, center[1] - h / 2]
  let temp = new QGdstk.Polygon([p1, p2, p3, p4])
  if (anchor == 'o') {
  } else if (anchor == 'e') {
    temp.translate([-w / 2, 0])
  } else if (anchor == 's') {
    temp.translate([0, h / 2])
  } else if (anchor == 'w') {
    temp.translate([w / 2, 0])
  } else if (anchor == 'n') {
    temp.translate([0, -h / 2])
  } else if (anchor == 'ne') {
    temp.translate([-w / 2, -h / 2])
  } else if (anchor == 'se') {
    temp.translate([-w / 2, h / 2])
  } else if (anchor == 'nw') {
    temp.translate([w / 2, -h / 2])
  } else if (anchor == 'sw') {
    temp.translate([w / 2, h / 2])
  }
  return temp.get_points()
}

export function get_path_polygons(points, width, radius) {
  let res = []
  if (!width) return res
  radius = parseFloat(radius)
  let type = radius > 0 ? 'round' : 'natural'
  let path = new QGdstk.FlexPath(points, width, 0, type, 'flush', radius, null, 1e-2, false, true, 0, 0)
  let polygons = path.to_polygons()
  polygons.forEach(p => {
    res.push(p.get_points())
  })

  return res
}

export function getRefTransformPos(arr, ref) {
  let temp = new QGdstk.Polygon(arr)
  let scale = ref.magnification
  temp.scale(scale, scale * ref.x_reflection)
  temp.rotate((ref.rotation * Math.PI) / 180)
  temp.translate([ref.origin[0], ref.origin[1]])
  return temp.get_points()
}

export function isRectangle(x1, y1, x2, y2, x3, y3, x4, y4) {
  let s = getSlope([x1, y1], [x2, y2])

  if (s === 0 || s === null) {
    if (x1 + x3 == x2 + x4 && y1 + y3 == y2 + y4) {
      return true
    }
  }
  return false
}

function isValidPathPoint(points, point) {
  let last = points.at(-2)
  if (last && last[0] === point[0] && last[1] === point[1]) {
    return false
  }
  let len = points.length
  let last_index = len - 1
  for (let index = 0; index < len; index++) {
    const p = points[index]
    if (index == last_index) break
    if (p[0] == point[0] && p[1] == point[1]) {
      return false
    }
  }
  return true
}

function isValidPolygonPoint(points, point) {
  let last = points.at(-2)
  if (last && last[0] === point[0] && last[1] === point[1]) {
    return false
  }
  let len = points.length
  let last_before_index = len - 3
  let last_index = len - 2
  let p1 = points[last_before_index]
  let p2 = points[last_index]
  if (p2) {
    if (p1) {
      if (p1[0] == p2[0] && p2[0] == point[0]) {
        return false
      }
      if (p1[1] == p2[1] && p2[1] == point[1]) {
        return false
      }
    }
  }

  return true
}

export function setHideAndLock(num, obj) {
  if (!STAGE?.layerDatas) return
  let layer = STAGE.layerDatas.filter(obj => obj.layerNumber == num)[0]
  if (!layer) return
  obj.hide = layer.hide
  obj.lock = layer.lock
}

export function isValidPos(p) {
  return Number.isFinite(p[0]) && Number.isFinite(p[1])
}

function dist_points(p1, p2) {
  let x = p1[0] - p2[0]
  let y = p1[1] - p2[1]
  const dist_points = x * x + y * y
  return Math.sqrt(dist_points)
}

function fixNumber(num) {
  return Math.ceil(num * 1000) / 1000
}
function fixPosNumber3(p) {
  return [Math.round(p[0] * 1000) / 1000, Math.round(p[1] * 1000) / 1000]
}
