// import { AnchornMap } from '../graphic_cell/utils/graphics-util'
import { getUserGnsPermission_api, getFileChannels_api } from '@/api/file/file.js'
import { deepClone } from '../utils'
import { getCellFromTable, getCompleteCellData } from '../../components/homes/fileList/function/fileListPublicFn'
import { putObjectToCache_api } from '@/api/objectStorage/objectStorage.js'
import Md5 from 'js-md5'
import bus from '@/components/common/bus'
import { exChangeCpmponent, updateCharcterCell } from '../graphic_cell/utils/cell-generate'
import { Message } from 'element-ui'
import i18n from '../../common/lang/i18n.js'
//获取编辑时间
export function getHistoryTime() {
  var date = new Date()
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear() //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1
  //日 getDate()：(1 ~ 31)
  var day = date.getDate()
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours()
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes()
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds()
  //毫秒
  var misecond = date.getMilliseconds()
  var time = year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second) + ':' + addZero(misecond)
  return time
}
//小于10的拼接上0字符串
export function addZero(s) {
  return s < 10 ? '0' + s : s
}
export function getKernelAnchor(v) {
  let Kernel = window.Kernel
  if (v === 5) {
    return Kernel.GdsAnchor.O
  } else if (v === 1) {
    return Kernel.GdsAnchor.N
  } else if (v === 9) {
    return Kernel.GdsAnchor.S
  } else if (v === 4) {
    return Kernel.GdsAnchor.W
  } else if (v === 6) {
    return Kernel.GdsAnchor.E
  } else if (v === 0) {
    return Kernel.GdsAnchor.NW
  } else if (v === 2) {
    return Kernel.GdsAnchor.NE
  } else if (v === 8) {
    return Kernel.GdsAnchor.SW
  } else if (v === 10) {
    return Kernel.GdsAnchor.SE
  }
}
//版图绑定消息服务
export async function addCellMessageServe(topInfo, proInfo, fileInfo, cellInfo, cell) {
  if (!topInfo || !proInfo || !fileInfo || !cellInfo || !cell) return

  if (cell.js_obj.WS || cell.js_obj.addedWS) {
    return
  }
  if (fileInfo.teamRule !== undefined) {
    // alert(1)
    if (typeof WebSocket === 'undefined') {
      console.error('您的浏览器不支持socket')
    } else {
      //const socketUrl = 'ws://192.168.10.24:8080/ws/chats/' + cell.snow_id + '/' //"ws://192.168.10.24:8080/ws/chats/test_demo/",
      cell.js_obj.addedWS = true
      let socketUrl
      if (location.host.indexOf('localhost') === 0 || location.host.indexOf('192.168.10.19:8080') === 0) {
        //本地环境
        socketUrl = 'ws://' + location.host + '/socket/ws/chats/' + cell.snow_id + '/'
      } else {
        socketUrl = 'wss://' + location.host + '/ws/chats/' + cell.snow_id + '/'
      }
      const msgList = []
      const msgData = await updateLatestCellData(cell, msgList, fileInfo.fileId)
      // alert(cell.snow_id)
      cell.js_obj.WS = new QedaCellSocket(socketUrl, cell, { topInfo, proInfo, fileInfo, cellInfo }, msgData)
      bus.$emit('cellHistoryUpdate', { cell: cell })
      // setInterval(() => {
      //   cell.js_obj.WS.sendMsg()
      // }, 5000)
    }
  }
}

//更新最近的历史记录
async function updateLatestCellData(cell, msgList, file_id) {
  let savedActions = await getFileChannels_api({ snow_id: cell.snow_id, start: 0, rows: 1000, sort: 'asc', is_red: 1 })
  let beforeSaveMsg = []
  let notSaveMsg = []
  //保存之前
  if (savedActions?.data?.data?.length) {
    const serveMsgList = savedActions.data.data
    for (let i = 0; i < serveMsgList.length; i++) {
      if (serveMsgList[i]._source.event == 'DRAWING') {
        const msg_json = serveMsgList[i]._source.param.data
        msgList.push(msg_json)
        beforeSaveMsg.push(msg_json)
      }
    }
  }
  let res = await getFileChannels_api({ snow_id: cell.snow_id, start: 0, rows: 1000, sort: 'asc', is_red: 0 })

  //保存之后
  if (res?.data?.data?.length) {
    const serveMsgList = res.data.data
    for (let i = 0; i < serveMsgList.length; i++) {
      if (serveMsgList[i]._source.event == 'DRAWING') {
        const msg_json = serveMsgList[i]._source.param.data
        msgList.push(msg_json)
        notSaveMsg.push(msg_json)
        await applyHistoryToCell(cell, msg_json)
      }
    }
    updateCellLayout(cell, file_id)
  }

  return { msgList, beforeSaveMsg, notSaveMsg }
}

//版图消息服务连接
export class QedaCellSocket {
  constructor(wsurl, cell, cellData, msgData) {
    this.wsurl = wsurl
    this.cellData = cellData
    this.msgData = msgData

    this.teamId = this.cellData.topInfo.teamId
    this.gns = `gns://${this.cellData.topInfo.teamId}/${this.cellData.proInfo.projectId}/${this.cellData.fileInfo.fileId}/${this.cellData.cellInfo.cellId}`
    this.cell = cell
    this.msgList = msgData.msgList //消息列表
    this.deleted = false
    this.ws = new WebSocket(wsurl)
    this.ws.onopen = this.onopen.bind(this)
    this.ws.onmessage = this.onmessage.bind(this)
    this.ws.onclose = this.onclose.bind(this)
    this.ws.onerror = this.onerror.bind(this)
    this.lockReconnect = false //是否真正建立连接
    this.timeout = 58 * 1000 //58秒一次心跳
    this.timeoutObj = null //心跳心跳倒计时
    this.serverTimeoutObj = null //心跳倒计时
    this.timeoutnum = null //断开 重连倒计时
  }
  initWebpack() {
    this.ws = new WebSocket(this.wsurl)
    this.ws.onopen = this.onopen.bind(this)
    this.ws.onmessage = this.onmessage.bind(this)
    this.ws.onclose = this.onclose.bind(this)
    this.ws.onerror = this.onerror.bind(this)
  }
  onopen() {
    // var params = '{"reqtype":"Query","action":"allexts"}'
    // this.ws.send(params)

    this.start()
  }
  start() {
    //开启心跳

    this.timeoutObj && clearTimeout(this.timeoutObj)
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
    let that = this
    this.timeoutObj = setTimeout(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，

      if (that.ws.readyState === 1) {
        // //如果连接正常
        // const heartbeat = '{"message": "ping", "to_user": 1}'
        // that.ws.send(heartbeat) //心跳包格式需要自己确定
      } else {
        //否则重连
        that.reconnect()
      }
      that.serverTimeoutObj = setTimeout(function () {
        //超时关闭
        that.ws.close()
        that.reconnect()
      }, that.timeout)
    }, this.timeout)
  }
  reconnect() {
    //重新连接
    if (this.deleted) return
    var that = this
    if (that.lockReconnect) {
      return
    }
    that.lockReconnect = true
    //没连接上会一直重连，设置延迟避免请求过多
    that.timeoutnum && clearTimeout(that.timeoutnum)
    that.timeoutnum = setTimeout(function () {
      //新连接
      that.initWebpack()
      that.lockReconnect = false
      that.isFirstGet = true
    }, 5000)
  }
  reset() {
    //重置心跳
    var that = this
    //清除时间
    clearTimeout(that.timeoutObj)
    clearTimeout(that.serverTimeoutObj)
    //重启心跳
    this.start()
  }

  //恢复至保存前的历史记录
  clearUnSavedHistory() {
    this.msgList = this.msgData.beforeSaveMsg
  }

  async onmessage(e) {
    const data = JSON.parse(e.data)

    const current_user_id = localStorage.getItem('userId')
    if (data.message.user_id !== current_user_id && data.event == 'CLOSE') {
      Message.warning(`${this.cell.name} ${i18n.t('messages.cellBoardMsg.message.cellNeedUpdate')}`)
      return
    }
    if (data.event !== 'DRAWING') return
    let json_data = data.message.data
    this.cell.js_obj.history = []
    if (data.message.user_id !== current_user_id) {
      if (json_data.file_url) {
        json_data = await getObjectData(json_data.file_url)
        await applyHistoryToCell(this.cell, json_data, this.cellData.fileInfo.fileId)
      } else {
        await applyHistoryToCell(this.cell, json_data, this.cellData.fileInfo.fileId)
      }

      updateCellLayout(this.cell, this.cellData.fileInfo.fileId)
    }
    this.msgList.push(json_data)

    bus.$emit('cellHistoryUpdate', { cell: this.cell })

    //处理数据的地方
    this.reset()
  }

  remove() {
    this.onclose()
    this.deleted = true
  }

  onclose(e) {
    this.ws.close()
  }
  onerror(e) {
    //重连
    this.reconnect()
  }

  //发送消息
  async sendMsg(data) {
    if (!data) return

    data.time = getHistoryTime()
    data.userName = localStorage.getItem('userName')
    data.teamName = this.cellData.topInfo.name
    const file = new Blob([JSON.stringify(data)], { type: 'text/json' })
    const validDataSize = file.size < 1400000 //1400000 //this.validDataSize(data);

    const user_id = localStorage.getItem('userId')
    const tema_id = this.teamId
    const qid = localStorage.getItem('qid')
    let to_user = await getUserGnsPermission_api({ gns: this.gns })

    let event = 'DRAWING'
    if (data.func == 'CLOSE') {
      event = 'CLOSE'
    }
    if (validDataSize) {
      const params = {
        message: { data, code: 200, user_id },
        to_user: to_user.data,
        qid,
        event,
        user_id,
        tema_id,
        gns: this.gns,
        type: 'NOTICE',
      }

      this.ws.send(JSON.stringify(params))
    } else {
      //对象存储存json数据

      //下载json测试
      // var a = document.createElement("a");
      // a.href = URL.createObjectURL(file);
      // a.download = "myFile.json";
      // a.click();
      let bucket_name = localStorage.getItem('bucket_name')
      let formData = new FormData()
      formData.append('bucket_name', bucket_name)
      formData.append('gns', this.gns)
      formData.append('file_type', 'OTHER_FILE')
      formData.append('file', file)

      let res = await putObjectToCache_api(formData)
      if (res.code === 200000) {
        // let paramsArr = [{ file_url: res.data.object_path, snow_id }];
        // this.getObjectData(res.data.object_path);
        const params = {
          message: { data: { file_url: res.data.object_path }, code: 200 },
          to_user: to_user.data,
          qid,
          event: 'DRAWING',
          user_id,
          tema_id,
          gns: this.gns,
          type: 'NOTICE',
        }
        this.ws.send(JSON.stringify(params))
      }
    }
  }
}
async function getObjectData(fileUrl) {
  let apiKey = localStorage.getItem('apiKey')
  let minio_name = localStorage.getItem('minio_name')
  let bucket_name = localStorage.getItem('bucket_name')
  let time = parseInt(new Date().getTime() / 1000)
  let file_content = await fetch('/file/api/v1/get_object_data/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      username: minio_name,
      sign: Md5(`${apiKey}_${time}`),
    },
    body: JSON.stringify({
      time,
      bucket_name,
      object_path: fileUrl,
    }),
  })

  let array_buffer = await file_content.arrayBuffer()
  let u8 = new Uint8Array(array_buffer)

  // let jsonObj = JSON.parse(String.fromCharCode.apply(null, u8));

  let enc = new TextDecoder('utf-8')
  // enc.decode(u8);
  let res = JSON.parse(enc.decode(u8))

  // let data_u8 = new Uint8Array(data_buffer);
  return res
}

async function applyHistoryToCell(cell, history, fileId) {
  for (let i = 0; i < history.actions.length; i++) {
    await applyActionToCell(cell, history.actions[i], fileId)
  }
}

async function applyActionToCell(cell, action, fileId) {
  if (action.action == 'add') {
    // action.objs.forEach(obj => {
    //   addObjToCell(cell, obj)
    // })
    const size = action.objs.length
    for (let i = 0; i < size; i++) {
      await addObjToCell(cell, action.objs[i])
    }
  } else if (action.action == 'delete') {
    action.objs.forEach(obj => {
      deleteObjToCell(cell, obj)
    })
  } else if (action.action == 'move') {
    action.objs.forEach(obj => {
      moveObj(cell, obj)
    })
  } else if (action.action == 'transform') {
    action.objs.forEach(obj => {
      transformObj(cell, obj)
    })
  } else if (action.action == 'property') {
    action.objs.forEach(obj => {
      propertyObj(cell, obj, fileId)
    })
  }
}

async function addObjToCell(cell, obj) {
  if (!obj) return
  const type = obj.t
  if (type == 0) {
    cell.add_flexpath(path(obj.ps, obj.off, obj.w, obj.r, obj.s_w, obj.s_p, obj.ly, obj.dt, obj.id))
  } else if (type == 1) {
    cell.add_polygon(polygon(obj.ps, obj.ly, obj.dt, obj.id))
  } else if (type == 2) {
    cell.add_polygon(rect(obj.ct, obj.w, obj.h, obj.ly, obj.dt, obj.id))
  } else if (type == 3) {
    cell.add_polygon(ellipse(obj.ct, obj.r_x, obj.r_y, obj.ly, obj.dt, obj.m_p, obj.id))
  } else if (type == 4) {
    cell.add_label(label(obj.txt, obj.ac, obj.o, obj.f_s, obj.mg, obj.x_f, obj.ly, obj.dt, obj.id))
  } else if (type == 5) {
    cell.add_label(keyPoint(obj.txt, obj.ac, obj.o, obj.f_s, obj.mg, obj.x_f, obj.dp, obj.ly, obj.dt, obj.id))
  } else if (type == 7) {
    const ref = await reference(obj)
    cell.add_reference(ref)
  }
  // else if(type == 6){
  //   cell.js_obj.push(ruler(obj.ps))
  // }
}

function deleteObjToCell(cell, obj) {
  if (!obj) return
  const type = obj.t
  if (type == 0) {
    const path = getIndexOfObj(obj.i, cell.flexpaths)
    cell.remove_flexpath(path)
  } else if (type == 1) {
    const polygon = getIndexOfObj(obj.i, cell.polygons)
    cell.remove_polygon(polygon)
  } else if (type == 2) {
    const rect = getIndexOfObj(obj.i, cell.polygons)
    cell.remove_polygon(rect)
  } else if (type == 3) {
    const e = getIndexOfObj(obj.i, cell.polygons)
    cell.remove_polygon(e)
  } else if (type == 4) {
    const label = getIndexOfObj(obj.i, cell.labels)
    cell.remove_label(label)
  } else if (type == 5) {
    const kp = getIndexOfObj(obj.i, cell.labels)
    cell.remove_label(kp)
  } else if (type == 6) {
  } else if (type == 7) {
    const ref = getIndexOfObj(obj.i, cell.referens)
    cell.remove_reference(ref)
  }
}

function moveObj(cell, obj) {
  if (!obj) return
  const type = obj.t
  const isPartChecked = obj.pc
  if (type == 0) {
    const path = getIndexOfObj(obj.i, cell.flexpaths)
    if (isPartChecked) {
      path.points = deepClone(obj.pos)
    } else {
      path.center = deepClone(obj.ct)
    }
  } else if (type == 1) {
    const polygon = getIndexOfObj(obj.i, cell.polygons)
    if (isPartChecked) {
      polygon.points = deepClone(obj.pos)
    } else {
      polygon.center = deepClone(obj.ct)
    }
  } else if (type == 2) {
    const rect = getIndexOfObj(obj.i, cell.polygons)
    if (isPartChecked) {
      rect.center = deepClone(obj.ct)
      rect.width = obj.w
      rect.height = obj.h
      rect.angle = obj.ro
    } else {
      rect.center = deepClone(obj.ct)
    }
  } else if (type == 3) {
    const ellipse = getIndexOfObj(obj.i, cell.polygons)
    ellipse.center = obj.ct
    if (isPartChecked) {
      ellipse.center = deepClone(obj.ct)
      ellipse.radius_x = obj.r_x
      ellipse.radius_y = obj.r_y
      ellipse.angle = obj.ro
    } else {
      ellipse.center = deepClone(obj.ct)
    }
  } else if (type == 4) {
    const label = getIndexOfObj(obj.i, cell.labels)
    label.origin = obj.o
  } else if (type == 5) {
    const kp = getIndexOfObj(obj.i, cell.labels)
    kp.origin = obj.o
  } else if (type == 6) {
    const ruler = getIndexOfObj(obj.i, cell.js_obj.rulers)
    ruler.points = obj.pos
  } else if (type == 7) {
    const ref = getIndexOfObj(obj.i, cell.referens)
    ref.origin = obj.o
  }
}

function transformObj(cell, obj) {
  if (!obj) return
  const type = obj.t
  if (type == 0) {
    const path = getIndexOfObj(obj.i, cell.flexpaths)
    // path.center = obj.ct
    // path.angle = obj.ro
    path.points = obj.ps
  } else if (type == 1) {
    const polygon = getIndexOfObj(obj.i, cell.polygons)
    // polygon.center = obj.ct
    // polygon.angle = obj.ro
    polygon.points = obj.ps
  } else if (type == 2) {
    const rect = getIndexOfObj(obj.i, cell.polygons)
    rect.center = obj.ct
    rect.angle = obj.ro
  } else if (type == 3) {
    const ellipse = getIndexOfObj(obj.i, cell.polygons)
    ellipse.center = obj.ct
    ellipse.angle = obj.ro
  } else if (type == 4) {
    const label = getIndexOfObj(obj.i, cell.labels)
    label.origin = obj.o
    label.angle = obj.ro
  } else if (type == 5) {
    const kp = getIndexOfObj(obj.i, cell.labels)
    kp.origin = obj.o
    kp.angle = obj.ro
  } else if (type == 6) {
    const ruler = getIndexOfObj(obj.i, cell.js_obj.rulers)
    ruler.points = obj.ps
  } else if (type == 7) {
    const ref = getIndexOfObj(obj.i, cell.referens)
    ref.origin = obj.o
    ref.rotation = obj.r
    ref.x_reflection = obj.x_f
    if (obj.rept) {
      ref.repetition.columns = obj.rept.c_s
      ref.repetition.rows = obj.rept.c_s
      ref.repetition.spacing = deepClone(obj.rept.s)
      // ref.repetition.type.value = obj.rept.t
      ref.repetition.v1 = deepClone(obj.rept.v1)
      ref.repetition.v2 = deepClone(obj.rept.v2)
    }
  }
}

//属性修改
function propertyObj(cell, obj, fileId) {
  if (!obj) return
  const type = obj.t
  if (type == 0) {
    const path = getIndexOfObj(obj.i, cell.flexpaths)
    // path.angle = obj.ro
    path.width = obj.w
    path.radius = obj.r
    path.layers = obj.ly
    path.id = obj.id
    path.datatype = obj.dt
    path.offset = obj.off
    path.scale_width = obj.s_w
    path.simple_path = obj.s_p
    path.points = obj.ps
  } else if (type == 1) {
    const polygon = getIndexOfObj(obj.i, cell.polygons)
    // polygon.center = obj.ct
    // polygon.angle = obj.ro
    polygon.points = obj.ps
    polygon.layer = obj.ly
    polygon.id = obj.id
    polygon.dt = obj.dt
  } else if (type == 2) {
    const rect = getIndexOfObj(obj.i, cell.polygons)
    rect.center = obj.ct
    rect.width = obj.w
    rect.height = obj.h
    rect.angle = obj.ro
    rect.layer = obj.ly
    rect.id = obj.id
    rect.dt = obj.dt
  } else if (type == 3) {
    const ellipse = getIndexOfObj(obj.i, cell.polygons)
    ellipse.center = obj.ct
    ellipse.radius_x = obj.r_x
    ellipse.radius_y = obj.r_y
    ellipse.max_points = obj.m_p
    ellipse.angle = obj.ro
    ellipse.layer = obj.ly
    ellipse.id = obj.id
    ellipse.dt = obj.dt
  } else if (type == 4) {
    const label = getIndexOfObj(obj.i, cell.labels)
    label.text = obj.txt
    label.font_size = obj.f_s
    label.origin = obj.o
    label.angle = obj.ro
    label.anchor = getKernelAnchor(obj.ac) //AnchornMap[obj.ac]
    label.magnification = obj.mg
    label.x_reflection = obj.x_f
    label.layer = obj.ly
    label.id = obj.id
    label.datatype = obj.dt
  } else if (type == 5) {
    const kp = getIndexOfObj(obj.i, cell.labels)
    kp.text = obj.txt
    kp.font_size = obj.f_s
    kp.origin = obj.o
    kp.angle = obj.ro
    kp.anchor = getKernelAnchor(obj.ac) //AnchornMap[obj.ac]
    kp.magnification = obj.mg
    kp.x_reflection = obj.x_f
    kp.layer = obj.ly
    kp.id = obj.id
    kp.datatype = obj.dt
    kp.need_be_dumped = obj.dp
  } else if (type == 6) {
    const ruler = getIndexOfObj(obj.i, cell.js_obj.rulers)
    ruler.points = obj.ps
  } else if (type == 7) {
    const ref = getIndexOfObj(obj.i, cell.referens)
    ref.origin = obj.o
    ref.rotation = obj.r
    ref.name = obj.n
    ref.x_reflection = obj.x_f
    ref.magnification = obj.m
    if (obj.rept) {
      ref.repetition.columns = obj.rept.c_s
      ref.repetition.rows = obj.rept.c_s
      ref.repetition.spacing = deepClone(obj.rept.s)
      // ref.repetition.type.value = obj.rept.t
      ref.repetition.v1 = deepClone(obj.rept.v1)
      ref.repetition.v2 = deepClone(obj.rept.v2)
    }
    if (obj.text && ref.cell.text) {
      let text = new Kernel.GdsText()
      text.anchor = getKernelAnchor(obj.text.av)
      text.text = obj.text.text
      text.size = obj.text.size
      text.datatype = obj.text.dt
      text.layer = obj.text.ly
      ref.cell.text = text
      updateCharcterCell(ref.cell, fileId)
    }

    if (obj.paras && ref.cell.pcell) {
      ref.cell.pcell.paras = obj.paras
      exChangeCpmponent(ref.cell, JSON.parse(obj.paras), fileId)
    }
  }
}

function path(points, off, width, radius, scale_width, simple_path, layers, datatype = 0, id) {
  const path = new Kernel.GdsFlexpath(points, 0, 0, 'round', 'flush', 0, 1e-2, true, true, 0, 0)
  path.width = width
  path.radius = radius
  path.layers = layers
  path.id = id
  path.datatype = datatype
  path.offset = off
  path.scale_width = scale_width
  path.simple_path = simple_path
  return path
}

function polygon(points, layer, datatype = 0, id) {
  let polygon = new Kernel.GdsPolygon()
  polygon.points = points
  polygon.layer = layer
  polygon.id = id
  polygon.datatype = datatype
  return polygon
}

function rect(center, width, height, layer, datatype = 0, id) {
  const rect = new Kernel.GdsRectangle(center, width, height)
  rect.layer = layer
  rect.id = id
  rect.datatype = datatype

  return rect
}

function ellipse(center, radius_x, radius_y, layer, datatype = 0, max_points = 40, id) {
  const e = new Kernel.GdsEllipse(center, radius_x, radius_y, max_points)
  e.layer = layer
  e.id = id
  e.datatype = datatype
  return e
}

function label(text, anchor_v, origin, font_size, magnification, x_reflection, layer, datatype = 0, id) {
  let label = new Kernel.GdsLabel()
  label.text = text
  label.font_size = font_size
  label.origin = origin
  label.anchor = getKernelAnchor(anchor_v) //AnchornMap[anchor_v]
  label.magnification = magnification
  label.x_reflection = x_reflection
  label.layer = layer
  label.id = id
  label.datatype = datatype
  return label
}
function keyPoint(text, anchor_v, origin, font_size, magnification, x_reflection, need_dump, layer, datatype = 0, id) {
  let kp = new Kernel.GdsKeyPoint()
  kp.text = text
  kp.font_size = font_size
  kp.origin = origin
  kp.anchor = getKernelAnchor(anchor_v) //AnchornMap[anchor_v]
  kp.magnification = magnification
  kp.x_reflection = x_reflection
  kp.layer = layer
  kp.id = id
  kp.datatype = datatype
  kp.need_be_dumped = need_dump
  return kp
}

async function reference(obj) {
  let ref = new Kernel.Reference()
  const cell = await getRefCellById(obj.pid, obj.fid, obj.cid)

  ref.cell = cell
  ref.name = obj.n
  ref.origin = deepClone(obj.o)
  ref.rotation = obj.r
  ref.x_reflection = obj.x_f

  ref.magnification = obj.m

  if (obj.rept) {
    ref.repetition.columns = data.rept.c_s
    ref.repetition.rows = data.rept.c_s
    ref.repetition.spacing = deepClone(data.rept.s)
    // ref.repetition.type.value = data.rept.t
    ref.repetition.v1 = deepClone(data.rept.v1)
    ref.repetition.v2 = deepClone(data.rept.v2)
  }
  return ref
}

//还原引用的cell
async function getRefCellById(pid, fid, cid) {
  // const cell =

  // let cell = null
  // if (!cid) {
  //   cell = null
  // } else {
  //   cell = getCellFromTable(cid)

  //   if (cell == null) {
  //     cell = await getCompleteCellData(pid, fid, cid)

  //   }
  // }
  let cell
  if (!cid) {
    cell = null
    resolve(cell)
  } else {
    cell = getCellFromTable(cid)

    if (cell == null) {
      cell = await getCompleteCellData(pid, fid, cid)
    }
  }

  return cell

  // return cell
}

async function getCell(pid, fid, cid) {
  return getCompleteCellData(pid, fid, cid)

  // return new Promise(async resolve => {
  //   let cell
  //   if (!cid) {
  //     cell = null
  //     resolve(cell)
  //   } else {
  //     cell = getCellFromTable(cid)

  //     if (cell == null) {
  //       cell = await getCompleteCellData(pid, fid, cid)

  //       resolve(cell)
  //     } else {
  //       resolve(cell)
  //     }
  //   }
  // })
}
//更新画板渲染
function updateCellLayout(cell, fileId) {
  if (window.QLayouts) {
    for (let i = 0; i < window.QLayouts.length; i++) {
      const QLayout = window.QLayouts[i]
      if (QLayout.constructor.name == 'QedaCellLayout') {
        if (QLayout.STATUS.active && QLayout.RENDER_CELL.snow_id == cell.snow_id) {
          QLayout.updateByMessageServe() //更新当前打开的画板图形数据
        } else if (isLayoutRefTaregtCell(QLayout, fileId, cell)) {
          QLayout.updateByMessageServe() //更新引用到此画板的图形
        }
      }
    }
  }
}

//器件1是否引用器件2
function isLayoutRefTaregtCell(QLayout, fileId, targetCell) {
  if (QLayout.fileInfo.fileId === fileId) {
    const depend_cells = QLayout.RENDER_CELL.depend_cells(-1)
    for (let i = 0; i < depend_cells.length; i++) {
      const cell = depend_cells[i]
      if (cell.snow_id == targetCell.snow_id) {
        return true
      }
    }
    return false
  }
}
//转换为绘制消息
export function transAddMsg(proinfo, history, cell, isUndo = false, isRedo = false) {
  let action = 'add'
  if (isUndo) action = 'delete'
  let msg = {}
  if (action == 'add') {
    msg = {
      func: history.func,
      isUndo,
      isRedo,
      actions: [{ action, objs: [copyObjForMsg(history.actions[0].objs[0], proinfo)] }],
    }
  } else if ((action = 'delete')) {
    msg = {
      func: history.func,
      isUndo,
      isRedo,
      actions: [{ action, objs: [copyIndexForMsg(cell, history.actions[0].objs[0], proinfo)] }],
    }
  }
  return msg
}
//转换为移动消息
export function transMoveMsg(proinfo, history, cell, isUndo = false, isRedo = false) {
  const msg = {
    func: history.func,
    params: history.params,
    isUndo,
    isRedo,
    actions: [{ action: 'move', objs: copyObjsPosForMsg(cell, history.actions[0].objs, proinfo) }],
  }
  return msg
}

//转换为旋转消息
export function transTransformMsg(proinfo, history, cell, isUndo = false, isRedo = false) {
  const msg = {
    func: history.func,
    params: history.params,
    isUndo,
    isRedo,
    actions: [{ action: 'transform', objs: copyObjsTransformForMsg(cell, history.actions[0].objs, proinfo) }],
  }
  return msg
}

//转换为绘制消息
export function transDelAddMsg(proinfo, history, cell, isUndo = false, isRedo = false) {
  let msg = {
    func: history.func,
    actions: [],
    isUndo,
    isRedo,
  }
  history.actions.forEach(item => {
    let action = item.action
    if (isUndo) {
      if (action == 'add') {
        action = 'delete'
      } else if ((action = 'delete')) {
        action = 'add'
      }
    }
    if (action == 'add') {
      msg.actions.push({ action, objs: copyObjsForMsg(item.objs, proinfo) })
    } else if (action == 'delete') {
      const objIndexs = copyIndexesForMsg(cell, item.objs, proinfo).sort((a, b) => b.i - a.i) //索引从大到小排序
      msg.actions.push({ action, objs: objIndexs })
    }
  })
  return msg
}

//转换为属性修改消息
export function transPropertyMsg(proinfo, history, cell, isUndo = false, isRedo = false) {
  const msg = {
    func: history.func,
    params: history.params,
    isUndo,
    isRedo,
    actions: [{ action: 'property', objs: copyObjsPropertyformForMsg(cell, history.actions[0].objs, proinfo) }],
  }
  return msg
}

export function copyObjsPropertyformForMsg(cell, objs, proinfo) {
  const res = []
  objs.forEach(obj => res.push(copyObjPropertyformForMsg(cell, obj, proinfo)))
  return res
}

export function copyObjPropertyformForMsg(cell, obj, proinfo) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    return {
      t: 0,
      i: getObjIndexInCell(cell, obj, TYPE),
      ro: obj.angle,
      ps: obj.points,
      ly: obj.layers,
      id: obj.id,
      off: obj.offset,
      w: obj.width,
      r: obj.radius,
      s_w: obj.scale_width,
      s_p: obj.simple_path,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsPolygon*') {
    return {
      t: 1,
      i: getObjIndexInCell(cell, obj, TYPE),
      ct: obj.center,
      ps: obj.points,
      ro: obj.angle,
      ly: obj.layer,
      id: obj.id,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsRectangle*') {
    return {
      t: 2,
      i: getObjIndexInCell(cell, obj, TYPE),
      ct: obj.center,
      ro: obj.angle,
      ly: obj.layer,
      id: obj.id,
      w: obj.width,
      h: obj.height,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsEllipse*') {
    return {
      t: 3,
      i: getObjIndexInCell(cell, obj, TYPE),
      ct: obj.center,
      ro: obj.angle,
      ly: obj.layer,
      id: obj.id,
      r_x: obj.radius_x,
      r_y: obj.radius_y,
      m_p: obj.max_points,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsLabel*') {
    return {
      t: 4,
      i: getObjIndexInCell(cell, obj, TYPE),
      ac: obj.anchor.value,
      o: obj.origin,
      ro: obj.angle,
      ly: obj.layer,
      id: obj.id,
      f_s: obj.font_size,
      mg: obj.magnification,
      txt: obj.text,
      x_f: obj.x_reflection,
      dt: obj.datatpye,
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      t: 5,
      i: getObjIndexInCell(cell, obj, TYPE),
      ac: obj.anchor.value,
      o: obj.origin,
      ro: obj.angle,
      ly: obj.layer,
      id: obj.id,
      f_s: obj.font_size,
      mg: obj.magnification,
      txt: obj.text,
      x_f: obj.x_reflection,
      dt: obj.datatpye,
      dp: obj.need_be_dumped,
    }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))
    const data = {
      t: 7,
      i: getObjIndexInCell(cell, obj, TYPE),
      n: obj.name,
      pid: proinfo.pid,
      fid: proinfo.fid,
      cid: obj.cell?.snow_id,
      o: obj.origin,
      m: obj.magnification,
      r: obj.rotation,
      x_f: obj.x_reflection,
      // refered_cell_id: obj.refered_cell_id,
      rept: {
        c_s: obj.repetition.columns,
        r_s: obj.repetition.rows,
        // size: obj.repetition.size,
        s: obj.repetition.spacing,
        // type: obj.repetition.type.value,
        v1: obj.repetition.v1,
        v2: obj.repetition.v2,
      },
    }
    if (obj.repetition.columns || obj.repetition.rows) {
      //存在阵列
      data.rept = {
        c_s: obj.repetition.columns,
        r_s: obj.repetition.rows,
        // size: obj.repetition.size,
        s: obj.repetition.spacing,
        t: obj.repetition.type.value,
        v1: obj.repetition.v1,
        v2: obj.repetition.v2,
      }
    }
    if (obj.js_obj.textChange && obj.cell?.text) {
      let text = obj.cell.text
      data.text = {
        ly: text.layer,
        dt: text.datatype,
        size: text.size,
        av: text.anchor.value,
        text: text.text,
      }
    }
    if (obj.js_obj.paramsChange && obj.cell?.pcell) {
      data.paras = obj.cell.pcell.paras
    }
    return data
  }
}

export function copyObjsForMsg(objs, proinfo) {
  const res = []
  objs.forEach(obj => res.push(copyObjForMsg(obj, proinfo)))
  return res
}
//复制基础对象用于消息通信
export function copyObjForMsg(obj, proinfo) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    return {
      t: 0,
      ps: obj.points,
      ly: obj.layers,
      id: obj.id,
      off: obj.offset,
      w: obj.width,
      r: obj.radius,
      s_w: obj.scale_width,
      s_p: obj.simple_path,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsPolygon*') {
    return {
      t: 1,
      ps: obj.points,
      ly: obj.layer,
      id: obj.id,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsRectangle*') {
    return {
      t: 2,
      ct: obj.center,
      ly: obj.layer,
      id: obj.id,
      w: obj.width,
      h: obj.height,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsEllipse*') {
    return {
      t: 3,
      ct: obj.center,
      ly: obj.layer,
      id: obj.id,
      r_x: obj.radius_x,
      r_y: obj.radius_y,
      m_p: obj.max_points,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsLabel*') {
    return {
      t: 4,
      ac: obj.anchor.value,
      o: obj.origin,
      ly: obj.layer,
      id: obj.id,
      f_s: obj.font_size,
      mg: obj.magnification,
      txt: obj.text,
      x_f: obj.x_reflection,
      dt: obj.datatype,
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      t: 5,
      ac: obj.anchor.value,
      o: obj.origin,
      ly: obj.layer,
      id: obj.id,
      f_s: obj.font_size,
      mg: obj.magnification,
      txt: obj.text,
      x_f: obj.x_reflection,
      dt: obj.datatype,
      dp: obj.need_be_dumped,
    }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))
    const data = {
      t: 7,
      n: obj.name,
      pid: proinfo.pid,
      fid: proinfo.fid,
      cid: obj.cell?.snow_id,
      o: obj.origin,
      m: obj.magnification,
      r: obj.rotation,
      x_f: obj.x_reflection,
      // refered_cell_id: obj.refered_cell_id,
      // rept: {
      //   c_s: obj.repetition.columns,
      //   r_s: obj.repetition.rows,
      //   // size: obj.repetition.size,
      //   spacing: obj.repetition.spacing,
      //   type: obj.repetition.type.value,
      //   v1: deepClone(obj.repetition.v1),
      //   v2: deepClone(obj.repetition.v2),
      // },
    }
    if (obj.repetition.columns || obj.repetition.rows) {
      //存在阵列
      data.rept = {
        c_s: obj.repetition.columns,
        r_s: obj.repetition.rows,
        // size: obj.repetition.size,
        s: obj.repetition.spacing,
        t: obj.repetition.type.value,
        v1: obj.repetition.v1,
        v2: obj.repetition.v2,
      }
    }
    return data
  }
  //  else if (obj.constructor.name == 'Ruler') {
  //   return { t: 6, ps: obj.points }
  // }
}

//复制对象下标
export function copyIndexesForMsg(cell, objs, proinfo) {
  let res = []
  objs.forEach(obj => {
    res.push(copyIndexForMsg(cell, obj, proinfo))
  })
  return res
}

export function copyIndexForMsg(cell, obj, proInfo) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    return {
      t: 0,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'GdsPolygon*') {
    return {
      t: 1,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'GdsRectangle*') {
    return {
      t: 2,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'GdsEllipse*') {
    return {
      t: 3,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'GdsLabel*') {
    return {
      t: 4,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      t: 5,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  } else if (TYPE == 'Ruler') {
    // return { t: 6, ps: deepClone(obj.points), TYPE }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))
    return {
      t: 7,
      i: getObjIndexInCell(cell, obj, TYPE),
    }
  }
}

//复制对象集合的位置坐标
export function copyObjsPosForMsg(cell, objs, proInfo) {
  let res = []
  objs.forEach(obj => {
    res.push(copyPosForMsg(cell, obj, proInfo))
  })
  return res
}

export function copyPosForMsg(cell, obj, proInfo) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  const isPartChecked = obj.js_obj.STATE.partChecked
  if (TYPE == 'GdsFlexpath*') {
    const data = {
      t: 0,
      i: getObjIndexInCell(cell, obj, TYPE),
      pc: isPartChecked,
    }
    if (isPartChecked) {
      data.pos = obj.points
    } else {
      data.ct = obj.center
    }
    return data
  } else if (TYPE == 'GdsPolygon*') {
    const data = {
      t: 1,
      i: getObjIndexInCell(cell, obj, TYPE),
      pc: isPartChecked,
    }
    if (isPartChecked) {
      data.pos = obj.points
    } else {
      data.ct = obj.center
    }
    return data
  } else if (TYPE == 'GdsRectangle*') {
    const data = {
      t: 2,
      i: getObjIndexInCell(cell, obj, TYPE),
      pc: isPartChecked,
    }
    if (isPartChecked) {
      data.ct = obj.center
      data.w = obj.width
      data.h = obj.height
      data.ro = obj.angle
    } else {
      data.ct = obj.center
    }
    return data
  } else if (TYPE == 'GdsEllipse*') {
    const data = {
      t: 3,
      i: getObjIndexInCell(cell, obj, TYPE),
      pc: isPartChecked,
    }
    if (isPartChecked) {
      data.ct = obj.center
      data.r_x = obj.radius_x
      data.r_y = obj.radius_y
      data.ro = obj.angle
    } else {
      data.ct = obj.center
    }
    return data
  } else if (TYPE == 'GdsLabel*') {
    return {
      t: 4,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      t: 5,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
    }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))
    return {
      t: 7,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
      // pid: proInfo.pid,
      // fid: proInfo.fid,
      // cid: obj?.snow_id,
    }
  }
  // else if (obj.constructor.name == 'Ruler') {
  //   return { t: 6, ps: deepClone(obj.points), TYPE }
  // }
}

//复制对象集合的位置坐标
export function copyObjsTransformForMsg(cell, objs, proInfo) {
  let res = []
  objs.forEach(obj => {
    res.push(copyTfForMsg(cell, obj, proInfo))
  })
  return res
}

//复制变换
export function copyTfForMsg(cell, obj, proInfo) {
  const TYPE = obj.$$?.ptrType.name //obj.constructor.name
  if (TYPE == 'GdsFlexpath*') {
    return {
      t: 0,
      i: getObjIndexInCell(cell, obj, TYPE),
      ps: obj.points,
    }
  } else if (TYPE == 'GdsPolygon*') {
    return {
      t: 1,
      i: getObjIndexInCell(cell, obj, TYPE),
      ps: obj.points,
    }
  } else if (TYPE == 'GdsRectangle*') {
    return {
      t: 2,
      i: getObjIndexInCell(cell, obj, TYPE),
      ct: obj.center,
      ro: obj.angle,
    }
  } else if (TYPE == 'GdsEllipse*') {
    return {
      t: 3,
      i: getObjIndexInCell(cell, obj, TYPE),
      ct: obj.center,
      ro: obj.angle,
    }
  } else if (TYPE == 'GdsLabel*') {
    return {
      t: 4,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
      ro: obj.angle,
    }
  } else if (TYPE == 'GdsKeyPoint*') {
    return {
      t: 5,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
      ro: obj.angle,
    }
  } else if (TYPE == 'Reference*') {
    // alert(deepClone(obj.origin))
    return {
      t: 7,
      i: getObjIndexInCell(cell, obj, TYPE),
      o: obj.origin,
      r: obj.rotation,
      x_f: obj.x_reflection,
      rept: {
        c_s: obj.repetition.columns,
        r_s: obj.repetition.rows,
        // size: obj.repetition.size,
        s: obj.repetition.spacing,
        // type: obj.repetition.type.value,
        v1: obj.repetition.v1,
        v2: obj.repetition.v2,
      },
    }
  }
  // else if (TYPE == 'Ruler') {
  //   return { t: 6, ps: deepClone(obj.points) }
  // }
}

export function getObjIndexInCell(cell, obj, TYPE) {
  if (TYPE == 'GdsFlexpath*') {
    return getObjIndex(obj, cell.flexpaths)
  } else if (TYPE == 'GdsPolygon*') {
    return getObjIndex(obj, cell.polygons)
  } else if (TYPE == 'GdsRectangle*') {
    return getObjIndex(obj, cell.polygons)
  } else if (TYPE == 'GdsEllipse*') {
    return getObjIndex(obj, cell.polygons)
  } else if (TYPE == 'GdsLabel*') {
    return getObjIndex(obj, cell.labels)
  } else if (TYPE == 'GdsKeyPoint*') {
    return getObjIndex(obj, cell.labels)
  } else if (TYPE == 'Reference*') {
    return getObjIndex(obj, cell.referens)
  } else if (obj.constructor.name == 'Ruler') {
    return getObjIndex(obj, cell.js_obj.rulers)
  }
}

//获取对象下标
export function getObjIndex(obj, arry) {
  const index = arry.findIndex(item => {
    if (item == obj) return true
    if (obj.$$ && obj.$$.ptr == item.$$.ptr) return true
  })
  return index
}

export function getIndexOfObj(index, arry) {
  return arry[index]
}
