const { PriorityQueue } = require('@datastructures-js/priority-queue')
import { GridMap } from './grid_map'

export class AStar {
  constructor(start_arr, goal_arr) {
    this.show_animation = true
    this.start_arr = start_arr || []
    this.goal_arr = goal_arr || []
    this.graph = new GridMap(10, 0)
    this.D = 1

    this.heuristic_conf = {
      g_cost: 'Diagonal', //Manhattan, Diagonal, Euclidean
      g_weight: 1.0,
      h_cost: 'Euclidean', //Manhattan, Diagonal, Euclidean
      h_weight: 1.0,
      reduce_corner: false, //Manhattan, Diagonal, Euclidean
    }
  }

  setGraphBox(box) {
    this.graph.setBox(box)
  }

  setStart(start_arr) {
    this.start_arr = start_arr
    this.graph.add_obstacle(this.start_arr)
  }

  setGoal(goal_arr) {
    this.goal_arr = goal_arr
    this.graph.add_obstacle(this.goal_arr)
  }

  setConf(heuristic_conf) {
    this.heuristic_conf = heuristic_conf
  }

  setCanvas(canvas) {
    this.canvas = canvas
  }

  search_arr_two(self) {
    // 所有起点终点周围添加障碍
    // this.graph.map_add_obstacle(this.start_arr)
    // this.graph.map_add_obstacle(this.goal_arr)

    let length = len(this.start_arr)
    for (let i = 0; i < parseInt(length / 2); i++) {
      let start = this.start_arr[i]
      let goal = this.goal_arr[i]
      this.search_one(start, goal)

      start = this.start_arr[length - i - 1]
      goal = this.goal_arr[length - i - 1]
      this.search_one(start, goal)
    }
    //   for (let i=0;i<length;i++){
    //       if (i % 5 ==0 )
    //           p = this.goal_arr[i]
    //           this.plt.text(p[0], p[1], '%d'%(i))
    //   }
    return
  }

  search_arr() {
    // 所有起点终点周围添加障碍
    //   print('start:', this.start_arr)
    //   print('goal:', this.goal_arr)
    // this.graph.add_obstacle(this.start_arr)
    // this.graph.add_obstacle(this.goal_arr)
    // let length = len(this.start_arr)
    for (let i = 0; i < this.start_arr.length; i++) {
      let start = this.start_arr[i]
      let goal = this.goal_arr[i]
      this.search_one(start, goal)
    }
    return
  }

  search_one(start, goal) {
    // 起点终点吸附到地图网格点上
    let map_start = this.graph.calc_grid_adsorp_xy(start)
    let map_goal = this.graph.calc_grid_adsorp_xy(goal)

    this.graph.remove_obstacle([map_start, map_goal])
    let came_from = this.search(map_start, map_goal)

    let path = this.get_road_pos(map_start, map_goal, came_from)
    path = this.simple_curve_angle(path, 30)

    // print(index, map_start, '->', map_goal)

    // if (path) {
    //   this.graph.add_obstacle(path)
    // }
    this.graph.add_obstacle([start, goal])
    return path
  }

  search(start, goal) {
    //   """搜寻路径
    //   代价函数: f(n) = g(n) + h(n)
    //   f(n) 是从起点经过点n到终点的代价估计
    //   g(n): 起点到点n的实际代价
    //   h(n): 点n到终点的预估代价
    //   调整g或h值, 来改变走线趋势
    //   """
    // 创建优先队列
    let frontier = new PriorityQueue((a, b) => {
      return a.cost < b.cost ? -1 : 1
    })
    frontier.push({ cost: 0, pos: start })

    // 加入起点
    let came_from = {}
    let cost_so_far = {}
    came_from[start] = null
    cost_so_far[start] = 0

    let next_pos = []

    let h_cost_type = this.heuristic_conf['h_cost']
    let h_weight = this.heuristic_conf['h_weight']
    let g_cost_type = this.heuristic_conf['g_cost']
    let g_weight = this.heuristic_conf['g_weight']
    let reduce_corner = this.heuristic_conf['reduce_corner']

    let g_cost = 0
    let h_cost = 0
    let weight_corner = 0

    let step = 0

    while (frontier.size()) {
      step += 1
      if (step > 1000) {
        break
      }
      let f = frontier.pop()
      let current = f.pos
      if (current.toString() == goal.toString()) break
      let neighbors = this.graph.neighbors(current)
      // 检测周围邻近位置路径代价
      neighbors.forEach(next => {
        next_pos.push(next)
        // 拐角权重计算
        // if (reduce_corner) weight_corner = this.cacl_corner_weigth(came_from[current], current, next)
        // else weight_corner = 0
        // print('weight_corner: ', weight_corner)
        // 计算当前位置到起点的代价
        // 离起点的距离代价
        //Manhattan, Diagonal, Euclidean
        if (g_cost_type == 'Manhattan') g_cost = cost_so_far[current] + this.graph.cost_Manhattan(current, next, 1) + 1.0 * weight_corner
        if (g_cost_type == 'Diagonal') g_cost = cost_so_far[current] + this.graph.cost_Diagonal(current, next, 1) + 1.0 * weight_corner
        if (g_cost_type == 'Euclidean') g_cost = cost_so_far[current] + this.graph.cost_Euclidean(current, next, 1) + 1.0 * weight_corner
        if (!cost_so_far[next] || g_cost < cost_so_far[next]) {
          cost_so_far[next] = g_cost
          // 离终点的距离代价
          if (h_cost_type == 'Euclidean') h_cost = this.heuristic_Euclidean(goal, next, 1)
          if (h_cost_type == 'Diagonal') h_cost = this.heuristic_Diagonal(goal, next, 1)
          if (h_cost_type == 'Manhattan') h_cost = this.heuristic_Manhattan(goal, next, 1)
          // f_cost = g_cost + 2.0*h_cost
          let f_cost = g_weight * g_cost + h_weight * h_cost
          frontier.push({ cost: f_cost, pos: next })
          came_from[next] = current
        }
      })
    }
    return came_from
  }

  get_road_pos(start, goal, came_from) {
    //"""计算路径, 通过点位获取"""
    if (!came_from[goal]) {
      return []
    }
    let current = goal
    let path = []
    while (current != start) {
      path.push(current)
      current = came_from[current]
    }
    path.push(start) // optional
    path.reverse() // optional
    return path
  }

  get_road_edge(start, goal, came_from) {
    //"""计算路径, 通过网格边来获取"""
    if (!came_from[goal]) return []
    path = [goal]
    pos = goal
    while (true) {
      if (came_from[pos]) {
        next = came_from[pos]
        edge = [next[0] - (next[0] - pos[0]) / 2, next[1] - (next[1] - pos[1]) / 2]
        path.push(edge)
        pos = next
      } else break
    }
    path.push(start)
    return path
  }

  cacl_corner_weigth(parent, current, next) {
    // 计算拐角权重
    let weight_corner = 0
    if (parent) {
      let direct1 = Math.atan2(next[1] - current[1], next[0] - current[0])
      let direct2 = Math.atan2(current[1] - parent[1], current[0] - parent[0])
      if (Math.abs(direct1 - direct2) > 1) weight_corner = 1
    }
    return weight_corner
  }

  heuristic_Manhattan(a, b, D) {
    //"""方格上的曼哈顿距离"""
    let dx = Math.abs(a[0] - b[0])
    let dy = Math.abs(a[1] - b[1])
    return D * (dx + dy)
  }

  heuristic_Euclidean(a, b, D) {
    //"""方格上的欧几里得距离"""
    let dx = Math.abs(a[0] - b[0])
    let dy = Math.abs(a[1] - b[1])
    return D * Math.sqrt(dx * dx + dy * dy)
    // return D*(dx*dx + dy*dy)
  }

  heuristic_Diagonal(a, b, D) {
    //"""方格上的对角线移动距离"""
    let dx = Math.abs(a[0] - b[0])
    let dy = Math.abs(a[1] - b[1])
    // D2 = 1.41421356
    let D2 = 1
    return D * (dx + dy) + (D2 - 2 * D) * Math.min(dx, dy)
  }

  simple_curve_angle(curve, angle) {
    //"""简化曲线，提取线骨骼点, 采用角度法"""
    function run() {
      for (let idx = 0; idx < curve.length - 3; idx++) {
        let a = curve[idx]
        let b = curve[idx + 1]
        let c = curve[idx + 2]
        let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
        let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])

        let err = ag_a_b - ag_b_c
        // print('a->b->c:', a, b, c,  'err:', err * 180 / 3.14)
        if (Math.abs((err * 180) / 3.14) < angle) {
          //   del curve[idx+1]
          return false
        }
        return true
      }
    }
    run()
    // while (!run()) {}
    return curve
  }
}
