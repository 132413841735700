import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/components/homes/Layout.vue'
// import toolsRouter from './modules/tools/index'

Vue.use(Router)

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default new Router({
  routes: [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '../components/common/404.vue'),
      meta: { title: '404' },
    },
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      // redirect: '/tools/autoline',
      redirect: '/home/layout',
      meta: {
        title: 'QEDA',
      },
      children: [
        {
          path: '/home/layout',
          meta: { title: '画板' },
          // component: () => import('@/components/homes/Layout.vue'),
          component: Layout,
          // redirect: '/home/layout/board',
          children: [
            {
              path: '/home/layout/board',
              component: () => import('../components/homes/DrawBoard.vue'),
              meta: { title: '画板测试' },
            },
          ],
        },
      ],
    },

    // 登录
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "home" */ '../components/login/Login.vue'),
      meta: { title: 'QEDA' },
    },
    // 修改密码
    {
      path: '/password',
      meta: {
        title: '修改密码',
      },
      component: () => import('@/components/homes/Password.vue'),
    },
    {
      path: '/user',
      meta: { title: '个人中心' },
      component: () => import('@/components/user/User.vue'),
      children: [
        {
          path: '/user',
          redirect: '/user/personal',
        },
        {
          path: '/user/personal',
          meta: { title: '个人中心' },
          component: () => import('@/components/user/Personal.vue'),
        },
        {
          path: '/user/teaminfo',
          meta: { title: '团队信息' },
          component: () => import('@/components/user/Teaminfo.vue'),
        },
        {
          path: '/user/userinfo',
          meta: { title: '用户信息' },
          component: () => import('@/components/user/Userinfo.vue'),
        },
      ],
    },

    // 工具
    {
      path: '/tools/autoline',
      meta: { title: 'autoline' },
      component: () => import('@/components/tools/pixijs2d/index.vue'),
    },

    // pcell
    {
      path: '/pcell',
      meta: { title: 'pcell' },
      component: () => import('@/components/pcell/pcell.vue'),
    },
    // {
    //   path: '/pdkgenerator',
    //   meta: { title: 'pdkgenerator' },
    //   component: () => import('@/components/pdkgenerator/pdkgenerator.vue'),
    // },

    // 3D
    {
      path: '/3d-view',
      meta: { title: '3D-View' },
      component: () => import('@/components/3d/3D-View.vue'),
    },
    // ws demo
    {
      path: '/ws/demo',
      meta: { title: 'demo' },
      component: () => import('@/components/homes/WsDemo.vue'),
    },
    {
      path: '/newboard',
      component: () => import('../components/homes/DrawBoardNew.vue'),
      meta: { title: '新画板测试' },
    },

    {
      path: '/test/pcell',
      meta: { title: 'test pcell' },
      component: () => import('@/components/homes/test/pcell.vue'),
    },

    // three测试页面
    {
      path: '/test/three_test',
      meta: { title: 'three_test' },
      component: () => import('@/components/homes/test/three_test.vue'),
    },
  ],
})
