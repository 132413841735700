<template>
  <div class="fileBar" onselectstart="javascript:return false;">
    <div class="tab">
      <el-tabs v-model="activeTabName" type="border-card" closable @tab-remove="closeDialog" @tab-click="handleClickTab($event.name)">
        <el-tab-pane v-for="item of editableTabs" :key="item.name" :name="item.name">
          <span slot="label" style="font-size: 14px; line-height: 31px; display: flex">
            <i :class="item.fileBarIcon" style="padding-right: 6px; font-size: 18px"></i>
            <span style="padding-right: 2px; font-size: 18px" v-if="!item.isSaved">*</span>
            <div style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
              <span style="font-size: 14px" v-if="item.isReadOnly">{{ `[${$t('messages.readOnly')}]` }}</span>
              {{ item.cellTitle }}
            </div>
          </span>
        </el-tab-pane>
        <!-- <el-tab-pane
          v-for="item of editableTabs"
          :key="item.name"
          :label="item.title"
        >
        <span>{{item.name}}</span>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="layoutbox">
      <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible" width="30%" :before-close="dialogClose">
        <span v-if="readOnly">{{ $t('messages.sureToClose') }}</span>
        <span v-else>{{ $t('messages.saveChanges') }}</span>
        <span slot="footer" class="dialog-footer" v-if="readOnly">
          <el-button type="primary" @click="
              dialogVisible = false
              handleClose(4, targetName)
            ">
            {{ $t('messages.sure') }}
          </el-button>
          <el-button @click="
              dialogVisible = false
              handleClose(3, targetName)
            ">
            {{ $t('messages.cancel') }}
          </el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-else>
          <el-button type="primary" @click="
              dialogVisible = false
              handleClose(1, targetName)
            ">
            {{ $t('messages.sure') }}
          </el-button>
          <el-button type="danger" @click="
              dialogVisible = false
              handleClose(2, targetName)
            ">
            {{ $t('messages.closeWithoutSaving') }}
          </el-button>
          <el-button @click="
              dialogVisible = false
              handleClose(3, targetName)
            ">
            {{ $t('messages.cancel') }}
          </el-button>
        </span>
      </el-dialog>
      <router-view></router-view>
    </div>
    <!-- 画板底部信息栏 -->
    <!-- <footer class="footer">
      <div class="selectedInfo">
        {{'选中的信息'}}
      </div>
      <div class="operation">
        {{'正在进行的操作'}}
      </div>
    </footer> -->
  </div>
</template>
<script>
import bus from "@/components/common/bus";
import {
  getCellFromTable,
  setDataToCellTable,
  reassembCell,
  saveCellFn,
} from "./fileList/function/fileListPublicFn";
import { getObjectData } from "./fileList/function/objectStorageFn";
import { unLockCell_api } from "@/api/file/file.js";
import { closeAllTab } from "./filebar/function/filebarFn.js";
export default {
  data() {
    return {
      closeSuggest: false,
      changeProject: false,
      dialogVisible: false,
      fileBarIcon: "",
      activeTabName: "",
      editableTabs: [],
      openedTab: [],
      targetName: "", //当前路由
      clickRoute: "",
      readOnly: false,
    };
  },
  created() {
    this.$router.push("/home");
  },
  mounted() {
    bus.$on("jumpToOtherPage", (data) => {
      closeAllTab(this.editableTabs, data);
    });
    bus.$on("fileBarIcon", (data) => {
      if (data == "1") {
        this.fileBarIcon = "iconfont icon-bantuming";
      } else if (data == "2") {
        this.fileBarIcon = "iconfont icon-qijianming";
      } else if (data == "3") {
        this.fileBarIcon = "iconfont icon-yuanlitu";
      }
    });
    bus.$on("resetNameFileBar", (data) => {
      if (data[3] == "项目") {
        let index = this.editableTabs.findIndex(
          (item) => item.proTitleId == data[2]
        );
        if (index !== -1) {
          this.editableTabs[index].proTitle = data[1];
        }
      } else if (data[3] == "文件") {
        let index = this.editableTabs.findIndex(
          (item) => item.fileTitleId == data[2]
        );
        if (index !== -1) {
          this.editableTabs[index].fileTitle = data[1];
        }
      } else if (data[3] == "CELL") {
        let index = this.editableTabs.findIndex(
          (item) => item.cellTitleId == data[2]
        );
        if (index !== -1) {
          this.editableTabs[index].cellTitle = data[1];
        }
      }
    });
    //tab对应的画板做出了修改
    bus.$on("fileChange", (data) => {
      let index = this.editableTabs.findIndex(
        (item) => item.cellTitleId === this.$store.state.cellInfo.cellId
      );

      this.editableTabs[index].isSaved = false;
    });
    //tab对应的画板触发保存
    bus.$on("saveCellSuccess", (cellId) => {
      let saveCell = this.editableTabs.find(
        (item) => item.cellTitleId === cellId
      );
      if (saveCell) {
        saveCell.isSaved = true;
      }
    });
    bus.$on("saveHasCell", (cellId) => {
      let saveCell = this.editableTabs.find(
        (item) => item.cellTitleId === cellId
      );
      if (saveCell) {
        saveCell.isSaved = true;
      }
      this.$message({
        message: this.$t("messages.fileTip15"),
        type: "warning",
      });
    });
    bus.$on("saveSchemaFileSuccess", (data) => {
      let saveArr = this.editableTabs.filter(
        (item) => item.cellTitleId === data
      );

      if (saveArr.length) {
        saveArr[0].isSaved = true;
      }

      this.$message.success(this.$t("messages.saveSuccess"));
    });

    // bus.$on("PDKLoadEnd", projectId => {

    //   let fileIdList = []
    //   this.editableTabs.forEach(item => {
    //     if (item.proTitleId === projectId && item.file_type === 1) {
    //       fileIdList.push(item.fileTitleId)
    //     }
    //   });

    //   // 更新这些文件图层

    //   bus.$emit("PDKLoadUpdateFileLayers", fileIdList)
    // })
  },
  beforeDestroy() {
    bus.$off("jumpToOtherPage");
    bus.$off("fileBarIcon");
    bus.$off("resetNameFileBar");
    bus.$off("fileChange");
    bus.$off("saveCellSuccess");
    bus.$off("saveHasCell");
    bus.$off("saveSchemaFileSuccess");
  },
  methods: {
    //弹出弹窗
    closeDialog(targetName) {
      let closeTabData = this.openedTab.filter(
        (item) => item.route == targetName
      )[0];

      if (closeTabData.isReadOnly) {
        this.readOnly = true;
      } else {
        this.readOnly = false;
      }
      this.dialogVisible = true;
      this.targetName = targetName;
    },
    //tab栏单击事件
    handleClickTab(route) {
      this.clickRoute = route;
      if (route !== this.$route.fullPath) {
        this.clickTab(this.clickRoute);
      }
    },
    clickTab(route) {
      let cellData = this.editableTabs.filter((item) => item.name == route)[0];
      let routeInfo = {
        route,
        isReadOnly: cellData.isReadOnly,
      };

      this.$store.commit("activeTab", routeInfo);
      let cellInfo = [];
      if (cellData.hasOwnProperty("cellTeamRule")) {
        cellInfo = [
          {
            teamId: cellData.teamTitleId,
            name: cellData.teamTitle,
            teamRole: cellData.teamTeamRole,
          },
          {
            projectId: cellData.proTitleId,
            name: cellData.proTitle,
            teamRule: cellData.proTeamRule,
          },
          {
            fileId: cellData.fileTitleId,
            name: cellData.fileTitle,
            teamRule: cellData.fileTeamRule,
            file_type: cellData.file_type,
          },
          {
            cellId: cellData.cellTitleId,
            name: cellData.cellTitle,
            teamRule: cellData.cellTeamRule,
            isSaved: cellData.isSaved,
            isReadOnly: cellData.isReadOnly,
            cell_type: cellData.cell_type,
          },
        ];
      } else {
        cellInfo = [
          {
            userId: cellData.userTitleId,
            name: cellData.userTitle,
          },
          {
            projectId: cellData.proTitleId,
            name: cellData.proTitle,
          },
          {
            fileId: cellData.fileTitleId,
            name: cellData.fileTitle,
            file_type: cellData.file_type,
          },
          {
            cellId: cellData.cellTitleId,
            name: cellData.cellTitle,
            isSaved: cellData.isSaved,
            isReadOnly: cellData.isReadOnly,
            cell_type: cellData.cell_type,
          },
        ];
      }

      this.$store.commit("setCellInfo", cellInfo);
      bus.$emit("changeActiveNode", cellInfo[3].cellId);
      if (cellInfo[3].cell_type === 1 || cellInfo[3].cell_type === 2) {
        bus.$emit("changeLayerList", cellInfo);
        bus.$emit("updateDrcVisible", true);
      } else {
        bus.$emit("updateDrcVisible", false);
      }
      bus.$emit("changeMenuAndSidebar", cellInfo[3].cell_type);
      bus.$emit("openCell", cellInfo);
      let activeCell = getCellFromTable(cellInfo[3].cellId);
      this.$store.commit("setActiveCell", activeCell);
      this.$router.push(route);
    },
    async removeTab(targetName, saved = true) {
      let removeTabSnowIdArr = [];
      let closeFileIdArr = [];
      let unSaveCellDataArr = [];
      for (let i = 0; i < this.editableTabs.length; i++) {
        for (let j = 0; j < targetName.length; j++) {
          if (this.editableTabs[i].name == targetName[j]) {
            // 关闭drc弹窗
            if (this.editableTabs[i].cell_type < 3) {
              bus.$emit("closeDRCVisible", true);
            }
            if (this.activeTabName == this.editableTabs[i].name) {
              let nextTab =
                this.editableTabs[i + 1] || this.editableTabs[i - 1];
              if (nextTab) {
                this.activeTabName = nextTab.name;
              }
            }
            let closeCellData = [];
            if (this.editableTabs[i].hasOwnProperty("fileTeamRule")) {
              closeCellData = [
                {
                  teamId: this.editableTabs[i].teamTitleId,
                  name: this.editableTabs[i].teamTitle,
                  teamRole: this.editableTabs[i].teamTeamRole,
                },
                {
                  projectId: this.editableTabs[i].proTitleId,
                  name: this.editableTabs[i].proTitle,
                  teamRule: this.editableTabs[i].proTeamRule,
                },
                {
                  name: this.editableTabs[i].fileTitle,
                  fileId: this.editableTabs[i].fileTitleId,
                  teamRule: this.editableTabs[i].fileTeamRule,
                  file_type: this.editableTabs[i].file_type,
                },
                {
                  name: this.editableTabs[i].cellTitle,
                  cellId: this.editableTabs[i].cellTitleId,
                  teamRule: this.editableTabs[i].cellTeamRule,
                  isSaved: this.editableTabs[i].isSaved,
                  isReadOnly: this.editableTabs[i].isReadOnly,
                  cell_type: this.editableTabs[i].cell_type,
                },
              ];
            } else {
              closeCellData = [
                {
                  userId: this.editableTabs[i].userTitleId,
                  name: this.editableTabs[i].userTitle,
                },
                {
                  projectId: this.editableTabs[i].proTitleId,
                  name: this.editableTabs[i].proTitle,
                },
                {
                  name: this.editableTabs[i].fileTitle,
                  fileId: this.editableTabs[i].fileTitleId,
                  file_type: this.editableTabs[i].file_type,
                },
                {
                  name: this.editableTabs[i].cellTitle,
                  cellId: this.editableTabs[i].cellTitleId,
                  isSaved: this.editableTabs[i].isSaved,
                  isReadOnly: this.editableTabs[i].isReadOnly,
                  cell_type: this.editableTabs[i].cell_type,
                },
              ];
            }
            //发送消息关闭单元消息
            async function sendMsgCloseCellLayout(data) {
              if (window.QLayouts) {
                let fileInfo = data[2];
                let cellInfo = data[3];
                for (let i = 0; i < window.QLayouts.length; i++) {
                  const QLayout = window.QLayouts[i];
                  if (QLayout.constructor.name == "QedaCellLayout") {
                    if (
                      QLayout.fileInfo.fileId == fileInfo.fileId &&
                      QLayout.cellInfo.cellId == cellInfo.cellId
                    ) {
                      await QLayout.sendMessage({ func: "CLOSE" });
                    }
                  }
                }
              }
            }

            let deductIndex = this.openedTab.findIndex(
              (item) => item.route == targetName[j]
            );

            this.openedTab.splice(deductIndex, 1);

            //记录关闭的tab有哪几个id
            removeTabSnowIdArr.push(this.editableTabs[i].cellTitleId);
            //删除对应数据在cellTable中的数据
            if (
              !closeFileIdArr.some(
                (item) => item === this.editableTabs[i].fileTitleId
              )
            ) {
              closeFileIdArr.push(this.editableTabs[i].fileTitleId);
            }
            //更新已打开页面的数据
            this.$store.commit("updateOpenedTab", this.openedTab);

            if (!saved) {
              await sendMsgCloseCellLayout(closeCellData);

              //判断是否有其他同文件单元打开
              let isNeedClear = false;
              let openedTab = this.$store.state.openedTab;

              for (let x = 0; x < openedTab.length; x++) {
                let fileId = openedTab[x].route
                  .split("/")
                [openedTab[x].route.split("/").length - 2].split("=")[1];
                if (fileId === closeCellData[2].fileId) {
                  isNeedClear = true;
                  break;
                }
              }

              if (isNeedClear) {
                let idObj = {
                  projectId: closeCellData[1].projectId,
                  fileId: closeCellData[2].fileId,
                  cellId: closeCellData[3].cellId,
                };

                // 从对象存储获取最新的保存的数据,替换未保存的数据
                let projectId = idObj.projectId;
                let fileId = idObj.fileId;
                let cellId = idObj.cellId;
                let oldCell = getCellFromTable(cellId);
                if (!oldCell.js_obj.isSave) {
                  let cell = await getObjectData(cellId);
                  cell = await reassembCell(cell, fileId);
                  //通过索引数据获取已缓存的数据,重新组装

                  oldCell.copying_partial_data(cell);

                  if (oldCell.$$.ptrType.name == "Cell*") {
                    const WS = oldCell.js_obj.WS;
                    if (WS) {
                      WS.clearUnSavedHistory();
                      // oldCell.js_obj.WS.remove();
                      // oldCell.js_obj.WS = null;
                    }
                    oldCell.js_obj = {};
                    oldCell.js_obj.WS = WS;
                  }
                  bus.$emit("notSaveCellReRender", oldCell.snow_id);
                  setDataToCellTable(projectId, fileId, oldCell);
                  bus.$emit("refreshCellRef", cellId);
                }
              }
            }
            bus.$emit("closeCell", closeCellData);
            setTimeout(() => {
              bus.$emit("ending", true);
            }, 500);
          }
        }
      }

      let unclosedFileIdArr = [];
      this.$store.state.openedTab.forEach((item) => {
        unclosedFileIdArr.push(
          item.route.split("/")[item.route.split("/").length - 2].split("=")[1]
        );
      });

      for (let i = 0; i < closeFileIdArr.length; i++) {
        if (!unclosedFileIdArr.some((item) => item === closeFileIdArr[i])) {
          this.$store.commit("removeFileFromCellTable", closeFileIdArr[i]);
          this.$store.commit("removeFileLayerList", closeFileIdArr[i]);
        }
      }

      //统一去除关闭的页面数据
      for (let i = 0; i < removeTabSnowIdArr.length; i++) {
        let index = this.editableTabs.findIndex(
          (item) => item.cellTitleId === removeTabSnowIdArr[i]
        );
        this.editableTabs.splice(index, 1);
      }

      if (this.openedTab.length == 0) {
        this.activeTabName = "";
        this.$store.commit("clearCellInfo", true);
        bus.$emit("changeMenuAndSidebar", "0");
        bus.$emit("closeLayerList", true);
        bus.$emit("resetShowCoordinate", true);
        this.$store.state.activeNode = {};
        this.$store.state.selectedLayer = {};
        this.$store.state.fileLayerList = [];
        this.$store.state.cellLayerList = [];
        this.$store.state.activeCell = {};
        this.$store.state.activeTab = "";
        // this.$store.state.topInfo = null
        // this.$store.state.proInfo = null
        // this.$store.state.fileInfo = null
        // this.$store.state.cellInfo = null
      } else {
        this.editableTabs.forEach((item) => {
          if (item.name === this.activeTabName) {
            let cellInfo = [];
            if (item.hasOwnProperty("fileTeamRule")) {
              cellInfo = [
                {
                  teamId: item.teamTitleId,
                  name: item.teamTitle,
                  teamRole: item.teamTeamRole,
                },
                {
                  projectId: item.proTitleId,
                  name: item.proTitle,
                  teamRule: item.proTeamRule,
                },
                {
                  name: item.fileTitle,
                  fileId: item.fileTitleId,
                  teamRule: item.fileTeamRule,
                  file_type: item.file_type,
                },
                {
                  name: item.cellTitle,
                  cellId: item.cellTitleId,
                  teamRule: item.cellTeamRule,
                  isSaved: item.isSaved,
                  isReadOnly: item.isReadOnly,
                  cell_type: item.cell_type,
                },
              ];
            } else {
              cellInfo = [
                {
                  userId: item.userTitleId,
                  name: item.userTitle,
                },
                {
                  projectId: item.proTitleId,
                  name: item.proTitle,
                },
                {
                  name: item.fileTitle,
                  fileId: item.fileTitleId,
                  file_type: item.file_type,
                },
                {
                  name: item.cellTitle,
                  cellId: item.cellTitleId,
                  isSaved: item.isSaved,
                  isReadOnly: item.isReadOnly,
                  cell_type: item.cell_type,
                },
              ];
            }
            let query = [item.fileTitleId, item.cellTitleId].join("/");
            this.$store.commit("setCellInfo", cellInfo);
            bus.$emit("openCell", cellInfo);
            bus.$emit("changeActiveNode", cellInfo[3].cellId);
            if (cellInfo[3].cell_type === 1 || cellInfo[3].cell_type === 2) {
              bus.$emit("changeLayerList", cellInfo);
            }
            bus.$emit("changeMenuAndSidebar", cellInfo[3].cell_type);
            let activeCell = getCellFromTable(cellInfo[3].cellId);
            this.$store.commit("setActiveCell", activeCell);
          }
        });
        this.$router.push(this.activeTabName);
      }
    },

    //关闭弹出框
    dialogClose() {
      this.dialogVisible = false;
    },
    async handleClose(removeBtnInfo) {
      let delArr = [];
      if (removeBtnInfo == 1) {
        await this.closeTabSaveFile(true, true);
        delArr.push(this.targetName);
        this.removeTab(delArr);
      } else if (removeBtnInfo == 2) {
        delArr.push(this.targetName);
        bus.$emit("loading", true);
        await this.closeTabSaveFile(false, true);
        this.removeTab(delArr, false);
      } else if (removeBtnInfo == 3) {
        this.$message({
          type: "info",
          message: this.$t("messages.closeCancel"),
        });
        this.dialogVisible = false;
      } else if (removeBtnInfo == 4) {
        // await this.closeTabSaveFile(false, false)
        delArr.push(this.targetName);
        this.removeTab(delArr);
      }
    },
    closeSuggestFn() {
      this.changeProject = false;
      this.clickTab(this.clickRoute);
    },

    async closeTabSaveFile(isSave, needUnLock) {
      let teamId = "";
      let projectId = "";
      let fileId = "";
      let cellId = "";
      let cellGns = "";
      // let cellNode = this.$refs.tree.getNode(cellId);
      for (let i = 0; i < this.editableTabs.length; i++) {
        if (this.editableTabs[i].name == this.targetName) {
          cellId = this.editableTabs[i].cellTitleId;
          fileId = this.editableTabs[i].fileTitleId;
          projectId = this.editableTabs[i].proTitleId;
          if (this.editableTabs[i].hasOwnProperty("teamTitleId")) {
            teamId = this.editableTabs[i].teamTitleId;
            cellGns = `gns://${teamId}/${projectId}/${fileId}/${cellId}`;
          } else {
            cellGns = `gns://${projectId}/${fileId}/${cellId}`;
          }
        }
      }
      if (isSave) {
        await saveCellFn(cellId, cellGns, false, needUnLock);
      } else {
        if (needUnLock) {
          await new Promise((res) => {
            setTimeout(() => {
              unLockCell_api({ snow_team_id: teamId, file_snow_id: cellId });
              res();
            }, 1500);
          });
        }
      }
    },
  },
  computed: {
    //已打开列表
    getOpenedTab() {
      return this.$store.state.openedTab;
    },
    //激活的页面
    activeTab() {
      return this.$store.state.activeTab;
    },
    closeTab() {
      return this.$store.state.closeTab;
    },
  },
  watch: {
    // 监听文件页面开关
    getOpenedTab: {
      handler(val) {
        if (val.length > this.openedTab.length) {
          // 添加了新的tab页
          // 导致$store.state中的openedTab长度
          // 大于
          // 标签页中的openedTab长度
          // 因此需要新增标签页
          let newTab = val[val.length - 1]; // 新增的肯定在数组最后一个

          if (
            this.$store.state.fileInfo.hasOwnProperty("teamRule") &&
            this.$store.state.cellInfo.hasOwnProperty("teamRule")
          ) {
            this.editableTabs.push({
              cellTitle: this.$store.state.cellInfo.name,
              cellTitleId: this.$store.state.cellInfo.cellId,
              cellTeamRule: this.$store.state.cellInfo.teamRule,
              cell_type: this.$store.state.cellInfo.cell_type,
              isReadOnly: this.$store.state.cellInfo.isReadOnly,
              fileTitle: this.$store.state.fileInfo.name,
              fileTitleId: this.$store.state.fileInfo.fileId,
              fileTeamRule: this.$store.state.fileInfo.teamRule,
              file_type: this.$store.state.fileInfo.file_type,
              proTitle: this.$store.state.proInfo.name,
              proTitleId: this.$store.state.proInfo.projectId,
              proTeamRule: this.$store.state.proInfo.teamRule,
              teamTitle: this.$store.state.topInfo.name,
              teamTitleId: this.$store.state.topInfo.teamId,
              teamTeamRole: this.$store.state.topInfo.teamRole,
              name: newTab.route,
              fileBarIcon: this.fileBarIcon,
              isSaved: this.$store.state.cellInfo.isSaved,
            });
          } else {
            this.editableTabs.push({
              cellTitle: this.$store.state.cellInfo.name,
              cellTitleId: this.$store.state.cellInfo.cellId,
              cell_type: this.$store.state.cellInfo.cell_type,
              isReadOnly: this.$store.state.cellInfo.isReadOnly,
              fileTitle: this.$store.state.fileInfo.name,
              fileTitleId: this.$store.state.fileInfo.fileId,
              file_type: this.$store.state.fileInfo.file_type,
              proTitle: this.$store.state.proInfo.name,
              proTitleId: this.$store.state.proInfo.projectId,
              userTitle: this.$store.state.topInfo.name,
              userTitleId: this.$store.state.topInfo.userId,
              name: newTab.route,
              fileBarIcon: this.fileBarIcon,
              isSaved: this.$store.state.cellInfo.isSaved,
            });
          }

          this.activeTabName = newTab.route;
          this.$store.commit("activeTab", newTab);
          this.openedTab.push(newTab);
        }
      },
      deep: true,
    },
    closeTab(val) {
      this.removeTab(val);
      // this.deleteTab();
    },
    //监听activetab以实现点击左侧栏时激活已存在的标签
    activeTab(val) {
      if (val.route !== this.activeTabName) {
        this.activeTabName = val.route;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.fileBar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .tab {
    width: 100%;
  }
  /deep/ .el-tabs__item {
    border: 1px solid #e4e7ed !important;
    border-bottom: none;
    border-top: none;
    // height: 100%;
    line-height: 33px;
    height: 33px;
    font-size: 12px;
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    background-color: #fff;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #378ddd;
    color: #fff;
  }
  /deep/
    .el-tabs--top.el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding-left: 10px !important;
  }
  /deep/ #tab-2 {
    padding-left: 10px !important;
  }
  /deep/.el-tabs__nav {
    display: flex;
  }
  /deep/.el-tabs__nav-scroll {
    height: 32px;
  }
  /deep/ .el-tabs__nav-prev,
  /deep/ .el-tabs__nav-next {
    line-height: 32px;
  }
  /deep/.el-tabs__content {
    padding: 0;
  }
  .layoutbox {
    flex: 1;
    background-color: #fff;
    position: relative;
    /deep/.el-dialog__body {
      padding-left: 30px;
    }
  }
  /deep/.el-icon-close {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .footer {
    width: 100%;
    height: 20px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .selectedInfo {
      padding-left: 25px;
    }
    .operation {
      padding-right: 5px;
    }
  }
}
</style>
