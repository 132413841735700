<template>
  <div class="right" id="right" ref="right" style="max-height: 900px" onselectstart="javascript:return false;">
    <canvas id="fill_img_temp" width="250" height="250" style="display: none"></canvas>
    <canvas id="border_img_temp" width="350" height="180" style="display: none"></canvas>
    <div class="cardHeader">
      <!-- 头部标题 -->
      <span>{{ $t('messages.layerPropertyList') }}</span>
    </div>
    <div class="layerSelector">
      <el-select popper-class="optionClass" v-model="value_layerLibraryName" @change="changeLayerDatas">
        <el-option v-for="item in options_layerLibrarySelector" :key="item.value" :label="item.id" :value="item.value"></el-option>
      </el-select>
    </div>
    <div class="layerTable">
      <el-table :data="layerDatas" :height="tableHeight" @header-click="clickHeader" @row-contextmenu="onContextmenu" id="layerDatasTable" class="layerDatasTable" ref="layerDatasTable" @current-change="layerDatasCurrentChange" highlight-current-row>
        <el-table-column width="45" align="center" :label="$t('messages.layer')" cell-style>
          <template slot-scope="scope">
            <img alt="" :src="scope.row.image" style="width: 25px; height: 15px" />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div>
              <span @dblclick="changeLayerName(scope.$index, scope.row)" v-show="!scope.row.showNameInput">{{ scope.row.layerName }}</span>
              <el-input :ref="'layers_name' + scope.$index" @blur="switchShowName(scope.row)" clearable @keyup.enter.native="resetNameEnter($event)" v-show="scope.row.showNameInput" size="mini" v-model="scope.row.layerName"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="42" align="center" :label="$t('messages.output')" v-if="isShowExport">
          <template slot-scope="scope">
            <input type="checkbox" v-model="scope.row.isExport" name="layerOutput" @change="exportChangeFn(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column width="35" align="center" :label="$t('messages.lock')" v-if="isShowLock">
          <template slot-scope="scope">
            <i
              @click="
                scope.row.lock = false
                lockClick(scope.row)
              "
              class="el-icon-lock"
              style="font-size: 16px"
              v-if="scope.row.lock"
            ></i>
            <!-- <i @click="scope.row.lock=true;lockClick(scope.row);" class="el-icon-unlock" style="font-size:16px;padding-left:5px" v-else></i> -->
            <!-- <el-checkbox v-model="scope.row.lock" @change="lockClick(scope.row)" v-else></el-checkbox> -->
            <input type="checkbox" v-model="scope.row.lock" name="layerOutput" @change="lockClick(scope.row)" v-else />
          </template>
        </el-table-column>
        <el-table-column width="35" align="center" :label="$t('messages.hide')" v-if="isShowHide">
          <template slot-scope="scope">
            <img alt=""
              @click="
                scope.row.hide = false
                scope.row.lock = false
                hideClick(scope.row)
              "
              style="height: 18px"
              src="../../assets/icons/layer/隐藏.svg"
              v-if="scope.row.hide"
            />
            <!-- <img alt="" @click="scope.row.hide=true;scope.row.lock=true;hideClick(scope.row);" style="height: 18px;padding-left:5px" src="../../assets/icons/layer/查看.svg" v-else /> -->
            <!-- <el-checkbox v-model="scope.row.hide" @change="hideClick(scope.row)" v-else></el-checkbox> -->
            <input type="checkbox" v-model="scope.row.hide" name="layerOutput" @change="hideClick(scope.row)" v-else />
          </template>
        </el-table-column>
        <el-table-column width="55" align="center" :label="$t('messages.NO')" v-if="isShowNumber">
          <template slot-scope="scope">
            <div>
              <span @dblclick="changeLayerNumber(scope.$index, scope.row)" v-show="!scope.row.showNumInput">{{ scope.row.layerNumber }}</span>
              <el-input :ref="'layer_number' + scope.$index" @blur="switchShowNum(scope.row, scope.$index)" clearable @keyup.enter.native="resetNumberEnter($event)" v-show="scope.row.showNumInput" size="mini" v-model="scope.row.layerNumber"></el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="layer-btn">
        <div class="layer-btn__btn" @click="addNewLayer"><i class="iconfont icon-tianjia"></i></div>
        <div class="layer-btn__btn" @click="mergeLayerBtn"><i class="iconfont icon-hebing1"></i></div>
        <div class="layer-btn__btn" @click="delLayerBtn"><i class="iconfont icon-shanchu"></i></div>
      </div>
    </div>
    <div class="fill-div">
      <div class="fillColor">
        <div class="cardHeader2">
          <!-- 头部标题 -->
          <strong>{{ $t('messages.fillColor') }}</strong>
          <div class="arrow" @click="fillColorSeen = !fillColorSeen">
            <span class="el-icon-arrow-down" v-if="fillColorSeen"></span>
            <span class="el-icon-arrow-up" v-else></span>
          </div>
        </div>
        <div v-show="fillColorSeen" class="colorControl">
          <div class="fillColor__body">
            <div v-for="(item, index) in fillColorDatas" :key="index" :style="{ background: item }" class="fillColor__item" @click="selectFillColor(item)"></div>
          </div>
          <div class="fillColor__btn">
            <el-color-picker v-model="fillColorSelect" @change="fillColorPickerChange"></el-color-picker>
          </div>
        </div>
      </div>
      <div class="borderColor">
        <div class="cardHeader2">
          <!-- 头部标题 -->
          <strong>{{ $t('messages.borderColor') }}</strong>
          <div class="arrow" @click="borderColorSeen = !borderColorSeen">
            <span class="el-icon-arrow-down" v-if="borderColorSeen"></span>
            <span class="el-icon-arrow-up" v-else></span>
          </div>
        </div>
        <div v-show="borderColorSeen" class="colorControl">
          <div class="borderColor__body">
            <div v-for="(item, index) in borderColorDatas" :key="index" :style="{ background: item }" class="borderColor__item" @click="selectBorderColor(item)"></div>
          </div>
          <div class="fillColor__btn">
            <el-color-picker v-model="borderColorSelect" @change="borderColorPickerChange"></el-color-picker>
          </div>
        </div>
      </div>
      <div class="fillShape">
        <div class="cardHeader2">
          <!-- 头部标题 -->
          <strong>{{ $t('messages.fillShape') }}</strong>
          <div class="arrow" @click="fillShapeSeen = !fillShapeSeen">
            <span class="el-icon-arrow-down" v-if="fillShapeSeen"></span>
            <span class="el-icon-arrow-up" v-else></span>
          </div>
        </div>
        <div class="fillShape__body" v-show="fillShapeSeen">
          <div v-for="(item, index) in fillShapeDatas" :key="index" class="fillShape__item">
            <img alt="" :src="item.image" style="width: 18px; height: 18px; cursor: pointer" @click="getFillShape(item.shape)" />
          </div>
          <!-- <button @click="fillShapeDialogVisible = true">更多...</button> -->
        </div>
      </div>
      <div class="borderSquare">
        <div class="cardHeader2">
          <!-- 头部标题 -->
          <strong>{{ $t('messages.borderShape') }}</strong>
          <div class="arrow" @click="borderSquareSeen = !borderSquareSeen">
            <span class="el-icon-arrow-down" v-if="borderSquareSeen"></span>
            <span class="el-icon-arrow-up" v-else></span>
          </div>
        </div>
        <div class="borderSquare__body" v-show="borderSquareSeen">
          <div v-for="(item, index) in bordershapeDatas" :key="index" class="borderSquare__item">
            <img alt="" :src="item.image" style="width: 35px; height: 18px; cursor: pointer" @click="getBorderSquare(item.border)" />
          </div>
        </div>
      </div>
    </div>
    <div class="delWindow">
      <el-dialog :title="$t('messages.deleteLayer')" :visible.sync="delLayerVisible" width="330px" :modal="false">
        <div>
          <el-table :data="layerDatas" :show-header="false" @selection-change="delSelectionChange" ref="delLayerTable" class="delLayerTable">
            <el-table-column prop="layerNumber" width="45"></el-table-column>
            <el-table-column width="35">
              <template slot-scope="scope">
                <img alt="" :src="scope.row.image" style="width: 25px; height: 15px" />
              </template>
            </el-table-column>
            <el-table-column prop="layerName"></el-table-column>
            <el-table-column type="selection" width="35"></el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" class="deactivate" ref="delWindowBtn" disabled @click="openDelDialog">
            {{ $t('messages.delete') }}
          </el-button>
          <el-button @click="cancelDelDialog" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="deleteDialog">
      <el-dialog :modal="false" :title="$t('messages.Warning')" :visible.sync="delDialogVisible" :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px" class="iconfont icon-jinggao"></i>
          <span>{{ $t('messages.powerTips_24') }}</span>
          <br />
        </div>
        <span class="deleteName">{{ deleteLayerName }}</span>
        <span slot="footer" class="dialog-footer">
          <el-checkbox v-model="userKnowChecked" @change="userKnow">{{ $t('messages.powerTips_13') }}</el-checkbox>
          <div>
            <el-button type="primary" class="deactivate" ref="delSureBtn" @click="delDialogSureBtn" disabled size="mini">{{ $t('messages.sure') }}</el-button>
            <el-button @click="delDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
    <!-- 合并图层弹窗 -->
    <div class="mergeLayer">
      <el-dialog :title="$t('messages.mergeLayer')" :visible.sync="mergeLayerVisible" width="350px" :modal="false">
        <div>
          <el-table :height="400" :data="layerDatas" :show-header="false" @selection-change="mergeSelectionChange" class="mergeLayerTable" ref="mergeLayerTable">
            <el-table-column prop="layerNumber" align="center" width="45"></el-table-column>
            <el-table-column width="35">
              <template slot-scope="scope">
                <img alt="" :src="scope.row.image" style="width: 25px; height: 15px" />
              </template>
            </el-table-column>
            <el-table-column prop="layerName" fit></el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
          </el-table>
          <div class="mergeToBox">
            <div class="merge__title">{{ $t('messages.mergedLayer') }}</div>
            <el-select popper-class="optionClass" v-model="mergeLayer" :placeholder="$t('messages.selectMergedLayer')" @change="selectMergeLayer">
              <el-option v-for="item in mergeLayerSelection" :key="item.layerNumber" :label="item.layerName" :value="item.layerName">
                <span style="width: 10px">{{ item.layerNumber }}</span>
                <img alt="" :src="item.image" style="width: 25px; height: 15px" />
                <span style="">{{ item.layerName }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="sure" size="mini" @click="mergeLayerSureBtn" ref="mergeSureBtn" disabled>
            {{ $t('messages.sure') }}
          </el-button>
          <el-button type="cancel" @click="mergeLayerCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- <div class="fillShapeWindow">
      <el-dialog :modal="false" title="填充形状" :visible.sync="fillShapeDialogVisible" :close-on-click-modal="false">
        <el-table ref="singleTable" :show-header="false" :data="fillShapeList" highlight-current-row @current-change="fillShapeCurrentChange" style="width: 100%">
          <el-table-column width="50">
            <template slot-scope="scope">
              <img alt="" :src="scope.row.image" style="width:25px;height:25px;" />
            </template>
          </el-table-column>
          <el-table-column property="name">
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" ref="fillShapeSureBtn" @click="fillShapeDialogSureButton" size="mini">{{$t("messages.sure")}}</el-button>
          <el-button @click="fillShapeDialogVisible = false" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div> -->
  </div>
</template>
<script>
import bus from '@/components/common/bus'
import '@/assets/iconfont/iconfont.css'
import { getFillAndBorderImgs } from '../../utils/graphic_f32/qeda-graphics'
import {
  getLayerList_api,
} from '@/api/file/file.js'
import { getPDKInfo_api } from '@/api/objectStorage/objectStorage.js'
import { getObjectDataFn } from './fileList/fileUtils'
export default {
  data() {
    return {
      options_layerLibrarySelector: [{ value: this.$t('messages.allLayers') }, { value: this.$t('messages.cellLayers') }],
      value_layerLibraryName: this.$t('messages.allLayers'),
      selcetLayer: {}, //列表上选中的图层
      fillColorSeen: true,
      borderColorSeen: true,
      fillShapeSeen: true,
      borderSquareSeen: true,
      delLayerVisible: false,
      delLayerSection: [],
      delDialogVisible: false,
      userKnowChecked: false,
      deleteLayerName: '',
      mergeLayerVisible: false,
      mergeLayer: '', //选择合并到的图层
      tableHeight: 376,
      fileLayerDatas: [],
      cellLayerDatas: [],
      cellLayerNumbers: [],
      layerDatas: [],
      defaultNewLayerDatas: [
        {
          color: '#F96CAB',
          shape: 'F1',
          border: 'B1',
          borderColor: '#F96CAB',
          image: '',
          snow_id: '',
        },
        {
          color: '#BA70F8',
          shape: 'F3',
          border: 'B1',
          borderColor: '#BA70F8',
          image: '',
          snow_id: '',
        },
        {
          color: '#7292F7',
          shape: 'F1',
          border: 'B1',
          borderColor: '#7292F7',
          image: '',
          snow_id: '',
        },
        {
          color: '#72D9F7',
          shape: 'F3',
          border: 'B1',
          borderColor: '#72D9F7',
          image: '',
          snow_id: '',
        },
        {
          color: '#63E4B5',
          shape: 'F1',
          border: 'B1',
          borderColor: '#63E4B5',
          image: '',
          snow_id: '',
        },
        {
          color: '#86DF72',
          shape: 'F3',
          border: 'B1',
          borderColor: '#86DF72',
          image: '',
          snow_id: '',
        },
        {
          color: '#D6F656',
          shape: 'F1',
          border: 'B1',
          borderColor: '#D6F656',
          image: '',
          snow_id: '',
        },
        {
          color: '#F3D85B',
          shape: 'F3',
          border: 'B1',
          borderColor: '#F3D85B',
          image: '',
          snow_id: '',
        },
        {
          color: '#F09D4C',
          shape: 'F1',
          border: 'B1',
          borderColor: '#F09D4C',
          image: '',
          snow_id: '',
        },
        {
          color: '#FF6E6E',
          shape: 'F3',
          border: 'B1',
          borderColor: '#FF6E6E',
          image: '',
          snow_id: '',
        },
      ],
      layerPointer: 0,
      lockPointer: 0,
      hidePointer: 0,
      isShowExport: false,
      isShowLock: true,
      isShowHide: true,
      isShowNumber: true,
      //被选中的要合并的图层
      mergeLayerSelection: [],
      fillColorSelect: '#FFFFFF',
      borderColorSelect: '#FFFFFF',
      fillColorDatas: ['#FFFFFF', '#FFE4E1', '#FFF5EE', '#D2B48C', '#DAA520', '#FFFFF0', '#00FF00', '#00FA9A', '#00FFFF', '#4682B4', '#191970', '#4B0082', '#D8BFD8', '#FFB6C1', '#0000FF', '#C0C0C0', '#FF0000', '#FF4500', '#CD853F', '#FFA500', '#FFFACD', '#7CFC00', '#F0FFF0', '#008080', '#B0E0E6', '#B0C4DE', '#483D8B', '#8B008B', '#0C7DE6', '#FF1493', '#000000', '#800000', '#FA8072', '#8B4513', '#FAEBD7', '#FFF8DC', '#FAFAD2', '#32CD32', '#7FFFAA', '#AFEEEE', '#F0F8FF', '#0000CD', '#9932CC', '#DA70D6', '#FF00FF'],
      borderColorDatas: ['#FFFFFF', '#FFE4E1', '#FFF5EE', '#D2B48C', '#DAA520', '#FFFFF0', '#00FF00', '#00FA9A', '#00FFFF', '#4682B4', '#191970', '#4B0082', '#D8BFD8', '#FFB6C1', '#0000FF', '#C0C0C0', '#FF0000', '#FF4500', '#CD853F', '#FFA500', '#FFFACD', '#7CFC00', '#F0FFF0', '#008080', '#B0E0E6', '#B0C4DE', '#483D8B', '#8B008B', '#0C7DE6', '#FF1493', '#000000', '#800000', '#FA8072', '#8B4513', '#FAEBD7', '#FFF8DC', '#FAFAD2', '#32CD32', '#7FFFAA', '#AFEEEE', '#F0F8FF', '#0000CD', '#9932CC', '#DA70D6', '#FF00FF'],
      fillShapeDatas: [
        { image: '', shape: 'F0', name: 'F0' },
        { image: '', shape: 'F1', name: 'F1' },
        { image: '', shape: 'F2', name: 'F2' },
        { image: '', shape: 'F3', name: 'F3' },
        { image: '', shape: 'F4', name: 'F4' },
        { image: '', shape: 'F5', name: 'F5' },
        { image: '', shape: 'F6', name: 'F6' },
        { image: '', shape: 'F7', name: 'F7' },
        { image: '', shape: 'F8', name: 'F8' },
        { image: '', shape: 'F9', name: 'F9' },
        { image: '', shape: 'F10', name: 'F10' },
        { image: '', shape: 'F11', name: 'F11' },
        { image: '', shape: 'F12', name: 'F12' },
        { image: '', shape: 'F13', name: 'F13' },
        { image: '', shape: 'F14', name: 'F14' },
      ],
      bordershapeDatas: [
        { image: '', border: 'B1', name: '纯色' },
        { image: '', border: 'B2', name: '纯色' },
        { image: '', border: 'B3', name: '纯色' },
        { image: '', border: 'B4', name: '纯色' },
        { image: '', border: 'B5', name: '纯色' },
      ],
      fillShapeDialogVisible: false, //填充形状选择弹窗
      fillShapeList: [
        { image: '', shape: 'F1', name: 'F1' },
        { image: '', shape: 'F2', name: 'F2' },
        { image: '', shape: 'F3', name: 'F3' },
        { image: '', shape: 'F4', name: 'F4' },
        { image: '', shape: 'F5', name: 'F5' },
        { image: '', shape: 'F6', name: 'F6' },
        { image: '', shape: 'F7', name: 'F7' },
        { image: '', shape: 'F8', name: 'F8' },
        { image: '', shape: 'F9', name: 'F9' },
        { image: '', shape: 'F10', name: 'F10' },
        { image: '', shape: 'F11', name: 'F11' },
        { image: '', shape: 'F12', name: 'F12' },
        { image: '', shape: 'F13', name: 'F13' },
        { image: '', shape: 'F14', name: 'F14' },
        { image: '', shape: 'F1', name: 'F1' },
        { image: '', shape: 'F1', name: 'F1' },
        { image: '', shape: 'F1', name: 'F1' },
      ], //更多填充形状
      fileSnowId: '',
      cellSnowId: '',
      teamRule: false, //团队权限
      num: 0, //强制数据刷新
      index: null, //文件图层对应在状态机中的位置
      isOpenCell: false,
      layerNumberOld: null,
      layerNameOld: '',
      layerNumberArr: [],
    }
  },
  mounted() {
    const that = this
    that.$refs.right.style.maxHeight = window.innerHeight - 69 + 'px'
    bus.$on('getFileId', data => {

      that.isOpenCell = true
      that.value_layerLibraryName = this.$t('messages.allLayers')
      that.fileSnowId = data.split('/')[0]
      that.cellSnowId = data.split('/')[1]
      if (that.$store.state.cellInfo.teamRule === '只读') {
        that.teamRule = true
      } else {
        that.teamRule = false
      }
      that.getFileLayerList()
      // 加载PDK
    })
    bus.$on('openCell', data => {
      if (that.$store.state.cellInfo.teamRule === '只读') {
        that.teamRule = true
      } else {
        that.teamRule = false
      }
    })
    bus.$on('layerImgChange', data => {
      const num = data.layerNumber
      if (num === 'All') {
        let len = data.imgs.length
        for (let i = 0; i < len; i++) {
          that.fileLayerDatas[i].image = data.imgs[i]
        }
      } else {
        const target = that.fileLayerDatas.filter(layer => {
          return layer.layerNumber === num
        })
        target[0].image = data.imgs[0]
        const target2 = that.layerDatas.filter(layer => {
          return layer.layerNumber === num
        })
        target2[0].image = data.imgs[0]
        let fileLayers = JSON.parse(JSON.stringify(that.fileLayerDatas))
        that.fileLayerDatas.splice(0, that.fileLayerDatas.length)
        fileLayers.forEach(item => {
          that.fileLayerDatas.push(item)
        })
        let currentlayerDatas = JSON.parse(JSON.stringify(that.layerDatas))
        that.layerDatas.splice(0, that.layerDatas.length)
        currentlayerDatas.forEach(item => {
          that.layerDatas.push(item)
        })
        const target3 = that.layerDatas.filter(layer => {
          return layer.layerNumber === num
        })
        that.$refs.layerDatasTable.setCurrentRow(target3[0])
      }
      let editData = {
        index: that.index,
        layerDatas: that.fileLayerDatas,
      }
      that.$store.commit('editLayerDatas', editData)
    })


    const result = getFillAndBorderImgs()
    result.fillImgs.forEach((img, i) => {
      that.fillShapeDatas[i].image = img
    })
    result.borderImgs.forEach((img, i) => {
      that.bordershapeDatas[i].image = img
    })


    bus.$on('syncLayerList', data => {

      bus.$emit('ending', true)
      that.cellLayerNumbers = data
      let syncLayerDatas = []
      for (let i = 0; i < that.fileLayerDatas.length; i++) {
        for (let j = 0; j < that.cellLayerNumbers.length; j++) {
          if (that.fileLayerDatas[i].layerNumber === that.cellLayerNumbers[j]) {
            syncLayerDatas.push(that.fileLayerDatas[i])
          }
        }
      }
      if (that.value_layerLibraryName == this.$t('messages.cellLayers')) {
        that.layerDatas = syncLayerDatas
        that.$store.commit('setCurrentCellLayer', syncLayerDatas)
      } else if (that.value_layerLibraryName == this.$t('messages.allLayers')) {
        that.layerDatas = that.fileLayerDatas
        that.$store.commit('setCurrentCellLayer', syncLayerDatas)
        // 加载PDK
        let proj_gns = that.$store.state.proInfo.projectId;
        that.getPDK(proj_gns);
      }
      that.cellLayerDatas = that.layerDatas
      if (that.layerDatas.length > 0) {
        that.toggleSelection(that.layerDatas[0])
      }
    })
    bus.$on('closeLayerList', data => {
      that.isOpenCell = false
      that.$store.commit('resetLayerDatas', true)
      that.layerDatas.splice(0, that.layerDatas.length)
      that.fileLayerDatas.splice(0, that.fileLayerDatas.length)
      that.cellLayerDatas.splice(0, that.cellLayerDatas.length)
      that.fillColorSelect = '#FFFFFF'
      that.borderColorSelect = '#FFFFFF'
    })
    bus.$on('batchCreateLayers', data => {
      that.addNewLayers(data)
    })
    that.$bus.$on('changeLang', data => {
      that.options_layerLibrarySelector = [{ value: that.$t('messages.allLayers') }, { value: that.$t('messages.cellLayers') }]
      if (that.value_layerLibraryName === '版图文件图层' || that.value_layerLibraryName === 'Layout File Layer') {
        that.value_layerLibraryName = that.$t('messages.allLayers')
      } else {
        that.value_layerLibraryName = that.$t('messages.cellLayers')
      }
    })
    bus.$on('rightVueUpdatePDK', proj_gns => {
      that.getPDK(proj_gns)
    })
    bus.$on('setDRF', drf => {
      that.drf = drf
      if (that.drf.drDefineColor) {
        that.fillColorDatas = []
        that.borderColorDatas = []
        for (const [key, value] of Object.entries(that.drf.drDefineColor)) {//NOSONAR
          that.fillColorDatas.push(value)
          that.borderColorDatas.push(value)
        }
      }
    })
    bus.$on('setTF', tf => {

      that.tf = tf
      if (that.tf.layerDefinitions) {
        that.tf.layerDefinitions.forEach(tf_layer => {
          let layer = that.layerDatas.find(elem => {
            return elem.layerNumber === tf_layer.Layer
          })
          if (that.drf.drDefinePacket) {
            let packet = that.drf.drDefinePacket.filter(pac => {
              return pac.PacketName === tf_layer.Packet
            })
            if (!layer) {
              that.addNewLayer()
              layer = that.layerDatas[that.layerDatas.length - 1]
            }
            if (packet[0]) {
              let fillcolor = packet[0].FillColor//NOSONAR
              let boardcolor = packet[0].OutlineColor//NOSONAR
              if (layer) {
                layer.layerName = tf_layer.Name
                layer.border = packet[0].LineStyle
                layer.borderColor = packet[0].OutlineColor
                layer.shape = packet[0].Stipple
                layer.color = packet[0].FillColor
                layer.hide = !tf_layer.Visible
                layer.isExport = tf_layer.Export
                layer.lock = tf_layer.Lock
                let old_layer_number = layer.layerNumber
                layer.layerNumber = tf_layer.Layer
                bus.$emit('changeLayerNumber', {
                  oldNumber: old_layer_number,
                  newNumber: layer.layerNumber,
                  layerData: layer,
                })
                that.updateRow()
                bus.$emit('layerChange', layer)
              }
            }
          }
        })

      }
    })
  },
  created() {},
  beforeDestroy() {
    bus.$off('getFileId')
    bus.$off('openCell')
    bus.$off('setCellRule')
    bus.$off('layerImgChange')
    bus.$off('syncLayerList')
    bus.$off('closeLayerList')
    bus.$off('batchCreateLayers')
    bus.$off('rightVueUpdatePDK')
    bus.$off('setDRF')
    bus.$off('setTF')
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add('deactivate')
      ref.$el.classList.add('is-disabled')
      ref.$el.disabled = true
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove('deactivate')
      ref.$el.classList.remove('is-disabled')
      ref.$el.disabled = false
    },
    onContextmenu(row, column, event) {
      this.$refs.layerDatasTable.setCurrentRow(row)
      if (this.value_layerLibraryName === this.$t('messages.allLayers')) {
        this.$contextmenu({
          items: [
            {
              label: this.$t('messages.addLayer'),
              divided: true,
              onClick: () => {
                this.addNewLayer()
              },
              icon: 'iconfont icon-tianjia',
              customClass: 'contextMenuIcon',
            },
            {
              label: this.$t('messages.deleteLayer'),
              onClick: () => {
                this.delLayerSection.push(row)
                this.openDelDialog()
              },
              icon: 'iconfont icon-shanchu',
            },
            {
              label: this.$t('messages.mergeLayer'),
              onClick: () => {
                this.mergeLayerBtn()
              },
              icon: 'iconfont icon-hebing1',
              disabled: true,
            },
            {
              label: this.$t('messages.listInformation'),
              onClick: () => {},
              icon: '',
              children: [
                {
                  label: this.$t('messages.hide'),
                  onClick: () => {
                    this.isShowHide = !this.isShowHide
                  },
                  icon: this.isShowHide ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.lock'),
                  onClick: () => {
                    this.isShowLock = !this.isShowLock
                  },
                  icon: this.isShowLock ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.output'),
                  onClick: () => {
                    this.isShowExport = !this.isShowExport
                  },
                  icon: this.isShowExport ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.NO'),
                  onClick: () => {
                    this.isShowNumber = !this.isShowNumber
                  },
                  icon: this.isShowNumber ? 'iconfont icon-gougou' : '',
                },
              ],
            },
          ],
          event, // 鼠标事件信息
          customClass: 'custom-class', // 自定义菜单 class
          zIndex: 10000, // 菜单样式 z-index
          minWidth: 170, // 主菜单最小宽度
        })
      } else if (this.value_layerLibraryName == this.$t('messages.cellLayers')) {
        this.$contextmenu({
          items: [
            {
              label: this.$t('messages.addLayer'),
              divided: true,
              onClick: () => {
                if (this.value_layerLibraryName === this.$t('messages.allLayers')) {//NOSONAR
                }
                this.addNewLayer()
              },
              icon: 'iconfont icon-tianjia',
              customClass: 'contextMenuIcon',
              disabled: true,
            },
            {
              label: this.$t('messages.deleteLayer'),
              onClick: () => {
                this.delLayerSection.push(row)
                this.openDelDialog()
              },
              icon: 'iconfont icon-shanchu',
            },
            {
              label: this.$t('messages.mergeLayer'),
              onClick: () => {
                this.mergeLayerBtn()
              },
              icon: 'iconfont icon-hebing1',
            },
            {
              label: this.$t('messages.listInformation'),
              onClick: () => {},
              icon: '',
              children: [
                {
                  label: this.$t('messages.hide'),
                  onClick: () => {
                    this.isShowHide = !this.isShowHide
                  },
                  icon: this.isShowHide ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.lock'),
                  onClick: () => {
                    this.isShowLock = !this.isShowLock
                  },
                  icon: this.isShowLock ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.output'),
                  onClick: () => {
                    this.isShowExport = !this.isShowExport
                  },
                  icon: this.isShowExport ? 'iconfont icon-gougou' : '',
                },
                {
                  label: this.$t('messages.NO'),
                  onClick: () => {
                    this.isShowNumber = !this.isShowNumber
                  },
                  icon: this.isShowNumber ? 'iconfont icon-gougou' : '',
                },
              ],
            },
          ],
          event, // 鼠标事件信息
          customClass: 'custom-class', // 自定义菜单 class
          zIndex: 10000, // 菜单样式 z-index
          minWidth: 170, // 主菜单最小宽度
        })
      }
    },
    handleClose(done) {
      this.$confirm(this.$t('messages.sureToClose'))
        .then(_ => {
          this.$refs.delLayerTable.clearSelection()
          done()
        })
        .catch(_ => {})
    },
    //获取图层数据
    async getFileLayerList() {
      let index = this.$store.state.layerDatas.findIndex(item => item.file_snow_id === this.fileSnowId)
      if (index === -1) {
        if (this.$store.state.layerDatas.length == 0) {
          this.index = 0
        } else {
          this.index = this.$store.state.layerDatas.length
        }
        let fileRes = await getLayerList_api({
          file_snow_id: this.fileSnowId,
        })
        if (fileRes.code === 200000) {
          fileRes.data.data.forEach(item => {
            item.showNameInput = false
            item.showNumInput = false
          })
          let fileLayerArr = fileRes.data.data
          this.fileLayerDatas = fileLayerArr
          let fileLayerObj = {
            file_snow_id: this.fileSnowId,
            layerDatas: this.fileLayerDatas,
          }
          this.$store.commit('addLayerDatas', fileLayerObj)
          bus.$emit('initLayerList', true)
        } else {
          bus.$emit('errDialog', this.$t('messages.code_' + fileRes.code))
        }
      } else {
        this.index = index
        this.fileLayerDatas = this.$store.state.layerDatas[this.index].layerDatas
        bus.$emit('initLayerList', true)
      }
      //获取文件图层库
    },
    async getPDK(proj_gns) {
      let pdk_res = await getPDKInfo_api({ project_snow_id: proj_gns })
      if (pdk_res.code === 200000) {
        let config = pdk_res.data.url_data;
        if (config.drf) {
          // 获取drf文件内容并解析为json
          let drf_u8 = await getObjectDataFn(config.drf)
          let drf_fr = new FileReader()
          drf_fr.onload = function (content) {
            let drf_json = JSON.parse(content.target.result)
            bus.$emit('setDRF', drf_json)
          }
          drf_fr.readAsText(new Blob([drf_u8]))
          if (config.tf) {
            // 获取tf文件内容并解析为json
            let tf_u8 = await getObjectDataFn(config.tf)
            let tf_fr = new FileReader()
            tf_fr.onload = function (content) {
              let tf_json = JSON.parse(content.target.result)
              bus.$emit('setTF', tf_json)
            }
            tf_fr.readAsText(new Blob([tf_u8]))
          } else {
            this.tf = null
          }
        } else {
          this.drf = null
        }
      } else {
        this.tf = null
        this.drf = null
      }
    },
    //改变图层库选择
    changeLayerDatas(val) {
      this.value_layerLibraryName = val
      if (this.value_layerLibraryName === this.$t('messages.allLayers')) {
        this.layerDatas = this.fileLayerDatas
        this.layerDatas = this.cellLayerDatas
      }
      if (this.layerDatas.length > 0) {
        this.toggleSelection(this.layerDatas[0])
      }
      bus.$emit('changeLayerList', true)
      this.num++
    },
    resetNameEnter(event) {
      event.target.blur()
    },
    switchShowName(row) {
      if (row.layerName !== '' && /^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(row.layerName) && [...row.layerName][0] !== ' ' && [...row.layerName][[...row.layerName].length - 1] !== ' ') {
        if ([...row.layerName].length > 0 && [...row.layerName].length <= 10) {
          this.$message({
            message: this.$t('messages.modifiedLayerInfo'),
            type: 'success',
          })
          row.showNameInput = false
        } else {
          bus.$emit('errDialog', this.$t('messages.LayerNameCannotEmpty'))
          row.showNameInput = true
        }
      } else if (row.layerName === '') {
        row.layerName = this.layerNameOld
        row.showNameInput = false
      } else {
        bus.$emit('errDialog', this.$t('messages.enterLayerNameFormat'))
        row.showNameInput = true
      }
    },
    changeLayerName(index, row) {
      row.showNameInput = true
      this.layerNameOld = row.layerName
      setTimeout(() => {
        this.$refs['layers_name' + index].focus()
        this.$refs['layers_name' + index].select()
      }, 1)
    },
    resetNumberEnter(event) {
      event.target.blur()
    },
    switchShowNum(row, index) {
      if (!isNaN(row.layerNumber)) {
        if (row.layerNumber == '' && parseInt(row.layerNumber) !== 0) {
          row.layerNumber = this.layerNumberOld
          row.showNumInput = false
        } else if (parseInt(row.layerNumber) === 0 && this.layerNumberArr.findIndex(item => item === 0) === -1) {
          row.layerNumber = 0
          if (parseInt(row.layerNumber) !== this.layerNumberOld) {
            this.$message({
              message: this.$t('messages.modifiedLayerInfo'),
              type: 'success',
            })
            bus.$emit('changeLayerNumber', {
              oldNumber: this.layerNumberOld,
              newNumber: row.layerNumber,
              layerData: row,
            })
          }
          row.showNumInput = false
        } else if (parseInt(row.layerNumber) >= 0 && parseInt(row.layerNumber) <= 4294967296) {
          if (!this.layerNumberArr.some(item => item === parseInt(row.layerNumber)) && parseInt(row.layerNumber) !== this.layerNumberOld) {
            this.$message({
              message: this.$t('messages.modifiedLayerInfo'),
              type: 'success',
            })
            row.showNumInput = false
            row.layerNumber = parseInt(row.layerNumber)
            bus.$emit('changeLayerNumber', {
              oldNumber: this.layerNumberOld,
              newNumber: row.layerNumber,
              layerData: row,
            })
          } else if (parseInt(row.layerNumber) === this.layerNumberOld) {
            row.showNumInput = false
          } else {
            bus.$emit('errDialog', this.$t('messages.enterCorrectLayerNumber'))
            row.layerNumber = this.layerNumberOld
            this.$refs['layer_number' + index].select()
            row.showNumInput = true
          }
        } else {
          bus.$emit('errDialog', this.$t('messages.enterCorrectLayerNumber'))
          row.layerNumber = this.layerNumberOld
          this.$refs['layer_number' + index].select()
          row.showNumInput = true
        }
      } else {
        bus.$emit('errDialog', this.$t('messages.enterCorrectLayerNumber'))
        row.layerNumber = this.layerNumberOld
        this.$refs['layer_number' + index].select()
        row.showNumInput = true
      }
    },
    changeLayerNumber(index, row) {
      row.showNumInput = true
      this.layerNumberOld = row.layerNumber
      this.layerNumberArr.splice(0, this.layerNumberArr.length)
      this.layerDatas.forEach(item => {
        this.layerNumberArr.push(item.layerNumber)
      })
      setTimeout(() => {
        this.$refs['layer_number' + index].focus()
        this.$refs['layer_number' + index].select()
      }, 1)
    },
    //全部锁定/隐藏
    async clickHeader(column) {
      if (column.label == '锁定') {
        let allLock = false
        if (this.lockPointer == 0) {
          this.layerDatas.forEach(item => {
            if (item.layerName != this.selcetLayer.layerName) {
              item.lock = true
              allLock = true
            }
          })
          this.lockPointer = 1
        } else {
          this.layerDatas.forEach(item => {
            if (item.layerName != this.selcetLayer.layerName) {
              item.lock = false
              allLock = false
            }
          })
          this.lockPointer = 0
        }
        bus.$emit('allLock', allLock)
      } else if (column.label == '隐藏') {
        let allHide = false
        if (this.hidePointer == 0) {
          this.layerDatas.forEach(item => {
            if (item.layerName != this.selcetLayer.layerName) {
              item.lock = true
              item.hide = true
              allHide = true
            }
          })
          this.hidePointer = 1
        } else {
          this.layerDatas.forEach(item => {
            if (item.layerName != this.selcetLayer.layerName) {
              item.lock = false
              item.hide = false
              allHide = false
            }
          })
          this.hidePointer = 0
        }
        bus.$emit('allHide', allHide)
      }
    },
    exportChangeFn(row) {},
    async lockClick(row) {
      bus.$emit('lockClick', row)
    },
    async hideClick(row) {
      bus.$emit('hideClick', row)
    },
    //单击图层列表
    layerDatasCurrentChange(val) {
      if (val !== null) {
        this.selcetLayer = val
        this.fillColorSelect = val.color
        this.borderColorSelect = val.borderColor
        if (val) {
          bus.$emit('changeLayerNum', val)
        }
      }
    },
    // 自动选择图层1
    toggleSelection(row) {
      setTimeout(() => {
        if (this.$refs.layerDatasTable.data.length > 0) {
          this.$refs.layerDatasTable.setCurrentRow(row)
        }
      }, 500)
    },
    //批量创建新图层
    addNewLayers(data) {
      if (this.fileLayerDatas.length >= 256) {
        bus.$emit('errDialog', this.$t('messages.layerNumberLimit1'))
        return
      } else {
        let layersNumberArr = []
        data.forEach(v => {
          if (!this.fileLayerDatas.some(item => item.layerNumber === v)) {
            layersNumberArr.push(v)
          }
        })
        let newLayersArr = []
        if (this.isOpenCell) {
          layersNumberArr.forEach(item => {
            newLayersArr.push({
              layerName: `layer${item}`,
              lock: false,
              hide: false,
              layerNumber: item,
              color: this.defaultNewLayerDatas[this.layerPointer].color,
              shape: this.defaultNewLayerDatas[this.layerPointer].shape,
              border: this.defaultNewLayerDatas[this.layerPointer].border,
              borderColor: this.defaultNewLayerDatas[this.layerPointer].borderColor,
              image: this.defaultNewLayerDatas[this.layerPointer].image,
              isExport: true,
              showNameInput: false,
              showNumInput: false,
            })
            this.fileLayerDatas.push({
              layerName: `layer${item}`,
              lock: false,
              hide: false,
              layerNumber: item,
              color: this.defaultNewLayerDatas[this.layerPointer].color,
              shape: this.defaultNewLayerDatas[this.layerPointer].shape,
              border: this.defaultNewLayerDatas[this.layerPointer].border,
              borderColor: this.defaultNewLayerDatas[this.layerPointer].borderColor,
              image: this.defaultNewLayerDatas[this.layerPointer].image,
              isExport: true,
              showNameInput: false,
              showNumInput: false,
            })
            if (this.layerPointer < 9) {
              this.layerPointer++
            } else {
              this.layerPointer = 0
            }
          })
          bus.$emit('batchLayersData', newLayersArr)
          let editData = {
            index: this.index,
            layerDatas: this.fileLayerDatas,
          }
          this.$store.commit('editLayerDatas', editData)
        }
      }
    },
    //新建图层
    addNewLayer() {
      let newLayer = {}
      if (this.isOpenCell) {
        if (this.value_layerLibraryName === this.$t('messages.allLayers')) {
          let maxLayerNumber = Math.max.apply(
            Math,
            this.layerDatas.map(function (item) {
              return item.layerNumber
            })
          )
          if (this.layerDatas.length > 0 && this.layerDatas.length < 256) {
            newLayer = {
              layerName: `layer${maxLayerNumber + 1}`,
              lock: false,
              hide: false,
              layerNumber: maxLayerNumber + 1,
              color: this.defaultNewLayerDatas[this.layerPointer].color,
              shape: this.defaultNewLayerDatas[this.layerPointer].shape,
              border: this.defaultNewLayerDatas[this.layerPointer].border,
              borderColor: this.defaultNewLayerDatas[this.layerPointer].borderColor,
              image: this.defaultNewLayerDatas[this.layerPointer].image,
              isExport: true,
              showNameInput: false,
              showNumInput: false,
            }
          } else if (this.layerDatas.length == 0) {
            newLayer = {
              layerName: `layer0`,
              lock: false,
              hide: false,
              layerNumber: 0,
              color: this.defaultNewLayerDatas[this.layerPointer].color,
              shape: this.defaultNewLayerDatas[this.layerPointer].shape,
              border: this.defaultNewLayerDatas[this.layerPointer].border,
              borderColor: this.defaultNewLayerDatas[this.layerPointer].borderColor,
              image: this.defaultNewLayerDatas[this.layerPointer].image,
              isExport: true,
              showNameInput: false,
              showNumInput: false,
            }
          } else {
            bus.$emit('errDialog', this.$t('messages.layerNumberLimit2'))
            return
          }
          if (this.layerPointer < 9) {
            this.layerPointer++
          } else {
            this.layerPointer = 0
          }
          this.fileLayerDatas.push(newLayer)
          let editData = {
            index: this.index,
            layerDatas: this.fileLayerDatas,
          }
          this.$store.commit('editLayerDatas', editData)
          bus.$emit('newLayer', newLayer)
          this.$nextTick(() => {
            this.$refs.layerDatasTable.setCurrentRow(this.$refs.layerDatasTable.data[this.$refs.layerDatasTable.data.length - 1])
            let scrollHeight = this.$refs.layerDatasTable.bodyWrapper.scrollHeight
            this.$refs.layerDatasTable.bodyWrapper.scrollTop = scrollHeight
          })
        }
      } else {
        return
      }
    },
    delSelectionChange(val) {
      this.delLayerSection = val
      this.delWindowActive()
    },
    delLayerBtn() {
      if (this.isOpenCell) {
        this.delLayerVisible = true
      } else {
        return
      }
    },
    delWindowActive() {
      if (this.delLayerSection.length === 0) {
        this.disableBtn(this.$refs.delWindowBtn)
      } else {
        this.activateBtn(this.$refs.delWindowBtn)
      }
    },
    //打开删除提示页面
    openDelDialog() {
      this.delDialogVisible = true
      this.deleteLayerName = ''
      this.delLayerSection.sort((a, b) => {
        return a.layerNumber - b.layerNumber
      })
      this.delLayerSection.forEach(item => {
        this.deleteLayerName += ` ${item.layerName}`
      })
    },
    //删除提示页面取消
    cancelDelDialog() {
      this.delLayerVisible = false
      this.$refs.delLayerTable.clearSelection()
    },
    //用户已知悉
    userKnow() {
      if (!this.userKnowChecked) {
        this.$refs.delSureBtn.$el.classList.add('deactivate')
        this.$refs.delSureBtn.$el.classList.add('is-disabled')
        this.$refs.delSureBtn.$el.disabled = true
      } else {
        this.$refs.delSureBtn.$el.classList.remove('deactivate')
        this.$refs.delSureBtn.$el.classList.remove('is-disabled')
        this.$refs.delSureBtn.$el.disabled = false
      }
    },
    //确认删除
    async delDialogSureBtn() {
      if (this.value_layerLibraryName === this.$t('messages.cellLayers')) {
        let delLayerArr = this.delLayerSection.slice(0)
        this.fileLayerDatas = this.filterDelLayer(this.fileLayerDatas)
        let editData = {
          index: this.index,
          layerDatas: this.fileLayerDatas,
        }
        this.$store.commit('editLayerDatas', editData)
        this.layerDatas = this.filterDelLayer(this.layerDatas)
        this.delLayerSection.splice(0, this.delLayerSection.length)
        this.cellLayerDatas = this.layerDatas
        bus.$emit('delLayer', delLayerArr)
      } else if (this.value_layerLibraryName === this.$t('messages.allLayers')) {
        let delLayerArr = this.delLayerSection.slice(0)
        this.layerDatas = this.filterDelLayer(this.layerDatas)
        this.delLayerSection.splice(0, this.delLayerSection.length)
        this.fileLayerDatas = this.layerDatas
        let editData = {
          index: this.index,
          layerDatas: this.fileLayerDatas,
        }
        this.$store.commit('editLayerDatas', editData)
        this.cellLayerDatas = this.filterDelLayer(this.cellLayerDatas)
        bus.$emit('delLayer', delLayerArr)
      }
      if (this.layerDatas.length > 0) {
        this.selcetLayer = this.layerDatas[0]
        this.$refs.layerDatasTable.setCurrentRow(this.selcetLayer)
      }
      this.delDialogVisible = false
      this.delLayerVisible = false
      this.userKnowChecked = false
      this.userKnow()
    },
    delDialogCancelBtn() {
      this.delDialogVisible = false
      this.userKnowChecked = false
      this.userKnow()
      this.delLayerSection.splice(0, this.delLayerSection.length)
    },
    //过滤删除图层
    filterDelLayer(layerDatas) {
      let afterDelFileLayer = layerDatas.filter(v => this.delLayerSection.every(val => val.layerNumber != v.layerNumber))
      layerDatas.splice(0, layerDatas.length)
      layerDatas = afterDelFileLayer
      return layerDatas
    },
    //打开合并列表
    mergeLayerBtn() {
      if (this.isOpenCell) {
        if (this.value_layerLibraryName == this.$t('messages.cellLayers')) {
          this.mergeLayerVisible = true
        } else if (this.value_layerLibraryName == this.$t('messages.allLayers')) {
          return
        }
      } else {
        return
      }
    },
    //选择合并图层
    selectMergeLayer(val) {
      if (this.mergeLayerSelection.length < 2) {
        this.mergeLayer = ''
      } else {
        this.mergeLayer = val
        this.mergeSureBtnClassList()
      }
    },
    mergeSureBtnClassList() {
      if (this.mergeLayer !== '' && this.mergeLayerSelection.length > 1) {
        this.$refs.mergeSureBtn.$el.classList.remove('deactivate')
        this.$refs.mergeSureBtn.$el.classList.remove('is-disabled')
        this.$refs.mergeSureBtn.$el.disabled = false
      } else {
        this.$refs.mergeSureBtn.$el.classList.add('deactivate')
        this.$refs.mergeSureBtn.$el.classList.add('is-disabled')
        this.$refs.mergeSureBtn.$el.disabled = true
      }
    },
    mergeSelectionChange(val) {
      let mergeVal = val.sort((a, b) => {
        return a.layerNumber - b.layerNumber
      })
      this.mergeLayerSelection = mergeVal //所选的需要合并操作的图层
      this.mergeSureBtnClassList(mergeVal)
    },
    mergeLayerSureBtn() {
      let mergeLayerData = {} //选择的合并到的图层
      this.layerDatas.forEach(item => {
        if (this.mergeLayer == item.layerName) {
          mergeLayerData = JSON.parse(JSON.stringify(item))
        }
      })
      this.layerDatas = this.filterMergeLayer(this.layerDatas, mergeLayerData)
      let mergeToData = this.layerDatas.filter(item => {
        return item.layerName === this.mergeLayer
      })
      //默认选择被合并到的图层
      this.selcetLayer = mergeToData[0]
      this.$refs.layerDatasTable.setCurrentRow(this.selcetLayer)
      let editData = {
        index: this.index,
        layerDatas: this.fileLayerDatas,
      }
      this.$store.commit('editLayerDatas', editData)
      // 传参-合并图层对应的编号
      let selectionArr = []
      this.mergeLayerSelection.forEach(item => {
        selectionArr.push(item.layerNumber)
      })
      bus.$emit('mergeLayer', [selectionArr, mergeToData[0].layerNumber])
      this.mergeLayer = ''
      this.mergeLayerVisible = false
    },
    mergeLayerCancelBtn() {
      this.mergeLayerVisible = false
      this.mergeLayer = ''
      this.mergeLayerSelection.splice(0, this.mergeLayerSelection.length)
      this.$refs.mergeLayerTable.clearSelection()
    },
    //过滤合并的图层
    filterMergeLayer(layerDatas, mergeLayerData) {
      let delLayerArr = this.mergeLayerSelection.filter(item => item.layerNumber !== mergeLayerData.layerNumber)
      if (this.value_layerLibraryName == this.$t('messages.cellLayers')) {
        let afterMergeLayerDatas = layerDatas.filter(v => delLayerArr.every(val => val.layerNumber != v.layerNumber)) //删除所选后的
        layerDatas.splice(0, layerDatas.length)
        layerDatas = afterMergeLayerDatas
        return layerDatas
      } else {
        return layerDatas
      }
    },
    //自动刷新
    updateRow() {
      let index = this.layerDatas.findIndex(item => item.layerNumber === this.selcetLayer.layerNumber)
      this.$set(this.layerDatas, index, this.selcetLayer)
    },
    //填充颜色
    selectFillColor(color) {
      this.fillColorSelect = color
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.color = color
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
    //选择自定义颜色
    fillColorPickerChange(color) {
      this.fillColorSelect = color
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.color = color
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
    //边框颜色
    selectBorderColor(color) {
      this.borderColorSelect = color
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.borderColor = color
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
    //选择自定义边框颜色
    borderColorPickerChange(color) {
      this.borderColorSelect = color
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.borderColor = color
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
    //选择更多填充形状
    fillShapeDialogSureButton() {
    },
    getFillShape(val) {
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.shape = val
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
    //单击填充形状
    fillShapeCurrentChange(val) {
    },
    //单击边框形状
    getBorderSquare(val) {
      if (Object.keys(this.selcetLayer).length > 0) {
        this.selcetLayer.border = val
        this.updateRow()
        bus.$emit('layerChange', this.selcetLayer)
      }
    },
  },
  computed: {},
  watch: {
    fillColorSeen: {
      deep: true,
      handler(val) {
        let t_h = 0
        if (val) {
          t_h = -74
        } else {
          t_h = +74
        }
        this.tableHeight += t_h
      },
    },
    borderColorSeen: {
      deep: true,
      handler(val) {
        let t_h = 0
        if (val) {
          t_h = -74
        } else {
          t_h = +74
        }
        this.tableHeight += t_h
      },
    },
    fillShapeSeen: {
      deep: true,
      handler(val) {
        let t_h = 0
        if (val) {
          t_h = -84
        } else {
          t_h = +84
        }
        this.tableHeight += t_h
      },
    },
    borderSquareSeen: {
      deep: true,
      handler(val) {
        let t_h = 0
        if (val) {
          t_h = -54
        } else {
          t_h = +54
        }
        this.tableHeight += t_h
      },
    },
  },
}
</script>
<style lang="less" scoped>
.spanBtn {
  cursor: pointer;
}
.right {
  min-width: 300px;
  font-size: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .cardHeader {
    height: 34px;
    line-height: 34px;
    background: #f4f4f4;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      height: 100%;
      display: inline-block;
      margin-left: 20px;
    }
    .el-icon-plus:before,
    .el-icon-minus:before {
      height: 100%;
      display: inline-block;
      line-height: 40px;
      margin-right: 20px;
    }
  }
  // 图层库切换
  .layerSelector {
    /deep/.el-select {
      margin: 10px;
      width: 90%;
      height: 26px;
      .el-input {
        .el-input__inner {
          width: 100%;
          height: 26px;
          padding-left: 8px;
          background-color: #fff !important;
        }
        .el-input__icon {
          line-height: 26px;
        }
      }
    }
  }
  .cardHeader2 {
    height: 30px;
    line-height: 30px;
    background: #eee;
    border-bottom: 1px B1 #d1d1d1;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    strong {
      height: 100%;
      display: inline-block;
      margin-left: 20px;
    }
  }
  .arrow {
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
  .layerTable {
    margin-right: 3px;
    border: 1px solid #dddddd;
    border-bottom: none;
  }
  /deep/ .el-table {
    font-size: 12px;
    .el-input__inner {
      padding: 0 !important;
      border-radius: 0 !important;
      background: #fff !important;
      border: 1px B1 #0c7de6 !important;
      height: 31px;
      line-height: 31px;
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__clear {
          justify-content: right;
        }
      }
    }
    .el-input--mini .el-input__icon {
      line-height: 31px !important;
      display: flex;
      align-items: center;
    }
  }
  // /deep/.el-table__body-wrapper::-webkit-scrollbar{
  ::-webkit-scrollbar-thumb {
    //滚动条颜色
    background-color: #dddddd;
  }
  ::-webkit-scrollbar {
    //滚动条宽度
    width: 3px;
  }
  /deep/ .el-table__cell {
    padding: 0 !important;
  }
  /deep/ .cell {
    div {
      width: 100%;
    }
    padding: 0 !important;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
  }
  .layerDatasTable {
    /deep/.has-gutter {
      tr th {
        text-align: center;
      }
      tr th:nth-child(2) {
        text-align: left;
      }
    }
    /deep/.el-table__row {
      height: 31px;
      td:nth-child(1) {
        .cell > img {
          margin: 0 10px;
        }
      }
      .cell {
        line-height: 31px;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      td:last-child {
        .cell > span {
          display: inline-block;
          width: 20px;
        }
      }
    }
    /deep/.is-scrolling-none,
    /deep/.is-scrolling-right,
    /deep/.is-scrolling-left {
      height: 352px !important;
    }
  }
  .layer-btn {
    display: flex;
    justify-content: right;
    flex-direction: row;
    .layer-btn__btn {
      font-size: 15px;
      margin: 5px 6px;
      cursor: pointer;
      color: #333333;
    }
  }
  .colorControl {
    width: 276px;
    height: 74px;
    display: flex;
    flex-direction: column;
  }
  .fillColor__body,
  .borderColor__body {
    display: flex;
    height: 54px;
    flex-wrap: wrap;
    width: 276px;
    padding-left: 4px;
  }
  .fillShape__body {
    display: flex;
    height: 84px;
    flex-wrap: wrap;
    width: 276px;
    button {
      height: 20px;
      line-height: 20px;
      background-color: #d3d3d3;
      border: none;
      width: 276px;
      color: #666666;
      cursor: pointer;
    }
  }
  .borderSquare__body {
    display: flex;
    flex-wrap: wrap;
    width: 276px;
  }
  .fillColor__btn {
    width: 100%;
    text-align: center;
    background: #fff;
    border: 1px B1 #d3d3d3;
    font-weight: 400;
    color: #666666;
    height: 18px;
    cursor: pointer;
  }
  /deep/.el-color-picker {
    width: 100%;
    height: 18px;
  }
  /deep/.el-color-picker__trigger {
    height: 100%;
    width: 100%;
    border: none;
    padding: 0;
    .el-icon-arrow-down {
      color: #ccc !important;
    }
  }
  /deep/.el-color-picker__color {
    border: none;
  }
  /deep/.el-color-picker__color-inner {
    height: 100%;
    width: 100%;
  }
  .fillColor__item,
  .borderColor__item {
    width: 16px;
    height: 16px;
    margin: 1px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }
  .fillShape__item:hover,
  .borderSquare__item:hover,
  .fillColor__item:hover,
  .borderColor__item:hover {
    transition: transform 0.1s;
    transform: scale(1.1);
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }
  .fillShape__item {
    width: 18px;
    height: 18px;
    margin: 7px 8px;
  }
  .borderSquare__item {
    width: 35px;
    height: 18px;
    margin: 7px 7px;
  }
  /deep/ .el-dialog {
    top: 30%;
    .el-dialog__body {
      padding: 15px !important;
    }
  }
  .merge__title {
    display: inline-block;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-right: none;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
  .el-button {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    padding: 8px 26px;
  }
  .el-button--cancel {
    background: #b0b0b0;
  }
  .el-button--sure {
    background: #4385f2;
  }
  .el-button--delete {
    border: 1px B1 #d44051;
    color: #d44051;
  }
  //删除图层页面
  .delWindow {
    /deep/.el-dialog {
      width: 337px;
      min-height: 249px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }
    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      min-height: 147px;
      max-height: 405px;
      overflow-y: scroll;
      .el-input__inner {
        width: 220px;
      }
      .el-input__suffix {
        right: 10px;
      }
    }
    /deep/.el-table__row {
      .cell {
        height: 30px;
        margin-left: 10px;
      }
    }
    /deep/.el-dialog__footer {
      padding: 20px 10px 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }
  //删除弹窗
  .deleteDialog {
    /deep/.el-dialog {
      width: 575px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }
    /deep/.el-dialog__body {
      padding: 20px 20px 90px 20px !important;
      font-weight: 400;
    }
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
    }
    .deleteName {
      padding-left: 32px;
      color: #0c7de6;
    }
    /deep/.el-dialog__footer {
      padding: 10px 20px 29px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }
  //合并图层弹窗
  .mergeLayer {
    /deep/.el-dialog {
      width: 350px;
      max-height: 536px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      overflow: hidden;
    }
    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      .mergeToBox {
        width: 340px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 8px;
      }
      .merge__title {
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
      }
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        width: 220px;
        border-radius: 0%;
        padding: 0 10px;
      }
      .el-input__suffix {
        right: 10px;
        .el-input__icon {
          line-height: 30px;
        }
      }
    }
    /deep/.el-table__row {
      .cell {
        height: 30px;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
      }
    }
    /deep/.el-dialog__footer {
      padding: 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
  //填充形状弹窗
  .fillShapeWindow {
    /deep/.el-dialog {
      width: 337px;
      max-height: 505px;
      font-size: 14px;
      border: 1px B1 #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      overflow: hidden;
    }
    /deep/.el-dialog__body {
      padding: 0px !important;
      font-weight: 400;
      max-height: 390px;
      overflow: scroll;
    }
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
    }
    /deep/.el-table__row {
      .cell {
        height: 40px;
        margin-left: 10px;
      }
    }
    /deep/.el-dialog__footer {
      padding: 0px 10px 10px;
      text-align: left;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #dce6f8;
  }
  /deep/.el-table__body tr.current-row > td.el-table__cell {
    background-color: #dce6f8;
  }
}
</style>
