import Md5 from 'js-md5'
import {
  getCellList_api, //获取cell列表
} from '@/api/file/file.js'
import { putObject_api, getDiagramFile_api, saveDiagramFile_api, getCopySnowId_api } from '@/api/objectStorage/objectStorage.js'
import bus from '@/components/common/bus'
import store from '@/store/index.js'

//单个获取对象存储数据转u8
export const getObjectDataFn = async function (fileUrl) {
  let time = parseInt(new Date().getTime() / 1000)
  const bucket_name = localStorage.getItem('bucket_name')
  const minio_name = localStorage.getItem('minio_name')
  const apiKey = localStorage.getItem('apiKey')
  let file_content = await fetch('/file/api/v1/get_object_data/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      username: minio_name,
      sign: Md5(`${apiKey}_${time}`),
    },
    body: JSON.stringify({
      time,
      bucket_name,
      object_path: fileUrl,
    }),
  })

  let data_buffer = await file_content.arrayBuffer()
  let data_u8 = new Uint8Array(data_buffer)
  // downloadFileData(data_u8, "cell", "txt")
  return data_u8
}

export const batchGetObjectDataFn = async function (fileUrlArr) {
  let time = parseInt(new Date().getTime() / 1000)
  let apiKey = localStorage.getItem('apiKey')
  let bucket_name = localStorage.getItem('bucket_name')
  let minio_name = localStorage.getItem('minio_name')
  let batch_path_data = []

  fileUrlArr.data.forEach(item => {
    let path_data = {
      snow_id: item.snow_id,
      bucket_name,
      object_path: item.file_url,
      version_id: '',
    }
    batch_path_data.push(path_data)
  })

  let file_content = await fetch('/file/api/v1/get_batch_object_data/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      username: minio_name,
      sign: Md5(`${apiKey}_${time}`),
    },
    body: JSON.stringify({
      time,
      batch_path_data,
    }),
  })

  let data_buffer = await file_content.arrayBuffer()

  let u8Array = new Uint8Array(data_buffer)

  let start = 0
  let end = 4
  let u8ArrayArray = []
  for (let i = 0; i < u8Array.length; i = start) {
    let u8_data_length = u8Array.slice(start, end)

    let length = bytesToNumber(u8_data_length)

    start = length + end
    u8ArrayArray.push(u8Array.slice(end, start))
    end = start + 4
  }

  return u8ArrayArray
}

function bytesToNumber(byteArray) {
  let result = 0
  for (let i = byteArray.length - 1; i >= 0; i--) {
    result = result * 256 + byteArray[i]
  }

  return result
}

//导出下载文件
export const downloadFileData = function (data_u8, name, suffix) {
  let content = data_u8
  let mime = 'application/octet-stream'
  var download_link = document.getElementById('download_gds')
  if (download_link == null) {
    download_link = document.createElement('a')
    download_link.id = 'download_gds'
    download_link.style.display = 'none'
    document.body.appendChild(download_link)
  }
  download_link.download = `${name}.${suffix}` //名字
  download_link.href = URL.createObjectURL(
    new Blob([content], {
      type: mime,
    })
  )
  download_link.click()
}

//批量获取原理图文件数据
export const getFileDataFn = async function (schemaSymbolArr) {
  let SchemaFileStreamArr = []
  let SchemaFileDataArr = []
  let schemaSymbolSnowIdArr = []
  schemaSymbolArr.forEach(item => {
    schemaSymbolSnowIdArr.push(item[0])
  })

  let urlData = await getDiagramFile_api({ file_snow_id: schemaSymbolSnowIdArr })

  if (urlData.code === 200000) {
    if (urlData.data.length === 0) {
      for (let i = 0; i < schemaSymbolArr.length; i++) {
        let newSchame = new window.Kernel.Schema()
        newSchame.snow_id = schemaSymbolArr[i][0]
        newSchame.name = schemaSymbolArr[i][1]
        SchemaFileDataArr.push(newSchame)
      }
    } else {
      SchemaFileStreamArr = await batchGetObjectDataFn(urlData)
    }
  }

  return [SchemaFileStreamArr, SchemaFileDataArr]
}

//原理图文件组装数据方法
export const assembleSchemaFileFn = function (SchemaFileStreamArr, SchemaFileDataArr) {
  let newSchemaFile = new window.Kernel.SchemaFile()
  newSchemaFile.snow_id = Math.random().toString(36).substr(2, 10)

  if (SchemaFileStreamArr.length > 0) {
    newSchemaFile.assemble(SchemaFileStreamArr)
  }

  SchemaFileDataArr.forEach(item => {
    newSchemaFile.add_schema(item)
  })

  return newSchemaFile
}

let recFileCallArr = []
//获取整个原理图/符号/封装符号的信息并返回一个新文件
export const getCompleteDataFn = async function (fileUrl) {
  let schemaSymbolArr = []
  let u8_data = await getObjectDataFn(fileUrl)
  let newSymbol = new window.Kernel.Symbol()
  let newCapSymbol = new window.Kernel.CapSymbol()
  let newSchema = new window.Kernel.Schema()
  let currentData = {}
  try {
    newSymbol.load(u8_data)
    currentData = newSymbol
  } catch (error) {
    try {
      newCapSymbol.load(u8_data)
      currentData = newCapSymbol
      // downloadFileData(u8_data,"capSymbol")
    } catch (error) {
      newSchema.load(u8_data)
      currentData = newSchema
    }
  }
  recFileCall(currentData)
  let dataArr = []
  for (let i = 0; i < recFileCallArr.length; i++) {
    let flag = true
    for (let j = 0; j < dataArr.length; j++) {
      if (dataArr[j].snow_id == recFileCallArr[i].snow_id) {
        flag = false
      }
    }
    if (flag) {
      dataArr.push(recFileCallArr[i])
    }
  }
  if (dataArr.length > 0) {
    dataArr.forEach(item => {
      schemaSymbolArr.push([item.snow_id, item.name])
    })
  }

  let otherData = await getFileDataFn(schemaSymbolArr)

  let fileData = await assembleSchemaFileFn(otherData[0], otherData[1])

  recFileCallArr.splice(0, recFileCallArr.length)
  return fileData
}

//递归获取层级数据
function recFileCall(data) {
  if (!data.has_circle()) {
    recFileCallArr.unshift(data)
    if (data.children.length) {
      data.children.forEach(item => {
        recFileCall(item)
      })
    } else {
      return
    }
  } else {
  }
}

//保存整个文件中的数据
export const saveAllSchemaFileData = async function (schemaFile, fileGns) {
  if (schemaFile.schemas.size) {
    for (let i = 0; i < schemaFile.schemas.size; i++) {
      await saveSchemaPublicFn(schemaFile.schemas.get(i), 'DIAGRAM_FILE', [fileGns, schemaFile.schemas.get(i).snow_id].join('/'))
    }
  }
  if (schemaFile.symbols.size) {
    for (let i = 0; i < schemaFile.symbols.size; i++) {
      await saveSchemaPublicFn(schemaFile.symbols.get(i), 'SYMBOLS_FILE', [fileGns, schemaFile.symbols.get(i).snow_id].join('/'))
    }
  }
  if (schemaFile.cap_symbols.size) {
    for (let i = 0; i < schemaFile.cap_symbols.size; i++) {
      await saveSchemaPublicFn(schemaFile.cap_symbols.get(i), 'SYMBOLS_FILE', [fileGns, schemaFile.cap_symbols.get(i).snow_id].join('/'))
    }
  }
  await saveSchemaPublicFn(schemaFile, 'DIAGRAM_FILE', fileGns)
}

export const saveSchemaPublicFn = async function (fileData, type, gns) {
  let data = fileData.dump()
  // 使用data构造blob，然后发送到后端
  let blob = new Blob([data])
  let formData = new FormData() //新建表单对象
  formData.append('file', blob)
  await putObject(formData, type, fileData.snow_id, gns)
}

//保存原理图文件上传文件流
export const putObject = async function (formData, file_type, snow_id, gns) {
  let folder_name = ''
  folder_name = getCurrentBoardFolderName(gns)
  let bucket_name = localStorage.getItem('bucket_name')
  let minio_name = localStorage.getItem('minio_name')
  let time = parseInt(new Date().getTime() / 1000)
  formData.append('time', time)
  formData.append('bucket_name', bucket_name)
  formData.append('folder_name', folder_name)
  formData.append('file_type', file_type)
  formData.append('object_name', '')
  let apiKey = localStorage.getItem('apiKey')
  let res = await putObject_api(formData, {
    sign: Md5(`${apiKey}_${time}`),
    username: minio_name,
  })
  if (res.code === 200000) {
    let paramsArr = [{ file_url: res.data.object_path, snow_id }]
    let res2 = await saveDiagramFile_api({ file_data: paramsArr })
  }

  //保存当前返回的url
}

//获取文件夹名称,一个用户一个文件夹,一个团队一个文件夹
export const getCurrentBoardFolderName = function (gns) {
  let cellGns = gns
  if (store.state.proInfo.hasOwnProperty('teamRule')) {
    //团队项目
    return `team_${store.state.topInfo.teamId}/${cellGns}`
  } else {
    //个人项目
    return `user_${localStorage.getItem('userId')}/${cellGns}`
  }
}

// 单独获取原理图文件数据
export const getSchemaFileData = async function (fileId) {
  let urlData = await getDiagramFile_api({ file_snow_id: [fileId] })
  if (urlData.code === 200000) {
    let data_u8 = await getObjectDataFn(urlData.data[0].file_url)
    let newSchemaFile = new window.Kernel.SchemaFile()
    newSchemaFile.snow_id = fileId
    newSchemaFile.load(data_u8)

    return newSchemaFile
  }
}

// 通过fileGns获取整个文件的数据
export const getWholeFileData = async function (fileData, category_child) {
  let schemaSymbolArr = []
  let fileGns = fileData.gns
  let fileId = fileData.snow_id
  let cellRes = await getCellList_api({
    start: 0,
    rows: 200,
    parent_gns: fileGns,
    category: 'CELL',
    category_child,
  })
  cellRes.data.data.forEach(item => {
    schemaSymbolArr.push([item._source.snow_id, item._source.name])
  })

  let newSchemaFile = new window.Kernel.SchemaFile()
  let SchemaFileStreamArr = []
  let SchemaFileDataArr = []
  newSchemaFile.snow_id = fileId
  if (schemaSymbolArr.length) {
    let newDataArr = await getFileDataFn(schemaSymbolArr)

    SchemaFileStreamArr = newDataArr[0]
    SchemaFileDataArr = newDataArr[1]

    if (SchemaFileStreamArr.length > 0) {
      newSchemaFile.assemble(SchemaFileStreamArr)
    }

    SchemaFileDataArr.forEach(item => {
      newSchemaFile.add_schema(item)
    })

    newSchemaFile.name = fileData.name

    for (let i = 0; i < newSchemaFile.schemas.size; i++) {
      newSchemaFile.schemas.get(i).js_obj.isSave = true
    }
    return newSchemaFile
  } else {
    return newSchemaFile
  }
}
