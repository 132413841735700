import { isEqual, max } from 'underscore'
import { distanceToLineSegment } from '../../graphic_f32/point-to-line-segment'
import { getAroundIndex, slopeAndIntercept, isValidPos, calculateIntersectionPoint } from '../qeda-schema-graphics'
import { deepClone } from '../../utils'
import { hexToRgb } from '../render-util'
import { getAStartPath } from './layout_astar'
import bus from '@/components/common/bus'
import { isCircle, renameSchemaNameCopy } from '../../../components/homes/fileList/function/fileListPublicFn'
import store from '../../../store'
export const directionMap = {
  中心: 'o',
  上: 'n',
  下: 's',
  左: 'w',
  右: 'e',
  左上: 'nw',
  右上: 'ne',
  左下: 'sw',
  右下: 'se',
}

export const BoardMode = {
  SELECT: 0,
  PART_SELECT: 1,
  CP_LINE: 2,
  PIN: 3,
  LABEL: 4,
  SYMBOL: 5,
  MOVE: 6,
  PASTE: 7,
  HL_NET: 8,
  RM_NET: 9,
}

export const HLNetColors = ['#ff00f9', '#00d33c', '#00cfff', '#c88fff', '#d3205c', '#ffc200', '#009e71', '#ff6e2e', '#c0e234', '#00e5c4']
export const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict'
Date.prototype.Format = function (fmt) {
  // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}
//根据框选盒子判断点线是否在内部或相交
export function checkInsideSelectBoxPoints(select_AABB, arry, isPath = false, flag = false) {
  const result = []
  const length = arry.length
  const minX = select_AABB[0][0]
  const minY = select_AABB[0][1]
  const maxX = select_AABB[1][0]
  const maxY = select_AABB[1][1]
  if (this.select_mode && !flag) {
    for (let i = 0; i < length; i++) {
      let p = arry[i]
      let x = p[0]
      let y = p[1]
      if (x > minX && x < maxX && y > minY && y < maxY) {
        result.push(i)
      }
    }
  } else {
    if (!isPath) {
      for (let i = 0; i < length; i++) {
        let p1 = arry[i]
        let next = i + 1
        if (next === length) {
          next = 0
        }
        let p2 = arry[next]
        if (lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY)) {
          result.push(i)
          result.push(next)
        }
      }
    } else {
      for (let i = 0; i < length - 1; i++) {
        let p1 = arry[i]
        let p2 = arry[i + 1]
        if (lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY)) {
          result.push(i)
          result.push(i + 1)
        }
      }
    }
  }
  return result
}

//判断线段和盒子相交
export function lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY) {
  //都在盒子内
  if ((p1[0] > minX && p1[0] < maxX && p1[1] > minY && p1[1] < maxY) || (p2[0] > minX && p2[0] < maxX && p2[1] > minY && p2[1] < maxY)) {
    return true
  }

  const points = [
    [minX, minY],
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ]
  for (let i = 0; i < 4; i++) {
    let p3 = points[i]
    let p4 = points[i + 1]
    if (segmentsIntr(p1, p2, p3, p4)) {
      return true
    }
  }
  return false
}

export function segmentsIntr(a, b, c, d) {
  // 三角形abc 面积的2倍
  var area_abc = (a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])

  // 三角形abd 面积的2倍
  var area_abd = (a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0])

  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd >= 0) {
    return false
  }

  // 三角形cda 面积的2倍
  var area_cda = (c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0])
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  var area_cdb = area_cda + area_abc - area_abd
  if (area_cda * area_cdb >= 0) {
    return false
  }

  return true

  //计算交点坐标
  var t = area_cda / (area_abd - area_abc)
  var dx = t * (b[0] - a[0]),
    dy = t * (b[1] - a[1])
  return { x: a.x + dx, y: a.y + dy }
}
//计算所有对象为整体的AABB
export function calGraphicsAABB(result, aabb) {
  if (!result) {
    return [
      [aabb[0][0], aabb[0][1]],
      [aabb[1][0], aabb[1][1]],
    ]
  }
  if (result[0][0] > aabb[0][0]) {
    //minx
    result[0][0] = aabb[0][0]
  }
  if (result[0][1] > aabb[0][1]) {
    //miny
    result[0][1] = aabb[0][1]
  }
  if (result[1][0] < aabb[1][0]) {
    //maxx
    result[1][0] = aabb[1][0]
  }
  if (result[1][1] < aabb[1][1]) {
    //maxy
    result[1][1] = aabb[1][1]
  }
  return result
}

//包围盒剔除
export function checkInsideStage(aabb, b) {
  return !(b[0][0] > aabb[1][0] || b[1][0] < aabb[0][0] || b[0][1] > aabb[1][1] || b[1][1] < aabb[0][1])
}
//根据框选盒子判断是否在内部或相交 整体选择
export function checkInsideSelectBoxOver(select_AABB, obj, force_intersection = false) {
  const b = obj.aabb
  if (this.select_mode && !force_intersection) {
    //在内部
    return b[0][0] > select_AABB[0][0] && b[1][0] < select_AABB[1][0] && b[0][1] > select_AABB[0][1] && b[1][1] < select_AABB[1][1]
  } else {
    //相交
    if (!(b[0][0] > select_AABB[1][0] || b[1][0] < select_AABB[0][0] || b[0][1] > select_AABB[1][1] || b[1][1] < select_AABB[0][1])) {
      if (obj.type === QedaGraphicType.CIRCLE) {
        return true
      } else {
        return checkInsideSelectBoxPoints(select_AABB, obj.shape, obj.type === QedaGraphicType.PATH).length > 0
      }
    }
  }
}

//根据框选盒子判断是否在内部或相交 部分选择
export function checkInsideSelectBoxPart(select_AABB, b, force_intersection = false) {
  if (this.select_mode && !force_intersection) {
    //在内部
    return b[0][0] > select_AABB[0][0] && b[1][0] < select_AABB[1][0] && b[0][1] > select_AABB[0][1] && b[1][1] < select_AABB[1][1]
  } else {
    //相交
    return !(b[0][0] > select_AABB[1][0] || b[1][0] < select_AABB[0][0] || b[0][1] > select_AABB[1][1] || b[1][1] < select_AABB[0][1])
  }
}

export function axisAdaptAABB(axis, stage, target_aabb) {
  if (!target_aabb) return
  let board_aabb = stage.aabb
  const target_h = target_aabb[1][1] - target_aabb[0][1]
  const stage_h = board_aabb[1][1] - board_aabb[0][1]
  const target_w = target_aabb[1][0] - target_aabb[0][0]
  const stage_w = board_aabb[1][0] - board_aabb[0][0]
  const scale_h = stage_h / target_h
  const scale_w = stage_w / target_w
  let scale = axis.scale * (scale_w > scale_h ? scale_h : scale_w) * 0.9
  if (scale > axis.scale_max * 2) {
    scale = axis.scale_max * 2
  } else if (scale < axis.scale_min / 2) {
    scale = axis.scale_min / 2
  }
  axis.scale = scale
  //计算坐标系统偏移量
  const centerX = target_aabb[0][0] + (target_aabb[1][0] - target_aabb[0][0]) / 2
  const centery = target_aabb[0][1] + (target_aabb[1][1] - target_aabb[0][1]) / 2
  axis.dx = stage.width / 2 - centerX * axis.scale
  axis.dy = stage.height / 2 + centery * axis.scale
  stage.drawRulerX()
  stage.drawRulerY()
  stage.config.onMouseWheel(axis.scale)
}

export function axisInitRrender(axis, canvasId) {
  axis.rulerX.height = 22
  axis.rulerY.width = 22
  var stage = document.getElementById('stage-container')
  axis.width = stage.offsetWidth - 25
  axis.height = stage.offsetHeight - 25
  axis.dx = axis.width / 2
  axis.dy = axis.height / 2
  axis.rulerX.width = axis.width
  axis.rulerY.height = axis.height
  axis.rulerX.height = 25
  axis.rulerY.width = 25
  axis.render()
  var canvas = document.getElementById(canvasId)
  canvas.width = axis.width
  canvas.height = axis.height
}

export function moveAxis(e, stage, axis, quadTree) {
  let move = false
  const delta = stage.width * 0.2 //以画板20%移动
  if (e.key === 'ArrowRight') {
    axis.dx -= delta
    move = true
  } else if (e.key === 'ArrowLeft') {
    axis.dx += delta
    move = true
  } else if (e.key === 'ArrowUp') {
    axis.dy += delta
    move = true
  } else if (e.key === 'ArrowDown') {
    axis.dy -= delta
    move = true
  }
  if (move) {
    axis.updateValue()
    axis.render()
    stage.renderRulers()
    stage.updateAABB()
    stage.updateRenderObjs(quadTree)
    stage.render()
  }
}

export class SchemaQuadTree {
  constructor(schema) {
    this.schema = schema
    this.showConfig = null
    this.#initial_quadtree()
  }

  #bounding_box_to_quadtreebox(bounding_box) {
    var top = bounding_box[1][1]
    var left = bounding_box[0][0]
    var width = Math.abs(bounding_box[1][0] - bounding_box[0][0])
    var height = Math.abs(bounding_box[1][1] - bounding_box[0][1])
    // for origin point (0,0) is most left top point, y aix is reverse to cartesian coord
    var box = new QGdstk.QuadtreeBox(left, -top, width, height)
    return box
  }

  #path_bounding_box(path) {
    var points = path.spine()
    var x = []
    var y = []
    points.forEach(element => {
      x.push(element[0])
      y.push(element[1])
    })
    var x_max = Math.max(x)
    var x_min = Math.min(x)
    var y_max = Math.max(y)
    var y_min = Math.min(y)
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  #initial_quadtree() {
    var bounding_box = [
      [-1000000, -100000],
      [100000, 100000],
    ] //this.schema.bounding_box
    var box = this.#bounding_box_to_quadtreebox(bounding_box)
    this.quadtree = new QGdstk.Quadtree(box)
    this.id_body_table = new Map()
    this.current_id = 0
    this.#process_schema(this.schema)
  }

  #process_schema(schema) {
    var cpls = schema.cp_lines
    for (let i = 0; i < cpls.length; i++) {
      const cpl = cpls[i]
      this.addNode(cpl)
    }

    var symbols = schema.symbol_ins
    for (let i = 0; i < symbols.length; i++) {
      const symbolIns = symbols[i]
      this.addNode(symbolIns)
    }

    var cap_symbols = schema.capsymbol_ins
    for (let i = 0; i < cap_symbols.length; i++) {
      const symbolIns = cap_symbols[i]
      this.addNode(symbolIns)
    }

    var pins = schema.pins
    for (let i = 0; i < pins.length; i++) {
      const pinsIns = pins[i]
      this.addNode(pinsIns)
    }

    var labels = schema.labels
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]
      this.addNode(label)
    }
  }

  reBuild() {
    this.#initial_quadtree()
  }

  addNode(el) {
    let aabb = el.bounding_box
    if (!aabb) {
      if (el.line) {
        aabb = [
          [0, 0],
          [0, 0],
        ]
      } else {
        return
      }
    }
    var box = this.#bounding_box_to_quadtreebox(aabb)
    var node = new QGdstk.QuadtreeNode(this.current_id, box)
    el.js_obj.tree_id = this.current_id
    this.id_body_table.set(this.current_id, { obj: el, node })
    this.quadtree.add(node)
    this.current_id++
    if (el.$$?.ptrType.name == 'Pin*') {
      this.addNode(el.name)
    }
  }

  removeNode(el) {
    let node = this.id_body_table.get(el.js_obj.tree_id)?.node
    if (!node) return false
    this.id_body_table.delete(el.js_obj.tree_id)
    this.quadtree.remove(node)
    if (el.$$?.ptrType.name == 'Pin*') {
      this.removeNode(el.name)
    }
    return true
  }

  updateNode(el) {
    //更新pin ins_name标签
    // if (el.name) {
    //   this.updateNode(el.name)
    // }

    let exist = this.removeNode(el)

    if (exist) {
      this.addNode(el)
    }
  }

  query(x, y, width, height) {
    var box = new QGdstk.QuadtreeBox(x, -y, width, height)
    var node = this.quadtree.query(box)
    var result = []
    for (let i in node) {
      result.push(this.id_body_table.get(node[i].id).obj)
    }
    return result
  }

  queryByAABB(aabb, flag) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    if (flag) {
      node = node.filter(n => quadtreebox.contains(n.box))
    }
    let rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    for (let i in node) {
      let item = this.id_body_table.get(node[i].id).obj
      if (this.showConfig) {
        if (!this.showConfig.pinName && item.belong_to?.$$?.ptrType.name === 'Pin*') continue
        if (!this.showConfig.netName && item.belong_to?.$$?.ptrType.name === 'CpLine*') continue
        if (!this.showConfig.normalLabel && item.$$?.ptrType.name === 'Label*' && item.belong_to == null) continue
      }
      //判断内部相交
      if (!flag && !hitObj(rect, item)) {
        continue
      }
      result.push(item)
    }
    return result
  }

  queryCpLines(aabb) {
    return this.queryByAABB(aabb).filter(obj => obj instanceof Kernel.CpLine)
  }

  querySymbols(aabb) {
    return this.queryByAABB(aabb).filter(obj => obj instanceof Kernel.Pin || obj instanceof Kernel.SymbolIns)
  }

  queryObstaclesByAABB(aabb) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    for (let i in node) {
      let item = this.id_body_table.get(node[i].id).obj
      if (item instanceof Kernel.Label || item instanceof Kernel.CpLine) {
        continue
      }
      result.push(item)
    }
    return result
  }

  needRenderObjs(aabb) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    for (let i in node) {
      result.push(this.id_body_table.get(node[i].id).obj)
    }
    return result
  }
}

export function newInsName(newName, names) {
  let used_num = 0 //计算名字使用次数
  let separator = '-'
  names.forEach(name => {
    if (used_num == 0) {
      if (newName === name) {
        used_num = 1
      }
    }

    if (name.indexOf(newName) === 0) {
      let words = name.split('')
      words.splice(0, newName.length)
      if (words.length > 1) {
        if (separator === '(') {
          // if (words[0] === '(' && words[words.length - 1] === ')') {
          //   words.shift()
          //   words.pop()
          //   let num_words = words.join('')
          //   if (!isNaN(num_words)) {
          //     //字符串为合法数字
          //     let num = parseInt(words.join(''))
          //     if (num >= used_num) {
          //       used_num = num + 1
          //     }
          //   }
          // }
        } else if (separator === '-') {
          if (words[0] === '-') {
            words.shift()
            let num_words = words.join('')
            if (!isNaN(num_words)) {
              //字符串为合法数字
              let num = parseInt(words.join(''))
              if (num >= used_num) {
                used_num = num + 1
              }
            }
          }
        }
      }
    }
  })
  // used_num = used_num == 0 ? 1 : used_num
  if (used_num) {
    if (separator == '(') {
      newName = `${newName}(${used_num})`
    } else if (separator == '-') {
      newName = `${newName}-${used_num}`
    }
  } else {
    newName = `${newName}-1`
  }
  return newName
}

export function newSymbolName(newName, names) {
  let used_num = 0 //计算名字使用次数
  let separator = '('
  names.forEach(name => {
    if (used_num == 0) {
      if (newName === name) {
        used_num = 1
      }
    }

    if (name.indexOf(newName) === 0) {
      let words = name.split('')
      words.splice(0, newName.length)
      if (words.length > 1) {
        if (separator === '(') {
          if (words[0] === '(' && words[words.length - 1] === ')') {
            words.shift()
            words.pop()
            let num_words = words.join('')
            if (!isNaN(num_words)) {
              //字符串为合法数字
              let num = parseInt(words.join(''))
              if (num >= used_num) {
                used_num = num + 1
              }
            }
          }
        } else if (separator === '-') {
          if (words[0] === '-') {
            words.shift()
            let num_words = words.join('')
            if (!isNaN(num_words)) {
              //字符串为合法数字
              let num = parseInt(words.join(''))
              if (num >= used_num) {
                used_num = num + 1
              }
            }
          }
        }
      }
    }
  })
  if (used_num) {
    newName = `${newName}(${used_num})`
  }
  return newName
}

export function newShortName(symbolName, shortName) {
  let words = symbolName.split('(')
  if (words.length > 1) {
    let end = words.at(-1).split(')')
    if (end.length == 2) {
      let num_words = end[0]
      if (!isNaN(num_words)) {
        return `${shortName}(${num_words})`
      }
    }
  }
  return shortName
}

//获取默认网络名
export function newNetName(LAYOUT) {
  let schema = LAYOUT.schema
  let cp_lines = schema.cp_lines
  let used_num = 1
  for (let i = 0; i < cp_lines.length; i++) {
    const cpl = cp_lines[i]
    let net_name = cpl.net_name
    if (net_name.indexOf('net') == 0) {
      let num_words = net_name.replace('net', '')
      if (!isNaN(num_words)) {
        //字符串为合法数字
        let num = parseInt(num_words)
        if (num >= used_num) {
          used_num = num + 1
        }
      }
    }
  }
  return `net${used_num}`
}

//修改鼠标样式
export function changeCursor(canvasId, type) {
  let canvas = document.getElementById(canvasId)
  canvas.setAttribute('data-cursor', type)
}

//重新计算画板宽度
export function resizeLayer(LAYOUT) {
  var canvas = document.getElementById(LAYOUT.canvasId)
  if (!canvas) return
  var stage = document.getElementById('stage-container')
  LAYOUT.axis.width = stage.offsetWidth - 25
  LAYOUT.axis.height = stage.offsetHeight - 25

  canvas.width = LAYOUT.axis.width
  canvas.height = LAYOUT.axis.height
  LAYOUT.stage.updateSize()
  LAYOUT.stage.updateAABB()
  LAYOUT.axis.rulerX.width = LAYOUT.axis.width
  LAYOUT.axis.rulerY.height = LAYOUT.axis.height
  LAYOUT.axis.render()
  bus.$emit('updateSchemaLayout', LAYOUT)
  setTimeout(() => {
    // LAYOUT.stage.updateRenderObjs(LAYOUT.quadTree)
    LAYOUT.stage.renderRulers()
  })
  LAYOUT.stage.render()
}

export function layoutDrawObject(LAYOUT) {
  if (LAYOUT.boardMode === BoardMode.SELECT) {
    if (LAYOUT.drawingObject) {
      //正在绘制
      if (LAYOUT.drawType === 'selectBox' || LAYOUT.drawType === 'cutBox') {
        //根据绘制点不需要坐标转换
        LAYOUT.drawingObject.drawPoint(LAYOUT.axis.mouse_point)
        // document.querySelector('body').style.cursor = url('./favicon.ico')
      } else {
        let perfect = false
        if (LAYOUT.press_shift || LAYOUT.axis.angledAdsorption) {
          perfect = true
        }
        LAYOUT.drawingObject.drawPoint(LAYOUT.getMousePostion(), false, perfect)
      }
      LAYOUT.stage.render()
      return
    }
    // if (LAYOUT.boardMode === 0 && LAYOUT.stage.checked_objs_hited) {
    //   //整体选择 如果落点选中物体禁止绘制选择框
    //   LAYOUT.completeDraw()
    //   return
    // }
    // if (LAYOUT.boardMode === 1 && LAYOUT.stage.checked_objs_hited) {
    //   //部分选择 选中物体禁止绘制选择框
    //   LAYOUT.completeDraw()
    //   return
    // }

    if (LAYOUT.rightPress) {
      //右键拖动绘制自适应框
      if (!LAYOUT.drawAdaptRect) {
        LAYOUT.axis.setDrag(false, null)
        let newGraph = QedaSchemaGraphics.newQedaSchemaGraphic('adaptRect', LAYOUT.getMousePostion())
        LAYOUT.drawingObject = newGraph
        LAYOUT.stage.addTool([newGraph])
      }
      LAYOUT.drawAdaptRect = true
      return
    }
    //左键拖动
    if (LAYOUT.leftPress && !LAYOUT.viewMode) {
      if (LAYOUT.checked_objs.length && LAYOUT.checked_objs_hited) {
        //记录拖动鼠标起始状态
        if (LAYOUT.drag_count === 0) {
          LAYOUT.record('transform')
        }
        //编辑单条线，判断生成点
        addPointToCpLine(LAYOUT)
        LAYOUT.drag_count++
        let pos = LAYOUT.getMousePosArray()
        let moveAll = !LAYOUT.stage.editCpLine || (LAYOUT.stage.editCpLine && LAYOUT.stage.editCpLine.checked_points_index?.length === LAYOUT.stage.editCpLine.js_obj.originLength)
        LAYOUT.checked_objs.forEach(obj => {
          moveObj(LAYOUT, obj, pos)
          if (moveAll && obj.$$?.ptrType.name === 'CpLine*') {
            for (let j = 0; j < obj.labels.length; j++) {
              const label = obj.labels[j]
              if (!label.js_obj.checked) {
                moveObj(LAYOUT, label, pos)
              }
            }
          }
        })
        updateLinePointsByPorts(LAYOUT, LAYOUT.checked_objs, LAYOUT.schema)
        return
      }
    }
    if (LAYOUT.drawType === 'selectBox' || LAYOUT.drawType === 'adaptRect') {
      //左键拖动绘制选择框
      if (LAYOUT.stage.moveMod || !LAYOUT.leftPress || LAYOUT.boardMode === 2 || LAYOUT.stage.editCpLine) return
      const mouse_pos = LAYOUT.axis.mouse_point //this.getMousePostion()
      LAYOUT.drawPoints.push(mouse_pos)
      let newGraph = QedaSchemaGraphics.newQedaSchemaGraphic(LAYOUT.drawType, mouse_pos)
      LAYOUT.drawingObject = newGraph
      LAYOUT.stage.addTool([newGraph])
    }
  } else if (LAYOUT.boardMode === BoardMode.LABEL) {
    if (LAYOUT.drawingObject) {
      LAYOUT.drawingObject.pos = LAYOUT.getMousePosArray()
      buildLabelBox(LAYOUT.drawingObject, LAYOUT.config)
      LAYOUT.quadTree.updateNode(LAYOUT.drawingObject)
    }
  } else if (LAYOUT.boardMode === BoardMode.PIN) {
    if (LAYOUT.drawingObject) {
      LAYOUT.drawingObject.pos = LAYOUT.getMousePosArray()
      buildPinPath(LAYOUT.drawingObject, LAYOUT.config)
      LAYOUT.quadTree.updateNode(LAYOUT.drawingObject)
    }
  } else if (LAYOUT.boardMode === BoardMode.SYMBOL) {
    if (LAYOUT.drawingObject) {
      LAYOUT.drawingObject.pos = LAYOUT.getMousePosArray()
      buildSymbolPath(LAYOUT.drawingObject)
      LAYOUT.quadTree.updateNode(LAYOUT.drawingObject)
    }
  } else if (LAYOUT.boardMode === BoardMode.CP_LINE) {
    if (LAYOUT.drawingObject) {
      drawLinePoint(LAYOUT, LAYOUT.drawingObject, LAYOUT.getMousePosArray(), LAYOUT.latestConfirmPos, LAYOUT.lineMod, LAYOUT.lineAngle)
    }
  } else if (LAYOUT.boardMode === BoardMode.MOVE) {
    if (LAYOUT.latestConfirmPos) {
      //记录拖动鼠标起始状态
      if (LAYOUT.drag_count === 0) {
        LAYOUT.record('transform')
      }
      LAYOUT.drag_count++
      let pos = LAYOUT.getMousePosArray()
      LAYOUT.checked_objs.forEach(obj => {
        moveObj(LAYOUT, obj, pos)
        if (obj.$$?.ptrType.name === 'CpLine*') {
          for (let j = 0; j < obj.labels.length; j++) {
            const label = obj.labels[j]
            if (!label.js_obj.checked) {
              moveObj(LAYOUT, label, pos)
            }
          }
        }
      })
      updateLinePointsByPorts(LAYOUT, LAYOUT.checked_objs, LAYOUT.schema)
      return
    }
  }
}

function addPointToCpLine(LAYOUT) {
  if (LAYOUT.stage.editCpLine && LAYOUT.addPoint) {
    let cpLine = LAYOUT.stage.editCpLine
    let points = cpLine.line.points
    points.splice([LAYOUT.addPoint.index + 1], 0, LAYOUT.addPoint.point)
    cpLine.line.js_obj.midPoints = getMidPoints(cpLine)
    cpLine.js_obj.points_mouse_offset = []
    let len = points.length
    let target = LAYOUT.addPoint.index + 1
    let noOff = null
    for (let i = 0; i < len; i++) {
      let off = noOff
      if (i == target) {
        off = LAYOUT.addPoint.offset
      }
      cpLine.js_obj.points_mouse_offset.push(off)
    }
    cpLine.js_obj.originLength = len
    cpLine.line.swap(points)
    LAYOUT.addPoint = null
  }
}

//完成图形绘制
export function layoutCompleteDraw(LAYOUT) {
  LAYOUT.last_mouse_pos = null
  if (LAYOUT.drawingObject || LAYOUT.preConnectNetLabel) {
    if (LAYOUT.boardMode === BoardMode.SELECT) {
      if (LAYOUT.drawAdaptRect || LAYOUT.drawType === 'adaptRect') {
        //框选自适应绘制完成
        let aabb = LAYOUT.drawingObject.getAABB()
        LAYOUT.stage.clearTools()
        LAYOUT.drawingObject = null
        LAYOUT.drawAdaptRect = false
        LAYOUT.adaptAABB(aabb)
        LAYOUT.drawType = 'selectBox'
      } else if (LAYOUT.drawType === 'selectBox') {
        LAYOUT.drawPoints.push(LAYOUT.getMousePostion())
        let flag = LAYOUT.drawPoints[0].x > LAYOUT.drawPoints[1].x ? false : true //框选半选模式 全选模式
        let aabb = LAYOUT.drawingObject.getAABB()
        if (aabb && aabb[0][0] < aabb[1][0]) {
          // LAYOUT.updateSelectObjByAABB(aabb, flag)
          LAYOUT.pickObjsBySelectBox(aabb, flag)
        }
        LAYOUT.drawingObject = null
        LAYOUT.drawPoints = []
        LAYOUT.stage.clearTools()
        LAYOUT.stage.render()
      }
    } else if (LAYOUT.boardMode === BoardMode.LABEL) {
      let confirmAddLabel = LAYOUT.drawingObject
      let connectCpl = LAYOUT.pre_checked_obj
      if (LAYOUT.drawingObject?.is_net) {
        if (connectCpl) {
          let before_net_name = connectCpl.net_name
          connectCpl.add_label(LAYOUT.drawingObject)
          //建立网络标签与线的联系
          connectCpl.net_name = LAYOUT.drawingObject.text
          reNameCplNetLabels(LAYOUT, connectCpl, before_net_name)
        } else {
          //先放置再连接
          LAYOUT.preConnectNetLabel = LAYOUT.drawingObject
          LAYOUT.drawingObject = null
          return
        }
      }
      if (LAYOUT.preConnectNetLabel) {
        if (connectCpl) {
          let before_net_name = connectCpl.net_name
          connectCpl.add_label(LAYOUT.preConnectNetLabel)
          //建立网络标签与线的联系
          connectCpl.net_name = LAYOUT.preConnectNetLabel.text
          reNameCplNetLabels(LAYOUT, connectCpl, before_net_name)
          confirmAddLabel = LAYOUT.preConnectNetLabel
          LAYOUT.preConnectNetLabel = null
        } else {
          return
        }
      }
      //ctDrawDataq
      let text = getCtLabelText(LAYOUT.ctText)
      let size = confirmAddLabel.size
      let font = confirmAddLabel.font
      let anchor = confirmAddLabel.anchor
      let is_net = confirmAddLabel.is_net
      let color = confirmAddLabel.color
      buildLabelBox(confirmAddLabel, LAYOUT.config)
      //记录上一次放置
      LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: [confirmAddLabel] }])
      if (text == null) {
        LAYOUT.ctText = LAYOUT.loopText.split(',')
        text = getCtLabelText(LAYOUT.ctText)
        // LAYOUT.drawingObject = null
        // // LAYOUT.switchBoardMode(BoardMode.SELECT)
        // return
      }
      var label = new Kernel.Label()
      label.pos = LAYOUT.getMousePosArray()
      label.text = text
      label.size = size
      label.font = font
      label.anchor = anchor
      label.is_net = is_net
      label.color = color
      buildLabelBox(label, LAYOUT.config)
      LAYOUT.add(label)
      LAYOUT.drawingObject = label
    } else if (LAYOUT.boardMode === BoardMode.PIN) {
      //ctDrawData
      let text = getCtLabelText(LAYOUT.ctText)
      if (text == null) {
        LAYOUT.ctText = LAYOUT.loopText.split(',')
        text = getCtLabelText(LAYOUT.ctText)
      }
      let io = LAYOUT.drawingObject.io
      LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: [LAYOUT.drawingObject] }])
      autoConnectSymbolPort(LAYOUT, [LAYOUT.drawingObject])
      autoConnectPort(LAYOUT)
      if (text == null) {
        LAYOUT.drawingObject = null
        return
      }
      let pinIns = new Kernel.pin()
      var label = new Kernel.Label()
      label.anchor = Kernel.Anchor.Centra
      // label.pos = LAYOUT.getMousePosArray()
      label.color = LAYOUT.drawingObject.name.color
      label.text = text
      label.size = LAYOUT.config.default_font_size
      buildLabelBox(label, LAYOUT.config)
      pinIns.io = io
      pinIns.name = label
      pinIns.pos = LAYOUT.getMousePosArray()
      buildPinPath(pinIns, LAYOUT.config)
      LAYOUT.add(pinIns)
      LAYOUT.drawingObject = pinIns
    } else if (LAYOUT.boardMode === BoardMode.SYMBOL) {
      // LAYOUT.quadTree.addNode(LAYOUT.drawingObject)
      // LAYOUT.schema.add_symbol_ins(LAYOUT.drawingObject)
      LAYOUT.add(LAYOUT.drawingObject)
      LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: [LAYOUT.drawingObject] }])
      autoConnectSymbolPort(LAYOUT, [LAYOUT.drawingObject])
      autoConnectPort(LAYOUT)
      LAYOUT.drawingObject = null
      LAYOUT.switchBoardMode(BoardMode.SELECT)

      // LAYOUT.file.capsymbol_ins.length
      // for (let i = 0; i < LAYOUT.file.capsymbol_ins.length; i++) {

      // }
      // for (let i = 0; i < LAYOUT.file.symbols.length; i++) {

      // }
      bus.$emit('loading', true)
      // bus.$emit('updateSchemaFileTree', true)
    } else if (LAYOUT.boardMode === BoardMode.CP_LINE) {
      removeCpLinePos(LAYOUT.drawingObject, LAYOUT.lineAngle)
      if (LAYOUT.drawingObject.line.points.length > 1) {
        let result = breakLine(LAYOUT, LAYOUT.drawingObject)
        if (result.length > 1) {
          //线被端口截断 分解成多条线
          result.forEach(cpL => {
            LAYOUT.add(cpL)
          })
          LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: result }])
          deleteDrawIngObj(LAYOUT)
        } else {
          buildCpLinePath(LAYOUT.drawingObject)
          LAYOUT.quadTree.updateNode(LAYOUT.drawingObject)
          LAYOUT.editHistory.record([{ action: 'add', schema: LAYOUT.schema, objs: [LAYOUT.drawingObject] }])
        }
      } else {
        deleteDrawIngObj(LAYOUT)
      }
      LAYOUT.drawingObject = null
    }
  }
}

//不记录 删除绘制对象
function deleteDrawIngObj(LAYOUT) {
  LAYOUT.delete(LAYOUT.drawingObject)
  LAYOUT.drawingObject.path?.delete()
}

function removeCpLinePos(drawingObject, lineAngle) {
  //删除未确认的绘制的点
  if (drawingObject.line.points.length > 1) {
    //拐角绘制
    if (lineAngle) {
    } else {
      //任意角度绘制
      let points = drawingObject.line.points
      points.pop()
      drawingObject.line.swap(points)
    }
  }
}

//重构原理图对象path
export function buildObjPath(obj, config) {
  if (obj instanceof Kernel.Label) {
    buildLabelBox(obj, config)
  } else if (obj instanceof Kernel.Pin) {
    buildPinPath(obj, config)
  } else if (obj instanceof Kernel.SymbolIns) {
    buildSymbolPath(obj)
  } else if (obj instanceof Kernel.CpLine) {
    obj.line.js_obj.midPoints = getMidPoints(obj.line.points)
    buildCpLinePath(obj)
  }
}

//构建原理图渲染数据
export function buildRenderData(schema, config) {
  //耦合线

  for (let i = 0; i < schema.cp_lines.length; i++) {
    buildCpLinePath(schema.cp_lines[i])
  }
  //符号
  for (let i = 0; i < schema.symbol_ins.length; i++) {
    buildSymbolPath(schema.symbol_ins[i])
  }
  //带原理图的符号符号
  for (let i = 0; i < schema.capsymbol_ins.length; i++) {
    buildSymbolPath(schema.capsymbol_ins[i])
  }

  //PIN
  for (let i = 0; i < schema.pins.length; i++) {
    buildPinPath(schema.pins[i], config)
  }
  //标签
  for (let i = 0; i < schema.labels.length; i++) {
    buildLabelBox(schema.labels[i], config)
  }
  return schema
}

export function buildCpLinePath(cp_line, points) {
  const line = cp_line.line
  if (cp_line.js_obj.path) {
    cp_line.js_obj.path.reset()
  } else {
    cp_line.js_obj.path = new CanvasKit.Path()
  }
  cp_line.js_obj.rgb = hexToRgb(cp_line.color).map(v => v / 255)
  if (points) {
    buildPath(cp_line.js_obj.path, points)
  } else {
    buildPath(cp_line.js_obj.path, line.points)
  }
  cp_line.js_obj.center = getBoxCenter(cp_line.bounding_box)
}

export function buildSymbolPath(symbolIns) {
  if (symbolIns.js_obj.path) {
    symbolIns.js_obj.path.reset()
  } else {
    symbolIns.js_obj.path = new CanvasKit.Path()
  }
  for (let j = 0; j < symbolIns.lines.length; j++) {
    const line = symbolIns.lines[j]
    buildPath(symbolIns.js_obj.path, line.points)
  }
  for (let k = 0; k < symbolIns.polygons.length; k++) {
    const polygon = symbolIns.polygons[k]
    buildPath(symbolIns.js_obj.path, polygon.points, true)
  }
}

export function buildPinPath(pinIns, config) {
  if (pinIns.js_obj.path) {
    pinIns.js_obj.path.reset()
  } else {
    pinIns.js_obj.path = new CanvasKit.Path()
  }
  let pin_name = pinIns.name
  if (pin_name) {
    buildLabelBox(pin_name, config)
  }

  for (let j = 0; j < pinIns.lines.length; j++) {
    const line = pinIns.lines[j]

    buildPath(pinIns.js_obj.path, line.points)
  }

  for (let k = 0; k < pinIns.polygons.length; k++) {
    const polygon = pinIns.polygons[k]
    buildPath(pinIns.js_obj.path, polygon.points, true)
  }
  pinIns.js_obj.center = getBoxCenter(pinIns.bounding_box)
}

function buildPath(path, points, isPolygon = false) {
  let len = points.length
  for (let i = 0; i < len; i++) {
    let p = points[i]
    if (!i) {
      path.moveTo(p[0], p[1])
    } else {
      path.lineTo(p[0], p[1])
    }
  }
  if (isPolygon) {
    path.close()
  }
}

export function getCtLabelText(array) {
  for (let i = 0; i < array.length; i++) {
    let text = array.shift()
    if (text !== undefined && text !== '') {
      return text
    }
  }
  return null
}

export function getCtHLNetColors(lastUsedColor) {
  let res = {}
  if (!lastUsedColor) {
    res.color = HLNetColors[0]
    res.rgb = hexToRgb(res.color).map(v => v / 255)
    return res
  }
  let len = HLNetColors.length
  let last = len - 1
  for (let i = 0; i < len; i++) {
    if (HLNetColors[i] === lastUsedColor) {
      let next = i + 1
      next = next == last ? 0 : next
      res.color = HLNetColors[next]
      res.rgb = hexToRgb(res.color).map(v => v / 255)
      return res
    }
  }
}

//获取文字高
export function getGlyphHeight(bounds) {
  let minX = bounds[0]
  let minY = bounds[1]
  let maxX = bounds[2]
  let maxY = bounds[3]
  for (let i = 0; i < bounds.length; i += 4) {
    if (bounds[i] < minX) {
      minX = bounds[i]
    }
    if (bounds[i + 1] < minY) {
      minY = bounds[i + 1]
    }
    if (bounds[i + 2] > maxX) {
      maxX = bounds[i + 2]
    }
    if (bounds[i + 3] > maxY) {
      maxY = bounds[i + 3]
    }
  }

  return Math.abs(maxY - minY)
}

//获取label包围盒
export function buildLabelBox(label, config) {
  let font_type = label.font ? label.font : config.default_font
  let textFont = FONT_FACES[font_type]
  let textPaint = config.label_paint
  let anchor = label.anchor
  let font_size = label.size
  textFont.setSize(font_size)
  let ids = textFont.getGlyphIDs(label.text)
  // let bounds = textFont.getGlyphBounds(ids, textPaint)
  let widths = textFont.getGlyphWidths(ids, textPaint)
  let w = widths.reduce(function (a, b) {
    return a + b
  })

  let h = font_size //getGlyphHeight(bounds)
  // let w = label.text.length * font_size * 0.6
  // let h = 1 * font_size
  let center = label.pos
  let p1 = [center[0] - w / 2, center[1] - h / 2]
  let p2 = [center[0] - w / 2, center[1] + h / 2]
  let p3 = [center[0] + w / 2, center[1] + h / 2]
  let p4 = [center[0] + w / 2, center[1] - h / 2]

  let temp = new QGdstk.Polygon([p1, p2, p3, p4])
  if (anchor == Kernel.Anchor.Centra) {
  } else if (anchor == Kernel.Anchor.Right) {
    temp.translate([-w / 2, 0])
  } else if (anchor == Kernel.Anchor.Down) {
    temp.translate([0, h / 2])
  } else if (anchor == Kernel.Anchor.Left) {
    temp.translate([w / 2, 0])
  } else if (anchor == Kernel.Anchor.Up) {
    temp.translate([0, -h / 2])
  } else if (anchor == Kernel.Anchor.RightUp) {
    temp.translate([-w / 2, -h / 2])
  } else if (anchor == Kernel.Anchor.RightDown) {
    temp.translate([-w / 2, h / 2])
  } else if (anchor == Kernel.Anchor.LeftUp) {
    temp.translate([w / 2, -h / 2])
  } else if (anchor == Kernel.Anchor.LeftDown) {
    temp.translate([w / 2, h / 2])
  }
  temp.rotate(label.angle, center)
  label.js_obj.box = temp
  //debug
  label.js_obj.path = new CanvasKit.Path()
  buildPath(label.js_obj.path, temp.get_points(), true)
  label.js_obj.w = w
  label.js_obj.h = h
  label.bounding_box = temp.bounding_box()
  label.js_obj.center = getBoxCenter(label.bounding_box)
  label.js_obj.rgb = hexToRgb(label.color).map(v => v / 255)
  // return { rect: temp, w: w, h: h }
}

export function drawLinePoint(LAYOUT, cpLine, pos, lastPos, mod, angle, confirm = false) {
  let line = cpLine.line
  let copyPoints = []
  let points = line.points
  points.forEach(p => copyPoints.push([p[0], p[1]]))
  let len = copyPoints.length
  //确认顶点
  if (confirm) {
    if (len) {
      // line.points = deepClone(line.points_render)
      if (angle) {
        let p = calTurnPoint(lastPos, pos, mod, angle)
        //判断拐点和另外两个点相等
        if (!isEqualPos(p, lastPos) && !isEqualPos(p, pos)) {
          copyPoints.push(p, pos)
        } else {
          copyPoints.push(pos)
        }
        let new_len = copyPoints.length
        if (new_len >= 3) {
          let a_i = new_len - 1
          let b_i = new_len - 2
          let c_i = new_len - 3
          let a = copyPoints[a_i]
          let b = copyPoints[b_i]
          let c = copyPoints[c_i]
          if (collinear(a[0], a[1], b[0], b[1], c[0], c[1])) {
            copyPoints.splice(b_i, 1)
          }
        }
        if (new_len >= 4) {
          let a_i = new_len - 2
          let b_i = new_len - 3
          let c_i = new_len - 4
          let a = copyPoints[a_i]
          let b = copyPoints[b_i]
          let c = copyPoints[c_i]
          if (collinear(a[0], a[1], b[0], b[1], c[0], c[1])) {
            copyPoints.splice(b_i, 1)
          }
        }
        // copyPoints = curve_del_parallel(copyPoints)

        points = copyPoints
      } else {
        let valid = isValidPathPoint(points, pos)
        if (!valid) return
        points.push(pos)
      }
    } else {
      points.push(pos)
    }
  } else {
    //实时生成
    if (!lastPos) return
    if (angle) {
      //自动布线
      // let res = getAStartPath(LAYOUT.quadTree, lastPos, pos)
      // LAYOUT.stage.aStartDebug = null
      // if (res.points.length) {
      //   copyPoints.push(...res.points)
      //   LAYOUT.stage.aStartDebug = res
      // } else {
      let p = calTurnPoint(lastPos, pos, mod, angle)
      copyPoints.push(p, pos)
      // }
      buildCpLinePath(cpLine, copyPoints)
      return
    } else {
      const index = len < 2 ? 1 : len - 1 //鼠标移动时保证最后一个点为鼠标坐标
      points[index] = pos
    }
  }
  line.swap(points)
  buildCpLinePath(cpLine)
  if (confirm) {
    if (LAYOUT.connectionTarget) {
      let connect_port = LAYOUT.connectionTarget?.port //吸附端口
      if (connect_port) {
        LAYOUT.schema.connect(connect_port, cpLine)
      } else if (LAYOUT.connectionTarget.$$?.ptrType.name == 'CpLine*') {
        cpLine.net_name = LAYOUT.connectionTarget.net_name
      }
    }
  }
}

function isValidPathPoint(points, point) {
  let last = points.at(-2)
  if (last && last[0] === point[0] && last[1] === point[1]) {
    return false
  }
  let len = points.length
  let last_index = len - 1
  for (let index = 0; index < len; index++) {
    const p = points[index]
    if (index == last_index) break
    if (p[0] == point[0] && p[1] == point[1]) {
      return false
    }
  }
  return true
}

//计算拐点
function calTurnPoint(p1, p2, mod, angle = 1) {
  let p1_x = p1[0]
  let p1_y = p1[1]
  let p2_x = p2[0]
  let p2_y = p2[1]
  let min_x, max_x, min_y, max_y
  if (p1_x < p2_x) {
    min_x = p1_x
  } else {
    max_x = p1_x
  }
  if (p1_y < p2_y) {
    min_y = p1_y
  } else {
    max_y = p2_y
  }
  let turn_pos
  if (mod) {
    turn_pos = [p1_x, p2_y]
    if (angle === 45) {
      let dist = p1_y - p2_y
      turn_pos[0] += dist
      let includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)

      if (includedAngle == 45) {
        turn_pos[0] = p1_x - dist
        includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)
        if (includedAngle == 45) {
          turn_pos = calculate135TurnPos(p1, p2, turn_pos, mod)
        }
      }
    }
  } else {
    turn_pos = [p2_x, p1_y]
    if (angle === 45) {
      let dist = p2_y - p1_y
      turn_pos[0] += dist
      let includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)

      if (includedAngle == 45) {
        turn_pos[0] = p2_x - dist
        includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)

        if (includedAngle == 45) {
          turn_pos = calculate135TurnPos(p1, p2, turn_pos, mod)
        }
      }
    }
  }
  return turn_pos
}

function calculate135TurnPos(p1, p2, turn_pos, mod) {
  let p1_x = p1[0]
  let p1_y = p1[1]
  let p2_x = p2[0]
  let p2_y = p2[1]
  let angle = 45
  if (mod) {
    turn_pos = [p2_x, p1_y]
    if (angle === 45) {
      let dist = p1_x - p2_x
      turn_pos[1] += dist
      let includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)

      if (includedAngle == 45) {
        turn_pos[1] = p1_y - dist
      }
    }
  } else {
    turn_pos = [p1_x, p2_y]
    if (angle === 45) {
      let dist = p2_x - p1_x
      turn_pos[1] += dist
      let includedAngle = calLinesAngle(p1, turn_pos, p2, turn_pos)

      if (includedAngle == 45) {
        turn_pos[1] = p2_y - dist
      }
    }
  }
  return turn_pos
}
function calLinesAngle(p1, p2, p3, p4) {
  var dAx = p2[0] - p1[0]
  var dAy = p2[1] - p1[1]
  var dBx = p4[0] - p3[0]
  var dBy = p4[1] - p3[1]
  var angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy)
  return Math.abs((angle * 180) / Math.PI)
}

export function updateDrawingCpLine(LAYOUT) {
  if (LAYOUT.boardMode == BoardMode.CP_LINE && LAYOUT.drawingObject) {
    LAYOUT.lineMod = !LAYOUT.lineMod
    layoutDrawObject(LAYOUT)
    LAYOUT.stage.render()
  }
}

//三个点在一条直线
export function collinear(x1, y1, x2, y2, x3, y3) {
  return (y1 - y2) * (x1 - x3) == (y1 - y3) * (x1 - x2)
}

//计算对象和鼠标相对位置
export function getMouseOffSet(obj, pos) {
  let result = []
  if (obj instanceof Kernel.CpLine) {
    let points_mouse_offset = []
    let points = obj.line.points
    const length = points.length
    for (let i = 0; i < length; i++) {
      let p = points[i]
      points_mouse_offset.push([pos[0] - p[0], pos[1] - p[1]])
    }
    result = points_mouse_offset
  } else {
    result = [pos[0] - obj.pos[0], pos[1] - obj.pos[1]]
  }
  return result
}

//更新对象和鼠标相对位置
export function updateMouseOffSet(objs, pos) {
  objs.forEach(obj => {
    if (obj instanceof Kernel.CpLine) {
      obj.js_obj.points_mouse_offset = []
      let points = obj.line.points
      const length = points.length
      obj.js_obj.originLength = length
      for (let i = 0; i < length; i++) {
        let p = points[i]
        obj.js_obj.points_mouse_offset.push([p[0] - pos[0], p[1] - pos[1]])
      }
      for (let j = 0; j < obj.labels.length; j++) {
        const label = obj.labels[j]
        if (!label.js_obj.checked) {
          updateMouseOffSet([label], pos)
        }
      }
    } else {
      obj.js_obj.mouse_offset = [obj.pos[0] - pos[0], obj.pos[1] - pos[1]]
    }
  })
}

//更新对象和鼠标相对位置
export function updateLinePointMouseOffSet(LAYOUT, pos) {
  let cpline = LAYOUT.stage.editCpLine
  let points = cpline.line.points
  let len = points.length
  let midPoints = cpline.line.js_obj.midPoints
  let range = 10 / LAYOUT.axis.scale

  let hits = []
  cpline.js_obj.originLength = len
  cpline.js_obj.slopes = getSlopes(points)
  LAYOUT.addPoint = null
  points.forEach((p, index) => {
    let dist = withinRange(pos, p, range)
    if (dist !== undefined) {
      hits.push({ type: 'P', point: p, dist: dist, index: index })
    }
  })
  midPoints.forEach((p, index) => {
    let dist = withinRange(pos, p, range)
    if (dist !== undefined) {
      hits.push({ type: 'M', point: p, dist: dist, index: index })
    }
  })
  cpline.js_obj.dragPointIndex = null
  cpline.js_obj.checked_points_index = []
  cpline.js_obj.headAndEndOffset = getHeadAndEndOffset(points)
  //选中点
  if (hits.length) {
    hits.sort((a, b) => a.dist - b.dist)

    let hit = hits[0]
    //判断拖动是首末两点
    if (isEqualPos(hit.point, points[0])) {
      cpline.js_obj.dragPointIndex = 0
    } else if (isEqualPos(hit.point, points.at(-1))) {
      cpline.js_obj.dragPointIndex = -1
    }
    let noOff = null //不移动的点

    if (hit.type === 'P') {
      //命中端点
      cpline.js_obj.points_mouse_offset = []
      for (let i = 0; i < len; i++) {
        let p = points[i]
        let off = noOff
        if (i == hit.index) {
          off = [p[0] - pos[0], p[1] - pos[1]]
        }
        cpline.js_obj.points_mouse_offset.push(off)
      }
    } else if (hit.type === 'M') {
      //命中中点 保持点信息至全局
      let p = hit.point
      hit.cpLine = cpline
      hit.offset = [p[0] - pos[0], p[1] - pos[1]]
      LAYOUT.addPoint = hit
    }
  } else {
    //未选中点 判断选中边
    let hitLine = getCheckedPointsIndex(points, pos, range)
    if (hitLine) {
      updateMouseOffSet([cpline], pos)
      cpline.js_obj.checked_points_index.push(hitLine.start, hitLine.end)
      cpline.js_obj.checked_points_mouse_offset = updatePointsMouseOffset(points, cpline.js_obj.checked_points_index, pos)
    }
  }
}

//判断鼠标是否命中选中物体
export function checkInsideCheckedObjs(LAYOUT) {
  //整体选择
  if (LAYOUT.boardMode === BoardMode.SELECT) {
    let hits = LAYOUT.quadTree.queryByAABB(LAYOUT.stage.mouse_aabb)
    let intersections = LAYOUT.checked_objs.filter(checked => hits.indexOf(checked) !== -1) //求交集
    const length = intersections.length
    if (length) {
      return true
    }
    // for (let i = 0; i < length; i++) {
    //   const obj = intersections[i]
    //   // obj.js_obj.checked_points_index = []
    //   if (checkHit(mouse_pos, obj)) {
    //     return true
    //   }
    // }
  }
  // else if (LAYOUT.boardMode === BoardMode.cpLine) {
  //   //部分选择
  //   for (let i = 0; i < length; i++) {
  //     const obj = this.checked_objs[i]
  //     // obj.js_obj.checked_points_index = []
  //     if (checkHitBeforePart(mouse_pos, obj)) {
  //       return true
  //     }
  //   }
  // }

  return false
}

export function noRepeatNear(arr) {
  let newPoints = []
  const hasObj = {}
  arr.forEach(pos => {
    const filterKey = JSON.stringify(pos[0]) + JSON.stringify(pos[1])
    if (!hasObj[filterKey]) {
      hasObj[filterKey] = true
      newPoints.push(pos)
    }
  })

  return newPoints
}

export function noRepeat(arr) {
  var newArr = []
  var myset = new Set(arr) //利用Set结构不能接收重复数据的特点
  for (var val of myset) {
    newArr.push(val)
  }
  return newArr
}

//深拷贝数据
export function deepCopyObjs(LAYOUT, objs) {
  let schemaTemp = new Kernel.Schema()
  // file.add_schema(schema)
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    if (obj instanceof Kernel.Label) {
      schemaTemp.add_label(obj)
    } else if (obj instanceof Kernel.Pin) {
      schemaTemp.add_pin(obj)
    } else if (obj instanceof Kernel.SymbolIns) {
      schemaTemp.add_symbol_ins(obj)
    } else if (obj instanceof Kernel.CpLine) {
      schemaTemp.add_cp_line(obj)
    }
  }

  //*****老方法*****//
  // let data = schemaTemp.dump_with_dep()
  // file.assemble(data)
  // let schemaCopy = null
  // for (let i = 0; i < file.schemas.length; i++) {
  //   const schema = file.schemas[i]
  //   if (schema.name == '') {
  //     schemaCopy = schema
  //     break
  //   }
  // }

  //*****新接口*****//
  // let copy = schemaTemp.deep_copy_logic()
  // let data = copy.save_project_file()
  // let schemaCopy = Kernel.parse_file(data)
  // schema.deep_copy_logic()
  // let res = getDataFromTempSchema(schemaCopy, LAYOUT.config)

  //****深拷贝数据*****/
  let schemaCopy
  let datas = schemaTemp.deep_copy_logic()

  for (let i = 0; i < datas.length; i++) {
    const data = datas[i]
    if (data.$$?.ptrType.name === 'Schema*' && data.name === '') {
      schemaCopy = data
      break
    }
  }

  renameSchemaNameCopy(schemaCopy.depend_symbol_schemas(-1), store.state.activeNode.parent)
  let res = getDataFromTempSchema(schemaCopy, LAYOUT.config)

  return res
}

//复制多个数据
export function copyObjs(objs) {
  let result = []
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    // if (obj.$$?.ptrType.name === 'Label*' && obj.belong_to) {
    if (obj.$$?.ptrType.name === 'Label*' && obj.belong_to && obj.belong_to.$$?.ptrType.name !== 'Schema') {
      continue
    }
    result.push(copy(obj))
  }
  return result
}

//复制数据
export function copy(obj, pos) {
  if (obj instanceof Kernel.Label) {
    let label = new Kernel.Label()
    label.anchor = Kernel.Anchor.Centra

    label.anchor = obj.anchor
    label.font = obj.font
    label.angle = obj.angle
    label.pos = pos ? pos : [obj.pos[0], obj.pos[1]]
    label.size = obj.size
    label.text = obj.text
    label.color = obj.color
    return label
  } else if (obj instanceof Kernel.Pin) {
    let pinIns = obj.copy() //obj.deepcopy() //new Kernel.pin()
    pinIns.js_obj = {}
    pinIns.pos = [obj.pos[0], obj.pos[1]]
    pinIns.angle = obj.angle
    pinIns.io = obj.io
    pinIns.name = copy(obj.name)
    pinIns.name.belong_to = pinIns
    if (pos) {
      pinIns.pos = pos
    }

    return pinIns
  } else if (obj instanceof Kernel.SymbolIns) {
    let symbolIns
    // if (obj.symbol) {
    symbolIns = obj.copy() //obj.deepcopy() //new Kernel.SymbolIns(obj.symbol)
    symbolIns.js_obj = {}
    // } else {
    //   symbolIns = obj.deepcopy_with_ref()
    //   symbolIns.symbol = null
    //   symbolIns.js_obj = {}
    // }
    symbolIns.ins_name = obj.ins_name
    symbolIns.angle = obj.angle
    symbolIns.pos = pos ? pos : obj.pos

    return symbolIns
  } else if (obj instanceof Kernel.CpLine) {
    // let cpLine = obj.copy()
    let line = new Kernel.Line()
    let points = pos ? pos : deepClone(obj.line.points)
    line.swap(points)
    let cpLine = new Kernel.CpLine()
    cpLine.line = line
    cpLine.color = obj.color
    cpLine.net_name = obj.net_name
    for (let j = 0; j < obj.labels.length; j++) {
      const newlabel = copy(obj.labels[j])
      // newlabel.belong_to = cpLine
      // cpLine.labels.add(newlabel)
      cpLine.add_label(newlabel)
    }
    return cpLine
  }
}

//根据鼠标位置移动整个物体
export function movePosByMouse(LAYOUT, obj, pos) {
  let connect_pos = LAYOUT.connectionTarget?.port?.pos //吸附端口
  if (obj instanceof Kernel.CpLine) {
    //判断在编辑线的模式
    let editLine = LAYOUT.stage.editCpLine
    let adsorp = LAYOUT.connectionTarget

    let line = obj.line
    let points = obj.line.points
    let len = points.length
    let checked_points_index = editLine?.js_obj.checked_points_index
    //查询线的两个端口

    let ports = [null, null]
    let query_ports = LAYOUT.schema.query_cp_line(obj)
    let ports_length = 0
    for (let i = 0; i < query_ports.length; i++) {
      const port = query_ports[i][0]

      if (port.belong_to?.js_obj.checked) continue
      ports_length++
      const pos = port.pos
      if (query_ports[i][1] == Kernel.HeadTail.Head) {
        ports[0] = pos
      } else if (query_ports[i][1] == Kernel.HeadTail.Tail) {
        ports[1] = pos
      }
    }

    let port1_pos = ports[0]
    let port2_pos = ports[1]
    //拖动已经连接端口的点
    if (LAYOUT.stage.editCpLine) {
      //点击移动---整体移动---移动只有一条边的线 相当于整体移动
      let moveAll = LAYOUT.boardMode == BoardMode.MOVE || !LAYOUT.stage.editCpLine || (LAYOUT.stage.editCpLine && checked_points_index?.length === obj.js_obj.originLength)
      //整体移动自动增加两个点
      if (moveAll) {
        if (query_ports.length) {
          //已经添加点
          if (len === obj.js_obj.originLength + query_ports.length) {
            //两边端点坐标不变
            if (port1_pos && port2_pos) {
              for (let i = 1; i < points.length - 1; i++) {
                let offset = obj.js_obj.points_mouse_offset[i - 1]
                if (offset) {
                  points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
                }
              }
            } else if (port1_pos) {
              for (let i = 1; i < points.length; i++) {
                let offset = obj.js_obj.points_mouse_offset[i - 1]
                if (offset) {
                  points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
                }
              }
            } else if (port2_pos) {
              for (let i = 0; i < points.length - 1; i++) {
                let offset = obj.js_obj.points_mouse_offset[i]
                if (offset) {
                  points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
                }
              }
            }
          } else {
            //未添加点
            for (let i = 0; i < points.length; i++) {
              let offset = obj.js_obj.points_mouse_offset[i]
              if (offset) {
                points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
              }
            }
            if (port1_pos) {
              points.unshift(port1_pos)
            }

            if (port2_pos) {
              points.push(port2_pos)
            }
          }
        } else {
          for (let i = 0; i < points.length; i++) {
            let offset = obj.js_obj.points_mouse_offset[i]
            if (offset) {
              points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
            }
          }
        }
      } else {
        //拖动一条边
        if (checked_points_index.length) {
          let origin_last_index = obj.js_obj.originLength - 1 //len - 1
          let originPoints = points //添加端口前的点坐标
          let dragHead = checked_points_index.indexOf(0) !== -1 //拖拽第一条边
          let dragEnd = checked_points_index.indexOf(obj.js_obj.originLength - 1) !== -1 // 拖拽第末尾条边
          //拖动第一条连接端口的边
          if (dragHead || dragEnd) {
            if (len === obj.js_obj.originLength + 1) {
              originPoints = []
              points.forEach(p => originPoints.push(p))
              //已经添加点 删除收尾项
              if (dragHead && port1_pos) {
                originPoints.shift()
              } else if (dragEnd && port2_pos) {
                originPoints.pop()
              }
            }
          }

          //移动所有选中点
          for (let i = 0; i < obj.js_obj.checked_points_index.length; i++) {
            const index = obj.js_obj.checked_points_index[i]
            const p = originPoints[index]
            const off_set = obj.js_obj.checked_points_mouse_offset[i]
            p[0] = pos[0] + off_set[0]
            p[1] = pos[1] + off_set[1]
          }
          moveLine(originPoints, obj.js_obj.slopes, origin_last_index, [checked_points_index[0], checked_points_index[1]])

          //拖动收尾线段 保持线首尾点相对位置不变
          if (dragHead) {
            let headOff = obj.js_obj.headAndEndOffset.head
            if (headOff) {
              originPoints[0] = [originPoints[1][0] + headOff[0], originPoints[1][1] + headOff[1]]
            }
          }
          if (dragEnd) {
            let endOff = obj.js_obj.headAndEndOffset.end
            if (endOff) {
              originPoints[origin_last_index] = [originPoints.at(-2)[0] + endOff[0], originPoints.at(-2)[1] + endOff[1]]
            }
          }
          if (dragHead || dragEnd) {
            if (len == obj.js_obj.originLength + 1) {
              //已添加 连接首尾项
              if (dragHead && port1_pos) {
                points = [port1_pos].concat(originPoints)
              } else if (dragEnd && port2_pos) {
                points = originPoints.concat([port2_pos])
              }
            } else {
              if (dragHead && port1_pos) {
                points.unshift(port1_pos) //添加首点
              } else if (dragEnd && port2_pos) {
                points.push(port2_pos) //添加尾点
              }
            }
          }

          // obj.line.points = points
        } else {
          //拖动一个点
          if (obj.js_obj.dragPointIndex !== null) {
            let head = obj.js_obj.dragPointIndex == 0
            let last = obj.js_obj.dragPointIndex == -1
            //只拖动一个端点
            if (len === obj.js_obj.originLength + 1) {
              //一端点连接点坐标不变
              if (head) {
                for (let i = 1; i < points.length; i++) {
                  let offset = obj.js_obj.points_mouse_offset[i - 1]
                  if (offset) {
                    points[i] = connect_pos ? connect_pos : [offset[0] + pos[0], offset[1] + pos[1]]
                  }
                }
              } else if (last) {
                for (let i = 0; i < points.length; i++) {
                  let offset = obj.js_obj.points_mouse_offset[i]
                  if (offset) {
                    points[i] = connect_pos ? connect_pos : [offset[0] + pos[0], offset[1] + pos[1]]
                  }
                }
              }
            } else {
              //未添加连接点
              for (let i = 0; i < points.length; i++) {
                let offset = obj.js_obj.points_mouse_offset[i]
                if (offset) {
                  points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
                }
              }
              if (head && port1_pos) {
                points.unshift(port1_pos) //拖动首点
              } else if (last && port2_pos) {
                points.push(port2_pos) //拖动尾点
              }
            }
          } else {
            for (let i = 0; i < points.length; i++) {
              let offset = obj.js_obj.points_mouse_offset[i]
              if (offset) {
                points[i] = connect_pos ? connect_pos : [offset[0] + pos[0], offset[1] + pos[1]]
              }
            }
          }
        }
      }
    } else {
      //整体移动
      //已经添加点

      if (ports_length) {
        if (len === obj.js_obj.originLength + ports_length) {
          //两边端点坐标不变
          if (port1_pos && port2_pos) {
            for (let i = 1; i < points.length - 1; i++) {
              let offset = obj.js_obj.points_mouse_offset[i - 1]
              if (offset) {
                points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
              }
            }
          } else if (port1_pos) {
            for (let i = 1; i < points.length; i++) {
              let offset = obj.js_obj.points_mouse_offset[i - 1]
              if (offset) {
                points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
              }
            }
          } else if (port2_pos) {
            for (let i = 0; i < points.length - 1; i++) {
              let offset = obj.js_obj.points_mouse_offset[i]
              if (offset) {
                points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
              }
            }
          }
        } else {
          //未添加点
          for (let i = 0; i < points.length; i++) {
            let offset = obj.js_obj.points_mouse_offset[i]
            if (offset) {
              points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
            }
          }
          if (port1_pos) {
            points.unshift(port1_pos)
          }

          if (port2_pos) {
            points.push(port2_pos)
          }
        }
      } else {
        for (let i = 0; i < points.length; i++) {
          let offset = obj.js_obj.points_mouse_offset[i]
          if (offset) {
            if (editLine && adsorp) {
              points[i] = pos
            } else {
              points[i] = [offset[0] + pos[0], offset[1] + pos[1]]
            }
          }
        }
      }
    }

    line.swap(points)
  } else {
    obj.pos = [obj.js_obj.mouse_offset[0] + pos[0], obj.js_obj.mouse_offset[1] + pos[1]]
  }
}
//移动物体
export function moveObj(LAYOUT, obj, pos) {
  movePosByMouse(LAYOUT, obj, pos)
  if (obj instanceof Kernel.Label) {
    buildLabelBox(obj, LAYOUT.config)
  } else if (obj instanceof Kernel.Pin) {
    buildPinPath(obj, LAYOUT.config)
  } else if (obj instanceof Kernel.SymbolIns) {
    buildSymbolPath(obj)
  } else if (obj instanceof Kernel.CpLine) {
    buildCpLinePath(obj)
  }
  LAYOUT.quadTree.updateNode(obj)
}

export function hitObj(rect, obj) {
  if (obj instanceof Kernel.Label) {
    return insertPolygon(rect, obj.js_obj.box.get_points())
  } else if (obj instanceof Kernel.Pin) {
    return insertCombineObj(rect, obj)
  } else if (obj instanceof Kernel.SymbolIns) {
    return insertCombineObj(rect, obj)
  } else if (obj instanceof Kernel.CpLine) {
    if (insertLine(rect, obj.line.points)) {
      return true
    }
  }
  return false
}

export function insertCombineObj(rect, obj) {
  for (let j = 0; j < obj.lines.length; j++) {
    const line = obj.lines[j]
    if (insertLine(rect, line.points)) {
      return true
    }
  }
  for (let k = 0; k < obj.polygons.length; k++) {
    const polygon = obj.polygons[k]
    if (insertPolygon(rect, polygon.points)) {
      return true
    }
  }
  return false
}
export function insertLine(rect, points) {
  if (!points.length) return false
  let path = new QGdstk.FlexPath(points, 0.1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 0, 0)
  if (new QGdstk.boolean([rect], [path], 'and').length) {
    return true
  }
}

export function insertPolygon(rect, points) {
  let temp = new QGdstk.Polygon(points)
  if (new QGdstk.boolean([rect], [temp], 'and').length) {
    return true
  }
}

//包围盒中心点
export function aabbCenter(aabb) {
  const start_x = aabb[0][0]
  const start_y = aabb[0][1]
  let w = aabb[1][0] - start_x
  let h = aabb[1][1] - start_y
  return [start_x + w / 2, start_y + h / 2]
}

//获取附近最近的port
export function getNearPortPos(LAYOUT, mouse, aabb) {
  let expand = 5
  aabb = deepClone(aabb)
  aabb[0][0] -= expand
  aabb[0][1] -= expand
  aabb[1][0] += expand
  aabb[1][1] += expand
  let res = LAYOUT.quadTree.querySymbols(aabb)
  let range = 5
  let hits = []
  for (let i = 0; i < res.length; i++) {
    const symbolIns = res[i]
    for (let j = 0; j < symbolIns.ports.length; j++) {
      const port = symbolIns.ports[j]
      let dist = withinRange(mouse, port.pos, range)
      if (dist !== undefined) {
        hits.push({ target: symbolIns, port: port, dist: dist })
      }
    }
  }
  if (hits.length) {
    hits.sort((a, b) => a.dist - b.dist)
    return hits[0]
  }
}

//获取附近最近的拐点
export function getNearPoint(LAYOUT, mouse) {
  let schema = LAYOUT.schema
  let editLine = LAYOUT.stage.editCpLine
  let drawingLine = LAYOUT.drawingObject
  let hits = []
  let hits_line = []
  let cpLines = LAYOUT.quadTree.queryCpLines(LAYOUT.stage.mouse_aabb) //schema.cp_lines

  let range = 5 // / LAYOUT.axis.scale
  for (let i = 0; i < cpLines.length; i++) {
    let cpline = cpLines[i]
    if (cpline.$$.ptr !== editLine?.$$.ptr && cpline.$$.ptr !== drawingLine?.$$.ptr) {
      const points = cpline.line.points
      const len = points.length
      const last = len - 1
      for (let j = 0; j < len; j++) {
        let point = points[j]
        let dist = withinRange(mouse, point, range)
        if (dist !== undefined) {
          hits.push({ target: cpline, port: null, dist: dist, point: point })
        }
        if (j !== last) {
          let next_point = points[j + 1]
          if (inner(point[0], point[1], next_point[0], next_point[1], mouse[0], mouse[1])) {
            hits_line.push({ target: cpline, port: null, dist: 0, point: mouse })
          }
        }
      }
    }
  }

  if (hits.length) {
    hits.sort((a, b) => a.dist - b.dist)

    return hits[0]
  }
  if (hits_line.length) {
    hits_line.sort((a, b) => a.dist - b.dist)
    return hits_line[0]
  }
}

function withinRange(p1, p2, range) {
  let x = p1[0] - p2[0]
  let y = p1[1] - p2[1]
  let dist = Math.sqrt(x * x + y * y)
  if (dist < range) {
    return dist
  }
}

//截断耦合线
function breakLine(LAYOUT, cpLine) {
  let quadTree = LAYOUT.quadTree
  let line_points = cpLine.line.points

  let aabb = deepClone(cpLine.bounding_box)
  let color = cpLine.color
  let expand = 5
  aabb[0][0] -= expand
  aabb[0][1] -= expand
  aabb[1][0] += expand
  aabb[1][1] += expand
  //获取命中的符号 PIN
  let hits = quadTree.queryByAABB(aabb).filter(hit => {
    return hit instanceof Kernel.Pin || hit instanceof Kernel.SymbolIns
  })
  let ports = []

  hits.forEach(hit => {
    for (let i = 0; i < hit.ports.length; i++) {
      const p = hit.ports[i]
      ports.push({ target: hit, port: p })
    }
  })

  let breakLines = []
  let beforePos = []
  let cut = false //存在截断点
  let line_len = line_points.length
  let end = line_points.length - 1
  let beforePort
  let beforeTarget

  // line_points[0] = fixedPos(line_points[0])
  // line_points[end] = fixedPos(line_points[end])
  for (let i = 0; i < line_len - 1; i++) {
    let inLinePoints = []
    const p1 = line_points[i]
    const p2 = line_points[i + 1]
    //计算在线段上的port
    for (let j = 0; j < ports.length; j++) {
      let port = ports[j]
      const port_pos = port.port.pos

      //判断点在线段内
      if (inner(p1[0], p1[1], p2[0], p2[1], port_pos[0], port_pos[1], false)) {
        //计算点和线段第一个点距离
        let x = p1[0] - port_pos[0]
        let y = p1[1] - port_pos[1]
        // x = x.toFixed(2)
        // y = y.toFixed(2)
        let dist = Math.sqrt(x * x + y * y)
        inLinePoints.push({ ...port, dist })
      }
    }
    //根据和第一个点距离上的点排序
    inLinePoints.sort((a, b) => a.dist - b.dist)
    let len = inLinePoints.length

    if (len) {
      cut = true
      // let last = len - 1
      // let last_pos = inLinePoints[last].port.pos
      for (let k = 0; k < inLinePoints.length; k++) {
        const ilp = inLinePoints[k]
        const pos = ilp.port.pos
        let line = new Kernel.Line()
        let cpLine = new Kernel.CpLine()
        cpLine.color = color
        cpLine.line = line
        if (k == 0) {
          //第一段
          line.swap(noRepeatNear(beforePos.concat([p1, pos])))
          beforePos = [pos]
        } else {
          //中间段
          let before = beforePos[0]
          let after = inLinePoints[k].port.pos
          line.swap(noRepeatNear([before, after]))
          LAYOUT.schema.connect(beforePort, cpLine)
          // before = [after]
          beforePos = [after]
        }
        //连接端口
        LAYOUT.schema.connect(ilp.port, cpLine)
        if (beforePort) {
          LAYOUT.schema.connect(beforePort, cpLine)
        }
        breakLines.push(cpLine)
        beforePort = ilp.port
        beforeTarget = ilp.target
      }
    } else {
      beforePos.push(p1, p2)
    }
  }
  if (cut) {
    //末尾段
    if (beforePos.length) {
      let line = new Kernel.Line()
      let cpLine = new Kernel.CpLine()
      cpLine.line = line
      cpLine.color = color
      line.swap(noRepeatNear(beforePos.concat([line_points.at(-1)])))
      LAYOUT.schema.connect(beforePort, cpLine)
      breakLines.push(cpLine)
    }
  } else {
    //不存在截断点 返回无连接点的原线
    let line = new Kernel.Line()
    let points = deepClone(cpLine.line.points)
    line.swap(points)
    let newCpLine = new Kernel.CpLine()
    newCpLine.line = line
    newCpLine.color = cpLine.color
    newCpLine.net_name = cpLine.net_name
    return [newCpLine]
  }

  breakLines = breakLines.filter(cpLine => cpLine.line.points.length > 1)
  // if (breakLines.length > 1) {

  breakLines.forEach(cpLine => buildCpLinePath(cpLine))
  if (breakLines.length == 1) {
    return breakLines //返回原来的线
  } else if (breakLines.length == 0) {
    return []
  } else {
    return breakLines
  }
}

//两个点相同
function isEqualPos(p1, p2) {
  return p1[0] == p2[0] && p1[1] == p2[1]
}

//点在直线内
function inner(x1, y1, x2, y2, x3, y3, fixed = false) {
  if (fixed) {
    x1 = x1.toFixed(2)
    x2 = x2.toFixed(2)
    x3 = x3.toFixed(2)
    y1 = y1.toFixed(2)
    y2 = y2.toFixed(2)
    y3 = y3.toFixed(2)
  }

  let minx
  let maxx
  let miny
  let maxy
  if (x1 < x2) {
    minx = x1
    maxx = x2
  } else {
    minx = x2
    maxx = x1
  }

  if (y1 < y2) {
    miny = y1
    maxy = y2
  } else {
    miny = y2
    maxy = y1
  }
  return (y1 - y2) * (x1 - x3) == (y1 - y3) * (x1 - x2) && x3 >= minx && x3 <= maxx && y3 >= miny && y3 <= maxy
}

/*****************自动连接线*******************/
export function autoConnectPort(LAYOUT, editLineCtEdgePort) {
  let checkeds = LAYOUT.checked_objs
  let cpLines = LAYOUT.schema.cp_lines
  let adds = []
  let deletes = []
  let propertyUpdates = []

  // let hits = quadTree.queryByAABB(aabb).filter(hit => {
  //   return hit instanceof Kernel.Pin || hit instanceof Kernel.SymbolIns
  // })
  recordCplineConnectPorts(cpLines, LAYOUT.schema)
  for (let i = 0; i < cpLines.length; i++) {
    const cpL = cpLines[i]
    let beforePorts = LAYOUT.schema.query_cp_line(cpL)
    let beforeLabels = cpL.labels
    let before_net_name = cpL.net_name

    let result = breakLine(LAYOUT, cpL)

    result.forEach(cpl => {
      if (cpl.net_name == '') {
        cpl.net_name = before_net_name
      }
    })
    //线发生改变=>存在被分解 或 端口发生改变
    if (result.length > 1 || (result.length == 1 && portsChange(beforePorts, LAYOUT.schema.query_cp_line(result[0])))) {
      // LAYOUT.clearCheckedObjs()
      LAYOUT.clearCheckedObj(cpL)
      if (result.length == 1 && editLineCtEdgePort) {
        result[0].js_obj.checked = true
        LAYOUT.checked_objs = result
      }
      let addLabels = addLabelsToCplines(beforeLabels, result)

      adds.push(...result)
      adds.push(...addLabels)
      //删除发生变化的线和网络标签
      deletes.push(cpL)
      for (let i = 0; i < cpL.labels.length; i++) {
        deletes.push(cpL.labels[i])
      }
      let now_net_name = result[0].net_name
      //判断器件与器件相连 不做重命名
      if (now_net_name !== '') {
        let res = reNameCplNetName(LAYOUT, before_net_name, now_net_name)
        propertyUpdates.push(...res)
      }
    }
  }
  if (adds.length) {
    adds.forEach(obj => LAYOUT.add(obj))
    deletes.forEach(obj => LAYOUT.delete(obj))
    let from = []
    let to = []
    propertyUpdates.forEach(obj => {
      const nowProperty = {}
      if (obj.$$?.ptrType.name == 'CpLine*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
      } else if (obj.$$?.ptrType.name == 'Label*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
        nowProperty.anchor = obj.anchor
        nowProperty.size = obj.size
        nowProperty.font = obj.font
        nowProperty.text = obj.text
      }
      from.push(nowProperty)
      to.push(null)
    })
    let lastRecords = LAYOUT.editHistory.undoStack.at(-1)
    // lastRecords.forEach(item => {
    //   if (item.action == 'add') {
    //     for (let i = 0; i < item.objs.length; i++) {
    //       const obj = item.objs[i]
    //       for (let j = 0; j < deletes.length; j++) {
    //         const target = deletes[j]
    //         if (obj.$$.ptr == target.$$.ptr) {
    //           alert(1)
    //           deletes.splice(j--, 1)
    //         }
    //       }
    //     }
    //   }
    // })

    // for (let j = 0; j < deletes.length; j++) {
    //   const target = deletes[j]
    //   lastRecords.forEach(item => {
    //     if (item.action == 'add') {
    //       for (let i = 0; i < item.objs.length; i++) {
    //         const obj = item.objs[i]
    //         if (obj.$$.ptr == target.$$.ptr) {
    //           deletes.splice(j--, 1)
    //         }
    //       }
    //     }
    //   })
    // }
    if (lastRecords) {
      LAYOUT.editHistory.undoStack.at(-1).push({ action: 'add', schema: LAYOUT.schema, objs: adds }, { action: 'delete', schema: LAYOUT.schema, objs: deletes }, { action: 'property', schema: LAYOUT.schema, objs: propertyUpdates, from: from, to: to })
    }
  }
}

export function getMidPoints(points) {
  let res = []
  for (let i = 0; i < points.length - 1; i++) {
    let p1 = points[i]
    let p2 = points[i + 1]
    let mid_x = (p1[0] + p2[0]) / 2
    let mid_y = (p1[1] + p2[1]) / 2
    res.push([mid_x, mid_y])
  }
  return res
}

//获取边斜率
function getSlopes(points) {
  let sp = points
  let slopes = []
  const length = sp.length
  if (!length) return
  for (let i = 0; i < length - 1; i++) {
    slopes.push(getSlope(sp[i], sp[i + 1]))
  }
  return slopes
}

//直线斜率 0 平行于x轴 null 垂直于y轴
function getSlope(p1, p2) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  const delta_x = x2 - x1
  return delta_x !== 0 ? (y2 - y1) / delta_x : null
}

function getCheckedPointsIndex(points, mouse_point, range) {
  let res = []
  for (let i = 0; i < points.length - 1; i++) {
    let start = points[i]
    let end = points[i + 1]
    let dist = distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point[0], mouse_point[1])
    if (dist <= range) {
      res.push({
        start: i,
        end: i + 1,
        dist,
      })
    }
  }
  if (res.length) {
    res.sort((a, b) => a.dist - b.dist)
    return res[0]
  }
}

//选择点和鼠标之间的偏移量
function updatePointsMouseOffset(points, checked_points_index, mouse_position) {
  let sp = points
  let checked_points_mouse_offset = []
  const length = checked_points_index.length
  for (let i = 0; i < length; i++) {
    const index = checked_points_index[i]
    const p = sp[index]
    checked_points_mouse_offset.push([p[0] - mouse_position[0], p[1] - mouse_position[1]])
  }
  return checked_points_mouse_offset
}

//移动线的的一条边
function moveLine(points, slopes, total_last_index, line_indexs) {
  const length = line_indexs.length
  const total = total_last_index
  // const end = length-1

  // const order_index = getOrderPoints(line_indexs, length, total)
  let head_index = line_indexs[0]
  let last_index = line_indexs.at(-1) //line_indexs[end]

  //判断收尾顺序
  const head = points[head_index]
  const last = points[last_index]

  const before_index = getAroundIndex(head_index, total, 3, 1)
  const after_index = getAroundIndex(last_index, total, 3, 1)

  //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
  const a = points[before_index[0]]
  const b = points[before_index[1]]
  const c = points[after_index[0]]
  const d = points[after_index[1]]

  const line_a_head = slopeAndIntercept(a, head, slopes[before_index[0]])
  let line_head_b
  if (length == 2) {
    line_head_b = slopeAndIntercept(head, b, slopes[head_index])
  } else {
    line_head_b = slopeAndIntercept(b, head, slopes[head_index])
  }

  const line_c_last = slopeAndIntercept(c, last, slopes[after_index[0]])
  const line_last_d = slopeAndIntercept(d, last, slopes[last_index])

  let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
  let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
  if (!isValidPos(p1) || !isValidPos(p2)) {
    return
  }
  points[head_index] = p1
  points[last_index] = p2
}

//计算首位线段偏移量，保证拖动时首尾端线长不变 a-b-...-c-d
function getHeadAndEndOffset(points) {
  let len = points.length
  let last = len - 1
  let head = []
  let end = []
  let a = points[0]
  let b = points[1]
  let c = points[last - 1]
  let d = points[last]
  head = [a[0] - b[0], a[1] - b[1]]
  if (len > 2) {
    end = [d[0] - c[0], d[1] - c[1]]
    return { head, end }
  }
  return {}
}

//移动符号后自动更新连接线连接点坐标
export function updateLinePointsByPorts(LAYOUT, modifyObjs, schema) {
  let cpLines = modifyObjs.filter(obj => obj instanceof Kernel.CpLine)
  let symbols = modifyObjs.filter(obj => obj instanceof Kernel.SymbolIns || obj instanceof Kernel.Pin)

  symbols.forEach(symbol => {
    for (let i = 0; i < symbol.ports.length; i++) {
      let port = symbol.ports[i]
      let res = schema.query_port(port)

      for (let j = 0; j < res.length; j++) {
        let cpl = res[j][0]
        let ht = res[j][1]
        if (!cpLines.filter(obj => obj.$$.ptr == cpl.$$.ptr).length) {
          // if (cpl.js_obj.checked) continue

          let points = cpl.line.points
          //根据距离判断首尾
          if (ht == Kernel.HeadTail.Head) {
            points[0] = port.pos
          } else if (ht == Kernel.HeadTail.Tail) {
            points[points.length - 1] = port.pos
          }
          cpl.line.swap(points)
          buildCpLinePath(cpl)
          LAYOUT.quadTree.updateNode(cpl)
        }
      }
    }
  })
}

function pointsDist(p1, p2) {
  let x = p1[0] - p2[0]
  let y = p1[1] - p2[1]
  return Math.sqrt(x * x + y * y)
}

function portsChange(ports1, ports2) {
  let len1 = ports1.length
  let len2 = ports2.length

  if (len1 == len2) {
    if (len1 == 0) return false
    if (len1 == 1) {
      if (ports1[0][0].$$.ptr === ports2[0][0].$$.ptr && ports1[0][1] === ports2[0][1]) {
        return false
      }
    }
    if (len1 == 2) {
      if (ports1[0][0].$$.ptr === ports2[0][0].$$.ptr && ports1[0][1] === ports2[0][1] && ports1[1][0].$$.ptr === ports2[1][0].$$.ptr && ports1[1][1] === ports2[1][1]) {
        return false
      }
      if (ports1[0][0].$$.ptr === ports2[1][0].$$.ptr && ports1[0][1] === ports2[1][1] && ports1[1][0].$$.ptr === ports2[0][0].$$.ptr && ports1[1][1] === ports2[0][1]) {
        return false
      }

      //拖拽前端口重叠可能无法判断首尾，首尾可能一致
      if (ports1[0][0].$$.ptr === ports2[0][0].$$.ptr && ports1[1][0].$$.ptr === ports2[1][0].$$.ptr && ports1[0][1] == ports1[1][1]) {
        return false
      }
      if (ports1[0][0].$$.ptr === ports2[1][0].$$.ptr && ports1[1][0].$$.ptr === ports2[0][0].$$.ptr && ports1[0][1] == ports1[1][1]) {
        return false
      }
    }
  }
  return true
}

function removeRepeatNear(points) {
  let result = [points[0]]
  let before = points[0]
  points.forEach(pos => {
    if (pos[0] != before[0] || pos[1] != before[1]) {
      result.push(pos)
    }
    before = pos
  })
  return result
}
//"""简化曲线，去除平行点位"""
export function curve_del_parallel(curve) {
  curve = removeRepeatNear(curve)
  let new_curve = [curve[0]]
  for (let idx = 0; idx <= curve.length - 3; idx++) {
    let a = curve[idx]
    let b = curve[idx + 1]
    let c = curve[idx + 2]
    let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
    let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])
    let err = Math.abs(ag_a_b - ag_b_c)
    if (err == 0) {
      continue
    } else {
      new_curve.push(b)
    }
  }
  new_curve.push(curve[curve.length - 1])
  return new_curve
}

export function getBoxCenter(box) {
  if (!box) {
    return [0, 0]
  }
  let minX = box[0][0]
  let minY = box[0][1]
  let maxX = box[1][0]
  let maxY = box[1][1]
  return [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2]
}

export function minDistLineCenter(p, points) {
  let dists = []
  for (let i = 0; i < points.length - 1; i++) {
    let start = points[i]
    let end = points[i + 1]
    let res = distToLine(start[0], start[1], end[0], end[1], p[0], p[1])
    let dist = res.dist
    dists.push({
      start: i,
      end: i + 1,
      dist,
      cross: res.cross,
    })
  }
  dists.sort((a, b) => a.dist - b.dist)
  return dists[0].cross
}

function distToLine(lx1, ly1, lx2, ly2, px, py) {
  var ldx = lx2 - lx1
  var ldy = ly2 - ly1
  var lineLengthSquared = ldx * ldx + ldy * ldy
  var t // t===0 at line pt 1 and t ===1 at line pt 2
  if (!lineLengthSquared) {
    // 0-length line segment. Any t will return same result
    t = 0
  } else {
    t = ((px - lx1) * ldx + (py - ly1) * ldy) / lineLengthSquared

    if (t < 0) t = 0
    else if (t > 1) t = 1
  }

  var lx = lx1 + t * ldx,
    ly = ly1 + t * ldy,
    dx = px - lx,
    dy = py - ly
  return { dist: Math.sqrt(dx * dx + dy * dy), cross: [lx, ly] }
}

//将网络标签添加到最近的分解线
function addLabelsToCplines(labels, cpls) {
  let addNetLabels = []
  for (let i = 0; i < labels.length; i++) {
    const label = labels[i]
    const center = label.js_obj.center
    const allMinDist = []
    cpls.forEach(cpl => {
      let dists = []
      let points = cpl.line.points
      for (let i = 0; i < points.length - 1; i++) {
        let start = points[i]
        let end = points[i + 1]
        let dist = distToLine(start[0], start[1], end[0], end[1], center[0], center[1]).dist
        dists.push(dist)
      }
      dists.sort((a, b) => a - b)
      allMinDist.push({ dist: dists[0], cpLine: cpl })
    })
    allMinDist.sort((a, b) => a.dist - b.dist)
    let targetCpl = allMinDist[0].cpLine
    let newLabel = copy(label)
    newLabel.text = targetCpl.net_name
    // targetCpl.labels.add(newLabel)
    // newLabel.belong_to = targetCpl
    targetCpl.add_label(newLabel)
    addNetLabels.push(newLabel)
  }
  return addNetLabels
}

function reNameCplNetLabels(LAYOUT, connectCpl, net_name) {
  //重命名其他网络标签
  for (let i = 0; i < connectCpl.labels.length; i++) {
    const label = connectCpl.labels[i]
    label.text = connectCpl.net_name
    buildLabelBox(label, LAYOUT.config)
    LAYOUT.quadTree.updateNode(label)
  }

  let cp_lines_size = LAYOUT.schema.cp_lines.length
  let cp_lines = LAYOUT.schema.cp_lines
  for (let i = 0; i < cp_lines_size; i++) {
    const cp_line = cp_lines[i]
    if (cp_line.net_name === net_name) {
      cp_line.net_name = connectCpl.net_name
      for (let i = 0; i < cp_line.labels.length; i++) {
        const label = cp_line.labels[i]
        label.text = cp_line.net_name
        buildLabelBox(label, LAYOUT.config)
        LAYOUT.quadTree.updateNode(label)
      }
    }
  }
}

function reNameCplNetName(LAYOUT, before_net_name, now_net_name) {
  let res = []
  let cp_lines_size = LAYOUT.schema.cp_lines.length
  let cp_lines = LAYOUT.schema.cp_lines
  for (let i = 0; i < cp_lines_size; i++) {
    const cp_line = cp_lines[i]
    if (cp_line.net_name === before_net_name) {
      res.push(cp_line)
      setTimeout(() => (cp_line.net_name = now_net_name))

      for (let i = 0; i < cp_line.labels.length; i++) {
        const label = cp_line.labels[i]
        res.push(label)
        setTimeout(() => {
          label.text = cp_line.net_name
          buildLabelBox(label, LAYOUT.config)
          LAYOUT.quadTree.updateNode(label)
          LAYOUT.stage.render()
        })
      }
    }
  }
  return res
}

export function autoAddCplinePortPoints(LAYOUT, cpls) {
  cpls.forEach(cpl => {
    //查询两个端口
    let ports = [null, null]
    let query_ports = LAYOUT.schema.query_cp_line(cpl)
    for (let i = 0; i < query_ports.length; i++) {
      const port = query_ports[i][0]
      const pos = port.pos
      if (query_ports[i][1] == Kernel.HeadTail.Head) {
        ports[0] = pos
      } else if (query_ports[i][1] == Kernel.HeadTail.Tail) {
        ports[1] = pos
      }
    }
    let line = cpl.line
    let points = line.points
    let head = ports[0]
    let end = ports[1]
    if (head && !isEqualPos(points[0], head)) {
      points.unshift(head)
    }
    if (end && !isEqualPos(points[points.length - 1], end)) {
      points.push(end)
    }
    line.swap(points) //noRepeatNear(points)
    line.js_obj.midPoints = getMidPoints(cpl)
    buildCpLinePath(cpl)
    LAYOUT.quadTree.updateNode(cpl)
  })
}

export function mirrorObj(obj, params) {
  // let offCenter = [0, 0]
  // if (obj.$$?.ptrType.name == 'Label*') {
  //   let center = obj.js_obj.center
  //   offCenter = [obj.pos[0] - center[0], obj.pos[1] - center[1]]
  //   obj.pos = center
  // }
  obj.mirror(params[0], params[1])
  // obj.pos = [obj.pos[0] + offCenter[0], obj.pos[1] + offCenter[1]]
}

export function updateLableValue(LAYOUT, label, newText) {
  if (newText === '' || !/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(newText)) {
    return
  }
  let records = []
  let from = []
  let to = []
  let parent = label.belong_to
  let text = label.text
  if (parent && parent.$$?.ptrType.name == 'CpLine*') {
    //网络标签

    let size = LAYOUT.schema.cp_lines.length
    let cpls = LAYOUT.schema.cp_lines
    for (let i = 0; i < size; i++) {
      let cpl = cpls[i]

      if (cpl.net_name == text) {
        records.push(cpl)
        from.push(cpl.net_name)
        to.push(newText)
        cpl.net_name = newText
        for (let i = 0; i < cpl.labels.length; i++) {
          const netLabel = cpl.labels[i]
          records.push(netLabel)
          from.push(netLabel.text)
          to.push(newText)
          netLabel.text = newText
          buildLabelBox(netLabel, LAYOUT.stage.config)
          LAYOUT.quadTree.updateNode(netLabel)
        }
      }
    }
  } else if (parent && parent.$$?.ptrType.name == 'Pin*') {
    //引脚标签
    records.push(label)
    from.push(label.text)
    to.push(newText)
    label.text = newText
    buildLabelBox(label, LAYOUT.stage.config)
    LAYOUT.quadTree.updateNode(label)
    let size = LAYOUT.schema.cp_lines.length
    let cpls = LAYOUT.schema.cp_lines
    for (let i = 0; i < size; i++) {
      let cpl = cpls[i]

      if (cpl.net_name == text) {
        records.push(cpl)
        from.push(cpl.net_name)
        to.push(newText)
        cpl.net_name = newText
        for (let i = 0; i < cpl.labels.length; i++) {
          const netLabel = cpl.labels[i]
          records.push(netLabel)
          from.push(netLabel.text)
          to.push(newText)
          netLabel.text = newText
          buildLabelBox(netLabel, LAYOUT.stage.config)
          LAYOUT.quadTree.updateNode(netLabel)
        }
      }
    }
    // for (let i = 0; i < parent.ports.length; i++) {
    //   let port = parent.ports[i]
    //   let res = LAYOUT.schema.query_port(port)

    //   for (let j = 0; j < res.length; j++) {
    //     let cpl = res[j][0]
    //     records.push(cpl)
    //     from.push(cpl.net_name)
    //     to.push(newText)
    //     cpl.net_name = newText
    //     for (let i = 0; i < cpl.labels.length; i++) {
    //       const netLabel = cpl.labels[i]
    //       records.push(netLabel)
    //       from.push(netLabel.text)
    //       to.push(newText)
    //       netLabel.text = newText
    //       buildLabelBox(netLabel, LAYOUT.stage.config)
    //       LAYOUT.quadTree.updateNode(netLabel)
    //     }
    //   }
    // }
  } else {
    //普通标签
    records.push(label)
    from.push(label.text)
    to.push(newText)
    label.text = newText
    buildLabelBox(label, LAYOUT.stage.config)
    LAYOUT.quadTree.updateNode(label)
  }

  LAYOUT.editHistory.record([{ action: 'text', schema: LAYOUT.schema, objs: records, from: from, to: to }])
}

export function getSymbolInsNames(schema) {
  let res = []
  for (let i = 0; i < schema.symbol_ins.length; i++) {
    res.push(schema.symbol_ins[i].ins_name)
  }
  for (let i = 0; i < schema.capsymbol_ins.length; i++) {
    res.push(schema.capsymbol_ins[i].ins_name)
  }

  return res
}

export function getCplineByNetName(schema, netName) {
  let cpl_size = schema.cp_lines.length
  let cpls = schema.cp_lines
  let res = []
  for (let i = 0; i < cpl_size; i++) {
    const cpl = cpls[i]
    if (cpl.net_name == netName) res.push(cpl)
  }
  return res
}
export function deepFileRename(ins, names) {
  ins.symbol.name = newSymbolName(ins.symbol.name, names)

  let before_name = ins.symbol.short_name
  ins.symbol.short_name = newShortName(ins.symbol.name, ins.symbol.short_name)

  ins.ins_name = ins.ins_name.replace(before_name, ins.symbol.short_name)
  if (ins.symbol.$$?.ptrType.name == 'CapSymbol' && ins.symbol.schema) {
    ins.symbol.schema.name = newSymbolName(ins.symbol.schema.name, names)
    let symbols = ins.symbol.schema.symbol_ins
    let symbols_size = symbols.length
    for (let i = 0; i < symbols_size; i++) {
      const ins = symbols[i]
      deepFileRename(ins, names)
    }
    let cap_symbols = ins.symbol.schema.capsymbol_ins
    let cap_symbols_size = cap_symbols.length
    for (let i = 0; i < cap_symbols_size; i++) {
      const ins = cap_symbols[i]
      deepFileRename(ins, names)
    }
  }
}

function fixedPos(pos) {
  let p1 = pos[0]
  let p2 = pos[0]
  let a = p1
  let b = p2
  if (decimalNumber(p1) > 2) {
    a = p1.toFixed(2)
  }

  if (decimalNumber(p2) > 2) {
    b = p1.toFixed(2)
  }

  return [a, b]
}

function decimalNumber(num) {
  var x = String(num).indexOf('.') + 1 //得到小数点的位置
  var y = String(num).length - x //小数点的位数
  return y
}

//自动重命名网络
export function autoRenameCplineNetName(LAYOUT, mdObjs) {
  if (mdObjs.length) {
    let records = []
    let from = []
    let to = []
    mdObjs.forEach(obj => {
      if (obj.$$?.ptrType.name == 'CpLine*') {
        let expand = 1
        let aabb = deepClone(obj.bounding_box)
        aabb[0][0] -= expand
        aabb[0][1] -= expand
        aabb[1][0] += expand
        aabb[1][1] += expand
        let cpLines = LAYOUT.quadTree.queryCpLines(aabb)

        for (let i = 0; i < cpLines.length; i++) {
          const cpl = cpLines[i]
          if (cpl.$$.ptr !== obj.$$.ptr && checkPointsInside(obj.line.points, cpl.line.points)) {
            let res = reNameCplNetName(LAYOUT, obj.net_name, cpl.net_name)
            records.push(...res)
            break
          }
        }
      }
    })
    records.forEach(obj => {
      const nowProperty = {}
      if (obj.$$?.ptrType.name == 'CpLine*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
      } else if (obj.$$?.ptrType.name == 'Label*') {
        nowProperty.color = obj.color
        nowProperty.net_name = obj.net_name
        nowProperty.anchor = obj.anchor
        nowProperty.size = obj.size
        nowProperty.font = obj.font
        nowProperty.text = obj.text
      }
      from.push(nowProperty)
      to.push(null)
    })
    setTimeout(() => {
      if (LAYOUT.editHistory.undoStack.at(-1)) {
        LAYOUT.editHistory.undoStack.at(-1).push({ action: 'property', schema: LAYOUT.schema, objs: records, from: from, to: to })
      }
    })
  }
}

//自动连接重叠的port
export function autoConnectSymbolPort(LAYOUT, mdObjs) {
  if (mdObjs.length) {
    let add_cpls = []
    let all_ports = getSchemaAllPorts(LAYOUT.schema)

    mdObjs.forEach(obj => {
      if (obj.ports) {
        let size = obj.ports.length
        let ports = obj.ports
        for (let i = 0; i < size; i++) {
          const port = ports[i]
          all_ports.forEach(port_unchceked => {
            let belong_to = port_unchceked.belong_to

            if (isEqualPos(port.pos, port_unchceked.pos) && belong_to?.$$?.ptr !== obj?.$$?.ptr && !LAYOUT.schema.query_port_port(port, port_unchceked)) {
              let net_name = newNetName(LAYOUT)
              if (obj.$$?.ptrType.name === 'Pin*') {
                net_name = obj.name.text
              } else if (belong_to?.$$?.ptrType.name === 'Pin*') {
                net_name = belong_to.name?.text
              }
              let pos = port.pos
              let line = new Kernel.Line()
              let cpLine = new Kernel.CpLine()
              cpLine.line = line
              cpLine.color = '#8F00FF'
              cpLine.net_name = net_name
              line.swap([pos])
              LAYOUT.schema.connect(port, cpLine)
              line.swap([pos, pos])
              LAYOUT.schema.connect(port_unchceked, cpLine)
              LAYOUT.add(cpLine)

              add_cpls.push(cpLine)
            }
          })
        }
      }
    })

    setTimeout(() => {
      if (LAYOUT.editHistory.undoStack.at(-1)) {
        LAYOUT.editHistory.undoStack.at(-1).push({ action: 'add', schema: LAYOUT.schema, objs: add_cpls })
      }
    })
  }
}

function getSchemaAllPorts(schema) {
  let res = []

  let symbols_size = schema.symbol_ins.length

  let symbols = schema.symbol_ins

  for (let i = 0; i < symbols_size; i++) {
    let symbolIns = symbols[i]
    let ports_size = symbolIns.ports.length
    let ports = symbolIns.ports
    for (let j = 0; j < ports_size; j++) {
      let port = ports[j]
      res.push(port)
    }
  }

  let cap_symbols_size = schema.capsymbol_ins.length
  let cap_symbols = schema.capsymbol_ins
  for (let i = 0; i < cap_symbols_size; i++) {
    let symbolIns = cap_symbols[i]
    let ports_size = symbolIns.ports.length
    let ports = symbolIns.ports
    for (let j = 0; j < ports_size; j++) {
      let port = ports[j]
      res.push(port)
    }
  }
  let pin_size = schema.pins.length
  let pins = schema.pins
  for (let i = 0; i < pin_size; i++) {
    let pin = pins[i]
    let ports_size = pin.ports.length
    let ports = pin.ports
    for (let j = 0; j < ports_size; j++) {
      let port = ports[j]
      res.push(port)
    }
  }

  res = res.filter(port => !port.belong_to?.js_obj.checked)

  return res
}

function checkPointsInside(points1, points2) {
  for (let i = 0; i < points1.length; i++) {
    const p = points1[i]
    for (let j = 0; j < points2.length - 1; j++) {
      const start = points2[j]
      const end = points2[j + 1]
      if (inner(start[0], start[1], end[0], end[1], p[0], p[1])) {
        return true
      }
    }
  }
  return false
}

export function reNameCopyData(LAYOUT, copyData) {
  let names = []
  let cap_symbols = LAYOUT.file.capsymbol_ins
  let cap_symbols_size = LAYOUT.file.capsymbol_ins.length
  for (let i = 0; i < cap_symbols_size; i++) {
    const symbol = cap_symbols[i]
    names.push(symbol.name)
  }
  let symbols = LAYOUT.file.symbols
  let symbols_size = LAYOUT.file.symbols.length
  for (let i = 0; i < symbols_size; i++) {
    const symbol = symbols[i]
    names.push(symbol.name)
  }
  let schemas = LAYOUT.file.schemas
  let schemas_size = LAYOUT.file.schemas.length

  for (let i = 0; i < schemas_size; i++) {
    const schema = schemas[i]
    names.push(schema.name)
  }
  copyData.forEach(data => {
    if (data.symbol) {
      if (data.symbol.is_official_symbol) {
        data.symbol.name = newSymbolName(data.symbol.name, names)
        let before_name = data.symbol.short_name
        data.symbol.short_name = newShortName(data.symbol.name, data.symbol.short_name)
        data.ins_name = data.symbol.short_name
        data.ins_name = data.ins_name.replace(before_name, data.symbol.short_name)
      } else {
        deepFileRename(data, names)
      }
    }
  })
}

function getDataFromTempSchema(schema, config) {
  let res = []
  //耦合线

  for (let i = 0; i < schema.cp_lines.length; i++) {
    buildCpLinePath(schema.cp_lines[i])
    res.push(schema.cp_lines[i])
  }
  //符号
  for (let i = 0; i < schema.symbol_ins.length; i++) {
    buildSymbolPath(schema.symbol_ins[i])
    res.push(schema.symbol_ins[i])
  }
  //带原理图的符号符号
  for (let i = 0; i < schema.capsymbol_ins.length; i++) {
    buildSymbolPath(schema.capsymbol_ins[i])
    res.push(schema.capsymbol_ins[i])
  }

  //PIN
  for (let i = 0; i < schema.pins.length; i++) {
    buildPinPath(schema.pins[i], config)
    res.push(schema.pins[i])
  }
  //标签
  for (let i = 0; i < schema.labels.length; i++) {
    buildLabelBox(schema.labels[i], config)
    res.push(schema.labels[i])
  }
  return res
}

export function mergeNearPoints(cpls) {
  for (let i = 0; i < cpls.length; i++) {
    const cpl = cpls[i]
    let points = cpl.line.points
    let len = points.length
    let endIndex = len - 1
    if (len > 2) {
      let start = points[0]
      let p1 = points[1]
      let p2 = points[endIndex - 1]
      let last = points[endIndex]
      if (pointsDist(start, p1) < 0.1) {
        points.shift()
      }
      if (pointsDist(p2, last) < 0.1) {
        points.pop()
      }
      cpl.line.swap(points)
      buildCpLinePath(cpl)
    }
  }
}
export function hasCircle(schema, objs) {
  for (let i = 0; i < objs.length; i++) {
    const obj = objs[i]
    if (obj instanceof Kernel.SymbolIns && obj.symbol?.$$?.ptrType.name == 'CapSymbol*' && obj.symbol.schema) {
      if (isCircle(obj.symbol.schema, schema)) {
        return true
      }
      // if (schema.add_children_cause_circle(obj.symbol)) {
      //   return true
      // }
    }
  }
  return false
}

export function fixedPoints(array) {
  for (let i = 0; i < array.length; i++) {
    let p = array[i]
    if (Array.isArray(p)) {
      array[i] = [parseFloat(p[0].toFixed(2)), parseFloat(p[1].toFixed(2))]
    } else {
      array[i] = parseFloat(p.toFixed(2))
    }
  }
  return array
}

export function expandAABB(aabb, v) {
  let newAABB = [
    [aabb[0][0], aabb[0][1]],
    [aabb[1][0], aabb[1][1]],
  ]
  newAABB[0][0] -= v
  newAABB[0][1] -= v
  newAABB[1][0] += v
  newAABB[1][1] += v
  return newAABB
}

export function clearSchemaStatus(schema) {
  //耦合线
  for (let i = 0; i < schema.cp_lines.length; i++) {
    const obj = schema.cp_lines[i]
    obj.js_obj.checked = false
    obj.js_obj.preChecked = false
    obj.js_obj.preCut = false
  }
  //符号
  for (let i = 0; i < schema.symbol_ins.length; i++) {
    const obj = schema.symbol_ins[i]
    obj.js_obj.checked = false
    obj.js_obj.preChecked = false
    obj.js_obj.preCut = false
  }
  //带原理图的符号符号
  for (let i = 0; i < schema.capsymbol_ins.length; i++) {
    const obj = schema.capsymbol_ins[i]
    obj.js_obj.checked = false
    obj.js_obj.preChecked = false
    obj.js_obj.preCut = false
  }
  //PIN
  for (let i = 0; i < schema.pins.length; i++) {
    const obj = schema.pins[i]
    obj.js_obj.checked = false
    obj.js_obj.preChecked = false
    obj.js_obj.preCut = false
    obj.name.js_obj.checked = false
    obj.name.js_obj.preChecked = false
    obj.name.js_obj.preCut = false
  }
  //标签
  for (let i = 0; i < schema.labels.length; i++) {
    const obj = schema.labels[i]
    obj.js_obj.checked = false
    obj.js_obj.preChecked = false
    obj.js_obj.preCut = false
  }
}

//记录删除前耦合线的连接关系
export function recordCplineConnectPorts(objs, schema) {
  let cpls = objs.filter(obj => obj.$$?.ptrType.name == 'CpLine*')
  if (cpls.length) {
    for (let i = 0; i < cpls.length; i++) {
      const cpl = cpls[i]
      let res = schema.query_cp_line(cpl)
      if (res.length) {
        cpl.js_obj.connent_ports = res.map(obj => obj[0])
        cpl.js_obj.connent_ports.forEach(port => (port.js_obj.cpline = cpl))
      }
    }
  }
}

//还原连接关系
export function revertCplineConnectPorts(cpl, schema) {
  if (cpl.js_obj.connent_ports?.length) {
    cpl.js_obj.connent_ports.forEach(port => {
      if (portNotConnectCpline(schema, cpl, port)) {
        schema.connect(port, cpl)
      }
    })
  }
}

export function revertPortConnectPorts(obj, schema) {
  if (obj.ports?.length) {
    obj.ports.forEach(port => {
      if (port.js_obj.cpline && portNotConnectCpline(schema, port.js_obj.cpline, port)) {
        schema.connect(port, port.js_obj.cpline)
      }
    })
  }
}

//判断线和端口是否连接
export function portNotConnectCpline(schema, cpline, port) {
  const ct_ports = schema.query_cp_line(cpline)
  if (ct_ports.filter(ct_port => ct_port[0].$$.ptr == port.$$.ptr).length) {
    return false
  }
  return true
}
