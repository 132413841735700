import { get, post, patch, httpDelete, download, getBlob } from '../axiosReq'

// 获取用户的apiKey信息
// export const getApiKey_api = (params, headers) => post('/file/api/v1/get_user_api_key/', params, headers)

//创建一个存储空间
export const createBucket_api = params => post('/file/api/v1/create_bucket/', params)

//读取文件的方式上传
export const putObjectPath_api = params => post('/file/api/v1/put_object_path/', params)

//文件流的方式上传
export const putObject_api = (params, headers) => post('/file/api/v1/put_object/', params, headers)

//消息服务上传文件
export const putObjectToCache_api = (params, headers) => post('/file/api/v1/put_object_to_cache/', params)

//批量上传文件
export const batchPutObject_api = (params, headers) => post('/file/api/v1/batch_put_object/', params, headers)

//批量上传文件-文件大小限制
export const batchPutObject_fileSize_api = params => post('/file/api/v1/batch_put_object/', params)

//复制文件
export const copyObject_api = (params, headers) => post('/file/api/v1/copy_object/', params, headers)

// 获取文件的基本信息
export const getObjectInfo_api = params => post('/file/api/v1/get_object_info/', params)

// 下载一个文件数据流
export const getObjectData_api = (params, headers) => post('/file/api/v1/get_object_data/', params, headers)

// 获取一个文件链接
export const getObjectUrl_api = params => post('/file/api/v1/get_object_url/', params)

// 获取文件的所有版本信息
export const getObjectList_api = params => post('/file/api/v1/get_object_list/', params)

// 删除文件
export const removeObject_api = (params, headers) => post('/file/api/v1/remove_object/', params, headers)

// 批量删除文件
export const batchRemoveObject_api = (params, headers) => post('/file/api/v1/remove_objects/', params, headers)

// 获取文件链接
export const getDiagramFile_api = params => post('/qeda/symbols/get_diagram_file/', params)

// 保存文件链接
export const saveDiagramFile_api = params => post('/qeda/symbols/save_diagram_file/', params)

// 获取新旧snow_id
export const getCopySnowId_api = params => post('/qeda/file/copy_record/', params)

//符号库--保存个人符号
export const saveMySymbol_api = params => post('/qeda/symbols/save_my_symbol/', params)

//符号库--保存团队符号
export const saveTeamSymbol_api = params => post('/qeda/symbols/save_team_symbol/', params)

//符号库--保存公用符号
export const savePublicSymbol_api = params => post('/qeda/symbols/save_public_symbol/', params)

//获取对象存储基本信息
export const getMinioInfo_api = params => post('/qeda/symbols/get_minio_info/', params)

//保存PDK信息
export const savePDKInof_api = params => post('/qeda/symbols/save_pdk_file/', params)

//获取PDK信息
export const getPDKInfo_api = params => post('/qeda/symbols/get_pdk_file/', params)
