import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)
const messages = {
  zh: {
    ...require('./zh'),
    ...zhLocale,
  },
  en: {
    ...require('./en'),
    ...enLocale,
  },
}
// 注册i18n实例并引入语言文件，文件格式等下解析
const i18n = new VueI18n({
  // 初始语言找本地储存的lang，默认为浏览器语言
  locale: 'zh',
  messages,
})

locale.i18n((key, value) => i18n.t(key, value))
export default i18n
