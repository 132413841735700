<template>
  <div class="layout" id="layout" onselectstart="javascript:return false;">
    <div v-if="selectFileData" style="height:100%">
      <Filebar></Filebar>
    </div>
    <div v-else>
      <div class="no-file-div">
        <div class="no-file-div-item">
          <div class="welcome">{{$t('messages.qlayoutWelcome')}}，{{this.$store.state.username ? this.$store.state.username : username}}</div>
          <div class="underline"></div>
          <div class="welcome-word">
            <div class="welcome-word-item">
              <div style="float: left;">{{$t('messages.quickStart')}}</div>
              <br>
              <img alt="" @click="toCreated" class="welcome-icon" src="../../assets/icons/file/快速开始.svg">
            </div>
            <div class="right-line"></div>
            <div class="welcome-word-item">
              <div style="float: left;">{{$t('messages.instructions')}}</div>
              <br>
              <img alt="" @click="toUsed" class="welcome-icon" src="../../assets/icons/file/使用文档.svg">
            </div>
          </div>
        </div>
        <br>
        <div class="no-file-div-item2">
          <div class="welcome-word2">{{$t('messages.tutorial')}}</div>
          <div class="welcome-word">

            <div class="welcome-word-item2">
              <div v-for="item, index in videosData" :key="index" align="center" class="des" @click="toSingleUsed(item.url)"><!-- NOSONAR -->
                <img alt="" class="welcome-icon2" src="../../assets/icons/file/使用教程.svg">
                <el-tooltip effect="dark" :content="$t('messages.'+item.name)" placement="bottom">
                  <div class="welcome-word-des">{{$t('messages.'+item.name)}}</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filebar from "./Filebar.vue";
import Right from "./Right.vue";
import bus from "../common/bus.js";

export default {
  components: {
    Filebar,
    Right,
  },
  data() {
    return {
      username: localStorage.getItem("user"),
      // selectFileData: {id: "33001"},
      selectFileData: false,
      videosData: [
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: 'video_1',
          url: "https://file.originqc.com.cn/QLayout/24比特版图绘制示例.mp4",
        },
        // {
        //   iconSrc: "../../assets/icons/file/使用教程.svg",
        //   name: 'video_2',
        //   url: "https://file.originqc.com.cn/QLayout/team_collaboration_demo.mp4",
        // },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_3",
          url: "https://file.originqc.com.cn/QLayout/团队1bit原理图版图绘制.mp4",
        },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_4",
          url: "https://file.originqc.com.cn/QLayout/智能工具.mp4",
        },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_5",
          url: "https://file.originqc.com.cn/QLayout/加载PDK_生成PDK.mp4",
        },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_6",
          url: "https://file.originqc.com.cn/QLayout/自动布线.mp4",
        },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_7",
          url: "https://file.originqc.com.cn/QLayout/团队协作及权限设置.mp4",
        },
        {
          iconSrc: "../../assets/icons/file/使用教程.svg",
          name: "video_8",
          url: "https://file.originqc.com.cn/QLayout/3D视图.mp4",
        },
      ],
    };
  },
  methods: {
    changeSelectFileData() {
      // 加载文件时切换，用bus传递文件信息，加载右边图层窗口
      bus.$emit("changeSelectFileDataStatus", true);
    },
    toCreated() {
      bus.$emit("headerCreateCell", true);
    },
    toUsed() {
      window.open("https://qcloud.originqc.com.cn/documentShow?label=6a39342b-3bf9-4b52-a9dd-116bf888da00", "_blank")
    },
    toSingleUsed(url) {
      window.open(url, "_blank")
    },
  },
  mounted() {
    bus.$on("closeLayerList", (data) => {
      bus.$emit("cancelSidebarSelect", true);
      this.selectFileData = false;
      this.$store.state.openedTab.splice(0,this.$store.state.openedTab.length)
      let route = "/home/layout"
      this.$router.push(route);
    });
    bus.$on("userGuide", (data) => {
      this.toUsed();
    });
    bus.$on("demonstrationVideo", (data) => {
      // this.toSingleUsed("https://file.originqc.com.cn/QLayout/QLayout2.0_demo.mp4")
      this.toSingleUsed("https://file.originqc.com.cn/QLayout/24比特版图绘制示例.mp4")
    })
  },
  beforeDestroy() {
    bus.$off("closeLayerList")
    bus.$off("userGuide")
    bus.$off("demonstrationVideo")
  },
  computed: {
    getOpenedCell() {
      return this.$store.state.cellInfo;
    },
  },
  watch: {
    getOpenedCell: {
      handler(val) {
        if (val) {
          this.selectFileData = true;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  flex: 1;
  height: 100%;
  width: 1340px;
  min-width: 400px;
  background-color: #f1efef;
  /deep/ .el-tabs__content {
    height: 100%;
  }
}
.no-file-div {
  padding: 20px;
}
.no-file-div-item {
  width: 100%;
  // height: 605px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.no-file-div-item2 {
  width: 100%;
  height: calc(50vh - 150px);
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.welcome {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  padding: 28px;
}
.underline {
  height: 1px;
  border-bottom: 1px solid #dddddd;
  margin: 0 28px;
}
.welcome-word {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  // align-items: flex-start;
  padding: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  .welcome-word-item {
    width: 45%;
    min-width: 40%;
    text-align: center;
  }
  .welcome-word-item2 {
    width: 100%;
    min-width: 80%;
    padding: 0 50px;
    display: flex;
  }
  .welcome-icon {
    width: 45%;
    padding: 0 20%;
    cursor: pointer;
  }
  .welcome-icon2 {
    height: 110px;
  }
  .right-line {
    width: 1px;
    border-left: 1px solid #dddddd;
    margin: 25px;
  }
  .welcome-word-des {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .des {
    display: inline-block;
    width: 200px;
    // padding: 30px 30px 60px 30px;
    // width: 20%;
    // max-height: 10%;
    cursor: pointer;
  }
}
.welcome-word2 {
  padding: 30px 30px 0px 30px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
</style>