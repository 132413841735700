import store from '../../../../store'
import router from '@/router'
import bus from '@/components/common/bus'
import { logout_api } from '@/api/login/login.js'
import { unLockCell_api } from '@/api/file/file.js'

export const closeAllTab = async function (editableTabs, type) {
  bus.$emit('loading', true)
  for (let i = 0; i < editableTabs.length; i++) {
    let closeCellData = []
    if (editableTabs[i].hasOwnProperty('fileTeamRule')) {
      closeCellData = [
        {
          teamId: editableTabs[i].teamTitleId,
          name: editableTabs[i].teamTitle,
          teamRole: editableTabs[i].teamTeamRole,
        },
        {
          projectId: editableTabs[i].proTitleId,
          name: editableTabs[i].proTitle,
          teamRule: editableTabs[i].proTeamRule,
        },
        {
          name: editableTabs[i].fileTitle,
          fileId: editableTabs[i].fileTitleId,
          teamRule: editableTabs[i].fileTeamRule,
          file_type: editableTabs[i].file_type,
        },
        {
          name: editableTabs[i].cellTitle,
          cellId: editableTabs[i].cellTitleId,
          teamRule: editableTabs[i].cellTeamRule,
          isSaved: editableTabs[i].isSaved,
          isReadOnly: editableTabs[i].isReadOnly,
          cell_type: editableTabs[i].cell_type,
        },
        await unLockCell_api({ snow_team_id: editableTabs[i].teamTitleId, file_snow_id: editableTabs[i].cellTitleId })
      ]
    } else {
      closeCellData = [
        {
          userId: editableTabs[i].userTitleId,
          name: editableTabs[i].userTitle,
        },
        {
          projectId: editableTabs[i].proTitleId,
          name: editableTabs[i].proTitle,
        },
        {
          name: editableTabs[i].fileTitle,
          fileId: editableTabs[i].fileTitleId,
          file_type: editableTabs[i].file_type,
        },
        {
          name: editableTabs[i].cellTitle,
          cellId: editableTabs[i].cellTitleId,
          isSaved: editableTabs[i].isSaved,
          isReadOnly: editableTabs[i].isReadOnly,
          cell_type: editableTabs[i].cell_type,
        },
      ]
    }

    bus.$emit('closeCell', closeCellData)
    
  }

  store.commit('resetFileLayerList', true)
  store.commit('resetCellTable', true)
  store.commit('resetOpenedTab', true)
  // bus.$emit("ending", true)
  if (type == 'toTeamInfo') {
    router.push('/user/teaminfo')
    store.commit('updateOpenedTab', [])
  } else if (type == 'toSelf') {
    router.push('/user')
    store.commit('updateOpenedTab', [])
  } else if (type == 'changePsd') {
    router.push('/password')
  } else if (type == 'exit') {
    logout_api().then(res => {
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
      localStorage.removeItem('user')
      localStorage.removeItem('apiKey')
      localStorage.removeItem('bucket_name')
      localStorage.removeItem('minio_name')
      localStorage.removeItem('qid')
      router.push('/login')
    })
  }
}
