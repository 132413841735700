function Point2d(x, y) {
  this.init(x, y)
}

Point2d.prototype.init = function (x, y) {
  this.x = x
  this.y = y
  this.w = 1
}

Point2d.prototype.degToRad = function (d) {
  return (d * Math.PI) / 180
}

Point2d.prototype.radToDeg = function (r) {
  return (r * 180) / Math.PI
}

Point2d.prototype.log = function () {}

Point2d.prototype.magnitude = function (p) {
  return Math.sqrt(p.x * p.x + p.y * p.y)
}

Point2d.prototype.angle = function (p) {
  return (Math.atan2(-p.y, -p.x) / Math.PI) * 180 + 180
}

Point2d.prototype.subtract = function (v1, v2) {
  return {
    x: v1.x - v2.x,
    y: v1.y - v2.y,
  }
}

Point2d.prototype.add = function (v1, v2) {
  return {
    x: v1.x + v2.x,
    y: v1.y + v2.y,
  }
}

Point2d.prototype.scale = function (v1, s) {
  return {
    x: v1.x * s,
    y: v1.y * s,
  }
}

Point2d.prototype.normalize = function (v) {
  let length = Math.sqrt(v.x * v.x + v.y * v.y)
  return {
    x: v.x / length,
    y: v.y / length,
  }
}

Point2d.prototype.distance = function (v1, v2) {
  return Math.sqrt((v1.x - v2.x) * (v1.x - v2.x) + (v1.y - v2.y) * (v1.y - v2.y))
}

Point2d.prototype.dot = function (v1, v2) {
  return v1.x * v2.x + v1.y * v2.y
}

Point2d.prototype.lerp = function (v1, v2, t) {
  return {
    x: (v2.x - v1.x) * t + v1.x,
    y: (v2.y - v1.y) * t + v1.y,
  }
}

function Matrix2d() {
  this.m = []
  this.m[0] = []
  this.m[1] = []
  this.m[2] = []
}

Matrix2d.prototype.log = function () {
  let i
  for (i = 0; i < 3; i += 1) {}
}

/**
 * @prop {number} a - 缩放 x
 * @prop {number} b - 倾斜 y
 * @prop {number} c - 倾斜 x
 * @prop {number} d - 缩放 y
 * @prop {number} e - 平移 x
 * @prop {number} f - 平移 y
 */
Matrix2d.prototype.identity = function (a = 1, b = 0, c = 0, d = 1, e = 0, f = 0) {
  this.m[0][0] = a
  this.m[0][1] = b
  this.m[0][2] = 0

  this.m[1][0] = c
  this.m[1][1] = d
  this.m[1][2] = 0

  this.m[2][0] = e
  this.m[2][1] = f
  this.m[2][2] = 1

  return this
}

Matrix2d.prototype.getMatrix = function () {
  return [
    [this.m[0][0], this.m[0][1]],
    [this.m[1][0], this.m[1][1]],
    [this.m[2][0], this.m[2][1]],
  ]
}

Matrix2d.prototype.translate = function (x, y) {
  this.transform(1, 0, 0, 1, x, y)
  return this
}

Matrix2d.prototype.translateX = function (x) {
  this.transform(1, 0, 0, 1, x, 0)
  return this
}

Matrix2d.prototype.translateY = function (y) {
  this.transform(1, 0, 0, 1, 0, y)
  return this
}

Matrix2d.prototype.scale = function (x, y) {
  this.transform(x, 0, 0, y, 0, 0)
  return this
}

Matrix2d.prototype.rotate = function (deg) {
  let rad = deg * (Math.PI / 180),
    c = Math.cos(rad),
    s = Math.sin(rad)
  this.transform(c, s, -s, c, 0, 0)
  return this
}

Matrix2d.prototype.getInverse = function (mat = this) {
  let a = mat.m[0][0],
    b = mat.m[0][1],
    c = mat.m[1][0],
    d = mat.m[1][1],
    e = mat.m[2][0],
    f = mat.m[2][1],
    mat_inverse = new Matrix2d().identity(),
    dt = a * d - b * c

  mat_inverse.m[0][0] = d / dt
  mat_inverse.m[0][1] = -b / dt
  mat_inverse.m[1][0] = -c / dt
  mat_inverse.m[1][1] = a / dt
  mat_inverse.m[2][0] = (c * f - d * e) / dt
  mat_inverse.m[2][1] = -(a * f - b * e) / dt

  return mat_inverse
}

Matrix2d.prototype.multiply = function (m1, m2) {
  let x,
    y,
    z,
    sum,
    d = new Matrix2d()
  for (x = 0; x < 3; x++) {
    for (y = 0; y < 3; y += 1) {
      sum = 0
      for (z = 0; z < 3; z += 1) {
        sum += m1.m[x][z] * m2.m[z][y]
      }
      d.m[x][y] = sum
    }
  }

  return d
}

Matrix2d.prototype.multiplyVec = function (v, m) {
  return {
    x: v.x * m.m[0][0] + v.y * m.m[1][0] + v.w * m.m[2][0],
    y: v.x * m.m[0][1] + v.y * m.m[1][1] + v.w * m.m[2][1],
    w: v.x * m.m[0][2] + v.y * m.m[1][2] + v.w * m.m[2][2],
  }
}

Matrix2d.prototype.transform = function (a2, b2, c2, d2, e2, f2) {
  const result = this.multiply(new Matrix2d().identity(a2, b2, c2, d2, e2, f2), this)
  this.m = result.m
  return this
}

Matrix2d.prototype.applyToPoint = function (x, y) {
  // return {
  //     x: x * this.m[0][0] + y * this.m[1][0] + this.m[2][0],
  //     y: x * this.m[0][1] + y * this.m[1][1] + this.m[2][1]
  // }
  return [x * this.m[0][0] + y * this.m[1][0] + this.m[2][0], x * this.m[0][1] + y * this.m[1][1] + this.m[2][1]]
}

Matrix2d.prototype.applyToArray = function (points) {
  let i = 0,
    p,
    l,
    mxPoints = []

  if (typeof points[0] === 'number') {
    l = points.length
    while (i < l) {
      p = this.applyToPoint(points[i++], points[i++])
      mxPoints.push(p.x, p.y)
    }
  } else if (Array.isArray(points[0])) {
    for (; (p = points[i]); i++) {
      mxPoints.push(this.applyToPoint(p[0], p[1]))
    }
  } else {
    for (; (p = points[i]); i++) {
      mxPoints.push(this.applyToPoint(p.x, p.y))
    }
  }

  return mxPoints
}

export { Point2d, Matrix2d }
