import store from '../../../../store'
import i18n from '../../../../common/lang/i18n'
import bus from '@/components/common/bus'
import { getUserProjectList, getTeamProjectList, normalFileCopyTo, getFileParams, setFileParams, getCellList, getLayerInfo, addLayerInfo, getPermission, selectLockCell } from './fileListApiFn.js'
import { batchSaveDataFn, batchSaveDataFn_fileSize, downloadFileData, getCurrentBoardFolderName, getFolderName, getObjectData, batchGetOSData, getObjectDataFn, batchGetObjectDataFn } from './objectStorageFn.js'
import { file } from 'jszip'
import { newSymbolName } from '@/utils/graphic_schema/layout/layout-util.js'
import { getPDKInfo_api, getDiagramFile_api } from '@/api/objectStorage/objectStorage.js'
import { addCellMessageServe } from '../../../../utils/message_serve/cell-message'
import { unLockCell_api } from '@/api/file/file.js'
const defaultNewLayerDatas = [
  {
    color: '#F96CAB',
    shape: 'F1',
    border: 'B1',
    borderColor: '#F96CAB',
    image: '',
  },
  {
    color: '#BA70F8',
    shape: 'F3',
    border: 'B1',
    borderColor: '#BA70F8',
    image: '',
  },
  {
    color: '#7292F7',
    shape: 'F1',
    border: 'B1',
    borderColor: '#7292F7',
    image: '',
  },
  {
    color: '#72D9F7',
    shape: 'F3',
    border: 'B1',
    borderColor: '#72D9F7',
    image: '',
  },
  {
    color: '#63E4B5',
    shape: 'F1',
    border: 'B1',
    borderColor: '#63E4B5',
    image: '',
  },
  {
    color: '#86DF72',
    shape: 'F3',
    border: 'B1',
    borderColor: '#86DF72',
    image: '',
  },
  {
    color: '#D6F656',
    shape: 'F1',
    border: 'B1',
    borderColor: '#D6F656',
    image: '',
  },
  {
    color: '#F3D85B',
    shape: 'F3',
    border: 'B1',
    borderColor: '#F3D85B',
    image: '',
  },
  {
    color: '#F09D4C',
    shape: 'F1',
    border: 'B1',
    borderColor: '#F09D4C',
    image: '',
  },
  {
    color: '#FF6E6E',
    shape: 'F3',
    border: 'B1',
    borderColor: '#FF6E6E',
    image: '',
  },
]

const createNewFileLayerList = function () {
  let randomIdList = []
  for (let i = 0; i < 13; i++) {
    let id = Math.random().toString(36).substr(2, 5)
    if (!randomIdList.some(item => item.snow_id === id)) {
      randomIdList.push(id)
    }
  }
  let layer_infos = [
    {
      id: randomIdList[0],
      hide: false,
      lock: false,
      color: '#FF0014',
      shape: 'F0',
      border: 'B1',
      isExport: true,
      layerName: 'KeyPoInt',
      borderColor: '#9D0000',
      layerNumber: 0,
      GDSType: '0',
    },
    {
      id: randomIdList[1],
      hide: false,
      lock: false,
      color: '#1955FF',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'Metal1',
      borderColor: '#1955FF',
      layerNumber: 1,
      GDSType: '0',
    },
    {
      id: randomIdList[2],
      hide: false,
      lock: false,
      color: '#7DAEF7',
      shape: 'F6',
      border: 'B1',
      isExport: true,
      layerName: 'Metal2',
      borderColor: '#7DAEF7',
      layerNumber: 2,
      GDSType: '0',
    },
    {
      id: randomIdList[3],
      hide: false,
      lock: false,
      color: '#9B51EE',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal3',
      borderColor: '#9B51EE',
      layerNumber: 3,
      GDSType: '0',
    },
    {
      id: randomIdList[4],
      hide: false,
      lock: false,
      color: '#C660F1',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal3-Flip',
      borderColor: '#C660F1',
      layerNumber: 4,
      GDSType: '0',
    },
    {
      id: randomIdList[5],
      hide: false,
      lock: false,
      color: '#A6B65A',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal4',
      borderColor: '#A6B65A',
      layerNumber: 5,
      GDSType: '0',
    },
    {
      id: randomIdList[6],
      hide: false,
      lock: false,
      color: '#7FB65A',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Metal4-Flip',
      borderColor: '#7FB65A',
      layerNumber: 6,
      GDSType: '0',
    },
    {
      id: randomIdList[7],
      hide: false,
      lock: false,
      color: '#E8CE3B',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'Indium',
      borderColor: '#E8CE3B',
      layerNumber: 7,
      GDSType: '0',
    },
    {
      id: randomIdList[8],
      hide: false,
      lock: false,
      color: '#DEB22B',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'Indium-Flip',
      borderColor: '#DEB22B',
      layerNumber: 8,
      GDSType: '0',
    },
    {
      id: randomIdList[9],
      hide: false,
      lock: false,
      color: '#B069E7',
      shape: 'F1',
      border: 'B1',
      isExport: true,
      layerName: 'TiN',
      borderColor: '#B069E7',
      layerNumber: 9,
      GDSType: '0',
    },
    {
      id: randomIdList[10],
      hide: false,
      lock: false,
      color: '#D469E7',
      shape: 'F3',
      border: 'B1',
      isExport: true,
      layerName: 'TiN-Flip',
      borderColor: '#D469E7',
      layerNumber: 10,
      GDSType: '0',
    },
    {
      id: randomIdList[11],
      hide: false,
      lock: false,
      color: '#E60012',
      shape: 'F4',
      border: 'B1',
      isExport: true,
      layerName: 'Junction',
      borderColor: '#E60012',
      layerNumber: 11,
      GDSType: '0',
    },
    {
      id: randomIdList[12],
      hide: false,
      lock: false,
      color: '',
      shape: '',
      border: 'B1',
      isExport: true,
      layerName: 'Label',
      borderColor: '#00E2DA',
      layerNumber: 12,
      GDSType: '0',
    },
  ]

  return layer_infos
}

//按键禁止
export const disableBtn = function (ref) {
  ref.$el.classList.add('deactivate')
  ref.$el.classList.add('is-disabled')
  ref.$el.disabled = true
}

//按键激活
export const activateBtn = function (ref) {
  ref.$el.classList.remove('deactivate')
  ref.$el.classList.remove('is-disabled')
  ref.$el.disabled = false
}

//获取团队列表信息
export const getTeamListInfo = function (teamListData) {
  let teamListInfo = []
  teamListData.forEach(item => {
    teamListInfo.push(new TeamNode(item.team_name, item.snow_team_id, item.gns, '', '', item.role))
  })
  return teamListInfo
}

//文件列表节点类
export class FileListNode {
  constructor(name, snow_id, gns, category, category_child) {
    this.name = name
    this.snow_id = snow_id
    this.gns = gns
    this.category = category
    this.category_child = category_child
  }
}

//用户节点类
export class UserNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child) {
    super(name, snow_id, gns, category, category_child)
    this.isTeam = false
  }
}

//团队节点类
export class TeamNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child, role) {
    super(name, snow_id, gns, category, category_child)
    this.role = role
    this.isTeam = true
    this.fileWs = null
  }
}

//个人项目节点类
export class UserProjectNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child) {
    super(name, snow_id, gns, category, category_child)
    this.showInput = false
    this.isTeam = false
  }
}

//团队项目节点类
export class TeamProjectNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child) {
    super(name, snow_id, gns, category, category_child)
    this.teamRule = ''
    this.showInput = false
    this.isTeam = true
    this.fileWs = null
  }
}

//个人文件节点类
export class UserFileNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child, file_type) {
    super(name, snow_id, gns, category, category_child)
    this.file_type = Number(file_type)
    this.showInput = false
    this.isTeam = false
  }
}

//团队文件节点类
export class TeamFileNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child, file_type) {
    super(name, snow_id, gns, category, category_child)
    this.file_type = Number(file_type)
    this.teamRule = ''
    this.showInput = false
    this.isTeam = true
    this.fileWs = null
    this.layerWs = null
  }
}

//个人单元类
export class UserCellNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, create_time) {
    super(name, snow_id, gns, category, category_child)
    this.cell_type = Number(cell_type)
    this.showInput = false
    this.isLeaf = false
    this.isTeam = false
    this.create_time = new Date(create_time).getTime()
  }
}

//个人版图/器件单元类
export class UserLayoutCellNode extends UserCellNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, isTemporary, create_time) {
    super(name, snow_id, gns, category, category_child, cell_type)
    this.isTemporary = isTemporary
    this.showInput = false
    this.isLeaf = false
    this.isTeam = false
    this.create_time = new Date(create_time).getTime()
  }
}

//个人原理图/符号/封装符号单元类
export class UserSchemaCellNode extends UserCellNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, isTemporary, epschemaName, create_time) {
    super(name, snow_id, gns, category, category_child, cell_type)
    this.isTemporary = isTemporary
    this.showInput = false
    this.isLeaf = false
    this.isTeam = false
    this.children = []
    this.epschemaName = epschemaName
    this.create_time = new Date(create_time).getTime()
  }
}

//团队单元类
export class TeamCellNode extends FileListNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, create_time) {
    super(name, snow_id, gns, category, category_child)
    this.cell_type = Number(cell_type)
    this.isLeaf = false
    this.teamRule = ''
    this.showInput = false
    this.isTeam = true
    this.create_time = new Date(create_time).getTime()
  }
}

//团队版图单元类
export class TeamLayoutCellNode extends TeamCellNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, isTemporary, create_time) {
    super(name, snow_id, gns, category, category_child, cell_type)
    this.isTemporary = isTemporary
    this.isLeaf = false
    this.teamRule = ''
    this.showInput = false
    this.isTeam = true
    this.create_time = new Date(create_time).getTime()
  }
}

//团队原理图单元类
export class TeamSchemaCellNode extends TeamCellNode {
  constructor(name, snow_id, gns, category, category_child, cell_type, isTemporary, epschemaName, create_time) {
    super(name, snow_id, gns, category, category_child, cell_type)
    this.isTemporary = isTemporary
    this.isLeaf = false
    this.teamRule = ''
    this.showInput = false
    this.isTeam = true
    this.children = []
    this.epschemaName = epschemaName
    this.create_time = new Date(create_time).getTime()
  }
}

//通过currentNode获取相关node
export const getParentNodeFn = function (currentNode) {
  let topNode = {}
  let projectNode = {}
  let fileNode = {}
  let cellNode = {}

  if (currentNode.level === 2) {
    topNode = currentNode.parent
    projectNode = currentNode
  } else if (currentNode.level === 3) {
    topNode = currentNode.parent.parent
    projectNode = currentNode.parent
    fileNode = currentNode
  } else if (currentNode.level === 4) {
    topNode = currentNode.parent.parent.parent
    projectNode = currentNode.parent.parent
    fileNode = currentNode.parent
    cellNode = currentNode
  }

  return {
    topNode,
    projectNode,
    fileNode,
    cellNode,
  }
}

//通过cellId获取目标单元节点及祖先节点相关数据
export const getTargetNodeInfoByCellId = function (fileId, cellId) {
  let idData = {
    fileId,
    cellId,
  }
  bus.$emit('getTargetNodeInfo', idData)
}

// 工程文件dump数据转u8
export const binaryDataToUTF8 = function (binaryDataObj) {
  let U8Array = []

  for (const key in binaryDataObj) {
    let size = binaryDataObj[key].size()
    let binaryArray = []
    for (let i = 0; i < size; i++) {
      binaryArray.push(binaryDataObj[key].get(i))
    }
    let data_u8 = new Uint8Array(binaryArray)
    U8Array.push({
      snow_id: key,
      data_u8,
    })
  }

  return U8Array
}

//多个u8合成一个u8
export const mergeU8Array = function (u8List) {
  let length = 0
  u8List.forEach(item => {
    length += item.length
  })
  let mergedArray = new Uint8Array(length)
  let offset = 0
  u8List.forEach(item => {
    mergedArray.set(item, offset)
    offset += item.length
  })

  return mergedArray
}

//设置单元表数据
export const setDataToCellTable = async function (projectId, fileId, cell) {
  cell.js_obj.isSave = true
  let cellDataObj = {
    projectId,
    fileId,
    cellId: cell.snow_id,
    cell,
  }
  getTargetNodeInfoByCellId(fileId, cell.snow_id)
  const data = store.state.targetNodeInfo
  await addCellMessageServe(data[0], data[1], data[2], data[3], cell)
  store.commit('setDataToCellTable', cellDataObj)
}

//单元表中查询单元
export const getCellFromTable = function (cellId) {
  let index = store.state.cellTable.findIndex(item => item.cell.snow_id === cellId)
  if (index === -1) {
    return null
  } else {
    return store.state.cellTable[index].cell
  }
}

//单元表中通过fileId批量获取同文件单元数据
export const batchGetCellFromTableByFileId = function (fileId) {
  let cells = store.state.cellTable.filter(item => item.fileId === fileId)
  return cells
}

//索引数据获取方法
export const getCellIndexData = async function (fileId) {
  let res = await getFileParams(fileId)

  if (res.code === 200000) {
    if (res.data.length) {
      let cellIndexData = res.data[0]._source.param

      return cellIndexData == undefined ? [] : cellIndexData
    } else {
      return []
    }
  }
}

//个人项目列表获取
export const getUserProNameList = async function (start, rows) {
  let res = await getUserProjectList(start, rows)
  if (res.code == 200000) {
    let options_proName = []
    res.data.data.forEach(item => {
      options_proName.push({
        value: item._source.name,
        project_gns: item._source.gns,
      })
    })

    return options_proName
  }
}

//团队项目列表获取
export const getTeamProjectNameList = async function (start, rows, teamName, teamId, teamGns) {
  if (teamName === '') {
    bus.$emit('errDialog', i18n.t('messages.fileTip5'))
    return []
  } else {
    let res = await getTeamProjectList(start, rows, teamId, teamGns)
    if (res.code == 200000) {
      let options_proName = []
      res.data.data.forEach(async item => {
        options_proName.push({
          value: item._source.name,
          project_gns: item._source.gns,
        })
      })

      return options_proName
    }
  }
}

//drf数据
export const getDrfFromTable = function (projectId) {
  let index = store.state.DRFTable.findIndex(item => item.projectId === projectId)
  if (index === -1) {
    return null
  } else {
    return store.state.DRFTable[index].drf
  }
}
//tf数据
export const getTfFromTable = function (projectId) {
  let index = store.state.TFTable.findIndex(item => item.projectId === projectId)
  if (index === -1) {
    return null
  } else {
    return store.state.TFTable[index].tf
  }
}

//获取pdk数据方法
export const getPDKFileFn = async function (project_snow_id) {
  let pdk_res = await getPDKInfo_api({ project_snow_id: project_snow_id })
  if (pdk_res.code === 200000) {
    let config = pdk_res.data.url_data
    if (config.lib || config.drf || config.tf) {
      // 获取drf文件内容并解析为json
      let lib_u8_drf = await getObjectDataFn(config.drf)
      let lib_u8_tf = await getObjectDataFn(config.tf)
      let lib_fr_drf = new FileReader()
      lib_fr_drf.readAsText(new Blob([lib_u8_drf]))
      let result_drf = new Promise(resolve => {
        lib_fr_drf.onload = evt => {
          resolve(evt)
        }
      })

      await result_drf.then(evt => {
        let pdkDrf = JSON.parse(evt.target.result)

        let pdkDrfObj = {
          drf: pdkDrf,
          projectId: project_snow_id,
        }
        store.commit('setPDK_DRF', pdkDrfObj)
      })

      let lib_fr_tf = new FileReader()
      lib_fr_tf.readAsText(new Blob([lib_u8_tf]))
      let result_tf = new Promise(resolve => {
        lib_fr_tf.onload = evt => {
          resolve(evt)
        }
      })

      await result_tf.then(evt => {
        let pdkTf = JSON.parse(evt.target.result)

        let pdkTfObj = {
          tf: pdkTf,
          projectId: project_snow_id,
        }
        store.commit('setPDK_TF', pdkTfObj)
      })
    }
  }
}

//获取指定id节点的node
export const getNodeByIdFn = function (nodeId) {
  bus.$emit('getNodeById', nodeId)
}

//获取完单元数据后得到完整单元数据
export const AssembleCellFn = function (cell, cellArray) {
  let resutl = Kernel.assemble([cell, ...cellArray])

  var new_cell = resutl.filter(item => item.snow_id === cell.snow_id)[0]

  return new_cell
}

//获取完整单元数据
export const getCompleteCellData = async function (projectId, fileId, cellId) {
  let cell = getCellFromTable(cellId)
  if (cell === null) {
    //不存在,从对象存储获取
    cell = await getObjectData(cellId)
    if (!cell) {
      bus.$emit('errDialog', i18n.t('messages.fileTip16'))
      if (store.state.openedTab.length === 0) {
        bus.$emit("changeMenuAndSidebar", "0");
        bus.$emit("closeLayerList", true);
        bus.$emit("resetShowCoordinate", true);
      }
      bus.$emit('ending', true)
      return false
    }
  }

  let cellIndexData = await getCellIndexData(fileId)
  let recIdArr = []
  let index = cellIndexData.findIndex(item => item.snow_id === cellId)
  //递归获取引用的单元id
  recIdArr = recGetCellIndex(recIdArr, cellIndexData, cellIndexData[index])

  //得到关联单元
  let associatedCellArr = await getAssociatedCell(projectId, fileId, recIdArr)

  //组装方法,返回完整数据
  cell = AssembleCellFn(cell, associatedCellArr)

  let cellList = []
  if (cell.type.value === 5) {
    cellList = cell.depend_cells(-1)
  } else if (cell.type.value === 3 || cell.type.value === 2) {
    cellList = cell.depend_symbol_schemas(-1)
  }
  cellList.push(cell)

  for (let i = 0; i < cellList.length; i++) {
    await setDataToCellTable(projectId, fileId, cellList[i])
  }
  return cell
}

//获取对象存储中完整单元数据
export const getOSCompleteCellData = async function (projectId, fileId, cellId, isSave = true) {
  let cell = await getObjectData(cellId)

  //获取索引数据
  let cellIndexData = await getCellIndexData(fileId)
  let recIdArr = []

  let index = cellIndexData.findIndex(item => item.snow_id === cellId)

  //递归获取引用的单元id
  recIdArr = recGetCellIndex(recIdArr, cellIndexData, cellIndexData[index])

  //得到关联单元
  let associatedCellArr = await batchGetOSData(recIdArr)

  //组装方法,返回完整数据
  cell = AssembleCellFn(cell, associatedCellArr)
  let cellList = []
  if (cell.type.value === 5) {
    cellList = cell.depend_cells(-1)
  } else if (cell.type.value === 3) {
    cellList = cell.depend_symbol_schemas(-1)
  }
  cellList.push(cell)
  if (isSave) {
    for (let i = 0; i < cellList.length; i++) {
      setDataToCellTable(projectId, fileId, cellList[i])
    }
  }
  return cell
}

//拿到一个新单元,重新组装
export const reassembCell = async function (cell, fileId) {
  //获取索引数据
  let cellId = cell.snow_id
  let cellIndexData = await getCellIndexData(fileId)
  let recIdArr = []

  let index = cellIndexData.findIndex(item => item.snow_id === cellId)

  //递归获取引用的单元id
  recIdArr = recGetCellIndex(recIdArr, cellIndexData, cellIndexData[index])

  //得到关联单元
  let associatedCellArr = await batchGetOSData(recIdArr)

  //组装方法,返回完整数据
  cell = AssembleCellFn(cell, associatedCellArr)
  return cell
}

//通过多个snowId拿到所有相关数据
export const batchGetCellByCellIdList = async function (projectId, fileId, cellIdList) {
  let completeCellList = []
  for (let i = 0; i < cellIdList.length; i++) {
    let cell = await getCompleteCellData(projectId, fileId, cellIdList[i])
    completeCellList.push(cell)
  }
  return completeCellList
}

//递归获取索引关系
export const recGetCellIndex = function (recArr, cellIndexArr, obj) {
  if (!obj) return []
  for (let i = 0; i < obj.cells.length; i++) {
    let index = cellIndexArr.findIndex(item => item.snow_id === obj.cells[i].snow_id)

    if (index === -1) {
      continue
    } else {
      if (!recArr.some(item => item === cellIndexArr[index].snow_id)) {
        recArr.push(cellIndexArr[index].snow_id)
      }
      if (cellIndexArr[index].cells.length > 0) {
        recGetCellIndex(recArr, cellIndexArr, cellIndexArr[index])
      }
    }
  }

  return recArr
}

//获取顶层索引节点
export const getTopCellIndex = function (target_cell_id, cellIndexArr) {
  let totalCellIndex = []
  for (let i = 0; i < cellIndexArr.length; i++) {
    for (let j = 0; j < cellIndexArr[i].cells.length; j++) {
      totalCellIndex.push(cellIndexArr[i].cells[j].snow_id)
    }
  }

  let cellIndexSetObj = new Set(totalCellIndex)
  return cellIndexSetObj.has(target_cell_id) ? false : true
}

//判断闭环
export const isCircle = function (targetCell, currentCell) {
  let target_cell_id = targetCell.snow_id //要调用的单元的id

  let currentCellList = []
  let targetCellList = []
  currentCellList.push(currentCell)
  if (targetCell.type.value === 2 || targetCell.type.value === 3) {
    targetCellList = targetCell.depend_symbol_schemas(-1)
  } else if (targetCell.type.value === 5) {
    targetCellList = targetCell.depend_cells(-1)
  }
  targetCellList.push(targetCell)
  let currentCellIdsList = currentCellList.map(item => item.snow_id)
  let targetCellIdsList = targetCellList.map(item => item.snow_id)

  for (let i = 0; i < currentCellIdsList.length; i++) {
    if (targetCellIdsList.some(item => item === currentCellIdsList[i])) {
      return true
    }
  }

  return false
}

//从单元表中判断有无已获取的数据,返回关联单元
export const getAssociatedCell = async function (projectId, fileId, recArr) {
  let unacquiredCellIdArr = []
  let existingCellArr = [] //已有的单元
  let associatedCellArr = [] //需要请求的关联的单元
  let cellTable = store.getters.getCellTable

  if (cellTable.length > 0) {
    for (let i = 0; i < recArr.length; i++) {
      if (!cellTable.some(item => item.cellId === recArr[i])) {
        unacquiredCellIdArr.push(recArr[i])
      } else {
        let existingCellObj = cellTable.filter(item => item.cellId === recArr[i])[0]
        existingCellArr.push(existingCellObj.cell)
      }
    }
  } else {
    unacquiredCellIdArr = recArr
  }

  let unacquiredCellArr = await batchGetOSData(unacquiredCellIdArr)

  //将获取到的cell数据缓存到前端
  for (let j = 0; j < unacquiredCellArr.length; j++) {
    setDataToCellTable(projectId, fileId, unacquiredCellArr[j])
  }

  associatedCellArr = [...existingCellArr, ...unacquiredCellArr]

  return associatedCellArr
}

//保存当前画板单元数据
export const saveCellFn = async function (cellId, cellGns, isForce = false, needUnLock = false) {
  let saveCell = getCellFromTable(cellId)

  let fileId = cellGns.split('/')[cellGns.split('/').length - 2]
  let projectId = cellGns.split('/')[cellGns.split('/').length - 3]

  if (isForce || (!saveCell.js_obj.isSave && saveCell.js_obj.isSave != undefined)) {
    let saveCellBinaryDataObj = saveCell.dump_file(true, 0)
    //需要有变化才保存
    if (Object.keys(saveCellBinaryDataObj).length > 0) {
      let u8Array = binaryDataToUTF8(saveCellBinaryDataObj)
      // let batch_data = {}
      let cellIndexNew = { snow_id: cellId, cells: [] }
      let cellIndexData = await getCellIndexData(fileId)

      let index = cellIndexData.findIndex(item => item.snow_id === cellId)

      let cellIndexOld = cellIndexData[index]
      let associatedCellList = []
      if (saveCell.type.value === 5) {
        associatedCellList = saveCell.depend_cells(1)
      } else if (saveCell.type.value === 3) {
        associatedCellList = saveCell.depend_symbol_schemas(1)
      }

      for (let i = 0; i < associatedCellList.length; i++) {
        cellIndexNew.cells.push({ snow_id: associatedCellList[i].snow_id })
      }

      //完成,文件保存
      // batch_data[cellId] = { folder_name: getCurrentBoardFolderName(cellGns), file_type: returnFileType(saveCell.type.value) }

      // downloadFileData(u8Array[0].data_u8, 'schema', 'txt')

      // await batchSaveDataFn(u8Array, batch_data)
      let batchSaveType = saveCell.type.value === 5 ? 'LAYOUT_FILE' : 'DIAGRAM_FILE'
      await batchSaveDataFn_fileSize(u8Array, projectId, fileId, batchSaveType, [cellId]);
      //索引比较,保存
      let isSaveIndex = cellIndexComparingFn(cellIndexOld, cellIndexNew)
      if (isSaveIndex) {
        cellIndexData[index] = cellIndexNew
        setFileParams(fileId, cellIndexData)
      }
      if (!isForce) {
        bus.$emit('saveCellSuccess', cellId)
      }
      bus.$emit('successMessage', i18n.t('messages.saveSuccess'))
      saveCell.js_obj.isSave = true
    } else {
      bus.$emit('saveHasCell', cellId)
    }
  } else {
    bus.$emit('saveHasCell', cellId)
  }

  if (needUnLock) {
    new Promise(res => {
      let teamId = cellGns.split('/')[cellGns.split('/').length - 4]
      setTimeout(async () => {
        await unLockCell_api({ snow_team_id: teamId, file_snow_id: cellId })
        res()
      }, 1500)
    })
  }
}

const cellIndexComparingFn = async function (cellIndexOld, cellIndexNew) {
  //获取索引数据

  if (JSON.stringify(cellIndexNew.cells) === JSON.stringify(cellIndexOld.cells)) {
    return false
  } else {
    return true
  }
}

// //只保存项目数据
export const saveProjectFn = async function (project, node) {
  // let projectGns = node.data.gns
  // let isTeam = node.data.isTeam
  // let topId = node.parent.data.snow_id
  // let batch_data = {}
  let projectId = project.snow_id

  let binaryDataObj = project.dump_file(true, 0)

  if (Object.keys(binaryDataObj).length > 0) {
    let u8Array = binaryDataToUTF8(binaryDataObj)
    // batch_data[projectId] = { folder_name: getFolderName(topId, projectGns, isTeam), file_type: 'LAYOUT_FILE' }
    // await batchSaveDataFn(u8Array, batch_data)
    await batchSaveDataFn_fileSize(u8Array, projectId, "", 'LAYOUT_FILE', "");
  }
}

//保存Library/SchemaFile
export const saveFileFn = async function (file, node) {
  // let fileGns = node.data.gns
  // let isTeam = node.data.isTeam
  // let topId = node.parent.parent.data.snow_id
  // let batch_data = {}
  let projectId = node.parent.data.snow_id
  let fileId = file.snow_id
  let batchSaveType = file.type === 6 ? 'LAYOUT_FILE' : 'DIAGRAM_FILE'
  let binaryDataObj = file.dump_file(true, 0)

  if (Object.keys(binaryDataObj).length > 0) {
    let u8Array = binaryDataToUTF8(binaryDataObj)
    // batch_data[fileId] = { folder_name: getFolderName(topId, fileGns, isTeam), file_type: file.type === 6 ? 'LAYOUT_FILE' : 'DIAGRAM_FILE' }
    // await batchSaveDataFn(u8Array, batch_data)
    await batchSaveDataFn_fileSize(u8Array, projectId, fileId, batchSaveType, "");
  }
}

//返回文件数据类型
export const returnFileType = function (value) {
  if (value === 1) {
    return 'SYMBOLS_FILE'
  } else if (value === 2) {
    return 'SYMBOLS_FILE'
  } else if (value === 3) {
    return 'DIAGRAM_FILE'
  } else if (value === 4) {
    return 'LAYOUT_FILE'
  } else if (value === 5) {
    return 'LAYOUT_FILE'
  } else if (value === 6) {
    return 'LAYOUT_FILE'
  } else if (value === 7) {
    return 'COMPONENT_FILE'
  } else if (value === 8) {
    return 'DIAGRAM_FILE'
  }
}

// 项目复制到
export const projectCopyTo = async function (currentNode, targetNode) {
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: [],
    snow_team_id: '',
    accessor_type: '',
  }
  all_data.snow_team_id = targetNode.node_data.data.snow_id
  all_data.accessor_type = targetNode.node_data.data.isTeam ? 2 : 1
  // step1:组装新的project
  let project = new Kernel.Project()
  // 获取目标文件下所有名字集合
  let names = targetNode.node_data.childNodes.map(node => {
    return node.data.name
  })
  // 对比名字，改名
  project.name = newSymbolName(targetNode.form_copyName, names)
  let new_project_snow_id = project.snow_id

  let new_project_gns = targetNode.node_data.data.isTeam ? `gns://${targetNode.node_data.data.snow_id}/${new_project_snow_id}` : `gns://${new_project_snow_id}`
  let project_data = {
    name: targetNode.form_copyName,
    snow_id: new_project_snow_id,
    gns: new_project_gns,
    parent_gns: targetNode.node_data.data.gns,
    parent_snow_id: targetNode.node_data.data.snow_id,
    type: '',
  }

  all_data.project_list.push(project_data)
  // setp2:新建项目至对象存储
  let binaryDataObj = project.dump_file(true, 0)
  let u8Array = binaryDataToUTF8(binaryDataObj)
  // let batch_data = {}
  // batch_data[new_project_snow_id] = { folder_name: getFolderName(targetNode.node_data.data.snow_id, new_project_gns, targetNode.node_data.data.isTeam), file_type: 'LAYOUT_FILE' }
  await normalFileCopyTo(all_data)
  // 先新建项目
  try {
    // await batchSaveDataFn(u8Array, batch_data)
    let batchSaveRes = await batchSaveDataFn_fileSize(u8Array, new_project_snow_id, "", 'LAYOUT_FILE', "");
    if (batchSaveRes.code === 200000) {
      // step2:组装新的file和cell
      let teamNode = {},
        userNode = {}
      if (targetNode.node_data.data.isTeam) {
        teamNode = {
          data: {
            snow_id: targetNode.node_data.data.snow_id,
            isTeam: true,
          },
        }
      } else {
        userNode = {
          data: {
            snow_id: '',
            isTeam: false,
          },
        }
      }

      for (let i in currentNode.childNodes) {
        let filechildNode = currentNode.childNodes[i]
        let parent_node = filechildNode.parent
        parent_node.data.snow_id = new_project_snow_id
        parent_node.data.gns = new_project_gns
        parent_node.data.isTeam = targetNode.node_data.data.isTeam
        parent_node.data.category_child = parent_node.data.isTeam ? '团队项目' : '个人项目'
        parent_node.parent = parent_node.data.isTeam ? teamNode : userNode
        let target_file_node = {
          form_copyName: filechildNode.data.name,
          node_data: parent_node,
        }
        let success = await fileCopyTo(filechildNode, target_file_node)
        if (!success) {
          return false
        }
      }
      return true
    } else {
      return false
    }
  } catch {
    return false
  }
}

// 文件复制到
export const fileCopyTo = async function (currentNode, targetNode) {
  console.log(currentNode, targetNode);
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: [],
    snow_team_id: '',
    accessor_type: '',
  }
  all_data.snow_team_id = targetNode.node_data.parent.data.snow_id
  all_data.accessor_type = targetNode.node_data.parent.data.isTeam ? 2 : 1

  // step1:重新生成新的file,并上传至对象存储
  let file_data = await getObjectData(currentNode.data.snow_id)

  let new_file_data = file_data.deep_copy(0)
  let new_file_snow_id = new_file_data.snow_id
  // 获取目标文件下所有名字集合
  let names = targetNode.node_data.childNodes.map(node => {
    return node.data.name
  })
  // 对比名字，改名
  new_file_data.name = newSymbolName(targetNode.form_copyName, names)
  const project_gns = targetNode.node_data.data.gns
  const new_file_gns = `${project_gns}/${new_file_snow_id}`
  let binaryDataObj = new_file_data.dump_file(true, 0)
  let u8Array = binaryDataToUTF8(binaryDataObj)
  // let batch_data = {}
  // batch_data[new_file_snow_id] = { folder_name: getFolderName(targetNode.node_data.parent.data.snow_id, new_file_gns, targetNode.node_data.parent.data.isTeam), file_type: currentNode.data.cell_type == 1 ? 'LAYOUT_FILE' : 'DIAGRAM_FILE' }
  // setp2:利用project的信息组装文件的gns
  let file_list = [
    {
      name: targetNode.form_copyName,
      snow_id: new_file_snow_id,
      gns: new_file_gns,
      parent_gns: targetNode.node_data.data.gns,
      parent_snow_id: targetNode.node_data.data.snow_id,
      type: currentNode.data.file_type,
    },
  ]
  // 先新建文件，否则单元内拿不到完整数据
  all_data.file_list = file_list

  await normalFileCopyTo(all_data)
  try {
    // await batchSaveDataFn(u8Array, batch_data)
    let batchSaveType = currentNode.data.file_type === 3 ? 'DIAGRAM_FILE' : 'LAYOUT_FILE'
    console.log(targetNode.node_data.data.snow_id);
    let batchSaveRes = await batchSaveDataFn_fileSize(u8Array, targetNode.node_data.data.snow_id, new_file_snow_id, batchSaveType, "");
    if (batchSaveRes.code === 200000) {
      //获取索引数据,得到所有顶层节点
      let topCellNodeIdList = []
      let cellIndexData = await getCellIndexData(currentNode.data.snow_id)
      if (cellIndexData === null) {
        cellIndexData = []
      }
      for (let i = 0; i < cellIndexData.length; i++) {
        if (getTopCellIndex(cellIndexData[i].snow_id, cellIndexData)) {
          topCellNodeIdList.push(cellIndexData[i].snow_id)
        }
      }

      if (currentNode.data.file_type === 1) {
        //复制文件图层列表
        let from_fileLayerobj = await getFileLayerListFn(currentNode.data.snow_id)
        let from_fileLayerList = JSON.parse(JSON.stringify(from_fileLayerobj.fileLayerDatas))
        for (let i = 0; i < from_fileLayerList.length; i++) {
          from_fileLayerList[i].id = Math.random().toString(36).substr(2, 5)
        }
        let to_fileLayerList = from_fileLayerList

        if (to_fileLayerList.length > 0) {
          await saveNewLayerFn(to_fileLayerList, new_file_gns)
        }
      }

      // step3:重新生成新的cell snow_id并上传cell到对象存储
      if (currentNode.childNodes.length > 0) {
        for (let i = 0; i < topCellNodeIdList.length; i++) {
          for (let j = 0; j < currentNode.childNodes.length; j++) {
            if (currentNode.childNodes[j].data.snow_id === topCellNodeIdList[i]) {
              let cellchildNode = currentNode.childNodes[j]
              cellchildNode.parent = currentNode
              let parent_node = { data: {}, childNodes: [], parent: { data: {} } }
              parent_node.data.name = targetNode.form_copyName
              parent_node.data.snow_id = new_file_snow_id
              parent_node.data.gns = new_file_gns
              parent_node.data.isTeam = targetNode.node_data.data.isTeam
              parent_node.data.category_child = parent_node.data.isTeam ? '个人文件' : '团队文件'
              parent_node.data.topId = targetNode.node_data.parent.data.snow_id
              parent_node.parent.data.snow_id = targetNode.node_data.data.snow_id
              parent_node.data.file_type = currentNode.data.file_type
              let target_cell_node_info = {
                form_copyName: cellchildNode.data.name,
                node_data: parent_node,
              }
              let success = await cellCopyTo(cellchildNode, target_cell_node_info)
              if (!success) {
                return false
              }
            }
          }
        }
      } else {
        let res = await getCellList(0, 500, currentNode.data.gns, currentNode.data.isTeam ? '团队cell' : '个人cell')
        if (res.code === 200000) {
          for (let i = 0; i < topCellNodeIdList.length; i++) {
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j]._source.snow_id === topCellNodeIdList[i]) {
                let cellchildNode = { data: { name: res.data.data[j]._source.name, snow_id: res.data.data[j]._source.snow_id, gns: res.data.data[j]._source.gns, isTeam: currentNode.data.isTeam } }
                cellchildNode.parent = currentNode

                let parent_node = { data: {}, childNodes: [], parent: { data: {} } }
                parent_node.data.name = targetNode.form_copyName
                parent_node.data.snow_id = new_file_snow_id
                parent_node.data.gns = new_file_gns
                parent_node.data.isTeam = targetNode.node_data.data.isTeam
                parent_node.data.category_child = parent_node.data.isTeam ? '个人文件' : '团队文件'
                parent_node.data.topId = targetNode.node_data.parent.data.snow_id
                parent_node.parent.data.snow_id = targetNode.node_data.data.snow_id
                parent_node.data.file_type = currentNode.data.file_type
                let target_cell_node_info = {
                  form_copyName: cellchildNode.data.name,
                  node_data: parent_node,
                }

                let success = await cellCopyTo(cellchildNode, target_cell_node_info)
                if (!success) {
                  return false
                }
              }
            }
          }
        }
      }
      return true
    } else {
      return false
    }
  } catch {
    return false
  }
}

// 单元复制到
export const cellCopyTo = async function (currentNode, targetNodeInfo) {
  // accessor_type:1、个人，2、团队
  let targetNode = targetNodeInfo.node_data //targetNode 目标文件节点
  const file_gns = targetNode.data.gns
  const file_snow_id = targetNode.data.snow_id
  const project_snow_id = targetNode.parent.data.snow_id
  let targetFileInfo = { data: { gns: file_gns, snow_id: file_snow_id }, childNodes: [] }
  let copyCellArr = []
  let new_cell = {}
  let recCells = []
  // let batch_data = {}
  let u8Array = []
  let cellBinaryDataObj = {}
  let cell_list = []
  let isOpenTab = false
  let topId = ''
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: [],
    snow_team_id: "",
    accessor_type: "",
  };
  let cellId_list = []
  let batchSaveType = ''

  //世杰方法
  if (!targetNodeInfo.hasOwnProperty('copyFromNode')) {
    topId = targetNode.data.topId
    let cell_data = await getOSCompleteCellData(currentNode.parent.parent.data.snow_id, currentNode.parent.data.snow_id, currentNode.data.snow_id, false)

    //先对数据的图层对照修改
    if (targetNodeInfo.node_data.data.file_type === 1) {
      let from_file_node = currentNode.parent

      //先改单元自身图层和目标图层
      cell_data = await fileLayerListChange_CellListLayerChange(from_file_node.data.snow_id, file_gns, cell_data)
    }
    if (cell_data.type.value === 5) {
      batchSaveType = 'LAYOUT_FILE'
      new_cell = await deepCopyRemaneCell(cell_data, currentNode, targetNode, false)
      recCells = new_cell.depend_cells(-1)
    } else if (cell_data.type.value === 1) {
      batchSaveType = 'DIAGRAM_FILE'
      new_cell = copyComponentCellFn(cell_data)
    } else if (cell_data.type.value === 2) {
      batchSaveType = 'DIAGRAM_FILE'
      new_cell = copyComponentCellFn(cell_data)
      recCells = new_cell.depend_symbol_schemas(-1)
    } else if (cell_data.type.value === 3) {
      batchSaveType = 'DIAGRAM_FILE'
      new_cell = copyComponentCellFn(cell_data)
      recCells = new_cell.depend_symbol_schemas(-1)
    }
    new_cell.name = targetNodeInfo.form_copyName
    copyCellArr = [new_cell, ...recCells]
  }
  //我的方法
  else {
    topId = targetNode.parent.parent.data.snow_id
    let res = await getCellList(0, 500, file_gns, targetNode.data.isTeam ? '团队cell' : '个人cell')

    if (res.code === 200000) {
      if (res.data.data.some(item => item._source.name === targetNodeInfo.form_copyName)) {
        bus.$emit('errDialog', i18n.t('messages.code_400045'))
        return 'err'
      } else {
        res.data.data.forEach(item => {
          targetFileInfo.childNodes.push({ data: { name: item._source.name, cell_type: item._source.types } })
        })
        let cell_data = await getOSCompleteCellData(currentNode.parent.parent.data.snow_id, currentNode.parent.data.snow_id, currentNode.data.snow_id, false)

        //先对数据的图层对照修改
        if (targetNodeInfo.node_data.data.file_type === 1) {
          let from_file_node = currentNode.parent

          //先改单元自身图层和目标图层
          cell_data = await fileLayerListChange_CellListLayerChange(from_file_node.data.snow_id, file_gns, cell_data)
          if (cell_data === 'err') {
            return 'err'
          } else {
            //判断目标文件是否打开
            let unclosedFileIdArr = []
            store.state.openedTab.forEach(item => {
              unclosedFileIdArr.push(item.route.split('/')[item.route.split('/').length - 2].split('=')[1])
            })
            if (unclosedFileIdArr.some(item => item === file_snow_id)) {
              isOpenTab = true
            }

            if (isOpenTab) {
              bus.$emit('getNewLayerList', file_snow_id)
            }

            if (targetNode.data.isTeam) {
              bus.$emit('layerUpdate', true)
            }
          }
        }

        if (cell_data.type.value === 5) {
          batchSaveType = 'LAYOUT_FILE'
          new_cell = await deepCopyRemaneCell(cell_data, currentNode, targetFileInfo, true)
          recCells = new_cell.depend_cells(-1)
        } else if (cell_data.type.value === 1) {
          batchSaveType = 'DIAGRAM_FILE'
          new_cell = deepCopyRemaneSymbol(cell_data, targetFileInfo)
        } else if (cell_data.type.value === 2) {
          batchSaveType = 'DIAGRAM_FILE'
          new_cell = deepCopyRemaneCapSymbol(cell_data, targetFileInfo)
          recCells = new_cell.depend_symbol_schemas(-1)
        } else if (cell_data.type.value === 3) {
          batchSaveType = 'DIAGRAM_FILE'
          new_cell = deepCopyRemaneSchema(cell_data, targetFileInfo)
          recCells = new_cell.depend_symbol_schemas(-1)
        }
        new_cell.name = targetNodeInfo.form_copyName
        copyCellArr = [new_cell, ...recCells]
      }
    }
  }
  // 获取索引数据
  let cellIndexData = await getCellIndexData(file_snow_id)
  if (cellIndexData === null) {
    cellIndexData = []
  }

  let file = await getObjectData(file_snow_id)
  for (let i = 0; i < copyCellArr.length; i++) {
    let cellIndexNew = { snow_id: copyCellArr[i].snow_id, cells: [] }
    let cells = []
    let cell_gns = `${file_gns}/${copyCellArr[i].snow_id}`
    if (copyCellArr[i].type.value === 5) {
      cell_list.push({
        name: copyCellArr[i].name,
        snow_id: copyCellArr[i].snow_id,
        gns: cell_gns,
        parent_gns: file_gns,
        parent_snow_id: file_snow_id,
        type: copyCellArr[i].js_obj.cellType,
      })
      let refs = copyCellArr[i].referens
      for (let j = 0; j < refs.length; j++) {
        if (refs[j].cell) {
          if (!cells.some(item => item.snow_id === refs[j].cell.snow_id)) {
            cells.push({ snow_id: refs[j].cell.snow_id })
          }
        }
      }
      file.add_cell(copyCellArr[i])
      // batch_data[copyCellArr[i].snow_id] = { folder_name: getFolderName(topId, cell_gns, targetNode.data.isTeam), file_type: 'LAYOUT_FILE' }
    } else if (copyCellArr[i].type.value === 1) {
      cell_list.push({
        name: copyCellArr[i].name,
        snow_id: copyCellArr[i].snow_id,
        gns: cell_gns,
        parent_gns: file_gns,
        parent_snow_id: file_snow_id,
        type: 4,
      })
      file.add_symbol(copyCellArr[i])
      // batch_data[copyCellArr[i].snow_id] = { folder_name: getFolderName(topId, cell_gns, targetNode.data.isTeam), file_type: 'SYMBOLS_FILE' }
    } else if (copyCellArr[i].type.value === 2) {
      cell_list.push({
        name: copyCellArr[i].name,
        snow_id: copyCellArr[i].snow_id,
        gns: cell_gns,
        parent_gns: file_gns,
        parent_snow_id: file_snow_id,
        type: 5,
      })
      if (copyCellArr[i].schema !== null) cells.push({ snow_id: copyCellArr[i].schema.snow_id })
      file.add_capsymbol(copyCellArr[i])
      // batch_data[copyCellArr[i].snow_id] = { folder_name: getFolderName(topId, cell_gns, targetNode.data.isTeam), file_type: 'SYMBOLS_FILE' }
    } else if (copyCellArr[i].type.value === 3) {
      cell_list.push({
        name: copyCellArr[i].name,
        snow_id: copyCellArr[i].snow_id,
        gns: cell_gns,
        parent_gns: file_gns,
        parent_snow_id: file_snow_id,
        type: 3,
      })
      let symbolIns = [...copyCellArr[i].symbol_ins, ...copyCellArr[i].capsymbol_ins]

      for (let j = 0; j < symbolIns.length; j++) {
        cells.push({ snow_id: symbolIns[j].symbol.snow_id })
      }
      file.add_schema(copyCellArr[i])
      // batch_data[copyCellArr[i].snow_id] = { folder_name: getFolderName(topId, cell_gns, targetNode.data.isTeam), file_type: 'DIAGRAM_FILE' }
    }
    cellId_list.push(copyCellArr[i].snow_id)
    cellIndexNew.cells = cells
    cellIndexData.push(cellIndexNew)
    let binaryDataObj = copyCellArr[i].dump_file(true, 0)
    cellBinaryDataObj = { ...cellBinaryDataObj, ...binaryDataObj }
  }

  // batch_data[file_snow_id] = { folder_name: getFolderName(topId, file_gns, targetNode.data.isTeam), file_type: 'LAYOUT_FILE' }
  let fileBinaryDataObj = file.dump_file(true, 0)
  fileBinaryDataObj = { ...fileBinaryDataObj, ...cellBinaryDataObj }
  u8Array = binaryDataToUTF8(fileBinaryDataObj)

  all_data.snow_team_id = topId
  all_data.accessor_type = targetNode.data.isTeam ? 2 : 1;
  all_data.cell_list = cell_list
  let res = await normalFileCopyTo(all_data);

  if (res.code === 200000) {
    await setFileParams(file_snow_id, cellIndexData)
    // await batchSaveDataFn(u8Array, batch_data)
    await batchSaveDataFn_fileSize(u8Array, project_snow_id, file_snow_id, batchSaveType, cellId_list);
    try {
      return true
    } catch {
      return false
    }
  } else {
    return false
  }
}

// 文件导入
export const fileImport = async function (LibraryFile, project_gns, file_name, file_type, topID, isTeam) {
  let file_snow_id = LibraryFile.snow_id
  let file_gns = `${project_gns}/${file_snow_id}`
  let project_snow_id = project_gns.split('/')[project_gns.split('/').length - 1]
  let file_list = [
    {
      name: file_name,
      snow_id: file_snow_id,
      gns: file_gns,
      parent_gns: project_gns,
      parent_snow_id: project_snow_id,
      type: 1,
    },
  ]
  let cellId_list = [];
  let all_file_data = {
    project_list: [],
    file_list: file_list,
    cell_list: [],
    snow_team_id: topID,
    accessor_type: isTeam ? 2 : 1,
  }
  try {
    let res1 = await normalFileCopyTo(all_file_data)
    if (res1.code !== 200000) {
      return false
    }
  } catch {
    return false
  }
  // 保存图层
  let cell_layers = LibraryFile.all_layers()
  let cell_layer_list = []
  for (let o = 0; o < cell_layers.size(); o++) {
    cell_layer_list.push(cell_layers.get(o))
  }
  cell_layer_list = Array.from(new Set(cell_layer_list))

  let all_layers = importLayerList(cell_layer_list)
  await saveNewLayerFn(all_layers, file_gns)
  let cell_list = []
  // let batch_data = {}
  // 文件的Library新建
  let file_binaryDataObj = LibraryFile.dump_file(true, 0)
  // let file_u8Array = binaryDataToUTF8(file_binaryDataObj);
  // batch_data[file_snow_id] = { folder_name: getFolderName(topID, file_gns, isTeam), file_type: 'LAYOUT_FILE' }

  // Cell的新建
  let cellIndexData = []

  for (let i in LibraryFile.children) {
    let cell_lib = LibraryFile.children[i]
    let cell_name = cell_lib.name
    let cell_snow_id = cell_lib.snow_id
    let cell_gns = `${file_gns}/${cell_snow_id}`
    cellId_list.push(cell_snow_id)
    cell_list.push({
      name: cell_name,
      snow_id: cell_snow_id,
      gns: cell_gns,
      parent_gns: file_gns,
      parent_snow_id: file_snow_id,
      type: 1,
    })
    let cell_origin_list = [LibraryFile.children[i]]

    let cell_refs = LibraryFile.children[i].depend_cells(-1)

    cell_origin_list = cell_origin_list.concat(cell_refs)

    let cells_ids = []
    if (cell_refs.length > 0) {
      for (let j in cell_refs) {
        cells_ids.push({ snow_id: cell_refs[j].snow_id })
      }
    }

    // if (cells_ids.length > 0) {
    //     cellIndexData.push({ snow_id: cell_snow_id, cells: cells_ids })
    // }
    cellIndexData.push({ snow_id: cell_snow_id, cells: cells_ids })

    let cell_cover_list = contrastCellDataByLayerNumber(all_layers, cell_origin_list)

    for (let l = 0; l < cell_cover_list.length; l++) {
      let cell_binaryDataObj = cell_cover_list[l].dump_file(true, 0)
      file_binaryDataObj = Object.assign(file_binaryDataObj, cell_binaryDataObj)
    }
  }
  let file_u8Array = binaryDataToUTF8(file_binaryDataObj)

  // for (let k in file_u8Array) {
  //   batch_data[file_u8Array[k].snow_id] = { folder_name: getFolderName(topID, `${file_gns}/${file_u8Array[k].snow_id}`, isTeam), file_type: 'LAYOUT_FILE' }
  // }
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: cell_list,
    snow_team_id: topID,
    accessor_type: isTeam ? 2 : 1,
  }
  await normalFileCopyTo(all_data)
  try {
    await setFileParams(file_snow_id, cellIndexData)
    // await batchSaveDataFn(file_u8Array, batch_data)
    await batchSaveDataFn_fileSize(file_u8Array, project_snow_id, file_snow_id, file_type, cellId_list);
    try {
      return true
    } catch {
      return false
    }
  } catch {
    return false
  }
}

// 获取完整的单元数据
export const getCompleteLibrary = async function (fileID, file_name, layerMap) {
  let cellIndex = await getCellIndexData(fileID)
  let all_cells = []
  cellIndex.forEach(cell_data => {
    all_cells.push(cell_data.snow_id)
    let cells_id = cell_data.cells.map(item => {
      return item.snow_id
    })
    all_cells = all_cells.concat(cells_id)
  })
  all_cells = Array.from(new Set(all_cells))
  let all_cells_data = await batchGetOSData(all_cells, true)
  let lib = new Kernel.Library(file_name)
  for (let i = 0; i < all_cells_data.length; i++) {
    let cell = all_cells_data[i]
    let new_cell = convertCellDataByLayerId(cell, layerMap)
    lib.add_cell(new_cell)
  }
  return lib
}

// 文件导出
export const fileExport = async function (libraryFile) {
  libraryFile.write_gds(libraryFile.name)
  let data_u8 = Kernel.FS.readFile(libraryFile.name)
  downloadFileData(data_u8, libraryFile.name, 'gds')
}

//导出dxf
export const dxfExport = async function (libraryFile) {
  let dxf = new Kernel.DxfConvertor()
  let cells = libraryFile.cells

  let layerConfig = new Kernel.LayerConfig()
  dxf.write_dxf(libraryFile.name, cells, layerConfig)
  let data_u8 = Kernel.FS.readFile(libraryFile.name)
  downloadFileData(data_u8, libraryFile.name, 'dxf')
}

// 原理图导入
export const schemaImport = async function (schemaFile, project_gns, file_name, file_type, topID, isTeam) {
  let file_snow_id = schemaFile.snow_id
  let file_gns = `${project_gns}/${file_snow_id}`
  let project_snow_id = project_gns.split('/')[project_gns.split('/').length - 1]
  let file_list = [
    {
      name: file_name,
      snow_id: file_snow_id,
      gns: file_gns,
      parent_gns: project_gns,
      parent_snow_id: project_snow_id,
      type: 3,
    },
  ]
  let cellId_list = []
  let all_file_data = {
    project_list: [],
    file_list: file_list,
    cell_list: [],
    snow_team_id: topID,
    accessor_type: isTeam ? 2 : 1,
  }
  try {
    let res1 = await normalFileCopyTo(all_file_data)
    if (res1.code !== 200000) {
      return false
    }
  } catch {
    return false
  }
  let cell_list = []
  // let batch_data = {}
  // 文件的Library新建
  let schema_binaryDataObj = {}
  schema_binaryDataObj[file_snow_id] = schemaFile.save_project_file()
  // batch_data[file_snow_id] = { folder_name: getFolderName(topID, file_gns, isTeam), file_type: 'DIAGRAM_FILE' }

  // 1:版图，2：器件，3：原理图，4：符号, 5:封装符号
  let symbol_type = {
    Schema: 3,
    Symbol: 4,
    CapSymbol: 5,
  }
  let cellIndexData = []
  for (let i in schemaFile.children) {
    let schema_lib = schemaFile.children[i]
    let schema_name = schema_lib.name
    let schema_snow_id = schema_lib.snow_id
    let schema_gns = `${file_gns}/${schema_snow_id}`
    cellId_list.push(schema_snow_id)
    cell_list.push({
      name: schema_name,
      snow_id: schema_snow_id,
      gns: schema_gns,
      parent_gns: file_gns,
      parent_snow_id: file_snow_id,
      type: symbol_type[schemaFile.children[i].$$?.ptrType.name.replace('*', '')],
    })
    let schema_refs = []
    if (schema_lib.$$?.ptrType.name !== 'Symbol*') {
      schema_refs = schema_lib.depend_symbol_schemas(1)
    }
    let all_schema_list = [schema_lib]
    all_schema_list = all_schema_list.concat(schema_refs)
    let cells_ids = []
    if (schema_refs.length > 0) {
      for (let j in schema_refs) {
        cells_ids.push({ snow_id: schema_refs[j].snow_id })
      }
    }
    cellIndexData.push({ snow_id: schema_snow_id, cells: cells_ids })
    for (let l = 0; l < all_schema_list.length; l++) {
      let cell_binaryDataObj = {}

      cell_binaryDataObj[all_schema_list[l].snow_id] = all_schema_list[l].save_project_file()
      schema_binaryDataObj = Object.assign(schema_binaryDataObj, cell_binaryDataObj)
    }
  }
  let schema_u8Array = binaryDataToUTF8(schema_binaryDataObj)
  // for (let k in schema_u8Array) {
  //   batch_data[schema_u8Array[k].snow_id] = { folder_name: getFolderName(topID, `${file_gns}/${schema_u8Array[k].snow_id}`, isTeam), file_type: 'DIAGRAM_FILE' }
  // }
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: cell_list,
    snow_team_id: topID,
    accessor_type: isTeam ? 2 : 1,
  }
  await normalFileCopyTo(all_data)
  try {
    await setFileParams(file_snow_id, cellIndexData)
    // await batchSaveDataFn(schema_u8Array, batch_data)
    await batchSaveDataFn_fileSize(schema_u8Array, project_snow_id, file_snow_id, "DIAGRAM_FILE", cellId_list);
    try {
      return true
    } catch {
      return false
    }
  } catch {
    return false
  }
}

// 原理图导出
export const schemaExport = async function (schemaFile) {
  let schemaBinary = {}
  schemaBinary[schemaFile.snow_id] = schemaFile.save_project_file()
  let data_u8 = binaryDataToUTF8(schemaBinary)[0]['data_u8']
  downloadFileData(data_u8, schemaFile.name, 'oqpro')
}

// cell对象转换gdstk对象
export const fileKernelCell2Gdstk = function (cell) {
  // let dump_u8_bin = cell.dump_file(true, 0);

  // let dump_u8 = binaryDataToUTF8(dump_u8_bin)

  // downloadFileData(dump_u8[0].data_u8, cell.name, 'gds')
  // cell构建转换lib
  let cell_refs = cell.depend_cells(-1)
  let lib = new Kernel.Library(cell.name)
  lib.add_cell(cell)
  for (let i = 0; i < cell_refs.length; i++) {
    lib.add_cell(cell_refs[i])
  }
  lib.write_gds(lib.name)
  let data_u8 = Kernel.FS.readFile(lib.name)
  // 转换gdstk对象=
  window.QGdstk.FS.writeFile(lib.name, data_u8)
  let library = window.QGdstk.read_gds(lib.name)
  return library
}

// cell转GDS的u8
export const cellToGDSu8 = function (cell) {
  // cell构建转换lib
  let cell_refs = cell.depend_cells(-1)
  let lib = new Kernel.Library(cell.name)
  lib.add_cell(cell)
  for (let i = 0; i < cell_refs.length; i++) {
    lib.add_cell(cell_refs[i])
  }
  lib.write_gds(lib.name)
  let data_u8 = Kernel.FS.readFile(lib.name)
  return data_u8
}

//拷贝完整的单元数据,但不能区分单元类型
export const copyComponentCellFn = function (cell) {
  let cellName = cell.name
  let cellType = cell.type.value
  let newCellList = []
  if (cell.$$?.ptrType.name.replace('*', '') == 'Symbol') {
    newCellList = [cell.deep_copy(0)]
  } else {
    newCellList = cell.deep_copy_logic()
  }

  let index = newCellList.findIndex(item => item.name === cellName && item.type.value === cellType)

  let newCell = newCellList.splice(index, 1)[0]
  newCell = AssembleCellFn(newCell, newCellList)
  return newCell
}

//针对当前画板的单元重命名方法
// export const currentDrawBoard_deepCopy_remane_cell = function (cell, isDeepCopy) {

//     let associatedCellList = []
//     let cellId = ''
//     if (isDeepCopy) {
//         let newTargetCell = copyComponentCellFn(cell)
//         cellId = newTargetCell.snow_id
//         associatedCellList = newTargetCell.depend_cells(-1)
//         associatedCellList.push(newTargetCell)
//     } else {
//         cellId = cell.snow_id
//         associatedCellList = cell.depend_cells(-1)
//         associatedCellList.push(cell)
//     }

//     for (let j = 0; j < associatedCellList.length; j++) {
//         associatedCellList[j].js_obj.cellType = 2
//     }
//     let currentFileNode = store.state.activeNode.parent
//     let currentCellNameList = []
//     currentFileNode.childNodes.forEach(item => {

//         currentCellNameList.push(item.data.name)
//     })

//     let newCellList = []
//     newCellList = recRenameCell(newCellList, associatedCellList, currentCellNameList, 1)
//     let index = newCellList.findIndex(item => item.snow_id === cellId)
//     let newCell = newCellList.splice(index, 1)[0]

//     if (isDeepCopy) {
//         newCell = AssembleCellFn(newCell, newCellList)
//     }
//     return newCell
// }

export const currentDrawBoard_deepCopy_remane_cell = function (cell, isDeepCopy, isDeeps = true) {
  if (!cell) {
    return
  }
  let associatedCellList = []
  let cellId = ''
  let currentFileNode = store.state.activeNode.parent
  let currentCellNameList = []
  currentFileNode.childNodes.forEach(item => {
    currentCellNameList.push(item.data.name)
  })

  if (!isDeeps) {
    cellId = cell.snow_id
    associatedCellList.push(cell)
  } else {
    if (isDeepCopy) {
      let newTargetCell = copyComponentCellFn(cell)
      cellId = newTargetCell.snow_id
      associatedCellList = newTargetCell.depend_cells(-1)
      associatedCellList.push(newTargetCell)
    } else {
      cellId = cell.snow_id
      associatedCellList = cell.depend_cells(-1)
      associatedCellList.push(cell)
    }
  }

  for (let j = 0; j < associatedCellList.length; j++) {
    associatedCellList[j].js_obj.cellType = 2
  }
  let newCellList = []
  newCellList = recRenameCell(newCellList, associatedCellList, currentCellNameList, 1)
  let index = newCellList.findIndex(item => item.snow_id === cellId)
  let newCell = newCellList.splice(index, 1)[0]

  if (isDeepCopy) {
    newCell = AssembleCellFn(newCell, newCellList)
  }

  return newCell
}

//调用单元重命名方法
export const renameCellName = function (cellId, targetCellList, currentFileNode) {
  let currentCellNameList = [] //当前打开文件的单元名列表
  let newCellList = [] //改名后的新单元列表

  if (currentFileNode.childNodes.length) {
    currentFileNode.childNodes.forEach(item => {
      currentCellNameList.push(item.data.name)
    })
  }

  newCellList = recRenameCell(newCellList, targetCellList, currentCellNameList, 1)

  let index = newCellList.findIndex(item => item.snow_id === cellId)
  let newCell = newCellList.splice(index, 1)[0]

  newCell = AssembleCellFn(newCell, newCellList)
  return newCell
}

//递归重命名
function recRenameCell(newCellList, targetCellList, currentCellNameList, type) {
  let length = targetCellList.length
  if (length > 0) {
    let newName = newSymbolName(targetCellList[0].name, currentCellNameList)
    if (type === 1) {
      // for (let i = 0; i < length; i++) {
      //     let refs = targetCellList[i].referens

      //     for (let j = 0; j < refs.length; j++) {
      //         if (refs[j].name === targetCellList[0].name) {
      //             refs[j].name = newName

      //         }
      //     }
      // }
      currentCellNameList.push(newName)
      targetCellList[0].name = newName
    } else if (type === 3) {
      if (targetCellList[0].type.value !== 3) {
        currentCellNameList.push(newName)
        // let r2 = /\((.+?)\)/g;
        // if (newName.match(r2)) {
        // let current_index = Number(newName.match(r2)[0].split('(')[1].split(')')[0]);
        // let newShortName = targetCellList[0].short_name.split('(')[0]
        // targetCellList[0].short_name = `${newShortName}(${current_index})`
        // }
        if (targetCellList[0].is_official_symbol) {
          // let reg = /\([^()]*\)/g;
          // let arr = newName.match(reg);

          // if (arr) {
          //     targetCellList[0].short_name = `${targetCellList[0].short_name}${arr[0]}`
          // }
          let r2 = /\((.+?)\)/g
          let suffix = ''
          let newShortName = ''
          if (newName.match(r2)) {
            let splitNameList = newName.split('')
            let index = splitNameList.findIndex(item => item == '(')
            splitNameList.splice(0, index)

            suffix = splitNameList.join('')
          }
          if (targetCellList[0].short_name.match(r2)) {
            let splitNameList = newName.split('')
            let index = splitNameList.findIndex(item => item == '(')
            let shortName = splitNameList.splice(0, index).join('')

            newShortName = `${shortName}${suffix}`
          } else {
            newShortName = `${targetCellList[0].short_name}${suffix}`
          }
          targetCellList[0].short_name = newShortName
        } else {
          targetCellList[0].short_name = newName
        }
      }
      targetCellList[0].name = newName
    }

    newCellList.push(targetCellList[0])
    targetCellList.splice(0, 1)
    if (targetCellList.length > 0) {
      recRenameCell(newCellList, targetCellList, currentCellNameList, type)
    }
  }

  return newCellList
}

//调用原理图重命名方法
export const renameSchemaName = function (cellId, targetCellList, currentFileNode) {
  let oldSchemaList = [] //旧原理图数据
  let oldSymbolList = [] //旧符号数据
  let oldCapSymbolList = [] //旧封装符号数据
  let newSchemaList = [] //新原理图数据
  let newSymbolList = [] //新符号数据
  let newCapSymbolList = [] //新封装符号数据
  let currentSchemaNameList = [] //当前打开文件的原理图名列表
  let currentSymbolNameList = [] //当前打开文件的符号名列表
  let currentCapSymbolNameList = [] //当前打开文件的封装符号名列表
  let newCellList = [] //改名后的新单元列表

  for (let i = 0; i < targetCellList.length; i++) {
    if (targetCellList[i].type.value === 1) {
      oldSymbolList.push(targetCellList[i])
    } else if (targetCellList[i].type.value === 2) {
      oldCapSymbolList.push(targetCellList[i])
    } else if (targetCellList[i].type.value === 3) {
      oldSchemaList.push(targetCellList[i])
    }
  }
  currentFileNode.childNodes.forEach(item => {
    if (item.data.cell_type === 3) {
      currentSchemaNameList.push(item.data.name)
    } else if (item.data.cell_type === 4) {
      currentSymbolNameList.push(item.data.name)
    } else if (item.data.cell_type === 5) {
      currentCapSymbolNameList.push(item.data.name)
    }
  })
  newSchemaList = oldSchemaList.length > 0 ? recRenameCell(newSchemaList, oldSchemaList, currentSchemaNameList, 3) : []
  newSymbolList = oldSymbolList.length > 0 ? recRenameCell(newSymbolList, oldSymbolList, currentSymbolNameList, 3) : []
  newCapSymbolList = oldCapSymbolList.length > 0 ? recRenameCell(newCapSymbolList, oldCapSymbolList, currentCapSymbolNameList, 3) : []

  newCellList = [...newSchemaList, ...newSymbolList, ...newCapSymbolList]
  let index = newCellList.findIndex(item => item.snow_id === cellId)
  let newCell = newCellList.splice(index, 1)[0]
  newCell = AssembleCellFn(newCell, newCellList)

  return newCell
}

//重命名原理图内部数据
export const renameSchemaNameCopy = function (targetCellList, currentFileNode) {
  let oldSchemaList = [] //旧原理图数据
  let oldSymbolList = [] //旧符号数据
  let oldCapSymbolList = [] //旧封装符号数据
  let newSchemaList = [] //新原理图数据
  let newSymbolList = [] //新符号数据
  let newCapSymbolList = [] //新封装符号数据
  let currentSchemaNameList = [] //当前打开文件的原理图名列表
  let currentSymbolNameList = [] //当前打开文件的符号名列表
  let currentCapSymbolNameList = [] //当前打开文件的封装符号名列表

  for (let i = 0; i < targetCellList.length; i++) {
    if (targetCellList[i].type.value === 1) {
      oldSymbolList.push(targetCellList[i])
    } else if (targetCellList[i].type.value === 2) {
      oldCapSymbolList.push(targetCellList[i])
    } else if (targetCellList[i].type.value === 3) {
      oldSchemaList.push(targetCellList[i])
    }
  }
  currentFileNode.childNodes.forEach(item => {
    if (item.data.cell_type === 3) {
      currentSchemaNameList.push(item.data.name)
    } else if (item.data.cell_type === 4) {
      currentSymbolNameList.push(item.data.name)
    } else if (item.data.cell_type === 5) {
      currentCapSymbolNameList.push(item.data.name)
    }
  })
  newSchemaList = oldSchemaList.length > 0 ? recRenameCell(newSchemaList, oldSchemaList, currentSchemaNameList, 3) : []
  newSymbolList = oldSymbolList.length > 0 ? recRenameCell(newSymbolList, oldSymbolList, currentSymbolNameList, 3) : []
  newCapSymbolList = oldCapSymbolList.length > 0 ? recRenameCell(newCapSymbolList, oldCapSymbolList, currentCapSymbolNameList, 3) : []
}

//对单元进行深拷贝和重命名
export const deepCopyRemaneCell = async function (cell, currentNode, targetFileNode, isReName = true) {
  let newTargetCell = copyComponentCellFn(cell)
  let newTargetCellId = newTargetCell.snow_id
  let associatedCellList = newTargetCell.depend_cells(-1)
  associatedCellList.push(newTargetCell)

  if (currentNode.data.cell_type) {
    currentNode.parent.childNodes
    for (let i = 0; i < currentNode.parent.childNodes.length; i++) {
      for (let j = 0; j < associatedCellList.length; j++) {
        if (currentNode.parent.childNodes[i].data.name === associatedCellList[j].name) {
          associatedCellList[j].js_obj.cellType = currentNode.parent.childNodes[i].data.cell_type
        }
      }
    }
  } else {
    let res2 = await getCellList(0, 500, currentNode.parent.data.gns, currentNode.data.isTeam ? '团队cell' : '个人cell')

    for (let i = 0; i < res2.data.data.length; i++) {
      for (let j = 0; j < associatedCellList.length; j++) {
        if (res2.data.data[i]._source.name === associatedCellList[j].name) {
          associatedCellList[j].js_obj.cellType = res2.data.data[i]._source.types
        }
      }
    }
  }
  if (isReName) {
    let newCell = renameCellName(newTargetCellId, associatedCellList, targetFileNode)

    return newCell
  } else {
    let index = associatedCellList.findIndex(item => item.snow_id === newTargetCell.snow_id)
    let newCell = associatedCellList.splice(index, 1)[0]
    newCell = AssembleCellFn(newCell, associatedCellList)

    return newCell
  }
}

//对原理图进行深拷贝和重命名
export const deepCopyRemaneSchema = function (schema, targetFileNode) {
  let newTargetSchema = copyComponentCellFn(schema)
  let newTargetSchemaId = newTargetSchema.snow_id
  let associatedCellList = newTargetSchema.depend_symbol_schemas(-1)
  associatedCellList.push(newTargetSchema)
  let newSchema = renameSchemaName(newTargetSchemaId, associatedCellList, targetFileNode)
  return newSchema
}

//对符号进行深拷贝和重命名
export const deepCopyRemaneSymbol = function (symbol, targetFileNode) {
  let newTargetSymbol = symbol.deep_copy(0)
  let newTargetSymbolId = newTargetSymbol.snow_id
  let newSymbol = renameSchemaName(newTargetSymbolId, [newTargetSymbol], targetFileNode)
  return newSymbol
}

//对封装符号进行深拷贝和重命名
export const deepCopyRemaneCapSymbol = function (capSymbol, targetFileNode) {
  let newTargetCapSymbol = copyComponentCellFn(capSymbol)

  let newTargetCapSymbolId = newTargetCapSymbol.snow_id
  let associatedCellList = newTargetCapSymbol.depend_symbol_schemas(-1)
  associatedCellList.push(newTargetCapSymbol)

  let newCapSymbol = renameSchemaName(newTargetCapSymbolId, associatedCellList, targetFileNode)
  return newCapSymbol
}

//单元复制时,先和自身图层列表比较,修改layerNumber,再和目标文件图层列表比较,修改id
export const fileLayerListChange_CellListLayerChange = async function (from_file_id, to_file_gns, cell) {
  let to_file_id = to_file_gns.split('/')[to_file_gns.split('/').length - 1]
  let from_fileLayerObj = await getFileLayerListFn(from_file_id)
  let from_fileLayerList = from_fileLayerObj.fileLayerDatas

  let to_fileLayerObj = await getFileLayerListFn(to_file_id)
  let to_fileLayerList = to_fileLayerObj.fileLayerDatas

  let cellList = cell.depend_cells(-1)
  cellList.push(cell)
  //先和自身所在图层列表id比较,更换正确layerNumber
  cellList = await contrastCellDataByLayerId(from_fileLayerList, cellList)

  let depend_layerNumbers = cell.depend_layers()
  let from_cell_layerNumbers = [...new Set([...cell.layers, ...depend_layerNumbers])]

  let to_file_layerNumbers = to_fileLayerList.map(item => item.layerNumber)
  let newLayerNumberlist = []
  for (let j = 0; j < from_cell_layerNumbers.length; j++) {
    if (!to_file_layerNumbers.some(item => item === from_cell_layerNumbers[j])) {
      newLayerNumberlist.push(from_cell_layerNumbers[j])
    }
  }

  newLayerNumberlist = sortLayerNumber(newLayerNumberlist)

  let newLayerList = []
  for (let i = 0; i < newLayerNumberlist.length; i++) {
    let id = Math.random().toString(36).substr(2, 5)
    let layer = JSON.parse(JSON.stringify(from_fileLayerList.filter(item => item.layerNumber === newLayerNumberlist[i])[0]))
    layer.id = id
    newLayerList.push(layer)
  }

  to_fileLayerList = [...to_fileLayerList, ...newLayerList]

  if (to_fileLayerList.length > 256) {
    bus.$emit('errDialog', i18n.t('messages.layerNumberLimit2'))
    return 'err'
  } else {
    //和目标图层列表对比,修改单元图层id数据
    cellList = contrastCellDataByLayerNumber(to_fileLayerList, cellList)

    if (newLayerList.length > 0) {
      await saveNewLayerFn(newLayerList, to_file_gns)
    }
    cell = cellList.filter(item => item.snow_id === cell.snow_id)[0]
    return cell
  }
}

//数据复制/另存为时与目标图层数据layerNumber对比,修改本单元id
export const contrastCellDataByLayerNumber = function (fileLayerList, cellList) {
  let layerNumberMap = {}
  fileLayerList.forEach(item => {
    layerNumberMap[item.layerNumber] = item.id
  })

  let size = cellList.length
  let newCellList = []
  for (let i = 0; i < size; i++) {
    let newCell = convertCellDataByLayerNumber(cellList[i], layerNumberMap)
    newCellList.push(newCell)
  }

  return newCellList
}

//与图层id比较,更换单元中的图层数据
export const contrastCellDataByLayerId = async function (fileLayerList, cellList) {
  let layerMap = {}
  fileLayerList.forEach(item => {
    layerMap[item.id] = item.layerNumber
  })

  let size = cellList.length

  let newCellList = []
  for (let i = 0; i < size; i++) {
    let newCell = convertCellDataByLayerId(cellList[i], layerMap)
    newCellList.push(newCell)
  }

  return newCellList
}

//根据图层数据重构器件数据，包括删除不存在的图层，图层id重命名 layerMap=>{id1:num1,id2:num2}
export const convertCellDataByLayerId = function (cell, layerMap) {
  if (!cell) return
  const QUAD_TREE = cell.js_obj.QUAD_TREE //四叉树
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length

  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    if (path.id.length > 1) {
      const id = path.id[0]
      path.id = [id, id]
    }
    if (layerMap[path.id[0]] == undefined) {
      //不存在图层id说明图层被删除
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(path)
      }
      cell.remove_flexpath(path)
      disposeGraphics(path.js_obj.graphics)
      continue
    }
    const layers = []
    path.id.forEach(id => {
      layers.push(layerMap[id])
    })
    path.layers = layers
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]
    if (layerMap[polygon.id] == undefined) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(polygon)
      }
      cell.remove_polygon(polygon)
      disposeGraphics(polygon.js_obj.graphics)
      continue
    }

    polygon.layer = layerMap[polygon.id]
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]
    if (layerMap[label.id] == undefined) {
      if (QUAD_TREE) {
        QUAD_TREE.removeNode(label)
      }
      cell.remove_label(label)
      disposeGraphics(label.js_obj.graphics)
      continue
    }
    label.layer = layerMap[label.id]
  }

  return cell
}

function disposeGraphics(grahics) {
  if (grahics) {
    for (const key in grahics) {
      if (grahics[key].geometry) {
        grahics[key].geometry.dispose()
      }
    }
  }
}

const convertCellDataByLayerNumber = function (cell, layerMap) {
  if (!cell) return
  const flexpaths = cell.flexpaths
  const flexpaths_size = flexpaths.length
  const polygons = cell.polygons
  const polygon_size = polygons.length
  const labels = cell.labels
  const labels_size = labels.length
  for (let i = 0; i < flexpaths_size; i++) {
    const path = flexpaths[i]
    const id = []
    path.layers.forEach(layer => {
      id.push(layerMap[layer])
    })

    path.id = id
  }
  for (let i = 0; i < polygon_size; i++) {
    const polygon = polygons[i]

    polygon.id = layerMap[polygon.layer]
  }
  for (let i = 0; i < labels_size; i++) {
    const label = labels[i]

    label.id = layerMap[label.layer]
  }

  return cell
}

const getFileLayerListFromTable = function (fileId) {
  let index = store.state.fileLayerListTable.findIndex(item => item.fileId === fileId)
  if (index === -1) {
    return null
  } else {
    return store.state.fileLayerListTable[index]
  }
}

const getFileLayerListFn = async function (fileId) {
  let fileLayerListObj = getFileLayerListFromTable(fileId)

  if (fileLayerListObj === null) {
    fileLayerListObj = await getDBFileLayerData(fileId)
  }

  return fileLayerListObj
}

const getDBFileLayerData = async function (fileId) {
  let fileLayerListObj = {}
  let fileLayerDataRes = await getLayerInfo(fileId)
  if (fileLayerDataRes.code === 200000) {
    let fileLayerDatas = []
    let size = fileLayerDataRes.data.data.length
    for (let i = 0; i < size; i++) {
      fileLayerDatas.push(
        new Layer(
          fileLayerDataRes.data.data[i].id,
          fileLayerDataRes.data.data[i].color,
          fileLayerDataRes.data.data[i].shape,
          fileLayerDataRes.data.data[i].border,
          fileLayerDataRes.data.data[i].borderColor,
          fileLayerDataRes.data.data[i].layerName,
          fileLayerDataRes.data.data[i].layerNumber
        )
      )
    }

    fileLayerListObj = {
      fileId,
      fileLayerDatas,
    }
  }
  return fileLayerListObj
}

class Layer {
  constructor(id, color, shape, border, borderColor, layerName, layerNumber) {
    this.id = id
    this.color = color
    this.shape = shape
    this.border = border
    this.borderColor = borderColor
    this.image = ''
    this.layerName = layerName
    this.layerNumber = layerNumber
    this.hide = false
    this.lock = false
    this.isExport = true
    this.showNameInput = false
    this.showNumInput = false
    this.GDSType = 0
  }
}

const saveNewLayerFn = async function (newLayerArr, file_gns) {
  let saveLayerList = []

  for (let i = 0; i < newLayerArr.length; i++) {
    let saveNewLayerData = {
      hide: newLayerArr[i].hide,
      lock: newLayerArr[i].lock,
      color: newLayerArr[i].color,
      shape: newLayerArr[i].shape,
      border: newLayerArr[i].border,
      isExport: newLayerArr[i].isExport,
      layerName: newLayerArr[i].layerName,
      borderColor: newLayerArr[i].borderColor,
      id: newLayerArr[i].id,
      layerNumber: newLayerArr[i].layerNumber,
      GDSType: newLayerArr[i].GDSType,
    }
    saveLayerList.push(saveNewLayerData)
  }

  let res = await addLayerInfo(file_gns, saveLayerList)
  return res
}

export const importLayerList = function (layerNumberList) {
  let layerPointer = 0
  let newLayer = {}
  let newLayerList = []
  let newId = ''
  let defaultLayerDatas = createNewFileLayerList()

  for (let i = 0; i < defaultLayerDatas.length; i++) {
    newId = Math.random().toString(36).substr(2, 5)
    newLayer = new Layer(newId, defaultLayerDatas[i].color, defaultLayerDatas[i].shape, defaultLayerDatas[i].border, defaultLayerDatas[i].borderColor, defaultLayerDatas[i].layerName, defaultLayerDatas[i].layerNumber)
    newLayerList.push(newLayer)
  }
  for (let i = 0; i < layerNumberList.length; i++) {
    newId = Math.random().toString(36).substr(2, 5)
    if (!defaultLayerDatas.some(item => item.layerNumber === layerNumberList[i])) {
      newLayer = new Layer(
        newId,
        defaultNewLayerDatas[layerPointer].color,
        defaultNewLayerDatas[layerPointer].shape,
        defaultNewLayerDatas[layerPointer].border,
        defaultNewLayerDatas[layerPointer].borderColor,
        `layer${layerNumberList[i]}`,
        layerNumberList[i]
      )
      newLayerList.push(newLayer)
      if (layerPointer < 9) {
        layerPointer++
      } else {
        layerPointer = 0
      }
    }
  }

  return newLayerList
}

//图层编号排序
const sortLayerNumber = function (layerNumberList) {
  layerNumberList.sort((a, b) => {
    return a - b
  })

  return layerNumberList
}

export const permissionsJudgmentFn = async function (gns) {
  let isOperable = false
  let permissionRes = await getPermission(gns)
  if (permissionRes.code === 200000) {
    if (permissionRes.data.permission == '可删除、编辑') {
      //先判断是否是可编辑权限
      isOperable = true
      return {
        isOperable,
        teamRule: permissionRes.data.permission,
      }
    } else {
      bus.$emit('errDialog', i18n.t('messages.userCellPermission'))
      bus.$emit('ending', true)
    }
  } else {
    bus.$emit('ending', true)
    bus.$emit('errDialog', i18n.t('messages.code_' + permissionRes.code))
  }
  return {
    isOperable,
    teamRule: '',
  }
}

//获取当前tab栏,获取是否有该单元已被只读模式打开
export const isCellOpenedReadOnly = function (snow_id) {
  let isOpendedInReadOnly = false
  if (store.state.openedTab.length) {
    for (const i in store.state.openedTab) {
      let gnsCellId = store.state.openedTab[i].route.split('=')[store.state.openedTab[i].route.split('=').length - 1].split('/')[1]

      if (gnsCellId === snow_id && store.state.openedTab[i].isReadOnly) {
        bus.$emit('errDialog', i18n.t('messages.fileTip9'))
        isOpendedInReadOnly = true
        break
      } else {
        isOpendedInReadOnly = false
      }
    }
  } else {
    isOpendedInReadOnly = false
  }
  return isOpendedInReadOnly
}

//判断是否已有人已打开单元,判断锁定状态
export const isCellOpened = async function (cellId) {
  let lock = ''
  let lockCellRes = await selectLockCell(cellId)

  if (lockCellRes.code === 200000) {
    if (lockCellRes.message == 'not lock') {
      lock = false
    } else if (lockCellRes.message == 'locked') {
      //被锁定时判断是否是当前已打开过的画板
      if (store.state.openedTab.length) {
        let isOpened = false
        for (const i in store.state.openedTab) {
          let gnsCellId = store.state.openedTab[i].route.split('=')[store.state.openedTab[i].route.split('=').length - 1].split('/')[1]
          if (gnsCellId === cellId) {
            isOpened = true
            break
          }
        }
        if (isOpened) {
          lock = false
        } else {
          lock = true
          bus.$emit('errDialog', i18n.t('messages.fileTip11'))
          bus.$emit('ending', true)
        }
      } else {
        lock = true
        bus.$emit('errDialog', i18n.t('messages.fileTip11'))
        bus.$emit('ending', true)
      }
    }
  }
  return lock
}

//判断cell是否为空
export const isCellEmpty = function (cell) {
  let isEmpty = true
  if (cell.type.value === 5) {
    if (cell.flexpaths.length > 0 || cell.labels.length > 0 || cell.polygons.length > 0 || cell.referens.length > 0) {
      isEmpty = false
    }
  } else if (cell.type.value === 3) {
    if (cell.capsymbol_ins.length > 0 || cell.cp_lines.length > 0 || cell.labels.length > 0 || cell.pins.length > 0 || cell.symbol_ins.length > 0) {
      isEmpty = false
    }
  } else {
    isEmpty = false
  }

  return isEmpty
}

//获取封装符号由哪个原理图封装而来
export const getCapSymbolIdByEncapsFrom = async function (cellIndexData, fileGns, cellId, isTeam) {
  let capSymbolIdList = []
  let schema_snow_id = cellId
  let recIdArr = []
  let index = cellIndexData.findIndex(item => item.snow_id === schema_snow_id)

  //递归获取引用的单元id
  for (let i = 0; i < cellIndexData.length; i++) {
    if (cellIndexData[i].cells.some(item => item.snow_id === schema_snow_id)) {
      recIdArr.push(cellIndexData[i].snow_id)
    }
  }

  if (recIdArr.length > 0) {
    let cellListRes = await getCellList(0, 500, fileGns, isTeam ? '团队cell' : '个人cell')
    if (cellListRes.code === 200000) {
      cellListRes.data.data.forEach(item => {
        if (recIdArr.some(id => id === item._source.snow_id) && item._source.types === 5) {
          capSymbolIdList.push(item._source.snow_id)
        }
      })
    }
  }

  return capSymbolIdList
}

//获取单元被那些单元调用
export const cellcalledBy = function (cellIndexData, fileId, cellId) {
  let parentOfCellIdList = []
  //先获取前端缓存的所有数据,在其中查找
  let cells = batchGetCellFromTableByFileId(fileId)

  let cellList = cells.map(item => item.cell)
  //过滤自己
  let index = cellList.findIndex(item => item.snow_id === cellId)
  cellList.splice(index, 1)

  for (let i = 0; i < cellList.length; i++) {
    let children = []
    if (cellList[i].type.value === 5) {
      children = cellList[i].depend_cells(1)
    } else if (cellList[i].type.value === 1) {
      children = []
    } else {
      children = cellList[i].depend_symbol_schemas(1)
    }
    for (let j = 0; j < children.length; j++) {
      if (children[j].snow_id === cellId) {
        parentOfCellIdList.push(cellList[i].snow_id)
      }
    }
  }

  parentOfCellIdList = [...new Set(parentOfCellIdList)]
  //缓存的数据中查找完了再在索引数据中查找
  for (let x = 0; x < cellIndexData.length; x++) {
    if (!parentOfCellIdList.some(item => item.snow_id === cellIndexData[x].snow_id)) {
      if (cellIndexData[x].cells.some(item => item.snow_id === cellId)) {
        parentOfCellIdList.push(cellIndexData[x].snow_id)
      }
    }
  }

  //返回所有调用了该单元的单元的idList

  return parentOfCellIdList
}

export const getOriginGDSData = async function (nodeId) {
  let urlData = await getDiagramFile_api({ file_snow_id: [nodeId] })
  if (urlData.code === 200000) {
    if (urlData.data.length === 0) {
      return []
    } else {
      let data_u8 = await getObjectDataFn(urlData.data[0].file_url)
      return data_u8
    }
  }
}

//批量获取原理图文件数据
export const getFileDataFn = async function (schemaSymbolArr) {
  let SchemaFileStreamArr = []
  let SchemaFileDataArr = []
  let schemaSymbolSnowIdArr = []
  schemaSymbolArr.forEach(item => {
    schemaSymbolSnowIdArr.push(item[0])
  })

  let urlData = await getDiagramFile_api({ file_snow_id: schemaSymbolSnowIdArr })
  for (let i in urlData.data) {
    let data_u8 = await getObjectDataFn(urlData.data[i].file_url)
    // downloadFileData(data_u8, `schema-${i}`, 'txt')
    SchemaFileStreamArr.push(data_u8)
  }

  // downloadFileData(SchemaFileStreamArr, 'schema', 'txt')
  return [SchemaFileStreamArr, SchemaFileDataArr]
}

let rename_project_name = function (name) {
  let reg = /\((.+?)\)/g
  let name_number_list = name.match(reg)
  if (!name_number_list) {
    return name
  } else {
    let name_pre = name.split(name.match(reg)[0])[0]
    let name_number = name_number_list.pop()
    let current_index = Number(name_number.split('(')[1].split(')')[0])
    let current_new_name = `${name_pre}${name_number_list.join('')}`
    return current_new_name
  }
}

//用于数据迁移，请求对象存储数据，将u8转为kernel对象
export const tranformToNewPeoject = async function (cell_node) {
  let current_expand_data = []
  let all_files_node = cell_node.parent.parent.childNodes
  let topID = cell_node.parent.parent.parent.data.snow_id
  let isTeam = cell_node.parent.parent.parent.data.isTeam
  let file_type = cell_node.parent.data.file_type
  // 先新建一个项目
  let all_data = {
    project_list: [],
    file_list: [],
    cell_list: [],
    snow_team_id: '',
    accessor_type: '',
  }
  all_data.snow_team_id = topID
  all_data.accessor_type = isTeam ? 2 : 1
  // step1:组装新的project
  let project = new Kernel.Project()
  // 改名
  let name_pre = rename_project_name(cell_node.parent.parent.data.name)

  project.name = name_pre
  // project.name = `${cell_node.parent.parent.data.name}(2.4版本)`;
  let new_project_snow_id = project.snow_id
  let new_project_gns = cell_node.parent.parent.parent.data.isTeam ? `gns://${cell_node.parent.parent.parent.data.snow_id}/${new_project_snow_id}` : `gns://${new_project_snow_id}`
  let project_data = {
    name: project.name,
    snow_id: new_project_snow_id,
    gns: new_project_gns,
    parent_gns: cell_node.parent.parent.parent.data.gns,
    parent_snow_id: cell_node.parent.parent.parent.data.snow_id,
    type: '',
  }

  all_data.project_list.push(project_data)
  // setp2:新建项目至对象存储
  let binaryDataObj = project.dump_file(true, 0)
  let u8Array = binaryDataToUTF8(binaryDataObj)
  // let batch_data = {}
  // batch_data[new_project_snow_id] = { folder_name: getFolderName(topID, new_project_gns, isTeam), file_type: 'LAYOUT_FILE' }
  // 先新建项目
  await normalFileCopyTo(all_data)
  try {
    // await batchSaveDataFn(u8Array, batch_data)
    let batchSaveRes = await batchSaveDataFn_fileSize(u8Array, new_project_snow_id, "", "LAYOUT_FILE", "");
    if (batchSaveRes.code === 200000) {
      for (let i in all_files_node) {
        let file_lib
        let file_node = all_files_node[i]
        let file_snow_id = file_node.data.snow_id

        if (file_node.data.file_type == 1) {
          // 获取整个文件对象的

          let data_u8 = await getOriginGDSData(file_snow_id)
          Kernel.FS.writeFile(file_snow_id, data_u8)
          file_lib = new Kernel.Library(file_snow_id)
          file_lib.read_gds(file_snow_id)
          file_lib.name = file_node.data.name

          await fileImport(file_lib, new_project_gns, file_lib.name, 'LAYOUT_FILE', topID, isTeam)
        } else {
          let oldSchemaFile = new Kernel.OldSchemaFile()
          oldSchemaFile.name = file_node.data.name
          let schemaSymbolArr = []
          if (file_node.childNodes.length > 0) {
            schemaSymbolArr = file_node.childNodes.map(cell_node => {
              return [cell_node.data.snow_id, cell_node.data.name]
            })
          } else {
            schemaSymbolArr = await completionCellNode(file_node)
          }

          let SchemaFileStreamArr = []
          let SchemaFileDataArr = []
          let schemaFileConvertor = new Kernel.SchemaFileConvertor()
          if (schemaSymbolArr.length > 0) {
            let newDataArr = await getFileDataFn(schemaSymbolArr)

            SchemaFileStreamArr = newDataArr[0]
            SchemaFileDataArr = newDataArr[1]
            if (SchemaFileStreamArr.length > 0) {
              // [[], [], []]
              oldSchemaFile.assemble(SchemaFileStreamArr)
            }
            SchemaFileDataArr.forEach(item => {
              oldSchemaFile.add_schema(item)
            })

            file_lib = schemaFileConvertor.convert_schemafile(oldSchemaFile)
          }
          // schemaFile, project_gns, file_name, file_type, topID, isTeam
          if (file_lib) {
            await schemaImport(file_lib, new_project_gns, file_lib.name, 'DIAGRAM_FILE', topID, isTeam)
          }
        }
        if (file_node.data.file_type == file_type) {
          let topData = {
            topId: topID,
            isTeam: isTeam,
          }
          let fileData = {
            name: file_lib.name,
            snow_id: file_lib.snow_id,
            gns: `${new_project_gns}/${file_lib.snow_id}`,
            isTeam: isTeam,
          }
          let projectData = {
            gns: new_project_gns,
            isTeam: isTeam,
            name: project.name,
            snow_id: new_project_snow_id,
          }
          let category = '文件'
          current_expand_data = [{ category: category, topData, projectData, fileData }]
        }
      }
    } else {
      return false
    }
  } catch (error) {
    return false
  }

  return current_expand_data
}

const completionCellNode = async function (file_node) {
  try {
    let res = await getCellList(0, 500, file_node.data.gns, file_node.data.isTeam ? '团队cell' : '个人cell')

    let child_cells_arr = []
    if (res.code === 200000) {
      for (let j = 0; j < res.data.data.length; j++) {
        let cellchildNode = { data: { name: res.data.data[j]._source.name, snow_id: res.data.data[j]._source.snow_id, gns: res.data.data[j]._source.gns, isTeam: file_node.data.isTeam } }
        cellchildNode.parent = file_node
        child_cells_arr.push([cellchildNode.data.snow_id, cellchildNode.data.name])
      }
    }
    return child_cells_arr
  } catch (error) {
    return []
  }
}

// //删除单元的批量画板更新
export const deleteCellFn = async function (node) {
  let refreshCellList = []
  let fileId = node.parent.data.snow_id
  let cellId = node.data.snow_id
  let cellIndexData = await getCellIndexData(fileId)
  let cellIndex = cellIndexData.findIndex(item => item.snow_id === cellId)
  cellIndexData.splice(cellIndex, 1)
  for (let i = 0; i < cellIndexData.length; i++) {
    let index = cellIndexData[i].cells.findIndex(item => item.snow_id === cellId)
    if (index !== -1) {
      cellIndexData[i].cells.splice(index, 1)
    }
  }
  await setFileParams(fileId, cellIndexData)

  //删除cell表中的缓存数据
  store.commit('removeFromCellTable', cellId)
  //删除单元的同时,删除已有缓存单元中对应引用的数据
  let cellObjList = batchGetCellFromTableByFileId(fileId)

  //如果是版图文件中的删除,需要将对应ref重新指向null
  if (node.parent.data.file_type === 1) {
    let cellList = cellObjList.map(item => item.cell)
    redirectRef(cellList, cellId)
    refreshCellList = cellList
  }
  //如果是原理图文件中的删除 
  else {
    //删除原理图,查找封装符号
    if (node.data.cell_type === 3) {
      let capSymbolObjList = cellObjList.filter(item => item.cell.type.value === 2)
      let capSymbolList = capSymbolObjList.map(item => item.cell)
      redirectSymbol(capSymbolList, cellId)
      refreshCellList = capSymbolList
    }
    // 删除符号/封装符号,查找原理图
    else {
      let schemaObjList = cellObjList.filter(item => item.cell.type.value === 3)
      let schemaList = schemaObjList.map(item => item.cell)
      redirectSchema(schemaList, cellId)
      refreshCellList = schemaList
    }

  }

  return refreshCellList
}

//cell中重定向第一层ref中的指向
function redirectRef(cellList, delCellId) {
  let cellLength = cellList.length
  for (let i = 0; i < cellLength; i++) {
    let referens = cellList[i].referens
    let refLength = referens.length
    for (let j = 0; j < refLength; j++) {
      if (referens[j].cell == null) {
        continue
      } else if (referens[j].cell_snow_id === delCellId) {
        // referens[j].cell = null
        cellList[i].remove_reference(referens[j])
      }
    }
  }
  bus.$emit("reRenderReplaceBoard")
}

//删除原理图重定向capsymbol中的schema
function redirectSymbol(capSymbolList, delSchemaId) {
  let capLength = capSymbolList.length
  for (let i = 0; i < capLength; i++) {
    if (capSymbolList[i].schema == null) {
      continue
    } else if (capSymbolList[i].schema.snow_id === delSchemaId) {
      capSymbolList[i].schema.delete_logic()
    }
  }
  bus.$emit("reRenderReplaceBoard")
}

//删除符号封装符号重定向schema的ins的symbol
function redirectSchema(schemaList, delSymbolId) {
  let schemaLength = schemaList.length
  for (let i = 0; i < schemaLength; i++) {
    let ins = [...schemaList[i].symbol_ins, ...schemaList[i].capsymbol_ins]
    let insLength = ins.length
    for (let j = 0; j < insLength; j++) {
      if (ins[j].symbol == null) {
        continue
      } else if (ins[j].symbol.snow_id === delSymbolId) {
        ins[j].symbol.delete_logic()
      }
    }
  }
  bus.$emit("reRenderReplaceBoard")
}