import { Matrix2d } from './matrix2d'
import { distanceToLineSegment } from './point-to-line-segment'
import { deepClone } from '../utils'
import { FillShader } from './qeda-stage'
import { refresh } from 'less'
import { isAdsorb } from '../pixi/qeda-pixi-data'
import { JSONLoader } from 'three.js'
import { decompressPropStr } from '../../utils/getcell'
const ELEM = 'foo'

export const QedaGraphicType = {
  PATH: 0,
  RECT: 1,
  POLYGON: 2,
  CIRCLE: 3,
  LABEL: 4,
  KEYPOINT: 5,
  TEXT: 6,
  RULER: 7,
  CELL3DAREA: 8,
  CELL3DCUTBOX: 9,
}

export const QedaGraphicFillType = {
  F0: [10000, 0, 0, 0, 0, 0, 0, 1], //填充
  F1: [1, 20, 0, 0, 45, 0, 0, 1], //斜线1
  F2: [5, 15, 0, 0, 45, 0, 0, 1], //斜线2
  F3: [1, 20, 0, 0, 135, 0, 0, 1], //斜线3
  F4: [1, 20, 0, 0, 45, 1, 0, 1], //斜网格
  F5: [2, 20, 5, 10, 45, 0, 0, 1], //间隙线
  F6: [5, 15, 10, 5, 45, 0, 0, 1], //循环方格点
  F7: [2, 6, 0, 0, 45, 1, 1, 1], //点阵密集
  F8: [15, 25, 0, 0, 45, 1, 1, 1], //点阵
  F9: [1, 20, 10, 10, 45, 1, 0, 1], //循环十字
  F10: [2, 40, 20, 20, 0, 1, 0, 1], //循环十字2
  F11: [6, 40, 10, 10, 45, 1, 0, 1], //特殊纹理1
  F12: [5, 20, 40, 10, 45, 1, 0, 2], //特殊纹理2
  F13: [2, 10, 20, 10, 45, 1, 0, 1], //特殊纹理3
  F14: [4, 20, 20, 20, 45, 1, 0, 1], //特殊纹理4
}

//图层面板填充缩略图参数
export const QedaGraphicFillTypeImg = {
  F0: [10000, 0, 0, 0, 0, 0, 0, 1], //填充
  F1: [5, 20, 0, 0, 45, 0, 0, 1], //斜线1
  F2: [10, 15, 0, 0, 45, 0, 0, 1], //斜线2
  F3: [5, 20, 0, 0, 135, 0, 0, 1], //斜线3
  F4: [1, 20, 0, 0, 45, 1, 0, 1], //斜网格
  F5: [4, 20, 5, 10, 45, 0, 0, 1], //间隙线
  F6: [5, 15, 10, 5, 45, 0, 0, 1], //循环方格点
  F7: [2, 6, 0, 0, 45, 1, 1, 1], //点阵密集
  F8: [15, 25, 0, 0, 45, 1, 1, 1], //点阵
  F9: [1, 20, 10, 10, 45, 1, 0, 1], //循环十字
  F10: [2, 40, 20, 20, 0, 1, 0, 2], //循环十字2
  F11: [6, 40, 10, 10, 45, 1, 0, 2], //特殊纹理1
  F12: [5, 20, 40, 10, 45, 1, 0, 2], //特殊纹理2
  F13: [2, 10, 20, 10, 45, 1, 0, 1], //特殊纹理3
  F14: [4, 20, 20, 20, 45, 1, 0, 1], //特殊纹理4
}

//图层面板边框缩略图参数
export const QedaGraphicBorderType = {
  B1: [0, 0, 0, 0],
  B2: [15, 9, 15, 9],
  B3: [6, 0, 0, 6],
  B4: [3, 3, 3, 3],
  B5: [1.5, 3, 1.5, 3],
}

//图层面板边框缩略图参数
export const QedaGraphicBorderTypeImg = {
  B1: [0, 0, 0, 0],
  B2: [5, 3, 5, 3],
  B3: [2, 0, 0, 2],
  B4: [1, 1, 1, 1],
  B5: [0.5, 1, 0.5, 1],
}
export class Transform {
  constructor(position = [0, 0], scale = [1, 1], rotation = 0, rotation_old = 0) {
    this.position = position //平移
    this._scale = scale //缩放
    this.rotation = rotation //旋转 角度
    this.rotation_old = rotation_old //旋转
  }
  copyTransform() {
    return new Transform([this.position[0], this.position[1]], [this._scale[0], this._scale[1]], this.rotation, this.rotation_old)
  }
  resetTransform() {
    this.position = [0, 0] //平移
    this._scale = [1, 1] //缩放
    this.rotation = 0 //旋转
    this.rotation_old = this.rotation //旋转
  }

  setRotationOld() {
    //旋转基于上次的rotation
    this.rotation_old = this.rotation
  }

  translate(x, y) {
    this.position = [x, y]
  }

  rotate(angle) {
    //围绕某个坐标旋转
    if (this.type === QedaGraphicType.CIRCLE) return
    this.rotation = angle
  }

  scale(x, y) {
    this._scale[0] = x
    this._scale[1] = y
  }

  scaleX(x) {
    this._scale[0] = x
  }

  scaleY(y) {
    this._scale[1] = y
  }
}

let QEDA_ID = 0

export class QedaLibrary {
  constructor(fileName, fileID, exist = false) {
    this.fileName = fileName
    this.fileID = fileID
    this.cells = {}
    if (!exist) {
      this.QLIB = new QGdstk.Library(this.fileName, 0.000001, 1e-9) //Lib根据名字新建
      this.QLIB.write_gds(this.fileID) //写入文件系统
    } else {
      //gdstk qlib新建

      // alert('获取后台版图GDS文件')
      this.QLIB = window.QGdstk.read_gds(this.fileID)
    }
    this.parseGdstkLib()
  }
  //更新图形隐藏锁定信息
  updateHideAndLock() {
    function updateStatus(obj) {
      setHideAndLock(obj.layer, obj)
    }
    Object.values(this.cells).forEach(cell => {
      cell.loopCell(updateStatus, cell)
    })
  }
  write() {
    this.QLIB.write_gds(this.fileID)
  }
  //重命名cell
  reNameCell(old_name, new_name) {
    let target = this.cells[old_name]
    if (target) {
      // let res = this.QLIB.cells.filter(cell => cell.name === target.QCELL.name)
      // if (res.length) {
      //   res[0].name = new_name
      // }
      target.name = new_name
      target.QCELL.name = new_name
    }
  }

  //添加cell 新建空cell
  addCell(name) {
    let cell = new QedaCell(name)
    this.cells[name] = cell
    cell.QCELL.dependencies(true)
    this.QLIB.add(cell.QCELL)
  }

  //添加已存在cell
  addExistCell(cell) {
    cell.dependencies(true)
    this.QLIB.add(cell)
    let qedaCell = this.generateCell(cell)
    this.cells[cell.name] = qedaCell
    //延时更新cell refs
    setTimeout(() => (this.cells[cell.name].references = this.generateCellRefs(cell)))
    return qedaCell
  }

  //添加器件返回器件引用盒子
  addDevice(targetCell, cells, rotation = 0, slef = false) {
    if (!slef) {
      let names = []
      for (let i = 0; i < cells.length; i++) {
        names.push(cells[i].name)
        this.addExistCell(cells[i])
      }
      this.updateCellsReferences(names)
    }

    const new_ref = new QedaReference()
    new_ref.name = targetCell.name
    new_ref.cell = this.cells[targetCell.name]
    new_ref.QREF = new QGdstk.Reference(targetCell, [0, 0], 0, 1, false, 1, 1, null)
    let points = targetCell.bounding_box()
    let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
    let box = new QedaGraphic() //QedaGraphics.drawPolygon([[0, 0]])
    box.type = QedaGraphicType.POLYGON
    box.hide = false
    box.lock = false
    new_ref.box = box
    box.isRefBox = true
    box.reference = new_ref
    box.setRefBoxPoints(rect.get_points())
    box.rotation = rotation
    box.updateShape()
    box.preAdd = true
    return box
  }

  updateCellsReferences(cellNames) {
    for (let i = 0; i < cellNames.length; i++) {
      let name = cellNames[i]

      this.cells[name].references = this.generateCellRefs(this.cells[name].QCELL)
    }
  }
  //删除cell
  deleteCell(name) {
    let target = this.cells[name]
    if (!target) {
      return
    }
    delete this.cells[name]
    Object.values(this.cells).forEach(cell => {
      let len = cell.references.length
      for (let i = 0; i < len; i++) {
        const ref = cell.references[i]
        if (ref?.cell === target) {
          cell.QCELL.remove(ref.QREF)
          cell.references.splice(i--, 1)
        }
      }
    })
    let cells = this.QLIB.cells

    let deleteCell = []
    for (let i = 0; i < cells.length; i++) {
      if (cells[i].name === name) {
        deleteCell.push(cells[i])
      }
    }
    this.QLIB.remove(deleteCell)
  }

  //向指定cell添加reference
  addReferencesToCell(cellName, cells, references) {
    for (let cell of cells) {
      this.cells[cell.name] = this.generateCell(cell)
    }
    this.QLIB.add(cells)
    let target = this.cells[cellName]
    for (let cell of cells) {
      this.cells[cell.name].references = this.generateCellRefs(cell)
    }

    let refBox = []
    for (let reference of references) {
      let ref = this.generateReferences(reference)
      target.references.push(ref)
      refBox.push(ref.box)
    }
    target.QCELL.add(references)

    return refBox
  }

  //将指定cell另存为器件
  getCellQlib(cellName, fileName) {
    let newFile = new QGdstk.Library(fileName, 0.000001, 1e-9) //Lib根据名字新建
    let targetCell = this.cells[cellName].QCELL
    let cells = targetCell.dependencies(true)
    newFile.add(cells)
    newFile.add(targetCell)
    return newFile
  }

  //gdstkLib => QedaLibrary
  parseGdstkLib() {
    this.parseGdstkCells(this.QLIB.cells)
  }
  //gdstkCells => cells
  parseGdstkCells(gdsCells) {
    console.time('ParseGdstkCells')
    let cells = {}
    for (let cell of gdsCells) {
      cells[cell.name] = this.generateCell(cell)
    }
    console.timeEnd('ParseGdstkCells')
    this.cells = cells

    console.time('ParseGdstkReferences')
    for (let cell of gdsCells) {
      this.cells[cell.name].references = this.generateCellRefs(cell)
    }
    console.timeEnd('ParseGdstkReferences')
  }

  //QgdstkCell => QedaCell
  generateCell(cell) {
    const new_cell = new QedaCell(cell.name)
    new_cell.name = cell.name
    new_cell.QCELL = cell
    // new_cell.aabb = cell.bounding_box()
    new_cell.bounding_box()

    //非引用cell
    const path_length = cell.paths.length
    const paths = cell.paths
    const polygons_length = cell.polygons.length
    const polygons = cell.polygons
    const labels_length = cell.labels.length
    const labels = cell.labels
    for (let i = 0; i < path_length; i++) {
      let path = paths[i]
      let w = path.get_gds_property(0)
      let r = path.get_gds_property(1)
      if (w) {
        w = JSON.parse(w)
      } else {
        w = 0
      }
      if (r) {
        r = JSON.parse(r)
      } else {
        r = 0
      }
      const obj = QedaGraphics.drawPath(path.get_points(), false, w, r)
      obj.layer = path.layers[0]
      setHideAndLock(obj.layer, obj)
      obj.QDATA = path
      new_cell.paths.push(obj)
    }
    for (let i = 0; i < polygons_length; i++) {
      let polygon = polygons[i]
      let circle_center = polygon.get_gds_property(0)
      if (circle_center && Array.isArray(JSON.parse(circle_center))) {
        //圆
        let center = JSON.parse(circle_center) //中心点坐标
        let r = JSON.parse(polygon.get_gds_property(1)) //半径
        const obj = QedaGraphics.drawEllipse(center[0], center[1], r[0], r[1])
        obj.layer = polygon.layer
        setHideAndLock(obj.layer, obj)
        obj.QDATA = polygon
        obj.updateShape()
        new_cell.ellipses.push(obj)
      } else {
        //多边形
        const obj = QedaGraphics.drawPolygon(polygon.get_points())
        obj.layer = polygon.layer
        setHideAndLock(obj.layer, obj)
        obj.QDATA = polygon
        new_cell.polygons.push(obj)
      }
    }
    for (let i = 0; i < labels_length; i++) {
      let label = labels[i]
      if (!label.get_gds_property(0)) {
        label.set_gds_property(0, JSON.stringify(30)) //字体大小默认30
      }
      let layer = label.layer
      // if (layer === 0) {
      //   //图层0 判断关键点
      //   // label.anchor = 'e'
      //   label.set_gds_property(1, JSON.stringify(true)) //设置关键点属性
      // }
      const obj = QedaGraphics.drawLabel(getLabelBox(label), false, label)
      obj.layer = layer

      setHideAndLock(obj.layer, obj)
      //   obj.QDATA = label
      new_cell.labels.push(obj)
    }
    return new_cell
  }

  generateCellRefs(cell) {
    const result = []
    const ref_length = cell.references.length
    const references = cell.references
    for (let i = 0; i < ref_length; i++) {
      result.push(this.generateReferences(references[i]))
    }
    return result
  }

  //QgdstkRef => QedaRef
  generateReferences(ref) {
    const new_ref = new QedaReference()
    new_ref.name = ref.cell.name
    new_ref.origin = ref.origin
    new_ref.magnification = ref.magnification
    new_ref.x_reflection = ref.x_reflection ? -1 : 1
    new_ref.rotation = (ref.rotation * 180) / Math.PI //弧度->角度
    new_ref.QREF = ref
    let points = ref.cell.bounding_box()
    if (!points)
      points = [
        [0, 0],
        [0, 0],
      ]
    let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
    const box = new QedaGraphic()
    box.type = QedaGraphicType.POLYGON
    new_ref.box = box //QedaGraphics.drawPolygon([[0, 0]])
    new_ref.box.hide = false
    new_ref.box.lock = false
    new_ref.box.isRefBox = true
    new_ref.box.reference = new_ref
    new_ref.box.setRefBoxPoints(rect.get_points())
    new_ref.cell = this.cells[ref.cell.name]
    return new_ref
  }

  //修改图层编号
  changeLayerNumber(froms, to) {
    // let record = { objs: [], from: [], to: [] }
    Object.values(this.cells).forEach(cell => {
      let res = cell.changeLayer(froms, to)
      // record.objs.push(...res.objs)
      // record.from.push(...res.from)
      // record.to.push(...res.to)
    })
    // return record
  }
}

export function anchorTran(anchor, len, pt) {
  // canvaskit字体在右上角，按照此位置转换到对应的8个方位
  // let dpi = 96 // dpi: 一般电脑为96
  // let px = (pt * dpi) / 72 // 转为像素大小
  let tranX = len * pt * 0.6
  let tranY = 1 * pt
  if (anchor == 'o') {
    return { x: tranX / 2, y: tranY / 2 }
  }
  if (anchor == 'e') {
    return { x: tranX, y: tranY / 2 }
  }
  if (anchor == 's') {
    return { x: tranX / 2, y: tranY }
  }
  if (anchor == 'w') {
    return { x: tranX, y: tranY / 2 }
  }
  if (anchor == 'n') {
    return { x: tranX / 2, y: 0 }
  }
  if (anchor == 'ne') {
    return { x: 0, y: 0 }
  }
  if (anchor == 'se') {
    return { x: 0, y: tranY }
  }
  if (anchor == 'nw') {
    return { x: tranX, y: 0 }
  }
  if (anchor == 'sw') {
    return { x: tranX, y: tranY }
  }
  return { x: 0, y: 0 }
}

export class QedaCell {
  constructor(name) {
    this.name = name
    this.paths = []
    this.polygons = []
    this.ellipses = []
    this.references = []
    this.rulers = []
    this.cell3DArea = []
    this.cell3DCutBox = []
    this.labels = []
    this.file_ref = null
    this.aabb = null
    this.area = 0
    this.hide = false
    this.lock = false
    this.QCELL = new QGdstk.Cell(name) //qgdstk
  }

  //计算包围盒
  bounding_box() {
    this.aabb = this.QCELL.bounding_box()
    this.area = this.QCELL.area()
    if (!this.aabb) {
      this.aabb = [
        [0, 0],
        [0, 0],
      ]
      this.area = 0
    }
    return this.aabb
  }
  //循环图形和第一层引用
  loopCell(func, cell, depth = false) {
    // if (!cell) return
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const labels_length = cell.labels.length
    const ref_length = cell.references.length
    for (let i = 0; i < path_length; i++) {
      func(cell.paths[i])
    }
    for (let i = 0; i < polygons_length; i++) {
      func(cell.polygons[i])
    }
    for (let i = 0; i < ellipses_length; i++) {
      func(cell.ellipses[i])
    }
    for (let i = 0; i < labels_length; i++) {
      func(cell.labels[i])
    }
    for (let i = 0; i < ref_length; i++) {
      // func(cell.references[i].box)
      if (depth) {
        this.loopCell(func, cell.references[i].cell, depth)
      }
    }
  }

  loopCellGraphics(func) {
    const path_length = this.paths.length
    const polygons_length = this.polygons.length
    const ellipses_length = this.ellipses.length
    const labels_length = this.labels.length
    for (let i = 0; i < path_length; i++) {
      func(this.paths[i])
    }
    for (let i = 0; i < polygons_length; i++) {
      func(this.polygons[i])
    }
    for (let i = 0; i < ellipses_length; i++) {
      func(this.ellipses[i])
    }
    for (let i = 0; i < labels_length; i++) {
      func(this.labels[i])
    }
  }

  setPreCut(flag) {
    function setCut(obj) {
      obj.preCut = flag
    }
    this.loopCellGraphics(setCut)
  }
  //添加图形
  add(obj) {
    if (obj.isRefBox) {
      this.references.push(obj.reference)
      this.QCELL.add(obj.reference.QREF)
    } else if (obj.type === QedaGraphicType.PATH) {
      // obj.QDATA = new QGdstk.FlexPath(obj.shape, 0)
      obj.QDATA = new QGdstk.FlexPath(obj.shape, 0, 0, 'round', 'flush', 0, null, 1e-2, true, true, 0, 0)
      obj.updateQDATA()
      this.QCELL.add(obj.QDATA)
      this.paths.push(obj)
    } else if (obj.type === QedaGraphicType.POLYGON || obj.type === QedaGraphicType.RECT) {
      obj.QDATA = new QGdstk.Polygon(obj.shape)
      obj.updateQDATA()
      this.QCELL.add(obj.QDATA)
      this.polygons.push(obj)
    } else if (obj.type === QedaGraphicType.CIRCLE) {
      obj.QDATA = new QGdstk.Polygon(obj.getGdsData().get_points())
      obj.updateQDATA()
      this.QCELL.add(obj.QDATA)
      obj.updateShape()
      this.ellipses.push(obj)
    } else if (obj.type === QedaGraphicType.RULER) {
      this.rulers.push(obj)
    } else if (obj.type === QedaGraphicType.LABEL) {
      if (!obj.QDATA) {
        obj.QDATA = new QGdstk.Label('Label', [0, 0], 'sw', 0, 1, false, 1, 0)
        QDATA.set_gds_property(0, JSON.stringify(30))
        obj.setPoints()
      }
      obj.updateQDATA()
      this.QCELL.add(obj.QDATA)
      this.labels.push(obj)
    } else if (obj.type === QedaGraphicType.KEYPOINT) {
      this.labels.push(obj)
    } else if (obj.type === QedaGraphicType.CELL3DAREA) {
      this.cell3DArea.push(obj)
    } else if (obj.type === QedaGraphicType.CELL3DCUTBOX) {
      this.cell3DCutBox.push(obj)
    }
  }

  //添加gdstk对象
  addGdsData(objs) {
    const objs_length = objs.length
    const addObjs = []
    for (let i = 0; i < objs_length; i++) {
      let obj = objs[i]
      if (obj.constructor.name === 'Polygon') {
        const new_obj = QedaGraphics.drawPolygon(obj.get_points())
        new_obj.layer = obj.layer
        new_obj.QDATA = obj
        this.polygons.push(new_obj)
        addObjs.push(new_obj)
      } else if (obj.constructor.name === 'FlexPath') {
        const new_obj = QedaGraphics.drawPath(obj.get_points())
        new_obj.layer = obj.layers[0]
        new_obj.QDATA = obj
        this.paths.push(new_obj)
        addObjs.push(new_obj)
      } else if (obj.constructor.name === 'Label') {
        let layer = obj.layer
        const new_obj = QedaGraphics.drawLabel(getLabelBox(obj), false, obj)
        new_obj.layer = layer
        this.labels.push(new_obj)
        addObjs.push(new_obj)
      }
      this.QCELL.add(obj)
    }
    return addObjs
  }

  //参数化器件更新清空线，多边形，圆，标签
  clear() {
    //let refboxs = [] //this.references.map(ref => ref.box)
    let res = [...this.paths, ...this.polygons, ...this.ellipses, ...this.labels]
    this.paths.forEach(p => this.QCELL.remove(p.QDATA))
    this.polygons.forEach(p => this.QCELL.remove(p.QDATA))
    this.ellipses.forEach(p => this.QCELL.remove(p.QDATA))
    this.labels.forEach(p => this.QCELL.remove(p.QDATA))
    this.paths = []
    this.polygons = []
    this.ellipses = []
    this.labels = []
    // this.references = []
    return res
  }

  clearPolygons() {
    let res = [...this.paths, ...this.polygons, ...this.ellipses]
    // this.paths.forEach(p => this.QCELL.remove(p.QDATA))
    // this.polygons.forEach(p => this.QCELL.remove(p.QDATA))
    // this.ellipses.forEach(p => this.QCELL.remove(p.QDATA))
    this.paths = []
    this.polygons = []
    this.ellipses = []
    return res
  }

  //删除gdstk对象
  removeGdsData(objs) {
    const objs_length = objs.length
    const deleteObjs = []
    for (let j = 0; j < objs_length; j++) {
      let obj = objs[j]
      if (obj.constructor.name === 'Polygon') {
        const polygons_length = this.polygons.length
        for (let i = 0; i < polygons_length; i++) {
          if (this.polygons[i].QDATA.$$.ptr == obj.$$.ptr) {
            deleteObjs.push(this.polygons[i])
            this.polygons.splice(i, 1)
            break
          }
        }
      } else if (obj.constructor.name === 'FlexPath') {
        const path_length = this.paths.length
        for (let k = 0; k < path_length; k++) {
          if (this.paths[k].QDATA.$$.ptr == obj.$$.ptr) {
            deleteObjs.push(this.paths[k])
            this.paths.splice(k, 1)
            break
          }
        }
      }
      this.QCELL.remove(obj)
    }
    // this.QCELL.remove(objs)
    return deleteObjs
  }
  //删除图形
  delete(obj, cell = this) {
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    const ruler_length = cell.rulers.length
    const label_length = cell.labels.length
    const cell3DArea_length = cell.cell3DArea.length
    const cell3DCutBox_length = cell.cell3DCutBox.length
    if (obj.isRefBox) {
      for (let i = 0; i < ref_length; i++) {
        //   this.delete(obj, cell.references[i].cell)
        if (cell.references[i].box === obj) {
          //通过图形盒子找到引用
          cell.QCELL.remove(cell.references[i].QREF)
          cell.references.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.PATH) {
      for (let i = 0; i < path_length; i++) {
        if (cell.paths[i] == obj) {
          cell.QCELL.remove(cell.paths[i].QDATA)
          cell.paths.splice(i, 1)
          return
        }
      }
      return
    }

    if (obj.type === QedaGraphicType.POLYGON || obj.type === QedaGraphicType.RECT) {
      for (let i = 0; i < polygons_length; i++) {
        if (cell.polygons[i] == obj) {
          cell.QCELL.remove(cell.polygons[i].QDATA)
          cell.polygons.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.CIRCLE) {
      for (let i = 0; i < ellipses_length; i++) {
        if (cell.ellipses[i] === obj) {
          cell.QCELL.remove(cell.ellipses[i].QDATA)
          cell.ellipses.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.RULER) {
      for (let i = 0; i < ruler_length; i++) {
        if (cell.rulers[i] === obj) {
          cell.rulers.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.LABEL) {
      for (let i = 0; i < label_length; i++) {
        if (cell.labels[i] === obj) {
          cell.QCELL.remove(cell.labels[i].QDATA)
          cell.labels.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.CELL3DAREA) {
      for (let i = 0; i < cell3DArea_length; i++) {
        if (cell.cell3DArea[i] === obj) {
          cell.cell3DArea.splice(i, 1)
          return
        }
      }
      return
    }
    if (obj.type === QedaGraphicType.CELL3DCUTBOX) {
      for (let i = 0; i < cell3DCutBox_length; i++) {
        if (cell.cell3DCutBox[i] === obj) {
          cell.cell3DCutBox.splice(i, 1)
          return
        }
      }
      return
    }
  }
  //删除多个图形
  // deleteItems(obj, cell = this) {
  //   const path_length = cell.paths.length
  //   const polygons_length = cell.polygons.length
  //   const ellipses_length = cell.ellipses.length
  //   const ref_length = cell.references.length
  //   const ruler_length = cell.rulers.length
  //   const label_length = cell.labels.length

  //   let refs = objs.filter(obj => obj.isRefBox)
  //     for (let i = 0; i < ref_length; i++) {
  //       //   this.delete(obj, cell.references[i].cell)
  //       if (cell.references[i].box === obj) {
  //         //通过图形盒子找到引用
  //         cell.QCELL.remove(cell.references[i].QREF)
  //         cell.references.splice(i, 1)
  //         return
  //       }
  //     }
  //     for (let i = 0; i < path_length; i++) {
  //       if (cell.paths[i] == obj) {
  //         cell.QCELL.remove(cell.paths[i].QDATA)
  //         cell.paths.splice(i, 1)
  //         return
  //       }
  //     }

  //     for (let i = 0; i < polygons_length; i++) {
  //       if (cell.polygons[i] == obj) {
  //         cell.QCELL.remove(cell.polygons[i].QDATA)
  //         cell.polygons.splice(i, 1)
  //         return
  //       }
  //     }
  //     for (let i = 0; i < ellipses_length; i++) {
  //       if (cell.ellipses[i] === obj) {
  //         cell.QCELL.remove(cell.ellipses[i].QDATA)
  //         cell.ellipses.splice(i, 1)
  //         return
  //       }
  //     }
  //     for (let i = 0; i < ruler_length; i++) {
  //       if (cell.rulers[i] === obj) {
  //         cell.rulers.splice(i, 1)
  //         return
  //       }
  //     }
  //     for (let i = 0; i < label_length; i++) {
  //       if (cell.labels[i] === obj) {
  //         cell.QCELL.remove(cell.labels[i].QDATA)
  //         cell.labels.splice(i, 1)
  //         return
  //       }
  //     }
  //   }
  // }
  //修改图层
  changeLayer(froms, to) {
    let record = { objs: [], from: [], to: [] }
    const path_length = this.paths.length
    const polygons_length = this.polygons.length
    const ellipses_length = this.ellipses.length
    const labels_length = this.labels.length
    for (let i = 0; i < path_length; i++) {
      let obj = this.paths[i]
      if (froms.indexOf(obj?.layer) !== -1) {
        record.objs.push(obj)
        record.from.push(obj.layer)
        record.to.push(to)
        obj.layer = to
        obj.updateQDATA()
      }
    }
    for (let i = 0; i < polygons_length; i++) {
      let obj = this.polygons[i]
      if (froms.indexOf(obj?.layer) !== -1) {
        record.objs.push(obj)
        record.from.push(obj.layer)
        record.to.push(to)
        obj.layer = to
        obj.updateQDATA()
      }
    }
    for (let i = 0; i < ellipses_length; i++) {
      let obj = this.ellipses[i]
      if (froms.indexOf(obj?.layer) !== -1) {
        record.objs.push(obj)
        record.from.push(obj.layer)
        record.to.push(to)
        obj.layer = to
        obj.updateQDATA()
      }
    }
    for (let i = 0; i < labels_length; i++) {
      let obj = this.labels[i]
      if (froms.indexOf(obj?.layer) !== -1) {
        record.objs.push(obj)
        record.from.push(obj.layer)
        record.to.push(to)
        obj.layer = to
        obj.updateQDATA()
      }
    }
    return record
  }
  //删除指定图层图形
  deleteLayer(layers) {
    const path_length = this.paths.length
    const polygons_length = this.polygons.length
    const ellipses_length = this.ellipses.length
    const labels_length = this.labels.length
    for (let i = 0; i < path_length; i++) {
      let obj = this.paths[i]
      if (layers.indexOf(obj?.layer) !== -1) {
        this.QCELL.remove(obj.QDATA)
        this.paths.splice(i--, 1)
      }
    }
    for (let i = 0; i < polygons_length; i++) {
      let obj = this.polygons[i]
      if (layers.indexOf(obj?.layer) !== -1) {
        this.QCELL.remove(obj.QDATA)
        this.polygons.splice(i--, 1)
      }
    }
    for (let i = 0; i < ellipses_length; i++) {
      let obj = this.ellipses[i]
      if (layers.indexOf(obj?.layer) !== -1) {
        this.QCELL.remove(obj.QDATA)
        this.ellipses.splice(i--, 1)
      }
    }
    for (let i = 0; i < labels_length; i++) {
      let obj = this.labels[i]
      if (layers.indexOf(obj?.layer) !== -1) {
        // alert('delete')
        this.QCELL.remove(obj.QDATA)
        this.labels.splice(i--, 1)
      }
    }
  }
  //删除引用
  deleteRef(ref) {
    let len = this.references.length()
    for (let i = 0; i < len; i++) {
      if (ref == this.references[i]) {
        this.references.splice(i, 1)
        this.QCELL.remove(ref.QREF)
      }
    }
  }
  //添加引用
  addRef(ref) {
    this.references.push(ref)
    this.QCELL.add(ref.QREF)
  }

  //获取cell使用的图层
  getLayers() {
    let result = []
    function getLayer(obj) {
      result.push(obj.layer)
    }
    this.loopCell(getLayer, this, true)
    return Array.from(new Set(result))
    // return [1,2,3]
  }

  //获取cell图形
  getPolygons(depth, keep_params_device = false) {
    let items = []
    if (!depth) {
      items = [...this.paths, ...this.polygons, ...this.ellipses, ...this.labels, ...this.references]
    } else {
      let refItems = []
      this.references.forEach(ref => {
        if (is_params_device(ref) && keep_params_device) {
          refItems.push(ref)
        } else {
          refItems.push(...ref.getPolygons(true, keep_params_device))
        }
      })
      items = [...this.paths, ...this.polygons, ...this.ellipses, ...this.labels, ...refItems]
    }

    return items
  }

  showHideCell(layerDatas) {
    let layers = this.getLayers()
    // let lib = new QGdstk.Library('temp', 0.000001, 1e-9)
    // lib.add(this.QCELL)
    // let layers = lib.layers_and_datatypes().map(data => data[0])
    // layers.push(...lib.layers_and_texttypes().map(data => data[0]))
    this.hide = true
    this.lock = true
    if (!layers.length) {
      this.hide = false
      this.lock = false
    }
    layers.forEach(ly =>
      layerDatas.forEach(data => {
        if (data.layerNumber === ly) {
          if (!data.hide) {
            this.hide = false
          }
          if (!data.lock) {
            this.lock = false
          }
        }
      })
    )
    // if (this.hide) {

    // }
  }
}

export class QedaReference {
  constructor() {
    this.name = ''
    this.magnification = 1 //缩放
    this.origin = [0, 0]
    this.repetition = null
    this.rotation = 0 //角度
    this.x_reflection = 1
    this.cell = null
    this.file_ref = null
    this.parents = []
    this.QREF = null //qgdstk
  }

  //更新引用transform变换
  updateTransform(pos, rotation, scale_y) {
    this.origin = pos
    this.rotation = rotation
    this.x_reflection = Math.sign(scale_y)
    this.QREF.origin = pos
    this.QREF.rotation = (this.rotation * Math.PI) / 180
    this.QREF.x_reflection = this.x_reflection === -1
    // this.magnification = scale
  }

  getPolygons(depth, keep_params_device = true) {
    let polygons = this.cell.getPolygons(depth, keep_params_device)
    let len = polygons.length
    let result = []

    for (let i = 0; i < len; i++) {
      result.push(QedaGraphics.copyRefPolygonsToGlobal(polygons[i], this))
    }
    return result
  }
}

export class QedaGraphic extends Transform {
  constructor() {
    super()
    QEDA_ID += 1
    this.id = QEDA_ID
    this.type //图形类型
    this.layer = STAGE.currentLayer?.layerNumber //图层
    this.hide = STAGE.currentLayer?.hide //隐藏
    this.lock = STAGE.currentLayer?.lock //锁定
    this.isTool = false
    this.isRefBox = false //引用包围盒
    this.selfDevice = false //自身器件
    this.reference = false //引用指向 QedaReference
    this.drawing = false //正在绘制图形
    this.r = [0, 0] //圆半径
    this.width = 0 //线宽
    this.radius = 0 //转弯半径
    this.shape = [] //图形顶点绘制数据
    this.pathBody = [] //带宽度的线多边形顶点
    this.checked_points_index = [] //被选中顶点下标
    this.checked_paths = [] //选中边 CanvasKit.Path
    this.checked_points_mouse_offset = [] //缓存选择点与鼠标偏移量
    this.checked_lines = [] //选中线
    this.aabb = [
      [0, 0],
      [0, 0],
    ] //包围盒 [x, y] [w, h]
    this.aabb_center = [0, 0] //包围盒中心坐标
    this.aabb_area = 0
    this.path = new CanvasKit.Path()
    this.circlePath = new CanvasKit.Path()
    this.insideStage = false
    this.hited = false //是否命中图形上
    this.checked = false //是否选中图形
    // this.preChecked = false //是否预选中图形
    this.mouse_offset = [0, 0] //和鼠标的偏移量
    this.deleted = false
    this.intersect_self = false
    this.slopes = [] //线斜率
    this.preCut = false
    this.preAdd = false
    this.gds_data_local //gds本地数据
    this.gds_data_global //gds全局数据
    this.parents = []
    this.QDATA = null //Qlib文件引用
  }

  //更新顶点信息
  updateShape() {
    this.updateGdsGlobalData()
    this.shape = this.gds_data_global.get_points()
    this.checkIsRect()
    this.updateQDATA()
    this.updatePath()
    this.updateAABB()
  }

  //更新全局坐标点
  updateGdsGlobalData() {
    this.gds_data_global = this.gds_data_local.copy()
    this.rotation = Math.ceil(this.rotation * 100) / 100
    // this.position = fixPosNumber4(this.position)

    let rot = (this.rotation * Math.PI) / 180 //角度->弧度
    this.gds_data_global.scale(this._scale[0], this._scale[1]) //缩放变换

    this.gds_data_global.rotate(rot) //旋转 弧度
    this.gds_data_global.translate(this.position)
    let arr = this.gds_data_global.get_points()
    //坐标保留三位有效数字
    for (let index = 0; index < arr.length; index++) {
      arr[index] = fixPosNumber3(arr[index])
    }
    this.gds_data_global.points = arr
    if (this.isRefBox) {
      //reference 盒子
      if (this.reference) {
        this.reference.updateTransform(this.position, this.rotation, this._scale[1])
      }
    }
  }

  //更新轮廓
  updatePath() {
    if (this.type === QedaGraphicType.CIRCLE) {
      this.path = [this.shape[0][0], this.shape[0][1], this.shape[2][0], this.shape[2][1]]
      if (this.QDATA) {
        this.circlePath.reset()
        const length = this.QDATA.points.length
        for (let i = 0; i < length; i++) {
          let p = this.QDATA.points[i]
          if (!i) {
            this.circlePath.moveTo(p[0], p[1])
          } else {
            this.circlePath.lineTo(p[0], p[1])
          }
        }
        this.circlePath.close()
      }

      return
    }
    this.path.reset()
    this.path.setIsVolatile(false)
    const length = this.shape.length
    for (let i = 0; i < length; i++) {
      let p = this.shape[i]
      if (!i) {
        this.path.moveTo(p[0], p[1])
      } else {
        this.path.lineTo(p[0], p[1])
      }
    }
    this.pathBody = []
    if (this.type === QedaGraphicType.PATH) {
      //有宽度的线

      if (this.width) {
        const length = this.shape.length
        for (let i = length - 2; i > 0; i--) {
          let p = this.shape[i]
          this.path.lineTo(p[0], p[1])
        }
        // this.path.close()
        let polygons = get_path_polygons(this.shape, this.width, this.radius)
        this.pathBody = polygons
        let len = polygons.length
        for (let i = 0; i < len; i++) {
          let polygon = polygons[i]
          const points_len = polygon.length
          for (let j = 0; j < points_len; j++) {
            let point = polygon[j]
            if (!j) {
              this.path.moveTo(point[0], point[1])
            } else {
              this.path.lineTo(point[0], point[1])
            }
          }
          this.path.close()
        }
      }
    } else {
      this.path.close()
    }
  }

  //更新文件引用对象
  updateQDATA() {
    if (this.QDATA) {
      if (this.type === QedaGraphicType.PATH) {
        this.width = fixNumber(this.width)
        this.radius = fixNumber(this.radius)
        this.QDATA.widths[0] = this.width
        // this.QDATA.bend_radius[0] = this.radius
        this.QDATA.layers[0] = this.layer
        this.QDATA.set_gds_property(0, JSON.stringify(this.width))
        this.QDATA.set_gds_property(1, JSON.stringify(this.radius))
        this.QDATA.simple_path = true

        // if (this.width) {
        //   this.QDATA.simple_path = false
        //   this.QDATA.set_joins(['round'])
        // }
      } else {
        this.QDATA.layer = this.layer
      }

      if (this.type === QedaGraphicType.CIRCLE) {
        this.QDATA.points = this.getGdsData().get_points()
        this.QDATA.set_gds_property(0, JSON.stringify(this.position))
        this.QDATA.set_gds_property(1, JSON.stringify(this.r))
      } else if (this.type === QedaGraphicType.LABEL) {
        this.QDATA.origin[0] = this.position[0]
        this.QDATA.origin[1] = this.position[1]
        this.QDATA.rotation = (this.rotation * Math.PI) / 180
      } else {
        this.QDATA.points = this.shape
      }
    }
  }

  //更新标签
  updateLabel(pos, anchor, text, font_size, layer, rotation) {
    this.QDATA.origin[0] = pos[0]
    this.QDATA.origin[1] = pos[1]
    this.QDATA.anchor = anchor
    this.QDATA.text = text
    this.QDATA.set_gds_property(0, JSON.stringify(parseFloat(font_size)))
    this.layer = layer
    this.QDATA.layer = layer
    this.setPoints(getLabelBox(this.QDATA))
    this.rotation = rotation
    this.updateShape()
  }

  //更新选中边
  updateLines() {
    updateLines(this)
  }

  //更新选中边Path
  updateCheckedPaths() {
    this.checked_paths = []
    let length = this.checked_points_index.length
    let last = length - 1
    //更新选中线
    if (length < 2) return //小于一条边退出
    let sp = this.shape
    const first_checked = this.checked_points_index[0] === 0
    const first_p = sp[0]
    const last_checked = this.checked_points_index[length - 1] === sp.length - 1
    let index_old = -2
    let path = new CanvasKit.Path()
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      if (index != index_old + 1) {
        //断点重置
        //const next_index = this.checked_points_index[i+1]
        //if(index + 1 !== next_index) continue //少于两个点不需要生成path
        path = new CanvasKit.Path()
        this.checked_paths.push(path)
        path.moveTo(p[0], p[1])
      } else {
        path.lineTo(p[0], p[1])
      }
      index_old = index
    }
    if (this.type !== QedaGraphicType.PATH) {
      if (last_checked && first_checked) {
        //判断首位相连
        this.checked_paths[this.checked_paths.length - 1].lineTo(first_p[0], first_p[1])
      }
    }
  }

  //更新边斜率
  updateSlopes() {
    let sp = this.shape
    this.slopes = []
    const length = sp.length
    if (!length) return
    for (let i = 0; i < length - 1; i++) {
      this.slopes.push(getSlope(sp[i], sp[i + 1]))
    }
    if (this.type !== QedaGraphicType.PATH) {
      this.slopes.push(getSlope(sp[0], sp[sp.length - 1]))
    }
  }
  //判断矩形
  checkIsRect() {
    if (this.isTool || this.isRefBox || this.drawing) return
    if (this.type === QedaGraphicType.RECT || this.type === QedaGraphicType.POLYGON) {
      if (this.shape.length == 4 && isRectangle(this.shape[0][0], this.shape[0][1], this.shape[1][0], this.shape[1][1], this.shape[2][0], this.shape[2][1], this.shape[3][0], this.shape[3][1])) {
        this.type = QedaGraphicType.RECT
      } else {
        this.type = QedaGraphicType.POLYGON
      }
    }
  }
  //拉伸图形后检查图形合法 自相交重置
  stretchConfirm() {
    if (this.type === QedaGraphicType.CIRCLE) {
      this.r[0] = (this.shape[2][0] - this.shape[0][0]) / 2
      this.r[1] = (this.shape[2][1] - this.shape[0][1]) / 2
      this.position[0] = this.shape[0][0] + this.r[0]
      this.position[1] = this.shape[0][1] + this.r[1]

      // this.updateTransform()
      this.setPoints()
      return
    }
    const flag = this.rotation !== 0 || this._scale[0] !== 1 || this._scale[1] !== 1
    // if (isSelfIntersection(this.shape, this.type !== QedaGraphicType.PATH)) {
    //     // alert('图形自相交')
    //     if (flag) { //经过旋转缩放变换 重置本地坐标
    //         this.resetTransform()
    //     }
    //     this.setPoints()
    //     this.updateSlopes()
    //     STAGE.render()
    // } else {
    let new_points = this.shape
    if (flag) {
      //经过旋转缩放变换 重置本地坐标
      this.resetTransform()
    }
    this.setPoints(new_points)
    if (!STAGE.isStretch) {
      //角度锁定不用更新斜率
      this.updateSlopes()
    }
    // }
  }

  //更新包围盒
  updateAABB() {
    //有宽度的线
    if (this.pathBody?.length) {
      let points = [...this.shape]
      this.pathBody.forEach(b => points.push(...b))
      let temp = new QGdstk.Polygon(points)
      this.aabb = temp.bounding_box()
    } else {
      this.aabb = this.gds_data_global.bounding_box()
    }
    // this.aabb = this.gds_data_global.bounding_box()

    const start_x = this.aabb[0][0]
    const start_y = this.aabb[0][1]
    let w = this.aabb[1][0] - start_x
    let h = this.aabb[1][1] - start_y
    this.aabb_center = [start_x + w / 2, start_y + h / 2]
    // w = w === 0 ? 1 : w
    // h = h === 0 ? 1 : h
    this.aabb_area = Math.abs(w * h)
  }

  //计算图形和鼠标之间的偏移量
  updateMouseOffset(mouse_pos) {
    this.mouse_offset = [this.position[0] - mouse_pos.x, this.position[1] - mouse_pos.y]
  }

  //选择点和鼠标之间的偏移量
  updatePointsMouseOffset(mouse_position) {
    if (!mouse_position) {
      mouse_position = AXIS.mouse_point
    }
    let sp = this.shape
    this.checked_points_mouse_offset = []
    const length = this.checked_points_index.length
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      this.checked_points_mouse_offset.push({
        x: p[0] - mouse_position.x,
        y: p[1] - mouse_position.y,
      })
    }
  }

  //重置状态
  resetStatus() {
    this.hited = false
    this.checked = false
    this.preChecked = false
    this.checked_points_index = []
    this.checked_points_mouse_offset = []
    this.checked_paths = []
    this.preCut = false
    this.preAdd = false
  }

  switchOverallSelection() {
    if (this.isRefBox || this.type === QedaGraphicType.LABEL || this.type === QedaGraphicType.KEYPOINT || this.type === QedaGraphicType.RULER) {
      this.resetStatus()
      return
    }
    if (this.checked_points_index.length === this.shape.length) {
      this.resetStatus()
      this.checked = true
    } else {
      this.resetStatus()
    }
  }

  switchPartialSelection() {
    if (this.isRefBox || this.type === QedaGraphicType.LABEL || this.type === QedaGraphicType.KEYPOINT || this.type === QedaGraphicType.RULER) {
      this.resetStatus()
      return
    }
    if (this.checked) {
      this.resetStatus()
      this.checked_points_index = this.shape.map((p, index) => index)
      this.updateCheckedPaths()
    } else {
      this.resetStatus()
    }
  }
  delete() {
    this.deleted = true
    this.resetStatus()
    this.reference = null
  }

  //布尔运算返回点
  getGdsData() {
    if (this.type === QedaGraphicType.CIRCLE) {
      let r = [this.r[0], this.r[1]] //半径可能为0或负数
      if (r[0] < 0) {
        r[0] = -r[0]
      }
      if (r[1] < 0) {
        r[1] = -r[1]
      }
      if (r[0] === 0) {
        r[0] = 0.00001
      }
      if (r[1] === 0) {
        r[1] = 0.00001
      }
      const polygon = new QGdstk.ellipse(this.position, r, null, 0, 0, 0.001, 0, 0)
      let points = polygon.get_points()
      let len = points.length
      if (len > 24) {
        let interval = parseInt(len / 24)
        polygon.points = points.filter((p, i) => i % interval === 0)
      }

      polygon.layer = this.layer
      return polygon
    } else {
      if (this.type === QedaGraphic.PATH) {
        this.gds_data_global.layer[0] = this.layer
      } else {
        this.gds_data_global.layer = this.layer
      }
      return this.gds_data_global
    }
  }

  //根据坐标系统坐标初始化图形信息
  setPoints(posArry) {
    if (this.type == QedaGraphicType.CIRCLE) {
      this.gds_data_local = new QGdstk.rectangle(
        [-this.r[0], -this.r[1]], //左下
        [this.r[0], this.r[1]] //右上
      )
      this.updateShape()
      // this.updateGdsGlobalData()
      // this.updateAABB()
      return
    }
    this.circlePath = null
    if (posArry === undefined) {
      posArry = this.gds_data_global.get_points()
    }
    let points = posArry
    if (this.isRefBox) {
      //引用盒子根据引用数据设置变换属性
      this.setRefBoxPoints(points)
      return
    }
    if (this.type === QedaGraphicType.LABEL) {
      //label指定轴心
      this.position = [this.QDATA.origin[0], this.QDATA.origin[1]]
      this.rotation = (this.QDATA.rotation * 180) / Math.PI
      this.rotation_old = this.rotation
      this.gds_data_local = new QGdstk.Polygon(points)
      this.gds_data_local.translate([-this.position[0], -this.position[1]])
    } else {
      const aabb = new QGdstk.Polygon(posArry).bounding_box() //计算中心点
      const w = aabb[1][0] - aabb[0][0]
      const h = aabb[1][1] - aabb[0][1]
      this.position = [aabb[0][0] + w / 2, aabb[0][1] + h / 2]
      this.gds_data_local = new QGdstk.Polygon(points)
      this.gds_data_local.translate([-this.position[0], -this.position[1]])
      this.gds_data_local.rotate((-this.rotation * Math.PI) / 180)
    }

    this.updateShape()
  }

  //直接使用引用变换属性
  setRefBoxPoints(points) {
    this._scale[0] = this.reference.magnification
    this._scale[1] = this.reference.magnification * this.reference.x_reflection
    this.rotation = this.reference.rotation //角度
    this.rotation_old = this.rotation
    this.position = [this.reference.origin[0], this.reference.origin[1]]
    this.gds_data_local = new QGdstk.Polygon(points)
    // this.gds_data_local.translate([-this.position[0], -this.position[1]])
    // this.gds_data_local.rotate(-this.rotation * Math.PI / 180)
    // this.gds_data_local.transform()
    this.updateShape()
  }
  //根据当前渲染坐标及矩阵计算本地坐标
  // resetPoints(arr){
  //     if(arr === undefined){
  //         arr = this.gds_data_global.get_points()
  //     }
  //     this.updateTransform()
  //     //平移到新的轴心点保证形状改变后轴心点
  //     this.position = centroid(arr) //计算中心点

  //     this.transform.m[2][0] = this.position[0];
  //     this.transform.m[2][1] = this.position[1];
  //     this.points = this.transform.getInverse().applyToArray(arr)
  //     this.points = this.points.map(p=>[p.x,p.y])
  //     this.gds_data_local = new QGdstk.Polygon(this.points)
  //     this.updateTransform()
  //     this.updateShape()
  // }

  //正在画板绘制图形
  drawPoint(point, confirm = false, perfect = false) {
    let points
    if (this.type == QedaGraphicType.PATH) {
      points = this.gds_data_global.get_points()

      if (confirm && !this.intersect_self) {
        let valid = isValidPathPoint(points, [point.x, point.y])
        if (!valid) return
        points.push([point.x, point.y])
      } else {
        const index = points.length < 2 ? 1 : points.length - 1 //鼠标移动时保证最后一个点为鼠标坐标
        points[index] = [point.x, point.y]
      }
      this.setPoints(points)
    } else if (this.type == QedaGraphicType.RULER) {
      points = this.gds_data_global.get_points()
      if (confirm && !this.intersect_self) {
        points.push([point.x, point.y])
      } else {
        const index = points.length < 2 ? 1 : points.length - 1 //鼠标移动时保证最后一个点为鼠标坐标
        points[index] = [point.x, point.y]
      }
      this.setPoints(points)
    } else if (this.type == QedaGraphicType.CIRCLE) {
      // if (shift) {
      //   let result = isAdsorb({ x: window.STARTPOS[0], y: window.STARTPOS[1] }, point, true, 'um')
      //   if (result.rotatePoint) {
      //     point = result.rotatePoint
      //   }
      // }
      // //以对角线生成圆
      let offsetX = (point.x - window.STARTPOS[0]) / 2
      let offsetY = (point.y - window.STARTPOS[1]) / 2
      this.r[0] = Math.abs(offsetX)
      this.r[1] = Math.abs(offsetY)
      if (perfect) {
        this.r[1] = this.r[0]
      }
      this.position[0] = window.STARTPOS[0] + offsetX
      this.position[1] = window.STARTPOS[1] + offsetY
      this.setPoints()
      //以中心点生成圆
      // this.r[0] = Math.abs(point[0] - this.position[0])
      // this.r[1] = Math.abs(point[1] - this.position[1])
    } else if (this.type == QedaGraphicType.RECT || this.type == QedaGraphicType.CELL3DCUTBOX) {
      //以对角线生成矩形
      let start = this.gds_data_global.get_points()[0]

      if (perfect) {
        start = [window.STARTPOS[0], window.STARTPOS[1]]
        let w = point.x - start[0]
        let h = Math.sign(point.y - start[1]) * w
        let p2, p3, p4
        if (w >= 0) {
          p2 = [start[0], start[1] + h]
          p3 = [start[0] + w, start[1] + h]
          p4 = [start[0] + w, start[1]]
        } else {
          p2 = [start[0], start[1] - h]
          p3 = [start[0] + w, start[1] - h]
          p4 = [start[0] + w, start[1]]
        }

        this.setPoints([start, p2, p3, p4])
      } else {
        const rect = new QGdstk.rectangle(start, [point.x, point.y], 0, 0)
        this.setPoints(rect.get_points())
      }
    } else if (this.type == QedaGraphicType.POLYGON || this.type == QedaGraphicType.CELL3DAREA) {
      points = this.gds_data_global.get_points()
      if (confirm && !this.intersect_self) {
        let valid = isValidPolygonPoint(points, [point.x, point.y])
        if (!valid) return
        points.push([point.x, point.y])
      } else {
        const index = points.length < 2 ? 1 : points.length - 1
        points[index] = [point.x, point.y]
      }
      //this.intersect_self = isSelfIntersection(points) //判断多边形自相交
      this.setPoints(points)
    }
    // switch (this.type) {
    //   case QedaGraphicType.PATH:
    //     points = this.gds_data_global.get_points()

    //     if (confirm && !this.intersect_self) {
    //       let valid = isValidPathPoint(points, [point.x, point.y])
    //       if (!valid) return
    //       points.push([point.x, point.y])
    //     } else {
    //       const index = points.length < 2 ? 1 : points.length - 1 //鼠标移动时保证最后一个点为鼠标坐标
    //       points[index] = [point.x, point.y]
    //     }
    //     this.setPoints(points)
    //     break
    //   case QedaGraphicType.RULER:
    //     points = this.gds_data_global.get_points()
    //     if (confirm && !this.intersect_self) {
    //       points.push([point.x, point.y])
    //     } else {
    //       const index = points.length < 2 ? 1 : points.length - 1 //鼠标移动时保证最后一个点为鼠标坐标
    //       points[index] = [point.x, point.y]
    //     }
    //     this.setPoints(points)
    //     break
    //   case QedaGraphicType.CIRCLE:
    //     // if (shift) {
    //     //   let result = isAdsorb({ x: window.STARTPOS[0], y: window.STARTPOS[1] }, point, true, 'um')
    //     //   if (result.rotatePoint) {
    //     //     point = result.rotatePoint
    //     //   }
    //     // }
    //     // //以对角线生成圆
    //     let offsetX = (point.x - window.STARTPOS[0]) / 2
    //     let offsetY = (point.y - window.STARTPOS[1]) / 2
    //     this.r[0] = Math.abs(offsetX)
    //     this.r[1] = Math.abs(offsetY)
    //     if (perfect) {
    //       this.r[1] = this.r[0]
    //     }
    //     this.position[0] = window.STARTPOS[0] + offsetX
    //     this.position[1] = window.STARTPOS[1] + offsetY
    //     this.setPoints()
    //     //以中心点生成圆
    //     // this.r[0] = Math.abs(point[0] - this.position[0])
    //     // this.r[1] = Math.abs(point[1] - this.position[1])
    //     break
    //   case QedaGraphicType.RECT:
    //     //以对角线生成矩形
    //     let start = this.gds_data_global.get_points()[0]

    //     if (perfect) {
    //       start = [window.STARTPOS[0], window.STARTPOS[1]]
    //       let w = point.x - start[0]
    //       let h = Math.sign(point.y - start[1]) * w
    //       let p2, p3, p4
    //       if (w >= 0) {
    //         p2 = [start[0], start[1] + h]
    //         p3 = [start[0] + w, start[1] + h]
    //         p4 = [start[0] + w, start[1]]
    //       } else {
    //         p2 = [start[0], start[1] - h]
    //         p3 = [start[0] + w, start[1] - h]
    //         p4 = [start[0] + w, start[1]]
    //       }

    //       this.setPoints([start, p2, p3, p4])
    //     } else {
    //       const rect = new QGdstk.rectangle(start, [point.x, point.y], 0, 0)
    //       this.setPoints(rect.get_points())
    //     }

    //     break
    //   case QedaGraphicType.POLYGON:
    //     points = this.gds_data_global.get_points()
    //     if (confirm && !this.intersect_self) {
    //       let valid = isValidPolygonPoint(points, [point.x, point.y])
    //       if (!valid) return
    //       points.push([point.x, point.y])
    //     } else {
    //       const index = points.length < 2 ? 1 : points.length - 1
    //       points[index] = [point.x, point.y]
    //     }
    //     //this.intersect_self = isSelfIntersection(points) //判断多边形自相交
    //     this.setPoints(points)
    //     break
    //   case QedaGraphicType.CELL3DAREA:
    //     points = this.gds_data_global.get_points()
    //     if (confirm && !this.intersect_self) {
    //       let valid = isValidPolygonPoint(points, [point.x, point.y])
    //       if (!valid) return
    //       points.push([point.x, point.y])
    //     } else {
    //       const index = points.length < 2 ? 1 : points.length - 1
    //       points[index] = [point.x, point.y]
    //     }
    //     //this.intersect_self = isSelfIntersection(points) //判断多边形自相交
    //     this.setPoints(points)
    //     break
    // }
  }

  //完成绘制
  completeDraw() {
    this.drawing = false
    let points
    let invalidGraphics = []
    if (this.type == QedaGraphicType.PATH) {
      points = this.gds_data_global.get_points()
      let len = points.length
      if (len < 3) {
        invalidGraphics.push(this)
      } else {
        points.pop()
        this.setPoints(points)
      }
    } else if (this.type == QedaGraphicType.RULER) {
      points = this.gds_data_global.get_points()
      if (points.length > 2) {
        points.pop()
        this.setPoints(points)
      }
      if (JSON.stringify(points[0]) === JSON.stringify(points[1])) {
        invalidGraphics.push(this)
      }
    } else if (this.type == QedaGraphicType.RECT || this.type == QedaGraphicType.CELL3DCUTBOX) {
      // this.setPoints()
    } else if (this.type == QedaGraphicType.POLYGON || this.type == QedaGraphicType.CELL3DAREA) {
      points = this.gds_data_global.get_points()
      const length = points.length
      if (length < 4) {
        //小于三点删除多边形
        this.delete()
        invalidGraphics.push(this)
      } else if (length > 2) {
        points.pop()
        this.setPoints(points)
      }
      let a = points[0]
      let b = points.at(-1)
      let c = points.at(-2)
      if (a && b && c) {
        if (a[0] === b[0] && c[0] === b[0]) {
          invalidGraphics.push(this)
        } else if (a[1] === b[1] && c[1] === b[1]) {
          invalidGraphics.push(this)
        }
      }
    }
    // switch (this.type) {
    //   case QedaGraphicType.PATH:
    //     points = this.gds_data_global.get_points()
    //     let len = points.length
    //     if (len < 3) {
    //       invalidGraphics.push(this)
    //     } else {
    //       points.pop()
    //       this.setPoints(points)
    //     }
    //     break
    //   case QedaGraphicType.RULER:
    //     points = this.gds_data_global.get_points()
    //     if (points.length > 2) {
    //       points.pop()
    //       this.setPoints(points)
    //     }
    //     if (JSON.stringify(points[0]) === JSON.stringify(points[1])) {
    //       invalidGraphics.push(this)
    //     }
    //     break
    //   case QedaGraphicType.RECT:
    //     // this.setPoints()
    //     break
    //   case QedaGraphicType.POLYGON:
    //     points = this.gds_data_global.get_points()
    //     const length = points.length
    //     if (length < 4) {
    //       //小于三点删除多边形
    //       this.delete()
    //       invalidGraphics.push(this)
    //     } else if (length > 2) {
    //       points.pop()
    //       this.setPoints(points)
    //     }
    //     let a = points[0]
    //     let b = points.at(-1)
    //     let c = points.at(-2)
    //     if (a && b && c) {
    //       if (a[0] === b[0] && c[0] === b[0]) {
    //         invalidGraphics.push(this)
    //       } else if (a[1] === b[1] && c[1] === b[1]) {
    //         invalidGraphics.push(this)
    //       }
    //     }
    //     break
    // }
    this.updateSlopes()
    this.drawing = false
    window.STARTPOS = null //清除绘制起始点
    return invalidGraphics
  }

  //根据鼠标位置移动
  movePosByMouse(mouse_pos) {
    STAGE.drag_objs = true
    STAGE.drag_obj_id = this.id
    // const mouse_pos = AXIS.mouse_point //鼠标相对坐标轴坐标
    // if (!mouse_pos) return
    this.translate(mouse_pos.x + this.mouse_offset[0], mouse_pos.y + this.mouse_offset[1])
    this.updateShape()
  }

  //根据鼠标位置移动选中顶点位置
  movePointsByByMouse(mouse_pos) {
    STAGE.drag_objs = true
    STAGE.drag_obj_id = this.id
    // const mouse_pos = AXIS.mouse_point //鼠标相对坐标轴坐标
    const length = this.checked_points_index.length

    let sp = this.shape
    const total_size = sp.length //点的总数
    const total_last_index = total_size - 1

    //移动所有选中点
    for (let i = 0; i < length; i++) {
      const index = this.checked_points_index[i]
      const p = sp[index]
      const off_set = this.checked_points_mouse_offset[i]
      p[0] = mouse_pos.x + off_set.x
      p[1] = mouse_pos.y + off_set.y
    }

    if (length === total_size) {
      //全选
      if (this.type === QedaGraphicType.CIRCLE) {
        this.position[0] = sp[0][0] + (sp[2][0] - sp[0][0]) / 2
        this.position[1] = sp[0][1] + (sp[2][1] - sp[0][1]) / 2
        // this.updateTransform()
        this.updateGdsGlobalData()
      }
    } else {
      if (this.type === QedaGraphicType.CIRCLE) {
        const line_length = this.checked_lines.length
        for (let i = 0; i < line_length; i++) {
          movePolygonLines(this, total_last_index, this.checked_lines[i], total_size, sp)
        }
        this.updateCheckedPaths()
        return
      }
      if (STAGE.isStretch) {
        if (this.type === QedaGraphicType.PATH) {
          //线

          const line_length = this.checked_lines.length
          let off_set_index = 0
          for (let i = 0; i < line_length; i++) {
            moveLines(this, mouse_pos, total_last_index, this.checked_lines[i], off_set_index)
            off_set_index += this.checked_lines[i].length
          }
        } else {
          //多边形

          const line_length = this.checked_lines.length
          for (let i = 0; i < line_length; i++) {
            movePolygonLines(this, total_last_index, this.checked_lines[i], total_size, sp)
          }
        }
      }
    }
    this.updateAABB()
    this.updatePath()
    this.updateCheckedPaths()
    // this.setPoints()
  }
}

//更新选中线 拉伸操作相关数据
function updateLines(obj) {
  const length = obj.checked_points_index.length

  const lines = [] //计算需要控制的线段或顶点
  let line = []
  lines.push(line)

  let start = obj.checked_points_index[0]
  for (let i = 0; i < length; i++) {
    const index = obj.checked_points_index[i]
    if (start == index) {
      line.push(index)
    } else {
      start = index
      line = [index]
      lines.push(line)
    }
    start += 1
  }
  if (obj.type !== QedaGraphicType.PATH) {
    //线
    let sp = obj.shape
    if (lines.length > 1) {
      const lines_last_index = lines.length - 1
      let first_line = lines[0]
      let last_line = lines[lines_last_index]
      if (first_line[0] === 0 && last_line.includes(sp.length - 1)) {
        //首尾相连
        // first_line.push(...lines[lines_last_index])
        // lines.splice(lines_last_index,1)
        last_line.push(...first_line)
        lines.shift()
      }
    }
  }
  obj.checked_lines = lines
}

//移动线的的点和边
function moveLines(obj, mouse_pos, total_last_index, line_indexs, off_set_index) {
  const length = line_indexs.length
  if (length === 1) {
    //移动单个点
    const index = line_indexs[0] //下标

    const p = obj.shape[index] //坐标
    let off_set = obj.checked_points_mouse_offset[off_set_index] //偏移量
    if (!off_set) {
      off_set = { x: 0, y: 0 }
    }
    if (index === 0) {
      //选择首尾点
      const a = obj.shape[1]
      const s = obj.slopes[0]
      const line_ap = slopeAndIntercept(a, p, s)
      let slope_line_mouse_p = null
      if (s == null) {
        //bug 929 拉伸垂直线上的点
        slope_line_mouse_p = 0
      }
      const line_mouse_p = slopeAndIntercept(p, p, slope_line_mouse_p)
      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)
      if (!isValidPos(p1)) {
        return
      }
      obj.shape[index] = p1
    } else if (index === total_last_index) {
      const a = obj.shape[total_last_index - 1]
      const s = obj.slopes[total_last_index - 1]
      const line_ap = slopeAndIntercept(a, p, s)
      let slope_line_mouse_p = null
      if (s == null) {
        //bug 929 拉伸垂直线上的点
        slope_line_mouse_p = 0
      }
      const line_mouse_p = slopeAndIntercept(p, p, slope_line_mouse_p)
      let p1 = calculateIntersectionPoint(line_ap, line_mouse_p)
      if (!isValidPos(p1)) {
        return
      }
      obj.shape[index] = p1
    } else {
      //选择中间点 实际是移动选中点和上下一点直接线段
      let m_index = line_indexs[0]
      let line = []
      let count = 2
      if (m_index - 1 === 0) {
        //第二个点
        line = [m_index, m_index + 1]
      } else if (m_index + 1 === total_last_index) {
        //最后一个点前一个点
        const p_before = obj.shape[index - 1] //跟新前一个坐标
        p_before[0] = mouse_pos.x + off_set.x
        p_before[1] = mouse_pos.y + off_set.y
        line = [m_index - 1, m_index]
      } else {
        //中间点
        line = [m_index + 1, m_index, m_index - 1]
        count = 3
      }
      moveLines(obj, mouse_pos, total_last_index, line)
    }
  } else {
    const total = total_last_index
    // const end = length-1

    // const order_index = getOrderPoints(line_indexs, length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]

    //判断收尾顺序
    const head = obj.shape[head_index]
    const last = obj.shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = obj.shape[before_index[0]]
    const b = obj.shape[before_index[1]]
    const c = obj.shape[after_index[0]]
    const d = obj.shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, obj.slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, obj.slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, obj.slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, obj.slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, obj.slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    obj.shape[head_index] = p1
    obj.shape[last_index] = p2
  }
}

//移动多边形的点和边
function movePolygonLines(obj, total_last_index, line_indexs, total_size, shape) {
  const length = line_indexs.length
  if (length > 1) {
    //封闭图形
    const total = total_last_index
    // const end = length-1
    // const order_index = getOrderPoints(deepClone(line_indexs), length, total)
    let head_index = line_indexs[0]
    let last_index = line_indexs.at(-1) //line_indexs[end]
    //判断收尾顺序
    const head = shape[head_index]
    const last = shape[last_index]

    const before_index = getAroundIndex(head_index, total, 3, 1)
    const after_index = getAroundIndex(last_index, total, 3, 1)

    //计算需要移动点首尾坐标a, head, b, ...p, c, last, d 根据点求线的交点
    const a = shape[before_index[0]]
    const b = shape[before_index[1]]
    const c = shape[after_index[0]]
    const d = shape[after_index[1]]

    const line_a_head = slopeAndIntercept(a, head, obj.slopes[before_index[0]])
    let line_head_b
    if (length == 2) {
      line_head_b = slopeAndIntercept(head, b, obj.slopes[head_index])
    } else {
      line_head_b = slopeAndIntercept(b, head, obj.slopes[head_index])
    }

    const line_c_last = slopeAndIntercept(c, last, obj.slopes[after_index[0]])
    const line_last_d = slopeAndIntercept(d, last, obj.slopes[last_index])

    let p1 = calculateIntersectionPoint(line_a_head, line_head_b)
    let p2 = calculateIntersectionPoint(line_c_last, line_last_d)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }

    // if (dist_points(p1, p2) == 0) return
    shape[head_index] = p1
    shape[last_index] = p2
  } else if (length === 1) {
    //移动单个点

    const index = line_indexs[0] //下标
    const p = shape[index] //坐标

    //计算点周围点坐标a, b, p, c, d
    const indexs = getAroundIndex(index, total_size - 1, 5, 2)

    const a = shape[indexs[0]]
    const b = shape[indexs[1]]
    const c = shape[indexs[2]]
    const d = shape[indexs[3]]

    const line_ab = slopeAndIntercept(a, b, obj.slopes[indexs[0]])
    const line_pb = slopeAndIntercept(p, b, obj.slopes[indexs[1]])

    const line_pc = slopeAndIntercept(p, c, obj.slopes[index])
    const line_dc = slopeAndIntercept(d, c, obj.slopes[indexs[2]])
    let p1 = calculateIntersectionPoint(line_ab, line_pb)
    let p2 = calculateIntersectionPoint(line_dc, line_pc)
    if (!isValidPos(p1) || !isValidPos(p2)) {
      return
    }
    shape[indexs[1]] = p1
    shape[indexs[2]] = p2
  }
}

//获取下标index周围的下标
function getAroundIndex(index, total, length, space) {
  const result = []
  for (let i = 0; i < length; i++) {
    let temp = index - space + i
    if (temp === index) continue
    if (temp < 0) {
      temp += total + 1
    } else if (temp > total) {
      temp -= total + 1
    }
    result.push(temp)
  }

  return result
}

//夹角
export function getAngle({ x: x1, y: y1 }, { x: x2, y: y2 }) {
  const dot = x1 * x2 + y1 * y2
  const det = x1 * y2 - y1 * x2
  const angle = (-Math.atan2(det, dot) / Math.PI) * 180
  return angle
}

//两条平行线偏移量
export function getLineOffset(l1, l2) {
  const start = l1[0]
  const slope1 = getVerticalSlope(getSlope(l1[0], l1[1]))
  const slope2 = getSlope(l2[0], l2[1])
  const line1 = slopeAndIntercept(start, [0, 0], slope1)
  const line2 = slopeAndIntercept(l2[0], l2[1], slope2)
  const cross = calculateIntersectionPoint(line1, line2)
  return [cross[0] - start[0], cross[1] - start[1]]
  // return [100,100]
}
//直线斜率 0 平行于x轴 null 垂直于y轴
export function getSlope(p1, p2) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  const delta_x = x2 - x1
  return delta_x !== 0 ? (y2 - y1) / delta_x : null
}

//获取斜率的垂直斜率
export function getVerticalSlope(s) {
  if (s === null) {
    return 0
  }
  if (s === 0) {
    return null
  }
  return -1 / s
}

//直线斜率和y交点 直线表达式 y = slope * x + intercept
export function slopeAndIntercept(p1, p2, s) {
  const x1 = p1[0]
  const x2 = p2[0]
  const y1 = p1[1]
  const y2 = p2[1]
  let slope = 0 //斜率
  if (s !== undefined) {
    slope = s
  } else {
    const delta_x = x2 - x1
    if (delta_x !== 0) {
      slope = (y2 - y1) / delta_x
    }
  }

  let intercept = y1 - slope * x1 //y轴交点
  if (slope === null) {
    //垂直线
    intercept = x1
  }
  return { slope, intercept }
}

//已知斜率求交点
export function calculateIntersectionPoint(line_a, line_b) {
  const a = line_a.slope
  const b = line_b.slope
  const c = line_a.intercept
  const d = line_b.intercept
  let px
  let py
  if (a === null) {
    px = c
    py = b * px + d
  } else if (b === null) {
    px = d
    py = a * px + c
  } else {
    px = (d - c) / (a - b)
    py = a * px + c
  }

  // return { x: px, y: py };
  return [px, py]
}
//两直线交点
export function calculateIntersection(p1, p2, p3, p4) {
  var c2x = p3.x - p4.x // (x3 - x4)
  var c3x = p1.x - p2.x // (x1 - x2)
  var c2y = p3.y - p4.y // (y3 - y4)
  var c3y = p1.y - p2.y // (y1 - y2)

  // down part of intersection point formula
  var d = c3x * c2y - c3y * c2x

  if (d == 0) {
    return null
    throw new Error('Number of intersection points is zero or infinity.')
  }

  // upper part of intersection point formula
  var u1 = p1.x * p2.y - p1.y * p2.x // (x1 * y2 - y1 * x2)
  var u4 = p3.x * p4.y - p3.y * p4.x // (x3 * y4 - y3 * x4)

  // intersection point formula

  var px = (u1 * c2x - c3x * u4) / d
  var py = (u1 * c2y - c3y * u4) / d

  var p = { x: px, y: py }

  return p
}

//图形工厂类
export class QedaGraphicsFactory {
  constructor() {}

  //正在绘制的物体
  newQedaGraphic(type, start_point) {
    let newGraphic
    switch (type) {
      case 'line':
        newGraphic = this.drawPath([[start_point.x, start_point.y]], true)
        break
      case 'ruler':
        newGraphic = this.drawRuler([[start_point.x, start_point.y]], true)
        break
      case 'label':
        let center = [start_point.x, start_point.y]
        let w = 12 * 5 * 0.6
        let h = 12
        let p1 = [center[0] - w / 2, center[1] - h / 2]
        let p2 = [center[0] - w / 2, center[1] + h / 2]
        let p3 = [center[0] + w / 2, center[1] + h / 2]
        let p4 = [center[0] + w / 2, center[1] - h / 2]
        newGraphic = this.drawLabel([p1, p2, p3, p4], true)
        break
      case 'keyPoint':
        newGraphic = this.drawKeyPoint([[start_point.x, start_point.y]], true)
        break
      case 'dogleg':
        newGraphic = this.drawPath([[start_point.x, start_point.y]], true)
        break
      case 'circle':
        newGraphic = this.drawEllipse(start_point.x, start_point.y)
        break
      case 'rectangle':
        newGraphic = this.drawRect(start_point.x, start_point.y, 0, 0, true)
        break
      case 'polygon':
        newGraphic = this.drawPolygon([[start_point.x, start_point.y]], true)
        break
      case 'selectBox':
        newGraphic = this.drawRect(start_point.x, start_point.y, 0, 0, true)
        break
      case 'adaptRect':
        newGraphic = this.drawRect(start_point.x, start_point.y, 0, 0, true)
        break
      case 'cutBox':
        newGraphic = this.drawRect(start_point.x, start_point.y, 0, 0, true)
        break
      case '3dArea':
        newGraphic = this.draw3DArea([[start_point.x, start_point.y]], true)
        break
      case '3dCutBox':
        newGraphic = this.draw3DCutBox(start_point.x, start_point.y, 0, 0, true)
        break
    }
    return newGraphic
  }

  //复制图形
  copy(data, pos) {
    let newGraphic
    //复制reference
    if (data.isRefBox) {
      let targetRef = data.reference
      let new_ref = new QedaReference()
      new_ref.name = targetRef.cell.name
      new_ref.magnification = targetRef.magnification
      new_ref.origin = [targetRef.origin[0], targetRef.origin[1]]
      new_ref.repetition = targetRef.repetition
      new_ref.rotation = targetRef.rotation
      new_ref.x_reflection = targetRef.x_reflection
      new_ref.cell = targetRef.cell
      new_ref.QREF = new QGdstk.Reference(new_ref.cell.QCELL, [new_ref.origin[0], new_ref.origin[1]], (new_ref.rotation * Math.PI) / 180, new_ref.magnification, false, 1, 1, null)
      let points = targetRef.cell.aabb
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      let box = new QedaGraphic() //QedaGraphics.drawPolygon([[0, 0]])
      box.type = QedaGraphicType.POLYGON
      box.hide = false
      box.lock = false
      new_ref.box = box
      box.isRefBox = true
      box.reference = new_ref
      box.setRefBoxPoints(rect.get_points())
      box.translate(pos[0], pos[1])
      box.updateShape()
      return new_ref.box
    }
    const points = data.gds_data_global.get_points()
    switch (data.type) {
      case QedaGraphicType.PATH:
        newGraphic = this.drawPath(points, false, data.width, data.radius)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.RULER:
        newGraphic = this.drawRuler(points)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.LABEL:
        newGraphic = this.drawLabel(getLabelBox(data.QDATA), false, data.QDATA.copy())
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        // newGraphic.QDATA = data.QDATA.copy()
        break
      case QedaGraphicType.CIRCLE:
        newGraphic = this.drawEllipse(pos[0], pos[1], data.r[0], data.r[1])
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.RECT:
        newGraphic = this.drawPolygon(points)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.POLYGON:
        newGraphic = this.drawPolygon(points, false)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.CELL3DAREA:
        newGraphic = this.draw3DArea(points, false)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
      case QedaGraphicType.CELL3DCUTBOX:
        newGraphic = this.drawPolygon(points)
        newGraphic.translate(pos[0], pos[1])
        newGraphic.updateShape()
        newGraphic.type = QedaGraphicType.CELL3DCUTBOX
        newGraphic.layer = data.layer
        newGraphic.hide = data.hide
        newGraphic.lock = data.lock
        break
    }
    return newGraphic
  }
  //复制引用下的图形到全局
  copyRefPolygonsToGlobal(data, ref) {
    if (data.box) {
      //引用盒子拷贝
      let new_position = [data.box.position[0], data.box.position[1]]
      new_position[0] += ref.origin[0]
      new_position[1] += ref.origin[1]

      let refBox = data.box
      let targetRef = refBox.reference
      let new_ref = new QedaReference()
      new_ref.name = targetRef.cell.name
      new_ref.magnification = targetRef.magnification
      new_ref.origin = [ref.origin[0] + data.box.position[0], ref.origin[1] + data.box.position[1]]
      new_ref.repetition = targetRef.repetition
      new_ref.rotation = targetRef.rotation + ref.rotation
      new_ref.x_reflection = targetRef.x_reflection
      new_ref.cell = targetRef.cell
      new_ref.QREF = new QGdstk.Reference(new_ref.cell.QCELL, [new_ref.origin[0], new_ref.origin[1]], (new_ref.rotation * Math.PI) / 180, new_ref.magnification, false, 1, 1, null)
      let points = targetRef.cell.aabb
      let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      let box = new QedaGraphic() //QedaGraphics.drawPolygon([[0, 0]])
      box.type = QedaGraphicType.POLYGON
      box.hide = false
      box.lock = false
      new_ref.box = box
      box.isRefBox = true
      box.reference = new_ref
      box.setRefBoxPoints(rect.get_points())
      // box.translate(new_position[0], new_position[1])
      // box.rotation = ref.rotation

      box.updateShape()
      return box
      // let temp = new QGdstk.Polygon([data.box.position])
      // temp.rotate((ref.rotation * Math.PI) / 180)
      // let scale = ref.magnification
      // temp.scale(scale, scale)
      // temp.translate([ref.origin[0], ref.origin[1]])
      // let new_posiiton = temp.get_points()[0]

      // let new_position = [data.box.position[0], data.box.position[1]]
      // new_position[0] += ref.origin[0]
      // new_position[1] += ref.origin[1]

      // let refBox = QedaGraphics.copy(data.box, new_position)
      // return refBox
    }
    let newGraphic
    //拷贝引用下的图形到全局
    const local_points = data.gds_data_global.get_points()
    let temp = new QGdstk.Polygon(local_points)
    let scale = ref.magnification
    temp.scale(scale, scale * ref.x_reflection)
    temp.rotate((ref.rotation * Math.PI) / 180)
    temp.translate([ref.origin[0], ref.origin[1]])
    let points = temp.get_points()
    let newPos = getRefTransformPos([data.position], ref)[0]
    if (data.isRefBox) {
      // let targetRef = data.reference
      // let points = targetRef.cell.aabb
      // let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
      // data.setRefBoxPoints(rect.get_points())
      data.position[0] += ref.origin[0]
      data.position[1] += ref.origin[1]
      data.rotation += ref.rotation
      data.updateShape()
      return data
    }
    switch (data.type) {
      case QedaGraphicType.PATH:
        newGraphic = this.drawPath(points, data.width, data.radius)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.CIRCLE:
        newGraphic = this.drawEllipse(newPos[0], newPos[1], data.r[0], data.r[1])
        newGraphic.layer = data.layer
        break
      case QedaGraphicType.RECT:
        newGraphic = this.drawPolygon(points)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.POLYGON:
        newGraphic = this.drawPolygon(points, false)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
      case QedaGraphicType.LABEL:
        let new_QDATA = data.QDATA.copy()
        new_QDATA.origin[0] = newPos[0]
        new_QDATA.origin[1] = newPos[1]
        newGraphic = this.drawLabel(points, false, new_QDATA)
        newGraphic.layer = data.layer
        newGraphic.updateShape()
        break
    }
    return newGraphic
  }
  //路径
  drawPath(arr, drawing = false, width = 0, radius = 0) {
    const path = new QedaGraphic()
    path.type = QedaGraphicType.PATH
    path.drawing = drawing
    path.width = width
    path.radius = radius
    path.setPoints(arr)
    path.updateSlopes()
    return path
  }

  //绘制尺子
  drawRuler(arr, drawing = false) {
    const path = new QedaGraphic()
    path.type = QedaGraphicType.RULER
    path.lock = false
    path.hide = false
    path.drawing = drawing
    path.setPoints(arr)
    path.updateSlopes()
    return path
  }

  //绘制标签
  drawLabel(arr, drawing = false, Qdata) {
    const label = new QedaGraphic()
    label.type = QedaGraphicType.LABEL
    label.drawing = drawing
    label.QDATA = Qdata
    label.layer = Qdata.layer
    label.setPoints(arr)
    return label
  }

  //绘制关键点
  drawKeyPoint(arr, drawing = false) {
    const point = new QedaGraphic()
    point.type = QedaGraphicType.KEYPOINT
    point.drawing = drawing
    point.setPoints(arr)
    return point
  }
  //矩形
  drawRect(x, y, w = 0, h = 0, drawing = false) {
    const rect = new QedaGraphic()
    rect.type = QedaGraphicType.RECT
    rect.drawing = drawing
    let arr = [
      [x, y],
      [x, y + h],
      [x + w, y + h],
      [x + w, y],
    ]
    window.STARTPOS = [x, y]
    rect.setPoints(arr)
    rect.updateSlopes()
    return rect
  }

  //多边形
  drawPolygon(arr, drawing = false) {
    const polygon = new QedaGraphic()
    polygon.type = QedaGraphicType.POLYGON
    polygon.drawing = drawing
    polygon.setPoints(arr)
    polygon.updateSlopes()
    return polygon
  }

  //圆形 椭圆
  drawEllipse(x, y, rx = 0, ry = rx) {
    const ellipse = new QedaGraphic()
    ellipse.type = QedaGraphicType.CIRCLE
    ellipse.position = [x, y]
    window.STARTPOS = [x, y]
    ellipse.r = [rx, ry]
    ellipse.setPoints()
    ellipse.updateSlopes()
    return ellipse
  }

  //绘制3D区域
  draw3DArea(arr, drawing = false) {
    const polygon = new QedaGraphic()
    polygon.type = QedaGraphicType.CELL3DAREA
    polygon.drawing = drawing
    polygon.setPoints(arr)
    polygon.updateSlopes()
    return polygon
  }

  //绘制剖面区域
  draw3DCutBox(x, y, w = 0, h = 0, drawing = false) {
    const rect = new QedaGraphic()
    rect.type = QedaGraphicType.CELL3DCUTBOX
    rect.drawing = drawing
    let arr = [
      [x, y],
      [x, y + h],
      [x + w, y + h],
      [x + w, y],
    ]
    window.STARTPOS = [x, y]
    rect.setPoints(arr)
    rect.updateSlopes()
    return rect
  }
}
const QedaGraphics = new QedaGraphicsFactory()
//判断命中图形
export function checkHit(mousePoint, obj, ctrl = false) {
  let adsorption = STAGE.myaxis.adsorpPoint //吸附
  if (STAGE.checkMode === 0) {
    let flag = false
    //整体选择
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    switch (obj.type) {
      case QedaGraphicType.PATH:
        return insidePath(mousePoint, obj.shape)
      case QedaGraphicType.RULER:
        return insidePath(mousePoint, obj.shape)
      case QedaGraphicType.LABEL:
        return obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
      case QedaGraphicType.POLYGON:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        // if (!flag && adsorption) {
        //   flag = checkHitPointAndLine(mousePoint, obj)
        // }
        return flag
      // return insidePolygon(mousePoint, obj.shape)
      case QedaGraphicType.RECT:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        // if (!flag && adsorption) {
        //   flag = checkHitPointAndLine(mousePoint, obj)
        // }
        return flag
      // return insidePolygon(mousePoint, obj.shape)
      case QedaGraphicType.CIRCLE:
        let r = [obj.r[0], obj.r[1]]
        if (adsorption) {
          r[0] += STAGE.pick_dist
          r[1] += STAGE.pick_dist
        }
        if (r[0] !== r[1]) {
          return insideEllipse(mousePoint, obj.position, r)
        }
        return insideCircle(mousePoint, obj.position, r, obj._scale)
      case QedaGraphicType.CELL3DAREA:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        return flag
      case QedaGraphicType.CELL3DCUTBOX:
        flag = obj.gds_data_global.contain([mousePoint.x, mousePoint.y])
        return flag
      default:
        return false
    }
  } else if (STAGE.checkMode === 1) {
    //部分选择
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    let removeCheckedPoints = false
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          //判断是否重复选择点
          let repeatChoose = hit_points.every(hit => obj.checked_points_index.includes(hit))
          if (repeatChoose) return
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.POLYGON:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          //移除点

          // if(hit_points.length == 2){
          //     if(hit_points[1] === obj.shape.length - 1){
          //         hit_points = [hit_points[1]]
          //     }else{
          //         hit_points = [hit_points[0]]
          //     }

          // }
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.RECT:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.CIRCLE:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        //圆外包矩形不能选择一个点
        if (obj.checked_points_index.length == 1) {
          obj.checked_points_index = []
        }
        obj.updateCheckedPaths()
        obj.updateLines()
        return obj.checked_points_index.length
      case QedaGraphicType.CELL3DAREA:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      case QedaGraphicType.CELL3DCUTBOX:
        hit_points = hited_points(mousePoint, obj.shape, STAGE.pick_dist, true)
        if (ctrl) {
          removeCheckedPoints = hit_points.some(hit => obj.checked_points_index.includes(hit))
          //移除点
          obj.checked_points_index = obj.checked_points_index.filter(p => !hit_points.includes(p))
        } else {
          obj.checked_points_index.push(...hit_points)
        }
        obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
        obj.checked_points_index.sort((a, b) => a - b) //排序
        obj.updateCheckedPaths()
        obj.updateLines()
        if (ctrl) {
          //判断是否取消选择了选中的点
          if (!removeCheckedPoints) return false
        }

        return obj.checked_points_index.length
      default:
        return false
    }
  } else if (STAGE.checkMode === 8) {
    //点对齐
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.LABEL:
        //判断关键点
        if (obj.QDATA.get_gds_property(1)) {
          hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, false, false, true)
        }
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      case QedaGraphicType.CELL3DAREA:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CELL3DCUTBOX:
        hit_points = hited_points_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      default:
        return false
    }
  } else if (STAGE.checkMode === 9) {
    //线对齐
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      case QedaGraphicType.CELL3DAREA:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CELL3DCUTBOX:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      default:
        return false
    }
  } else if (STAGE.checkMode === 10) {
    //选择线
    if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
    let hit_points //命中的点
    switch (obj.type) {
      case QedaGraphicType.PATH:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.RULER:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      case QedaGraphicType.POLYGON:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.RECT:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CIRCLE:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true, true)
        return hit_points
      case QedaGraphicType.CELL3DAREA:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist, true)
        return hit_points
      case QedaGraphicType.CELL3DCUTBOX:
        hit_points = hited_lines_alignment(mousePoint, obj, STAGE.pick_dist)
        return hit_points
      default:
        return false
    }
  }
}

export function checkHitPointAndLine(mousePoint, obj, adsorption, scale = 1) {
  let pick_dist = STAGE.pick_dist / scale
  if (!insideAABB_expand(mousePoint, obj.aabb, pick_dist)) return false //扩大包围盒过滤
  let hit_points //命中的点
  switch (obj.type) {
    case QedaGraphicType.PATH:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, false, adsorption)
      if (hit_points?.point) return hit_points
      if (adsorption) {
        if (obj.pathBody?.length) {
          for (let index = 0; index < obj.pathBody.length; index++) {
            const body = obj.pathBody[index]
            hit_points = hited_points_alignment(mousePoint, { shape: body, position: obj.position }, pick_dist, false, false, false, adsorption)
            if (hit_points?.point) return hit_points
          }
        }
      }

      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist)
        if (hit_points?.lines?.length) return hit_points
        if (adsorption) {
          if (obj.pathBody?.length) {
            for (let index = 0; index < obj.pathBody.length; index++) {
              const body = obj.pathBody[index]
              hit_points = hited_lines_alignment(mousePoint, { shape: body, position: obj.position }, pick_dist, true)
              if (hit_points?.lines?.length) return hit_points
            }
          }
        }
      }
      return hit_points
    case QedaGraphicType.RULER:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, false, false)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist)
      }
      return hit_points
    case QedaGraphicType.LABEL:
      //判断关键点
      if (obj.QDATA.get_gds_property(1)) {
        hit_points = hited_points_alignment(mousePoint, obj, pick_dist, false, false, true, adsorption)
      }
      return hit_points
    case QedaGraphicType.POLYGON:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    case QedaGraphicType.RECT:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    case QedaGraphicType.CIRCLE:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, true, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true, true)
      }
      return hit_points
    case QedaGraphicType.CELL3DAREA:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    case QedaGraphicType.CELL3DCUTBOX:
      hit_points = hited_points_alignment(mousePoint, obj, pick_dist, true, false, false, adsorption)
      if (!hit_points?.point) {
        hit_points = hited_lines_alignment(mousePoint, obj, pick_dist, true)
      }
      return hit_points
    default:
      return false
  }
}

//判断命中图形之前选择部分
export function checkHitBeforePart(mousePoint, obj) {
  if (!insideAABB_expand(mousePoint, obj.aabb, STAGE.pick_dist)) return false //扩大包围盒过滤
  let result
  switch (obj.type) {
    case QedaGraphicType.PATH:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.POLYGON:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.RECT:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.CIRCLE:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集
      return result
    case QedaGraphicType.CELL3DAREA:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    case QedaGraphicType.CELL3DCUTBOX:
      result = isIntersection(hited_points(mousePoint, obj.shape, STAGE.pick_dist, true), obj.checked_points_index) //判断点击的点和之前选中的点是否有交集

      return result
    default:
      return false
  }
}

function isIntersection(arr1, arr2) {
  const length_1 = arr1.length
  const length_2 = arr2.length
  for (let i = 0; i < length_1; i++) {
    for (let j = 0; j < length_2; j++) {
      if (arr1[i] === arr2[j]) {
        return true
      }
    }
  }
  return false
}
//判断在包围盒内
export function insideAABB(point, aabb) {
  return aabb[0][0] < point.x && aabb[0][1] < point.y && aabb[1][0] > point.x && aabb[1][1] > point.y
}
//判断在扩大包围盒内
export function insideAABB_expand(point, aabb, expand) {
  return aabb[0][0] - expand < point.x && aabb[0][1] - expand < point.y && aabb[1][0] + expand > point.x && aabb[1][1] + expand > point.y
}

//判断多边形中心点
function centroid(arr) {
  // arr = JSON.parse(JSON.stringify(arr));
  const x = arr.map(function (a) {
    return a[0]
  })
  const y = arr.map(function (a) {
    return a[1]
  })
  const minX = Math.min.apply(null, x)
  const maxX = Math.max.apply(null, x)
  const minY = Math.min.apply(null, y)
  const maxY = Math.max.apply(null, y)
  return [(minX + maxX) / 2, (minY + maxY) / 2]
}

//判断点是否落在圆形内
function insideCircle(point, position, r, _scale) {
  const dist_points = (point.x - position[0]) * (point.x - position[0]) + (point.y - position[1]) * (point.y - position[1])
  return Math.sqrt(dist_points) < r[0] * _scale[0]
}

//判断点是否落在椭圆内
export function insideEllipse(point, position, r) {
  const p = Math.pow(point.x - position[0], 2) / Math.pow(r[0], 2) + Math.pow(point.y - position[1], 2) / Math.pow(r[1], 2)

  return p <= 1
}

//判断点是否落在矩形内
// function insideRect(point, rect) {
//     let x = point.x, y = point.y;

//     let inside = false;
//     return x <= x && x <= x + this.width &&
//         y <= y && y <= this.y + this.height;
// }

//判断点是否落在多边形内
function insidePolygon(point, polygon) {
  let x = point.x,
    y = point.y

  let inside = false
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].x,
      yi = polygon[i].y
    let xj = polygon[j].x,
      yj = polygon[j].y

    let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

//判断点是否落在路径上 循环和每一条线段比较
function insidePath(mouse_point, arr, dist = 10) {
  dist = STAGE.pick_dist
  let near = false
  for (let i = 0; i < arr.length - 1; i++) {
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      near = true
      break
    }
  }
  return near
}
//部分选择
function hited_points(mouse_point, arr, dist = 10, close = false, circle = false) {
  dist = STAGE.pick_dist
  let result = []
  const length = arr.length
  if (!circle) {
    for (let i = 0; i < length; i++) {
      //判断命中点
      let point = arr[i]
      const x = point[0] - mouse_point.x
      const y = point[1] - mouse_point.y
      if (Math.sqrt(x * x + y * y) <= dist) {
        result.push(i)
        return result
      }
    }
  }

  for (let i = 0; i < length - 1; i++) {
    //点未命中判断边
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      result.push(i, i + 1)
      return result
    }
  }
  if (close) {
    //封闭多边形
    const first = arr[0]
    const last = arr[length - 1]
    if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
      result.push(0, length - 1)
    }
  }

  return result
}
//对齐选择
function hited_points_alignment(mouse_point, obj, dist = 10, close = false, circle = false, label = false, can_pick_extra = true) {
  // dist = STAGE.pick_dist
  let result = { index: null, point: null, line_indexes: [], lines: [] }
  let arr = obj.shape
  if (label) {
    arr = [obj.position]
  }
  if (STAGE.checkMode_bk === 1 && STAGE.alignmentPoints.length === 0) {
    //部分选择对齐第一个点只能在旋转点里选择
    const checkIndexs = obj.checked_points_index
    arr = obj.shape.filter((sp, i) => checkIndexs.indexOf(i) !== -1)
  }
  const length = arr.length
  const last = length - 1
  const p_m = [mouse_point.x, mouse_point.y]
  // let can_pick_extra = true //选取额外的点 中点 中心点
  // if(STAGE.checkMode_bk === 1){
  //     can_pick_extra = STAGE.alignmentPoints.length !== 0 //对齐操作第二点无法拾取中心点，原点
  // }
  // if (!close) {
  //无宽度的线
  for (let i = 0; i < length; i++) {
    //点未命中判断边
    let start = arr[i]
    if (pointsInsideDist(start, p_m, dist)) {
      result.index = i
      result.point = start
      return result
    }
    if (can_pick_extra) {
      if (close) {
        let end = arr[i + 1]
        if (i === last) {
          end = arr[0]
        }
        const mid_point = midpoint(start, end)
        if (pointsInsideDist(mid_point, p_m, dist)) {
          result.index = 'midPoint'
          result.point = mid_point
          return result
        }
      } else {
        if (i !== last) {
          let end = arr[i + 1]
          const mid_point = midpoint(start, end)
          if (pointsInsideDist(mid_point, p_m, dist)) {
            result.index = 'midPoint'
            result.point = mid_point
            return result
          }
        }
      }
    }
  }
  // return result
  // }
  if (can_pick_extra) {
    if (pointsInsideDist(obj.position, p_m, dist)) {
      //判断中心点
      result.index = 'center'
      result.point = obj.position
      return result
    }
  }

  // if(circle && !can_pick_extra){ //圆对齐只能选择边
  //     for (let i = 0; i < length - 1; i++) { //点未命中判断边
  //         let start = arr[i]
  //         let end = arr[i + 1]
  //         if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
  //             result.line_indexes.push(i, i + 1)
  //             result.lines.push([start, end])
  //             return result
  //         }
  //     }
  //     const first = arr[0]
  //     const last = arr[length - 1]
  //     if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
  //         result.line_indexes.push(0, length - 1)
  //         result.lines.push([first, last])
  //         return result
  //     }
  //     return result
  // }else{ //多边形
  //     for (let i = 0; i < length; i++) { //判断命中点
  //         if (pointsInsideDist(arr[i], p_m, dist)) {
  //             result.index = i
  //             result.point = arr[i]
  //             return result
  //         }
  //     }
  // }
  if (!circle) {
    //多边形
    for (let i = 0; i < length; i++) {
      //判断命中点
      if (pointsInsideDist(arr[i], p_m, dist)) {
        result.index = i
        result.point = arr[i]
        return result
      }
    }
  }
  return result
}

function hited_lines_alignment(mouse_point, obj, dist = 10, close = false, circle = false) {
  // dist = STAGE.pick_dist
  let result = { point_indexes: [], lines: [] }
  let arr = obj.shape
  let insideLines = false
  if (STAGE.checkMode_bk === 1) {
    //部分选择
    if (STAGE.alignmentLines.length === 0) {
      //第一个点
      if (obj.checked_paths.length) {
        insideLines = true
      } else {
        return
      }
    }
  }
  let compareSlope = false
  if (STAGE.alignmentLines.length !== 0) {
    compareSlope = true
  }
  const length = arr.length
  for (let i = 0; i < length - 1; i++) {
    //判断边
    let start = arr[i]
    let end = arr[i + 1]
    if (distanceToLineSegment(start[0], start[1], end[0], end[1], mouse_point.x, mouse_point.y) <= dist) {
      if (compareSlope) {
        //判断平行线
        if (isSameSlope(obj.slopes[i], STAGE.alignmentLineSlope)) {
          // result.point_indexes.push(i, i + 1)
          result.lines.push([start, end])
          return result
        }
        const slope_v = getVerticalSlope(obj.slopes[i]) //线的垂直斜率
        if (isSameSlope(slope_v, STAGE.alignmentLineSlope)) {
          result.lines.push(getMidVertialLine(start, end, slope_v))
          return result
        }
      } else {
        if (insideLines) {
          const indexs = obj.checked_points_index

          if (indexs.indexOf(i) !== -1 && indexs.indexOf(i + 1) !== -1) {
            //判断已选择
            // result.point_indexes.push(i, i + 1)
            result.lines.push([start, end])
            return result
          }
        } else {
          // result.point_indexes.push(i, i + 1)
          result.lines.push([start, end])
          return result
        }
      }
    }
  }
  if (close) {
    //判断封闭边
    const first = arr[0]
    const last = arr[length - 1]
    if (distanceToLineSegment(first[0], first[1], last[0], last[1], mouse_point.x, mouse_point.y) <= dist) {
      if (compareSlope) {
        if (isSameSlope(obj.slopes[length - 1], STAGE.alignmentLineSlope)) {
          //平行
          // result.point_indexes.push(0, length - 1)
          result.lines.push([first, last])
          return result
        }
        const slope_v = getVerticalSlope(obj.slopes[length - 1]) //线的垂直斜率
        if (isSameSlope(slope_v, STAGE.alignmentLineSlope)) {
          result.lines.push(getMidVertialLine(first, last, slope_v))
          return result
        }
      } else {
        // result.point_indexes.push(0, length - 1)
        if (insideLines) {
          const indexs = obj.checked_points_index
          if (indexs.indexOf(0) !== -1 && indexs.indexOf(length - 1) !== -1) {
            //判断已选择
            result.point_indexes.push(0, length - 1)
            result.lines.push([first, last])
            return result
          }
        } else {
          result.lines.push([first, last])
          return result
        }
      }
    }
  }
  return result
}

function isSameSlope(s1, s2) {
  if (!s1 || !s2) {
    return s1 === s2
  } else {
    return s1.toFixed(2) === s2.toFixed(2)
  }
}

function getMidVertialLine(p1, p2, slope) {
  const mid_p = midpoint(p1, p2)
  const line = slopeAndIntercept(mid_p, [0, 0], slope)
  const p_start = [0, 0]
  const p_end = [0, 0]
  const d = 10 / AXIS.scale
  // 线的角度
  var theta = Math.atan(slope)
  if (slope === null) {
    theta = Math.PI / 2
  }

  //计算附近两点
  p_start[0] = mid_p[0] + d * Math.cos(theta)
  p_start[1] = mid_p[1] + d * Math.sin(theta)

  p_end[0] = mid_p[0] - d * Math.cos(theta)
  p_end[1] = mid_p[1] - d * Math.sin(theta)

  return [p_start, p_end]
}

function midpoint(p1, p2) {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2]
}

function pointsInsideDist(p1, p2, dist) {
  const x = p1[0] - p2[0]
  const y = p1[1] - p2[1]
  return Math.sqrt(x * x + y * y) <= dist
}

//判断多边形自相交
function isSelfIntersection(ring, close = true) {
  if (ring.length < 3) {
    return false
  }
  let rings = JSON.parse(JSON.stringify(ring))
  // rings.pop();
  if (close) {
    rings.push(rings[0])
  }
  const arr = []
  const data = rings

  for (let i = 0; i < data.length - 3; i++) {
    for (let j = i + 2; j <= data.length - 2; j++) {
      let a = data[i]
      let b = data[i + 1]
      let c = data[j]
      let d = data[j + 1]
      if (a === d) continue
      if (lineSegmentsIntersect(a[0], a[1], b[0], b[1], c[0], c[1], d[0], d[1])) {
        return true
      }
    }
  }
  return false
}

//判断两条线相交
function lineSegmentsIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  var a_dx = x2 - x1
  var a_dy = y2 - y1
  var b_dx = x4 - x3
  var b_dy = y4 - y3
  var s = (-a_dy * (x1 - x3) + a_dx * (y1 - y3)) / (-b_dx * a_dy + a_dx * b_dy)
  var t = (+b_dx * (y1 - y3) - b_dy * (x1 - x3)) / (-b_dx * a_dy + a_dx * b_dy)
  return s >= 0 && s <= 1 && t >= 0 && t <= 1
}

//判断两条线相交
// function segmentsIntr(a, b, c, d) {
//     /** 1 解线性方程组, 求线段交点. **/
//     // 如果分母为0 则平行或共线, 不相交
//     let denominator = (b.y - a.y) * (d.x - c.x) - (a.x - b.x) * (c.y - d.y);
//     if (denominator == 0) {
//         return false;
//     }
//     // 线段所在直线的交点坐标 (x , y)
//     let x =
//         ((b.x - a.x) * (d.x - c.x) * (c.y - a.y) +
//             (b.y - a.y) * (d.x - c.x) * a.x -
//             (d.y - c.y) * (b.x - a.x) * c.x) /
//         denominator;
//     let y =
//         -(
//             (b.y - a.y) * (d.y - c.y) * (c.x - a.x) +
//             (b.x - a.x) * (d.y - c.y) * a.y -
//             (d.x - c.x) * (b.y - a.y) * c.y
//         ) / denominator;
//     /** 2 判断交点是否在两条线段上 **/
//     if (
//         // 交点在线段1上
//         (x - a.x) * (x - b.x) <= 0 &&
//         (y - a.y) * (y - b.y) <= 0 &&
//         // 且交点也在线段2上
//         (x - c.x) * (x - d.x) <= 0 &&
//         (y - c.y) * (y - d.y) <= 0
//     ) {
//         // 返回交点p
//         return { x: x, y: y };
//     }
//     //否则不相交
//     return false;
// }

function segmentsIntr(a, b, c, d) {
  // 三角形abc 面积的2倍
  var area_abc = (a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])

  // 三角形abd 面积的2倍
  var area_abd = (a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0])

  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd > 0) {
    return false
  }

  // 三角形cda 面积的2倍
  var area_cda = (c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0])
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  var area_cdb = area_cda + area_abc - area_abd
  if (area_cda * area_cdb > 0) {
    return false
  }

  //计算交点坐标
  var t = area_cda / (area_abd - area_abc)
  var dx = t * (b[0] - a[0]),
    dy = t * (b[1] - a[1])
  return [a[0] + dx, a[1] + dy]
}

function getRectFromAABB(aabb) {
  const minX = aabb[0][0]
  const minY = aabb[0][1]
  const maxX = aabb[1][0]
  const maxY = aabb[1][1]

  return [
    { x: minX, y: minY },
    { x: minX, y: maxY },
    { x: maxX, y: maxY },
    { x: maxX, y: minY },
  ]
}

function cloneArray(arry) {
  const result = []
  const length = arry.length
  for (let i = 0; i < length; i++) {
    result.push([arry[i][0], arry[i][1]])
  }
  return result
}

export function sliceLines(lines, val, axis) {
  const aabb = STAGE.checkedGraphicsAABB
  let min_x = STAGE.checkedGraphicsAABB[0][0]
  let min_y = STAGE.checkedGraphicsAABB[0][1]
  let max_x = STAGE.checkedGraphicsAABB[1][0]
  let max_y = STAGE.checkedGraphicsAABB[1][1]
  min_y -= 10
  max_y += 10
  min_x -= 10
  max_x += 10
  let line_slice = []
  if (axis === 'x') {
    line_slice = [
      [val, min_y],
      [val, max_y],
    ]
  } else {
    line_slice = [
      [min_x, val],
      [max_x, val],
    ]
  }

  const result = []
  const len = lines.length
  for (let i = 0; i < len; i++) {
    result.push({ result: sliceLine(lines[i], line_slice), layer: lines[i].layer, width: lines[i].width, radius: lines[i].radius })
  }
  return result
}

function sliceLine(path, line_slice) {
  const result = []
  const points = path.gds_data_global.get_points()
  const len = points.length
  let path_new = []
  for (let i = 0; i < len - 1; i++) {
    const p1 = points[i]
    const p2 = points[i + 1]

    const p_intersection = segmentsIntr(p1, p2, line_slice[0], line_slice[1])
    path_new.push(p1)
    if (p_intersection) {
      //存在交点
      path_new.push(p_intersection)
      let path_new_copy = deepClone(path_new)
      if (curve_del_parallel(path_new_copy).length > 1) {
        result.push(deepClone(path_new_copy))
      }
      path_new = []
      path_new.push(p_intersection)
    }
  }
  path_new.push(points[len - 1])
  if (curve_del_parallel(path_new).length > 1) {
    result.push(path_new)
  }
  // path.deleted = true
  return result
}

export function getFillAndBorderImgs() {
  const fillImgs = []
  const borderImgs = []
  const rect_fill = CanvasKit.LTRBRect(0, 0, 250, -250)
  const fill_pt = new CanvasKit.Paint()
  const fillFact = CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理

  // let canvas = document.createElement('canvas')
  // canvas.id = 'img_temp'
  for (let key in QedaGraphicFillTypeImg) {
    fill_pt.setShader(fillFact.makeShader([...CanvasKit.BLACK, ...QedaGraphicFillType[key], 0, 0, 1]))
    const surface = CanvasKit.MakeCanvasSurface('fill_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    // const outline_pt = this.stage.layer_paints_img[index]
    canvas.save()
    canvas.scale(3, 3)
    canvas.drawRect(rect_fill, fill_pt)
    canvas.restore()

    surface.flush()
    let data = surface.makeImageSnapshot().encodeToBytes()
    let blob = new Blob([data], { type: 'image/jpeg' })
    data = null // 要置null，否则存在内存泄漏风险
    let url = URL.createObjectURL(blob)
    fillImgs.push(url)
  }

  const rect_border = CanvasKit.LTRBRect(0, 0, 11.5, -6)
  const outline_pt = new CanvasKit.Paint()
  for (let key in QedaGraphicBorderTypeImg) {
    const dep = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[key])
    outline_pt.setPathEffect(dep)
    outline_pt.setColor(CanvasKit.BLACK)
    outline_pt.setStyle(CanvasKit.PaintStyle.Stroke)
    outline_pt.setStrokeWidth(1.5)
    const surface = CanvasKit.MakeCanvasSurface('border_img_temp')
    const canvas = surface.getCanvas()
    canvas.scale(1, -1)
    canvas.translate(0, 0)
    canvas.clear(CanvasKit.WHITE)
    canvas.save()
    canvas.scale(30, 30)
    canvas.drawRect(rect_border, outline_pt)
    canvas.restore()
    surface.flush()
    let border_data = surface.makeImageSnapshot().encodeToBytes()
    let border_blob = new Blob([border_data], { type: 'image/jpeg' })
    border_data = null // 要置null，否则存在内存泄漏风险
    let border_url = URL.createObjectURL(border_blob)
    borderImgs.push(border_url)
  }
  return { fillImgs, borderImgs }
}

export function stretchPoint(target, start, to_point) {
  // if(Array.isArray(index)){
  //     index.forEach(ind=>target.shape[ind] = to_point)
  //     target.checked_points_index = index
  // }else{
  //     target.shape[index] = to_point
  //     target.checked_points_index = [index]
  // }
  // target.shape[index] = to_point
  // target.checked_points_index = [index]
  target.updatePointsMouseOffset({ x: start[0], y: start[1] })
  //移动所有选中点

  const sp = target.shape
  const checked_length = target.checked_points_index.length
  for (let i = 0; i < checked_length; i++) {
    const index = target.checked_points_index[i]
    const p = sp[index]
    const off_set = target.checked_points_mouse_offset[i]
    p[0] = to_point[0] + off_set.x
    p[1] = to_point[1] + off_set.y
  }
  const p_end = { x: to_point[0], y: to_point[1] }

  const total_size = sp.length //点的总数
  const check_all = total_size === checked_length
  if (STAGE.isStretch && !check_all) {
    const total_last_index = total_size - 1
    target.checked_paths = []
    target.updateLines()
    if (target.type === QedaGraphicType.PATH) {
      //线
      const line_length = target.checked_lines.length
      let off_set_index = 0
      for (let i = 0; i < line_length; i++) {
        moveLines(target, p_end, total_last_index, target.checked_lines[i], off_set_index)
        off_set_index += target.checked_lines[i].length
      }
    } else {
      //多边形
      const line_length = target.checked_lines.length
      for (let i = 0; i < line_length; i++) {
        movePolygonLines(target, total_last_index, target.checked_lines[i], total_size, sp)
      }
    }
  }
  target.checked_points_mouse_offset = []
  target.stretchConfirm()
  target.updateCheckedPaths()
}
// export function stretchLines(target, point_offset){
//     target.updatePointsMouseOffset({x:start[0], y:start[1]})
//     //移动所有选中点

//     const sp = target.shape
//     const checked_length = target.checked_points_index.length
//     for (let i = 0; i < checked_length; i++) {
//         const index = target.checked_points_index[i]
//         const p = sp[index]
//         const off_set = target.checked_points_mouse_offset[i]
//         p[0] = to_point[0] + off_set.x
//         p[1] = to_point[1] + off_set.y
//     }
//     const p_end = {x: to_point[0], y: to_point[1]}

//     const total_size = sp.length //点的总数
//     const check_all = total_size === checked_length
//     if(STAGE.isStretch && !check_all){
//         const total_last_index = total_size - 1
//         target.checked_paths = []
//         target.updateLines()
//         if (target.type === QedaGraphicType.PATH) { //线
//             const line_length = target.checked_lines.length
//             let off_set_index = 0
//             for (let i = 0; i < line_length; i++) {
//                 moveLines(target, p_end, total_last_index, target.checked_lines[i], off_set_index)
//                 off_set_index += target.checked_lines[i].length
//             }
//         } else {//多边形
//             const line_length = target.checked_lines.length
//             for (let i = 0; i < line_length; i++) {
//                 movePolygonLines(target, total_last_index, target.checked_lines[i], total_size, sp)
//             }
//         }
//     }
//     target.checked_points_mouse_offset = []
//     target.stretchConfirm()
//     target.updateCheckedPaths()
// }

//获取label包围盒
export function getLabelBox(label) {
  //   label.anchor = 'e' //debug
  let anchor = label.anchor
  if (!label.get_gds_property(0)) {
    label.set_gds_property(0, JSON.stringify(30)) //字体大小默认30
  }
  let font_size = JSON.parse(label.get_gds_property(0))
  let w = label.text.length * font_size * 0.6
  let isKeyPoint = label.get_gds_property(1)
  if (isKeyPoint) {
    let params = JSON.parse(isKeyPoint)
    if (params === true) {
      anchor = 'o'
    } else {
      if (!params?.create_label) {
        anchor = 'o'
      }
    }
  }
  //没有文字的关键点或者没有文字默认一个文字单位
  if ((isKeyPoint && w == 0) || w == 0) {
    w = font_size * 0.6
  }
  let h = 1 * font_size
  let center = [label.origin[0], label.origin[1]]
  let p1 = [center[0] - w / 2, center[1] - h / 2]
  let p2 = [center[0] - w / 2, center[1] + h / 2]
  let p3 = [center[0] + w / 2, center[1] + h / 2]
  let p4 = [center[0] + w / 2, center[1] - h / 2]
  let temp = new QGdstk.Polygon([p1, p2, p3, p4])
  if (anchor == 'o') {
  } else if (anchor == 'e') {
    temp.translate([-w / 2, 0])
  } else if (anchor == 's') {
    temp.translate([0, h / 2])
  } else if (anchor == 'w') {
    temp.translate([w / 2, 0])
  } else if (anchor == 'n') {
    temp.translate([0, -h / 2])
  } else if (anchor == 'ne') {
    temp.translate([-w / 2, -h / 2])
  } else if (anchor == 'se') {
    temp.translate([-w / 2, h / 2])
  } else if (anchor == 'nw') {
    temp.translate([w / 2, -h / 2])
  } else if (anchor == 'sw') {
    temp.translate([w / 2, h / 2])
  }
  return temp.get_points()
}

export function get_path_polygons(points, width, radius) {
  let res = []
  if (!width) return res
  radius = parseFloat(radius)
  let type = radius > 0 ? 'round' : 'natural'
  let path = new QGdstk.FlexPath(points, width, 0, type, 'flush', radius, null, 1e-2, false, true, 0, 0)
  let polygons = path.to_polygons()
  polygons.forEach(p => {
    res.push(p.get_points())
  })

  return res
}

export function getRefTransformPos(arr, ref) {
  let temp = new QGdstk.Polygon(arr)
  let scale = ref.magnification
  temp.scale(scale, scale * ref.x_reflection)
  temp.rotate((ref.rotation * Math.PI) / 180)
  temp.translate([ref.origin[0], ref.origin[1]])
  return temp.get_points()
}

export function isRectangle(x1, y1, x2, y2, x3, y3, x4, y4) {
  let s = getSlope([x1, y1], [x2, y2])

  if (s === 0 || s === null) {
    if (x1 + x3 == x2 + x4 && y1 + y3 == y2 + y4) {
      return true
    }
  }
  return false
}

function isValidPathPoint(points, point) {
  let last = points.at(-2)
  if (last && last[0] === point[0] && last[1] === point[1]) {
    return false
  }
  let len = points.length
  let last_index = len - 1
  for (let index = 0; index < len; index++) {
    const p = points[index]
    if (index == last_index) break
    if (p[0] == point[0] && p[1] == point[1]) {
      return false
    }
  }
  return true
}

function isValidPolygonPoint(points, point) {
  let last = points.at(-2)
  if (last && last[0] === point[0] && last[1] === point[1]) {
    return false
  }
  let len = points.length
  let last_before_index = len - 3
  let last_index = len - 2
  let p1 = points[last_before_index]
  let p2 = points[last_index]
  if (p2) {
    if (p1) {
      if (p1[0] == p2[0] && p2[0] == point[0]) {
        return false
      }
      if (p1[1] == p2[1] && p2[1] == point[1]) {
        return false
      }
    }
  }

  return true
}

export function setHideAndLock(num, obj) {
  if (!STAGE?.layerDatas) return
  let layer = STAGE.layerDatas.filter(obj => obj.layerNumber == num)[0]
  if (!layer) return
  obj.hide = layer.hide
  obj.lock = layer.lock
}

function isValidPos(p) {
  return Number.isFinite(p[0]) && Number.isFinite(p[1])
}

function isSameSlopes(obj) {
  let slope_now
  let sp = obj.shape
  slope_now = []
  const length = sp.length
  if (!length) return
  for (let i = 0; i < length - 1; i++) {
    slope_now.push(getSlope(sp[i], sp[i + 1]))
  }
  if (obj.type !== QedaGraphicType.PATH) {
    slope_now.push(getSlope(sp[0], sp[sp.length - 1]))
  }
  return JSON.stringify(slope_now) == JSON.stringify(obj.slopes)
}

function dist_points(p1, p2) {
  let x = p1[0] - p2[0]
  let y = p1[1] - p2[1]
  const dist_points = x * x + y * y
  return Math.sqrt(dist_points)
}
function fixNumber(num) {
  return Math.ceil(num * 1000) / 1000
}
function fixPosNumber3(p) {
  return [Math.round(p[0] * 1000) / 1000, Math.round(p[1] * 1000) / 1000]
}
function fixPosNumber4(p) {
  return [Math.ceil(p[0] * 10000) / 10000, Math.ceil(p[1] * 10000) / 10000]
}

function is_params_device(ref) {
  let res = ref.cell.QCELL.labels.filter(lable => {
    let params = lable.get_gds_property(2)
    if (params) {
      let label_attr = decompressPropStr(params)
      if (label_attr.is_params_device) {
        return true
      }
    }
  })
  return res.length
}

//"""简化曲线，去除平行点位"""
function curve_del_parallel(curve) {
  let new_curve = [curve[0]]
  for (let idx = 0; idx <= curve.length - 3; idx++) {
    let a = curve[idx]
    let b = curve[idx + 1]
    let c = curve[idx + 2]
    let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
    let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])
    let err = Math.abs(ag_a_b - ag_b_c)
    if (err == 0) {
      continue
    } else {
      new_curve.push(b)
    }
  }
  new_curve.push(curve[curve.length - 1])
  if (new_curve.length == 2 && new_curve[0][0] == new_curve[1][0] && new_curve[0][1] == new_curve[1][1]) {
    new_curve = [new_curve[0]]
  }
  return new_curve
}
