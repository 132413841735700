var QGeosJs = require('./geos')
var QGdstk = null
;(function () {
  function AutoToolLibs() {
    QGdstk = window.QGdstk
  }

  AutoToolLibs.prototype.angleModify = function (arc) {
    // """弧度角修正到[0, 2*pi]
    // args:
    //     arc: 弧度角
    // return
    //     res: [0~2*pi]
    // """
    arc = arc % (2 * Math.PI)
    arc = arc >= 0 ? arc : 2 * Math.PI + arc
    return arc
  }

  AutoToolLibs.prototype.pointDistance = function (p1, p2) {
    return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2))
  }

  AutoToolLibs.prototype.pointCenter = function (p1, p2) {
    x = (p2[0] - p1[0]) / 2.0 + p1[0]
    y = (p2[1] - p1[1]) / 2.0 + p1[1]
    return [x, y]
  }

  AutoToolLibs.prototype.bounding_box = function (points) {
    let x_min = Infinity
    let y_min = Infinity
    let x_max = -Infinity
    let y_max = -Infinity
    for (let i = 0; i < points.length; i++) {
      if (points[i][0] < x_min) x_min = points[i][0]
      if (points[i][0] > x_max) x_max = points[i][0]

      if (points[i][1] < y_min) y_min = points[i][1]
      if (points[i][1] > y_max) y_max = points[i][1]
    }
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }
  AutoToolLibs.prototype.box_center = function (b) {
    let cx = b[0][0] + (b[1][0] - b[0][0]) / 2
    let cy = b[0][1] + (b[1][1] - b[0][1]) / 2
    return { x: cx, y: cy }
  }

  AutoToolLibs.prototype.movePointToPolygon = function (poly, pos, r) {
    // """
    // 移动点到多边形最近的边上
    // args:
    //     pos(array[]): 点
    //     poly(array[]): 多边形点集
    //     r: 半径
    // return
    //     valid_pos: 点位
    //     valid_edge: 点位所在边
    // """
    if (!pos) return []
    let poly_edges = []
    for (let i = 0; i < poly.length - 1; i++) {
      poly_edges.push([poly[i], poly[i + 1]])
    }
    poly_edges.push([poly[-1], poly[0]])

    let valid_pos = []
    let valid_line = []

    let pos_p = new QGeosJs('Point', pos)
    let pos_p_buffer = pos_p.buffer(r)
    for (let i = 0; i < poly_edges.length; i++) {
      let line = poly_edges[i]
      let line_ls = new QGeosJs('LineString', line)
      let inter = pos_p_buffer.intersection(line_ls)
      if (!inter) continue
      inter_points = inter.coordinates
      // # 检测是否存在两个交点
      if (inter_points.length == 2) {
        let p1 = inter_points[0]
        let p2 = inter_points[1]
        let x = p1[0] + (p2[0] - p1[0]) / 2
        let y = p1[1] + (p2[1] - p1[1]) / 2
        valid_pos = [x, y]
        valid_line = line
        break
      }
    }
    return {
      pos: valid_pos,
      line: valid_line,
    }
  }

  AutoToolLibs.prototype.pointExtendToLine = function (pos, direct, length) {
    // """ 按点位和方向生成一定长度的线段
    // args:
    //     pos: 点位
    //     direct: 方向角度, 弧度
    //     length: 长度
    // return:
    //     line: 线段
    // """
    let dx = length * Math.cos(direct)
    let dy = length * Math.sin(direct)
    let line = [pos, [pos[0] + dx, pos[1] + dy]]
    return line
  }
  AutoToolLibs.prototype.extendLine = function (line, length, reverse) {
    // """ 按线的方向延长线段
    // args:
    //     line: 线段
    //     length: 延长长度
    //     reverse: 是否反向
    // """
    if (line.length < 2) return
    if (reverse)
      // 反向
      line = line.slice().reverse()
    let ag = this.angle_direction(line[0], line[1])
    line[1] = [line[1][0] + length * Math.cos(ag), line[1][1] + length * Math.sin(ag)]
    return line
  }

  AutoToolLibs.prototype.movePoint = function (pos, direct, length) {
    // """ 按照方向移动点位到指定长度的新点位
    // args:
    //     pos: 位置
    //     direct: 方向
    //     length: 长度
    // return:
    //     res: 新点位
    // """
    let dx = length * Math.cos(direct)
    let dy = length * Math.sin(direct)
    pos = [pos[0] + dx, pos[1] + dy]
    return pos
  }

  AutoToolLibs.prototype.lineNormalVector = function (line) {
    // """获取线段的两个方向的法向量
    // args:
    //     line: line
    // return
    //     nv1: 法向量1
    //     nv2: 法向量2
    // """
    let ag = this.angle_direction(line[0], line[1])
    let nv1 = (ag - Math.PI / 2) % (2 * Math.PI)
    nv1 = nv1 > 0 ? nv1 : 2 * Math.PI + nv1
    let nv2 = (ag + Math.PI / 2) % (2 * Math.PI)
    nv2 = nv2 > 0 ? nv2 : 2 * Math.PI + nv2
    return {
      nv1: nv1,
      nv2: nv2,
    }
  }

  AutoToolLibs.prototype.ray = function (pos, lines, direction, length) {
    // """ 射线交点
    // args:
    //     pos: 射线起点
    //     direction: 方向角度
    //     length: 长度
    // return:
    //     交点位置, 交点所在线段, 交点所在线段的角度
    // """
    // 按照方向创建射线
    let line = this.pointExtendToLine(pos, direction, length)
    let s_line = new QGeosJs('LineString', line)
    let inter = s_line.intersection(lines)
    if (!inter) {
      return null
    }

    let valid_pos = null
    let pos_p = new QGeosJs('Point', pos)
    // 两个交点，取距离最远的交点
    if (inter.type == 'MultiPoint') {
      let max_d = Infinity
      for (let i = 0; i < inter.coordinates.length; i++) {
        // let p = list(p.coords)[0]
        let p = inter.coordinates[i]
        let p_p = new QGeosJs('Point', p)
        let d = pos_p.distance(p_p)
        if (d < 0.1)
          // 去掉自身交点
          continue
        if (d < max_d) {
          max_d = d
          valid_pos = [p[0], p[1]]
        }
      }
    }
    if (inter.type == 'Point') {
      valid_pos = inter.coordinates
    }
    return valid_pos
  }

  // 去重
  AutoToolLibs.prototype.removeDuplicate = function (arr) {
    const newArr = []
    arr.forEach(item => {
      if (!newArr.includes(item)) {
        newArr.push(item)
      }
    })
    return newArr
  }

  //"""2d计算两点之间的方位角函数
  //p1: 点1
  //p2: 点2
  //return angle 方位角
  //"""
  AutoToolLibs.prototype.angle_direction = function (p1, p2) {
    let x1 = p1[0]
    let y1 = p1[1]
    let x2 = p2[0]
    let y2 = p2[1]
    let dx = x2 - x1
    let dy = y2 - y1
    if (Math.abs(dy) < 0.0001) {
      if (dx > 0) return 0
      if (dx < 0) return Math.PI
    }
    if (Math.abs(dx) < 0.0001) {
      if (dy > 0) return Math.PI / 2
      if (dy < 0) return (Math.PI * 3) / 2
    }
    angle = Math.atan(Math.abs(dy / dx))
    if (dy > 0) {
      if (dx > 0)
        // 第一象限
        return angle
      if (dx < 0)
        // 第二象限
        return Math.PI - angle
    }
    if (dy < 0) {
      if (dx < 0)
        // 第三象限
        return Math.PI + angle
      if (dx > 0)
        // 第四象限
        return 2 * Math.PI - angle
    }
    return 0
  }

  AutoToolLibs.prototype.polygonStep = function (polygon, step) {
    let step_polygon = []
    for (let i = 0; i < polygon.length; i++) {
      step_polygon.push([polygon[i][0] / step, polygon[i][1] / step])
    }
    step_polygon.push(step_polygon[0])
    return step_polygon
  }

  AutoToolLibs.prototype.split_polygons_bk = function (polygons = [], step = 10) {
    if (polygons.length == 0) return []
    let multipolygons = []
    for (let i = 0; i < polygons.length; i++) {
      // polygons[i].scale(1 / step, 1 / step, [0, 0])
      let polygon_points = polygons[i].get_points()
      polygon_points.push(polygon_points[0])
      multipolygons.push(polygon_points)
    }
    let cell = new QGdstk.Cell('tmp')
    cell.add(polygons)
    let box = cell.bounding_box()

    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]
    let x_len = Math.floor(x_max - x_min)
    let y_len = Math.floor(y_max - y_min)

    let x_lines = []
    for (let i = 0; i < y_len; i++) {
      x_lines.push([
        [x_min, y_min + i],
        [x_max, y_min + i],
      ])
    }

    let sp_x_lines = new QGeosJs('MultiLineString', x_lines)
    let sp_multipolygons = new QGeosJs('MultiPolygon', [multipolygons])
    let inter = sp_x_lines.intersection(sp_multipolygons)
    let row_lines = []

    if (!inter) return []
    if (inter.type == 'GeometryCollection') {
      let geos_arr = inter.coordinates
      for (let i = 0; i < geos_arr.length; i++) {
        let tmp_geo = geos_arr[i]
        if (tmp_geo.type == 'LineString') {
          row_lines.push(tmp_geo.coordinates)
        }
      }
    } else if (inter.type == 'MultiLineString') {
      row_lines = inter.coordinates
    } else if (inter.type == 'LineString') {
      row_lines.push(inter.coordinates)
    }
    let rr = []
    for (let i = 0; i < row_lines.length; i++) {
      let pointVec = row_lines[i]
      let start = pointVec[0]
      let end = pointVec[pointVec.length - 1]
      if (start[0] < end[0]) {
        for (let j = start[0]; j < end[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      } else {
        for (let j = end[0]; j < start[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      }
    }
    return rr
  }

  AutoToolLibs.prototype.fillPolygonBk2 = function (polygon = [], step = 10, tolerance = 0.01) {
    polygon.scale(1 / step, 1 / step, [0, 0])
    let box = polygon.bounding_box()

    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]
    let x_len = Math.floor(x_max - x_min)
    let y_len = Math.floor(y_max - y_min)

    let x_lines = []
    for (let i = 0; i < y_len; i++) {
      x_lines.push([
        [x_min, y_min + i],
        [x_max, y_min + i],
      ])
    }
    let sp_x_lines = new QGeosJs('MultiLineString', x_lines)
    // let points = this.polygonStep(polygon.points, 1)
    let polygon_points = polygon.get_points()
    polygon_points.push(polygon_points[0])

    let sp_polygon = new QGeosJs('Polygon', [polygon_points])
    let inter = sp_x_lines.intersection(sp_polygon)
    let row_lines = []

    if (!inter) return []
    if (inter.type == 'GeometryCollection') {
      let geos_arr = inter.coordinates
      for (let i = 0; i < geos_arr.length; i++) {
        let tmp_geo = geos_arr[i]
        if (tmp_geo.type == 'LineString') {
          row_lines.push(tmp_geo.coordinates)
        }
      }
    } else if (inter.type == 'MultiLineString') {
      row_lines = inter.coordinates
    } else if (inter.type == 'LineString') {
      row_lines.push(inter.coordinates)
    }
    let rr = []
    for (let i = 0; i < row_lines.length; i++) {
      let pointVec = row_lines[i]
      let start = pointVec[0]
      let end = pointVec[pointVec.length - 1]
      if (start[0] < end[0]) {
        for (let j = start[0]; j < end[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      } else {
        for (let j = end[0]; j < start[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      }
    }
    return rr
  }

  AutoToolLibs.prototype.fillPolygon = function (polygon = [], step = 10, tolerance = 0.01) {
    let polygon_step = this.polygonStep(polygon, step)
    let lineVec = QGdstk.fillPolygonLine(polygon_step, tolerance)
    let rr = []
    for (let i = 0; i < lineVec.length; i++) {
      let pointVec = lineVec[i]
      let start = pointVec[0]
      let end = pointVec[1]
      if (start[0] < end[0]) {
        for (let j = start[0]; j < end[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      } else {
        for (let j = end[0]; j < start[0]; j++) {
          rr.push([j * step, start[1] * step])
        }
      }
    }
    return rr
  }

  /**
   * 线性填充多边形, 返回rr, cc
   * @param {*} line
   * @param {*} step
   * @returns
   */

  AutoToolLibs.prototype.fillPolygonRC = function (polygon = [], step = 10, tolerance = 0.01) {
    let polygon_step = this.polygonStep(polygon, step)
    let lineVec = QGdstk.fillPolygonLine(polygon_step, tolerance)
    let rr = []
    let cc = []
    for (let i = 0; i < lineVec.length; i++) {
      let pointVec = lineVec[i]
      for (let j = 0; j < pointVec.length; j += 2) {
        let start = pointVec[j]
        let end = pointVec[j + 1]
        for (let k = start[0]; k < end[0]; k++) {
          rr.push(k)
          cc.push(start[1])
        }
      }
    }
    return { rr: rr, cc: cc }
  }

  /**
   * 线性分割线段, 将一条线按照步距进行分割，包含起点和终点
   * @param {*} line
   * @param {*} step
   * @returns
   */
  AutoToolLibs.prototype.split_line = function (line, step) {
    let p1 = line[0]
    let p2 = line[line.length - 1]
    let dx = p2[0] - p1[0]
    let dy = p2[1] - p1[1]
    let length = Math.sqrt(dx * dx + dy * dy)
    if (length <= step) return line
    let num = length / step
    let x_step = dx / num
    let y_step = dy / num

    res = []
    for (let i = 0, len = parseInt(num) + 1; i < len; i++) {
      res.push([p1[0] + i * x_step, p1[1] + i * y_step])
    }
    res.push(p2)
    return res
  }

  AutoToolLibs.prototype.split_lines = function (lines = [], step = 10) {
    let points = []
    // 进度
    let length = lines.length
    let process_n = Math.ceil(length / 10)
    let n = 0
    // 骨架线轮廓点位标注
    for (let idx = 0, len = lines.length; idx < len; idx++) {
      let line = lines[idx]
      for (let i = 0, leni = line.length - 1; i < leni; i++) {
        let s = [line[i], line[i + 1]]
        let sp = this.split_line(s, step)
        sp.forEach(element => {
          if (points.length > 0) {
            if (element[0] == points[points.length - 1][0] && element[1] == points[points.length - 1][1]) {
            } else points.push(element)
          } else {
            points.push(element)
          }
        })
        // points.push.apply(points, sp)
      }
      n += 1
      // if (n % process_n == 0) {

      // } else if (n == length) {

      // }
    }
    return points
  }

  AutoToolLibs.prototype.split_paths = function (lines = [], step = 10) {
    let points = []
    // 进度
    let length = lines.length
    let process_n = Math.ceil(length / 10)
    let n = 0
    // 骨架线轮廓点位标注
    for (let idx = 0, len = lines.length; idx < len; idx++) {
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }
      let line = lines[idx].get_points()
      for (let i = 0, leni = line.length - 1; i < leni; i++) {
        let s = [line[i], line[i + 1]]
        let sp = this.split_line(s, step)
        points.push.apply(points, sp)
      }
      n += 1
    }
    return points
  }

  AutoToolLibs.prototype.split_paths_k = function (lines = [], step = 10) {
    let points = []
    // 进度
    let length = lines.length
    let process_n = Math.ceil(length / 10)
    let n = 0
    // 骨架线轮廓点位标注
    for (let idx = 0, len = lines.length; idx < len; idx++) {
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }
      let line = lines[idx].points
      for (let i = 0, leni = line.length - 1; i < leni; i++) {
        let s = [line[i], line[i + 1]]
        let sp = this.split_line(s, step)
        points.push.apply(points, sp)
      }
      n += 1
    }
    return points
  }

  AutoToolLibs.prototype.split_polygons_lines = function (lines = [], step = 10) {
    let points = []
    // 进度
    let length = lines.length
    let process_n = Math.ceil(length / 10)
    // 骨架线轮廓点位标注
    lines.forEach((element, idx) => {
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }
      let line = element.get_points()
      line.push(line[0])
      for (let i = 0, leni = line.length - 1; i < leni; i++) {
        let s = [line[i], line[i + 1]]
        let sp = this.split_line(s, step)
        points.push.apply(points, sp)
      }
    })
    return points
  }

  AutoToolLibs.prototype.split_polygons = function (polygons = [], step = 10) {
    let points = []
    // 进度
    let length = polygons.length
    let process_n = Math.ceil(length / 10)
    // 骨架线轮廓点位标注
    for (let idx = 0; idx < length; idx++) {
      let poly = polygons[idx].points
      let fill = this.fillPolygon(poly, step, 1 / step)
      // let fill = this.fillPolygon(polygons[idx], step, 1 / step)
      for (let j = 0; j < fill.length; j++) {
        points.push(fill[j])
      }
      // 边缘填充
      for (let j = 1; j < poly.length; j++) {
        let sp = this.split_line([poly[j - 1], poly[j]], step / 2)
        points.push.apply(points, sp)
      }
      if (poly.length > 1) {
        let sp_end = this.split_line([poly[poly.length - 1], poly[0]], step / 2)
        points.push.apply(points, sp_end)
      }
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }
    }

    return points
  }

  AutoToolLibs.prototype.split_polygons_edge = function (polygons = [], step = 10, close = false) {
    let points = []
    // 进度
    let length = polygons.length
    let process_n = Math.ceil(length / 10)
    // 骨架线轮廓点位标注
    for (let idx = 0; idx < length; idx++) {
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }

      let poly = polygons[idx].get_points()
      if (close) {
        poly.push(poly[0])
      }
      // 边缘填充
      for (let j = 1; j < poly.length; j++) {
        let sp = this.split_line([poly[j - 1], poly[j]], step)
        points.push.apply(points, sp)
        // sp.forEach(element => {
        //   points.push(element)
        // })
      }
    }
    return points
  }

  AutoToolLibs.prototype.split_polygons_edge_k = function (polygons = [], step = 10, close = false) {
    let points = []
    // 进度
    let length = polygons.length
    let process_n = Math.ceil(length / 10)
    // 骨架线轮廓点位标注
    for (let idx = 0; idx < length; idx++) {
      if (idx % process_n == 0) {
      } else if (idx == length - 1) {
      }

      let poly = polygons[idx].points
      if (close) {
        poly.push(poly[0])
      }
      // 边缘填充
      for (let j = 1; j < poly.length; j++) {
        let sp = this.split_line([poly[j - 1], poly[j]], step)
        points.push.apply(points, sp)
        // sp.forEach(element => {
        //   points.push(element)
        // })
      }
    }
    return points
  }

  AutoToolLibs.prototype.getPathsByLayer = function (paths = [], layer = 0) {
    let layer_paths = []
    for (let i = 0, len = paths.length; i < len; i++) {
      if (paths[i].layers[0] == layer) {
        layer_paths.push(paths[i])
      }
    }
    return layer_paths
  }

  AutoToolLibs.prototype.pathsTranLines = function (paths) {
    let lines = []
    for (let i = 0; i < paths.length; i++) {
      lines.push(paths[i].spine())
    }
    return lines
  }
  AutoToolLibs.prototype.pathsTranLines_k = function (paths) {
    let lines = []
    for (let i = 0; i < paths.length; i++) {
      lines.push(paths[i].points)
    }
    return lines
  }

  AutoToolLibs.prototype.rectPointsTransform = function (center = [0, 0], width = 10, angle = 0) {
    let w = width
    let points = [
      [w, 0],
      [w, w],
      [0, w],
      [-w, w],
      [-w, 0],
      [-w, -w],
      [0, -w],
      [w, -w],
    ]
    let p = new QGdstk.Polygon(points, 0, 0)
    p.rotate((angle = angle))
    p.translate(center[0], center[1])
    return p.points
  }

  // Gdstk Cell转Kernel Cell
  AutoToolLibs.prototype.GdstkCell2KernelCell = function (cell, fileLayerList) {
    let fileLayerDict = {}
    fileLayerList.forEach(item => {
      if (item) {
        fileLayerDict[item.layerNumber] = item
      }
    })
    let kernel_cell = new window.Kernel.Cell(cell.name)
    // cell.paths.forEach(element => {
    //   let path = new Kernel.GdsFlexpath(element.points, inner_width, 0, 'round', 'flush', radius, 1e-2, false, true, layer, datatype)
    //   kernel_cell.add(path)
    // })
    cell.polygons.forEach(element => {
      let points = []
      for (let i = 0; i < element.points.length; i++) {
        let p = element.points[i]
        points.push([p[0], p[1]])
      }
      let p = new window.Kernel.GdsPolygon(points)
      let layer = fileLayerDict[parseInt(element.layer)]
      if (layer) p.id = layer.id
      else p.id = String(parseInt(element.layer))
      p.layer = element.layer
      p.datatype = element.datatype
      kernel_cell.add_polygon(p)
    })
    return kernel_cell
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolLibs
  } else {
    window.AutoToolLibs = AutoToolLibs
  }
})()
