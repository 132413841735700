import {
  //引入api
  createNewPFC_api, //新建项目/文件/单元
  getUserProjectList_api, //获取个人用户项目目录
  getFileList_api, //获取文件列表
  getCellList_api, //获取cell列表
  deletePFC_api, //删除项目/文件/单元
  searchFile_api, //搜索项目/文件
  updataName_api, //重命名
  getTeamList_api, //获取团队名列表
  getTeamProjectList_api, //获取团队项目列表
  getTeamPermissionList_api, //团队下项目权限
  copyFileToOtherProject_api, //复制文件到项目
  batchCreatePWD_api, //创建导入的文件结构
  getCellFile_api, //获取文件内容
  getBlobFile, //返回二进制
  saveCellFile_api, //保存文件内容
  createMyComponent_api, //保存到个人器件库
  createTeamComponent_api, //保存到团队器件库
  lockCell_api, //锁文件
  selectLockCell_api,
  getTree_api, //获取文件树
  getUserPermission_api, //2.1获取团队成员权限
  create_file, // 复制到
  getFileParams_api, //获取索引数据
  setFileParams_api, //修改索引数据
  getUserSymbolLibInfo_api, //获取当前用户的个人符号库信息
  saveToUserSymbolLib_api, //存到个人符号库
  saveToTeamSymbolLib_api, //存到团队符号库
} from '@/api/file/file.js'
import { addLayerInfo_api, getLayerInfo_api, editLayerInfo_api, deleteLayerInfo_api } from '@/api/layer/layer.js'
import {
  putObject_api,
  getDiagramFile_api,
  saveDiagramFile_api,
  removeObject_api,
  getCopySnowId_api, //获取新snowid映射表
} from '@/api/objectStorage/objectStorage.js'
import { get_user_config_api } from '@/api/setting/setting.js'
import bus from '@/components/common/bus'

//获取团队列表
export const getTeamList = async function (start, rows) {
  let res = await getTeamList_api({
    start,
    rows,
    key_word: '',
  })
  return res
}
//用户项目列表获取
export const getUserProjectList = async function (start, rows) {
  let res = await getUserProjectList_api({
    start,
    rows,
  })
  return res
}

//获取团队项目列表
export const getTeamProjectList = async function (start, rows, snow_team_id, team_gns) {
  let res = await getTeamProjectList_api({
    start,
    rows,
    snow_team_id,
    team_gns,
    key_word: '',
  })
  return res
}

//获取文件列表
export const getFileList = async function (start, rows, parent_gns, category_child) {
  let res = await getFileList_api({
    start,
    rows,
    parent_gns,
    category: '文件',
    category_child,
  })
  return res
}

//搜索文件列表
export const searchFileList = async function (start, rows, parent_gns, category_child, types) {
  let res = await searchFile_api({
    start,
    rows,
    parent_gns,
    category: '文件',
    category_child,
    key_words: '',
    types,
  })
  return res
}

//获取单元层级列表
export const getCellList = async function (start, rows, parent_gns, category_child) {
  let res = await getCellList_api({
    start,
    rows,
    parent_gns,
    category: 'CELL',
    category_child,
  })
  return res
}

//创建新单元
export const createNewCell = async function (accessor_type, accessor_id, project_name, project_gns, project_snow_id, file_name, file_type, file_gns, file_snow_id, file_params, cell_name, cell_type, cell_snow_id) {
  let res = await createNewPFC_api({
    accessor_type,
    accessor_id,
    project_name,
    project_gns,
    project_snow_id,
    file_name,
    file_type,
    file_gns,
    file_snow_id,
    file_params,
    cell_type,
    cell_name,
    cell_snow_id,
  })
  return res
}

// 删除接口
export const deleteCell = async function (gns, category) {
  let deleteRes = await deletePFC_api({
    gns,
    category,
  })
  return deleteRes
}

//获取权限
export const getPermission = async function (gns) {
  let permissionRes = await getUserPermission_api({
    gns,
  })
  return permissionRes
}

//批量获取权限
export const batchGetPermission = async function (obj) {
  let permissionRes = await getTeamPermissionList_api(obj)
  return permissionRes
}

//获取锁定状态
export const selectLockCell = async function (snow_id) {
  let lockCellRes = await selectLockCell_api(snow_id)
  return lockCellRes
}

//批量获取单元相关文件url数据
export const getRefDataUrlFn = async function (RefCellSnowIdArr) {
  let urlRes = await getDiagramFile_api({ file_snow_id: RefCellSnowIdArr })

  return urlRes
}

//批量创建单元
export const batchCreateCellFn = async function (accessor_type, accessor_id, project_name, project_gns, snow_file_id, file_name, file_gns, file_type, is_upload_file, cell_name_list, file, layer_info) {
  var formData = new FormData() //新建表单对象
  formData.append('accessor_type', accessor_type)
  formData.append('accessor_id', accessor_id)
  formData.append('project_name', project_name)
  formData.append('project_gns', project_gns)
  formData.append('snow_file_id', snow_file_id)
  formData.append('file_name', file_name)
  formData.append('file_gns', file_gns)
  formData.append('file_type', file_type)
  formData.append('is_upload_file', is_upload_file)
  formData.append('cell_name_list', cell_name_list)
  formData.append('layer_info', layer_info)
  formData.append('file', file) //把文件二进制对象添加到表单对象里
  let batchCreateRes = await batchCreatePWD_api(formData)
  return batchCreateRes
}

// 重命名
export const renameFileName = async function (params) {
  let ReRes = await updataName_api(params)
  return ReRes
}

// 重命名
export const normalFileCopyTo = async function (params) {
  let ReRes = await create_file(params)
  return ReRes
}

//通用新建
export const normalCreateFileFn = async function (params) {
  let res = await create_file(params)
  return res
}

//获取索引数据
export const getFileParams = async function (fileId) {
  let res = await getFileParams_api(fileId)
  return res
}

//修改索引数据
export const setFileParams = async function (snow_id, file_param) {
  let res = await setFileParams_api({
    snow_id,
    file_param,
  })
  return res
}

//获取图层
export const getLayerInfo = async function (file_snow_id) {
  let res = await getLayerInfo_api({
    file_snow_id,
  })

  return res
}

//添加图层
export const addLayerInfo = async function (file_gns, layer_infos) {
  let res = await addLayerInfo_api({
    file_gns,
    layer_infos,
  })
  return res
}
//编辑图层
export const editLayerInfo = async function (file_gns, layer_infos) {
  let res = await editLayerInfo_api({
    file_gns,
    layer_infos,
  })
  return res
}
//删除图层
export const deleteLayerInfo = async function (file_gns, layer_numbers) {
  let res = await deleteLayerInfo_api({
    file_gns,
    layer_numbers,
  })
  return res
}

//获取当前用户个人符号库信息
export const getUserSymbolLibInfo = async function () {
  let res = await getUserSymbolLibInfo_api({})
  return res
}

//存到个人符号库
export const saveToUserSymbolLib = async function (formData) {
  let res = await saveToUserSymbolLib_api({
    formData,
  })
  return res
}

//存到团队符号库
export const saveToTeamSymbolLib = async function (formData) {
  let res = await saveToTeamSymbolLib_api({
    formData,
  })
  return res
}

//获取用户设置
export const getUserConfig = async function (project_id) {
  let res = await get_user_config_api({
    project_id,
  })
  return res
}
