import * as PIXI from 'pixi.js'
import { get } from 'underscore'
const exactMath = require('exact-math') // 解决精度问题
const initData = function initData(name, type, points, lineWidth, color, stepId) {
  return {
    name: name,
    type: type,
    selected: false,
    points: points,
    lineWidth: lineWidth,
    color: color,
    stepId: stepId,
  }
}

const getNode = function getNode(n, v) {
  n = document.createElementNS('http://www.w3.org/2000/svg', n)
  for (var p in v)
    n.setAttributeNS(
      null,
      p.replace(/[A-Z]/g, function (m, p, o, s) {
        return '-' + m.toLowerCase()
      }),
      v[p]
    )
  return n
}

const generatePoints = function (points) {
  let svg_points = ''
  for (var j = 0; j < points.length; j++) {
    svg_points = svg_points + `${points[j][0]},${points[j][1]} `
  }
  return svg_points
}

const checkoutMousePoints = function (points1, points2) {
  if (!points2) {
    return false
  }
  if (points1.x === points2.x && points1.y === points2.y) {
    return false
  }
  return true
}

const pointsTopoint = function (pointArray) {
  let reList = []
  pointArray.forEach(item => {
    reList.push({ x: item[0], y: item[1] })
  })
  return reList
}

const getNodePoints = function (graphicsData) {
  if (!graphicsData) {
    return []
  }
  let shape = graphicsData.shape
  let points = shape.points
  if (points.length < 2) {
    return
  }
  var point_list = []
  for (let index = 0; index < points.length / 2; index++) {
    if (index * 2 + 1 >= points.length) {
      break
    }
    point_list.push([points[index * 2], points[index * 2 + 1]])
  }
  return point_list
}

const unitTransform = function (oldUnit, newUnit, datas) {
  let _transForm = Number((oldUnit / newUnit).toFixed(7))
  if (!datas) return
  let points = datas.attrs.draw_points
  switch (datas.attrs.draw_type) {
    case 'line':
      points.forEach(item => {
        item.x = exactMath.div(item.x, _transForm)
        item.y = exactMath.div(item.y, _transForm)
      })
      break
    case 'dogleg':
      points.forEach(item => {
        item.x = exactMath.div(item.x, _transForm)
        item.y = exactMath.div(item.y, _transForm)
      })
      break
    case 'circle':
      points.x = exactMath.div(points.x, _transForm)
      points.y = exactMath.div(points.y, _transForm)
      points.radiusX = exactMath.div(points.radiusX, _transForm)
      points.radiusY = exactMath.div(points.radiusY, _transForm)
      break
    case 'rectangle':
      points.x = exactMath.div(points.x, _transForm)
      points.y = exactMath.div(points.y, _transForm)
      points.width = exactMath.div(points.width, _transForm)
      points.height = exactMath.div(points.height, _transForm)
      break
    case 'polygon':
      points.forEach(item => {
        item.x = exactMath.div(item.x, _transForm)
        item.y = exactMath.div(item.y, _transForm)
      })
      break
    default:
      break
  }
}

const initNewCellData = function (graph) {
  let graphicsData = graph.geometry.graphicsData[0]

  let shape = graphicsData.shape
  let type = graphicsData.type
  let attrs = {}
  var className = 'Graphics'
  attrs.shape_type = type
  attrs.draw_type = graph.draw_type
  attrs.draw_points = graph.draw_points
  attrs.obj_layer_list = graph.obj_layer_list
  attrs.name = graph.name
  attrs.unitInfo = graph.unitInfo
  switch (type) {
    case PIXI.SHAPES.POLY:
      attrs.point_list = getNodePoints(graphicsData)
      break
    case PIXI.SHAPES.RECT:
      attrs.width = shape.width
      attrs.height = shape.height
      attrs.x = shape.x
      attrs.y = shape.y
      break
    case PIXI.SHAPES.CIRC:
      break
    case PIXI.SHAPES.ELIP:
      attrs.width = shape.width
      attrs.height = shape.height
      attrs.x = shape.x
      attrs.y = shape.y
      break
    case PIXI.SHAPES.RREC:
      break
    default:
      break
  }
  if (window.unit_scale) {
    attrs.scaleX = 1.0 / window.unit_scale
  }
  return {
    attrs: attrs,
    uuid: graph.uuid,
    className: className,
  }
}

function pointDist(p1, p2) {
  let a = p2.x - p1.x,
    b = p2.y - p1.y
  return Math.sqrt(a * a + b * b)
}

const getAngle = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
  const dot = x1 * x2 + y1 * y2
  const det = x1 * y2 - y1 * x2
  const angle = (Math.atan2(det, dot) / Math.PI) * 180
  return Math.round(angle + 360) % 360
}

const rightAngle = function angle(p1, p2) {
  let cx = p1.x,
    cy = p1.y,
    ex = p2.x,
    ey = p2.y
  var dy = ey - cy
  var dx = ex - cx
  var theta = Math.atan2(dy, dx) // range (-PI, PI]
  theta *= 180 / Math.PI // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta
}

const is45 = function pointCompare(p1, p2) {
  return (
    rightAngle(p1, p2) == 45 ||
    rightAngle(p1, p2) == 90 ||
    rightAngle(p1, p2) == 135 ||
    rightAngle(p1, p2) == 180 ||
    rightAngle(p1, p2) == 0 ||
    rightAngle(p1, p2) == -45 ||
    rightAngle(p1, p2) == -90 ||
    rightAngle(p1, p2) == -135 ||
    rightAngle(p1, p2) == -180
  )
}

const isAdsorb = function (p1, p2, adsorb, unit, circle) {
  const unitPointFloor = {
    nm: -1,
    um: -4,
    mm: -7,
    cm: -7,
  }
  // 22.5: 吸附角度阈值
  const resInfo = {
    isAdsorb:
      (rightAngle(p1, p2) < 45 + 22.5 && rightAngle(p1, p2) > 45 - 22.5) ||
      (rightAngle(p1, p2) < 90 + 22.5 && rightAngle(p1, p2) > 90 - 22.5) ||
      (rightAngle(p1, p2) < 135 + 22.5 && rightAngle(p1, p2) > 135 - 22.5) ||
      (rightAngle(p1, p2) < 180 + 22.5 && rightAngle(p1, p2) > 180 - 22.5) ||
      (rightAngle(p1, p2) < 22.5 && rightAngle(p1, p2) > -22.5) ||
      (rightAngle(p1, p2) < -45 + 22.5 && rightAngle(p1, p2) > -45 - 22.5) ||
      (rightAngle(p1, p2) < -90 + 22.5 && rightAngle(p1, p2) > -90 - 22.5) ||
      (rightAngle(p1, p2) < -135 + 22.5 && rightAngle(p1, p2) > -135 - 22.5) ||
      (rightAngle(p1, p2) < -180 + 22.5 && rightAngle(p1, p2) > -180 - 22.5),
    realAngle: getRealAngle(p1, p2, circle),
    rotatePoint: is45(p1, p2) ? p2 : get45rotatePoint(p1, p2, getRealAngle(p1, p2, circle), adsorb, unitPointFloor[unit]),
  }
  return resInfo
}

const get45rotatePoint = function (p1, p2, rotate, adsorb, unit_floor) {
  var r_end_angle = (rotate / 360) * 2 * Math.PI
  if (adsorb) {
    //精度丢失
    // const genPoint = {
    //   x: exactMath.floor(p1.x + pointDist(p1, p2) * Math.cos(r_end_angle)),
    //   y: exactMath.floor(p1.y + pointDist(p1, p2) * Math.sin(r_end_angle)),
    // }
    const genPoint = {
      x: p1.x + pointDist(p1, p2) * Math.cos(r_end_angle),
      y: p1.y + pointDist(p1, p2) * Math.sin(r_end_angle),
    }
    // if (is45(p1, genPoint)) {
    //   return genPoint
    // } else {
    //   return verificationPoint(p1, genPoint)
    // }

    if (rotate === 0) {
      genPoint.y = p1.y
    } else if (rotate === 90) {
      genPoint.x = p1.x
    } else if (rotate === 180) {
      genPoint.y = p1.y
    } else if (rotate === 270) {
      genPoint.x = p1.x
    }
    return genPoint
  } else {
    return {
      x: exactMath.floor(p1.x + pointDist(p1, p2) * Math.cos(r_end_angle), unit_floor),
      y: exactMath.floor(p1.y + pointDist(p1, p2) * Math.sin(r_end_angle), unit_floor),
    }
  }
}

const getRealAngle = function (p1, p2, circle = false) {
  if (circle) {
    if (rightAngle(p1, p2) < 90 && rightAngle(p1, p2) > 0) {
      return 45
    }
    if (rightAngle(p1, p2) < 180 && rightAngle(p1, p2) >= 90) {
      return 135
    }
    if (rightAngle(p1, p2) < 0 && rightAngle(p1, p2) > -90) {
      return 315
    }
    if (rightAngle(p1, p2) < -90 && rightAngle(p1, p2) >= -180) {
      return 225
    }
  }
  if (rightAngle(p1, p2) < 45 + 22.5 && rightAngle(p1, p2) > 45 - 22.5) {
    return 45
  }
  if (rightAngle(p1, p2) < 90 + 22.5 && rightAngle(p1, p2) > 90 - 22.5) {
    return 90
  }
  if (rightAngle(p1, p2) < 135 + 22.5 && rightAngle(p1, p2) > 135 - 22.5) {
    return 135
  }
  if (rightAngle(p1, p2) < 180 + 22.5 && rightAngle(p1, p2) > 180 - 22.5) {
    return 180
  }
  if (rightAngle(p1, p2) < 22.5 && rightAngle(p1, p2) > -22.5) {
    return 0
  }
  if (rightAngle(p1, p2) < -45 + 22.5 && rightAngle(p1, p2) > -45 - 22.5) {
    return 315
  }
  if (rightAngle(p1, p2) < -90 + 22.5 && rightAngle(p1, p2) > -90 - 22.5) {
    return 270
  }
  if (rightAngle(p1, p2) < -135 + 22.5 && rightAngle(p1, p2) > -135 - 22.5) {
    return 225
  }
  if (rightAngle(p1, p2) < -180 + 22.5 && rightAngle(p1, p2) > -180 - 22.5) {
    return 180
  }
}

const verificationPoint = function (p1, p2) {
  const p2_ax = { x: p2.x + 1, y: p2.y },
    p2_ay = { x: p2.x, y: p2.y + 1 },
    p2_cx = { x: p2.x - 1, y: p2.y },
    p2_cy = { x: p2.x, y: p2.y - 1 }
  if (is45(p1, p2_ax)) {
    return p2_ax
  }
  if (is45(p1, p2_ay)) {
    return p2_ay
  }
  if (is45(p1, p2_cx)) {
    return p2_cx
  }
  if (is45(p1, p2_cy)) {
    return p2_cy
  }
}

var QedaData = class QedaData {
  initLayer(data) {
    this.allLayer = data
  }
  addLayer(data) {
    this.allLayer.push(data)
  }
  deleteLayer(index) {
    this.Layers.splice(index, 1)
  }
  updateLayer(data, index) {
    this.allLayer[index] = data
  }
  getAllLayer() {
    return this.allLayer
  }
  initData() {
    this.allQedaData = []
  }
  setData(data) {
    this.allQedaData = data
  }
  allNode() {
    return this.allQedaData
  }
  getNode(uuid) {
    let node = null
    this.allQedaData.forEach(item => {
      if (item.uuid === uuid) {
        node = item
      }
    })
    return node
  }
  add(data) {
    this.allQedaData.push(data)
  }
  update(uuid, data) {
    this.allQedaData.forEach(item => {
      if (item.uuid === uuid) {
        item.attrs = data.attrs
      }
    })
  }
  delete(uuid) {
    let deleteIndex = 0
    this.allQedaData.forEach((item, key) => {
      if (item.uuid === uuid) {
        deleteIndex = key
      }
    })
    this.allQedaData.splice(deleteIndex, 1)
  }
  onSegement(Pi, Pj, Q, unit_scale) {
    return pointDist(Pi, Q) + pointDist(Pj, Q) == pointDist(Pi, Pj) || pointDist(Pi, Q) + pointDist(Pj, Q) < pointDist(Pi, Pj) + 10 * unit_scale
  }
  judgePoints(points, unit_scale) {
    let select_uuid = null
    this.allQedaData.forEach(item => {
      let hitBool = false
      switch (item.attrs.draw_type) {
        case 'line':
          hitBool = this.onSegement(item.attrs.draw_points[0], item.attrs.draw_points[1], points, unit_scale)
          break
        case 'dogleg':
          for (let i = 0; i < item.attrs.draw_points.length; i++) {
            if (i > 0) {
              if (this.onSegement(item.attrs.draw_points[i - 1], item.attrs.draw_points[i], points, unit_scale)) {
                hitBool = true
                break
              }
            }
          }
          break
        case 'circle':
          const circleCenter = { x: item.attrs.draw_points.x, y: item.attrs.draw_points.y }
          const _angle = getAngle(
            {
              x: points.x - circleCenter.x,
              y: points.y - circleCenter.y,
            },
            {
              x: 0,
              y: item.attrs.draw_points.radiusY,
            }
          )
          const circlePoint = {
            x: circleCenter.x + item.attrs.draw_points.radiusX * Math.sin((Math.PI * 2 * _angle) / 360),
            y: circleCenter.y + item.attrs.draw_points.radiusY * Math.cos((Math.PI * 2 * _angle) / 360),
          }
          if (pointDist(circlePoint, points) < 1 * unit_scale * 1000) {
            hitBool = true
            break
          }
          break
        case 'rectangle':
          const pointArray = [
            { x: item.attrs.draw_points.x, y: item.attrs.draw_points.y },
            { x: item.attrs.draw_points.x + item.attrs.draw_points.width, y: item.attrs.draw_points.y },
            { x: item.attrs.draw_points.x + item.attrs.draw_points.width, y: item.attrs.draw_points.y + item.attrs.draw_points.height },
            { x: item.attrs.draw_points.x, y: item.attrs.draw_points.y + item.attrs.draw_points.height },
          ]
          for (let i = 0; i < pointArray.length; i++) {
            if (i > 0) {
              if (this.onSegement(pointArray[i - 1], pointArray[i], points, unit_scale)) {
                hitBool = true
                break
              }
            } else {
              if (this.onSegement(pointArray[0], pointArray[3], points, unit_scale)) {
                hitBool = true
                break
              }
            }
          }
          break
        case 'polygon':
          for (let i = 0; i < item.attrs.draw_points.length; i++) {
            if (i > 0) {
              if (this.onSegement(item.attrs.draw_points[i - 1], item.attrs.draw_points[i], points, unit_scale)) {
                hitBool = true
                break
              }
            } else {
              if (this.onSegement(item.attrs.draw_points[0], item.attrs.draw_points[item.attrs.draw_points.length - 1], points, unit_scale)) {
                hitBool = true
                break
              }
            }
          }
          break
        default:
          break
      }
      if (hitBool) {
        select_uuid = item.uuid
      }
    })
    this._updateHistoryUUid(select_uuid)
    return select_uuid
  }
  _setSelectUUid(uuid) {
    this.select_uuid = uuid
  }
  _setHistoryUUid() {
    this.history_uuid = [null, null]
  }
  _updateHistoryUUid(uuid) {
    this.history_uuid.push(uuid)
    this.history_uuid.splice(0, 1)
  }
}

export { QedaData, initData, getNode, generatePoints, pointsTopoint, checkoutMousePoints, initNewCellData, unitTransform, is45, isAdsorb, get45rotatePoint }
