// import QedaAxis from './qeda-axis'
import store from '@/store/index'
import { PATH } from '@pixi-essentials/svg'
import Quadtree from '@timohausmann/quadtree-js'
import { deepClone } from '../utils'
import { UndoRedojs } from './redo-undo'
var AutoToolLibs = require('../tools/libs')
import BigNumber from '../tools/bignumber'
import { isAdsorb } from '../pixi/qeda-pixi-data'
import {
  QedaGraphicsFactory,
  QedaGraphicType,
  QedaGraphicFillType,
  QedaLibrary,
  QedaCell,
  QedaReference,
  QedaGraphicFillTypeImg,
  QedaGraphicBorderType,
  QedaGraphicBorderTypeImg,
  getAngle,
  checkHit,
  checkHitBeforePart,
  getLineOffset,
  insideEllipse,
  stretchPoint,
  getSlope,
  checkHitPointAndLine,
  insideAABB,
  getVerticalSlope,
  slopeAndIntercept,
  calculateIntersectionPoint,
  insideAABB_expand,
} from './qeda-graphics'
import { QedaRenderer } from './qeda-renderer'
import bus from '@/components/common/bus'
window.Gds = {
  cell: null, // 当前cell
  cells: null, // 所有cell
  // 层级颜色
  layer_colors: [
    { id: 0, hex: 0xf151fc, alpha: 1.0 },
    { id: 1, hex: 0x0074d9, alpha: 1.0 },
    { id: 2, hex: 0x39cccc, alpha: 1.0 },
    { id: 3, hex: 0x2ecc40, alpha: 1.0 },
    { id: 4, hex: 0xff4136, alpha: 1.0 },
    { id: 5, hex: 0x85144b, alpha: 1.0 },
    { id: 6, hex: 0x007e6c, alpha: 1.0 },
    { id: 7, hex: 0xdbbadf, alpha: 1.0 },
    { id: 8, hex: 0x2290ff, alpha: 1.0 },
    { id: 9, hex: 0xfaff00, alpha: 1.0 },
  ],
}

window.Axis = {
  render: false, // 是否绘制
  adsorp: false, // 是否吸附
  adsorp_point: { x: 0, y: 0 }, // 吸附点
  adsorp_precision: 1, // 吸附精度
  grid_show: false, // 显示网格
  gird_color: { hex: 0x77787b, alpha: 1.0 }, // 网格颜色
  center_cross_color: { hex: 0xff0000, alpha: 1.0 }, // 中心十字线颜色
  mouse_cross_color: { hex: 0x00ff00, alpha: 1.0 }, // 鼠标十字颜色
  divid_ruler_color: { hex: 0x00ff00, alpha: 1.0 }, // 刻度尺颜色
  x: 0, // 坐标x
  y: 0, // 坐标y
  scale: 1, // 缩放
  dx: 1, // x轴平移dx
  dy: 1, // y轴平移dy
  unit: 1.0e-6, // 单位 um: 1.0e-6
  precision: 1.0e-9, // 精度：nm: 1.0e-9

  // gds相关变量
  cell: null,
  cells: null,
  check_lines: [],
  check_lines_color: { hex: 0xff0000, alpha: 1.0 }, // 错误线颜色
  // 层级颜色
  layer_colors: [
    { id: 0, hex: 0xf151fc, str: '#f151fc', alpha: 1.0 },
    { id: 1, hex: 0x0074d9, str: '#0074d9', alpha: 1.0 },
    { id: 2, hex: 0x39cccc, str: '#39cccc', alpha: 1.0 },
    { id: 3, hex: 0x2ecc40, str: '#2ecc40', alpha: 1.0 },
    { id: 4, hex: 0xff4136, str: '#ff4136', alpha: 1.0 },
    { id: 5, hex: 0x85144b, str: '#85144b', alpha: 1.0 },
    { id: 6, hex: 0x007e6c, str: '#007e6c', alpha: 1.0 },
    { id: 7, hex: 0xdbbadf, str: '#dbbadf', alpha: 1.0 },
    { id: 8, hex: 0x2290ff, str: '#2290ff', alpha: 1.0 },
    { id: 9, hex: 0xfaff00, str: '#faff00', alpha: 1.0 },
  ],
}
//背景网格生成
const axisShader = `
  uniform float step; //小网格像素宽度
  uniform float2 transform_p; //位移变换
  uniform float2 loop_p; //循环坐标系 由CPU计算避免GPU在大于10^7浮点计算丢失精度
  uniform float4 bg_color; //背景颜色
  uniform float4 axis_color; //坐标轴颜色
  uniform float4 line_color; //grid颜色
  uniform float width; //屏幕宽
  uniform float height; //屏幕高
  uniform float show_grid; //显示grid
  float grid(float value, float space, float width, float grid_size) {
    return ceil(mod(value + width, space * grid_size)) - width;
  }

  // float grid(float value, float space, float width, float grid_size) {
  //   return mod(value + width * 0.5, space * grid_size) - width;
  // }

  half4 main(float2 p) {
      float2 pp = p; //拷贝像素坐标
      pp.x -= loop_p.x; //位移变换
      pp.y -= loop_p.y;
      float axis_w = 1.0; //坐标轴宽度
      float w = 1.0; //网格宽度
      float space = step; //点间距，方格宽度
      float2 pos;
      float4 col;

      pos.x = grid(pp.x, space, w, 5);
      pos.y = grid(pp.y, space, w, 5);
      col = bg_color;
      if(bool(show_grid)){
        if (!bool(pos.x) || !bool(pos.y)) { //计算大网格
            col = line_color;
        } else {
            pos.x = grid(pp.x, space, w, 1); //计算小网格
            pos.y = grid(pp.y, space, w, 1);
            if (!bool(pos.x) || !bool(pos.y)) {
              col = line_color * 0.5;
            }
        }
      }
      if(abs(p.x - transform_p.x) <= axis_w || abs(p.y - transform_p.y) <= axis_w){ //计算坐标轴
        col = axis_color;
      }

      // if(abs(pp.x) <= axis_w*2.0 || abs(pp.y) <= axis_w*2.0){ //坐标系循环debug
      //   col = float4(0.8,0,0,1);
      // }

      // float center_x = width*0.5;
      // float center_y = height*0.5;
      // float cross_w = 100;
      // if((p.x <= (center_x+5) && p.x >= (center_x-5) && abs(p.y) < center_y + cross_w && abs(p.y) > center_y - cross_w) 
      //   || (p.y <= center_y + 5 && p.y >= center_y -5 && abs(p.x) < center_x + cross_w && abs(p.x) > center_x - cross_w)){ //屏幕中心debug
      //   col = float4(0.5,0.8,0.5,1);
      // }
      return half4(col);
  }
`

const testShader = `
  uniform float width; //屏幕宽
  uniform float height; //屏幕高
  uniform float scale; //缩放
  uniform float2 d; //平移
  half4 main(float2 p) {
    float4 col = float4(0);
    float2 start = float2(-23.2,0);
    float2 end = float2(23.2,0);
    start *= 2000000;
    end *= 20000000;
    float2 o = float2(d.x, d.y);
    float linWidth = 10.0;
    float2 min = float2(o.x + start.x, o.y - linWidth/2);
    float2 max = float2(o.x + end.x, o.y + linWidth/2);

    float angle = 10;
    float deg =  angle * (3.1415926/ 180);
    mat3 matrix = mat3(
      float3(cos(deg),sin(deg),0),
      float3(-sin(deg),cos(deg),0),
      float3(0,0,1)
    );
    float2 pp = (matrix * float3(p.x,p.y,1)).xy;
    if(pp.x > min.x && pp.x < max.x && pp.y > min.y && pp.y < max.y){
      col = float4(1);
    }
    return half4(col);
  }
`

export const FillShader = `
    uniform float4 col; //颜色
    uniform float width; //线宽
    uniform float space; //网格间距
    uniform float line_widht; //线段长度
    uniform float dot; //线段空隙间距
    uniform float angle; //旋转角度
    uniform float only_side; //只绘制一边 0 || 1 默认0
    uniform float use_point_grid; //使用点阵 0 || 1 默认0
    uniform float scale; //缩放 默认1
    uniform float2 position; //位移
    uniform float axis_scale;//坐标系缩放
    float fix_scale = 1000000;
    float grid(float value, float width, float space, float vertical, float line_widht, float dot) {
        float total = line_widht + dot; //线长加间距 -----  ----- -----
        float pos = mod(vertical, total);
        float dot_weight = 0;
        if(pos <= line_widht){
            dot_weight = width + 1; //计算点距权重
        }
        return mod(value + width * 0.5, space) - width + dot_weight;
    }
    half4 main(float2 p) {
        if(width > 1000){
          fix_scale = 1.0;
        }
        float4 color; //输出颜色
        float4 color_bg; //背景颜色
        float deg =  angle * (3.1415926/ 180);
        mat3 matrix = mat3( //变换矩阵
        float3(cos(deg) * scale,sin(deg),0),
        float3(-sin(deg),cos(deg) * scale,0),
        float3(fix_scale, fix_scale,1)
        );
        p *= axis_scale;
        p += 2000;
        // p -= position;
        float2 pp = (matrix * float3(p.x,p.y,1)).xy; //旋转后像素坐标
        float mx = 1-smoothstep(0, 1, grid(pp.x, width, space, pp.y - line_widht/2, line_widht, dot));
        float my;
        if(bool(only_side)){
            my = 1-smoothstep(0, 1, grid(pp.y, width, space, pp.x - line_widht/2, line_widht, dot));
        }
        color = mix(color_bg, col, clamp(mx + my, 0.0, col.w));//网格
        if(bool(use_point_grid)){
            color = mix(color_bg, col, clamp(mx + my - 1, 0.0, col.w));//点阵
        }
        return half4(color);
    }
`
// SkMatrix: [scaleX, 0, tranX, 0, scaleY, tranY, 0, 0, 1]
var CanvasKit = null
export class QedaStage {
  constructor(axis, fileInfo, containerId) {
    CanvasKit = window.CanvasKit
    this.libs = new AutoToolLibs()
    this.containerId = containerId
    this.ELEM = containerId + '_canvas'
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    // const surfaceOff = CanvasKit.MakeCanvasSurface('canvasOff')
    // const surfaceOff = CanvasKit.MakeCanvas(300, 300)
    if (!this.surface) {
      console.error('Could not make surface')
      return
    }
    //this.renderer = new QedaRenderer(this.ELEM)
    // 离线缓存
    // let canvasOff = new OffscreenCanvas(50, 50)

    this.canvasOff = null

    // 获取canvas对象
    this.canvas = document.getElementById(this.ELEM)

    // 宽高
    this.width = this.canvas.width
    this.height = this.canvas.height
    this.scale_box = 1

    // 坐标系初始化
    this.myaxis = axis //new QedaAxis(this.width, this.height, 1.0e-6, 1.0e-9)

    this.matrix = [this.myaxis.scale, 0, this.myaxis.dx, 0, -this.myaxis.scale, this.myaxis.dy, 0, 0, 1]

    // 全局cell变量
    ;(this.qedaLib = null), (this.cell = null) //当前渲染的cell
    this.cells = {} // 文件所有cell
    this.currentEditCell = null //当前编辑的cell
    this.cell_name = '' // 当前cell name
    this.cells_paths = {} // cells_paths , 用于提前缓存path路径，快速绘制
    this.cells_quadtree = {} // 四叉树
    this.cell_quadtree_elements = [] // 四叉树搜索结果

    this.editLowerCell = null //当前编辑的下层cell
    this.canvasRulerX = document.getElementById(this.containerId + 'ruler-x')
    this.canvasRulerY = document.getElementById(this.containerId + 'ruler-y')

    //drc检测图形
    this.drcGraphics = []
    this.drcGraphicPaint = new CanvasKit.Paint()
    this.drcGraphicPaint.setColor(CanvasKit.Color4f(1, 1, 0, 1))
    this.drcGraphicPaint.setStyle(CanvasKit.PaintStyle.Stroke)
    this.drcGraphicPaint.setAntiAlias(true)
    this.drcPointPaint = new CanvasKit.Paint()
    this.drcPointPaint.setColor(CanvasKit.Color4f(1, 1, 0, 1))
    this.drcPointPaint.setStyle(CanvasKit.PaintStyle.Fill)
    this.drcPointPaint.setAntiAlias(true)

    //3d区域图形
    this.cell3dAreaPaint_width = 2
    this.cell3dAreaPaint = new CanvasKit.Paint()
    this.cell3dAreaPaint.setColor(CanvasKit.Color4f(1, 0, 0, 1))
    this.cell3dAreaPaint.setStyle(CanvasKit.PaintStyle.Stroke)
    this.cell3dAreaPaint.setAntiAlias(true)
    this.cell3dAreaPaint.setPathEffect(CanvasKit.PathEffect.MakeDash([10, 10, 10, 10]))
    this.pre_checked_cell3dAreaPaint = this.cell3dAreaPaint.copy()
    this.pre_checked_cell3dAreaPaint.setColor(CanvasKit.Color4f(1, 1, 1, 1))
    this.checked_cell3dAreaPaint = this.cell3dAreaPaint.copy()
    this.checked_cell3dAreaPaint.setColor(CanvasKit.Color4f(1, 1, 0, 1))

    this.cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)
    this.pre_checked_cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)
    this.checked_cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)

    //3d剖面区域
    this.cell3dCutBoxPaint_width = 2
    this.cell3dCutBoxPaint = new CanvasKit.Paint()
    this.cell3dCutBoxPaint.setColor(CanvasKit.Color4f(1, 0, 0, 1))
    this.cell3dCutBoxPaint.setStyle(CanvasKit.PaintStyle.Stroke)
    this.cell3dCutBoxPaint.setAntiAlias(true)
    this.pre_checked_cell3dCutBoxPaint = this.cell3dCutBoxPaint.copy()
    this.pre_checked_cell3dCutBoxPaint.setColor(CanvasKit.Color4f(1, 1, 1, 1))
    this.checked_cell3dCutBoxPaint = this.cell3dCutBoxPaint.copy()
    this.checked_cell3dCutBoxPaint.setColor(CanvasKit.Color4f(1, 1, 0, 1))

    this.cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
    this.pre_checked_cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
    this.checked_cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
    // 线宽
    this.line_width = 1

    // 滚轮操作标记
    this.wheel_flag = false

    // 画笔
    this.layer_paints = {} //new Array(255)
    this.layer_paints_img = {} //new Array(255)
    this.layer_fill_paints = {} //new Array(255)
    this.layer_fill_paints_img = {} //new Array(255)
    this.layer_checked_paints = {} //new Array(255)
    this.point_checked_paint = null
    this.pre_aligment_point_checked_paint = null
    this.aligment_point_checked_paint = null
    this.pre_aligment_line_checked_paint = null
    this.aligment_line_checked_paint = null
    this.layer_checked_fill_paints = {} //new Array(255)
    this.layer_pre_checked_fill_paints = {}
    this.grid_paint = new CanvasKit.Paint()
    this.center_cross_paint = new CanvasKit.Paint()
    this.mouse_cross_paint = new CanvasKit.Paint()
    this.divid_ruler_paint = new CanvasKit.Paint()
    this.divid_ruler_checked_paint = new CanvasKit.Paint()
    this.divid_ruler_font = new CanvasKit.Font(null, 12)
    this.divid_ruler_font_paint = new CanvasKit.Paint()
    this.divid_ruler_font_checked_paint = new CanvasKit.Paint()
    this.label_font = new CanvasKit.Font(null, 30)
    this.check_lines_paint = new CanvasKit.Paint()
    this.select_box_paint = new CanvasKit.Paint()
    this.backgroundColor = '#191919'
    this.gridColor = '#B2B2B2'
    this.showGrid = true
    this.axisFact = CanvasKit.RuntimeEffect.Make(axisShader)
    this.axisPaint = new CanvasKit.Paint()
    this.testFact = CanvasKit.RuntimeEffect.Make(testShader)
    this.fillFact = CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理
    this.fileInfo = fileInfo
    this.setPaints()
    this.active = true
    this.area_scale = 1 //面积缩放系数
    //包围盒
    this.aabb = [
      [-this.width / 2, -this.height / 2],
      [this.width / 2, this.height / 2],
    ]
    //对象池
    this.pool = []
    //绘图工具
    this.tools = []
    //图形能否被选中
    this.check_able = true
    //预选中图形index列表
    this.pre_checked_objs_id = []
    this.pre_checked_objs_all_id = []
    this.pre_cover_index = -1
    //选中图形index列表
    this.checked_objs_id = []
    //当前选中图形index 根据列表循环切换
    this.cover_index = -1
    //画板所有图形包围盒
    this.graphicsAABB = null
    //选择模式 0整体 1部分 2布尔运算 3 X轴切割 4 Y轴切割 5 阵列 6 预粘贴 7预剪切 8点对齐 9线对齐 10 z线选择模式 11谐振腔 多边形 点绘制模式
    this.checkMode = 0
    this.checkMode_bk = 0
    //重新计算path
    this.re_render = false
    //画板选中图形的包围盒
    this.checkedGraphicsAABB = null
    //画板选中图形的包围盒中心点坐标
    this.checkedGraphicsAABB_center = null
    //旋转中心
    this.rotat_center = null
    this.rotation = 0
    //选择所有对象
    this.check_all = false
    //预选择对象
    this.pre_checked_obj = null
    //选中的对象
    this.checked_objs = []
    //选中对象数量
    this.checked_objs_count = 0
    //命中选中目标之一
    this.checked_objs_hited = false
    //拾取点线的最大距离
    this.pick_dist = 10
    //鼠标是否在移动
    this.mouse_drag = false
    //实际绘制对象数量
    this.render_count = 0
    //渲染点数
    this.render_points = 0
    //冻结画板
    this.freeze = false
    //拖动物体标志
    this.drag_count = 0
    this.drag_objs = false
    this.drag_obj_id = null
    //选择框包围盒
    this.select_mode = true // true 包围盒全选模式 false 包围盒半选模式
    this.select_AABB = null
    //包围盒四个点，用于判断与圆相交
    this.select_AABB_points = []
    //移动模式
    ;(this.moveMod = 0), //0 正常鼠标拖动 1 点击确认
      (this.movePosition = []) //记录两次移动点
    //部分选中后是否拉伸
    this.isStretch = true
    //剪切源数据
    this.preCutObjs = []
    //添加器件
    this.preAddCell = null
    this.fileInfo = fileInfo
    //当前图层
    this.currentLayer = null //store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas[0]
    this.layerDatas = []
    this.display_checked_objs = []
    this.points = [] //像素缩略点
    this.checked_pixel_points = [] //像素缩略点
    //绘制线，填充多边形
    this.paths = []
    this.fillPaths = []
    this.checked_paths = []
    this.checked_fillPaths = []

    this.checked_points = [] //部分选择选中的点
    this.checked_lines = [] //部分选择选中的线
    //对齐偏移参数
    this.alignmentOffset = []
    //对齐选中点
    this.preAlignmentPoints = []
    this.pointAlignmentTarget = null
    this.alignmentPoints = []
    //对齐选中线
    this.alignmentLineSlope = null //第一条线斜率
    this.preAlignmentLines = []
    this.preAlignmentLinesPath = []
    this.alignmentLines = []
    this.alignmentLinesPath = []
    //预选择点，线
    this.preCheckedPoints = []
    this.preCheckedLines = []
    this.preCheckedLinesPath = []
    //工具选择线
    this.checkedLines = []
    //吸附点 线
    this.adsorptionPoints = []
    this.adsorptiontLines = []
    this.adsorptionLinesPath = []
    //对齐操作对象
    this.alignmentTarget = []
    this.file_snow_id = 0
    //吸附
    this.adsorption = false
    //图形工厂
    this.QedaGraphics = new QedaGraphicsFactory()
    //辅助工具
    this.auxiliaryTools = []
    //计算fps
    this.times = []
    //抗锯齿
    this.antiAlias = false
    this.editHistory = new window.UndoRedojs()
    let that = this
    this.editHistory.onChange = function () {
      bus.$emit('fileChange', that.fileInfo.fileId)
    }
    // this.graphicsAABBPaint = new CanvasKit.Paint(); //设置画笔
    // const dpe = CanvasKit.PathEffect.MakeDash([5, 5, 5, 5], 0);
    // this.graphicsAABBPaint.setAntiAlias(false);
    // this.graphicsAABBPaint.setColor(CanvasKit.YELLOW);
    // this.graphicsAABBPaint.setPathEffect(dpe);
    // this.graphicsAABBPaint.setStyle(CanvasKit.PaintStyle.Stroke)
    //鼠标事件监听
    this.mouseDownListener = this.onMouseDown.bind(this)
    this.mouseMoveListener = this.onMouseMove.bind(this)
    this.mouseUpListener = this.onMouseUp.bind(this)
    this.mouseWheelListener = this.onMouseWheel.bind(this)
    this.keyBoardListener = this.onKeyDown.bind(this)
    this.dbClickListener = this.onDbClick.bind(this)
    //绘制循环
    this.drawFrameLoop = this.drawFrame.bind(this)

    // 监听事件
    // window.$bus.$on('EV_SET_AXIS_GRID_SHOW', data => {
    //   this.myaxis.grid_show = data
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_AXIS_UNIT', data => {
    //   this.myaxis.setUnit(data)
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_AXIS_PRECISION', data => {
    //   this.myaxis.setPrecision(data)
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_AXIS_ADSORP', data => {
    //   this.myaxis.setAdsorp(data)
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_AXIS_ADSORP_PRECISION', data => {
    //   this.myaxis.setAdsorpPrecision(data)
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_AXIS_ON_CENTER', data => {
    //   this.myaxis.setCenter(data)
    //   this.setPaints()
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_DRAW_CELL', data => {
    //   this.drawCell()
    //   this.render()
    // })
    // window.$bus.$on('EV_SET_DRAW_CHECKLINE', data => {

    //   window.Axis.check_lines = data
    //   // 绘制错误线
    // })
    // window.$bus.$on('EV_LOAD_CELLS', this.loadCells.bind(this))
    // window.$bus.$on('EV_SET_AXIS_LOAD_CELL', data => {
    //   // loadCell()
    // })

    //初始化 绑定事件
    this.init()
    this.addEventListener()
    this.viewMode = false
  }

  addEventListener() {
    this.active = true
    this.updateAreaScale()
    this.surface.requestAnimationFrame(this.drawFrameLoop)
    this.canvas.addEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.addEventListener('mousemove', this.mouseMoveListener)
    this.canvas.addEventListener('mousedown', this.mouseDownListener)
    this.canvas.addEventListener('mouseup', this.mouseUpListener)
    this.canvas.addEventListener('dblclick', this.dbClickListener)
    document.addEventListener('keydown', this.keyBoardListener)
  }

  removeEventListeners() {
    this.active = false
    this.canvas.removeEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.removeEventListener('mousemove', this.mouseMoveListener)
    this.canvas.removeEventListener('mousedown', this.mouseDownListener)
    this.canvas.removeEventListener('mouseup', this.mouseUpListener)
    this.canvas.removeEventListener('dblclick', this.dbClickListener)
    document.removeEventListener('keydown', this.keyBoardListener)
  }
  // 设置所有画笔
  setPaints() {
    function hex2rgb(hex) {
      return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255]
    }
    let that = this
    //设置画笔样式
    function setPaint(paint, strokeWidth, hex, alpha, antiAlias) {
      let rgb = hex2rgb(hex)
      paint.setColor(CanvasKit.Color4f(rgb[0], rgb[1], rgb[2], alpha))
      paint.setStyle(CanvasKit.PaintStyle.Stroke)
      paint.setAntiAlias(that.antiAlias)
      paint.setStrokeWidth(strokeWidth)
    }
    const pt = new CanvasKit.Paint()
    setPaint(pt, 10 / this.myaxis.scale, [0, 0, 0], 0, false)
    pt.setColor(CanvasKit.YELLOW)
    pt.setAntiAlias(this.antiAlias)
    this.point_checked_paint = pt
    this.pre_aligment_point_checked_paint = pt.copy()
    this.pre_aligment_point_checked_paint.setColor(CanvasKit.WHITE)
    this.pre_aligment_line_checked_paint = this.pre_aligment_point_checked_paint.copy()
    this.pre_aligment_line_checked_paint.setStrokeWidth(2 / this.myaxis.scale)
    this.aligment_point_checked_paint = pt.copy()
    this.aligment_point_checked_paint.setColor(CanvasKit.RED)
    this.aligment_line_checked_paint = this.aligment_point_checked_paint.copy()
    this.aligment_line_checked_paint.setStrokeWidth(2 / this.myaxis.scale)
    let axis = window.Axis
    // let colors = window.Gds.layer_colors

    //   'deepclone ----',
    //   deepClone(
    //     this.fileInfo.fileId,
    //     store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)
    //   )
    // )
    // //

    //   'layerDatas ----',
    //   this.fileInfo.fileId,
    //   store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)
    // )

    // if(!store.state.layerDatas[0]){
    //   return
    // }
    let layers = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0]?.layerDatas //store.state.layerDatas[0].layerDatas //window.Gds.layer_colors
    let before = this.layerDatas
    this.layerDatas = layers
    if (!before && layers?.length) {
      this.qedaLib?.updateHideAndLock()
    }
    if (!layers) {
      return
    }
    let line_width = this.myaxis.getLineWidth()

    let layer_paints_temp = {}
    let layer_checked_paints_temp = {}
    let layer_paints_img_temp = {}
    let layer_fill_paints_temp = {}
    let layer_fill_paints_img_temp = {}
    let layer_checked_fill_paints_temp = {}
    let layer_pre_checked_fill_paints_temp = {}
    const width = line_width * 1.5
    const yellow = CanvasKit.YELLOW
    const white = CanvasKit.WHITE
    for (let i = 0, len = layers.length; i < len; i++) {
      const layer = layers[i]
      let border_rgb = this.hexToRgb(layers[i].borderColor)
      if (!border_rgb) {
        border_rgb = [0, 0, 0]
      }
      const paint = new CanvasKit.Paint()
      this.setPaintRgb(paint, width, border_rgb, false)
      const paint_img = paint.copy()
      paint_img.setStrokeWidth(1.5)
      const border_style = layer.border
      if (border_style === 'B1') {
        paint.setStrokeWidth(line_width)
      } else if (border_style) {


        const dep = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderType[border_style])
        const dep_img = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[border_style])
        paint.setPathEffect(dep)
        paint_img.setPathEffect(dep_img)
      } else {
        paint.setAlphaf(0)
        paint_img.setAlphaf(0)
      }
      // this.layer_paints.push(paint)
      // this.layer_checked_paints.push(paint_checked)
      // this.layer_paints_img.push(paint_img)
      const index = layer.layerNumber //下标和图层编号对应
      layer_paints_temp[index] = { pt: paint, points: [] }

      const paint_checked = paint.copy()

      paint_checked.setColor(yellow)
      layer_checked_paints_temp[index] = { pt: paint_checked, points: [] }
      layer_paints_img_temp[index] = paint_img

      let rgb = this.hexToRgb(layer.color)
      const shape = layer.shape
      const pt = new CanvasKit.Paint()
      const pt_img = new CanvasKit.Paint()
      const pt_c = new CanvasKit.Paint()
      const pt_p_c = new CanvasKit.Paint()
      if (!shape) {
        //透明
        // if(!rgb){
        pt.setAlphaf(0)
        pt_img.setAlphaf(0)
        pt_c.setAlphaf(0)
        pt_p_c.setAlphaf(0)
        // }else{
        //   //纯色
        //   const col = CanvasKit.Color(rgb[0], rgb[1], rgb[2])
        //   pt.setStyle(CanvasKit.PaintStyle.Fill);
        //   pt.setColor(col)
        //   pt_img.setStyle(CanvasKit.PaintStyle.Fill);
        //   pt_img.setColor(col)
        //   pt_c.setStyle(CanvasKit.PaintStyle.Fill);
        //   pt_c.setColor(yellow)
        // }
      } else {
        pt.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
        pt_img.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillTypeImg[shape], 0, 0, 1]))
        pt_c.setShader(this.fillFact.makeShader([...yellow, ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
        pt_p_c.setShader(this.fillFact.makeShader([...white, ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
      }
      layer_fill_paints_temp[index] = pt
      layer_fill_paints_img_temp[index] = pt_img
      layer_checked_fill_paints_temp[index] = pt_c
      layer_pre_checked_fill_paints_temp[index] = pt_p_c
    }
    this.layer_paints = layer_paints_temp
    this.layer_checked_paints = layer_checked_paints_temp
    this.layer_paints_img = layer_paints_img_temp
    this.layer_fill_paints = layer_fill_paints_temp
    this.layer_fill_paints_img = layer_fill_paints_img_temp
    this.layer_checked_fill_paints = layer_checked_fill_paints_temp
    this.layer_pre_checked_fill_paints = layer_pre_checked_fill_paints_temp
    // let i = 0
    // for (let key in QedaGraphicFillType) {
    //   let rgb = this.hexToRgb(layers[i].color)
    //   if(!rgb) break
    //   const pt = new CanvasKit.Paint()
    //   pt.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillType[key], 0, 0, this.myaxis.scale]))
    //   this.layer_fill_paints[i] = pt
    //   const pt_img = pt.copy()
    //   pt_img.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillTypeImg[key], 0, 0, this.myaxis.scale]))
    //   this.layer_fill_paints_img[i] = pt_img
    //   const pt_c = new CanvasKit.Paint()
    //   pt_c.setShader(this.fillFact.makeShader([...yellow, ...QedaGraphicFillType[key], 0, 0, this.myaxis.scale]))
    //   this.layer_checked_fill_paints[i] = pt_c
    //   i++
    // }
    setPaint(this.grid_paint, line_width, axis.gird_color.hex, axis.gird_color.alpha, false)
    setPaint(this.center_cross_paint, line_width, axis.center_cross_color.hex, axis.center_cross_color.alpha, false)
    setPaint(this.mouse_cross_paint, 1, axis.mouse_cross_color.hex, axis.mouse_cross_color.alpha, true)
    setPaint(this.check_lines_paint, 5 * line_width, axis.check_lines_color.hex, axis.check_lines_color.alpha, false)
    this.setPaintRgb(this.select_box_paint, 1.5 * line_width, [255, 255, 0], false)

    // 刻度尺
    this.divid_ruler_paint.setColor(CanvasKit.Color4f(255, 0, 0, 1))
    this.divid_ruler_paint.setStyle(CanvasKit.PaintStyle.Stroke)
    this.divid_ruler_paint.setAntiAlias(this.antiAlias)
    this.divid_ruler_checked_paint = this.divid_ruler_paint.copy()
    this.divid_ruler_checked_paint.setColor(CanvasKit.YELLOW)
    this.divid_ruler_font_paint.setColor(CanvasKit.Color4f(255, 0, 0, 1))
    this.divid_ruler_font_paint.setAntiAlias(this.antiAlias)
    this.divid_ruler_font_checked_paint = this.divid_ruler_font_paint.copy()
    this.divid_ruler_font_checked_paint.setColor(CanvasKit.YELLOW)

    this.cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)
    this.pre_checked_cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)
    this.checked_cell3dAreaPaint.setStrokeWidth(this.cell3dAreaPaint_width / this.myaxis.scale)

    this.cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
    this.pre_checked_cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
    this.checked_cell3dCutBoxPaint.setStrokeWidth(this.cell3dCutBoxPaint_width / this.myaxis.scale)
  }

  setPaintRgb(paint, strokeWidth, rgb, antiAlias) {
    paint.setColor(CanvasKit.Color(rgb[0], rgb[1], rgb[2]))
    paint.setStyle(CanvasKit.PaintStyle.Stroke)
    paint.setAntiAlias(this.antiAlias)
    paint.setStrokeWidth(strokeWidth)
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
  }

  setLayerPaint(layer) {
    const index = layer.layerNumber //图层和编号对应
    const shape = layer.shape
    let line_width = this.myaxis.getLineWidth()
    let rgb = this.hexToRgb(layer.color)
    const width = line_width * 1.5
    const yellow = CanvasKit.YELLOW
    const white = CanvasKit.WHITE
    let border_rgb = this.hexToRgb(layer.borderColor)
    if (!border_rgb) {
      border_rgb = [0, 0, 0]
    }
    const paint = new CanvasKit.Paint()

    paint.setAntiAlias(this.antiAlias)
    this.setPaintRgb(paint, width, border_rgb, false)
    const paint_img = paint.copy()
    paint_img.setStrokeWidth(1.5)
    const border_style = layer.border
    if (border_style === 'B1') {
      paint.setStrokeWidth(line_width)
    } else if (border_style) {
      const dep = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderType[border_style])
      const dep_img = CanvasKit.PathEffect.MakeDash(QedaGraphicBorderTypeImg[border_style])
      paint.setPathEffect(dep)
      paint_img.setPathEffect(dep_img)
    } else {
      paint.setAlphaf(0)
      paint_img.setAlphaf(0)
    }

    const paint_checked = paint.copy()
    paint_checked.setColor(yellow)
    this.layer_paints[index].pt = paint
    this.layer_checked_paints[index].pt = paint_checked
    this.layer_paints_img[index] = paint_img

    const pt = new CanvasKit.Paint()
    const pt_img = new CanvasKit.Paint()
    const pt_c = new CanvasKit.Paint()
    const pt_p_c = new CanvasKit.Paint()
    if (!shape) {
      // if(!rgb){
      //透明
      pt.setAlphaf(0)
      pt_img.setAlphaf(0)
      pt_c.setAlphaf(0)
      pt_p_c.setAlphaf(0)
      // }else{
      //   //纯色
      //   const col = CanvasKit.Color(rgb[0], rgb[1], rgb[2])
      //   pt.setStyle(CanvasKit.PaintStyle.Fill);
      //   pt.setColor(col)
      //   pt_img.setStyle(CanvasKit.PaintStyle.Fill);
      //   pt_img.setColor(col)
      //   pt_c.setStyle(CanvasKit.PaintStyle.Fill);
      //   pt_c.setColor(yellow)
      // }
    } else {
      pt.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
      pt_img.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillTypeImg[shape], 0, 0, 1]))
      pt_c.setShader(this.fillFact.makeShader([...yellow, ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
      pt_p_c.setShader(this.fillFact.makeShader([...white, ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale]))
    }
    this.layer_fill_paints[index] = pt
    this.layer_fill_paints_img[index] = pt_img
    this.layer_checked_fill_paints[index] = pt_c
    this.layer_pre_checked_fill_paints[index] = pt_p_c
  }
  // 绘制坐标系统
  // drawAxis(canvas) {
  //   // 网格
  //   let grid_path = new CanvasKit.Path()
  //   let grid_lines = myaxis.grid.lines
  //   for (let i = 0, len = grid_lines.length; i < len; i++) {
  //     grid_path.moveTo(grid_lines[i][0][0], grid_lines[i][0][1])
  //     grid_path.lineTo(grid_lines[i][1][0], grid_lines[i][1][1])
  //   }
  //   canvas.drawPath(grid_path, grid_paint)

  //   // 十字中心
  //   let center_cross_path = new CanvasKit.Path()
  //   let center_cross_lines = myaxis.center_cross
  //   for (let i = 0, len = center_cross_lines.length; i < len; i++) {
  //     center_cross_path.moveTo(center_cross_lines[i][0][0], center_cross_lines[i][0][1])
  //     center_cross_path.lineTo(center_cross_lines[i][1][0], center_cross_lines[i][1][1])
  //   }
  //   canvas.drawPath(center_cross_path, center_cross_paint)

  //   grid_path.delete()
  //   center_cross_path.delete()
  // }

  // 绘制坐标系统
  drawMouse(canvas) {
    // 鼠标十字
    let path = new CanvasKit.Path()
    if (this.checkMode == 3) {
      path.moveTo(this.myaxis.mouse_pixel_x, 0)
      path.lineTo(this.myaxis.mouse_pixel_x, this.height)
    } else if (this.checkMode == 4) {
      path.moveTo(0, this.myaxis.mouse_pixel_y)
      path.lineTo(this.width, this.myaxis.mouse_pixel_y)
    } else {
      path.moveTo(this.myaxis.mouse_pixel_x, 0)
      path.lineTo(this.myaxis.mouse_pixel_x, this.height)
      path.moveTo(0, this.myaxis.mouse_pixel_y)
      path.lineTo(this.width, this.myaxis.mouse_pixel_y)
    }
    canvas.drawPath(path, this.mouse_cross_paint)
    path.delete()
  }

  drawRulerX() {
    if (!this.canvasRulerX) {
      return
    }
    const ctx = this.canvasRulerX.getContext('2d')
    const rulerX = this.myaxis.rulerX
    ctx.clearRect(0, 0, rulerX.width, rulerX.height)

    ctx.save()
    ctx.strokeStyle = '#ff0000'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.moveTo(this.myaxis.pos_x, rulerX.height)
    ctx.lineTo(this.myaxis.pos_x, rulerX.height - 40)
    ctx.stroke()
    ctx.restore()

    ctx.fillStyle = '#77787b'
    ctx.strokeStyle = '#77787b'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.font = '12px Arial'
    const lines = rulerX.lines

    for (let i = 0; i < lines.paths.length; i++) {
      ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
      ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
    }
    for (let i = 0; i < lines.texts.length; i++) {
      ctx.fillText(lines.texts[i].label, lines.texts[i].x, lines.texts[i].y + 18)
    }
    ctx.stroke()
  }

  drawRulerY() {
    if (!this.canvasRulerY) {
      return
    }
    const ctx = this.canvasRulerY.getContext('2d')
    const rulerY = this.myaxis.rulerY
    ctx.clearRect(0, 0, rulerY.width, rulerY.height)

    ctx.save()
    ctx.strokeStyle = '#ff0000'
    ctx.lineWidth = 1
    ctx.beginPath()
    ctx.moveTo(rulerY.width - 40, this.myaxis.pos_y)
    ctx.lineTo(rulerY.width, this.myaxis.pos_y)
    ctx.stroke()
    ctx.restore()

    ctx.fillStyle = '#77787b'
    ctx.strokeStyle = '#77787b'
    ctx.font = '12px Arial'
    ctx.lineWidth = 1
    ctx.beginPath()
    const lines = rulerY.lines
    for (let i = 0; i < lines.paths.length; i++) {
      ctx.moveTo(lines.paths[i][0][0], lines.paths[i][0][1])
      ctx.lineTo(lines.paths[i][1][0], lines.paths[i][1][1])
    }
    ctx.stroke()

    for (let i = 0; i < lines.texts.length; i++) {
      ctx.save()
      // const tX = lines.texts[i].translate[0]
      // const tY = lines.texts[i].translate[1]
      // ctx.translate(tX, tY)
      // ctx.rotate(lines.texts[i].rotate)
      // ctx.fillText(lines.texts[i].label, lines.texts[i].x, lines.texts[i].y - 20)
      // ctx.rotate(-lines.texts[i].rotate)
      // ctx.translate(-tX, -tY)

      ctx.translate(5, lines.texts[i].lineY + 2) //对应刻度尺y坐标
      ctx.rotate(lines.texts[i].rotate)
      ctx.fillText(lines.texts[i].label, 0, 0)
      ctx.restore()
    }
  }

  pointDistance(p1, p2) {
    return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2))
  }

  loadCells(data) {
    // this.cells = data.cells
    // this.cell_name = data.name

    // this.cell = this.cells[this.cell_name]

    this.cells = data
    this.cell = this.cells.filter(cell => cell.name === 'triple10')[0]
    window.Axis.cell = this.cell
    window.Axis.cells = this.cells

    this.cells_paths = {}
    //this.cells_quadtree = {}
    for (let cell of this.cells) {
      let path = this.getCellPaths(cell)
      //let quadtree = this.getCellQuadTree(this.cells[key])
      if (path) {
        this.cells_paths[cell.name] = path
        //this.cells_quadtree[key] = quadtree
      }
    }

    // 计算按照版图宽度转换1：1系数
    //this.scale_box = this.getScaleByBox(this.cell.bounding_box, this.width, this.height)
    // new Quadtree( { x: 0, y: 0, width: 400, height: 400, }, 4, 0)

    // this.myaxis.setCenterCell(this.cell.bounding_box)
    // myaxis.setPrecision(cell.unit[0] / cell.unit[1])
    // this.myaxis.setCellScale(this.cell.unit[0] / this.cell.unit[1])
    this.setPaints()
    this.myaxis.render()
    this.drawRulerX()
    this.drawRulerY()
  }

  //获取cell的quadtree, 提前缓存好
  getCellQuadTree(cell) {
    let box = cell.bounding_box
    let boxs = cell.references_boxs
    var myTree = new Quadtree({ x: box[0][0], y: box[0][1], width: box[1][0] - box[0][0], height: box[1][1] - box[0][1] }, 4, 10)
    for (let i = 0, len = boxs.length; i < len; i++) {
      const x_min = boxs[i][0][0]
      const y_min = boxs[i][0][1]
      const x_max = boxs[i][1][0]
      const y_max = boxs[i][1][1]
      myTree.insert({ id: i, x: x_min, y: y_min, width: x_max - x_min, height: y_max - y_min })
    }
    return myTree
  }
  // 四叉树搜索结果
  quadTreeSearch() {
    return
    let cellTree = this.cells_quadtree[this.cell_name]
    let cell_path = this.cells_paths[this.cell_name]
    let elements = []
    this.cell_quadtree_elements = []
    // 图形放大到2倍进行四叉树查询
    if (this.myaxis.scale / this.scale_box > 2) {
      const box = this.myaxis.getBounds('box')
      const rect = { x: box.x_min, y: box.y_min, width: box.x_max - box.x_min, height: box.y_max - box.y_min }
      elements = cellTree.retrieve(rect)

      for (let i = 0, len = elements.length; i < len; i++) {
        this.cell_quadtree_elements.push(cell_path.refs[elements[i].id])
      }
    }
  }

  //获取cell的path, 提前缓存好
  getCellPaths(cell) {
    function cellPaths(items) {
      if (!items) {
        return
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        const points = items[i].points
        const path = new CanvasKit.Path()
        path.moveTo(points[0][0], points[0][1])
        for (let j = 1, len = points.length; j < len; j++) {
          path.lineTo(points[j][0], points[j][1])
        }
        res.push({
          pt: path,
          ly: 1, //items[i].layer,
        })
      }
      return res
    }

    function cellPolygons(items) {
      if (!items) {
        return
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        const points = items[i].points
        const layer = 1 //items[i].layer
        const path = new CanvasKit.Path()
        path.moveTo(points[0][0], points[0][1])
        for (let j = 1, len = points.length; j < len; j++) {
          path.lineTo(points[j][0], points[j][1])
        }
        path.close()
        // path.lineTo(points[0][0], points[0][1])

        res.push({
          pt: path,
          ly: 1, //layer,
        })
      }
      return res
    }

    function cellRefs(items, cells) {
      if (!items) {
        return
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        const ref = items[i]

        const rotation = (ref.rotation * Math.PI) / 180 ? ref.rotation : 0
        const x_reflection = ref.x_reflection ? -1 : 1
        //const cell = cells[ref.name]
        const cell = ref.cell
        res.push({
          og: ref.origin,
          x_r: x_reflection,
          rt: rotation,
          nm: cells[0]?.name, //ref.name,
          refs: cellRefs(cell.references, cells),
        })
      }
      return res
    }
    if (!cell || !this.cells) return
    let paths = cellPaths(cell.paths)
    let polygons = cellPolygons(cell.polygons)
    let references = cellRefs(cell.references, this.cells)
    //let box = cell.bounding_box
    //let area = (box[1][0] - box[0][0]) * (box[1][1] - box[0][1])
    let new_cell = {
      name: cell.name,
      // box: cell.bounding_box,
      // area: area,
      //max: Math.max(box[1][0] - box[0][0], box[1][1] - box[0][1]),
      paths: paths,
      polygons: polygons,
      refs: references,
    }
    return new_cell
  }

  // 获取标准显示时的缩放系数比值
  getScaleByBox(box, target_w, target_h) {
    let w = box[1][0] - box[0][0]
    let h = box[1][1] - box[0][1]
    let s = 1
    if (w <= target_w && h <= target_h) {
      return s
    }
    if (w > h) {
      s = target_w / w
    } else {
      s = target_h / h
    }

    return s
  }

  drawCellQuadTree(canvas, matrix) {
    if (!this.cells || !this.cell_name) return
    let cell = this.cells[this.cell_name]
    if (!cell) return
    let boxs = cell.references_boxs
    canvas.save()
    canvas.concat(matrix)
    for (let i = 0, len = boxs.length; i < len; i++) {
      // left, top, right, down
      let bb = boxs[i]
      let w = bb[1][0] - bb[0][0]
      let h = bb[1][1] - bb[0][1]
      canvas.drawRect(CanvasKit.XYWHRect(bb[0][0], bb[0][1], w, h), this.layer_paints[5])
    }
    canvas.restore()
  }

  // 绘制cell对应的缓存paths
  drawCellPaths(canvas) {
    let cell_path = this.cells_paths['triple10']
    if (!cell_path) return
    this.drawPaths(canvas, cell_path.paths)
    this.drawPolygons(canvas, cell_path.polygons)

    // if (this.cell_quadtree_elements) {
    //   this.drawReferences(canvas, this.cell_quadtree_elements)
    // } else {
    //   this.drawReferences(canvas, cell_path.refs)
    // }
    this.drawReferences(canvas, cell_path.refs)
  }

  drawPaths(canvas, items) {
    if (!items) return
    for (let i = 0, len = items.length; i < len; i++) {
      // this.layer_paints[items[i].ly].setShader(this.testFact.makeShader([]));
      canvas.drawPath(items[i].pt, this.layer_paints[items[i].ly])
    }
  }

  drawPolygons(canvas, items) {
    if (!items) return
    for (let i = 0, len = items.length; i < len; i++) {
      // this.layer_paints[items[i].ly].setShader(this.testFact.makeShader([]));
      canvas.drawPath(items[i].pt, this.layer_paints[items[i].ly])
    }
  }

  drawReferences(canvas, items) {
    if (!items) return
    for (let i = 0, len = items.length; i < len; i++) {
      const ref = items[i]

      const ref_cell = this.cells_paths[ref.nm]

      // const jump = ref_cell.max * this.myaxis.scale
      // if (jump < 10) continue
      canvas.save()
      canvas.concat([ref.x_r, 0, ref.og[0], 0, 1, ref.og[1], 0, 0, 1])
      canvas.rotate(ref.rt, 0, 0)
      this.drawPaths(canvas, ref_cell.paths)
      this.drawPolygons(canvas, ref_cell.polygons)
      this.drawReferences(canvas, ref_cell.refs)
      canvas.restore()
    }
  }

  // 绘制错误线
  drawCellErrPaths(canvas) {
    let check_lines = window.Axis.check_lines
    if (!check_lines) return

    function drawErrPaths(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let points = items[i]
        const path = new CanvasKit.Path()
        path.moveTo(points[0][0], points[0][1])
        for (let i = 1, len = points.length; i < len; i++) {
          path.lineTo(points[i][0], points[i][1])
        }
        canvas.drawPath(path, check_lines_paint)
        path.delete()
      }
    }
    function drawErrPoints(canvas, points) {
      if (!points) return
      for (let i = 1, len = points.length; i < len; i++) {
        canvas.drawCircle(points[i][0], points[i][1], line_width * 50, check_lines_paint)
      }
    }

    for (let key in check_lines) {
      drawErrPaths(canvas, check_lines[key].err_paths)
      drawErrPoints(canvas, check_lines[key].err_points)
    }
  }

  // 鼠标按下
  onMouseDown(e) {
    if (this.viewMode) return
    //debug

    if (this.moveMod === 0) {
      this.updateMoveMode()
    }
    if (this.moveMod === 2) {
      this.updateMoveMode()
    }
    let mouse_pos = this.getMousePos()
    if (this.myaxis.adsorpPoint && this.myaxis.hit_point) {
      let hit_point = this.myaxis.hit_point
      if (this.editReference) {
        hit_point = this.applyEditRefInverseTransform([hit_point], this.editReference).get_points()[0]
      }
      mouse_pos = { x: hit_point[0], y: hit_point[1] }
    }
    this.checked_objs_hited = this.checkInsideCheckedObjs(mouse_pos)
    this.onMouseDownPool(e, mouse_pos)
    this.myaxis.onMouseDown(e)
    this.myaxis.setDrag(e.button !== 0, e.button)
  }

  // 鼠标抬起
  onMouseUp(e) {
    if (this.viewMode) return
    this.onMouseUpPool(e)
    this.updateCoverIndex()
    this.myaxis.onMouseUp(e)
    this.myaxis.setDrag(false, null)
    setTimeout(() => {
      this.mouse_drag = false

      this.render()
    })
    this.updateMoveMode()
    if (this.moveMod === 2) {
      //旋转模式清除记录
      this.resetMoveMode()
    }
  }

  // 鼠标移动
  onMouseMove(e) {
    //this.renderer.generateCellImgs(this.cells, this.myaxis.scale, this.layer_paints, this.layer_fill_paints)
    if (!e.movementX && !e.movementY) {
      //判断鼠标未移动，避免长按左键触发mousemove
      return
    }
    if (this.myaxis.left_drag && this.moveMod === 2) {
      //旋转模式 更新坐标
      this.updateMoveMode()
    }
    // this.cover_index = -1
    this.onMouseMovePool(e)
    this.myaxis.onMouseMove(e)
    window.$bus.$emit('EVENT_GET_AXIS_XY', this.myaxis.getXY())
    this.drawRulerX()
    this.drawRulerY()
    this.mouse_drag = this.myaxis.left_drag
    if (this.mouse_drag || this.myaxis.drag) {
      this.updateAABB()
      this.quadTreeSearch()
      this.render()
    } else {
      // render()
    }
    // this.render()

    // if(this.checked_objs_count){
    //   this.render()
    // }
  }

  // 鼠标滚轮缩放
  onMouseWheel(e, scale_center = false) {
    if (!this.currentLayer) {
      //本地测试
      this.currentLayer = store.state.layerDatas.filter(item => this.fileInfo.fileId === item.file_snow_id)[0].layerDatas[0] //{ layerNumber: 0, lock: false, hide: false }
    }
    this.myaxis.onMouseWheel(e, scale_center)
    this.line_width = 2.0 / this.myaxis.scale
    this.pick_dist = 20 / this.myaxis.scale
    this.wheel_flag = true
    window.$bus.$emit('EVENT_GET_AXIS_XY', this.myaxis.getXY())
    this.updateAreaScale()
    this.setPaints()
    this.drawRulerX()
    this.drawRulerY()
    this.updateAABB()
    this.quadTreeSearch()
    if (this.preAddCell) {
      this.preAddCell.position = [this.myaxis.mouse_point.x, this.myaxis.mouse_point.y]
      this.preAddCell.updateShape()
    }
    this.render()
  }

  // 键盘事件
  onKeyDown(e) {
    const key = e.key.toLowerCase()
    const delta = this.width * 0.2 //以画板20%移动
    let can_move = true
    // let isInput = false
    let objs = document.querySelectorAll('div[aria-label]') //''对象编辑
    //判断弹窗是否打开
    objs.forEach(obj => {
      if (obj.getAttribute('role') == 'dialog') {
        if (obj.parentNode.style.display !== 'none') {
          can_move = false
          // isInput = true
        }
      }
    })
    if (!can_move) return
    if (e.key === 'ArrowRight') {
      this.myaxis.dx -= delta
    } else if (e.key === 'ArrowLeft') {
      this.myaxis.dx += delta
    } else if (e.key === 'ArrowUp') {
      this.myaxis.dy += delta
    } else if (e.key === 'ArrowDown') {
      this.myaxis.dy -= delta
    } else if (e.key === 'f') {
      this.adaptScreen()
    } else if (key == 'escape') {
      bus.$emit('esc', true)
      e.target.blur()
    }
    this.onKeyDownPool(e)
    this.myaxis.render()
    this.drawRulerX()
    this.drawRulerY()
    this.updateAABB()
    this.render()
  }

  // 双击事件
  onDbClick(e) {
    if (window.getSelection)
      //清除选中元素 防止影响mouseMove执行
      window.getSelection().removeAllRanges()
    else if (document.selection) document.selection.empty()
    this.render()
  }

  // 着色器
  // drawOffSurfacePath() {
  //   const subSurface = surface.makeSurface({
  //     width: width * 2,
  //     height: height * 2,
  //     alphaType: CanvasKit.AlphaType.Premul,
  //     colorType: CanvasKit.ColorType.RGBA_8888,
  //     colorSpace: CanvasKit.ColorSpace.SRGB,
  //   })
  //   if (!subSurface) {
  //     console.error('Could not make subsurface')
  //     return
  //   }
  //   const subCanvas = subSurface.getCanvas()
  //   subCanvas.clear(CanvasKit.BLACK)
  //   subCanvas.save()
  //   let box = cells[cell_name].bounding_box

  //   drawCellPaths(subCanvas, cell_name, [scale_box, 0, 0, 0, scale_box, 0, 0, 0, 1])
  //   subCanvas.restore()

  //   // matrix: [scaleX, 0, tranX, 0, scaleY, tranY, 0, 0, 1]
  //   // Snap it off as an Image - this image will be in the form the
  //   // parent surface prefers (e.g. Texture for GPU / Raster for CPU).
  //   const img = subSurface.makeImageSnapshot()

  //   // clean up the temporary surface (which also cleans up subCanvas)
  //   subSurface.delete()
  //   // paint.delete()

  //   // Make it repeat a bunch with a shader
  //   const pattern = img.makeShaderCubic(CanvasKit.TileMode.Repeat, CanvasKit.TileMode.Mirror, 1 / 3, 1 / 3)
  //   const patternPaint = new CanvasKit.Paint()
  //   patternPaint.setShader(pattern)
  //   return patternPaint
  // }

  // cellToImg(sf) {
  //   const canvas = sf.getCanvas()

  //   let m = myaxis.matrix
  //   let matrix = [m[0], 0, m[2], 0, m[4], m[5], 0, 0, 1]
  //   drawCellPaths(canvas, cell_name, matrix)
  //   // Snap it off as an Image - this image will be in the form the
  //   // parent surface prefers (e.g. Texture for GPU / Raster for CPU).
  //   const img = sf.makeImageSnapshot()

  //   // clean up the temporary surface (which also cleans up subCanvas)

  //   // Make it repeat a bunch with a shader
  //   const pattern = img.makeShaderCubic(CanvasKit.TileMode.Repeat, CanvasKit.TileMode.Mirror, 1 / 3, 1 / 3)
  //   const patternPaint = new CanvasKit.Paint()
  //   patternPaint.setShader(pattern)
  //   return patternPaint
  // }

  // 绘制frame
  drawFrame(canvas) {
    if (!this.active) return
    const start = window.performance.now()
    while (this.times.length > 0 && this.times[0] <= start - 1000) {
      this.times.shift()
    }
    this.times.push(start)
    // canvas.save()
    // if (wheel_flag) {
    //   wheel_flag = false
    //   // canvasOff = cellToImg(surface)
    //   if (!cells_paints.hasOwnProperty(myaxis.scale)) {
    //     cells_paints[myaxis.scale] = drawOffSurfacePath()
    //   }

    //   // surfaceOff.requestAnimationFrame(drawFrameOff)
    //   // drawFrameOff()
    // }
    // canvas.restore()
    canvas.drawColor(CanvasKit.BLACK)
    canvas.save()
    this.drawAxisByShader(canvas)
    canvas.concat(this.myaxis.matrix)
    this.drawDrcResult(canvas)
    this.drawPool(canvas)
    // if (this.cell) {
    //   this.renderer.render(canvas, this.cell.name)
    // }
    canvas.restore()
    this.drawMouse(canvas)
    const end = window.performance.now()
    //debug
    // this.drawText(canvas, { x: 10, y: 20 }, `Total Objs : ${this.pool.length}`)
    // this.drawText(canvas, { x: 10, y: 30 }, `Render Objs: ${this.render_count}`)
    // this.drawText(canvas, { x: 10, y: 40 }, `Render points: ${this.render_points}`)
    // this.drawText(canvas, { x: 10, y: 50 }, `Render time: ${(end - start).toFixed(2)} ms`)
    // this.drawText(canvas, { x: 10, y: 60 }, `FPS: ${this.times.length}`)
    // this.drawText(canvas, { x: 10, y: 70 }, `Scale: ${this.myaxis.scale}`)
    // this.drawText(canvas, { x: 10, y: 80 }, `dx: ${this.myaxis.dx}`)
    // this.drawText(canvas, { x: 10, y: 90 }, `dy: ${this.myaxis.dy}`)
    // this.drawText(canvas, { x: 10, y: 100 }, `Checked Objs: ${this.checked_objs_count}`)
    // this.drawText(canvas, { x: 10, y: 110 }, `Stretch Mode: ${this.isStretch}`)
    // this.drawText(canvas, { x: 10, y: 120 }, `snow id: ${this.file_snow_id}`)
    // this.drawText(canvas, { x: 10, y: 130 }, `active: ${this.active}`)
    // this.drawText(canvas, { x: 10, y: 140 }, `divid: ${this.myaxis.step_divid}`)
    // this.drawText(canvas, { x: 10, y: 140 }, `adsorp: ${this.myaxis.adsorp}`)
    // this.drawText(canvas, { x: 10, y: 150 }, `adsorp_point: [${this.myaxis.adsorp_point.x}, ${this.myaxis.adsorp_point.y}]`)
    // this.drawText(canvas, { x: 10, y: 150 }, `unit_scale: ${this.myaxis.unit_scale}`)
    // this.drawText(canvas, { x: 10, y: 160 }, `board_width: ${this.width * this.myaxis.step_divid}`)
    // this.drawText(canvas, { x: 10, y: 170 }, `real_box: [${this.myaxis.real_box.x_min}, ${this.myaxis.real_box.y_min}], [${this.myaxis.real_box.x_max}, ${this.myaxis.real_box.y_max}]`)
    // this.drawText(canvas, { x: 10, y: 180 }, `real : [${this.myaxis.real_x}, ${this.myaxis.real_y}]`)
    // this.drawText(canvas, { x: 10, y: 190 }, `mouse_point: [${this.myaxis.mouse_point.x}, ${this.myaxis.mouse_point.y}]`)
    // this.drawText(canvas, { x: 10, y: 200 }, `bounds min: [${this.myaxis.bounds.x_min}, ${this.myaxis.bounds.y_min}]`)
    // this.drawText(canvas, { x: 10, y: 210 }, `bounds max: [${this.myaxis.bounds.x_max}, ${this.myaxis.bounds.y_max}]`)
    // if (this.myaxis.mouse_cross.length > 0) {
    //   this.drawText(canvas, { x: 10, y: 220 }, `mouse_line: [${this.myaxis.mouse_cross[0][0]}]`)
    //   this.drawText(canvas, { x: 10, y: 230 }, `mouse_line: [${this.myaxis.mouse_cross[0][1]}]`)
    //   this.drawText(canvas, { x: 10, y: 240 }, `mouse_line: [${this.myaxis.mouse_cross[1][0]}]`)
    //   this.drawText(canvas, { x: 10, y: 250 }, `mouse_line: [${this.myaxis.mouse_cross[1][1]}]`)
    // }
    if (this.active) {
      this.surface.requestAnimationFrame(this.drawFrameLoop)
    }
  }

  drawLabel(canvas, l, preCut = false, preAdd = false, preChecked = false) {
    if (l.hide) return
    let s = 1 / this.myaxis.scale
    let pointRadius = 5 * s //关键点半径
    const QDATA = l.QDATA
    let font_size = JSON.parse(QDATA.get_gds_property(0))
    let isKeyPoint = QDATA.get_gds_property(1)

    let pt, point_pt
    pt = l.checked ? this.layer_checked_paints[l.layer].pt : this.layer_paints[l.layer].pt
    //关键点 点样式
    if (isKeyPoint) {
      point_pt = l.checked ? this.layer_checked_fill_paints[l.layer] : this.layer_fill_paints[l.layer]
      if (preChecked) {
        point_pt = this.layer_pre_checked_fill_paints[l.layer]
      }
      if (preAdd) {
        point_pt = this.pre_aligment_line_checked_paint
      }
      if (preCut) {
        point_pt.setAlphaf(0.5)
      }
    } else {
      point_pt = pt
    }
    if (preChecked) {
      pt = pt.copy()
      pt.setColor(CanvasKit.WHITE)
    }
    if (preAdd) {
      pt = this.pre_aligment_line_checked_paint
    }
    pt = pt.copy()
    pt.setStyle(CanvasKit.PaintStyle.Fill)
    if (preCut) {
      pt.setAlphaf(0.5)
    }
    let text = QDATA.text
    let create_label = false
    //文本图形debug
    // this.drawRect(canvas, l.aabb) //包围盒
    // canvas.drawPath(l.path, this.layer_paints[l.layer].pt)
    // this.drawPoint(canvas, { x: l.position[0], y: l.position[1] }, this.myaxis.scale) //轴心点
    // this.drawPoint(canvas, { x: l.QDATA.origin[0], y: l.QDATA.origin[1] }, this.myaxis.scale) //原始坐标点 label origin
    if (isKeyPoint) {
      // QDATA.anchor = 'n'
      canvas.drawCircle(QDATA.origin[0], QDATA.origin[1], pointRadius, point_pt)
      let params = JSON.parse(isKeyPoint)
      create_label = params.create_label
      if (!create_label) return
    }
    let x = QDATA.origin[0]
    let y = QDATA.origin[1]
    let angle = QDATA.rotation
    let tran = this.anchorTran(QDATA.anchor, text.length, font_size, isKeyPoint)
    canvas.save()
    canvas.rotate((angle * 180) / Math.PI, x, y)
    canvas.translate(-x, y)
    canvas.scale(1 * s, -1 * s)
    canvas.translate(x / s, -y)
    let size = JSON.parse(QDATA.get_gds_property(0))
    this.label_font.setSize(size)
    canvas.drawText(`${text}`, x / s - tran.x, y - 2 + tran.y, pt, this.label_font)
    canvas.restore()
    pt.delete()
  }

  // 绘制刻度, p1: 起点， p2:终点
  drawDividRuler(canvas, p1, p2, checked, preChecked = false) {
    let pt = checked ? this.divid_ruler_checked_paint : this.divid_ruler_paint
    let font_pt = checked ? this.divid_ruler_font_checked_paint : this.divid_ruler_font_paint
    if (preChecked) {
      pt = pt.copy()
      pt.setColor(CanvasKit.WHITE)
      font_pt = font_pt.copy()
      font_pt.setColor(CanvasKit.WHITE)
    }
    // 根据象限绘制长度值
    function drawValue(canvas, pos, txt, scale, angle, ck_paint, ck_font) {
      let x = pos[0]
      let y = pos[1]
      let s = scale // 字体缩放系数
      let txx = BigNumber(txt).toString()
      let tranX = 0
      if (quadrant == 1 || quadrant == 3) {
        tranX = (-12 * txx.length) / 2
        // tranX = (-txx.length * (12 * 96)) / 72 // 转为像素大小
      }
      canvas.save()
      canvas.rotate((angle * 180) / Math.PI, x, y)
      canvas.translate(-x, y)
      canvas.scale(1 * s, -1 * s)
      canvas.translate(x / s, -y)
      canvas.drawText(`${txx}`, x / s + tranX, y - 2, ck_paint, ck_font)
      canvas.restore()
    }
    // 获取固定位数
    function getFixedNum(val) {
      let tmp_val = val * 1.0
      let fixed_num = 1
      let max_loop = 9
      while (max_loop > 0) {
        if (tmp_val > 1) {
          break
        }
        if (tmp_val == 0) {
          break
        }
        tmp_val = tmp_val * 10
        fixed_num += 1
        max_loop -= 1
      }
      return fixed_num
    }
    // 系数
    let unit_scale = this.myaxis.unit_scale
    // 当前视图真实区域
    let real_box = this.myaxis.real_box
    // 固定位数
    // let fix_num = getFixedNum(this.myaxis.step_divid)
    let fix_num = 6
    // 步长
    let step = this.myaxis.step_divid * unit_scale
    let angle = this.libs.angle_direction(p1, p2)
    let cal_angle = 0
    let quadrant = 1
    // 1象限
    if (angle >= 0 && angle <= Math.PI / 2) {
      cal_angle = angle + Math.PI / 2
      quadrant = angle == 0 ? 4 : 1
    }
    // 2象限
    if (angle > Math.PI / 2 && angle <= Math.PI) {
      cal_angle = angle - Math.PI / 2
      quadrant = 2
    }
    // 3象限
    if (angle > Math.PI && angle <= (Math.PI * 3) / 2) {
      cal_angle = angle - Math.PI / 2
      quadrant = 3
    }
    // 4象限
    if (angle > (Math.PI * 3) / 2 && angle <= Math.PI * 2) {
      cal_angle = angle + Math.PI / 2
      quadrant = 4
    }
    let dx = p2[0] - p1[0]
    let dy = p2[1] - p1[1]
    let len = Math.sqrt(dx * dx + dy * dy)
    let n = Math.floor(len / step)
    n = n < 1 ? 1 : n
    let x_step = dx / n
    let y_step = dy / n

    let path = new CanvasKit.Path()
    // 绘制起点->终点
    path.moveTo(p1[0], p1[1])
    path.lineTo(p2[0], p2[1])
    // 绘制中间视图区域内刻度
    let line = null
    let find_left = false
    let txt = 0
    for (let i = 0; i < n; i++) {
      // 截取刻度在视图区域内的绘制，视图歪的不绘制，减少性能消耗
      let pos_x = p1[0] + i * x_step
      let pos_y = p1[1] + i * y_step
      if (pos_x >= real_box.x_min * unit_scale && pos_x <= real_box.x_max * unit_scale && pos_y >= real_box.y_min * unit_scale && pos_y <= real_box.y_max * unit_scale) {
        find_left = true
      } else {
        if (find_left) {
          break
        }
        continue
      }
      // 绘制刻度线和刻度值
      let p = [p1[0] + i * x_step, p1[1] + i * y_step]
      if (i % 5 == 0) {
        line = this.libs.pointExtendToLine(p, cal_angle, 15 / this.myaxis.scale)
        txt = i * step
        drawValue(canvas, line[1], txt.toFixed(fix_num), 1 / this.myaxis.scale, 0, font_pt, this.divid_ruler_font)
      } else {
        line = this.libs.pointExtendToLine(p, cal_angle, 5 / this.myaxis.scale)
      }
      path.moveTo(line[0][0], line[0][1])
      path.lineTo(line[1][0], line[1][1])
    }
    // 终点刻度
    line = this.libs.pointExtendToLine(p2, cal_angle, 15 / this.myaxis.scale)
    path.moveTo(line[0][0], line[0][1])
    path.lineTo(line[1][0], line[1][1])
    txt = len
    drawValue(canvas, line[1], txt.toFixed(fix_num), 1 / this.myaxis.scale, 0, font_pt, this.divid_ruler_font)
    canvas.drawPath(path, pt)
    path.delete()
  }

  //绘制预选择对象
  drawPreCheckedObj(canvas) {
    if (this.pre_checked_obj) {
      let checked = this.pre_checked_obj.checked
      let preAdd = this.pre_checked_obj.preAdd

      if (checked || preAdd) return
      let isRefBox = this.pre_checked_obj.isRefBox
      let type = this.pre_checked_obj.type
      let path = this.pre_checked_obj.path
      let circlePath = this.pre_checked_obj.circlePath
      let layer = this.pre_checked_obj.layer
      if (isRefBox) {
        canvas.drawPath(path, this.pre_aligment_line_checked_paint)
      } else if (type === QedaGraphicType.RULER) {
        this.drawDividRuler(canvas, this.pre_checked_obj.shape[0], this.pre_checked_obj.shape[1], false, true)
      } else {
        if (type === QedaGraphicType.LABEL) {
          this.drawLabel(canvas, this.pre_checked_obj, false, false, true)
        } else if (type === QedaGraphicType.CELL3DAREA) {
          canvas.drawPath(path, this.pre_checked_cell3dAreaPaint)
        } else if (type === QedaGraphicType.CELL3DCUTBOX) {
          canvas.drawPath(path, this.pre_checked_cell3dCutBoxPaint)
        } else if (type == QedaGraphicType.CELL3DCUTBOX) {
        } else {
          let stroke_pt = this.layer_paints[layer].pt //边框
          stroke_pt = stroke_pt.copy()
          stroke_pt.setColor(CanvasKit.WHITE)
          let stroke_fill = this.layer_pre_checked_fill_paints[layer] //填充
          if (type === QedaGraphicType.CIRCLE) {
            path = circlePath
          }
          canvas.drawPath(path, stroke_pt)
          stroke_pt.delete()
          if (type === QedaGraphicType.PATH) {
            if (this.pre_checked_obj.width) {
              canvas.drawPath(path, stroke_fill)
            }
            return
          }
          canvas.drawPath(path, stroke_fill)
        }
      }
    }
    // canvas.restore()
  }
  // canvasOff = drawOffSurfacePath()
  render() {
    this.updateAreaScale()
    //this.surface.requestAnimationFrame(this.drawFrameLoop)
    this.pick_dist = 20 / this.myaxis.scale
    this.updateLabelBox()
    this.re_render = true
  }

  updateSize() {
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    // 获取canvas对象
    this.canvas = document.getElementById(this.ELEM)

    // 宽高
    this.width = this.canvas.width
    this.height = this.canvas.height
    this.updateAABB()
  }

  init() {
    window.AXIS = this.myaxis
    window._STROKE_PAINT = new CanvasKit.Paint()
    _STROKE_PAINT.setStyle(CanvasKit.PaintStyle.Stroke)
    _STROKE_PAINT.setAntiAlias(this.antiAlias)

    window._FIll_PAINT = new CanvasKit.Paint()
    _FIll_PAINT.setStyle(CanvasKit.PaintStyle.Fill)

    window._FONT_PAINT = new CanvasKit.Paint()

    window._PAINT = new CanvasKit.Paint()
    _PAINT.setStyle(CanvasKit.PaintStyle.Fill)
    window._PATH = new CanvasKit.Path()
    _PAINT.clear = function () {
      //清除画笔上一次设置
      this.setPathEffect(null)
      this.setShader(null)
    }
    this.drawRulerX()
    this.drawRulerY()
    this.updateAABB()
    // preventScrolling(document.getElementById(this.ELEM))
  }

  updateAreaScale() {
    this.area_scale = this.myaxis.scale * this.myaxis.scale
  }

  //更新label包围盒大小
  updateLabelBox() {
    if (!this.cell) return
    let s = 1 / this.myaxis.scale
    let scale = [s, s]
    let len = this.cell.labels.length
    for (let index = 0; index < len; index++) {
      let l = this.cell.labels[index]
      l._scale = scale
      l.updateShape()
    }
  }

  redraw() {
    this.drawRulerX()
    this.drawRulerY()
    this.updateAABB()
    this.surface.requestAnimationFrame(this.drawFrameLoop)
  }
  delete() {
    this.canvas.removeEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.removeEventListener('mousemove', this.mouseMoveListener)
    this.canvas.removeEventListener('mousedown', this.mouseDownListener)
    this.canvas.removeEventListener('mouseup', this.mouseUpListener)
    this.canvas.removeEventListener('dblclick', this.dbClickListener)
    document.removeEventListener('keydown', this.keyBoardListener)
  }

  axisLoopOffset(v) {
    let loopWidth = 10 * this.myaxis.step //坐标系以10 * 刻度尺最小单位的屏幕像素宽度无限循环
    if (Math.abs(v) > loopWidth) {
      v = v % loopWidth
    }
    return v + loopWidth
  }

  drawAxisByShader(canvas) {
    let backgroundColor = this.hexToRgb(this.backgroundColor).map(v => v / 255)
    let gridColor = this.hexToRgb(this.gridColor).map(v => v / 255)
    backgroundColor.push(1)
    gridColor.push(1)
    if (this.myaxis.grid_show) {
      const shader = this.axisFact.makeShader([
        this.myaxis.step,
        this.myaxis.dx,
        this.myaxis.dy,
        this.axisLoopOffset(this.myaxis.dx),
        this.axisLoopOffset(this.myaxis.dy),
        ...backgroundColor,
        ...gridColor,
        ...gridColor,
        this.width,
        this.height,
        this.showGrid,
      ])
      this.axisPaint.setShader(shader)
      const w = this.width
      canvas.drawRect(CanvasKit.LTRBRect(0, this.height, this.width, 0), this.axisPaint)
      shader.delete()
    }
    // const shader_line = this.testFact.makeShader([
    //   this.width,
    //   this.height,
    //   this.myaxis.scale,
    //   this.myaxis.dx, this.myaxis.dy,
    // ])
    // this.axisPaint.setShader(shader_line);
    // canvas.drawRect(CanvasKit.LTRBRect(0, this.height, this.width, 0), this.axisPaint);
    // shader_line.delete()
  }

  // 获取坐标系统
  getAxis() {
    return this.myaxis
  }

  //添加对象
  addChild(objs) {
    // this.pool = []
    if (Array.isArray(objs)) {
      const leng = objs.length
      for (let i = 0; i < leng; i++) {}
      this.pool.push(...objs)
    } else {
      this.pool.push(objs)
    }
  }
  //添加工具
  addTool(tool) {
    if (Array.isArray(tool)) {
      // tool.map(obj=>obj.axis = this.myaxis)
      this.tools.push(...tool)
    } else {
      // objs.axis = this.myaxis
      this.tools.push(objs)
    }
  }

  clearTools() {
    for (let i = 0; i < this.tools.length; i++) {
      if (this.tools[i]?.deleted) {
        this.tools[i].delete()
      }
    }
    this.tools = []
    this.drcGraphics = []
  }

  adaptScreen() {
    this.adaptAABB(this.checkedGraphicsAABB ? this.checkedGraphicsAABB : this.graphicsAABB)
    this.line_width = 2.0 / this.myaxis.scale
  }

  //画板自适应aabb包围盒
  adaptAABB(target_aabb) {
    if (!target_aabb) return
    //计算坐标系统缩放比例
    const target_h = target_aabb[1][1] - target_aabb[0][1]
    const stage_h = this.aabb[1][1] - this.aabb[0][1]
    const target_w = target_aabb[1][0] - target_aabb[0][0]
    const stage_w = this.aabb[1][0] - this.aabb[0][0]
    const scale_h = stage_h / target_h
    const scale_w = stage_w / target_w
    let scale = this.myaxis.scale * (scale_w > scale_h ? scale_h : scale_w) * 0.9
    if (scale > this.myaxis.scale_max * 2) {
      scale = this.myaxis.scale_max * 2
    } else if (scale < this.myaxis.scale_min / 2) {
      scale = this.myaxis.scale_min / 2
    }

    this.myaxis.scale = scale
    //计算坐标系统偏移量
    const centerX = target_aabb[0][0] + (target_aabb[1][0] - target_aabb[0][0]) / 2
    const centery = target_aabb[0][1] + (target_aabb[1][1] - target_aabb[0][1]) / 2
    this.myaxis.dx = this.width / 2 - centerX * this.myaxis.scale
    this.myaxis.dy = this.height / 2 + centery * this.myaxis.scale
    this.updateAABB()
    this.setPaints()
    // this.myaxis.updateValue()
    this.drawRulerX()
    this.drawRulerY()
  }

  //更新舞台包围盒
  updateAABB() {
    const offset_x = (this.myaxis.dx - this.width / 2) / this.myaxis.scale
    const offset_y = (this.myaxis.dy - this.height / 2) / this.myaxis.scale

    this.aabb = [
      [-(this.width / 2) / this.myaxis.scale - offset_x, -(this.height / 2) / this.myaxis.scale + offset_y],
      [this.width / 2 / this.myaxis.scale - offset_x, this.height / 2 / this.myaxis.scale + offset_y],
    ]
  }

  //包围盒剔除
  checkInsideStage(b) {
    return !(b[0][0] > this.aabb[1][0] || b[1][0] < this.aabb[0][0] || b[0][1] > this.aabb[1][1] || b[1][1] < this.aabb[0][1])
  }
  //根据框选盒子判断是否在内部或相交 整体选择
  checkInsideSelectBoxOver(obj, force_intersection = false) {
    const b = obj.aabb
    if (this.select_mode && !force_intersection) {
      //在内部
      return b[0][0] > this.select_AABB[0][0] && b[1][0] < this.select_AABB[1][0] && b[0][1] > this.select_AABB[0][1] && b[1][1] < this.select_AABB[1][1]
    } else {
      //相交
      if (!(b[0][0] > this.select_AABB[1][0] || b[1][0] < this.select_AABB[0][0] || b[0][1] > this.select_AABB[1][1] || b[1][1] < this.select_AABB[0][1])) {
        if (obj.type === QedaGraphicType.CIRCLE) {
          return true //this.checkAABBInsertEllipse(obj)
        } else {
          return this.checkInsideSelectBoxPoints(obj.shape, obj.type === QedaGraphicType.PATH).length > 0
        }
      }
    }
  }

  //根据框选盒子判断是否在内部或相交 部分选择
  checkInsideSelectBoxPart(b, force_intersection = false) {
    if (this.select_mode && !force_intersection) {
      //在内部
      return b[0][0] > this.select_AABB[0][0] && b[1][0] < this.select_AABB[1][0] && b[0][1] > this.select_AABB[0][1] && b[1][1] < this.select_AABB[1][1]
    } else {
      //相交
      return !(b[0][0] > this.select_AABB[1][0] || b[1][0] < this.select_AABB[0][0] || b[0][1] > this.select_AABB[1][1] || b[1][1] < this.select_AABB[0][1])
    }
  }
  //判断包围盒与圆相交
  checkAABBInsertEllipse(obj) {
    const b = obj.aabb
    if (b[0][0] > this.select_AABB[0][0] && b[1][0] < this.select_AABB[1][0] && b[0][1] > this.select_AABB[0][1] && b[1][1] < this.select_AABB[1][1]) {
      return true
    }
    for (let i = 0; i < 4; i++) {
      if (insideEllipse(this.select_AABB_points[i], obj)) {
        return true
      }
    }
    return false
  }
  //根据框选盒子判断点线是否在内部或相交
  checkInsideSelectBoxPoints(arry, isPath = false, flag = false) {
    const result = []
    const length = arry.length
    const minX = this.select_AABB[0][0]
    const minY = this.select_AABB[0][1]
    const maxX = this.select_AABB[1][0]
    const maxY = this.select_AABB[1][1]
    if (this.select_mode && !flag) {
      for (let i = 0; i < length; i++) {
        let p = arry[i]
        let x = p[0]
        let y = p[1]
        if (x > minX && x < maxX && y > minY && y < maxY) {
          result.push(i)
        }
      }
    } else {
      if (!isPath) {
        for (let i = 0; i < length; i++) {
          let p1 = arry[i]
          let next = i + 1
          if (next === length) {
            next = 0
          }
          let p2 = arry[next]
          if (this.lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY)) {
            result.push(i)
            result.push(next)
          }
        }
      } else {
        for (let i = 0; i < length - 1; i++) {
          let p1 = arry[i]
          let p2 = arry[i + 1]
          if (this.lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY)) {
            result.push(i)
            result.push(i + 1)
          }
        }
      }
    }
    return result
  }

  //判断线段和盒子相交
  lineIntersectsSelectBox(p1, p2, minX, minY, maxX, maxY) {
    //都在盒子内
    if ((p1[0] > minX && p1[0] < maxX && p1[1] > minY && p1[1] < maxY) || (p2[0] > minX && p2[0] < maxX && p2[1] > minY && p2[1] < maxY)) {
      return true
    }

    const points = [
      [minX, minY],
      [minX, maxY],
      [maxX, maxY],
      [maxX, minY],
      [minX, minY],
    ]
    for (let i = 0; i < 4; i++) {
      let p3 = points[i]
      let p4 = points[i + 1]
      if (this.segmentsIntr(p1, p2, p3, p4)) {
        return true
      }
    }
    return false
  }

  segmentsIntr(a, b, c, d) {
    // 三角形abc 面积的2倍
    var area_abc = (a[0] - c[0]) * (b[1] - c[1]) - (a[1] - c[1]) * (b[0] - c[0])

    // 三角形abd 面积的2倍
    var area_abd = (a[0] - d[0]) * (b[1] - d[1]) - (a[1] - d[1]) * (b[0] - d[0])

    // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
    if (area_abc * area_abd >= 0) {
      return false
    }

    // 三角形cda 面积的2倍
    var area_cda = (c[0] - a[0]) * (d[1] - a[1]) - (c[1] - a[1]) * (d[0] - a[0])
    // 三角形cdb 面积的2倍
    // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
    var area_cdb = area_cda + area_abc - area_abd
    if (area_cda * area_cdb >= 0) {
      return false
    }

    return true

    //计算交点坐标
    var t = area_cda / (area_abd - area_abc)
    var dx = t * (b[0] - a[0]),
      dy = t * (b[1] - a[1])
    return { x: a.x + dx, y: a.y + dy }
  }

  //计算所有对象为整体的AABB
  calGraphicsAABB(result, aabb) {
    if (!result) {
      return deepClone(aabb)
    }
    if (result[0][0] > aabb[0][0]) {
      //minx
      result[0][0] = aabb[0][0]
    }
    if (result[0][1] > aabb[0][1]) {
      //miny
      result[0][1] = aabb[0][1]
    }
    if (result[1][0] < aabb[1][0]) {
      //maxx
      result[1][0] = aabb[1][0]
    }
    if (result[1][1] < aabb[1][1]) {
      //maxy
      result[1][1] = aabb[1][1]
    }
    return result
  }

  setCellPath(cell) {
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    const ruler_length = cell.rulers.length
    const label_length = cell.labels.length
    const cell3DArea_length = cell.cell3DArea.length
    const cell3DCutBox_length = cell.cell3DCutBox.length
    for (let i = 0; i < path_length; i++) {
      this.generatePath(cell.paths[i])
    }
    for (let i = 0; i < polygons_length; i++) {
      this.generatePath(cell.polygons[i])
    }
    for (let i = 0; i < ellipses_length; i++) {
      this.generatePath(cell.ellipses[i])
    }
    for (let i = 0; i < ref_length; i++) {
      this.generatePath(cell.references[i].box)
    }
    for (let i = 0; i < ruler_length; i++) {
      this.generatePath(cell.rulers[i])
    }
    for (let i = 0; i < label_length; i++) {
      this.generatePath(cell.labels[i])
    }
    for (let i = 0; i < cell3DArea_length; i++) {
      this.generatePath(cell.cell3DArea[i])
    }
    for (let i = 0; i < cell3DCutBox_length; i++) {
      this.generatePath(cell.cell3DCutBox[i])
    }
  }

  drawRef(canvas, ref, depth = 1, preCut, preAdd, scale = 1) {
    // if (!ref.cell) return
    let canEditRef = false
    if (ref === this.editReference) {
      canEditRef = true
    }
    // if (depth === 1) {

    //   // this.drawRect(canvas, ref.box.aabb)
    //   // this.drawRect(canvas, this.aabb)
    // }
    //在屏幕内 面积剔除
    // if (ref.magnification === 1000) {

    //   return
    // }
    if (depth !== 1 || (depth === 1 && this.checkInsideStage(ref.box.aabb) && (ref.cell.area === 0 || ref.cell.area * ref.magnification * this.area_scale > 5))) {
      canvas.save()
      // canvas.concat([ref.magnification, 0, ref.origin[0], 0, ref.x_reflection * ref.magnification, ref.origin[1], 0, 0, 1])
      canvas.translate(ref.origin[0], ref.origin[1])
      canvas.rotate(ref.rotation, 0, 0)
      canvas.scale(ref.magnification, ref.x_reflection * ref.magnification)
      let current_scale = scale * ref.magnification
      this.drawRefCell(canvas, this.cells[ref.cell.name], ref, canEditRef, depth, preCut, preAdd, current_scale)
      canvas.restore()
    }
    //包围盒debug
    // if(depth === 1){
    //   this.drawRect(canvas, ref.box.aabb)
    // }
  }

  drawRefCell(canvas, cell, ref, canEditRef, depth, preCut, preAdd, scale = 1) {
    if (!cell) return
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    const labels_len = cell.labels.length

    for (let i = 0; i < path_length; i++) {
      const path = cell.paths[i]
      this.drawRefCellGraphic(canvas, path, ref, canEditRef, preCut, preAdd, scale)
    }
    for (let i = 0; i < polygons_length; i++) {
      const polygon = cell.polygons[i]
      this.drawRefCellGraphic(canvas, polygon, ref, canEditRef, preCut, preAdd, scale)
    }
    for (let i = 0; i < ellipses_length; i++) {
      const ellipse = cell.ellipses[i]
      this.drawRefCellGraphic(canvas, ellipse, ref, canEditRef, preCut, preAdd, scale)
    }
    for (let i = 0; i < labels_len; i++) {
      const label = cell.labels[i]
      if (canEditRef) {
        this.selectObjByAABB(label)
      }
      this.drawLabel(canvas, label, preCut, preAdd)
    }
    for (let i = 0; i < ref_length; i++) {
      let ref = cell.references[i]
      if (canEditRef) {
        this.drawRefCellGraphic(canvas, ref.box, ref, canEditRef, preCut, preAdd, scale)
      }
      this.drawRef(canvas, ref, (depth += 1), preCut, preAdd, scale)
    }
  }

  drawRefCellGraphic(canvas, obj, ref, canEditRef, preCut, preAdd, scale = 1) {
    if (preAdd) {
      //预添加样式只绘制线
      let pt = this.pre_aligment_line_checked_paint
      if (!obj.isRefBox && scale !== 1) {
        pt = pt.copy()
        pt.setStrokeWidth(pt.getStrokeWidth() / scale)
      }
      if (obj.type === QedaGraphicType.CIRCLE) {
        canvas.drawOval(obj.path, pt)
      } else {
        canvas.drawPath(obj.path, pt)
      }
      if (!obj.isRefBox && scale !== 1) {
        pt.delete()
      }
    } else {
      let layer = obj.layer
      let stroke_pt //边框
      let stroke_fill //填充
      if (obj.hide) return
      if (canEditRef) {
        //编辑下层才能操作，否则只是渲染
        this.selectObjByAABB(obj)
        this.generateGraphicsCheckedLinesPath(obj, ref) //选中线
        obj.checked_points_index.forEach((shape_index, i) => {
          //选中点
          let s_p = obj.shape[shape_index]
          let point = this.applyEditRefTransform([s_p], ref).get_points()[0]
          this.checked_points.push(point[0], point[1])
        })
      }
      if (obj.isRefBox) {
        if (obj.checked) {
          stroke_pt = this.layer_checked_paints[0].pt
          stroke_fill = this.layer_checked_fill_paints[0].pt
        } else {
          return
        }
      } else if (canEditRef && obj.checked) {
        stroke_pt = this.layer_checked_paints[layer].pt
        stroke_fill = this.layer_checked_fill_paints[layer]
      } else {
        stroke_pt = this.layer_paints[layer].pt //边框
        stroke_fill = this.layer_fill_paints[layer] //填充
      }
      if (!stroke_pt) {
        return
      }
      // //预选中状态
      // if (!obj.checked && obj.preChecked) {
      //   stroke_pt = stroke_pt.copy()
      //   stroke_pt.setColor(CanvasKit.WHITE)
      //   stroke_fill = this.layer_checked_fill_paints[layer]
      // }
      if (!obj.isRefBox && scale !== 1) {
        stroke_pt = stroke_pt.copy()
        stroke_pt.setStrokeWidth(stroke_pt.getStrokeWidth() / scale)
        stroke_fill = this.getFillPtByScale(obj.layer, scale)
      }
      if (preCut) {
        //剪切样式
        stroke_pt = stroke_pt.copy()
        stroke_fill = stroke_fill.copy()
        stroke_pt.setAlphaf(0.5)
        stroke_fill.setAlphaf(0.5)
      }
      //绘制圆
      if (obj.type === QedaGraphicType.CIRCLE) {
        // canvas.drawOval(obj.path, stroke_pt)
        // canvas.drawOval(obj.path, stroke_fill)
        canvas.drawPath(obj.circlePath, stroke_fill)
        canvas.drawPath(obj.circlePath, stroke_pt)
        if (scale !== 1 || preCut) {
          stroke_pt.delete()
          stroke_fill.delete()
        }
        return
      }
      //绘制线
      if ((obj.type !== QedaGraphicType.PATH || obj.width) && !obj.isRefBox) {
        //绘制填充
        canvas.drawPath(obj.path, stroke_fill)
      }
      canvas.drawPath(obj.path, stroke_pt)
      if ((!obj.isRefBox && scale !== 1) || preCut) {
        stroke_pt.delete()
        stroke_fill.delete()
      }
    }
  }

  //生成cell paths
  generatePath(obj) {
    if (!obj || obj.deleted) {
      // this.pool.splice(i,1)
      return
    }
    if (obj.hide) return
    this.graphicsAABB = this.calGraphicsAABB(this.graphicsAABB, obj.aabb) //计算画板所有图形集合包围盒
    if (!this.editReference) {
      this.selectObjByAABB(obj) //框选
    }
    if (obj.type === QedaGraphicType.RULER || obj.type === QedaGraphicType.LABEL) return
    obj.insideStage = this.checkInsideStage(obj.aabb) //在屏幕内
    if (!obj.insideStage) return
    if (obj.isRefBox && !obj.checked) return //引用包围盒只有选中或预选中才显示
    if (obj.type === QedaGraphicType.PATH || obj.aabb_area == 0 || obj.aabb_area * this.area_scale > 20 || obj.checked) {
      //未小于像素面积 线除外
      // if(obj.reference?.origin){
      //   this.checked_points.push(obj.reference?.origin[0]) //position debug
      //   this.checked_points.push(obj.reference?.origin[1])
      // }
      // this.checked_points.push(obj.position[0]) //position debug
      // this.checked_points.push(obj.position[1])
      this.render_count++
      if (obj.checked) {
        //选中的图形最后绘制，保证图形显示在最上层
        this.display_checked_objs.push(obj)
        // this.generateGraphicsPath(canvas, obj, true)
        return
      }
      //计算path
      this.generateGraphicsPath(obj)
      obj.checked_points_index.forEach((shape_index, i) => {
        //this.drawPoint(canvas, obj.shape[shape_index], this.myaxis.scale) //绘制选中顶点信息
        let s_p
        s_p = obj.shape[shape_index]
        this.checked_points.push(s_p[0], s_p[1])
      })
      //计算选中边path
      this.generateGraphicsCheckedLinesPath(obj)
    } else {
      //小于像素面积用点代替
      if (obj.checked) {
        this.layer_checked_paints[obj.layer].points.push(obj.position[0], obj.position[1])
        // this.checked_pixel_points.push(obj.position[0])
        // this.checked_pixel_points.push(obj.position[1])
      } else {
        this.layer_paints[obj.layer].points.push(obj.position[0], obj.position[1])
        // this.points.push(obj.position[0])
        // this.points.push(obj.position[1])
      }
    }
  }

  drawDrcResult(canvas) {
    this.drcGraphicPaint.setStrokeWidth(4 / this.myaxis.scale)
    this.drcGraphics.forEach(obj => {
      if (obj.type == 'points') {
        canvas.drawPath(obj.path, this.drcPointPaint)
      } else {
        canvas.drawPath(obj.path, this.drcGraphicPaint)
      }
    })
  }
  //绘制对象池
  drawPool(canvas) {
    if (!Object.keys(this.layer_paints).length) return
    this.render_count = 0
    //渲染引用对象
    if (this.cell) {
      if (this.editReference) {
        this.checkedGraphicsAABB = null
        this.checkedGraphicsAABB_center = null
        this.checked_objs = []
        this.checked_points = [] //部分选择选中的点
        this.checked_lines = []
      }
      const len = this.cell.references.length
      for (let i = 0; i < len; i++) {
        let ref = this.cell.references[i]
        let scale = 1
        this.drawRef(canvas, ref, 1, ref.box.preCut, ref.box.preAdd, scale)
      }
    }
    //渲染顶层cell
    if (this.re_render || this.preAddCell) {
      if (!this.editReference) {
        this.checkedGraphicsAABB = null
        this.checkedGraphicsAABB_center = null
        this.checked_objs = []
        this.checked_objs_count = 0
        this.checked_points = [] //部分选择选中的点
        this.checked_lines = []
      }
      Object.values(this.layer_paints).forEach(val => (val.points = []))
      Object.values(this.layer_checked_paints).forEach(val => (val.points = []))
      this.graphicsAABB = null
      this.display_checked_objs = []
      this.points = [] //像素缩略点
      this.checked_pixel_points = [] //像素缩略点
      // const drag_flag = AXIS.drag_button === 0
      this.render_points = 0
      this.paths = []
      this.fillPaths = []

      if (this.cell) {
        this.setCellPath(this.cell)
        //更新cell包围盒
        this.cell.aabb = deepClone(this.graphicsAABB)
      }
      //整体选择下选中物体path paint生成
      const checked_length = this.display_checked_objs.length
      for (let i = 0; i < checked_length; i++) {
        this.generateGraphicsPath(this.display_checked_objs[i], true)
      }
      if (this.editReference) {
        //存在编辑下层
        // this.editReference.cell.bounding_box()
        this.editReference.cell.bounding_box()
        this.updateCellRefBox(this.editReference)
        this.generateRefBoxPath(this.editReference.box)
      }
    }
    const fill_path_length = this.fillPaths.length //绘制填充path
    const paths_length = this.paths.length //绘制边框path
    const checked_lines_length = this.checked_lines.length //绘制部分选择选中线path
    for (let i = 0; i < fill_path_length; i++) {
      const path = this.fillPaths[i]
      if (path.circle) {
        canvas.drawOval(path.path, path.pt)
      } else {
        canvas.drawPath(path.path, path.pt)
      }
    }

    for (let i = 0; i < paths_length; i++) {
      const path = this.paths[i]
      if (path.circle) {
        canvas.drawOval(path.path, path.pt)
      } else {
        canvas.drawPath(path.path, path.pt)
      }
    }

    for (let i = 0; i < checked_lines_length; i++) {
      const path = this.checked_lines[i]
      let ref = path.ref
      if (ref) {
        canvas.save()
        // canvas.concat([ref.magnification, 0, ref.origin[0], 0, ref.x_reflection * ref.magnification, ref.origin[1], 0, 0, 1])
        canvas.translate(ref.origin[0], ref.origin[1])
        canvas.rotate(ref.rotation, 0, 0)
        canvas.scale(ref.magnification, ref.x_reflection * ref.magnification)
        canvas.drawPath(path.path, path.pt)
        canvas.restore()
      } else {
        canvas.drawPath(path.path, path.pt)
      }
    }
    //绘制部分选择选中点
    canvas.drawPoints(CanvasKit.PointMode.Points, this.checked_points, this.point_checked_paint)

    //绘制图形省略像素点
    for (let val of Object.values(this.layer_paints)) {
      if (val.points.length) canvas.drawPoints(CanvasKit.PointMode.Points, val.points, val.pt)
    }
    for (let val of Object.values(this.layer_checked_paints)) {
      if (val.points.length) canvas.drawPoints(CanvasKit.PointMode.Points, val.points, val.pt)
    }

    //绘制标签 尺子
    if (this.cell) {
      let labels_len = this.cell.labels.length
      for (let i = 0; i < labels_len; i++) {
        this.drawLabel(canvas, this.cell.labels[i])
      }
      this.cell.rulers.forEach(r => {
        if (r.shape.length === 2) {
          this.drawDividRuler(canvas, r.shape[0], r.shape[1], r.checked)
        }
      })
    }
    //绘制预选择对象
    if (this.editReference) {
      canvas.save()
      let ref = this.editReference
      // canvas.concat([ref.magnification, 0, ref.origin[0], 0, ref.magnification * ref.x_reflection, ref.origin[1], 0, 0, 1])
      // canvas.rotate(ref.rotation, 0, 0)
      canvas.translate(ref.origin[0], ref.origin[1])
      canvas.rotate(ref.rotation, 0, 0)
      canvas.scale(ref.magnification, ref.x_reflection * ref.magnification)
      this.drawPreCheckedObj(canvas)
      canvas.restore()
    } else {
      this.drawPreCheckedObj(canvas)
    }

    //绘制点对齐选中点
    // let preAlignmentPoints_display =  this.preAlignmentPoints
    // let ref = this.editReference
    // if(ref){
    //   let rect = [this.preAlignmentPoints[0],this.preAlignmentPoints[1]]
    //   let temp = new QGdstk.Polygon(rect)
    //   temp.translate([ref.origin[0], -ref.origin[1]])
    //   temp.rotate(ref.rotation * Math.PI / 180)
    //   let point = temp.get_points()
    //   preAlignmentPoints_display = [point[0][0],point[0][1]]
    // }
    canvas.drawPoints(CanvasKit.PointMode.Points, this.preAlignmentPoints, this.pre_aligment_point_checked_paint)
    canvas.drawPoints(CanvasKit.PointMode.Points, this.alignmentPoints, this.aligment_point_checked_paint)
    //绘制线对齐选中线
    this.preAlignmentLinesPath.forEach(path => {
      canvas.drawPath(path, this.pre_aligment_line_checked_paint)
    })
    this.alignmentLinesPath.forEach(path => {
      canvas.drawPath(path, this.aligment_line_checked_paint)
    })

    //绘制预选择线
    this.preCheckedLinesPath.forEach(path => {
      canvas.drawPath(path, this.pre_aligment_line_checked_paint)
    })
    //绘制预选择点
    canvas.drawPoints(CanvasKit.PointMode.Points, this.preCheckedPoints, this.pre_aligment_point_checked_paint)

    this.drawRotAngle(canvas)
    //绘制吸附线 debug
    // this.adsorptionLinesPath.forEach(path => {
    //   canvas.drawPath(path, this.pre_aligment_line_checked_paint)
    // })
    //绘制吸附点
    //canvas.drawPoints(CanvasKit.PointMode.Points, this.adsorptionPoints, this.pre_aligment_point_checked_paint)

    // this.render_count = count
    for (let i = 0; i < this.tools.length; i++) {
      //遍历工具在画布绘制 鼠标选择框 自适应框 切割框
      const tool = this.tools[i]
      // tool.updateShape()
      this.drawTools(canvas, tool)
    }

    // //图形集合包围盒debug
    // this.drawRect(canvas, this.graphicsAABB)
    // //选中图形集合包围盒debug
    if (this.check_all) this.checkedGraphicsAABB = this.graphicsAABB
    //this.drawRect(canvas, this.checkedGraphicsAABB)
    if (this.checkedGraphicsAABB) {
      //计算选中物体包围盒中心点坐标
      const w = this.checkedGraphicsAABB[1][0] - this.checkedGraphicsAABB[0][0]
      const h = this.checkedGraphicsAABB[1][1] - this.checkedGraphicsAABB[0][1]
      this.checkedGraphicsAABB_center = [this.checkedGraphicsAABB[0][0] + w / 2, this.checkedGraphicsAABB[0][1] + h / 2]
    }
    this.checked_objs_count = this.checked_objs.length
    this.select_AABB = null //清除选框盒子
    this.check_all = false
    this.re_render = false
  }

  //绘制图形
  generateGraphicsPath(obj, checked = false) {
    const T = obj.type
    let layer = obj.layer
    if (obj.isRefBox) {
      //引用盒子layer可能为undefined
      layer = this.currentLayer?.layerNumber
      if (layer === undefined) layer = 0
    }
    let stroke_pt = checked ? this.layer_checked_paints[layer].pt : this.layer_paints[layer].pt //边框
    let stroke_fill = checked ? this.layer_checked_fill_paints[layer] : this.layer_fill_paints[layer] //填充
    //3DPaint
    if (obj.type == QedaGraphicType.CELL3DAREA) {
      stroke_pt = checked ? this.checked_cell3dAreaPaint : this.cell3dAreaPaint //边框
    } else if (obj.type == QedaGraphicType.CELL3DCUTBOX) {
      stroke_pt = checked ? this.checked_cell3dCutBoxPaint : this.cell3dCutBoxPaint //边框
    }

    // if(!stroke_pt || !stroke_fill){

    // }
    // if(!stroke_pt || !stroke_fill) return
    if (obj.preCut) {
      //剪切样式
      stroke_pt = stroke_pt.copy()
      stroke_fill = stroke_fill.copy()
      stroke_pt.setAlphaf(0.5)
      stroke_fill.setAlphaf(0.5)
    }

    if (T === QedaGraphicType.CIRCLE) {
      //绘制圆
      // const circle = [obj.shape[0][0], obj.shape[0][1], obj.shape[2][0], obj.shape[2][1]]
      // canvas.drawOval(circle, stroke_fill); //填充圆
      // canvas.drawOval(circle, stroke_pt); //绘制圆
      // this.fillPaths.push({ circle: true, path: obj.path, pt: stroke_fill })
      // this.paths.push({ circle: true, path: obj.path, pt: stroke_pt })
      this.fillPaths.push({ path: obj.circlePath, pt: stroke_fill })
      this.paths.push({ path: obj.circlePath, pt: stroke_pt })
    } else {
      //绘制线，折现，矩形，多边形
      if ((!obj.isTool && obj.type !== QedaGraphicType.PATH && !obj.isRefBox && obj.type !== QedaGraphicType.CELL3DAREA && obj.type !== QedaGraphicType.CELL3DCUTBOX) || obj.width) {
        this.fillPaths.push({ path: obj.path, pt: stroke_fill }) //canvas.drawPath(obj.path, stroke_fill); //填充纹理
      }
      this.paths.push({ path: obj.path, pt: stroke_pt }) //;canvas.drawPath(obj.path, stroke_pt); //绘制形状
    }
    this.render_points += obj.shape.length
  }

  //绘制当前编辑引用盒子
  generateRefBoxPath(obj) {
    let pt = this.layer_checked_paints[this.currentLayer.layerNumber].pt.copy()
    pt.setColor(CanvasKit.WHITE)
    this.paths.push({ path: obj.path, pt })
  }

  //绘制选中边path
  generateGraphicsCheckedLinesPath(obj, ref) {
    let layer = obj.layer
    if (layer === undefined) {
      layer = 0
    }
    const stroke_pt = this.layer_checked_paints[layer].pt
    obj.checked_paths.forEach(path => {
      this.checked_lines.push({ path: path, pt: stroke_pt, ref: ref })
      // canvas.drawPath(path, stroke_pt); //绘制选中边
    })
  }

  //绘制特殊工具
  drawTools(canvas, obj) {
    canvas.drawPath(obj.path, this.select_box_paint) //绘制形状
  }

  //绘制点坐标 debug
  drawPoint(canvas, p, scale) {
    const paint = new CanvasKit.Paint() //设置画笔
    const pointRadius = 5 / scale //点半径
    paint.setAntiAlias(this.antiAlias)
    paint.setColor(CanvasKit.YELLOW)

    canvas.drawCircle(p.x, p.y, pointRadius, paint)

    // const fontPaint = new CanvasKit.Paint();
    // const fontSize = 10 / scale;
    // fontPaint.setAntiAlias(true);
    // fontPaint.setColor(CanvasKit.WHITE);
    // const textFont = new CanvasKit.Font(null, fontSize);
    // const pp = { ...p } //浅拷贝
    // pp.x += 5 / scale; //偏移量
    // pp.y += 5 / scale;
    // textFont.setScaleX(-1) //x镜像翻转
    // canvas.save();
    // canvas.rotate(-180, pp.x, pp.y) //y轴旋转180
    // const textBlob = CanvasKit.TextBlob.MakeFromText(`${parseInt(p.x)},${parseInt(p.y)}`, textFont);
    // canvas.drawTextBlob(textBlob, pp.x, pp.y, fontPaint)
    // canvas.restore();
    paint.delete()
    // textBlob.delete();
    // fontPaint.delete();
  }

  //绘制矩形
  drawRect(canvas, aabb) {
    if (!aabb) return
    const lineWidth = 2.0 / this.myaxis.scale
    const paint = new CanvasKit.Paint() //设置画笔
    paint.setAntiAlias(this.antiAlias)
    paint.setColor(CanvasKit.YELLOW)
    paint.setStrokeWidth(lineWidth)
    paint.setStyle(CanvasKit.PaintStyle.Stroke)
    canvas.drawRect(CanvasKit.LTRBRect(aabb[0][0], aabb[0][1], aabb[1][0], aabb[1][1]), paint)
    paint.delete()
  }
  //绘制文字
  drawText(canvas, p, txt) {
    const fontPaint = new CanvasKit.Paint()
    const fontSize = 12
    fontPaint.setAntiAlias(this.antiAlias)
    fontPaint.setColor(CanvasKit.WHITE)
    const textFont = new CanvasKit.Font(null, fontSize)
    canvas.save()
    const textBlob = CanvasKit.TextBlob.MakeFromText(`${txt}`, textFont)
    canvas.drawTextBlob(textBlob, p.x, p.y, fontPaint)
    fontPaint.delete()
  }

  updateCoverIndex() {
    //计算重叠状态下选择图形下标
    const checked_length = this.checked_objs_id.length
    const max_index = checked_length - 1
    if (checked_length > 1) {
      // if(this.drag_objs) return
      this.cover_index += 1
      if (this.cover_index > max_index) this.cover_index = 0
    } else {
      this.cover_index = -1
    }
    setTimeout(() => {
      this.checked_objs_id = []
      this.drag_obj_id = null
    }, 0)
  }

  updatePreCoverIndex(move = false) {
    //计算重叠状态下选择图形下标
    const checked_length = this.pre_checked_objs_id.length
    const max_index = checked_length - 1

    if (checked_length > 0) {
      // this.pre_cover_index = this.cover_index + 1
      // if (this.pre_cover_index > max_index) this.pre_cover_index = 0
      // if (move) this.pre_cover_index = 0
      this.pre_cover_index = 0
    } else {
      this.pre_cover_index = -1
    }
    setTimeout(() => {
      this.pre_checked_objs_id = []
      this.pre_checked_objs_all_id = []
      // this.drag_obj_id = null
    }, 0)
  }

  updateCheckedObjsId(id) {
    if (!this.checked_objs_id.includes(id)) {
      this.checked_objs_id.push(id)
      this.checked_objs_id.sort((a, b) => b - a)
    }
  }

  updatePreCheckedObjsId(id, checked) {
    if (!this.pre_checked_objs_all_id.includes(id)) {
      this.pre_checked_objs_all_id.push(id)
      this.pre_checked_objs_all_id.sort((a, b) => b - a)
    }

    if (checked) return

    if (!this.pre_checked_objs_id.includes(id)) {
      this.pre_checked_objs_id.push(id)
      this.pre_checked_objs_id.sort((a, b) => b - a)
    }
  }

  canCheked(id) {
    //覆盖图形判断

    if (this.checked_objs_id.length < 2) return true
    return this.checked_objs_id[this.cover_index] === id
  }

  canPreCheked(id) {
    if (this.pre_checked_objs_id.length < 1) return true
    if (this.cover_index > 0) {
      let index = this.cover_index + 1
      if (index > this.pre_checked_objs_all_id.length - 1) index = 0
      return this.pre_checked_objs_all_id[index] === id
    }
    return this.pre_checked_objs_id[this.pre_cover_index] === id
  }

  updateSelectObjByAABB(aabb, mode) {
    this.select_mode = mode
    this.select_AABB = aabb
    const minX = this.select_AABB[0][0]
    const minY = this.select_AABB[0][1]
    const maxX = this.select_AABB[1][0]
    const maxY = this.select_AABB[1][1]
    this.select_AABB_points = [
      { x: minX, y: minY },
      { x: minX, y: maxY },
      { x: maxX, y: minY },
      { x: maxX, y: maxY },
    ]
    let ref = this.editReference
    if (ref) {
      let rect = this.select_AABB_points.map(p => [p.x, p.y])
      let temp = this.applyEditRefInverseTransform(rect, ref)
      this.select_AABB = temp.bounding_box()
      this.select_AABB_points = temp.get_points().map(p => {
        return { x: p[0], y: p[1] }
      })
    }
    this.render()
  }
  //框选 多选
  selectObjByAABB(obj) {
    // if(obj.layer === this.currentLayer?.layerNumber){ //选中当前图层
    if (obj.isRefBox && (obj.reference.cell.hide || obj.reference.cell.lock)) {
      obj.checked = false
      return
    }
    if (!obj.lock) {
      if (this.select_AABB) {
        //鼠标拖动生成选择框
        if (this.checkMode === 0) {
          //整体选择
          if (!obj.checked) {
            //判断未选中的图形
            obj.checked = this.checkInsideSelectBoxOver(obj)
          }
        } else if (this.checkMode === 1) {
          if (obj.isRefBox || obj.type === QedaGraphicType.LABEL || obj.type === QedaGraphicType.KEYPOINT || obj.type === QedaGraphicType.RULER) return
          //部分选择
          if (this.checkInsideSelectBoxPart(obj.aabb, true)) {
            //包围盒剔除
            obj.checked_points_index.push(...this.checkInsideSelectBoxPoints(obj.shape, obj.type === QedaGraphicType.PATH, obj.type === QedaGraphicType.CIRCLE)) //计算点
            obj.checked_points_index = Array.from(new Set(obj.checked_points_index)) //去重
            obj.checked_points_index.sort((a, b) => a - b) //排序
            obj.updateCheckedPaths()
            obj.updateLines()
          }
        }
      }

      if (this.check_all) {
        //全选
        if (this.checkMode === 0) {
          //整体选择
          obj.hited = true
          obj.checked = true
        } else if (this.checkMode === 1) {
          //部分选择
          obj.checked_points_index = obj.shape.map((p, index) => index)
          obj.updateCheckedPaths()
          obj.updateLines()
        }
      } else if (obj.checked || obj.checked_points_index.length) {
        //只有左键拖动才固定画板
        this.checked_objs.push(obj)
        if (!this.checkedGraphicsAABB) {
          //赋初值

          this.checkedGraphicsAABB = [
            [obj.aabb[0][0], obj.aabb[0][1]],
            [obj.aabb[1][0], obj.aabb[1][1]],
          ]
        }
        this.calGraphicsAABB(this.checkedGraphicsAABB, obj.aabb)
      }
    }
    // }
  }
  //判断鼠标是否命中选中物体
  checkInsideCheckedObjs(mouse_pos) {
    const length = this.checked_objs.length
    if (this.checkMode === 0) {
      //整体选择
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        // obj.checked_points_index = []
        if (checkHit(mouse_pos, obj)) {
          return true
        }
      }
    } else if (this.checkMode === 1) {
      //部分选择
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        // obj.checked_points_index = []
        if (checkHitBeforePart(mouse_pos, obj)) {
          return true
        }
      }
    }

    return false
  }

  //对象池鼠标移动事件
  onMouseMovePool(e) {
    const shiftKey = e.shiftKey
    const ctrlKey = e.ctrlKey
    this.myaxis.hit_point = null
    let mouse_pos = this.getMousePos()
    let mouse_pos_arr = [mouse_pos.x, mouse_pos.y]
    //吸附坐标计算
    this.adsorptionPoints = []
    this.adsorptionLines = []
    this.adsorptionLinesPath = []
    let _this = this

    // this.myaxis.angled_dsorp_point = null
    //斜角吸附 拖动或者点击移动
    if (((this.moveMod === 0 && this.myaxis.left_drag) || this.moveMod === 1) && this.myaxis.angledAdsorption && this.movePosition.length) {
      let result = isAdsorb({ x: this.movePosition[0][0], y: this.movePosition[0][1] }, mouse_pos, true, 'um')

      if (result.rotatePoint) {
        this.myaxis.angled_dsorp_point = [result.rotatePoint.x, result.rotatePoint.y]
        mouse_pos = result.rotatePoint
      }
    } else {
      //吸附
      if (this.myaxis.adsorpPoint && !this.myaxis.drag) {
        this.adsorpGraphic(mouse_pos, mouse_pos_arr)
        if (this.myaxis.hit_point) {
          let hit_point = this.myaxis.hit_point
          if (this.editReference) {
            hit_point = this.applyEditRefInverseTransform([hit_point], this.editReference).get_points()[0]
          }
          mouse_pos = { x: hit_point[0], y: hit_point[1] }
        }
      }
    }
    if (this.preAddCell) {
      this.preAddCell.position = [mouse_pos.x, mouse_pos.y]
      this.preAddCell.updateShape()
      return
    }
    if (!this.check_able) return
    if (this.alignmentLines.length) {
      this.alignmentLineSlope = getSlope(this.alignmentLines[0][0], this.alignmentLines[0][1])
    }
    //全局选择线
    if (this.checkMode === 10) {
      this.preCheckedLines = []
      function pickAllGraphicLine(obj, transforms) {
        if (obj.isTool || obj.lock || obj.hide || obj.isRefBox) return //工具图形 隐藏 锁定 其他图层 禁止选中
        let mp = mouse_pos
        if (transforms.length) {
          let transP = _this.transPointsInverse([mouse_pos_arr], transforms)
          mp = { x: transP[0][0], y: transP[0][1] }
        }
        const result = checkHit(mp, obj)
        if (result?.lines?.length) {
          //存在命中线
          let line = result.lines[0]

          if (transforms.length) {
            line = _this.transPoints(line, transforms)
          }
          _this.preCheckedLines = [line]
        }
      }
      this.loopCellGraphics(this.currentEditCell, pickAllGraphicLine, null, true, [], mouse_pos)
      _this.updatePreCheckedLinesPath()
      this.render()
      return
    }
    let pre_check = true
    //预选中模式 排除框选模式
    if (pre_check && !this.tools.length && !this.preAddCell) {
      this.preCheckGraphic(mouse_pos, mouse_pos_arr)
      this.updatePreCoverIndex(true)
    }
    if (this.checkMode === 0) {
      //整体选择模式
      let rot, rot_old, rot_delta
      const rotating = AXIS.left_drag && this.moveMod === 2 && this.movePosition.length == 2
      const length = this.checked_objs.length
      if (!length) return
      if (rotating) {
        let center = this.checkedGraphicsAABB_center
        if (length === 1) {
          const obj = this.checked_objs[0]
          if (obj.isRefBox) {
            //引用盒子旋转中心为本地原点坐标
            center = this.checked_objs[0].position
          }
        }
        rot = -getAngle(
          {
            x: this.movePosition[0][0] - center[0],
            y: this.movePosition[0][1] - center[1],
          },
          {
            x: this.movePosition[1][0] - center[0],
            y: this.movePosition[1][1] - center[1],
          }
        )
        if (this.myaxis.angledAdsorption) {
          rot = Math.round(rot / 45) * 45 //45度旋转吸附
        }
        rot_old = this.rotation
        this.rotation = rot
        rot_delta = rot - rot_old
      }
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        // if (obj.checked) {
        //点击移动
        if (this.moveMod === 1 && this.movePosition.length) {
          obj.movePosByMouse(mouse_pos)
          this.render()
          continue
        }
        if (rotating) {
          //旋转模式
          if (this.drag_count === 0) {
            //记录旋转起始状态
            this.recordHistory('transform')
          }
          // if(obj.type !== QedaGraphicType.CIRCLE){
          if (this.checked_objs_count > 1 && this.rotat_center) {
            //多选 公转 计算物体rotation和position
            const temp = new QGdstk.Polygon([obj.position])
            temp.rotate((rot_delta * Math.PI) / 180, this.rotat_center)
            obj.position = temp.get_points()[0]
            obj.rotate(rot + obj.rotation_old)
            obj.updateShape()
          } else {
            //单选 自转 计算物体rotation
            rot += obj.rotation_old
            // if (this.myaxis.angledAdsorption) {
            //   rot = Math.round(rot / 45) * 45 //45度旋转吸附
            // }
            obj.rotate(rot)
            obj.updateShape()
            // }
            this.render()
          }
          this.drag_count++
          continue
        }
        if (AXIS.left_drag && !shiftKey && !ctrlKey && !this.moveMod) {
          //左键拖动
          if (!this.checked_objs_hited) {
            //拖动时鼠标起点未在选中图形内部
            obj.resetStatus()
            continue
          }
          if (this.drag_count === 0) {
            //记录移动起始状态
            this.recordHistory('transform')
          }
          this.drag_count++
          obj.movePosByMouse(mouse_pos)
        }
        // }
      }
      return
    }

    if (this.checkMode === 1) {
      //部分选择模式
      const length = this.checked_objs.length
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        if (obj.isRefBox) continue //部分选择过滤引用
        if (this.moveMod === 1 && this.movePosition.length) {
          obj.movePointsByByMouse(mouse_pos)
          obj.stretchConfirm()
          continue
        }
        if (AXIS.left_drag && !shiftKey && !ctrlKey && !this.moveMod) {
          //左键拖动
          if (!this.checked_objs_hited) {
            //拖动时鼠标起点未在选中图形内部
            obj.resetStatus()
            continue
          }
          if (this.drag_count === 0) {
            //记录移动起始状态
            this.recordHistory('shape')
          }
          this.drag_count++
          obj.movePointsByByMouse(mouse_pos)
          obj.stretchConfirm()
        }
      }
      this.render()
    }

    function partAligmentPick_overall(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.mouse_drag || _this.drawing) return
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        mp = { x: transP[0], y: transP[1] }
      }
      const result = checkHit(mp, obj)
      if (result?.point) {
        let p = result.point
        if (transforms.length) {
          p = _this.transPoints([result.point], transforms)[0]
        }
        _this.preAlignmentPoints.push(p[0], p[1])
        return 'break'
      }
    }

    function partAligmentPick_point2(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        mp = { x: transP[0], y: transP[1] }
      }
      const result = checkHit(mp, obj)
      if (result?.point) {
        let p = result.point
        if (transforms.length) {
          p = _this.transPoints([result.point], transforms)[0]
        }
        _this.preAlignmentPoints.push(p[0], p[1])
        return 'break'
      }
    }

    if (this.checkMode === 8) {
      //点对齐选择模式
      const checked_length = this.checked_objs_count
      this.preAlignmentPoints = []
      this.preAlignmentLines = []
      this.preAlignmentLinesPath = []
      if (this.checkMode_bk === 0) {
        //整体选择下
        this.loopCellGraphics(this.currentEditCell, partAligmentPick_overall, null, true, [], mouse_pos)
      } else if (this.checkMode_bk === 1) {
        //部分选择下
        if (this.alignmentPoints.length === 0) {
          //第一个点在选择图形下选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            // if (obj.type === QedaGraphicType.CIRCLE) continue
            const result = checkHit(mouse_pos, obj)
            if (result.point) {
              this.preAlignmentPoints.push(result.point[0], result.point[1])
              break
            }
          }
        } else {
          //第二个点在所有图形下选择
          this.loopCellGraphics(this.currentEditCell, partAligmentPick_point2, null, true, [], mouse_pos)
        }
      }
    }

    function lineAligmentPick_overall(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.mouse_drag || _this.drawing) return
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr, _this.alignmentLines[0][0], _this.alignmentLines[0][1]], transforms)
        mp = { x: transP[0][0], y: transP[0][1] }

        _this.alignmentLineSlope = getSlope(transP[1], transP[2])
      }
      const result = checkHit(mp, obj)
      if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }
        _this.preAlignmentLines.push(line)
        _this.updatePreAlignmentLinesPath()
        return 'break'
      }
    }

    function lineAligmentPick_part2(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr, _this.alignmentLines[0][0], _this.alignmentLines[0][1]], transforms)
        mp = { x: transP[0][0], y: transP[0][1] }

        _this.alignmentLineSlope = getSlope(transP[1], transP[2])
      }
      const result = checkHit(mp, obj)
      if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }

        _this.preAlignmentLines.push(line)
        _this.updatePreAlignmentLinesPath()
        return 'break'
      }
    }

    if (this.checkMode === 9) {
      //线对齐选择模式
      const checked_length = this.checked_objs_count
      this.preAlignmentPoints = []
      this.preAlignmentLines = []
      this.preAlignmentLinesPath = []
      if (this.checkMode_bk === 0) {
        //整体选择下
        if (this.alignmentLines.length === 0) {
          //第一条线在自身选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            if (this.mouse_drag || this.drawing) continue
            let transforms = []
            let result = null
            if (obj.isRefBox) {
              let res = this.getRefLines(mouse_pos_arr, obj.reference, transforms)
              if (res) {
                result = res.result
                transforms = res.transforms
              }
            } else {
              result = checkHit(mouse_pos, obj)
            }
            if (result?.lines?.length) {
              //存在命中线
              let line = result.lines[0]
              if (transforms.length) {
                line = this.transPoints(line, transforms)
              }
              this.preAlignmentLines.push(line)
              this.updatePreAlignmentLinesPath()
              return 'break'
            }
          }
        } else {
          this.loopCellGraphics(this.currentEditCell, lineAligmentPick_overall, null, true, [], mouse_pos)
        }
      } else if (this.checkMode_bk === 1) {
        //部分选择下
        if (this.alignmentLines.length === 0) {
          //第一个点在选择图形下选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            // if(obj.type === QedaGraphicType.CIRCLE) continue
            const result = checkHit(mouse_pos, obj)
            if (result?.lines?.length) {
              //存在命中线
              this.preAlignmentLines.push(result.lines[0])
              this.updatePreAlignmentLinesPath()
              return 'break'
            }
          }
        } else {
          //第二个点在所有图形下选择
          this.loopCellGraphics(this.currentEditCell, lineAligmentPick_part2, null, true, [], mouse_pos)
        }
      }
    }
  }

  //对象池鼠标点击事件
  onMouseDownPool(e, mouse_pos) {
    if (!this.check_able) return
    if (e.button !== 0) return
    const shiftKey = e.shiftKey
    const ctrlKey = e.ctrlKey

    if (this.checkMode === 0) {
      //整体选择模式
      const length = this.checked_objs.length
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        if (this.moveMod === 1) {
          //点击移动模式
          obj.updateMouseOffset(mouse_pos)
          continue
        }
        if (this.moveMod === 2) {
          //旋转模式
          continue
        }
        if (this.checked_objs_count && !shiftKey && !ctrlKey) {
          //移动完成后如果落点没有在指定区域重置状态
          // setTimeout(() => {
          if (!this.checked_objs_hited) {
            obj.resetStatus()
            continue
          }
          // }, 0)
        }
        if (shiftKey || this.checked_objs_count) {
          //多选
          obj.updateMouseOffset(mouse_pos)
          continue
        }
      }
      return
    }

    if (this.checkMode === 1) {
      //部分选择模式
      const length = this.checked_objs.length
      for (let i = 0; i < length; i++) {
        const obj = this.checked_objs[i]
        if (obj.isRefBox) continue //部分选择过滤引用
        if (this.moveMod === 1) {
          if (obj.isRefBox) continue //部分选择过滤引用
          //拉伸模式
          obj.updatePointsMouseOffset(mouse_pos)
          continue
        }
        obj.updatePointsMouseOffset(mouse_pos)
        if (!shiftKey && !ctrlKey) {
          //移动完成后如果落点没有在指定区域重置状态
          if (!this.checked_objs_hited) {
            obj.resetStatus()
            // obj.stretchConfirm()//拉伸后检查自相交 如果相交重置
            continue
          }
        }
      }
    }
  }

  //对象池鼠标点取消击事件 选中物体判断
  onMouseUpPool(e) {
    if (this.preAddCell) {
      if (e.button === 0) {
        this.preAddCell.preAdd = false
        let cells = []
        let action1 = { action: 'add', cellName: this.getCurrentCellName(), objs: [this.preAddCell] }
        if (!this.preAddCell.selfDevice) {
          cells = this.preAddCell.reference.cell.QCELL.dependencies(true)
          let action2 = { action: 'addCell', objs: cells }
          this.editHistory.record([action2, action1])
          this.editHistory.record([action1])
        } else {
          this.editHistory.record([action1])
        }
        cells.push(this.preAddCell.reference.cell.QCELL)
        this.qedaLib.write()
        let layers = this.cell.getLayers()
        bus.$emit('syncLayerList', layers)

        bus.$emit(
          'updateFileTree',
          cells.map(cell => cell.name)
        )
        bus.$emit('fileChange', this.fileInfo.fileId)

        this.preAddCell = null
        return
      }
    }
    this.drag_count = 0
    if (!this.check_able || !this.currentLayer) return
    const shiftKey = e.shiftKey
    const ctrlKey = e.ctrlKey
    let mouse_pos = this.getMousePos()
    if (this.myaxis.adsorpPoint && this.myaxis.hit_point) {
      let hit_point = this.myaxis.hit_point
      if (this.editReference) {
        hit_point = this.applyEditRefInverseTransform([hit_point], this.editReference).get_points()[0]
      }
      mouse_pos = { x: hit_point[0], y: hit_point[1] }
    }
    let mouse_pos_arr = [mouse_pos.x, mouse_pos.y]
    let _this = this
    if (shiftKey) {
      this.cover_index = -1
    }
    //整体选择
    function overallPick(obj) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (obj.isRefBox && (obj.reference.cell.hide || obj.reference.cell.lock)) {
        obj.checked = false
        return
      }
      if (!_this.check_able) return //舞台禁止选中模式禁止选中
      if (_this.moveMod === 1) return //点击移动模式禁止选中
      if (e.button === 0) {
        //松开左键
        if (obj.checked) {
          //已选中
          if (_this.moveMod === 1) {
            //点击移动模式
            obj.updateMouseOffset(mouse_pos)
            return
          } else if (_this.moveMod === 2) {
            // bus.$emit('cancelSidebarSelect')
            //结束旋转
            _this.rotat_center = null
            _this.rotation = 0
            obj.setRotationOld()
            obj.updateSlopes()
            return
          }
          if (shiftKey) return //shift已经多选
        }
        obj.hited = checkHit(mouse_pos, obj)
        if (ctrlKey) {
          //ctrl取消选择
          if (obj.checked && obj.hited) {
            obj.resetStatus()

            return 'break'
          }
          return
        }
        if (obj.hited) {
          if (_this.mouse_drag) return //避免移动完成后的落点在重叠部分进行循环切换
          _this.updateCheckedObjsId(obj.id) //更新选中元素列表
          setTimeout(() => {
            obj.checked = _this.canCheked(obj.id) //等待上面列表更新结束延迟判断是否可以被选中
            obj.hited = obj.checked
          })
        } else {
          if (_this.checked_objs_count <= 1) {
            //如果不是多选重置状态
            obj.resetStatus()
          }
        }
      }
    }
    //部分选择模式
    function partPick(obj) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.moveMod === 1) return //拉伸模式禁止选中
      if (e.button === 0) {
        if (obj.checked) {
          if (_this.moveMod === 1) {
            //拉伸模式
            obj.updatePointsMouseOffset(mouse_pos)
            return
          }
        }
        //松开左键
        if (_this.mouse_drag || _this.drawing) return
        if (!shiftKey && !ctrlKey) {
          //单选清除数组
          obj.checked_points_index = []
        }

        const result = checkHit(mouse_pos, obj, ctrlKey)
        if (result) return 'break' //每次只选择一条边或点，避免重叠图形一次多选
        // if(obj.rotation !== 0){ //经过旋转缩放变换 重置本地坐标
        //   obj.resetPoints()
        // }
      }
    }
    if (this.checkMode === 0) {
      //整体选择模式
      this.loopCellGraphics(this.currentEditCell, overallPick)
      setTimeout(() => {
        this.preCheckGraphic(mouse_pos, mouse_pos_arr, shiftKey)
        this.updatePreCoverIndex()
      })
    }
    if (this.checkMode === 1) {
      //部分选择模式
      this.loopCellGraphics(this.currentEditCell, partPick)
    }

    function pointAligmentPickPoints_overall(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.mouse_drag || _this.drawing) return
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        mp = { x: transP[0], y: transP[1] }
      }
      const result = checkHit(mp, obj)
      if (result?.point) {
        let p = result.point
        if (transforms.length) {
          p = _this.transPoints([result.point], transforms)[0]
        }
        //存在命中点
        _this.alignmentPoints.push(p[0], p[1])
        if (_this.alignmentPoints.length === 4) {
          //选中两个点执行点对齐
          _this.recordHistory('shape')
          _this.alignmentObjsByPoints()
        }
        return 'break'
      }
    }

    function pointAligmentPickPoints_part2(obj, transforms) {
      //点对齐部分选择选择第二个点
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        mp = { x: transP[0], y: transP[1] }
      }
      const result = checkHit(mp, obj)
      if (result.point) {
        let p = result.point
        if (transforms.length) {
          p = _this.transPoints([result.point], transforms)[0]
        }
        //存在命中点
        _this.alignmentPoints.push(p[0], p[1])
        if (_this.alignmentPoints.length === 4) {
          //选中两个点执行点对齐
          _this.recordHistory('shape')
          _this.alignmentObjsByPoints()
        }
        return 'break'
      }
    }

    if (this.checkMode === 8 && e.button === 0) {
      //点对齐部分选择模式
      const checked_length = this.checked_objs_count
      if (this.checkMode_bk === 0) {
        //整体选择下
        this.loopCellGraphics(this.currentEditCell, pointAligmentPickPoints_overall, null, true, [], mouse_pos)
      } else if (this.checkMode_bk === 1) {
        //部分选择下
        if (this.alignmentPoints.length === 0) {
          //第一个点在选择图形下选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            // if (obj.type === QedaGraphicType.CIRCLE) continue
            const result = checkHit(mouse_pos, obj)
            if (result.point) {
              this.alignmentPoints.push(result.point[0], result.point[1])
              this.pointAlignmentTarget = obj
              break
            }
          }
        } else {
          //第二个点在所有图形下选择
          this.loopCellGraphics(this.currentEditCell, pointAligmentPickPoints_part2, null, true, [], mouse_pos)
        }
      }
    }

    // this.alignmentLineSlope = getSlope(...result.lines[0])
    function lineAligmentPickLine_overall(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.mouse_drag || _this.drawing) return
      let mp = mouse_pos
      if (transforms.length) {
        // let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        // mp = { x: transP[0], y: transP[1] }
        let transP = _this.transPointsInverse([mouse_pos_arr, _this.alignmentLines[0][0], _this.alignmentLines[0][1]], transforms)
        mp = { x: transP[0][0], y: transP[0][1] }

        _this.alignmentLineSlope = getSlope(transP[1], transP[2])
      }
      const result = checkHit(mp, obj)
      if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }
        _this.alignmentLines.push(line)
        _this.updateAlignmentLinesPath()
        if (_this.alignmentLines.length === 2) {
          _this.recordHistory('shape')

          _this.alignmentObjsByLines()
        }
        return 'break'
      }
    }

    function lineAligmentPickLine_part2(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr, _this.alignmentLines[0][0], _this.alignmentLines[0][1]], transforms)
        mp = { x: transP[0][0], y: transP[0][1] }

        _this.alignmentLineSlope = getSlope(transP[1], transP[2])
      }
      const result = checkHit(mp, obj)
      if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }
        _this.alignmentLines.push(line)
        _this.updateAlignmentLinesPath()
        if (_this.alignmentLines.length === 2) {
          _this.recordHistory('shape')

          _this.alignmentObjsByLines()
          return 'break'
        }
      }
    }

    if (this.checkMode === 9) {
      //线对齐选择模式
      const checked_length = this.checked_objs_count
      if (this.checkMode_bk === 0) {
        //整体选择下
        if (this.alignmentLines.length === 0) {
          //第一条线在自身选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            if (this.mouse_drag || this.drawing) continue
            let transforms = []
            let result = null
            if (obj.isRefBox) {
              let res = this.getRefLines(mouse_pos_arr, obj.reference, transforms)
              if (res) {
                result = res.result
                transforms = res.transforms
              }
            } else {
              result = checkHit(mouse_pos, obj)
            }
            if (result?.lines?.length) {
              //存在命中线
              let line = result.lines[0]
              if (transforms.length) {
                line = this.transPoints(line, transforms)
              }
              this.alignmentLines.push(line)
              this.alignmentLineSlope = getSlope(...line)
              this.updateAlignmentLinesPath()
              break
            }
          }
        } else {
          //选择第二条边
          this.loopCellGraphics(this.currentEditCell, lineAligmentPickLine_overall, null, true, [], mouse_pos)
        }
      } else if (this.checkMode_bk === 1) {
        //部分选择下
        if (this.alignmentLines.length === 0) {
          //第一个点在选择图形下选择
          for (let i = 0; i < checked_length; i++) {
            const obj = this.checked_objs[i]
            if (obj.isTool || obj.lock || obj.hide) continue //工具图形 隐藏 锁定 其他图层 禁止选中
            // if(obj.type === QedaGraphicType.CIRCLE) continue
            const result = checkHit(mouse_pos, obj)
            if (result?.lines?.length) {
              //存在命中线
              this.alignmentLines.push(result.lines[0])
              this.alignmentLineSlope = getSlope(...result.lines[0])
              // alert(this.alignmentLineSlope)
              this.updateAlignmentLinesPath()
              break
            }
          }
        } else {
          //第二条线在所有图形下选择
          this.loopCellGraphics(this.currentEditCell, lineAligmentPickLine_part2, null, true, [], mouse_pos)
        }
      }
    }

    function pickAllGraphicLine(obj, transforms) {
      if (obj.isTool || obj.lock || obj.hide || obj.isRefBox) return //工具图形 隐藏 锁定 其他图层 禁止选中
      let mp = mouse_pos
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)
        mp = { x: transP[0][0], y: transP[0][1] }
      }
      const result = checkHit(mp, obj)
      if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }
        _this.runTOOLSQUIDZLINE(line)
        return 'break'
        // _this.checkedLines = [line]
      }
    }
    //全局选择线
    if (this.checkMode === 10) {
      this.loopCellGraphics(this.currentEditCell, pickAllGraphicLine, null, true, [], mouse_pos)
    }
  }

  //对象池键盘事件
  onKeyDownPool(e) {
    const key = e.key
    if (key === 'Escape') {
      //取消选择操作
      function escape(obj) {
        obj.resetStatus()
      }
      this.loopCellGraphics(this.currentEditCell, escape)
    }
  }

  //更新点击移动相关状态
  updateMoveMode() {
    let mouse_pos = this.getMousePos()
    let pos = [mouse_pos.x, mouse_pos.y]
    //整体移动，计算45度吸附角度
    if (this.moveMod === 0) {
      this.movePosition[0] = pos
    }
    if (this.moveMod === 1) {
      //点击移动模式判断，清除鼠标位置记录

      this.movePosition.push(pos)
      if (this.movePosition.length === 1) {
        if (this.checkMode === 0) {
          this.recordHistory('transform')
        } else if (this.checkMode === 1) {
          this.recordHistory('shape')
        }
      }
      if (this.movePosition.length > 1) {
        this.resetMoveMode()
      }
    }
    if (this.moveMod === 2) {
      //旋转模式判断，清除鼠标位置记录
      if (this.movePosition.length) {
        this.movePosition[1] = pos
      } else {
        this.movePosition.push(pos)
      }
    }
  }

  resetMoveMode() {
    bus.$emit('cancelSidebarSelect')
    this.moveMod = 0
    this.movePosition = []
  }

  //返回鼠标位置对象
  getMousePos() {
    let ref = this.editReference
    if (ref) {
      //如果编辑引用对象鼠标位置转换到引用的局部坐标
      let mouse_pos = this.myaxis.mouse_point
      let trans_p = this.applyEditRefInverseTransform([[mouse_pos.x, mouse_pos.y]], ref).get_points()[0]
      return { x: trans_p[0], y: trans_p[1] }
    } else {
      return this.myaxis.mouse_point
    }
  }

  //点对齐
  alignmentObjsByPoints() {
    const p1 = [this.alignmentPoints[0], this.alignmentPoints[1]]
    const p2 = [this.alignmentPoints[2], this.alignmentPoints[3]]
    const data = this.checked_objs
    const data_size = data.length
    const offset = this.alignmentOffset
    if (this.checkMode_bk === 0) {
      //整体选择模式下对齐
      for (let j = 0; j < data_size; j++) {
        const p = data[j].position
        const p_new = [p2[0] - p1[0] + p[0] + offset[0], p2[1] - p1[1] + p[1] + offset[1]]

        data[j].position = p_new
        data[j].updateShape()
      }
    } else if (this.checkMode_bk === 1) {
      const p_new = [p2[0] + offset[0], p2[1] + offset[1]]

      //部分选择模式下对齐
      for (let j = 0; j < data_size; j++) {
        // const p_new = [p2[0] + offset[0], p2[1] + offset[1]]

        stretchPoint(data[j], p1, p_new)
      }
    }
    this.resetStatus()
    this.checkMode = this.checkMode_bk
    this.checkMode_bk = 0
  }

  //线对齐
  alignmentObjsByLines() {
    const line_1 = this.alignmentLines[0]
    const line_2 = this.alignmentLines[1]
    const offset_origin = getLineOffset(line_1, line_2)

    const data = this.checked_objs

    const data_size = data.length
    const offset = this.alignmentOffset
    if (this.checkMode_bk === 0) {
      //整体选择模式下对齐
      for (let j = 0; j < data_size; j++) {
        const p = data[j].position
        const p_new = [p[0] + offset_origin[0] + offset[0], p[1] + offset_origin[1] + offset[1]]

        data[j].position = p_new
        data[j].updateShape()
      }
    } else if (this.checkMode_bk === 1) {
      //部分选择模式下对齐
      for (let j = 0; j < data_size; j++) {
        const p_new = [line_1[0][0] + offset_origin[0] + offset[0], line_1[0][1] + offset_origin[1] + offset[0]]

        stretchPoint(data[j], line_1[0], p_new)
        // const point_offset = [offset_origin[0] + offset[0], offset_origin[0] + offset[0]]
        // stretchLines(data[j], point_offset)
      }
    }
    this.resetStatus()
    this.checkMode = this.checkMode_bk
    this.checkMode_bk = 0
  }

  //重置状态
  resetStatus() {
    this.preCheckedPoints = []
    this.preCheckedLines = []
    this.alignmentOffset = []
    this.alignmentPoints = []
    this.preAlignmentPoints = []
    this.preAlignmentLines = []
    this.preAlignmentLinesPath = []
    this.alignmentLines = []
    this.alignmentLinesPath = []
    this.alignmentLineSlope = null
    this.alignmentTarget = []
    this.updatePreCheckedLinesPath()
    this.updateAdsorptionLinesPath()
    this.updateAlignmentLinesPath()
    this.updatePreAlignmentLinesPath()
  }

  //更新预选择线
  updatePreCheckedLinesPath() {
    this.preCheckedLinesPath = []
    this.preCheckedLines?.forEach(line => {
      let path = new CanvasKit.Path()
      path.moveTo(line[0][0], line[0][1])
      path.lineTo(line[1][0], line[1][1])
      this.preCheckedLinesPath.push(path)
    })
  }

  //更新吸附线
  updateAdsorptionLinesPath() {
    this.adsorptionLinesPath = []
    this.adsorptionLines?.forEach(line => {
      let path = new CanvasKit.Path()
      path.moveTo(line[0][0], line[0][1])
      path.lineTo(line[1][0], line[1][1])
      this.adsorptionLinesPath.push(path)
    })
  }

  //更新鼠标预选择线
  updatePreAlignmentLinesPath() {
    this.preAlignmentLines?.forEach(line => {
      let path = new CanvasKit.Path()
      path.moveTo(line[0][0], line[0][1])
      path.lineTo(line[1][0], line[1][1])
      this.preAlignmentLinesPath.push(path)
    })
  }

  //更新边对齐选中线
  updateAlignmentLinesPath() {
    this.alignmentLinesPath = []
    this.alignmentLines?.forEach(line => {
      let path = new CanvasKit.Path()
      path.moveTo(line[0][0], line[0][1])
      path.lineTo(line[1][0], line[1][1])
      this.alignmentLinesPath.push(path)
    })
  }

  //清空cell状态
  resetCellStatus() {
    function resetCell(obj) {
      obj.resetStatus()
    }
    this.loopCellGraphics(this.cell, resetCell)
  }

  //循环cell所有图形
  loopCellGraphics(cell, func, ref, depth = false, transforms = [], mouse_pos, dep_count = 0) {
    if (!cell) return
    // if(this.editReference){//编辑下层
    //   cell = this.cells[this.editReference.name]
    // }
    if (ref && !depth) {
      func(ref.box)
      return
    }
    //记录引用变换
    if (ref) {
      transforms.push(ref)
    }
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    const ruler_length = cell.rulers.length
    const label_length = cell.labels.length
    const cell3DArea_length = cell.cell3DArea.length
    const cell3DCutBox_length = cell.cell3DCutBox.length
    for (let i = 0; i < path_length; i++) {
      let result = func(cell.paths[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < polygons_length; i++) {
      let result = func(cell.polygons[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ellipses_length; i++) {
      let result = func(cell.ellipses[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ruler_length; i++) {
      let result = func(cell.rulers[i], [])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < label_length; i++) {
      let result = func(cell.labels[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < cell3DArea_length; i++) {
      let result = func(cell.cell3DArea[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < cell3DCutBox_length; i++) {
      let result = func(cell.cell3DCutBox[i], transforms)
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ref_length; i++) {
      //根据ref盒子过滤第一层引用
      if (mouse_pos && dep_count == 0 && !insideAABB_expand(mouse_pos, cell.references[i].box.aabb, this.pick_dist)) {
        //!cell.references[i].box.gds_data_global.contain([mouse_pos.x, mouse_pos.y])
        continue
      }
      let trans = [...transforms] //Object.assign(transforms)
      let dep_c = dep_count + 1
      let result = this.loopCellGraphics(cell.references[i].cell, func, cell.references[i], depth, trans, mouse_pos, dep_c)
      if (result === 'break') {
        break
      }
    }
  }

  getRefLines(mouse_pos, ref, transforms = []) {
    transforms.push(ref)
    let transP = this.transPointsInverse([mouse_pos], transforms)
    let mp = { x: transP[0][0], y: transP[0][1] }

    let cell = ref.cell
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    for (let i = 0; i < path_length; i++) {
      let result = checkHit(mp, cell.paths[i])
      if (result?.lines?.length) {
        return { result, transforms }
      }
    }
    for (let i = 0; i < polygons_length; i++) {
      let result = checkHit(mp, cell.polygons[i])
      if (result?.lines?.length) {
        return { result, transforms }
      }
    }
    for (let i = 0; i < ellipses_length; i++) {
      let result = checkHit(mp, cell.ellipses[i])
      if (result?.lines?.length) {
        return { result, transforms }
      }
    }
    for (let i = 0; i < ref_length; i++) {
      let result = this.getRefLines(mouse_pos, cell.references[i], transforms)
      if (result) {
        return result
      }
    }
  }

  //吸附功能，循环cell所有图形
  loopCellGraphicsAdsorption(cell, func, ref, depth = false, transforms = []) {
    if (!cell) return
    // if(this.editReference){//编辑下层
    //   cell = this.cells[this.editReference.name]
    // }
    if (ref && !depth) {
      func(ref.box)
      return
    }
    //记录引用变换
    if (ref) {
      transforms.push(ref)
    }
    const path_length = cell.paths.length
    const polygons_length = cell.polygons.length
    const ellipses_length = cell.ellipses.length
    const ref_length = cell.references.length
    const ruler_length = cell.rulers.length
    const label_length = cell.labels.length
    const cell3DArea_length = cell.cell3DArea.length
    const cell3DCutBox_length = cell.cell3DCutBox.length
    for (let i = 0; i < path_length; i++) {
      let result = func(cell.paths[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < polygons_length; i++) {
      let result = func(cell.polygons[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ellipses_length; i++) {
      let result = func(cell.ellipses[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ruler_length; i++) {
      let result = func(cell.rulers[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < label_length; i++) {
      let result = func(cell.labels[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < cell3DArea_length; i++) {
      let result = func(cell.cell3DArea[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < cell3DCutBox_length; i++) {
      let result = func(cell.cell3DCutBox[i])
      if (result === 'break') {
        return 'break'
      }
    }
    for (let i = 0; i < ref_length; i++) {
      let result = this.loopCellGraphics(cell.references[i].cell, func, cell.references[i], depth, transforms)
      if (result === 'break') {
        break
      }
    }
  }
  //更新cell 引用 box
  updateCellRefBox(ref) {
    let aabb = ref.cell.aabb

    let rect = new QGdstk.rectangle(aabb[0], aabb[1], 0, 0)
    ref.box.setRefBoxPoints(rect.get_points())
    // for (let i = 0; i < ref_length; i++) {
    //   if (this.cell.references[i].name === refName) {
    //     let ref = this.cell.references[i]
    //     let points = cell.aabb
    //     let rect = new QGdstk.rectangle(points[0], points[1], 0, 0)
    //     ref.box.setRefBoxPoints(rect.get_points())
    //   }
    // }
  }

  //将点根据器件引用做矩阵变换
  applyEditRefTransform(points, ref) {
    if (!ref) return points
    let temp = new QGdstk.Polygon(points)
    temp.scale(1 * ref.magnification, ref.x_reflection * ref.magnification)
    temp.rotate((ref.rotation * Math.PI) / 180)
    temp.translate([ref.origin[0], ref.origin[1]])
    return temp
  }

  //将点根据器件引用做逆矩阵变换
  applyEditRefInverseTransform(points, ref) {
    if (!ref) return points
    let temp = new QGdstk.Polygon(points)
    temp.translate([-ref.origin[0], -ref.origin[1]])
    temp.rotate((-ref.rotation * Math.PI) / 180)
    temp.scale(1 / ref.magnification, (1 / ref.x_reflection) * ref.magnification)
    return temp
  }

  //记录历史
  recordHistory(type, obj = null) {
    //记录单个物体属性
    if (type === 'property') {
      let from = []
      let to = []
      let qdata = null
      if (obj.QDATA?.constructor.name === 'Label') {
        qdata = obj.QDATA.copy()
      }
      from.push({
        type: obj.type,
        transform: obj.copyTransform(),
        local_points: obj.gds_data_local.get_points(),
        r: [obj.r[0], obj.r[1]],
        width: obj.width,
        radius: obj.radius,
        layer: obj.layer,
        QDATA: qdata,
      })
      to.push(null)
      this.editHistory.record([{ action: type, cellName: this.cell.name, objs: [obj], from: from, to: to }])
      return
    }

    let len = this.checked_objs.length
    if (!len) return
    let targets = []
    let from = []
    let to = []
    for (let i = 0; i < len; i++) {
      let obj = this.checked_objs[i]
      if (type === 'transform') {
        targets.push(obj)
        from.push(obj.copyTransform()) //拷贝position
        to.push(null)
      } else if (type === 'shape') {
        targets.push(obj)
        from.push({
          //拷贝数据
          type: obj.type,
          transform: obj.copyTransform(),
          local_points: obj.gds_data_local.get_points(),
          r: [obj.r[0], obj.r[1]],
          width: obj.width,
          radius: obj.radius,
        })
        to.push(null)
      }
    }
    this.editHistory.record([{ action: type, cellName: this.cell.name, objs: targets, from: from, to: to }])
  }

  //撤销
  undo(history) {
    let undo_objs = history.objs
    let action_objs_len = undo_objs.length
    let isDelete = history.action === 'delete'
    let isAdd = history.action === 'add'
    let isTransform = history.action === 'transform'
    let isShapeUpdate = history.action === 'shape'
    let isPropertyUpdate = history.action === 'property'
    let isAddCell = history.action === 'addCell'
    let isMergeLayers = history.action === 'mergeLayers'

    let editRef = false
    const cell = this.cells[history.cellName]
    for (let i = 0; i < action_objs_len; i++) {
      let obj = undo_objs[i]
      if (obj.isRefBox) {
        editRef = true
      }
      if (isDelete) {
        //判断反向操作 delete->add add->delete
        cell.add(obj)
      } else if (isAdd) {
        cell.delete(obj)
      } else if (isTransform) {
        history.to[i] = obj.copyTransform() //撤销前记录当前位置
        let transform = history.from[i]
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.updateShape()
        obj.updateSlopes()
        obj.updateCheckedPaths()
      } else if (isShapeUpdate) {
        history.to[i] = {
          //撤销前记录当前数据
          type: obj.type,
          transform: obj.copyTransform(),
          local_points: obj.gds_data_local.get_points(),
          r: [obj.r[0], obj.r[1]],
          width: obj.width,
          radius: obj.radius,
        }
        let transform = history.from[i].transform
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.gds_data_local = new QGdstk.Polygon(history.from[i].local_points)
        obj.r = history.from[i].r
        obj.width = history.from[i].width
        obj.radius = history.from[i].radius
        obj.updateShape()
        obj.updateSlopes()
        obj.updateCheckedPaths()
      } else if (isPropertyUpdate) {
        let qdata = null
        // 撤销标签相关数据
        if (obj.QDATA?.constructor.name === 'Label') {
          qdata = obj.QDATA.copy()
        }
        history.to[i] = {
          //撤销前记录当前数据
          type: obj.type,
          transform: obj.copyTransform(),
          local_points: obj.gds_data_local.get_points(),
          r: [obj.r[0], obj.r[1]],
          width: obj.width,
          radius: obj.radius,
          layer: obj.layer,
          QDATA: qdata,
        }
        let transform = history.from[i].transform
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.gds_data_local = new QGdstk.Polygon(history.from[i].local_points)
        obj.r = history.from[i].r
        obj.width = history.from[i].width
        obj.radius = history.from[i].radius
        obj.layer = history.from[i].layer
        this.setHideAndLock(obj.layer, obj)
        if (qdata) {
          //撤销标签相关属性
          obj.QDATA = history.from[i].QDATA
        }
        obj.updateShape()
        obj.updateSlopes()
        obj.updateCheckedPaths()
      } else if (isAddCell) {
        this.qedaLib.deleteCell(obj.name)
      } else if (isMergeLayers) {
        obj.layer = history.from[i]
        obj.updateQDATA()
        this.setHideAndLock(obj.layer, obj)
      }
    }
    this.qedaLib.write()

    if (editRef) {
      bus.$emit('updateFileTree', true)
    }
  }
  //恢复
  redo(history) {
    let redo_objs = history.objs
    let action_objs_len = redo_objs.length
    let isDelete = history.action === 'delete'
    let isAdd = history.action === 'add'
    let isTransform = history.action === 'transform'
    let isShapeUpdate = history.action === 'shape'
    let isPropertyUpdate = history.action === 'property'
    let isAddCell = history.action === 'addCell'
    let isMergeLayers = history.action === 'mergeLayers'
    let editRef = false
    const cell = this.cells[history.cellName]
    for (let i = 0; i < action_objs_len; i++) {
      let obj = redo_objs[i]
      if (obj.isRefBox) {
        editRef = true
      }
      if (isDelete) {
        cell.delete(obj)
      } else if (isAdd) {
        cell.add(obj)
      } else if (isTransform) {
        let transform = history.to[i]
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.updateShape()
        obj.updateCheckedPaths()
      } else if (isShapeUpdate) {
        let transform = history.to[i].transform
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.gds_data_local = new QGdstk.Polygon(history.to[i].local_points)
        obj.r = history.to[i].r
        obj.width = history.to[i].width
        obj.radius = history.to[i].radius
        obj.updateShape()
        obj.updateCheckedPaths()
      } else if (isPropertyUpdate) {
        let transform = history.to[i].transform
        obj.position = transform.position
        obj._scale = transform._scale
        obj.rotation = transform.rotation
        obj.rotation_old = transform.rotation_old
        obj.gds_data_local = new QGdstk.Polygon(history.to[i].local_points)
        obj.r = history.to[i].r
        obj.width = history.to[i].width
        obj.radius = history.to[i].radius
        obj.layer = history.to[i].layer
        this.setHideAndLock(obj.layer, obj)
        if (history.to[i].QDATA) {
          obj.QDATA = history.to[i].QDATA
        }
        obj.updateShape()
        obj.updateCheckedPaths()
      } else if (isAddCell) {
        this.qedaLib.addExistCell(obj)
      } else if (isMergeLayers) {
        obj.layer = history.to[i]
        obj.updateQDATA()
        this.setHideAndLock(obj.layer, obj)
      }
    }
    this.qedaLib.write()
    if (editRef) {
      bus.$emit('updateFileTree', true)
    }
  }

  //指定cell添加图形
  addCellGraphic(cell, graphic) {
    cell.add(graphic)
  }

  //指定cell删除图形
  deleteCellGraphic(cell, graphic) {
    cell.delete(graphic)
  }

  // addCellItems(cellName, objs) {
  //   const cell = this.cells[cellName]
  //   let refs = objs.filter(obj => obj.isRefBox)
  //   cell.addItems(objs)
  //   this.qedaLib.write()
  // }

  // deleteCellItems(cellName, objs) {
  //   const cell = this.cells[cellName]
  //   cell.deleteItems(objs)
  //   this.qedaLib.write()
  // }

  // //指定cell更新图形
  // updateCellGraphic(graphic) {
  //   // graphic
  // }

  //获取当前编辑的cell
  getCurrentEditCell() {
    return this.cells[this.getCurrentCellName()]
  }
  //获取当前编辑的cell
  setCurrentEditCell() {
    this.currentEditCell = this.getCurrentEditCell()
  }
  //获取当前编辑的cell name
  getCurrentCellName() {
    if (this.editReference) {
      return this.editReference.cell.name
    }
    return this.cell.name
  }

  anchorTran(anchor, len, pt, isKeyPoint) {
    // canvaskit字体在右上角，按照此位置转换到对应的8个方位
    // let dpi = 96 // dpi: 一般电脑为96
    // let px = (pt * dpi) / 72 // 转为像素大小
    let offset = 6
    if (!isKeyPoint) {
      offset = 0
    }
    let tranX = len * pt * 0.6
    let tranY = 1 * pt
    if (anchor == 'o') {
      return { x: tranX / 2, y: tranY / 2 }
    }
    if (anchor == 'e') {
      return { x: tranX, y: tranY / 2 }
    }
    if (anchor == 's') {
      return { x: tranX / 2, y: 0 - offset }
    }
    if (anchor == 'w') {
      return { x: 0, y: tranY / 2 }
    }
    if (anchor == 'n') {
      return { x: tranX / 2, y: tranY + offset }
    }
    if (anchor == 'ne') {
      return { x: tranX, y: tranY + offset }
    }
    if (anchor == 'se') {
      return { x: tranX, y: 0 - offset }
    }
    if (anchor == 'nw') {
      return { x: 0, y: tranY + offset }
    }
    if (anchor == 'sw') {
      return { x: 0, y: 0 - offset }
    }
    return { x: 0, y: 0 }
  }

  transPointsInverse(points, references) {
    let temp = new QGdstk.Polygon(points)
    references.forEach(ref => {
      temp.translate([-ref.origin[0], -ref.origin[1]])
      temp.rotate((-ref.rotation * Math.PI) / 180)
      temp.scale(1 / ref.magnification, ref.x_reflection / ref.magnification)
    })

    return temp.get_points()
  }

  transPoints(points, references) {
    let temp = new QGdstk.Polygon(points)
    let i = references.length - 1
    while (i >= 0) {
      let ref = references[i]
      temp.scale(1 * ref.magnification, ref.x_reflection * ref.magnification)
      temp.rotate((ref.rotation * Math.PI) / 180)
      temp.translate([ref.origin[0], ref.origin[1]])
      i--
    }
    // references.forEach(ref => {
    //   temp.rotate((ref.rotation * Math.PI) / 180)
    //   temp.translate([ref.origin[0], ref.origin[1]])
    // })

    return temp.get_points()
  }

  //预选择
  preCheckGraphic(mouse_pos, mouse_pos_arr, mutilp_check = false) {
    if (this.moveMod !== 0) return
    let _this = this
    function preCheckOverall(obj, transforms) {
      if (obj.drawing || obj.isTool || obj.lock || obj.hide) return //工具图形 隐藏 锁定 其他图层 禁止选中 || obj.checked
      if (_this.mouse_drag || _this.drawing) return
      if (obj.isRefBox && (obj.reference.cell.hide || obj.reference.cell.lock)) return
      const result = checkHit(mouse_pos, obj)
      if (result) {
        _this.updatePreCheckedObjsId(obj.id, obj.checked) //更新选中元素列表
        // if (obj.checked) return
        setTimeout(() => {
          if (_this.canPreCheked(obj.id)) {
            //等待上面列表更新结束延迟判断是否可以被选中
            _this.pre_checked_obj = obj
          }
        })
        // _this.pre_checked_obj = obj
        // return 'break'
      }
    }
    function preCheckPart(obj, transforms) {
      if (obj.drawing || obj.isTool || obj.lock || obj.hide || obj.isRefBox) return //工具图形 隐藏 锁定 其他图层 禁止选中
      if (_this.mouse_drag || _this.drawing) return
      const result = checkHitPointAndLine(mouse_pos, obj, false)
      //存在命中点
      if (result?.point) {
        _this.preCheckedPoints.push(result.point[0], result.point[1])
        return 'break'
      } else if (result?.lines?.length) {
        //存在命中线
        _this.preCheckedLines.push(result.lines[0])
        _this.updatePreCheckedLinesPath()
        return 'break'
      }
    }
    // this.pre_checked_obj = null
    setTimeout(() => {
      //防闪烁
      this.pre_checked_obj = null
    })
    this.preCheckedPoints = []
    this.preCheckedLines = []
    this.preCheckedLinesPath = []
    if (this.checkMode === 0) {
      this.loopCellGraphicsAdsorption(this.currentEditCell, preCheckOverall)
    } else if (this.checkMode === 1) {
      this.loopCellGraphicsAdsorption(this.currentEditCell, preCheckPart)
    }

    _this.render()
  }

  //吸附点计算
  adsorpGraphic(mouse_pos, mouse_pos_arr) {
    if (this.editReference) {
      mouse_pos = this.myaxis.mouse_point
      mouse_pos_arr = [mouse_pos.x, mouse_pos.y]
    }
    let _this = this
    function preCheckPart(obj, transforms) {
      //排除选择的器件
      if (transforms.length && ((transforms[0].box.checked && _this.myaxis.left_drag) || transforms[0].box.preAdd)) return
      if (obj.drawing || ((obj.checked || obj.checked_paths.length) && (_this.myaxis.left_drag || (_this.moveMod === 1 && _this.movePosition.length))) || obj.isTool || obj.lock || obj.hide || obj.isRefBox || obj.preAdd) return //工具图形 隐藏 锁定 其他图层 预放置禁止吸附
      let mp = mouse_pos
      let scale = 1
      if (transforms.length) {
        let transP = _this.transPointsInverse([mouse_pos_arr], transforms)[0]
        mp = { x: transP[0], y: transP[1] }
        scale = transforms.at(-1).magnification
      }

      const result = checkHitPointAndLine(mp, obj, true, scale)

      //存在命中点
      if (result?.point) {
        let p = result.point
        if (transforms.length) {
          p = _this.transPoints([result.point], transforms)[0]
        }
        _this.adsorptionPoints.push(p[0], p[1])
        return 'break'
      } else if (result?.lines?.length) {
        //存在命中线
        let line = result.lines[0]
        if (transforms.length) {
          line = _this.transPoints(line, transforms)
        }
        //debug
        _this.adsorptionLines.push(line)
        _this.updateAdsorptionLinesPath()
        return 'break'
      }
    }
    this.loopCellGraphics(this.cell, preCheckPart, null, true, [], mouse_pos)
    _this.setHitPoint()
    // _this.render()
  }
  setHitPoint() {
    this.myaxis.hit_point = null
    if (this.adsorptionPoints.length) {
      this.myaxis.hit_point = this.adsorptionPoints
      // this.myaxis.updateValue()
    } else if (this.adsorptionLines.length) {
      let mp = this.myaxis.mouse_point
      let start = this.adsorptionLines[0][0]
      let end = this.adsorptionLines[0][1]
      let slope = getSlope(start, end)
      let slope_v = getVerticalSlope(slope)
      let intercept = mp.y - mp.x * slope_v
      if (slope_v === null) {
        intercept = mp.x
      }
      const line1 = slopeAndIntercept(start, end, slope)
      const line2 = { slope: slope_v, intercept: intercept }
      const cross = calculateIntersectionPoint(line1, line2)
      //求吸附线的交点
      this.adsorptionPoints = cross
      this.myaxis.hit_point = this.adsorptionPoints
    }
    this.myaxis.getMouseCrossLine()
    this.myaxis.getCenterCrossLine()
  }

  getRulerMouseAdropPos(mp) {
    let start = this.adsorptionLines[0][0]
    let end = this.adsorptionLines[0][1]
    let slope = getSlope(start, end)
    let slope_v = getVerticalSlope(slope)
    let intercept = mp.y - mp.x * slope_v
    if (slope_v === null) {
      intercept = mp.x
    }
    const line1 = slopeAndIntercept(start, end, slope)
    const line2 = { slope: slope_v, intercept: intercept }
    const cross = calculateIntersectionPoint(line1, line2)
    //求吸附线的交点
    return cross
  }
  runTOOLSQUIDZLINE(line) {
    bus.$emit('TOOL_SQUID_ZLINE_SELECT', line)
    this.preCheckedLines = []
    this.preCheckedLinesPath = []
    this.checkMode = this.checkMode_bk
  }

  drawRotAngle(canvas) {
    if (this.rotat_center) {
      let point = this.rotat_center
      if (this.editReference) {
        point = this.applyEditRefTransform([point], this.editReference).get_points()[0]
      }

      let x = point[0]
      let y = point[1]
      // canvas.drawPoints(CanvasKit.PointMode.Points, [x, y], this.point_checked_paint)
      let pt = this.pre_aligment_line_checked_paint.copy()
      pt.setStyle(CanvasKit.PaintStyle.Fill)

      let s = 1 / this.myaxis.scale
      canvas.save()
      canvas.translate(-x, y)
      canvas.scale(1 * s, -1 * s)
      canvas.translate(x / s, -y)
      canvas.drawText(`${this.rotation.toFixed(2)}°`, x / s - 40, y + 10, pt, this.label_font)
      canvas.restore()
      pt.delete()
    }
  }

  setHideAndLock(num, obj) {
    let layer = this.layerDatas.filter(obj => obj.layerNumber == num)[0]
    obj.hide = layer.hide
    obj.lock = layer.lock
  }

  getFillPtByScale(num, scale) {
    let layer
    if (num === undefined) {
      layer = this.layerDatas[0]
    } else {
      layer = this.layerDatas.filter(layer => layer.layerNumber == num)[0]
    }
    let rgb = this.hexToRgb(layer.color)
    const shape = layer.shape
    const pt = new CanvasKit.Paint()
    pt.setShader(this.fillFact.makeShader([...CanvasKit.Color(rgb[0], rgb[1], rgb[2]), ...QedaGraphicFillType[shape], 0, 0, this.myaxis.scale * scale]))
    return pt
  }
}
