export class QedaRenderer {
  constructor(cnavas_id) {
    this.ELEM = cnavas_id
    this.surface = CanvasKit.MakeCanvasSurface(this.ELEM)
    this.subSurface = this.surface.makeSurface({
      width: this.surface.width(),
      height: this.surface.height(),
      alphaType: CanvasKit.AlphaType.Premul,
      colorType: CanvasKit.ColorType.RGBA_8888,
      colorSpace: CanvasKit.ColorSpace.SRGB,
    })
    if (!this.subSurface) {
      console.error('Could not make subsurface')
      return
    }
    this.subCanvas = this.subSurface.getCanvas()
    this.cell_imgs = {}
  }

  async generateCellImgs(cells, scale, layer_paints, layer_fill_paints) {
    this.cell_imgs = {}
    let cell_list = Object.values(cells)
    for (let i = 0; i < cell_list.length; i++) {
      let cell = cell_list[i]
      let cell_name = cell_list[i].name
      let shader_promise = new Promise((resolve, reject) => {
        this.subCanvas.clear(CanvasKit.WHITE)
        //   this.subCanvas.concat([scale, 0, 0, 0, scale, 0, 0, 0, 1])
        // const path_length = cell.paths.length
        // const polygons_length = cell.polygons.length
        // const ellipses_length = cell.ellipses.length
        // const ref_length = cell.references.length
        // const ruler_length = cell.rulers.length
        // const label_length = cell.labels.length
        //   for (let i = 0; i < path_length; i++) {
        //     let obj = cell.paths[i]
        //     this.subCanvas.drawPath(obj.path, layer_paints[obj.layer].pt)
        //   }
        //   for (let i = 0; i < polygons_length; i++) {
        //     let obj = cell.polygons[i]
        //     this.subCanvas.drawPath(obj.path, layer_paints[obj.layer].pt)
        //     this.subCanvas.drawPath(obj.path, layer_fill_paints[obj.layer])
        //   }
        const img = this.subSurface.makeImageSnapshot()
        // const pattern = img.makeShaderCubic(CanvasKit.TileMode.Decal, CanvasKit.TileMode.Decal,
        //     1 / 3, 1 / 3, [1, 0, trans_x, 0, 1, trans_y, 0, 0, 1]);
        resolve(img)
      })
      await shader_promise.then(data => {
        this.cell_imgs[cell_name] = data
      })
    }
  }

  render(canvas, cell_name) {
    let cell_img = this.cell_imgs[cell_name]

    if (cell_img) {
      const pattern = cell_img.makeShaderCubic(CanvasKit.TileMode.Decal, CanvasKit.TileMode.Decal, 1 / 3, 1 / 3, [1, 0, 0, 0, 1, 0, 0, 0, 1])
      const paint = new CanvasKit.Paint()
      //   paint.setColor(CanvasKit.RED)
      paint.setShader(pattern)
      canvas.drawRect([-200, -200, 200, 200], paint)
      paint.delete()
      pattern.delete()
    }
  }
}
