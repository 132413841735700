import store from '@/store/index'

import { getCellFunc } from '@/utils/getcell'
import { getPDKCellFunc } from '@/utils/pdk/getPDKCell.js'
import { deepClone } from '@/utils/utils'

//更新文本图形器件
export function updateCharcterCell(characterCell, fileId) {
  if (characterCell) {
    clearCellQTNode(characterCell)
    characterCell.regenerate()
    let id = getLayerId(fileId, characterCell.text.layer)
    characterCell.polygons.forEach(polygon => {
      polygon.id = id
    })
    characterCell.labels.forEach(label => {
      let id = getLayerId(fileId, label.layer)
      label.id = id
    })
    characterCell.js_obj.bounding_box = characterCell.bounding_box()
  }
}

//更新参数化器件
export function updateParamCell(cell, fileId) {
  if (cell) {
    cell.regenerate()
    let id = getLayerId(fileId, characterCell.text.layer)
    cell.polygons.forEach(polygon => {
      polygon.id = id
    })
    cell.labels.forEach(label => {
      let id = getLayerId(fileId, label.layer)
      label.id = id
    })
  }
}

export function getLayerId(fileId, num) {
  let layerDatas = null
  let index = store.state.fileLayerListTable.findIndex(item => item.fileId === fileId)
  if (index === -1) {
  } else {
    layerDatas = store.state.fileLayerListTable[index].fileLayerDatas
  }
  for (let i = 0; i < layerDatas.length; i++) {
    if (layerDatas[i].layerNumber == num) {
      return layerDatas[i].id
    }
  }
}

const checkPDKObj = function (obj) {
  let labels = obj.labels
  let isPDK = false
  for (let i in labels) {
    if (labels[i].get_gds_property(5)) {
      isPDK = true
      break
    }
  }
  return isPDK
}

// 器件替换
export const exChangeCpmponent = function (cell, obj_params, fileId) {
  let file_gds_lib = null
  let function_name = obj_params.function_name
  let isPDK = checkPDKObj(cell)
  if (isPDK) {
    file_gds_lib = getPDKCellFunc(function_name, obj_params.params, cell)
  } else {
    file_gds_lib = getCellFunc(function_name, obj_params.params, cell)
  }
  file_gds_lib.write_gds(function_name)
  let data_u8 = window.QGdstk.FS.readFile(function_name)
  Kernel.FS.writeFile(function_name, data_u8)
  let file_lib = new Kernel.Library(function_name)
  file_lib.read_gds(function_name)
  let cell_obj = file_lib.cells.filter(cell => cell.name == function_name)[0]
  // 替换
  clearCellQTNode(cell)
  cell.copying_partial_data(cell_obj)
  updateCellLayerIds(cell, fileId)
  let params_json = {}
  params_json.function_name = function_name
  params_json.params = obj_params.params
  params_json.is_params_device = true
  // 赋值
  cell.pcell.paras = JSON.stringify(params_json)

  return cell
}

//清除四叉树内容
export function clearCellQTNode(cell) {
  const QUAD_TREE = cell.js_obj.QUAD_TREE
  if (QUAD_TREE) {
    const flexpaths = cell.flexpaths
    const flexpaths_size = flexpaths.length
    const polygons = cell.polygons
    const polygon_size = polygons.length
    const labels = cell.labels
    const labels_size = labels.length
    const referens = cell.referens
    const ref_size = referens.length
    for (let i = 0; i < flexpaths_size; i++) {
      const path = flexpaths[i]
      QUAD_TREE.removeNode(path)
    }
    for (let i = 0; i < polygon_size; i++) {
      const polygon = polygons[i]
      QUAD_TREE.removeNode(polygon)
    }
    for (let i = 0; i < labels_size; i++) {
      const label = labels[i]
      QUAD_TREE.removeNode(label)
    }
    for (let i = 0; i < ref_size; i++) {
      const ref = referens[i]
      QUAD_TREE.removeNode(ref)
    }
  }
}

export function updateCellLayerIds(cell, fileId) {
  cell.polygons.forEach(polygon => {
    let id = getLayerId(fileId, polygon.layer)
    polygon.id = id
  })
  cell.labels.forEach(label => {
    let id = getLayerId(fileId, label.layer)
    label.id = id
  })
  cell.js_obj.bounding_box = cell.bounding_box()
}
