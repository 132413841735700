//图层列表相关接口
import { get, post, patch, httpDelete, download } from '../axiosReq'

//添加图层
export const addLayerInfo_api = (params) => post('/qeda/component/add_layer_info/', params)

//获取图层
export const getLayerInfo_api = (params) => post('/qeda/component/get_layer_info/', params)

//编辑图层
export const editLayerInfo_api = (params) => post('/qeda/component/edit_layer_info/', params)

//删除图层
export const deleteLayerInfo_api = (params) => post('/qeda/component/delete_layer_info/', params)