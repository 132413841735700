export function getAStartPath(quadtree, start, end) {
  var res = { points: [], translate: [0, 0], nodeSet: [] }
  var area = new QGdstk.rectangle(start, end, 0, 0)

  var hits = quadtree.queryObstaclesByAABB(area.bounding_box()).map(hit => hit.bounding_box)
  if (!hits.length) {
    return res
  }
  var expand = 5 //放大系数
  var n = 10
  var box = getMapWH(start, end, hits, n, expand)
  var w = (box.w / n) * (n + expand)
  var h = (box.h / n) * (n + expand)

  var translate = box.translate //box.translate 地图左下角坐标
  var grid_w = w / n
  var grid_h = h / n
  translate[0] -= grid_w / 2 + (grid_w / 2) * expand
  translate[1] -= grid_h / 2 + (grid_h / 2) * expand
  var aStar = new AStar(n, w / n, h / n, grid_w, grid_h, translate)
  var { nodeSet, startNode, endNode } = aStar.buildNodeSet(quadtree, start, end)
  // var startNode = nodeSet[0][0]
  // var endNode = nodeSet[n - 1][n - 1]
  if (!startNode || !endNode) {
    return res
  }
  startNode.isStart = true
  startNode.isObstacle = false
  endNode.isEnd = true
  endNode.isObstacle = false
  var path = aStar.getPath(startNode, endNode)

  let points = []
  if (path.length) {
    path.forEach(node => points.push(node.pos))
    points.reverse()
  }
  res.points = points
  res.translate = translate
  res.nodeSet = nodeSet

  return res
  // }
}

function getMapWH(start, end, boxs, n, exp) {
  var res = { w: 0, h: 0 }
  var expand_x = 0
  var expand_y = 0
  var min_x = start[0]
  var min_y = start[1]
  var max_x = end[0]
  var max_y = end[1]
  boxs.forEach(box => {
    if (min_x > box[0][0]) {
      min_x = box[0][0]
    }
    if (min_y > box[0][1]) {
      min_y = box[0][1]
    }
    if (max_x < box[1][0]) {
      max_x = box[1][0]
    }
    if (max_y < box[1][1]) {
      max_y = box[1][1]
    }
  })
  expand_x = ((max_x - min_x) / n) * exp
  expand_y = ((max_y - min_y) / n) * exp
  min_x -= expand_x
  min_y -= expand_y
  max_x += expand_x
  max_y += expand_y

  res.translate = [min_x, min_y]
  res.w = max_x - min_x
  res.h = max_y - min_y
  return res
}

var Node = function (x, y, w, h, pos) {
  this.x = x
  this.y = y
  this.g = Infinity
  this.f = Infinity
  this.cameFrom = undefined
  this.neighbors = new Array()
  this.isObstacle = false
  this.isPath = false
  this.isStart = false
  this.isEnd = false
  this.w = w
  this.h = h
  this.aabb = null
  this.pos = pos
  this.setNeighbors = function (set) {
    for (var i = -1; i < 2; i++) {
      for (var j = -1; j < 2; j++) {
        if (this.x + j >= 0 && this.x + j < set.length && this.y + i >= 0 && this.y + i < set.length) {
          this.neighbors.push(set[this.y + i][this.x + j])
        }
      }
    }
  }
}

function distance(start, end) {
  return Math.sqrt((start.y - start.x) * 2 + (end.y - end.x) * 2)
}

function reconstructPath(node) {
  var tempPath = new Array()
  while (node.cameFrom != undefined) {
    node.isPath = true
    tempPath.push(node)
    node = node.cameFrom
  }

  return tempPath
}

var AStar = function (np, wp, hp, grid_w, grid_h, translate) {
  this.openSet = new Array()
  this.closedSet = new Array()
  this.w = wp
  this.h = hp
  this.n = np
  this.grid_w = grid_w
  this.grid_h = grid_h
  this.translate = translate
  this.getPath = function (start, end) {
    this.openSet.push(start)
    start.g = 0
    start.h = distance(start, end)

    var current = start
    while (this.openSet.length > 0) {
      var currentIndex = 0
      for (var i = 0; i < this.openSet.length; i++) {
        if (this.openSet[i].g < current.g) {
          currentIndex = i
        }
      }

      current = this.openSet[currentIndex]
      if (current == end) {
        return reconstructPath(current)
      }

      this.openSet.splice(this.openSet[currentIndex], 1)

      for (var i = 0; i < current.neighbors.length; i++) {
        if (!current.neighbors[i].isObstacle && isVertical(current.pos, current.neighbors[i].pos)) {
          var tG = current.g + 1 + distance(start, end)
          if (tG < current.neighbors[i].g) {
            current.neighbors[i].cameFrom = current
            current.neighbors[i].g = tG
            current.neighbors[i].f = tG + distance(current.neighbors[i], end)
            if (!this.openSet.includes(current.neighbors[i])) {
              this.openSet.push(current.neighbors[i])
            }
          }
        }
      }
    }
    return Array()
  }

  this.buildNodeSet = function (quadtree, start, end) {
    var set = new Array()
    var startNode, endNode
    for (var i = 0; i < this.n; i++) {
      set.push(new Array(this.n))
    }
    let half_grid_w = this.grid_w / 2
    let half_grid_h = this.grid_h / 2
    for (var i = 0; i < this.n; i++) {
      for (var j = 0; j < this.n; j++) {
        let pos = [this.translate[0] + i * this.grid_w + half_grid_w, this.translate[1] + j * this.grid_h + half_grid_h]
        let node = new Node(j, i, this.w, this.h, pos)
        set[i][j] = node
        let min_x = pos[0] - half_grid_w
        let min_y = pos[1] - half_grid_h
        let max_x = pos[0] + half_grid_w
        let max_y = pos[1] + half_grid_h
        let aabb = [
          [min_x, min_y],
          [max_x, max_y],
        ]
        if (!startNode) {
          if (start[0] >= min_x && start[1] >= min_y && start[0] <= max_x && start[1] <= max_y) {
            startNode = node
          }
        }
        if (!endNode) {
          if (end[0] >= min_x && end[1] >= min_y && end[0] <= max_x && end[1] <= max_y) {
            endNode = node
          }
        }

        node.aabb = aabb

        if (quadtree.queryByAABB(aabb).length) {
          node.isObstacle = true
        }
      }
    }
    for (var i = 0; i < this.n; i++) {
      for (var j = 0; j < this.n; j++) {
        set[i][j].setNeighbors(set)
      }
    }

    return { nodeSet: set, startNode, endNode }
  }
}

function isVertical(p1, p2) {
  return p1[0] == p2[0] || p1[1] == p2[1]
}
